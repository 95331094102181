import { styled } from '@mui/material';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { InfoAlert } from '@components/alerts';

const Container = styled('div')({
  position: 'absolute',
  top: 15,
  left: 15,
  zIndex: 3000,
  '#info-alert': {
    background: '#00223A',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiAlert-icon': {
    borderRadius: '16px',
    background: '#FF3D3D',
    width: 32,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    color: '#fff',
  },
  '.MuiAlert-message > div': {
    color: '#fff !important',
    fontWeight: 400,
    padding: 0,
  },
});

export default function ChartReadonlyAlert() {
  const { canEditGraph, isCaseComplete } = useCasePermission();

  if (canEditGraph) {
    return null;
  }

  if (isCaseComplete) {
    return (
      <Container>
        <InfoAlert title="READ ONLY CHART - This Analysis is complete" />
      </Container>
    );
  }

  return (
    <Container>
      <InfoAlert title="READ ONLY CHART - You do not have editor permissions for this RCA" />
    </Container>
  );
}
