import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface CooldownCallback {
  call: () => void;
  isCoolingDown: boolean;
  timeLeft: number;
}

export default function useCooldownCallback(
  callback: () => void
): CooldownCallback {
  const currentInterval = useRef<NodeJS.Timer | null>(null);
  const [timeLeft, setTimeLeft] = useState(0);

  const call = useCallback(() => {
    if (currentInterval.current != null) {
      return;
    }

    callback();
    setTimeLeft(30);

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(interval);
          currentInterval.current = null;
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    currentInterval.current = interval;
  }, []);

  useEffect(() => {
    return () => {
      if (currentInterval.current != null) {
        clearInterval(currentInterval.current);
      }
    };
  }, []);

  const isCoolingDown = timeLeft > 0;

  return useMemo(
    () => ({ call, isCoolingDown, timeLeft }),
    [call, isCoolingDown, timeLeft]
  );
}
