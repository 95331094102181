import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useAppDispatch } from '@store/store';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { CreateCaseFileRequest } from '@api/types/case/case-file/create-case-file.request';
import { useState } from 'react';
import caseFileApi from '@api/endpoints/case-file.api';

export default function useCaseFileManagerLink({
  caseId,
}: CaseFileManagerState) {
  const [isBusy, setIsBusy] = useState(false);
  const { showErrorMessage } = usePageAlertVariants();
  const dispatch = useAppDispatch();

  const linkUrl = useField<string>([required()]);
  const linkName = useField<string>([required()]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    linkUrl,
    linkName,
  ]);

  const canSubmitLink = isValid && isDirty && !isBusy;

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    try {
      setIsBusy(true);

      return await dispatch(
        caseFileApi.endpoints.createCaseFile.initiate({
          caseId: caseId,
          files: [
            {
              name: linkName.value,
              caseFileType: CaseFileType.location,
              location: linkUrl.value,
            },
          ],
        })
      ).unwrap();
    } catch (error) {
      setIsBusy(false);
      const { errors, message } = error as ApiError<
        CreateCaseFileRequest & { caseId: number }
      >;

      showErrorMessage(errors?.caseId ?? message);

      const firstError = errors?.files?.[0];
      linkUrl.setError(firstError);

      return false;
    }
  };

  return {
    linkUrl,
    linkName,
    canSubmitLink,
    submit,
    isBusy,
  };
}
