import { api } from '@api/api';
import { InstallConfigurationResource } from '@api/types/install/install-configuration.resource';

const installApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInstallConfiguration: build.query<InstallConfigurationResource, void>({
      query: () => 'Install/Configuration',
    }),
  }),
});

export const { useGetInstallConfigurationQuery } = installApi;
