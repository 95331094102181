import useField from '@hooks/use-field-hook';
import { PhoneNumber } from '@components/input/phone-number-field';
import {
  useGetCountryNamesQuery,
  useGetDialCodesQuery,
} from '@api/endpoints/country.api';
import { required, validEmail } from '@util/validators';
import { useRequestAccessMutation } from '@api/endpoints/enterprise.api';
import { ApiError } from '@api/types/api-error';
import { RegisterInterestRequest } from '@api/types/enterprise/register-interest.request';
import { useAppTr } from '@i18n/use-app-tr';
import { usePageAlertVariants } from '@components/alerts';
import { useDefaultIfSingleOption } from '@hooks/use-default-if-single-option-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useRegisterInterest() {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const { t } = useAppTr('registerInterest');

  const { data: dialCodes, isLoading: loadingDialCodes } =
    useGetDialCodesQuery(undefined);
  const { data: countries, isLoading: loadingCountries } =
    useGetCountryNamesQuery(undefined);

  const [requestAccess, { isLoading: isSubmitting }] =
    useRequestAccessMutation();

  const email = useField<string>([required(), validEmail()]);
  const firstName = useField<string>([required()]);
  const surname = useField<string>([required()]);
  const phoneNumber = useField<PhoneNumber | undefined>();
  const companyName = useField<string>([required()]);
  const jobTitle = useField<string>();
  const country = useField<number>(
    [required()],
    useDefaultIfSingleOption(countries)
  );
  const requirements = useField<string>([required()]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    email,
    firstName,
    surname,
    phoneNumber,
    companyName,
    jobTitle,
    country,
    requirements,
  ]);

  const isLoading = loadingDialCodes || loadingCountries;
  const canSubmit = isValid && isDirty && !isSubmitting && !isLoading;

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return requestAccess({
      companyName: companyName.value,
      requirements: requirements.value,
      jobTitle: jobTitle.value,
      email: email.value,
      firstName: firstName.value,
      countryId: country.value,
      telephoneNumber: phoneNumber.value?.number,
      lastName: surname.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<RegisterInterestRequest>) => {
        showErrorMessage(message);

        companyName.setError(errors?.companyName);
        requirements.setError(errors?.requirements);
        jobTitle.setError(errors?.jobTitle);
        email.setError(errors?.email);
        firstName.setError(errors?.firstName);
        country.setError(errors?.countryId);
        phoneNumber.setError(errors?.telephoneNumber);
        surname.setError(errors?.lastName);

        return false;
      });
  };

  return {
    email,
    firstName,
    surname,
    phoneNumber,
    companyName,
    jobTitle,
    country,
    requirements,
    dialCodes,
    countries,
    canSubmit,
    isLoading,
    isSubmitting,
    submit,
  };
}
