import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import useCaseFileViewModal from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-view-modal-hook';
import { Box, Grid, Link, styled, Typography } from '@mui/material';
import { FlatButton, OutlinedButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import CircleContainer from '@components/containers/circle-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faDownload,
  faFiles,
  faFolder,
  faLink,
} from '@fortawesome/pro-light-svg-icons';
import { InlineBoxSkeleton } from '@components/skeletons';
import { dateOnlyShortFormatter, timespanFormatter } from '@i18n/formatters';
import LabelValueDisplay from '@components/data/label-value-display';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import Spacer from '@components/layout-util-components/spacer';
import { usePageAlertVariants } from '@components/alerts';
import {
  CaseFileEvidenceItem,
  CaseFileSolutionItem,
} from '@api/types/case/case-file/case-file.resource';
import Column from '@components/layout-util-components/column';
import CaseSolutionItem from '@components/cards/case-solution-item';
import EvidenceCard from '@components/cards/evidence-card';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';
import { convertBytesToMB } from '@util/helper-util';
import config from 'src/config';
import CaseFileIcon from '@components/case-file/case-file-icon';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';

export interface CaseFileViewModalProps extends SideDrawerFormProps<never> {
  caseId: number;
  caseFileId: number;
  onDelete?: () => Promise<boolean>;
}

const Container = styled(DrawerContent)({});

function CaseFileViewModal({
  caseFileId,
  caseId,
  onClose,
  onDelete,
}: CaseFileViewModalProps) {
  const navigate = useNavigate();
  const { showSuccessMessage } = usePageAlertVariants();
  const {
    isFile,
    isUrl,
    caseFileDetail,
    isLoading,
    evidence,
    solutions,
    currency,
  } = useCaseFileViewModal(caseId, caseFileId);

  const onViewEvidence = (evidence: CaseFileEvidenceItem) => {
    onClose();
    navigate(`/rca/tabs/${caseId}/evidence/?evidenceId=${evidence.evidenceId}`);
  };
  const onViewSolution = (solution: CaseFileSolutionItem) => {
    onClose();
    navigate(
      `/rca/tabs/${caseId}/solutions/?solutionId=${solution.solutionId}`
    );
  };

  return (
    <>
      <DrawerHeader onClose={onClose}>View File</DrawerHeader>
      <Container>
        <Row>
          <CaseFileIcon
            caseId={caseFileDetail?.caseId ?? 0}
            type={caseFileDetail?.caseFileType ?? CaseFileType.file}
            contentType={caseFileDetail?.contentType}
            fileMetadataId={caseFileDetail?.smallThumbnailFileMetadataId}
            blurredFileMetadataId={
              caseFileDetail?.smallBlurredThumbnailFileMetadataId
            }
            largeFileMetadataId={caseFileDetail?.largeThumbnailFileMetadataId}
            largeBlurredFileMetadataId={
              caseFileDetail?.largeBlurredThumbnailFileMetadataId
            }
            blurred={caseFileDetail?.showBlurredThumbnail}
            canUnblur={true}
            size={50}
          />
          <Gap size={10} />
          <Typography variant="h5">
            {isLoading ? (
              <InlineBoxSkeleton width={150} />
            ) : (
              `${caseFileDetail!.name} (${dateOnlyShortFormatter(
                caseFileDetail!.created
              )})`
            )}
          </Typography>
        </Row>
        <Gap size={32} />
        <Grid container rowGap={1}>
          <Grid item xs={12}>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : isFile ? (
              <Row>
                <LabelValueDisplay
                  label="FILE SIZE"
                  value={convertBytesToMB(caseFileDetail!.fileLength ?? 0)}
                />
                <Spacer />
                <FlatButton
                  onClick={() => {
                    window.open(
                      `${config.apiUrl}/api/case/${caseId}/caseFile/file/${
                        caseFileDetail!.fileMetadataId
                      }`,
                      '_blank'
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </FlatButton>
              </Row>
            ) : (
              <Row>
                <LabelValueDisplay
                  label="FILE LOCATION"
                  value={
                    isUrl ? (
                      <Link href={caseFileDetail!.fileLocation} target="_blank">
                        {caseFileDetail!.fileLocation}
                      </Link>
                    ) : (
                      <>{caseFileDetail!.fileLocation}</>
                    )
                  }
                />
                <Spacer />
                <FlatButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      caseFileDetail!.fileLocation!
                    );
                    showSuccessMessage('Link copied to clipboard');
                  }}
                >
                  <FontAwesomeIcon icon={faFiles} />
                </FlatButton>
              </Row>
            )}
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label="ADDED BY"
                value={caseFileDetail!.createdByName}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label="DATE CREATED"
                value={`${dateOnlyShortFormatter(
                  caseFileDetail!.created
                )} (${timespanFormatter(caseFileDetail!.created)})`}
              />
            )}
          </Grid>
        </Grid>
        <Gap size={32} />
        <Typography>
          {isLoading ? <InlineBoxSkeleton /> : 'File utilisation'}
        </Typography>
        {evidence.length > 0 && (
          <Column>
            <Gap size={20} />
            <Typography variant="overline">
              Evidence ({evidence.length})
            </Typography>
            <Gap size={10} />
            {evidence.map((caseFileEvidenceItem) => (
              <>
                <EvidenceCard
                  evidence={CaseFileEvidenceItem.toResource(
                    caseFileEvidenceItem
                  )}
                  endAction={
                    <FlatButton
                      onClick={() => onViewEvidence(caseFileEvidenceItem)}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </FlatButton>
                  }
                />
                <Gap size={10} />
              </>
            ))}
          </Column>
        )}
        <Gap size={32} />
        <Typography>
          {isLoading ? <InlineBoxSkeleton /> : 'File Preview'}
        </Typography>
        <Gap size={20} />
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CaseFileIcon
            caseId={caseFileDetail?.caseId ?? 0}
            type={caseFileDetail?.caseFileType ?? CaseFileType.file}
            contentType={caseFileDetail?.contentType}
            fileMetadataId={caseFileDetail?.largeThumbnailFileMetadataId}
            blurredFileMetadataId={
              caseFileDetail?.largeBlurredThumbnailFileMetadataId
            }
            largeFileMetadataId={caseFileDetail?.largeThumbnailFileMetadataId}
            largeBlurredFileMetadataId={
              caseFileDetail?.largeBlurredThumbnailFileMetadataId
            }
            blurred={caseFileDetail?.showBlurredThumbnail}
            canUnblur={true}
            size={490}
            iconSize={200}
          />
        </Box>

        {solutions.length > 0 && (
          <Column>
            <Gap size={20} />
            <Typography variant="overline">
              Solutions ({solutions.length})
            </Typography>
            <Gap size={10} />
            {solutions.map((solutionItem) => (
              <>
                <CaseSolutionItem
                  solution={CaseFileSolutionItem.toResource(solutionItem)}
                  currency={currency}
                  trailing={
                    <FlatButton onClick={() => onViewSolution(solutionItem)}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </FlatButton>
                  }
                />
                <Gap size={10} />
              </>
            ))}
          </Column>
        )}
      </Container>
      <DrawerFooter>
        {onDelete && (
          <OutlinedButton
            disabled={isLoading}
            onClick={() =>
              onDelete().then((didDelete) => {
                if (didDelete) {
                  onClose();
                }
              })
            }
          >
            Delete file
          </OutlinedButton>
        )}
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(CaseFileViewModal);
