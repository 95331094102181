import { createTheme, LinkProps } from '@mui/material';
import LinkBehavior from './behaviours/link-behaviour';
import { darkPalette, lightPalette } from './lightPalette';
import { typography } from './typography';

const createAppTheme = (mode: 'light' | 'dark') =>
  createTheme({
    breakpoints: {
      values: {
        xs: 414,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            display: 'none',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
    palette: {
      ...(mode === 'light' ? lightPalette : darkPalette),
    },
    typography,
  });

export default createAppTheme;
