import { ReactComponent as RcaIcon } from '@assets/svgs/rca-icon.svg';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  onCreateClicked: () => void;
}

export default function NoRcaInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<RcaIcon />}
      title="There are no RCA investigations to show"
      message="You can create your first RCA investigation by clicking the button below"
      createMessage="Create RCA"
      onCreateClicked={onCreateClicked}
    />
  );
}
