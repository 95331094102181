import { RootState } from '@store/store';
import { createSelector } from '@reduxjs/toolkit';
import { isNullOrEmpty } from '@util/string-util';

export const selectCurrentCompanyUserId = (state: RootState) =>
  state.user.companyUserId;

export const selectUser = (state: RootState) => state.user;

export const selectUserCompanyId = (state: RootState) => state.user?.companyId;

export const selectIsCancelledCompanyStatus = createSelector(
  [selectUser],
  ({ companyStatus }) => {
    return companyStatus === 'Canceled' || companyStatus === 'Cancelled';
  }
);

export const selectIsLoggedInAndFullyAuthenticated = createSelector(
  [selectUser, selectIsCancelledCompanyStatus],
  (
    { userId, requires2FA, is2FAAuthenticated, companyId, companyStatus },
    isCancelledCompany
  ) => {
    return !!(
      !isNullOrEmpty(userId) &&
      (!requires2FA || is2FAAuthenticated) &&
      !!companyId &&
      !isCancelledCompany
    );
  }
);
