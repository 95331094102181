import requireAuth from '@components/hoc/with-required-auth-hoc';
import useGroups from './groups-hook';
import ManageGroupsPage from './manage-groups-page';
import MyGroupsPage from './my-groups-page';

function GroupsPage() {
  const { canManageGroups } = useGroups();
  return canManageGroups ? <ManageGroupsPage /> : <MyGroupsPage />;
}

export default requireAuth(GroupsPage);
