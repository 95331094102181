import { Button, styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import FilledBadge from '@components/badges/filled-badge';
import Spacer from '@components/layout-util-components/spacer';
import {
  ChainItemAction,
  ChainItemActionRow,
  ChainItemActionType,
} from '@api/types/chain/chain-item-health-score/chain-item-action.response';
import { v4 as uuid } from 'uuid';
import {
  makeSelectNodeFromChainItemId,
  selectCurrentRcaCaseId,
} from '@store/rca-editor/selectors';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import { RcaUtil } from '@util/rca-util';
import {
  NodePanelEditorTab,
  selectNodePanelEditorTab,
  setSelectedNode,
} from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';
import { useNavigate } from 'react-router-dom';
import { useIsPath } from '@util/path-util';

interface Props {
  data: ChainItemActionRow;
}

const Container = styled('div', { name: 'health-score-node-item' })(
  ({ theme: { palette } }) => ({
    marginBottom: 10,
    border: '1px solid #D8DBE3',
    borderRadius: 5,
    background: '#F8F8FA',
    padding: 12,

    strong: {
      fontWeight: '600',
    },

    '>.title': {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      color: '#636D73',
      textDecoration: 'underline',
      textTransform: 'none',
      margin: '5px 0',
      '&:hover': {
        background: 'none',
        color: '#000',
      },
      '&:focus': {
        background: 'none',
      },
    },

    svg: {
      height: '15px',
      path: {
        fill: 'rgba(0, 0, 0, 0.65)',
      },
    },

    '.filled-badge': {
      background: '#FFDABF',
      color: 'rgba(0, 0, 0, 0.87)',
      borderRadius: '16px',
      fontSize: '13px',
      padding: '6px 8px',
      textTransform: 'none',
      marginRight: '11px',
      fontWeight: '500',
    },

    '.actions-list': {
      padding: 0,
      margin: 0,
      listStyleType: 'none',

      li: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D8DBE3',
        background: '#fff',
        borderRadius: 3,
        color: '#000000DE',
        marginTop: 5,
        padding: '8px 10px',
        cursor: 'pointer',

        '&:hover': {
          border: '1px solid #00223A',
          outline: '3px solid rgb(0, 34, 58, 0.12)',
          svg: {
            path: {
              fill: '#00223A',
            },
          },
        },

        '>.title': {
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.4000000059604645px',
          textAlign: 'left',
        },
      },
      '.filled-badge': {
        background: '#FFF0E5',
        color: '#001927',
        fontSize: '13px',
        padding: '5px 8px',
        marginRight: '0',
        fontWeight: '400',
      },
    },
  })
);

export default function HealthScoreNodeItem({
  data: { points, label, actions, chainItemId },
}: Props) {
  const navigate = useNavigate();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const isChartPage = useIsPath(`/rca/tabs/${caseId}/chart`, {
    startsWith: true,
  });

  const dispatch = useAppDispatch();

  const selectNode = useMemo(
    () => makeSelectNodeFromChainItemId(chainItemId),
    [chainItemId]
  );

  const node = useAppSelector(selectNode);

  const onActionClick = (action: ChainItemAction) => {
    let tab = NodePanelEditorTab.overview;
    switch (action.chainItemActionTypeKey) {
      case ChainItemActionType.task:
        tab = NodePanelEditorTab.tasks;
        break;
      case ChainItemActionType.evidence:
        tab = NodePanelEditorTab.evidence;
        break;
      case ChainItemActionType.solution:
        tab = NodePanelEditorTab.solutions;
        break;
      case ChainItemActionType.noEndState:
        tab = NodePanelEditorTab.overview;
        break;
      case ChainItemActionType.impact:
        tab = NodePanelEditorTab.impacts;
        break;
    }

    if (node != null && isChartPage) {
      dispatch(setSelectedNode(node));
      dispatch(selectNodePanelEditorTab(tab));
      RcaUtil.snapFocusToNode(node);
    } else {
      navigate(
        `/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}&panelTab=${tab}`
      );
    }
  };

  const getLabel = ({ chainItemActionTypeKey, count }: ChainItemAction) => {
    switch (chainItemActionTypeKey) {
      case ChainItemActionType.task:
        if (count === 1) {
          return '1 task is still incomplete';
        }
        return `${count} tasks are still incomplete`;
      case ChainItemActionType.evidence:
        return `No associated ${chainItemActionTypeKey.toLocaleLowerCase()}`;
      case ChainItemActionType.solution:
      case ChainItemActionType.impact:
        return `No associated ${chainItemActionTypeKey.toLocaleLowerCase()}s`;
      case ChainItemActionType.noEndState:
        return 'No associated end state';
      default:
        throw new Error(
          'Invalid cause box action type: ' + chainItemActionTypeKey
        );
    }
  };

  return (
    <Container>
      <Row>
        <Typography variant="body2">
          <strong>{`${actions.length} outstanding action${
            actions.length > 1 ? 's' : ''
          }`}</strong>
        </Typography>
        <Spacer />
        <FilledBadge>-{points} pts</FilledBadge>
      </Row>
      <Gap size={8} />
      <Button
        variant="text"
        className="title"
        onClick={() => {
          if (isChartPage && node != null) {
            dispatch(setSelectedNode(node));
            RcaUtil.snapFocusToNode(node);
          } else {
            navigate(`/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}`);
          }
        }}
      >
        {label}
      </Button>
      <ul className="actions-list">
        {actions.map((action) => (
          <li key={uuid()} onClick={() => onActionClick(action)}>
            <div className="title">{getLabel(action)}</div>
            <Spacer />
            <FilledBadge>+{action.points} pts</FilledBadge>
          </li>
        ))}
      </ul>
    </Container>
  );
}
