import { styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty, truncateString } from '@util/string-util';
import Row from '@components/layout-util-components/row';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { BorderedContainer } from '@components/containers/bordered-container';
import { Gap } from '@components/layout-util-components/gap';
import OutcomeSeverityBadge from '@components/outcome-report/components/outcome-severity-badge';
import EvidenceStrengthBadge from '@components/outcome-report/components/evidence-strength-badge';
import Spacer from '@components/layout-util-components/spacer';
import FileIndicator from '@components/outcome-report/components/file-indicator';

interface Props {
  evidence: CaseEvidenceResource;
  trailing?: ReactNode;
}

const Container = styled(BorderedContainer)(({ theme: { palette } }) => ({
  justifyContent: 'flex-start',
  '.name': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    marginRight: '1rem',
  },
  '.caption': {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#636D73',
    marginRight: '1rem',
  },

  '.associated-chain-items': {
    borderRadius: 4,
    background: palette.common.grey20,
    color: palette.common.black,
    padding: 4,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    alignSelf: 'flex-start',
  },
  '.scoreRow': {
    display: 'flex',

    '> *': {
      flex: '0 0 50%',
    },
    '.scoreContainer': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '.label': {
        color: '#949FA2',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      },
    },
  },
}));

export default function CaseEvidenceCard({ evidence, trailing }: Props) {
  console.log(evidence);
  return (
    <Container>
      <Column sx={{ flexGrow: 1 }}>
        <Typography className="name">
          {truncateString(evidence.name, 60)}
        </Typography>
        {!isNullOrEmpty(evidence.description) ? (
          <>
            <Gap size={8} />
            <Typography className="caption">
              {truncateString(evidence.description, 90)}
            </Typography>
          </>
        ) : null}
        {evidence.chainItems != null && evidence.chainItems.length > 0 ? (
          <>
            <Gap size={15} />
            <AssociatedCauseBoxBadge count={evidence.chainItems.length} />
          </>
        ) : null}
        <Gap size={15} />
        <Row className="scoreRow">
          <span className="scoreContainer">
            <span className="label severity">Strength</span>
            <EvidenceStrengthBadge strength={evidence.quality.toString()} />
          </span>
          <span className="scoreContainer">
            <span className="label severity">Sensitivity</span>
            <OutcomeSeverityBadge severity={evidence.sensitivity} />
          </span>
        </Row>
      </Column>
      <Column
        sx={{
          alignSelf: 'stretch',
          alignItems: 'center',
        }}
      >
        {trailing != null ? trailing : null}
        <Spacer />
        <FileIndicator count={evidence.caseFiles?.length} />
      </Column>
    </Container>
  );
}
