import { useNavigate } from 'react-router-dom';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import useUser from '@store/user/user-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { useDeleteCaseMutation } from '@api/endpoints/case.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';

export function usePersonalCaseOptions(
  caseId: number,
  createBy: number,
  isDraft: boolean,
  caseAnalysisId: number
) {
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [deleteCase] = useDeleteCaseMutation();

  const navigate = useNavigate();

  const { isAdminOrSuperAdminUser, companyUserId } = useUser();
  const canDelete = isAdminOrSuperAdminUser || createBy === companyUserId;

  const onDeleteClick = () => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'RCA',
      onDelete: async () => {
        try {
          await deleteCase(caseId).unwrap();
          showSuccessMessage('Case deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ caseId: number }>;
          showErrorMessage(errors?.caseId ?? message);
          return false;
        }
      },
    });
  };

  const CaseElipsisOptions: Array<PopoverMenuAction> = !isDraft
    ? [
        {
          label: 'Open Analysis',
          onClick: () => {
            navigate(`/rca/tabs/${caseId}/chart`);
          },
        },
        {
          label: 'Delete',
          hasPermission: canDelete,
          onClick: onDeleteClick,
        },
      ]
    : [
        {
          label: 'Complete',
          onClick: () => {
            navigate(
              `/rca/create/steps/?type=${caseAnalysisId}&step=0&caseId=${caseId}`
            );
          },
        },
        {
          label: 'Delete',
          onClick: onDeleteClick,
        },
      ];

  return CaseElipsisOptions;
}
