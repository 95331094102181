import {
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@mui/material';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { Divider } from '@components/layout-util-components/divider';
import {
  PopoverMenuAction,
  PopoverMenuState,
} from '@components/popover-menu/use-popover-menu';

export default function PopoverMenu({
  id,
  target,
  isShown,
  actionsRef,
  close,
}: PopoverMenuState) {
  const renderAction = (action: PopoverMenuAction) => {
    const key = uuid();

    if ('divider' in action) {
      return <Divider key={key} />;
    } else if ('label' in action) {
      const { label, onClick, hasPermission, icon } = action;
      if (hasPermission != null && !hasPermission) {
        return null;
      }

      return (
        <MenuItem
          key={uuid()}
          onClick={(e) => {
            e.stopPropagation();
            close();
            onClick();
          }}
        >
          {icon}
          <ListItemText sx={{ ml: icon ? 2 : 0 }}>{label}</ListItemText>
        </MenuItem>
      );
    }
  };

  if (target == null) {
    return <></>;
  }

  return (
    <Popover
      id={id}
      open={isShown}
      onClick={(e) => {
        const className = (e.target as any)?.className as string | undefined;
        if (
          (className != null && className.indexOf('MuiBackdrop-root') !== -1) ??
          false
        ) {
          e.stopPropagation();
        }
      }}
      anchorEl={target}
      onClose={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper sx={{ minWidth: 150 }}>
        <MenuList>{actionsRef.current.map(renderAction)}</MenuList>
      </Paper>
    </Popover>
  );
}
