import { LinearProgress, styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';
import { RcaUtil } from '@util/rca-util';

interface Props {
  score: number;
}

const Container = styled('div', { target: 'health-score-bar' })(
  ({ theme: { palette } }) => ({
    background: 'rgba(69,197,150,0.12)',
    border: `1px solid ${palette.success.main}`,
    borderRadius: 5,
    padding: '15px',

    '.info': {
      fontFamily: 'Usual',
      fontSize: '12px',
      color: '#636D73',
      fontWeight: '500',
      lineHeight: '12px',
      letterSpacing: '0.17000000178813934px',
      marginBottom: '10px',

      '&.score': {
        fontSize: '14px',
        color: palette.success.main,
      },
    },

    '.MuiLinearProgress-root': {
      height: 9,
      borderRadius: 10,
      background: 'rgba(62, 132, 107, 0.30)',
      '>span': {
        borderRadius: 10,
        background: palette.success.main,
      },
    },

    '&.average': {
      background: 'rgba(255, 108, 0, 0.12)',
      border: `1px solid ${palette.warning.main}`,
      '.info': {
        '&.score': {
          color: palette.warning.main,
        },
      },
      '.MuiLinearProgress-root': {
        background: 'rgba(255, 108, 0, 0.04)',
        '>span': {
          background: palette.warning.main,
        },
      },
    },
    '&.not-applicable': {
      background: 'none',
      border: '1px solid #D8D8E3',
      '.info': {
        '&.score': {
          color: '#636D73',
        },
      },
      '.MuiLinearProgress-root': {
        background: 'rgba(255, 108, 0, 0.3)',
        '>span': {
          background: palette.warning.main,
        },
      },
    },
    '&.unhealthy': {
      background: 'rgba(255,61,61,0.12)',
      border: `1px solid ${palette.error.main}`,
      '.info': {
        '&.score': {
          color: palette.error.main,
        },
      },
      '.MuiLinearProgress-root': {
        background: 'rgba(255,61,61,0.3)',
        '>span': {
          background: palette.error.main,
        },
      },
    },
  })
);

export default function HealthScoreBar({ score }: Props) {
  return (
    <Container className={RcaUtil.getHealthStateFromScore(score ?? -1)}>
      <Row>
        <span className="info">HEALTH SCORE</span>
        <Spacer />
        <span className="info score">
          {score < 0 ? 'N/A' : `${score.toFixed(0)}%`}
        </span>
      </Row>
      {score >= 0 && (
        <>
          <Gap size={12} />
          <LinearProgress variant="determinate" value={score} />
        </>
      )}
    </Container>
  );
}
