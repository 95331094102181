import { SystemTextKey } from '@api/types/system-text/system-text';
import { useAppDispatch, useAppSelector } from '@store/store';
import { loadSystemText } from '@store/system-text/system-text-actions';
import { useEffect, useMemo } from 'react';

export default function useSystemText() {
  const dispatch = useAppDispatch();
  const {
    init,
    loading,
    systemText: systemTextObj,
  } = useAppSelector((state) => state.systemText);

  useEffect(() => {
    if (!init && !loading) {
      dispatch(loadSystemText());
    }
  }, [dispatch, init, loading]);

  const isReady = init && !loading;

  const systemText = useMemo(() => {
    const flattenedObject: Record<SystemTextKey, string> = {} as Record<
      SystemTextKey,
      string
    >;

    systemTextObj?.forEach((item) => {
      flattenedObject[item.systemTextKey] = item.text;
    });

    return flattenedObject;
  }, [systemTextObj]);

  return {
    systemText,
    isReady,
  };
}
