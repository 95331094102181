import {
  StyledOnboardingBody,
  StyledOnboardingHeader,
} from '@components/onboarding/styles';
import HeaderImage from '@assets/images/onboarding/step-3.png';
import { Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { LinkButton, OutlinedButton } from '@components/buttons';
import { StepProps } from '@components/onboarding/app-onboarding-modal';
import { Gap } from '@components/layout-util-components/gap';
const OnboardingStep3 = '/svgs/onboarding_step3.svg';

export function StepThreeHeader() {
  return (
    <StyledOnboardingHeader
      className="step3"
      sx={({ palette }) => ({
        background: `url(${OnboardingStep3}) no-repeat center center #FFF9EF`,
      })}
    >
      <img src={HeaderImage} alt="step three header" />
    </StyledOnboardingHeader>
  );
}

export function StepThreeBody({ onSkip, onNext }: StepProps) {
  return (
    <StyledOnboardingBody>
      <div className="content">
        <Gap size={32} />
        <Typography variant="h2">Enhance your RCA skillset</Typography>
        <Gap size={15} />
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          consectetur nisi sit amet risus luctus, at ornare odio aliquet.
          Integer orci ante, porttitor eu ipsum sed, finibus ultricies odio.
        </Typography>
        <Gap size={32} />
        <Spacer />
      </div>
      <div className="actions">
        <OutlinedButton onClick={onNext}>Next</OutlinedButton>
        <LinkButton onClick={onSkip}>Skip</LinkButton>
      </div>
    </StyledOnboardingBody>
  );
}
