import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import TasksIndexPage from '@pages/app/tasks/tasks-index-page';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';

function RcaEditorTasks() {
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  return (
    <div style={{ width: '100%' }}>
      {/* Simply embed the task page here */}
      <TasksIndexPage caseId={caseId} />
    </div>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorTasks));
