import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { ChainItemEvidenceRequest } from '@api/types/chain/chain-item-evidence/chain-item-evidence.request';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { LinkEvidenceRequest } from '@api/types/chain/chain-item-evidence/link-evidence.request';
import { makeError } from '@api/types/api-error';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

export interface ChainItemEvidencePathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemEvidenceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemEvidence: build.query<
      PaginatedResult<CaseEvidenceResource>,
      ChainItemEvidencePathParams & ChainItemEvidenceRequest
    >({
      query: ({ chainId, chainItemId, ...params }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/evidence`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map((data) => ({
                id: data.evidenceId,
                type: 'Evidence' as const,
              })),
              'Evidence',
            ]
          : [],
    }),
    linkEvidence: build.mutation<void, LinkEvidenceRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/linkEvidence`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError<LinkEvidenceRequest>(error),
      invalidatesTags: (_, error, request) =>
        !error
          ? [
              { id: request.evidenceId, type: 'Evidence' as const },
              'Evidence',
              'CaseTotals',
            ]
          : [],
    }),
    unlinkEvidence: build.mutation<void, LinkEvidenceRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/unlinkEvidence`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError<LinkEvidenceRequest>(error),
      invalidatesTags: (_, error, request) =>
        !error
          ? [
              { id: request.evidenceId, type: 'Evidence' as const },
              'Evidence',
              'CaseTotals',
            ]
          : [],
    }),
  }),
});

export const {
  useGetChainItemEvidenceQuery,
  useLinkEvidenceMutation,
  useUnlinkEvidenceMutation,
} = chainItemEvidenceApi;

export default chainItemEvidenceApi;
