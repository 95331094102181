import { styled } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator/index';

const Container = styled('div')({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
});

export default function FullScreenLoadingIndicator() {
  return (
    <Container>
      <LoadingIndicator show />
    </Container>
  );
}
