import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import ContentContainer from '@components/containers/content-container';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorPathParams } from '@pages/app/rca/tabs/editor-path-params';
import { useGetCaseDetailQuery } from '@api/endpoints/case.api';
import { InlineBoxSkeleton } from '@components/skeletons';
import React, { ComponentType, useEffect, useState } from 'react';
import {
  NavTabContainer,
  NavTabHeader,
} from '@components/menu-items/app/nav-tab-header';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useDirtyGuard from '@hooks/use-dirty-guard';
import OverviewStep from '@pages/app/rca/create/steps/overview-step';
import ImpactStep from '@pages/app/rca/create/steps/impact-step';
import CollaborationStep from '@pages/app/rca/create/steps/collaboration-step';
import { useAppDispatch } from '@store/store';
import { setCurrentCase } from '@store/create-analysis-process/create-analysis-slice';
import { numberFromString } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';
import { useCasePermission } from '@hooks/case/use-case-permission';

export enum SelectedCaseEditTab {
  overview = 'overview',
  impacts = 'impacts',
  collaboration = 'collaboration',
}

interface QueryParams {
  initialTab?: SelectedCaseEditTab;
}

const TabMap: { [k in SelectedCaseEditTab]: ComponentType<RcaStepProps> } = {
  [SelectedCaseEditTab.overview]: OverviewStep,
  [SelectedCaseEditTab.impacts]: ImpactStep,
  [SelectedCaseEditTab.collaboration]: CollaborationStep,
};

function EditCasePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { confirmDirty } = useDirtyGuard();
  const { initialTab } = useSearchParamsTyped<QueryParams>();
  const [tab, setTab] = useState(initialTab ?? SelectedCaseEditTab.overview);
  const { canChangeCaseDetails, loading } = useCasePermission();

  const { caseId } = useParams<EditorPathParams>();
  const caseIdNumber = numberFromString(caseId);
  const { data, isSuccess } = useGetCaseDetailQuery(
    caseIdNumber != null ? caseIdNumber : 0,
    {
      skip: caseIdNumber == null,
    }
  );

  const CurrentTab = TabMap[tab];

  useEffect(() => {
    if (data == null) {
      return;
    }

    if (!loading && !canChangeCaseDetails) {
      navigate(`/rca/tabs/${caseId}`);
    }

    dispatch(setCurrentCase(data));
  }, [data, dispatch, canChangeCaseDetails, caseId, navigate, loading]);

  return (
    <Column>
      <Gap size={32} />
      <Typography variant="h1">
        {isSuccess ? (
          `Edit ${data!.caseAnalysisTypeName}`
        ) : (
          <InlineBoxSkeleton width={100} />
        )}
      </Typography>
      <Gap size={32} />
      <NavTabContainer>
        <NavTabHeader
          isSelected={tab === SelectedCaseEditTab.overview}
          onClick={confirmDirty(() => setTab(SelectedCaseEditTab.overview))}
        >
          Overview
        </NavTabHeader>
        <NavTabHeader
          isSelected={tab === SelectedCaseEditTab.impacts}
          onClick={confirmDirty(() => setTab(SelectedCaseEditTab.impacts))}
        >
          Impacts
        </NavTabHeader>
        <NavTabHeader
          isSelected={tab === SelectedCaseEditTab.collaboration}
          onClick={confirmDirty(() =>
            setTab(SelectedCaseEditTab.collaboration)
          )}
        >
          Collaboration
        </NavTabHeader>
      </NavTabContainer>

      <CurrentTab
        onGoBack={() => navigate(`/rca/tabs/${caseId}`)}
        onComplete={() => {}}
        caseId={caseIdNumber!}
      />
    </Column>
  );
}

export default requireAuth(
  withEditorScaffold(EditCasePage, { containerClassName: 'rca-edit' })
);
