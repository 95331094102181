import { useGetDialCodesQuery } from '@api/endpoints/country.api';
import useField from '@hooks/use-field-hook';
import { exactLength, onlyNumbers, required } from '@util/validators';
import { useCallback, useEffect, useMemo } from 'react';
import { ApiError } from '@api/types/api-error';
import { RequestMfaOptInCodeRequest } from '@api/types/auth/request-mfa-opt-in-code.request';
import { PhoneNumber } from '@components/input/phone-number-field';
import { useLazyRequestConfirmationCodeQuery } from '@api/endpoints/user.api';
import { MfaMethod } from '@api/types/auth/mfa-method';
import { maskPhoneNumber } from '@util/string-util';
import { usePageAlertVariants } from '@components/alerts';

export default function useChangePhoneNumber(newPhoneNumber: PhoneNumber) {
  const { showErrorMessage } = usePageAlertVariants();

  const [requestCode, { isFetching: isLoading }] =
    useLazyRequestConfirmationCodeQuery();

  const { data: dialCodes } = useGetDialCodesQuery(true);

  const code = useField<string>([required(), onlyNumbers(), exactLength(6)]);

  const canSubmit = useMemo(() => {
    return !isLoading && code.validate(false);
  }, [code, isLoading]);

  const phoneNumber = useMemo(() => {
    const selectedCode = dialCodes!.find(
      (x) => x.id === newPhoneNumber.dialCode
    )!;
    return maskPhoneNumber(selectedCode.label, newPhoneNumber.number!);
  }, [dialCodes, newPhoneNumber]);

  const onResendCode = useCallback(() => {
    requestCode({
      method: MfaMethod.sms,
      to: newPhoneNumber.number!,
      countryDialCode: newPhoneNumber.dialCode!,
    })
      .unwrap()
      .catch(({ message, errors }: ApiError<RequestMfaOptInCodeRequest>) => {
        showErrorMessage(message ?? errors?.phoneNumber);
      });
  }, [
    requestCode,
    newPhoneNumber.number,
    newPhoneNumber.dialCode,
    showErrorMessage,
  ]);

  useEffect(() => {
    onResendCode();
  }, [onResendCode]);

  return {
    onResendCode,
    code,
    phoneNumber,
    canSubmit,
    isLoading,
  };
}
