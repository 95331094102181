import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import SubpageTitle from '@components/menu-items/subpage-title';
import WCTSwitchField from '@components/input/switch-field';
import useMfaSetup from '@pages/app/profile/settings/profile-mfa-setup-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import SetupMfaForm, {
  SetupMfaFormProps,
} from '@pages/app/profile/settings/forms/setup-mfa-form';
import { InlineBoxSkeleton } from '@components/skeletons';
import PaperContainer from '@components/containers/paper-container';
import { ReactComponent as MfaIcon } from '@assets/svgs/company-2fa-icon.svg';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SwitchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  background: 'rgba(0, 0, 0, 0.2)',
});

function ProfileMfaSetupPage() {
  const { t } = useAppTr('mfaSettings');
  const { showConfirmationModal, showPopup } = useUiPopup();

  const { isOn, disable2FA, isCompanyMfaEnforced, isLoading } = useMfaSetup();

  const onToggleSetting = async () => {
    // We're about to toggle OFF
    if (isOn.value) {
      const didDisable = await showConfirmationModal({
        title: t('disableModel.title'),
        message: t('disableModel.message'),
        noButtonLabel: t('disableModel.noButtonLabel'),
        yesButtonLabel: t('disableModel.yesButtonLabel'),
        yesButtonAsyncAction: disable2FA,
      });

      if (didDisable) {
        isOn.set(false);
      }
    } else {
      const shouldEnable = await showConfirmationModal({
        title: t('enableModal.title'),
        message: t('enableModal.message'),
        noButtonLabel: t('enableModal.noButtonLabel'),
        yesButtonLabel: t('enableModal.yesButtonLabel'),
      });

      if (shouldEnable) {
        const didEnable = await showPopup<SetupMfaFormProps, boolean>(
          SetupMfaForm
        );

        if (didEnable) {
          isOn.set(true);
        }
      }
    }
  };

  return (
    <Container>
      <SubpageTitle breadCrumb={t('breadCrumb')} title={t('title')} />
      <Gap size={32} />
      {isCompanyMfaEnforced ? (
        <PaperContainer>
          <MfaIcon />
          <Gap size={32} />
          <Typography variant="h5" component="p">
            {t('companyEnforcedMessage')}
          </Typography>
        </PaperContainer>
      ) : (
        <Grid container>
          <Grid item xs={6} flexDirection="column">
            <Typography variant="body1">{t('sectionLabel')}</Typography>
            <Typography variant="body2">{t('sectionMessage')}</Typography>
          </Grid>
          <Grid item xs={6} flexDirection="column">
            <SwitchContainer>
              {isLoading ? (
                <InlineBoxSkeleton />
              ) : (
                <WCTSwitchField
                  name="2fa-toggle"
                  label={t(isOn.value ? 'toggle.on' : 'toggle.off')}
                  value={isOn.value}
                  error={isOn.error}
                  onChange={onToggleSetting}
                  onBlur={isOn.validate}
                />
              )}
            </SwitchContainer>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default requireAuth(ProfileMfaSetupPage);
