import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SystemText } from '@api/types/system-text/system-text';

export interface SystemTextState {
  init: boolean;
  loading: boolean;
  systemText?: SystemText[];
}

const initialState: SystemTextState = {
  init: false,
  loading: false,
  systemText: [],
};

export const createSystemTextSlice = createSlice({
  name: 'create-analysis-slice',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setInit(state, action: PayloadAction<boolean>) {
      state.init = action.payload;
    },
    setSystemText(state, action: PayloadAction<SystemText[]>) {
      state.systemText = action.payload;
      state.init = true;
      state.loading = false;
    },
  },
});

export default createSystemTextSlice.reducer;

export const { setLoading, setInit, setSystemText } =
  createSystemTextSlice.actions;
