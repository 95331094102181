import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import ShareOutcomeModal, {
  ShareOutcomeModalProps,
} from '@pages/app/rca/outcome/modals/share-outcome-modal';
import { OutlinedButton, PrimaryButton } from '@components/buttons';

interface Props {
  caseId: number;
  outcomeId: number;
  filled?: boolean;
}

export default function ShareButton({ caseId, outcomeId, filled }: Props) {
  const { showPopup } = useUiPopup();

  const onClick = async () => {
    await showPopup<ShareOutcomeModalProps, boolean>(ShareOutcomeModal, {
      caseId,
      outcomeId,
    });
  };

  const Component = filled ? PrimaryButton : OutlinedButton;

  return <Component onClick={onClick}>Share</Component>;
}
