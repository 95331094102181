import { api } from '@api/api';
import { Option } from '@api/types/option';

const solutionTermApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSolutionTermOptions: build.query<Option[], void>({
      query: () => ({
        url: 'solutionTerm/options',
      }),
    }),
  }),
});

export const { useGetSolutionTermOptionsQuery } = solutionTermApi;
