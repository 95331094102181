import { useGetWACompanyDetailQuery } from '@api/endpoints/wct-admin/wct-admin-company.api';
import { Button, Grid, styled, Typography, useTheme } from '@mui/material';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { PrimaryButton } from '@components/buttons';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import LabelValueDisplay from '@components/data/label-value-display';
import Column from '@components/layout-util-components/column';
import HealthBadge from '@components/badges/health-badge';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { ErrorAlert } from '@components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import Spacer from '@components/layout-util-components/spacer';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import WAManageSubscriptionForm from '@pages/wct-admin/companies/components/forms/wa-manage-subscription-form';

export interface WAViewCompanyProps extends SideDrawerFormProps<never> {
  companyId: number;
  companyName: string;
}

const Container = styled(DrawerContent)({});

function WACompanyTableRow({
  companyId,
  companyName,
  onClose,
}: WAViewCompanyProps) {
  const { showPopup } = useUiPopup();
  const { palette } = useTheme();
  const { data, isLoading, isFetching } = useGetWACompanyDetailQuery(companyId);
  const subscription = data?.subscription;

  const isEndDateInPast =
    subscription?.endDate != null &&
    dayjs(subscription.endDate).isBefore(dayjs());
  const isNextPaymentDataInPast =
    subscription?.nextPaymentDate != null &&
    dayjs(subscription.nextPaymentDate).isBefore(dayjs());

  const onManageSubscription = () => {
    showPopup(WAManageSubscriptionForm, {
      companyId,
      companyName,
    });
  };

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Row>
          <Typography variant="h5">{companyName}</Typography>
          <Gap size={8} />
          <FetchingIndicator show={isFetching} />
        </Row>
      </DrawerHeader>
      <Container>
        <Column>
          <Gap size={24} />
          {!isLoading &&
            subscription != null &&
            subscription.hasPaymentIssue && (
              <>
                <ErrorAlert message="This company has a payment issue" />
                <Gap size={24} />
              </>
            )}
          <Grid container>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Sector"
                  value={data?.sectorName ?? 'Not set'}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Region"
                  value={data?.regionName ?? 'Not set'}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Health Score"
                  value={
                    data?.healthScore != null ? (
                      <HealthBadge health={data!.healthScore} />
                    ) : (
                      'Not set'
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
          <Divider addMargin />
          <Row>
            <Typography variant="h6">Subscription Info</Typography>
            <Spacer />
            <Button variant="text" size="small" onClick={onManageSubscription}>
              Manage
            </Button>
          </Row>
          <Gap size={15} />
          <Grid container rowSpacing={3}>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Package"
                  value={subscription?.packageName ?? 'Not set'}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Quantity"
                  value={
                    subscription?.quantity != null
                      ? `${subscription!.quantity} seats`
                      : 'Not set'
                  }
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Frequency"
                  value={subscription?.billingFrequency ?? 'Not set'}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Status"
                  value={subscription?.status ?? 'Not set'}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Start Date"
                  value={
                    subscription?.startDate != null
                      ? dateOnlyShortFormatter(subscription!.startDate)
                      : 'Not set'
                  }
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="End Date"
                  value={
                    <span
                      style={{
                        color: isEndDateInPast ? palette.error.main : undefined,
                      }}
                    >
                      {subscription?.endDate != null
                        ? dateOnlyShortFormatter(subscription!.endDate)
                        : 'Not set'}
                    </span>
                  }
                />
              )}
            </Grid>

            <Grid item xs={4}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Free Trial End"
                  value={
                    subscription?.freeTrialEnd != null
                      ? dateOnlyShortFormatter(subscription!.freeTrialEnd)
                      : 'Not set'
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Next Payment Date"
                  value={
                    <span
                      style={{
                        color: isNextPaymentDataInPast
                          ? palette.error.main
                          : undefined,
                      }}
                    >
                      {subscription?.nextPaymentDate != null
                        ? dateOnlyShortFormatter(subscription!.nextPaymentDate)
                        : 'Not set'}
                    </span>
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label="Has payment method"
                  value={
                    subscription!.hasPaymentMethod ? (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        color={palette.success.main}
                        size="2x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        color={palette.error.main}
                        size="2x"
                      />
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
        </Column>
      </Container>
      <DrawerFooter>
        <PrimaryButton onClick={() => onClose()}>Close</PrimaryButton>
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(WACompanyTableRow);
