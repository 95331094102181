import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyCaseImpactTypes from './company-case-impact-types-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

function CompanyCaseImpactTypesPage() {
  const {
    options,
    loadingCaseImpactTypes,
    enableCaseImpactType,
    disableCaseImpactType,
  } = useCompanyCaseImpactTypes();

  return (
    <>
      <SubpageTitle
        breadCrumb="RCA Settings"
        title="Impact Category Configuration"
      />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingCaseImpactTypes}
        onEnable={enableCaseImpactType}
        onDisable={disableCaseImpactType}
        type="impact category"
      />
    </>
  );
}

export default requireAuth(CompanyCaseImpactTypesPage);
