import { api } from '@api/api';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { ChainUpdateRequest } from '@api/types/chain/chain-update.request';
import { makeError } from '@api/types/api-error';
import { PaginatedResult } from '@api/types/paginated-result';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { TaskDetailListRequest } from '@api/types/task-detail/task-detail-list.request';
import { RcaEdgeType, RcaNodeType } from '@store/rca-editor/types';
import { setChartFromServerData } from '@store/rca-editor/rca-editor-slice';
import { parseChain } from '@util/node-parsing-util';
import { maybeUpdateCache, SHORT_CACHE_DURATION } from '@api/cache-util';
import { ChainMetaDataResponse } from '@api/types/chain/chain-meta-data.response';
import { SetChainEditorRequest } from '@api/types/chain/set-chain-editor-request';
import caseApi from '@api/endpoints/case.api';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';

export type ChainChainItemArgs = {
  chainId: number;
  chainItemId: number;
};

const transformUpdateRequest = (
  body: ChainUpdateRequest
): ChainUpdateRequest => {
  // Need to make sure we don't parse non-saveable node types
  return {
    edges: body.edges.filter((x) => x.type !== RcaEdgeType.connection),
    nodes: body.nodes.filter((x) => x.type !== RcaNodeType.connection),
    moveToStorageChainItemIds: body.moveToStorageChainItemIds,
  };
};

const chainApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainDetail: build.query<ChainDetailResponse, number>({
      query: (chainId) => ({
        url: `chain/${chainId}`,
        method: 'GET',
      }),
      transformResponse: parseChain,
    }),
    getChainMetaData: build.query<ChainMetaDataResponse, number>({
      query: (chainId) => ({
        url: `chain/${chainId}/metaData`,
        method: 'GET',
      }),
      providesTags: ['CaseTotals'],
    }),
    updateChain: build.mutation<
      ChainDetailResponse,
      ChainUpdateRequest & { chainId: number }
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/updateGraph`,
        method: 'POST',
        body: transformUpdateRequest(body),
      }),
      invalidatesTags: ['CaseTotals'],
      transformErrorResponse: (error) => makeError<ChainUpdateRequest>(error),
      transformResponse: parseChain,
      onQueryStarted: async ({ chainId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setChartFromServerData(data));
        dispatch(
          chainApi.util.updateQueryData('getChainDetail', chainId, (draft) => {
            Object.assign(draft, data);
          })
        );
      },
    }),
    getTasksForChainItem: build.query<
      PaginatedResult<TaskDetailListItemResponse>,
      TaskDetailListRequest & ChainChainItemArgs
    >({
      query: ({ chainId, chainItemId, ...params }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/taskDetail`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : ['TaskDetail'],
    }),
    setChainEditor: build.mutation<void, SetChainEditorRequest>({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/setChainEditor`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        const patches = maybeUpdateCache('CaseUser', undefined, [
          {
            api: caseApi,
            endpoint: caseApi.endpoints.getUsersForCase,
            callback: (d: PaginatedResult<CaseUserResource>) => {
              d.model.forEach((x) => {
                x.isChainEditor = x.companyUserId === arg.companyUserId;
              });
            },
          },
          {
            api: caseApi,
            endpoint: caseApi.endpoints.getCaseDetailsForMe,
            callback: (user: CaseUserResource) => {
              user.isChainEditor = user.companyUserId === arg.companyUserId;
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
      transformErrorResponse: (error) =>
        makeError<SetChainEditorRequest>(error, {
          chainEditorCompanyUserId: 'companyUserId',
          caseId: 'chainId',
        }),
      invalidatesTags: (_, error) => (!error ? ['Case'] : []),
    }),
  }),
});

export const { useGetTasksForChainItemQuery, useSetChainEditorMutation } =
  chainApi;

export default chainApi;
