import { useTranslation } from 'react-i18next';

export const useAuthTr = (
  keyPrefix:
    | 'common'
    | 'acceptInvite'
    | 'confirmEmailRequest'
    | 'createAccount'
    | 'createAccountProgress'
    | 'createCompany'
    | 'createPassword'
    | 'forgotPassword'
    | 'login'
    | 'mfa'
    | 'resetPassword'
) => useTranslation('auth', { keyPrefix });
