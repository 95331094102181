import { ComponentType, useCallback, useState } from 'react';

export interface StepperStep<T = unknown> {
  title: string;
  onClick?: () => void;
  Component: ComponentType<T>;
}

export default function useStepper<T = unknown>(
  steps: Array<StepperStep<T>>,
  setStepOnClick = false
) {
  const [currentStepIndex, setCurrentStep] = useState(0);

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  const moveNext = useCallback(() => {
    if (!isLastStep) {
      setCurrentStep((v) => v + 1);
    }
  }, [isLastStep]);

  const moveBack = useCallback(() => {
    if (!isFirstStep) {
      setCurrentStep((v) => v - 1);
    }
  }, [isFirstStep]);

  if (setStepOnClick) {
    steps = steps.map((x, i) => ({
      ...x,
      ...{ onClick: () => setCurrentStep(i) },
    }));
  }

  return {
    currentStepIndex,
    isLastStep,
    isFirstStep,
    moveNext,
    moveBack,
    steps,
    setCurrentStep,
    currentStep: steps[currentStepIndex],
  };
}

export type StepperState<T = unknown> = ReturnType<typeof useStepper<T>>;
