import { api } from '@api/api';
import { ChainItemActionType } from '@api/types/chain/chain-item-health-score/chain-item-action.response';
import { Option } from '@api/types/option';

const chainItemActionTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemActionTypeOptions: build.query<
      Option<ChainItemActionType>[],
      number
    >({
      query: (chainId) => ({
        url: `chain/${chainId}/chainItemActionType/options`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetChainItemActionTypeOptionsQuery } = chainItemActionTypeApi;

export default chainItemActionTypeApi;
