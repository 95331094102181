import { RefObject } from 'react';
import { CircularProgress, styled } from '@mui/material';

interface Props {
  chartRef: RefObject<HTMLDivElement>;
}

const Container = styled('div')<{ chart: HTMLDivElement }>(
  ({ chart, theme: { palette } }) => ({
    position: 'fixed',
    zIndex: 100,
    top: chart.offsetTop,
    left: chart.offsetLeft,
    width: chart.offsetWidth,
    height: chart.offsetHeight,
    backgroundColor: palette.background.default,
    pointerEvents: 'all',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export default function GraphInitialLoadContainer({ chartRef }: Props) {
  const chart = chartRef.current;
  if (chart == null) {
    return null;
  }

  return (
    <Container
      chart={chart}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CircularProgress />
    </Container>
  );
}
