import { api } from '@api/api';
import { UpdateAllSystemTextRequest } from '@api/types/system-text/update-all-system-text.request';
import { loadSystemText } from '@store/system-text/system-text-actions';

const companySystemTextApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCompanySystemText: build.mutation<void, UpdateAllSystemTextRequest>({
      query: (body: UpdateAllSystemTextRequest) => ({
        url: 'company/systemText',
        method: 'PUT',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(loadSystemText());
      },
    }),
  }),
});

export const { useUpdateCompanySystemTextMutation } = companySystemTextApi;
