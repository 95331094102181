import FullPageFormScaffold from '@components/layouts/scaffolds/full-page-form-scaffold';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import useCreateOutcome from '@pages/app/rca/outcome/views/create-outcome-hook';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import WCTTextField from '@components/input/text-field';
import { Divider } from '@components/layout-util-components/divider';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import { OutcomeStepProps } from '@pages/app/rca/outcome/rca-outcome-page';
import { InputSkeleton } from '@components/skeletons';
import SwitchFieldSkeleton from '@components/skeletons/switch-field-skeleton';
import useSystemText from '@hooks/use-system-text';
import { Gap } from '@components/layout-util-components/gap';

export default function CreateOutcomeView({
  state,
  onContinue,
  onBack,
}: OutcomeStepProps) {
  const { systemText } = useSystemText();
  const { appBarRef } = state;
  const {
    toggleOverviewMap,
    toggleAnalysis,
    toggleInsights,
    toggleImpacts,
    toggleSolutions,
    toggleEvidence,
    toggleEvidenceDetail,
    toggleTeam,
    name,
    submit,
    isBusy,
    isLoading,
    canSubmit,
  } = useCreateOutcome(state);

  return (
    <FullPageFormScaffold
      appBarRef={appBarRef}
      onSubmit={async () => {
        if (await submit()) {
          onContinue();
        }
      }}
    >
      <Gap size={128} />
      <Typography variant="h1">Create an outcome</Typography>
      <Gap size={20} />
      <Typography variant="body2">
        Outcome reports allow you to share selected elements of your RCA. You
        can edit this outcome report or create alternative versions.
      </Typography>
      <Gap size={64} />
      <Grid container>
        <Grid container item xs={6}>
          <div>
            <Typography variant="h2">Name this outcome</Typography>
            <Typography variant="body2">
              Give your outcome report a unique name
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="name"
              label="Name"
              value={name.value}
              error={name.error}
              onChange={name.set}
              maxLength={100}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid container item xs={6}>
          <div>
            <Typography variant="h2">Configure outcome</Typography>
            <Typography variant="body2">
              Select RCA elements to include in your outcome report
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={6} direction="column" gap={1}>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleOverviewMap"
                label="Overview Map"
                value={toggleOverviewMap.value}
                error={toggleOverviewMap.error}
                onChange={toggleOverviewMap.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleImpacts"
                label="Impacts"
                value={toggleImpacts.value}
                error={toggleImpacts.error}
                onChange={toggleImpacts.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleTeam"
                label="Team"
                value={toggleTeam.value}
                error={toggleTeam.error}
                onChange={toggleTeam.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleAnalysis"
                label="Analysis"
                value={toggleAnalysis.value}
                error={toggleAnalysis.error}
                onChange={toggleAnalysis.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleSolutions"
                label="Solutions"
                value={toggleSolutions.value}
                error={toggleSolutions.error}
                onChange={toggleSolutions.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleEvidence"
                label="Evidence"
                value={toggleEvidence.value}
                error={toggleEvidence.error}
                onChange={toggleEvidence.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleEvidenceDetail"
                label="Evidence Detail"
                value={toggleEvidenceDetail.value}
                error={toggleEvidenceDetail.error}
                onChange={toggleEvidenceDetail.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleLenses"
                label="Lenses"
                value={toggleInsights.value}
                error={toggleInsights.error}
                onChange={toggleInsights.set}
              />
            )}
          </SwitchContainer>
        </Grid>
      </Grid>
      <ActionsRow anchorBottom addPadding filled>
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Spacer />
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Continue
        </PrimaryButton>
      </ActionsRow>
    </FullPageFormScaffold>
  );
}
