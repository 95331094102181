import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import { LoadingIndicator } from '@components/loading-indicator';
import useImpactsPanel, {
  ImpactsPanelView,
} from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import SelectImpacts from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/select-impacts';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import ImpactInfoPanelForm from '@pages/app/rca/create/forms/impact-info-panel-form';
import { OutlinedButton } from '@components/buttons';

export default function ImpactsPanel() {
  const state = useImpactsPanel();
  const {
    impacts,
    isFetching,
    isLoading,
    view,
    caseId,
    onBeginAddingImpacts,
    onCancelAddingImpacts,
    canContribute,
    hasNoImpacts,
  } = state;

  return (
    <Column>
      <EditPanelHeader
        title="Impacts"
        count={view === ImpactsPanelView.initial ? impacts?.length : undefined}
        end={
          <Row>
            <FetchingIndicator show={isFetching} />
          </Row>
        }
      />
      {canContribute && !hasNoImpacts && view === ImpactsPanelView.initial ? (
        <>
          <Gap size={24} />
          <OutlinedButton onClick={onBeginAddingImpacts}>
            Create Impact
          </OutlinedButton>
        </>
      ) : null}
      <Gap size={24} />
      {isLoading && <LoadingIndicator show xOffset={-68} />}
      {!isLoading && view === ImpactsPanelView.initial && (
        <SelectImpacts state={state} />
      )}
      {!isLoading && view === ImpactsPanelView.createImpact && (
        <ImpactInfoPanelForm
          caseId={caseId}
          onSuccess={onCancelAddingImpacts}
          onCancel={onCancelAddingImpacts}
        />
      )}
    </Column>
  );
}
