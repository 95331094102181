import { api } from '@api/api';
import { CaseAnalysisType } from '@api/types/case-analysis-type/case-analysis-type';

const companyCaseAnalysisTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseAnalysisType: build.query<CaseAnalysisType[], void>({
      query: () => ({
        url: 'company/CaseAnalysisType',
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.map((data) => ({
                id: data.caseAnalysisTypeId,
                type: 'CaseAnalysisType' as const,
              })),
              'CaseAnalysisType',
            ]
          : [],
    }),
    companyEnableCaseAnalysisType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseAnalysisType/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseAnalysisType' as const }] : [],
    }),
    companyDisableCaseAnalysisType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseAnalysisType/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseAnalysisType' as const }] : [],
    }),
  }),
});

export const {
  useGetCompanyCaseAnalysisTypeQuery,
  useCompanyEnableCaseAnalysisTypeMutation,
  useCompanyDisableCaseAnalysisTypeMutation,
} = companyCaseAnalysisTypeApi;
