import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '@components/layouts/auth-layout';
import ConfirmEmailRequestPage from '@pages/auth/confirm-email-request-page';
import CreatePasswordPage from '@pages/auth/create-password-page';
import CreateAccountProgressPage from '@pages/auth/create-account-progress-page';
import LoginPage from '@pages/auth/login-page';
import ForgotPasswordPage from '@pages/auth/forgot-password-page';
import ResetPasswordPage from '@pages/auth/reset-password-page';
import MfaPage from '@pages/auth/mfa-page';
import LoginErrorPage from '@pages/auth/login-error-page';
import AcceptInvitePage from '@pages/auth/accept-invite-page';
import App from 'src/app';
import LogoutCapturePage from '@pages/auth/logout-capture-page';
import ErrorRouteCatcher from '@components/error-handling/error-route-catcher';
import WCTAdminLayout from '@components/layouts/wct-admin-layout';
import ProfilePageLayout from '@components/layouts/app/profile-layout';
import ProfileSettingsIndexPage from '@pages/app/profile/settings/profile-settings-index-page';
import ProfileUpdatePasswordPage from '@pages/app/profile/settings/profile-update-password-page';
import WACompaniesIndexPage from '@pages/wct-admin/companies/wa-companies-index-page';
import WaInstallsIndexPage from '@pages/wct-admin/installs/wa-installs-index-page';
import WADashboard from '@pages/wct-admin/dashboard/wa-dashboard';

const wctAdminRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorRouteCatcher />,
    children: [
      {
        path: '/',
        element: <WCTAdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            index: true,
            element: <WADashboard />,
          },
        ],
      },
      {
        path: 'companies',
        element: <WCTAdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <WACompaniesIndexPage /> }],
      },
      {
        path: 'installs',
        element: <WCTAdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <WaInstallsIndexPage /> }],
      },
      {
        path: 'profile',
        element: <WCTAdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            element: <ProfilePageLayout />,
            children: [
              { index: true, element: <ProfileSettingsIndexPage /> },
              { path: 'password', element: <ProfileUpdatePasswordPage /> },
            ],
          },
        ],
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          { path: 'logout', element: <LogoutCapturePage /> },
          {
            path: 'confirm-email-request',
            element: <ConfirmEmailRequestPage />,
          },
          {
            path: 'accept-invite',
            element: <AcceptInvitePage />,
          },
          {
            path: 'create-password',
            element: <CreatePasswordPage />,
          },
          {
            path: 'create-account-progress',
            element: <CreateAccountProgressPage />,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'login-error',
            element: <LoginErrorPage />,
          },
          {
            path: 'mfa',
            element: <MfaPage />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
]);

export default wctAdminRoutes;
