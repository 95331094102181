import { useState } from 'react';
import useField from '@hooks/use-field-hook';
import { equals, required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export enum DeleteConfirmationViewState {
  yesNo,
  confirm,
}

export default function useDeleteConfirmation(
  onDelete: () => Promise<boolean>
) {
  const [isBusy, setIsBusy] = useState(false);
  const [viewState, setViewState] = useState(DeleteConfirmationViewState.yesNo);

  const deleteConfirmText = useField<string>([required(), equals('DELETE')]);

  const { isValid, validateAll, isDirty } = useFieldsWatcher([
    deleteConfirmText,
  ]);

  const onConfirmYes = () => {
    console.assert(viewState === DeleteConfirmationViewState.yesNo);
    setViewState(DeleteConfirmationViewState.confirm);
  };

  const onSubmitConfirm = async () => {
    console.assert(viewState === DeleteConfirmationViewState.confirm);

    if (!validateAll()) {
      return false;
    }

    try {
      setIsBusy(true);

      const didDelete = await onDelete();
      if (!didDelete) {
        setIsBusy(false);
      }

      return didDelete;
    } catch (_) {
      setIsBusy(false);
      return false;
    }
  };

  const canSubmitConfirm = isValid && isDirty;

  return {
    viewState,
    onConfirmYes,
    deleteConfirmText,
    onSubmitConfirm,
    canSubmitConfirm,
    isBusy,
  };
}
