import { api } from '@api/api';
import { CaseRoleOption } from '@api/types/case-role/case-role-option';

const caseRoleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseRoleOptions: build.query<CaseRoleOption[], void>({
      query: () => ({
        url: 'caseRole/options',
      }),
    }),
  }),
});

export const { useGetCaseRoleOptionsQuery } = caseRoleApi;
