import { Grid, LinearProgress, Typography } from '@mui/material';
import useCreateAccountProgress from '@pages/auth/create-account-progress-hook';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { PageAlertDisplay } from '@components/alerts';
import React from 'react';
import { Gap } from '@components/layout-util-components/gap';

function CreateAccountProgressPage() {
  const { progress } = useCreateAccountProgress();

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h2" style={{ fontSize: '32px' }}>
        Account creation in progress
      </Typography>
      <Typography variant="body1">
        Please wait while we set up your profile
      </Typography>

      <Gap size={2} />
      <PageAlertDisplay />

      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
}

export default requireAuth(CreateAccountProgressPage);
