import { Box, styled, Typography } from '@mui/material';
import FilledBadge from '@components/badges/filled-badge';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import {
  dateOnlyShortFormatter,
  timeOnlyShortFormatter,
} from '@i18n/formatters';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  tag: string;
  title: string;
  createdBy: string;
  createdAt: string; // Datetime
  onClick: () => void;
}

const Container = styled('div')({
  display: 'flex',
  border: '1px solid #D8DBE3',
  background: '#F8F8FA',
  borderRadius: 5,
  padding: '12px 16px',
  cursor: 'pointer',
  '.filled-badge': {
    background: '#FFDABF',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '13px',
  },
});

export default function RecentLenseOption(props: Props) {
  const { tag, createdAt, createdBy, title, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Column>
        <Box>
          <FilledBadge>{tag}</FilledBadge>
        </Box>
        <Gap size={8} />
        <Typography variant="h3">{title}</Typography>
        <Gap size={4} />
        <Typography variant="caption">{`Created by ${createdBy} ${dateOnlyShortFormatter(
          createdAt
        )} | ${timeOnlyShortFormatter(createdAt)}`}</Typography>
      </Column>
      <Spacer />
      <Gap size={15} />
      <Box alignSelf="flex-start" mt={3}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Box>
    </Container>
  );
}
