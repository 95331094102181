import { styled } from '@mui/material';

interface Props {
  noStretch?: boolean;
  centerContent?: boolean;
}

const Column = styled('div')<Props>(({ noStretch, centerContent }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: noStretch ? 'center' : 'stretch',
  justifyContent: centerContent ? 'center' : 'flex-start',
}));

export const ColumnForm = styled('form')<Props>(({ noStretch }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: noStretch ? 'center' : 'stretch',
  minHeight: '100%',
}));

export default Column;
