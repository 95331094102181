import { api } from '@api/api';
import { CaseImpactType } from '@api/types/case-impact-type/case-impact-type';

const companyCaseImpactTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseImpactType: build.query<CaseImpactType[], void>({
      query: () => ({
        url: 'company/CaseImpactType',
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.map((data) => ({
                id: data.caseImpactTypeId,
                type: 'CaseImpactType' as const,
              })),
            ]
          : [],
    }),
    companyEnableCaseImpactType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseImpactType/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpactType' as const }] : [],
    }),
    companyDisableCaseImpactType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseImpactType/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpactType' as const }] : [],
    }),
  }),
});

export const {
  useGetCompanyCaseImpactTypeQuery,
  useCompanyEnableCaseImpactTypeMutation,
  useCompanyDisableCaseImpactTypeMutation,
} = companyCaseImpactTypeApi;
