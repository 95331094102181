import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  PopoverProps,
  styled,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Divider } from '@components/layout-util-components/divider';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';

interface Props {
  actions: Array<PopoverMenuAction>;
  disabled?: boolean;
  className?: string;
  elipsesVariant?: 'one' | 'two';
}

const StyledButton = styled(Button)<{
  elipsesVariant: Props['elipsesVariant'];
}>(({ elipsesVariant, theme: { palette } }) => ({
  display: 'flex',
  width: 32,
  height: 32,
  maxWidth: 32,
  maxHeight: 32,
  minWidth: 32,
  minHeight: 32,
  flex: '0 1 auto',
  color: palette.blue.dark,
  borderRadius: 4,
  border: `1px solid ${palette.blue.dark}`,
  background: palette.common.white,
  svg: {
    color: palette.blue.dark,
  },
  '&:hover': {
    background: palette.blue.dark,
    color: palette.common.white,
    svg: {
      color: palette.common.white,
    },
  },
  ...(elipsesVariant === 'two' && {
    background: '#F3F4F7',
    border: 'none',
  }),
}));

const StyledPopover = styled(Popover)<PopoverProps>({
  '&>.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '200px',
    transform: 'translateX(-84px) !important',
  },
});

export default function ElipsisButton({
  disabled,
  actions,
  className,
  elipsesVariant,
}: Props) {
  const anchorEl = useRef<HTMLButtonElement>();
  const id = useRef(uuid());
  const [open, setOpen] = useState(false);

  const filteredActions = actions.filter((action) => {
    if ('hasPermission' in action) {
      return action.hasPermission == null || action.hasPermission;
    }
    return true;
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    anchorEl.current = e.currentTarget;
    setOpen(true);
  };

  const renderAction = (action: PopoverMenuAction) => {
    const key = uuid();

    if ('divider' in action) {
      return <Divider key={key} addMargin superCompact />;
    } else if ('label' in action) {
      const { label, onClick, hasPermission, icon } = action;
      if (hasPermission != null && !hasPermission) {
        return null;
      }

      return (
        <MenuItem
          key={uuid()}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            onClick();
          }}
        >
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          {label ? <ListItemText>{label}</ListItemText> : null}
        </MenuItem>
      );
    }
  };

  return (
    <>
      {filteredActions.length > 0 && (
        <>
          <StyledButton
            elipsesVariant={elipsesVariant || 'one'}
            type="button"
            className={className}
            disabled={disabled}
            aria-describedby={id.current}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </StyledButton>
          <StyledPopover
            id={id.current}
            open={open}
            onClick={(e) => {
              const className = (e.target as any)?.className as
                | string
                | undefined;
              if (
                (className != null &&
                  className.indexOf('MuiBackdrop-root') !== -1) ??
                false
              ) {
                e.stopPropagation();
              }
            }}
            anchorEl={anchorEl.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList>{filteredActions.map(renderAction)}</MenuList>
          </StyledPopover>
        </>
      )}
    </>
  );
}
