import { WACompanyRow } from '@api/types/wct-admin/wct-admin-company-types';
import { styled, TableCell, TableRow } from '@mui/material';
import GroupedBadges from '@components/containers/grouped-badges';
import HealthBadge from '@components/badges/health-badge';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import WAManageSubscriptionForm from '@pages/wct-admin/companies/components/forms/wa-manage-subscription-form';

interface Props {
  company: WACompanyRow;
  onView: () => void;
}

const Container = styled(TableRow)({});

export default function WACompanyTableRow({ company, onView }: Props) {
  const { showPopup } = useUiPopup();
  const {
    companyName,
    subscriptionName,
    rcaCount,
    clientAdminName,
    tags,
    healthScore,
    companyStatusName,
    created,
  } = company;

  const onManageSubscription = () => {
    showPopup(WAManageSubscriptionForm, {
      companyId: company.companyId,
      companyName,
    });
  };

  return (
    <Container onClick={onView}>
      <TableCell>{companyName}</TableCell>
      <TableCell>{subscriptionName}</TableCell>
      <TableCell>{rcaCount}</TableCell>
      <TableCell>{clientAdminName}</TableCell>
      <TableCell>
        <GroupedBadges items={tags ?? []} />
      </TableCell>
      <TableCell>
        <HealthBadge health={healthScore} />
      </TableCell>
      <TableCell>{companyStatusName}</TableCell>
      <TableCell>{dateOnlyShortFormatter(created)}</TableCell>
      <TableCell>
        <ElipsisButton
          actions={[
            { label: 'View', onClick: onView },
            { label: 'Manage Subscription', onClick: onManageSubscription },
          ]}
        />
      </TableCell>
    </Container>
  );
}
