import { Outlet, useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Logo from '@assets/images/logo.png';
import { PrimaryButton } from '@components/buttons';
import { useCommonTr } from '@i18n/use-common-tr';
import { Gap } from '@components/layout-util-components/gap';

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  backgroundColor: `${palette.common.grey45}80`,
}));

const StyledAppBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 102,
  padding: 21,
  backgroundColor: 'white',
});

const StyledSpacer = styled('div')({
  flex: '1 1 102px',
  minHeight: 24,
});

const ScrollableContainer = styled('div')({
  width: '100vw',
  height: 'calc(100vh - 102px)',
  overflowY: 'auto',
});

const ContentContainer = styled('div')({
  display: 'flex',

  '&>.promo-content': {
    flex: '1 1 auto',
  },

  '&>.outlet-container': {
    backgroundColor: 'white',
    borderRadius: 10,
    width: 500,
    margin: '0 50px',
    padding: 30,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

export default function AppLandingLayout() {
  const navigate = useNavigate();

  const { t: buttonTr } = useCommonTr('buttons');

  return (
    <PageContainer>
      <StyledAppBar>
        <img src={Logo} width={60} height={60} alt="logo" />
        <Box flexGrow={1} minWidth={24} />
        <PrimaryButton color="info" onClick={() => navigate('/auth/login')}>
          {buttonTr('login')}
        </PrimaryButton>
        <Gap size={15} />
        <PrimaryButton onClick={() => {}}>Try it free</PrimaryButton>
      </StyledAppBar>
      <ScrollableContainer>
        <StyledSpacer />
        <ContentContainer>
          <div className="promo-content"></div>
          <div className="outlet-container">
            <Outlet />
          </div>
        </ContentContainer>
        <StyledSpacer />
      </ScrollableContainer>
    </PageContainer>
  );
}
