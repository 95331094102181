import { Skeleton, styled } from '@mui/material';

const StyledContainer = styled('div')({
  display: 'flex',
  gap: 16,
});

export default function CheckboxSkeleton() {
  return (
    <StyledContainer>
      <Skeleton variant="rounded" width={32} height={32} />
      <Skeleton width="100%" />
    </StyledContainer>
  );
}
