import useReportPanel from '@pages/app/rca/tabs/reports/report-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import ReportContainer from '@pages/app/rca/tabs/reports/components/report-container';
import ReportTitleBar from '@pages/app/rca/tabs/reports/components/report-title-bar';
import { ReportView } from '@store/reports/reports-selectors';
import CoverageReportView from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import UnknownReportView from '@pages/app/rca/tabs/reports/views/unknown-report-view';
import ReportFooterBar from '@pages/app/rca/tabs/reports/components/report-footer-bar';
import React from 'react';
import { Box } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import useMaybeSnapToFocusedNode from '@pages/app/rca/tabs/hooks/use-maybe-snap-to-focused-node-hook';

const ViewMap = {
  [ReportView.coverage]: CoverageReportView,
  [ReportView.unknown]: UnknownReportView,
};

export default function ReportPanel() {
  const state = useReportPanel();
  const {
    isOpen,
    isLoading,
    close,
    runModel,
    runData,
    shouldShrink,
    reportView,
    isEmpty,
    splitData,
  } = state;

  useMaybeSnapToFocusedNode(isOpen);

  const View = ViewMap[reportView];

  return (
    <ReportContainer isOpen={isOpen} shrink={shouldShrink}>
      {!isOpen || isLoading ? (
        <LoadingIndicator show />
      ) : (
        <>
          <ReportTitleBar runModel={runModel!} onClose={close} state={state} />
          {isEmpty ? (
            <>
              <Gap size={shouldShrink ? 2 : 50} />
              <OnboardingEntryContainer
                icon={
                  <FontAwesomeIcon icon={faFileCircleExclamation} size="2x" />
                }
                title="No data available"
                message="There is no data available for this report."
                hideBgImage
              />
            </>
          ) : (
            <Box
              sx={{
                height: !shouldShrink ? 'calc(100% - 120px)' : undefined,
                paddingBottom: !shouldShrink ? 10 : 0,
                overflowY: 'auto',
              }}
            >
              <View data={runData!} model={runModel!} splitData={splitData} />
            </Box>
          )}
          {!shouldShrink && (
            <>
              <Gap size={50} />
              <ReportFooterBar state={state} />
            </>
          )}
        </>
      )}
    </ReportContainer>
  );
}
