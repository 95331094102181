import { styled } from '@mui/material';

interface StyledOnboardingHeaderProps {}

export const StyledOnboardingHeader = styled(
  'div'
)<StyledOnboardingHeaderProps>(({ color }) => ({
  flex: '0 0 285px',
  width: '100%',
  height: 285,
  borderRadius: '16px 16px 0 0',
  '& img': {
    width: '50%',
    height: 'auto',
    position: 'absolute',
    bottom: -10,
    left: '50%',
    transform: 'translate(-50%)',
  },
}));

export const StyledOnboardingBody = styled('div')({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowY: 'auto',

  '&>.content': {
    padding: '0 92px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
  },

  '&>.actions': {
    padding: '0 30px 32px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',

    '& .link-button': {
      position: 'absolute',
      right: 20,
      bottom: 20,
    },

    '&>button:not(.link-button,.MuiBox-root)': {
      minWidth: 150,
    },
  },
});
