import { api } from '@api/api';
import {
  WAInstallRow,
  WAInstallRowFilters,
  WAInstallSeed,
  WAUpsertInstallRequest,
  WAUpsertInstallResponse,
} from '@api/types/wct-admin/wct-admin-install-types';
import { makeError } from '@api/types/api-error';
import { PaginatedResult } from '@api/types/paginated-result';
import { Option } from '@api/types/option';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const wctAdminInstallApi = api.injectEndpoints({
  endpoints: (build) => ({
    upsertWAInstall: build.mutation<
      WAUpsertInstallResponse,
      WAUpsertInstallRequest
    >({
      query: (body) => ({
        url: 'install',
        method: body.installId == null ? 'POST' : 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<WAUpsertInstallRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['WAInstall'] : []),
    }),
    getAllWAInstalls: build.query<
      PaginatedResult<WAInstallRow>,
      WAInstallRowFilters
    >({
      query: (params) => ({
        url: 'install',
        method: 'GET',
        params,
      }),
      providesTags: ['WAInstall'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getAllWAInstallNameOptions: build.query<Option[], void>({
      query: () => ({
        url: 'install/nameOptions',
        method: 'GET',
      }),
      providesTags: ['WAInstall'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    seedWAInstall: build.mutation<WAUpsertInstallResponse, WAInstallSeed>({
      query: ({ id, ...body }) => ({
        url: `install/${id}/seed`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<WAInstallSeed>(error),
      invalidatesTags: (_, error) => (!error ? ['WAInstall'] : []),
    }),
    deleteWAInstall: build.mutation<void, number>({
      query: (id) => ({
        url: `install/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) =>
        makeError<{ installId: number }>(error),
      invalidatesTags: (_, error) => (!error ? ['WAInstall'] : []),
    }),
  }),
});

export const {
  useDeleteWAInstallMutation,
  useSeedWAInstallMutation,
  useGetAllWAInstallNameOptionsQuery,
  useGetAllWAInstallsQuery,
  useUpsertWAInstallMutation,
} = wctAdminInstallApi;
