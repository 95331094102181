import { Skeleton, styled } from '@mui/material';

const Container = styled('dl')({
  display: 'inline-flex',
  flexDirection: 'column',
});

const StyledDT = styled('dt')({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  margin: 0,
  padding: 0,
  color: '#000000DE',
});

const StyledDD = styled('dd')({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  margin: 0,
  padding: 0,
  color: '#000000DE',
});

export default function LabelValueSkeleton() {
  return (
    <Container>
      <StyledDT>
        <Skeleton width={60} />
      </StyledDT>
      <StyledDD>
        <Skeleton width={40} />
      </StyledDD>
    </Container>
  );
}
