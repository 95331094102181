import useField from '@hooks/use-field-hook';
import { exactLength, onlyNumbers, required } from '@util/validators';
import { useCallback, useEffect, useMemo } from 'react';
import { ApiError } from '@api/types/api-error';
import { useLazyRequestConfirmationCodeQuery } from '@api/endpoints/user.api';
import { MfaMethod } from '@api/types/auth/mfa-method';
import { usePageAlertVariants } from '@components/alerts';
import { ConfirmationCodeRequest } from '@api/types/user/confirmation-code.request';

export default function useChangeEmailAddressModal(newEmailAddress: string) {
  const { showErrorMessage } = usePageAlertVariants();

  const [requestCode, { isFetching: isLoading }] =
    useLazyRequestConfirmationCodeQuery();

  const code = useField<string>([required(), onlyNumbers(), exactLength(6)]);

  const canSubmit = useMemo(() => {
    return !isLoading && code.validate(false);
  }, [code, isLoading]);

  const onResendCode = useCallback(() => {
    requestCode({
      method: MfaMethod.email,
      to: newEmailAddress,
    })
      .unwrap()
      .catch(({ message, errors }: ApiError<ConfirmationCodeRequest>) => {
        showErrorMessage(errors?.to ?? errors?.method ?? message);
      });
  }, [newEmailAddress, requestCode, showErrorMessage]);

  useEffect(() => {
    onResendCode();
  }, [onResendCode]);

  return {
    onResendCode,
    code,
    canSubmit,
    isLoading,
  };
}
