import { BaseEdge, EdgeProps, getSmoothStepPath } from 'reactflow';
import {
  makeSelectIsDescendantOfMetaNode,
  makeSelectNodeCollapsedState,
  makeSelectParentNode,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { useMemo } from 'react';
import { RcaUtil } from '@util/rca-util';

export default function RcaDefaultEdge({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const selectCollapsedState = useMemo(() => {
    return makeSelectNodeCollapsedState(target);
  }, [target]);

  const selectIsDescendantOfMetaNode = useMemo(
    () => makeSelectIsDescendantOfMetaNode(source),
    [source]
  );

  const selectParentNode = useMemo(
    () => makeSelectParentNode(target),
    [target]
  );

  const collapsedState = useAppSelector(selectCollapsedState);
  const isMetaNodeParent = RcaUtil.isMetaNode(useAppSelector(selectParentNode));
  const isDescendantOfMetaNode = useAppSelector(selectIsDescendantOfMetaNode);

  const [path] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 0,
    borderRadius: 18,
    centerX: sourceX + (targetX - sourceX) * (isMetaNodeParent ? 0.5 : 0.6),
  });

  if (collapsedState === 'collapse-state-hidden') {
    return <></>;
  }

  return (
    <BaseEdge
      id={id}
      path={path}
      markerEnd={markerEnd}
      style={{
        ...style,
        stroke: '#787878',
        strokeWidth: 2,
        strokeDasharray: isDescendantOfMetaNode ? '5,5' : undefined,
        opacity:
          isDescendantOfMetaNode || collapsedState === 'collapse-state-ghost'
            ? 0.2
            : 1.0,
      }}
    />
  );
}
