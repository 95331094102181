import { styled } from '@mui/material';
import { ReactComponent as CloseIcon } from '@assets/svgs/close.svg';
import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectHighlightedNodeCount,
  selectIsHighlightMode,
} from '@store/rca-editor/selectors';
import { unHighlightAllNodes } from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div', { target: 'chart-cancel-action' })(
  ({ theme: { palette } }) => ({
    zIndex: 99999,
    position: 'fixed',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: 282,
    minHeight: 55,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    background: palette.common.black,

    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: palette.common.grey15,

    button: {
      border: 'none',
      cursor: 'pointer',
      width: 32,
      height: 32,
      borderRadius: 8,
      padding: 8,
      flex: '0 0 32px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#3C3C3C',

      svg: {
        height: 14,
        width: 'auto',
        fill: palette.common.grey15,
      },
    },
  })
);

enum ActionType {
  clearHighlight,
  none,
}

export default function ChartCancelAction() {
  const dispatch = useAppDispatch();

  const isHighlightMode = useAppSelector(selectIsHighlightMode);
  const highlightedCount = useAppSelector(selectHighlightedNodeCount);

  const actionType = useMemo(() => {
    if (isHighlightMode && highlightedCount > 0) {
      return ActionType.clearHighlight;
    }

    return ActionType.none;
  }, [highlightedCount, isHighlightMode]);

  if (actionType === ActionType.none) {
    return <></>;
  }

  const label = () => {
    switch (actionType) {
      case ActionType.clearHighlight:
        return 'Clear Highlighted Cause Box(es)';
    }
  };

  const onActionClick = () => {
    switch (actionType) {
      case ActionType.clearHighlight:
        dispatch(unHighlightAllNodes());
        break;
    }
  };

  return (
    <Container>
      <button onClick={onActionClick} type="button">
        <CloseIcon />
      </button>
      <Gap size={12} />
      <span>{label()}</span>
    </Container>
  );
}
