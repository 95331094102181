import {
  AlertInfo,
  AlertRegisterOptions,
  usePageAlert,
} from '@components/alerts/hooks/page-alert-hook';
import { useEffect, useState } from 'react';
import { ErrorAlert, InfoAlert, SuccessAlert } from '@components/alerts/index';
import { styled } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WarningAlert from '@components/alerts/warning-alert';

type Props = {
  addMargin?: boolean;
  maxTime?: number;
  absolute?: boolean;
} & AlertRegisterOptions;

const AbsoluteAlertContainer = styled('div')({
  position: 'fixed',
  left: 80,
  bottom: 50,
  height: 80,
  width: 400,
  zIndex: 1000,
  borderRadius: 0,

  '.MuiAlert-root': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const DEFAULT_PAGE_ALERT_MAX_TIME = 6000;

export default function PageAlertDisplay(props: Props) {
  const { absolute } = props;
  const { addMargin, maxTime, ...options } = props;
  const { registerDisplay, unregisterDisplay, hideAlert } = usePageAlert();

  const [alert, setAlert] = useState<AlertInfo>();

  const { ignoreError, ignoreSuccess, ignoreInfo } = options;
  useEffect(() => {
    hideAlert();

    const id = registerDisplay(
      {
        showAlert: (alertInfo) => {
          setAlert(alertInfo);
          if (alertInfo.type === 'success' || !!maxTime || absolute) {
            setTimeout(() => {
              setAlert(undefined);
            }, maxTime ?? 6000);
          }
        },
        hideAlert: () => setAlert(undefined),
      },
      {
        ignoreError: ignoreError,
        ignoreInfo: ignoreInfo,
        ignoreSuccess: ignoreSuccess,
      }
    );
    return () => unregisterDisplay(id);
  }, [
    ignoreError,
    ignoreInfo,
    ignoreSuccess,
    registerDisplay,
    unregisterDisplay,
    hideAlert,
    maxTime,
  ]);

  const makeAlert = () => {
    const { type, title, message } = alert!;

    switch (type) {
      case 'error':
        return (
          <ErrorAlert title={title} message={message} onClose={hideAlert} />
        );
      case 'success':
        return (
          <SuccessAlert title={title} message={message} onClose={hideAlert} />
        );
      case 'info':
        return (
          <InfoAlert title={title} message={message} onClose={hideAlert} />
        );

      case 'warning':
        return (
          <WarningAlert title={title} message={message} onClose={hideAlert} />
        );
    }
  };

  return (
    <>
      {alert != null ? (
        absolute ? (
          <AbsoluteAlertContainer>
            {makeAlert()}
            {addMargin ? <Gap size={15} /> : null}
          </AbsoluteAlertContainer>
        ) : (
          <>
            {makeAlert()}
            {addMargin ? <Gap size={15} /> : null}
          </>
        )
      ) : null}
    </>
  );
}
