import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseResource } from '@api/types/case/case.resource';

export interface CreateAnalysisState {
  selectedTypeId?: number;
  currentCase?: CaseResource;
}

const initialState: CreateAnalysisState = {};

export const createAnalysisSlice = createSlice({
  name: 'create-analysis-slice',
  initialState,
  reducers: {
    setAnalysisCreationType(state, action: PayloadAction<number>) {
      state.selectedTypeId = action.payload;
    },
    setCurrentCase(state, action: PayloadAction<CaseResource>) {
      state.currentCase = action.payload;
    },
  },
});

export default createAnalysisSlice.reducer;

export const { setAnalysisCreationType, setCurrentCase } =
  createAnalysisSlice.actions;
