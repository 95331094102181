import {
  Box,
  Checkbox,
  Chip,
  ChipProps,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { optionalLabel } from '@util/string-util';
import { ReactNode, useMemo, useState } from 'react';
import React from 'react';

type SelectFieldValueType = string | number | readonly string[];

interface SelectFieldItem {
  label: string;
  id?: SelectFieldValueType;
  disabled?: boolean;
  children?: SelectFieldItem[];
}

interface SelectFieldProps {
  id: string;
  name?: string;
  label?: string | DefaultTFuncReturn;
  options: Array<SelectFieldItem>;
  value?: SelectFieldValueType | Array<SelectFieldValueType>;
  error?: string;
  helperText?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  required?: boolean;
  onChange: (value: SelectFieldValueType | Array<SelectFieldValueType>) => void;
  onBlur?: () => void;
  multiple?: boolean;
  readonly?: boolean;
  allowTypeSearch?: boolean;
  renderValue?: (item: SelectFieldValueType) => ReactNode;
  renderOption?: (option: SelectFieldItem) => ReactNode;
  autoReadonly?: boolean;
  group?: boolean;
}

const StyledFormControl = styled(FormControl)(({ theme: { palette } }) => ({
  margin: 0,
  padding: 0,
  label: {
    color: palette.common.textMid,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    transform: 'translate(12px, 16px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: palette.common.textMid,
      transform: 'translate(12px, 3px) scale(0.75)',
    },
    pointerEvents: 'none',
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    minHeight: '48px',
    display: 'flex',
    borderRadius: 4,
    border: `1px solid ${palette.common.grey70}`,
    background: 'white',
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'white',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `1px solid ${palette.primary.main}`,
      outline: '5px solid rgba(255, 108, 0, 0.12)',
      background: 'white',
      '&::after': {
        display: 'none',
      },
    },
    '&.Mui-disabled': {
      border: '1px solid #ECEDF0',
      background: '#ECEDF0',
      '&:hover': {
        border: '1px solid #ECEDF0',
        background: '#ECEDF0',
      },
      '.MuiInputBase-readOnly': {
        color: '#000',
        textFillColor: '#000',
      },
    },
    '& .MuiInputBase-input': {
      padding: '22px 12px 6px 12px',
    },
  },
}));

const StyledChip = styled(Chip)<ChipProps>(({ theme }) => ({
  marginRight: 5,
  marginBottom: 3,
}));

export default function WCTNestedSelectField({
  label,
  id,
  name,
  options: unfilteredOptions,
  value,
  error,
  helperText,
  placeholder,
  required,
  onChange,
  onBlur,
  multiple,
  readonly,
  allowTypeSearch,
  renderValue,
  renderOption,
  autoReadonly = true,
  group,
}: SelectFieldProps) {
  const hasLabel = !!label;
  const hasError = !!error;
  const hasHelper = !!helperText;

  const [initValue] = useState<
    SelectFieldValueType | Array<SelectFieldValueType> | undefined
  >(value);

  // Remove options with duplicate ids
  const options = unfilteredOptions.filter(
    (option, index, self) => self.findIndex((x) => x.id === option.id) === index
  );

  if (
    autoReadonly &&
    options.length < 2 &&
    initValue != null &&
    required &&
    (!Array.isArray(initValue) || initValue.length !== 0)
  ) {
    readonly = true;
  }

  const defaultValue = multiple ? [] : '';

  const flatOptions = useMemo(() => {
    return [...options, ...options.flatMap((x) => x.children ?? [])];
  }, [options]);

  const renderPlaceholder = () => {
    if (!placeholder) {
      return;
    }

    return (
      <Typography
        sx={{
          color: 'rgba(0, 0, 0, 0.38)',
        }}
      >
        {placeholder}
      </Typography>
    );
  };

  const renderChips = () => {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return renderPlaceholder();
    }

    return (
      <Box display="flex" flexWrap="wrap">
        {value.map((v) => (
          <StyledChip
            disabled={readonly}
            key={`${v}`}
            label={flatOptions.find((x) => x.id === v)?.label}
            size="small"
            clickable
            onMouseDown={(e) => e.stopPropagation()}
            onDelete={() => {
              onChange(value.filter((x) => x !== v));
            }}
          />
        ))}
      </Box>
    );
  };

  const renderOptionMenuItem = (option: SelectFieldItem, depth = 0) => {
    if (option.disabled && (!option.children || option.children.length === 0))
      return null;

    const hasCheckbox = multiple && !option.disabled;

    return [
      <MenuItem
        key={`${option.id}`}
        value={option.id}
        sx={{ marginLeft: `${depth * 32}px` }}
      >
        {hasCheckbox ? (
          <Checkbox
            checked={
              Array.isArray(value) && value.indexOf(option.id as any) !== -1
            }
          />
        ) : null}
        {renderOption != null ? (
          renderOption(option)
        ) : (
          <ListItemText
            primary={option.label}
            sx={{
              marginLeft: !hasCheckbox ? '13px' : undefined,
              '& span': {
                fontWeight: !hasCheckbox ? 500 : undefined,
              },
            }}
          />
        )}
      </MenuItem>,
      ...(option.children?.map((x) => renderOptionMenuItem(x, depth + 1)) ??
        []),
    ];
  };

  return (
    <StyledFormControl
      error={!!error}
      hiddenLabel={!hasLabel}
      variant="filled"
      className="wct-select"
      required={required}
      fullWidth
    >
      {hasLabel ? (
        <InputLabel
          id={id}
          shrink={value == null ? !!label && !!placeholder : true}
        >
          {optionalLabel(label, required)}
        </InputLabel>
      ) : null}
      <Select
        labelId={hasLabel ? id : undefined}
        id={id}
        name={name}
        value={(value || defaultValue) as any}
        label={hasLabel ? optionalLabel(label, required) : undefined}
        error={!!error}
        readOnly={readonly}
        disabled={readonly}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        multiple={multiple}
        renderValue={
          multiple ? renderChips : !value ? renderPlaceholder : renderValue
        }
        displayEmpty
      >
        {options.map((option) => renderOptionMenuItem(option))}
      </Select>
      {hasError || hasHelper ? (
        <FormHelperText>{error ?? helperText}</FormHelperText>
      ) : null}
    </StyledFormControl>
  );
}
