import {
  useGetSkillLinkedQuery,
  useGetSkillOptionsQuery,
  useUpdateSkillsLinksMutation,
} from '@api/endpoints/skills.api';
import { Option } from '@api/types/option';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';

export default function useSkillPage() {
  const { showErrorMessage } = usePageAlertVariants();

  const { data: skillsOptions, isLoading: loadingSkillsOptions } =
    useGetSkillOptionsQuery();

  const { data: skills, isLoading: loadingSkills } = useGetSkillLinkedQuery();

  const [updateSkillLinks, { isLoading: isSubmitting }] =
    useUpdateSkillsLinksMutation();

  const selectSkill = async (value: Option | Array<Option>) => {
    try {
      await updateSkillLinks(Array.isArray(value) ? value : [value]);

      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const removeSkill = async (skillId: number) => {
    try {
      await updateSkillLinks([
        ...(skills ?? [])?.filter((m) => m.id !== skillId),
      ]);

      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const isLoading = loadingSkillsOptions || loadingSkills;

  return {
    skillsOptions: skillsOptions ?? [],
    skills: skills ?? [],
    selectSkill,
    removeSkill,
    isLoading,
    isSubmitting,
  };
}
