import { ReportSubType } from '@api/types/report-type/report-subtype.option';

export enum ReportRankBy {
  nonTransitory = 'NonTransitory',
  transitory = 'Transitory',
  coverage = 'Coverage',
  quality = 'Quality',
  coverageActual = 'CoverageActual',
  coveragePotential = 'CoveragePotential',
  coverageAll = 'CoverageAll',
  effectiveness = 'Effectiveness',
  solutionTerm = 'SolutionTerm',
  all = 'All',
}

export interface ReportRankByOption {
  reportRankById: number;
  name: string;
  reportSubTypeId: number;
  reportSubTypeName: string;
  reportSubType: ReportSubType;
  reportRankBy: ReportRankBy;
}
