import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import Row from '@components/layout-util-components/row';
import { Grid, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import {
  DropDownFilter,
  SortByFilter,
  WCTTableFilters,
} from '@components/table';

import CaseFileGridItem from '@components/cards/case-file-grid-item';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import CaseFileManagerModal, {
  CaseFileManagerProps,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal';
import { OutlinedButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import React from 'react';
import Column from '@components/layout-util-components/column';
import { LoadingIndicator } from '@components/loading-indicator';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { faFiles } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  state: CaseFileManagerState;
}

export default function CaseFileManagerGridView({ state }: Props) {
  const {
    isLoading,
    search,
    sortBy,
    sortByOptions,
    fileType,
    fileTypeOptions,
    caseFiles,
    caseId,
    isFetching,
    removeFile,
    canContribute,
  } = state;

  const { showPopup } = useUiPopup();

  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const onDeleteClick = (caseFileId: number) => {
    return showPopup(DeleteConfirmationModal, {
      itemName: 'File',
      title: 'Are you sure you want to delete this file?',
      message:
        'This file is associated to evidence and solutions within the RCA chart and would no longer be associated with these if deleted.\n\nAre you sure you want to delete this file?',
      onDelete: async () => {
        try {
          await removeFile({ caseFileId, caseId }).unwrap();
          showSuccessMessage('Case file deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ caseId: number }>;
          showErrorMessage(errors?.caseId ?? message);
          return false;
        }
      },
    });
  };

  const onAddNew = () => {
    showPopup<CaseFileManagerProps, Array<CaseFileListItemResponse>>(
      CaseFileManagerModal,
      {
        allowSelector: false,
      }
    );
  };

  if (isLoading) {
    return <LoadingIndicator show />;
  }

  const isEmpty = !caseFiles?.totalCount;

  return (
    <Column>
      <Row>
        <Typography variant="h1">Files ({caseFiles?.totalCount})</Typography>
        <Spacer />
        {canContribute && (
          <OutlinedButton onClick={onAddNew}>Add file</OutlinedButton>
        )}
      </Row>
      <Gap size={20} />
      <WCTTableFilters
        isLoading={isLoading}
        isFetching={isFetching}
        search={search.value}
        onSearch={search.set}
        rightOfSearchContent={
          <SortByFilter
            value={sortBy.value}
            onChange={sortBy.set}
            options={sortByOptions}
          />
        }
      >
        <DropDownFilter
          value={fileType.value}
          onChange={fileType.set}
          options={fileTypeOptions}
        >
          File type
        </DropDownFilter>
      </WCTTableFilters>
      <Gap size={15} />
      {isEmpty ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faFiles} size="2x" />}
          title="No files have been added to this RCA"
          message={
            canContribute ? 'Click below to add files to this RCA' : undefined
          }
          createMessage="Add file"
          onCreateClicked={canContribute ? onAddNew : undefined}
        />
      ) : (
        <Grid container spacing={4}>
          {caseFiles?.model.map((caseFile) => (
            <CaseFileGridItem
              key={caseFile.caseFileId}
              caseFile={caseFile}
              onSelectDeleteFile={async (
                caseFile: CaseFileListItemResponse
              ) => {
                return !!(await onDeleteClick(caseFile.caseFileId));
              }}
            />
          ))}
        </Grid>
      )}
    </Column>
  );
}
