import useCustomiseOutcome from '@pages/app/rca/outcome/views/customise-outcome-hook';
import { Grid, Typography } from '@mui/material';
import FullPageFormScaffold from '@components/layouts/scaffolds/full-page-form-scaffold';
import React from 'react';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import { OutcomeStepProps } from '@pages/app/rca/outcome/rca-outcome-page';
import { Divider } from '@components/layout-util-components/divider';
import WCTSortableListField from '@components/input/sortable-list-field';
import { InlineBoxSkeleton, InputSkeleton } from '@components/skeletons';
import CustomLenseSelectionItem, {
  DraggableReportItemProps,
} from '@pages/app/rca/outcome/components/custom-lense-selection-item';
import WCTSelectField from '@components/input/select-field';
import ShareButton from '@pages/app/rca/outcome/components/share-button';
import { Gap } from '@components/layout-util-components/gap';
import Column from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import EvidenceSelectionItem, {
  DraggableEvidenceItemProps,
} from '../components/evidence-selection-item';

export default function CustomiseOutcomeView({
  state,
  onBack,
  onContinue,
}: OutcomeStepProps) {
  const { outcomeId, caseId } = state;
  const {
    recommendations,
    summary,
    rankBy,
    rankByOptions,
    lenses,
    evidence,
    canSubmit,
    isBusy,
    isLoading,
    toggleSelectedReport,
    toggleSelectedEvidence,
    submit,
    showLenses,
    showSolutions,
    showRecommendations,
    showSummary,
    showDefaultThemesOutcome,
    showDefaultSolutionsOutcome,
    showEvidenceDetail,
  } = useCustomiseOutcome(state);

  const renderDraggable = (item: DraggableReportItemProps) => {
    return (
      <CustomLenseSelectionItem
        {...item}
        onToggleSelected={(v) => toggleSelectedReport(v!)}
      />
    );
  };

  const renderDraggableEvidence = (item: DraggableEvidenceItemProps) => {
    return (
      <EvidenceSelectionItem
        {...item}
        onToggleSelected={(v) => toggleSelectedEvidence(v!)}
      />
    );
  };

  return (
    <FullPageFormScaffold
      appBarRef={state.appBarRef}
      onSubmit={async () => {
        if (await submit()) {
          onContinue();
        }
      }}
    >
      <Gap size={128} />
      <Typography variant="h1">Summarise & conclude this outcome</Typography>
      <Gap size={32} />
      {showSummary ? (
        <>
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="h2">Executive Summary</Typography>
                <Typography variant="body2">
                  Provide an introduction and overview of this RCA and reasons
                  why this outcomes report should be read.
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="executiveSummary"
                  placeholder="Executive Summary"
                  value={summary.value}
                  error={summary.error}
                  onChange={summary.set}
                  multiline
                  required
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {showLenses ? (
        <>
          {showSummary ? <Divider addMargin /> : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="h2">Lenses</Typography>
                <Typography variant="body2">
                  Some detail about lenses..
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              <Column>
                <Typography
                  sx={{
                    color: '#949FA2',
                    textTransform: 'uppercase',
                    fontSize: 13,
                    fontWeight: '500',
                    padding: '5px 0 5px',
                  }}
                >
                  {isLoading ? (
                    <InlineBoxSkeleton width={100} />
                  ) : (
                    'Default Lenses'
                  )}
                </Typography>
                <Gap size={10} />
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <CustomLenseSelectionItem
                    reportName="Theme Coverage"
                    reportTypeName="Coverage Report"
                    isSelected={showDefaultThemesOutcome.value}
                    onToggleSelected={() =>
                      showDefaultThemesOutcome.set(
                        !showDefaultThemesOutcome.value
                      )
                    }
                    draggable={false}
                  />
                )}
                <Gap size={10} />
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <CustomLenseSelectionItem
                    reportName="Solutions Coverage"
                    reportTypeName="Coverage Report"
                    isSelected={showDefaultSolutionsOutcome.value}
                    onToggleSelected={() =>
                      showDefaultSolutionsOutcome.set(
                        !showDefaultSolutionsOutcome.value
                      )
                    }
                    draggable={false}
                  />
                )}
                <Gap size={24} />
                {isLoading ? (
                  <InputSkeleton />
                ) : lenses.value.length > 0 ? (
                  <>
                    <Typography
                      sx={{
                        color: '#949FA2',
                        textTransform: 'uppercase',
                        fontSize: 13,
                        fontWeight: '500',
                        padding: '5px 0 5px',
                      }}
                    >
                      {isLoading ? (
                        <InlineBoxSkeleton width={100} />
                      ) : (
                        'Custom Lenses'
                      )}
                    </Typography>
                    <Gap size={15} />
                    <WCTSortableListField
                      name="lenses"
                      itemIdKey="id"
                      value={lenses.value}
                      onChange={lenses.set}
                      RenderItem={renderDraggable}
                    />
                  </>
                ) : null}
              </Column>
            </Grid>
          </Grid>
        </>
      ) : null}

      {showEvidenceDetail ? (
        <>
          <Divider addMargin />
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="h2">Evidence Detail</Typography>
                <Typography variant="body2">
                  Select evidence to include
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              <Column>
                {isLoading ? (
                  <InputSkeleton />
                ) : evidence.value.length > 0 ? (
                  <>
                    <Typography
                      sx={{
                        color: '#949FA2',
                        textTransform: 'uppercase',
                        fontSize: 13,
                        fontWeight: '500',
                        padding: '5px 0 5px',
                      }}
                    >
                      {isLoading ? (
                        <InlineBoxSkeleton width={100} />
                      ) : (
                        'Evidence'
                      )}
                    </Typography>
                    <Gap size={15} />
                    <WCTSortableListField
                      name="evidence"
                      itemIdKey="id"
                      value={evidence.value}
                      onChange={evidence.set}
                      RenderItem={renderDraggableEvidence}
                    />
                  </>
                ) : null}
              </Column>
            </Grid>
          </Grid>
        </>
      ) : null}

      {showSolutions ? (
        <>
          {showLenses || showSummary ? <Divider addMargin /> : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="h2">Solutions</Typography>
                <Typography variant="body2">
                  Select how solutions are presented
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={6}
              flexGrow={1}
              alignItems="stretch"
              flexDirection="column"
            >
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTSelectField
                  name="ranking"
                  id="ranking"
                  label="Ranking"
                  options={rankByOptions!.map((option) => ({
                    id: option.reportRankById,
                    label: option.name,
                  }))}
                  value={rankBy.value}
                  error={rankBy.error}
                  onChange={rankBy.set}
                  required
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {showRecommendations ? (
        <>
          {showLenses || showSummary || showSolutions ? (
            <Divider addMargin />
          ) : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="h2">Closing Statement</Typography>
                <Typography variant="body2">
                  Your closing statement will summarise the RCA, present
                  conclusions and highlight key findings and or next actions.
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="recommendations"
                  placeholder="Closing Statement"
                  value={recommendations.value}
                  error={recommendations.error}
                  onChange={recommendations.set}
                  multiline
                  required
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      <ActionsRow anchorBottom addPadding filled>
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Spacer />
        {outcomeId != null ? (
          <ShareButton caseId={caseId} outcomeId={outcomeId} />
        ) : null}
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Continue
        </PrimaryButton>
      </ActionsRow>
    </FullPageFormScaffold>
  );
}
