import { isNullOrEmpty } from '@util/string-util';

export const formatCurrency = (
  value: string | number,
  currencyCode: string
) => {
  const valueStr = value?.toString();
  if (isNullOrEmpty(valueStr)) {
    return '';
  }

  const numericValue = parseFloat(valueStr.replace(/,/g, '')) || 0;

  return numericValue
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: 0,
    })
    .replace(/[^0-9.,]+/, '');
};

export const parseCurrency = (formattedValue: string) => {
  return formattedValue.replace(/[^0-9.-]+/g, '');
};
