import { useCommonTr } from '@i18n/use-common-tr';
import useField from '@hooks/use-field-hook';
import { equals, required, validPassword } from '@util/validators';
import { useUpdatePasswordMutation } from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { UpdatePasswordRequest } from '@api/types/user/update-password.request';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useProfileUpdatePassword() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { t: validationTr } = useCommonTr('validation');

  const [updatePassword, { isLoading: isSubmitting }] =
    useUpdatePasswordMutation();

  const currentPassword = useField<string>([required()]);
  const newPassword = useField<string>([required(), validPassword()]);
  const confirmNewPassword = useField<string>([
    required(),
    equals(newPassword.value, validationTr('passwordMissMatchError')),
  ]);

  const { isValid, isDirty, validateAll, resetAll } = useFieldsWatcher([
    currentPassword,
    newPassword,
    confirmNewPassword,
  ]);
  const canSubmit = isValid && isDirty && !isSubmitting;

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return updatePassword({
      existingPassword: currentPassword.value,
      password: newPassword.value,
      confirmPassword: confirmNewPassword.value,
    })
      .unwrap()
      .then(() => {
        resetAll();

        showSuccessMessage('Password updated!');
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdatePasswordRequest>) => {
        showErrorMessage(message);

        currentPassword.setError(errors?.existingPassword);
        newPassword.setError(errors?.password);
        confirmNewPassword.setError(errors?.confirmPassword);

        return false;
      });
  };

  return {
    currentPassword,
    newPassword,
    confirmNewPassword,
    canSubmit,
    submit,
    isSubmitting,
  };
}
