import { Position } from 'reactflow';

export type RFDirection = 'TB' | 'LR' | 'RL' | 'BT';

export const rfPositionMap: Record<string, Position> = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom,
};

export const getRFPosition = (x: number, y: number, direction: RFDirection) => {
  switch (direction) {
    case 'LR':
      return { x: y, y: x };
    case 'RL':
      return { x: -y, y: -x };
    case 'BT':
      return { x: -x, y: -y };
    default:
      return { x, y };
  }
};
