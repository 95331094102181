import { Button, styled, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import useHelpCentre from '@pages/app/help-centre/help-centre-hook';
import { useNavigate } from 'react-router-dom';
import { HelpCenterFAQs } from '@pages/app/help-centre/components/help-centre-faqs';
import { Videos } from '@pages/app/help-centre/components/videos';
import { SearchBar } from '@pages/app/help-centre/components/search-bar';
import { Articles } from '@pages/app/help-centre/components/articles';
import {
  DrawerContent,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import useHideConflictingPanels, {
  SingleUsePanel,
} from '@hooks/rca/use-hide-conflicting-panels-hook';

export interface RcaHelpPanelProps extends SideDrawerFormProps<never> {}

const StyledHelpButton = styled(Button)(({ theme: { palette } }) => ({
  borderRadius: 0,
  background: 'none',
  display: 'flex',
  alignItems: 'center',
  color: palette.blue.dark,
  fontSize: '15px',
  lineHeight: '133.4%',
  textTransform: 'none',
  padding: 0,
  marginTop: 10,
}));

function RCAHelpPanel({ onClose, show }: RcaHelpPanelProps) {
  const state = useHelpCentre();
  const navigate = useNavigate();

  const { search, searchResult, videoContent, faqContent, articleContent } =
    state;

  useHideConflictingPanels(SingleUsePanel.help, show);

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Typography variant="h2">RCA Help</Typography>
        <StyledHelpButton
          onClick={() => {
            onClose();
            navigate('/help-centre');
          }}
        >
          Help Centre <FontAwesomeIcon width={31} icon={faChevronRight} />
        </StyledHelpButton>
        <SearchBar
          search={search}
          searchResult={searchResult}
          onOptionTapped={onClose}
        />
      </DrawerHeader>
      <DrawerContent>
        <Videos
          label="Related Videos"
          showAll={false}
          videos={videoContent ?? []}
          direction="column"
        />
        <Articles
          label="Related Articles"
          showAll={false}
          articles={articleContent ?? []}
          direction="column"
        />
        <HelpCenterFAQs
          label="Related FAQ's"
          showAll={false}
          faqs={faqContent ?? []}
        />
      </DrawerContent>
    </>
  );
}

export default withSideDrawer(RCAHelpPanel, {
  allowTapOutside: true,
  belowAppBar: true,
});
