import { api } from '@api/api';
import { SystemText } from '@api/types/system-text/system-text';

const systemTextApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSystemText: build.query<SystemText[], void>({
      query: () => ({
        url: 'systemText',
      }),
    }),
  }),
});

export const { useGetSystemTextQuery } = systemTextApi;

export default systemTextApi;
