import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export interface ApiError<T> {
  status: FetchBaseQueryError['status'];
  message?: string;
  errors?: ErrorType<T>;
}

export function isApiError<T>(x: any): x is ApiError<T> {
  if (typeof x !== 'object') {
    return false;
  }

  return 'status' in x && ('errors' in x || 'message' in x);
}

type Flatten<T> = T extends Array<infer R> ? R : T;

export type ErrorData = string | string[];
export type ErrorType<T> = {
  [K in keyof Flatten<T>]: ErrorData | undefined;
};

type PropertyRenameMap<T> = Record<string, keyof Flatten<T>>;

function maybeRemap<T>(
  property: string,
  map?: PropertyRenameMap<T>
): keyof Flatten<T> {
  if (map == null || map[property] == null) {
    return property as keyof Flatten<T>;
  }

  return map[property];
}

export const makeError = <T>(
  { status, data }: FetchBaseQueryError,
  propertyReMapper?: PropertyRenameMap<T>
): ApiError<T> => {
  if (typeof status !== 'number') {
    return {
      status,
    };
  }

  if (status < 400 || status > 499) {
    return {
      status,
    };
  }

  let message: string | undefined;
  const errors: { [K in keyof Flatten<T>]?: ErrorData } = {};
  if (typeof data === 'object' && data != null) {
    if ('errors' in data) {
      const propertyErrors = data.errors as object;
      for (const [key, value] of Object.entries(propertyErrors)) {
        // Check if the key contains an array index
        const arrayPropertyMatch = key.match(/^(.+)\[(\d+)]$/);

        if (arrayPropertyMatch) {
          // Handle the array property case
          const propertyName = maybeRemap(
            arrayPropertyMatch[1],
            propertyReMapper
          );
          const arrayIndex = parseInt(arrayPropertyMatch[2]);

          // If the property is not an array yet, initialize it
          if (!Array.isArray(errors[propertyName])) {
            errors[propertyName] = [];
          }

          // Assign the error message to the corresponding array index
          // Assert that errors[propertyName] is not undefined
          (errors[propertyName] as string[])[arrayIndex] = (
            value as Array<string>
          )[0];
        } else {
          // Handle the string property case
          const property = maybeRemap(key, propertyReMapper);
          errors[property] = (value as Array<string>)[0];
        }
      }
    }

    if (
      'title' in data &&
      typeof data.title === 'string' &&
      data.title.length > 0
    ) {
      message = data.title;
    } else if (
      'message' in data &&
      typeof data.message === 'string' &&
      data.message.length > 0
    ) {
      message = data.message;
    }
  }

  return {
    status,
    message,
    errors: errors as ErrorType<T>,
  };
};
