import useField from '@hooks/use-field-hook';
import { equals, phoneNumber, required, validEmail } from '@util/validators';
import { useAuthTr } from '@i18n/use-auth-tr';
import { useRegisterUserMutation } from '@api/endpoints/user.api';
import { PhoneNumber } from '@components/input/phone-number-field';
import { ApiError } from '@api/types/api-error';
import { RegisterUserRequest } from '@api/types/user/register-user.request';
import { useGetDialCodesQuery } from '@api/endpoints/country.api';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useEffect, useRef } from 'react';

export default function useCreateAccount(packageId?: number) {
  const { t } = useAuthTr('createAccount');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { data: dialCodes, isLoading: isLoadingDialCodes } =
    useGetDialCodesQuery(true);

  const email = useField<string>([required(), validEmail()]);
  const firstName = useField<string>([required()]);
  const surname = useField<string>([required()]);
  const phone = useField<PhoneNumber | undefined>(phoneNumber());
  const optIn = useField<boolean>();
  const agreeToTerms = useField<boolean>([
    equals(true, 'Must agree to the terms and conditions'),
  ]);

  const [registerUser, { isLoading: isSubmitting }] = useRegisterUserMutation();

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    email,
    firstName,
    surname,
    phone,
    agreeToTerms,
  ]);

  const isLoading = isLoadingDialCodes;
  const canSubmit = isValid && isDirty && !isSubmitting && !isLoading;

  const hasSetDialCode = useRef(false);
  const setPhone = phone.set;
  useEffect(() => {
    if (hasSetDialCode.current || dialCodes == null || dialCodes.length === 0) {
      return;
    }

    setPhone({
      dialCode: dialCodes.find((x) => x.label.includes('44'))?.id,
      number: '',
    });
    hasSetDialCode.current = true;
  }, [dialCodes, isLoading, setPhone]);

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return registerUser({
      emailAddress: email.value,
      firstName: firstName.value,
      lastName: surname.value,
      countryDialCode: phone.value?.dialCode,
      telephoneNumber: phone.value?.number,
      acceptTerms: agreeToTerms.value,
      marketingOptIn: optIn.value,
      inviteToken: '??',
      signUpPackageId: packageId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ errors, message }: ApiError<RegisterUserRequest>) => {
        showErrorMessage(errors?.signUpPackageId || message);

        email.setError(errors?.emailAddress);
        firstName.setError(errors?.firstName);
        surname.setError(errors?.lastName);
        phone.setError(errors?.telephoneNumber || errors?.countryDialCode);

        return false;
      });
  };

  return {
    dialCodes,
    email,
    firstName,
    surname,
    phone,
    optIn,
    agreeToTerms,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
  };
}
