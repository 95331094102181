import useUser from '@store/user/user-hook';
import { useMemo } from 'react';
import { Permission } from '@api/types/permission';

export default function usePermission(permission: Permission) {
  const { hasPermission } = useUser();

  return useMemo(() => hasPermission(permission), [hasPermission, permission]);
}

export function usePermissions(permissions: Array<Permission>) {
  const { hasPermission } = useUser();

  return useMemo(() => {
    for (const permission of permissions) {
      if (hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }, [hasPermission, permissions]);
}
