import { TableCell, TableRow } from '@mui/material';
import { dateOnlyLongFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import GroupedAvatars from '@components/containers/grouped-avatars';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { useMemo } from 'react';
import { getAddressString, truncateString } from '@util/string-util';
import { usePersonalCaseOptions } from '@hooks/case/use-personal-case-options';
import { getInitials } from '@util/name-util';
import { useNavigate } from 'react-router-dom';
import RcaIcon from '@components/rca-components/rca-icon';
import RcaStatusText from '@components/rca-components/rca-status-text';
import RcaSeverityPill from '@components/rca-components/rca-severity-pill';
import RcaHealthPill from '@components/rca-components/rca-health-pill';

interface Props {
  data: CaseItemRowResponse;
}
export default function RcaTableRow({ data }: Props) {
  const navigate = useNavigate();
  const elipsisOptions = usePersonalCaseOptions(
    data.caseId,
    data.createdByCompanyUserId,
    data.caseStatusName?.toLowerCase() === 'draft',
    data.caseAnalysisTypeId
  );
  const incidentLocation = useMemo(() => {
    const location = data.incidentLocation;
    if (location == null) {
      return;
    }

    return location.name || getAddressString({ ...location });
  }, [data.incidentLocation]);

  return (
    <TableRow
      onClick={() =>
        navigate(
          data.caseStatusName?.toLowerCase() === 'draft'
            ? `/rca/create/steps/?type=${data.caseAnalysisTypeId}&step=0&caseId=${data.caseId}`
            : `/rca/tabs/${data.caseId}`
        )
      }
    >
      <TableCell>
        <RcaIcon type={data.caseAnalysisTypeShortName} dark />
      </TableCell>
      <TableCell>{truncateString(data.name, 45)}</TableCell>
      <TableCell>
        {
          <>
            <div>{dateOnlyLongFormatter(data.created)}</div>
          </>
        }
      </TableCell>
      <TableCell>
        {data.incidentLocation != null ? incidentLocation : null}
      </TableCell>
      <TableCell>
        <RcaSeverityPill severity={data.caseSeverityName} />
      </TableCell>
      <TableCell>
        <RcaHealthPill health={data.healthScore} />
      </TableCell>
      <TableCell>
        <GroupedAvatars
          items={data.companyUsers.map(({ companyUserId, name }) => ({
            id: companyUserId,
            label: getInitials(name),
          }))}
        />
      </TableCell>
      <TableCell>
        {!!data.caseStatusId && <RcaStatusText statusId={data.caseStatusId!} />}
      </TableCell>
      <TableCell className="action_btn">
        <ElipsisButton actions={elipsisOptions} />
      </TableCell>
    </TableRow>
  );
}
