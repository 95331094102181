import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { Grid, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import WCTTextField from '@components/input/text-field';
import { LinkButton, OutlinedButton, PrimaryButton } from '@components/buttons';
import { PageAlertDisplay } from '@components/alerts';
import ActionsRow from '@components/layout-util-components/actions-row';
import useChangeEmailAddressModal from '@pages/app/profile/forms/change-email-address-hook';
import { Gap } from '@components/layout-util-components/gap';

export interface ChangeEmailAddressModalProps extends ModalProps<string> {
  email: string;
}

const Container = styled('form')({
  width: 600,
  display: 'flex',
  flexDirection: 'column',
});

function ChangeEmailAddressModal({
  email,
  onClose,
}: ChangeEmailAddressModalProps) {
  const { t } = useAppTr('personalDetails');

  const { code, onResendCode, canSubmit, isLoading } =
    useChangeEmailAddressModal(email);

  const onSubmit = () => {
    onClose(code.value);
  };

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h2">{t('requestEmailCodeModal.title')}</Typography>
      <Gap size={24} />
      <Typography variant="body1">
        {t('requestEmailCodeModal.message', { email: email })}
      </Typography>
      <Gap size={20} />
      <Grid container alignItems="center" wrap="nowrap" gap={1}>
        <Grid item xs={6}>
          <WCTTextField
            name="code"
            label={t('requestEmailCodeModal.inputLabel')}
            value={code.value}
            error={code.error}
            onChange={code.set}
            onBlur={code.validate}
            maxLength={6}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <LinkButton disabled={isLoading} onClick={onResendCode}>
            {t('requestEmailCodeModal.resendButtonLabel')}
          </LinkButton>
        </Grid>
      </Grid>
      <Gap size={24} />
      <PageAlertDisplay addMargin ignoreSuccess />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose()}>
          {t('requestEmailCodeModal.cancelButtonLabel')}
        </OutlinedButton>
        <PrimaryButton disabled={!canSubmit} type="submit">
          {t('requestEmailCodeModal.continueButtonLabel')}
        </PrimaryButton>
      </ActionsRow>
    </Container>
  );
}

export default withModal(ChangeEmailAddressModal);
