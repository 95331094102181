import { useEffect, useState } from 'react';
import { useGetReportTypeOptionsQuery } from '@api/endpoints/report-type.api';
import { ReportTypeOption } from '@api/types/report-type/report-type.option';
import { useGetCaseReportsQuery } from '@api/endpoints/case-report.api';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseIdNullable } from '@store/rca-editor/selectors';
import { selectRunReportModel } from '@store/reports/reports-selectors';

export enum LensesPanelViewState {
  optionSelect,
  reportForm,
  allRecentLenses,
}

export default function useLensesPanel() {
  const caseId = useAppSelector(selectCurrentRcaCaseIdNullable);
  const currentRunModel = useAppSelector(selectRunReportModel);

  const [viewState, setViewState] = useState<LensesPanelViewState>(
    LensesPanelViewState.optionSelect
  );
  const [selectedReportType, setSelectedReportType] =
    useState<ReportTypeOption>();

  const { data: reportTypeOptions, isLoading: loadingReportTypes } =
    useGetReportTypeOptionsQuery();

  const { data: allReports, isLoading: loadingAllReports } =
    useGetCaseReportsQuery(caseId ?? -1, { skip: caseId == null });

  const isLoading = loadingReportTypes || loadingAllReports || caseId == null;
  const canMoveNext = selectedReportType != null;

  const selectReportType = (option: ReportTypeOption) => {
    setSelectedReportType(option);
  };

  const goToForm = () => {
    setViewState(LensesPanelViewState.reportForm);
  };

  const showAllRecentLenses = () => {
    setViewState(LensesPanelViewState.allRecentLenses);
  };

  const backToOptionsView = () => {
    setViewState(LensesPanelViewState.optionSelect);
  };

  useEffect(() => {
    const model = currentRunModel;
    if (model != null) {
      setSelectedReportType({
        name: model.reportTypeName,
        reportTypeId: model.reportTypeId,
        reportType: model.reportType,
      });
      setViewState(LensesPanelViewState.reportForm);
    } else {
      setSelectedReportType(undefined);
      setViewState(LensesPanelViewState.optionSelect);
    }
  }, [currentRunModel]);

  return {
    selectedReportType,
    selectReportType,
    viewState,
    showAllRecentLenses,
    backToOptionsView,
    isLoading,
    reportTypeOptions,
    allReports,
    canMoveNext,
    goToForm,
  };
}

export type LensesPanelState = ReturnType<typeof useLensesPanel>;
