import { api } from '@api/api';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { Option } from '@api/types/option';
import { PaginatedResult } from '@api/types/paginated-result';
import { CompanyLocationRowResponse } from '@api/types/company/company-location/company-location-row.response';
import { PaginatedFilters } from '@api/types/paginated-filters';
import { CreateCompanyLocationRequest } from '@api/types/company/company-location/create-company-location.request';
import { makeError } from '@api/types/api-error';
import { maybeUpdateCache, SHORT_CACHE_DURATION } from '@api/cache-util';

const companyLocationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLocationsForCompany: build.query<
      PaginatedResult<CompanyLocationRowResponse>,
      PaginatedFilters
    >({
      query: (params) => ({
        url: 'company/companyLocation',
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.companyLocationId,
                type: 'CompanyLocation' as const,
              })),
              'CompanyLocation',
            ]
          : ['CompanyLocation'],
    }),
    createLocationForCompany: build.mutation<
      CompanyLocationResource,
      CreateCompanyLocationRequest
    >({
      query: (body) => ({
        url: 'company/companyLocation',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCompanyLocationRequest>(error),
      invalidatesTags: ['CompanyLocation'],
    }),
    getLocationForCompany: build.query<CompanyLocationResource, number>({
      query: (id) => ({
        url: `company/companyLocation/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (result) =>
        result
          ? [
              {
                id: result.companyLocationId,
                type: 'CompanyLocation' as const,
              },
              'CompanyLocation',
            ]
          : ['CompanyLocation'],
    }),
    deleteLocationForCompany: build.mutation<void, number>({
      query: (companyId) => ({
        url: `company/companyLocation/${companyId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg, type: 'CompanyLocation' as const }, 'CompanyLocation']
          : [],
    }),
    toggleCompanyLocationStatus: build.mutation<void, number>({
      query: (companyLocationId) => ({
        url: `company/companyLocation/${companyLocationId}/ToggleStatus`,
        method: 'PUT',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg, type: 'CompanyLocation' as const }, 'CompanyLocation']
          : [],
      async onQueryStarted(companyLocationId, { queryFulfilled }) {
        const patches = maybeUpdateCache('CompanyLocation', companyLocationId, [
          {
            api: companyLocationApi,
            endpoint: companyLocationApi.endpoints.getLocationsForCompany,
            callback: (data: PaginatedResult<CompanyLocationRowResponse>) => {
              data.model = data.model.map((location) => {
                if (location.companyLocationId === companyLocationId) {
                  location.disabled = !location.disabled;
                }
                return location;
              });
            },
          },
        ]);
        try {
          await queryFulfilled;
        } catch (e) {
          console.log(e);
          patches.forEach((x) => x.undo());
        }
      },
    }),
    getCompanyLocationOptions: build.query<Option[], void>({
      query: () => ({
        url: 'companyLocation/options',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.map((location) => ({
                id: location.id,
                type: 'CompanyLocation' as const,
              })),
              'CompanyLocation',
            ]
          : ['CompanyLocation'],
    }),
    getCompanyLocationDetailOptions: build.query<
      CompanyLocationResource[],
      void
    >({
      query: () => ({
        url: 'companyLocation/DetailOptions',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.map((location) => ({
                id: location.companyLocationId,
                type: 'CompanyLocation' as const,
              })),
              'CompanyLocation',
            ]
          : ['CompanyLocation'],
    }),
  }),
});

export const {
  useGetCompanyLocationOptionsQuery,
  useGetCompanyLocationDetailOptionsQuery,
  useCreateLocationForCompanyMutation,
  useGetLocationForCompanyQuery,
  useDeleteLocationForCompanyMutation,
  useGetLocationsForCompanyQuery,
  useToggleCompanyLocationStatusMutation,
} = companyLocationApi;

export default companyLocationApi;
