import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import { Box, Typography, styled } from '@mui/material';
import { BackToHelpCentre } from './components/back-to-help-centre';
import useArticleDetail from './article-detail-page-hook';
import { useParams } from 'react-router-dom';

export const ArticleDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const state = useArticleDetail(id);

  const { article } = state;

  const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));

  return (
    <Column>
      <ContentContainer>
        <BackToHelpCentre />
        <StyledHeader>
          <Typography variant="h1">{article?.title}</Typography>
        </StyledHeader>
        {article?.content}
      </ContentContainer>
    </Column>
  );
};

export default requireAuth(ArticleDetailPage);
