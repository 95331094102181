import { api } from '@api/api';
import { IHelpCentreCategory } from '@api/types/help-center/help-center-category';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { IHelpCentrePaginatedFilters } from '@api/types/help-center/help-center-paginated-filters';
import { IHelpCentreSearch } from '@api/types/help-center/help-center-search';
import { Option } from '@api/types/option';

const adminHelpCentreApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHelpCentreCategoryById: build.query<IHelpCentreCategory, number>({
      query: (id) => ({
        url: `HelpCentreCategory/${id}`,
      }),
    }),
    getHelpCentreCategory: build.query<Option<string>[], void>({
      query: () => ({
        url: 'HelpCentreCategory',
      }),
    }),
    getHelpCentreContent: build.query<
      IHelpCentreContent[],
      IHelpCentrePaginatedFilters
    >({
      query: ({ ...params }) => ({
        url: 'HelpCentreContent',
        method: 'GET',
        params,
      }),
    }),
    getHelpCentreContentById: build.query<IHelpCentreContent, string>({
      query: (id) => ({
        url: `HelpCentreContent/${id}`,
      }),
    }),
    getHelpCentreContentByIdFile: build.query<Option<string>[], string>({
      query: (id) => ({
        url: `HelpCentreContent/${id}/File`,
      }),
    }),
    getHelpCentreCategoryOptions: build.query<Option<number>[], boolean>({
      query: (isRca) => ({
        url: 'HelpCentreCategory/Options',
        params: {
          isRca,
        },
      }),
    }),
    getHelpCentreSearch: build.query<
      IHelpCentreSearch[],
      IHelpCentrePaginatedFilters
    >({
      query: ({ ...params }) => ({
        url: 'HelpCentreContent/Search',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetHelpCentreSearchQuery,
  useGetHelpCentreCategoryQuery,
  useGetHelpCentreCategoryByIdQuery,
  useGetHelpCentreContentQuery,
  useGetHelpCentreContentByIdQuery,
  useGetHelpCentreContentByIdFileQuery,
  useGetHelpCentreCategoryOptionsQuery,
} = adminHelpCentreApi;
