import { useGetCaseFileDetailQuery } from '@api/endpoints/case-file.api';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { isLocationString } from '@util/string-util';

export default function useCaseFileViewModal(
  caseId: number,
  caseFileId: number
) {
  const { data: caseFileDetail, isLoading: loadingDetail } =
    useGetCaseFileDetailQuery({ caseId, caseFileId });
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const isFile = (caseFileDetail?.fileLength ?? 0) > 0;
  const isUrl = !isFile && isLocationString(caseFileDetail?.fileLocation ?? '');
  const isLoading = loadingDetail;

  return {
    caseFileDetail,
    isFile,
    isUrl,
    isLoading,
    evidence: caseFileDetail?.evidence ?? [],
    solutions: caseFileDetail?.solutions ?? [],
    chainItems: caseFileDetail?.chainItems ?? [],
    currency,
  };
}
