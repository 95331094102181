import { Box, styled, Typography } from '@mui/material';
import { OutlinedButton } from '@components/buttons';
import PaperContainer from '@components/containers/paper-container';
import { ReactNode } from 'react';
import { Gap } from '@components/layout-util-components/gap';
import useBusyAction from '@hooks/use-busy-action-hook';

const LeftBgImage = '/svgs/no-item-left.svg';
const RightBgImage = '/svgs/no-item-right.svg';
const LeftBgImagesm = '/svgs/no-item-left-sm.svg';
const RightBgImagesm = '/svgs/no-item-right-sm.svg';

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  width: 88,
  height: 88,
  background: '#CDF1F3',
  textAlign: 'center',
  borderRadius: 44,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export interface OnboardingEntryContainerProps {
  icon: ReactNode;
  title?: ReactNode;
  message?: ReactNode;
  createMessage?: ReactNode;
  onCreateClicked?: () => Promise<void> | void;
  compact?: boolean;
  dark?: boolean;
  variant?: 'icon' | 'image';
  afterContent?: ReactNode;
  hideBgImage?: boolean;
  backgroundColor?: boolean;
}

export default function OnboardingEntryContainer({
  icon,
  title,
  message,
  createMessage,
  onCreateClicked,
  compact,
  dark,
  variant = 'icon',
  afterContent,
  hideBgImage,
  backgroundColor,
}: OnboardingEntryContainerProps) {
  const [onCreate, isBusy] = useBusyAction(async () => {
    const maybePromise = onCreateClicked?.();
    if (maybePromise instanceof Promise) {
      await maybePromise;
    }
  });
  return (
    <PaperContainer
      sx={({ palette }) => ({
        padding: compact ? '20px !important' : undefined,
        backgroundColor: dark ? palette.common.grey15 : undefined,
        borderRadius: '4px',
        background:
          variant === 'image' || hideBgImage
            ? '#fff !important'
            : compact
            ? 'none'
            : `url(${
                backgroundColor ? LeftBgImagesm : LeftBgImage
              }) no-repeat left center, url(${
                backgroundColor ? RightBgImagesm : RightBgImage
              }) no-repeat top right ${backgroundColor ? '#FFF9EF' : '#FFF'}`,
      })}
    >
      {variant === 'icon' && (
        <StyledBox
          sx={({ palette }) => ({
            '> svg': {
              maxWidth: 88,
              maxHeight: 88,
            },
          })}
        >
          {icon}
        </StyledBox>
      )}
      {variant === 'image' && icon}
      <Gap size={32} />
      <Typography variant="subtitle1" component="p">
        {title}
      </Typography>
      {message && (
        <>
          <Gap size={20} />
          <Typography variant="body2">{message}</Typography>
        </>
      )}
      {!!createMessage && onCreateClicked != null ? (
        <>
          <Gap size={32} />
          <OutlinedButton isBusy={isBusy} onClick={onCreate}>
            {createMessage}
          </OutlinedButton>
        </>
      ) : null}
      {afterContent != null ? (
        <>
          <Gap size={32} />
          {afterContent}
        </>
      ) : null}
    </PaperContainer>
  );
}
