import { styled } from '@mui/material';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

const Container = styled('div')({
  position: 'absolute',
  top: 20,
  left: 20,
  padding: 4,
  backgroundColor: 'white',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10000,
});

export default function ChartBusyIndicator() {
  return (
    <Container>
      <FetchingIndicator show />
    </Container>
  );
}
