import { useAppDispatch } from '@store/store';
import { useEffect } from 'react';
import { setIsHelpPanelOpen } from '@store/rca-editor/rca-editor-slice';
import { setNotificationsOpen } from '@store/notifications/notifications-slice';

export enum SingleUsePanel {
  notifications,
  help,
}

export default function useHideConflictingPanels(
  forPanel: SingleUsePanel,
  show: boolean
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!show) return;

    switch (forPanel) {
      case SingleUsePanel.notifications:
        dispatch(setIsHelpPanelOpen(false));
        break;
      case SingleUsePanel.help:
        dispatch(setNotificationsOpen(false));
        break;
    }
  }, [dispatch, forPanel, show]);
}
