import { Outlet, useNavigate } from 'react-router-dom';
import { Link, styled, Typography } from '@mui/material';
import { CircleButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import { useEffect, useState } from 'react';
import { useIsPath } from '@util/path-util';
import AppLogo from '@assets/svgs/app-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBuildings,
  faChevronLeft,
  faHardDrive,
  faHome,
} from '@fortawesome/pro-light-svg-icons';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import MenuItemProfile from '@components/menu-items/app/menu-item-profile';
import Column from '@components/layout-util-components/column';
import { PageAlertDisplay } from '@components/alerts';

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#ECEDF0',
}));

const SideNav = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  width: isOpen ? 275 : 80,
  height: '100vh',
  padding: isOpen ? '14px 0 0px 0' : '14px 15px 0px 15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '0 0 auto',
  backgroundColor: 'white',
  transition: 'all 0.2s ease-in-out',
  '@media (max-width:768px)': {
    position: 'fixed',
    left: isOpen ? 0 : '-80px',
    zIndex: 100,
    paddingTop: 70,
    boxShadow: '0 0 10px rgba(0,0,0,0.25)',
  },

  '& > .nav-top': {
    width: isOpen ? 215 : 52,
    height: '100px',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    flexShrink: 0,

    '.sub-title': {
      transform: isOpen ? 'translateX(10px)' : 'translateX(0)',
      transition: 'all 0.2s ease-in-out',
    },

    '@media (max-width:768px)': {
      position: 'fixed',
      borderBottom: '1px solid #D8DBE3',
      left: 0,
      top: 0,
      width: '100%',
      zIndex: 101,
      textAlign: 'center',
      background: '#fff',
      height: 80,
    },
  },

  '& .nav-logo': {
    transition: 'all 0.2s ease-in-out',
    width: isOpen ? 80 : 52,
    height: isOpen ? 80 : 52,
    position: 'relative',
    zIndex: 3,
    '@media (max-width:768px)': {
      width: 52,
      height: 80,
    },
  },

  '& .menu-item-row': {
    height: 48,
    width: isOpen ? null : 48,
    borderRadius: 8,
    borderLeft: isOpen ? null : 0,
    paddingLeft: 14,
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 5,
    svg: {
      width: isOpen ? 31 : 'auto',
    },
  },

  '& .menu-item-descriptor': {
    display: 'block',
    overflow: 'hidden',
    opacity: isOpen ? '1' : '0',
    width: isOpen ? '100%' : '0px',
    transition: 'all 0.2s ease-in-out',
    '& p': {
      width: 155,
    },
  },

  '& .menu-item-profile': {
    padding: isOpen ? '15px 30px' : '15px 0',
    '& .name-container': {
      width: isOpen ? 'auto' : 0,
      display: isOpen ? 'block' : 'none',
    },
  },
}));

const ButtonWrapper = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  zIndex: 2,
  right: 0,
  top: isOpen ? 0 : 23,
  height: '100px',
  paddingTop: isOpen ? 22.5 : 65,
  paddingRight: isOpen ? '0' : '8.5px',
  transition: 'all 0.2s ease-in-out',
  '@media (max-width:768px)': {
    right: 'auto',
    left: 30,
    top: 0,
    paddingTop: 22.5,
  },
}));

const NavItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',
  gap: 8,
});

const OutletContainer = styled('div')<{ isNavOpen: boolean }>(
  ({ isNavOpen }) => ({
    transition: 'all 0.2s ease-in-out',
    flex: '1 0 auto',
    marginLeft: isNavOpen ? 275 : 80,
    width: 'calc(100vw - 275px)',
    overflowY: 'auto',
    '@media (max-width:768px)': {
      marginLeft: 0,
      marginTop: 80,
    },
  })
);

export default function WCTAdminLayout() {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(() => {
    return localStorage?.getItem('openNav') === 'true';
  });

  const isDashboard = useIsPath(['/', '/dashboard', '/home']);
  const isCompanies = useIsPath('/companies', { startsWith: true });
  const isInstalls = useIsPath('/installs', { startsWith: true });

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    localStorage?.setItem('openNav', openNav ? 'true' : 'false');
  }, [openNav]);

  return (
    <>
      <PageContainer className={openNav ? 'nav-open' : 'nav-closed'}>
        <SideNav isOpen={openNav}>
          <div className="nav-top">
            <Link href="/">
              <img className="nav-logo" src={AppLogo} alt="logo" />
            </Link>
            <Column>
              <Gap size={8} />
              <Typography variant="h5" className="sub-title">
                ADMIN
              </Typography>
              <Gap size={8} />
            </Column>
            <ButtonWrapper isOpen={openNav}>
              <CircleButton onClick={toggleNav}>
                <FontAwesomeIcon icon={openNav ? faChevronLeft : faBars} />
              </CircleButton>
            </ButtonWrapper>
          </div>
          <Gap size={32} />
          <NavItemsContainer>
            <MenuItemRow
              onClick={() => navigate('/')}
              isSelected={isDashboard}
              icon={<FontAwesomeIcon icon={faHome} />}
            >
              Dashboard
            </MenuItemRow>
            <MenuItemRow
              onClick={() => navigate('/companies')}
              isSelected={isCompanies}
              icon={<FontAwesomeIcon icon={faBuildings} />}
            >
              Companies
            </MenuItemRow>
            <MenuItemRow
              onClick={() => navigate('/installs')}
              isSelected={isInstalls}
              icon={<FontAwesomeIcon icon={faHardDrive} />}
            >
              Installs
            </MenuItemRow>
          </NavItemsContainer>
          <MenuItemProfile isSideDrawerOpen={openNav} />
        </SideNav>
        <OutletContainer isNavOpen={openNav}>
          <Column>
            <PageAlertDisplay ignoreError absolute />
          </Column>
          <Outlet />
        </OutletContainer>
      </PageContainer>
    </>
  );
}
