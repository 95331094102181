import { api } from '@api/api';
import { CaseImpactTypeOption } from '@api/types/case-impact-type/case-impact-type.option';
import { EntityChainItemDetailResponse } from '@api/types/chain/entity-chain-item-detail.response';

const caseImpactTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseImpactTypes: build.query<CaseImpactTypeOption[], number>({
      query: (caseId: number) => ({
        url: 'caseImpactType',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
    getDetailedCaseImpactTypes: build.query<
      EntityChainItemDetailResponse[],
      number
    >({
      query: (caseId: number) => ({
        url: 'caseImpactType/detailedOptions',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
  }),
});

export const {
  useGetCaseImpactTypesQuery,
  useGetDetailedCaseImpactTypesQuery,
} = caseImpactTypeApi;
