import { Chip, styled, useTheme } from '@mui/material';
import OutcomeColoredBadge from '@components/outcome-report/components/outcome-colored-badge';

interface Props {
  severity: string;
}

const StyledChip = styled(Chip)<{ bg?: string; textCol?: string }>(
  ({ bg, textCol, theme: { palette } }) => ({
    borderRadius: '4px',
    cursor: 'pointer',
    background: bg || '#F8F8FA',
    display: 'flex',
    height: '30px',
    padding: '4px 6px',
    maxWidth: '115px',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    '> span': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      p: {
        fontSize: 12,
        color: textCol || '#525B5E',
      },
    },
  })
);

export default function RcaSeverityPill({ severity }: Props) {
  const { palette } = useTheme();

  const severityBgColor =
    {
      'very low': `${palette.blue.dark}26`,
      low: `${palette.blue.dark}26`,
      medium: `${palette.primary.main}26`,
      high: `${palette.error.main}26`,
      'very high': `${palette.error.main}26`,
    }[severity.toLowerCase()] || '#F8F8FA';

  const severityColor =
    {
      'very low': palette.blue.dark,
      low: palette.blue.dark,
      medium: palette.primary.main,
      high: palette.error.main,
      'very high': palette.error.main,
    }[severity.toLowerCase()] || '#F8F8FA';

  const indicatorColor =
    {
      'very low': palette.blue.dark,
      low: palette.blue.dark,
      medium: palette.primary.main,
      high: palette.error.main,
      'very high': palette.error.main,
    }[severity.toLowerCase()] || '#F8F8FA';

  const borderColor =
    {
      'very low': palette.blue.dark,
      low: palette.blue.dark,
      medium: palette.primary.main,
      high: palette.error.main,
      'very high': palette.error.main,
    }[severity.toLowerCase()] || '#F8F8FA';

  return (
    <OutcomeColoredBadge
      bgColor={severityBgColor}
      textColor={severityColor}
      indicatorColor={indicatorColor}
      borderColor={borderColor}
      label={severity}
    />
  );
}
