import { api } from '@api/api';
import { Option } from '@api/types/option';

const languageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLanguageOptions: build.query<Option[], void>({
      query: () => 'language/options',
    }),
  }),
});

export const { useGetLanguageOptionsQuery } = languageApi;
