import { Box, Button, Grid, Popover, Typography, styled } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useCommonTr } from '@i18n/use-common-tr';
import {
  SortOption,
  SortValueType,
} from '@components/table/hooks/use-sortable-header-hook';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  value?: SortValueType;
  onChange: (value?: SortOption) => void;
  options: Array<SortOption>;
}

const PopoverContent = styled(Grid)({
  minWidth: 192,
  padding: 17,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.textDark,
  textTransform: 'none',
  textWrap: 'nowrap',
  height: '36px',
  fontSize: 14,
  '&>div': {
    marginLeft: 8,
  },
}));

export default function SortByFilter({ value, onChange, options }: Props) {
  const { t } = useCommonTr('input');

  const buttonRef = useRef<HTMLButtonElement | null>();
  const [isOpen, setIsOpen] = useState(false);

  const selectedLabel = useMemo(() => {
    return options.find(
      (x) => x.id === value?.id && x.direction === value?.direction
    )?.label;
  }, [options, value]);

  const select = (option: SortOption) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <>
      <StyledButton
        ref={(b) => (buttonRef.current = b)}
        onClick={() => setIsOpen((v) => !v)}
      >
        {t('sort.label')}
        <Typography fontWeight={600} marginLeft={1} fontSize={14}>
          {selectedLabel ? ` ${selectedLabel} ` : null}
        </Typography>
        <Box alignSelf="center">
          <FontAwesomeIcon icon={faCaretDown} />
        </Box>
      </StyledButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsOpen(false)}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContent
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="start"
        >
          {options.map((option, index) => (
            <Button
              key={`${option.id}(${index + 1})`}
              variant="text"
              onClick={() => select(option)}
            >
              {option.label}
            </Button>
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
}
