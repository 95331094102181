import { styled } from '@mui/material';

export type GapSize =
  | 2
  | 4
  | 6
  | 8
  | 10
  | 12
  | 15
  | 20
  | 24
  | 32
  | 50
  | 64
  | 128;

type Props = {
  size: GapSize;
};

export const Gap = styled('div')<Props>(({ size }) => ({
  flex: `0 0 ${size}px`,
}));
