import { PropsWithChildren } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '@assets/svgs/close.svg';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onClose: (e: any) => void;
  onBack?: () => void;
}

const StyledContainer = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'space-between',
  gap: 24,
  alignItems: 'center',
});

const StyledButton = styled('button')({
  display: 'inline-flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  border: 'none',
  cursor: 'pointer',
  borderRadius: 16,
  backgroundColor: '#F9F4F1',
  svg: {
    fill: 'rgba(0, 0, 0, 0.87)',
    height: '10px',
    width: 'auto',
  },
});

export default function DrawerHeader({
  children,
  onClose,
  onBack,
}: PropsWithChildren<Props>) {
  return (
    <StyledContainer>
      {onBack != null ? (
        <FlatButton onClick={onBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </FlatButton>
      ) : null}
      <Box justifySelf="flex-start" flexGrow={1}>
        <Typography variant="h2">{children}</Typography>
      </Box>
      <StyledButton type="button" onClick={(e) => onClose(e)}>
        <CloseIcon />
      </StyledButton>
    </StyledContainer>
  );
}
