import { api } from '@api/api';
import { Option } from '@api/types/option';

const efficacyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEfficacyOptions: build.query<Option[], void>({
      query: () => ({
        url: 'efficacy/options',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetEfficacyOptionsQuery } = efficacyApi;
