import { ReactComponent as RcaIcon } from '@assets/svgs/rca-icon.svg';
import NoContentContainer from '@components/containers/no-content-container';

export default function NoRcaForGroupInfo() {
  return (
    <NoContentContainer
      icon={<RcaIcon height={70} />}
      title="This group has no RCA's"
    />
  );
}
