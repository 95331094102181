import useField from '@hooks/use-field-hook';
import { required, validEmail } from '@util/validators';
import { useForgottenPasswordMutation } from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { ResendEmailConfirmationRequest } from '@api/types/user/resend-email-confirmation.request';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useForgotPassword() {
  const { showErrorMessage } = usePageAlertVariants();

  const [forgotPassword, { isLoading }] = useForgottenPasswordMutation();

  const email = useField<string>([required(), validEmail()]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([email]);
  const canSubmit = isValid && isDirty && !isLoading;

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return forgotPassword({
      emailAddress: email.value,
    })
      .unwrap()
      .then(() => true)
      .catch(
        ({ message, errors }: ApiError<ResendEmailConfirmationRequest>) => {
          showErrorMessage(message);
          email.setError(errors?.emailAddress);
          return false;
        }
      );
  };

  return {
    email,
    canSubmit,
    isLoading,
    submit,
  };
}
