import { ReactComponent as NoTasksIcon } from '@assets/svgs/no-tasks.svg';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

export default function NoFilteredTasksInfo() {
  return (
    <OnboardingEntryContainer
      icon={<NoTasksIcon />}
      title="No Tasks to display"
      message="Adjust the filters to view tasks."
    />
  );
}
