import { FlatButton } from '@components/buttons';
import { useState } from 'react';
import ChartStorageNode from '@pages/app/rca/tabs/components/storage/chart-storage-node';
import { useAppDispatch, useAppSelector } from '@store/store';
import { addNodeToStorage } from '@store/rca-editor/rca-editor-actions';
import { isNullOrEmpty } from '@util/string-util';
import { selectChainId } from '@store/rca-editor/selectors';
import { ApiError } from '@api/types/api-error';
import { CreateStorageItemRequest } from '@api/types/chain/chain-item-storage/create-storage-item.request';
import { usePageAlertVariants } from '@components/alerts';
import { useCreateStorageItemMutation } from '@api/endpoints/chain/chain-item-storage.api';
import Column, { ColumnForm } from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import { CircularProgress, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onClose: () => void;
}

const Container = styled(ColumnForm)({});

export default function ChartStorageAddNode({ onClose }: Props) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const chainId = useAppSelector(selectChainId);
  const dispatch = useAppDispatch();

  const [description, setDescription] = useState<string>();

  const [createItem, { isLoading: isSubmitting }] =
    useCreateStorageItemMutation();

  const onCreate = async () => {
    if (!isNullOrEmpty(description)) {
      try {
        const node = await createItem({
          chainId: chainId!,
          description: description!,
          tasksNA: false,
        }).unwrap();

        dispatch(addNodeToStorage(node));
        showSuccessMessage(`Added cause box "${description}" to storage`);
        onClose();
      } catch (e) {
        const { errors, message } = e as ApiError<
          CreateStorageItemRequest & { chainId: number }
        >;

        showErrorMessage(
          errors?.chainId ?? errors?.tasksNA ?? errors?.description ?? message
        );
      }
    }
  };

  return (
    <Container
      sx={{ flex: '0 0 auto', minHeight: 'unset' }}
      onSubmit={(e) => {
        e.preventDefault();
        onCreate();
      }}
    >
      <ChartStorageNode
        onSubmitDescription={onCreate}
        onEditDescription={setDescription}
        description={description}
        actionButton={
          isSubmitting ? (
            <CircularProgress size={18} />
          ) : (
            <Column>
              <FlatButton
                disabled={isSubmitting}
                onClick={() => {
                  onClose();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </FlatButton>
              <Gap size={4} />
              <FlatButton
                type="submit"
                disabled={isNullOrEmpty(description) || isSubmitting}
              >
                <FontAwesomeIcon icon={faCheck} />
              </FlatButton>
            </Column>
          )
        }
      />
    </Container>
  );
}
