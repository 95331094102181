import { Box, Grid, Typography } from '@mui/material';
import useConfirmEmailRequest from '@pages/auth/confirm-email-request-hook';
import { useAuthTr } from '@i18n/use-auth-tr';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { LoadingIndicator } from '@components/loading-indicator';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';
import { PrimaryButton } from '@components/buttons';

export default function ConfirmEmailRequestPage() {
  const { email, resendEmail, isSending } = useConfirmEmailRequest();

  const { t } = useAuthTr('confirmEmailRequest');

  return (
    <Grid container direction="column" justifyContent="center">
      <Typography variant="h2" style={{ fontSize: '32px' }}>
        {t('title')}
      </Typography>
      <Gap size={24} />
      <Typography variant="body1">{t('message', { email })}</Typography>
      <Gap size={24} />

      <PageAlertDisplay />
      <Gap size={24} />

      <PrimaryButton
        onClick={resendEmail.call}
        disabled={isSending || resendEmail.isCoolingDown}
        size="large"
      >
        {t('buttonLabel')} <LoadingIndicator show={isSending} />
      </PrimaryButton>
      {resendEmail.isCoolingDown && (
        <>
          <Gap size={8} />
          <Box alignSelf="flex-end">
            <Typography variant="body2" color="textSecondary">
              Not received an email? Try again in {resendEmail.timeLeft} seconds
            </Typography>
          </Box>
        </>
      )}
      <Gap size={24} />

      <ContactSupportLink />
    </Grid>
  );
}
