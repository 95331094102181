import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { useAppTr } from '@i18n/use-app-tr';
import { Grid } from '@mui/material';
import useGroupDetail, {
  ActiveTab,
} from '@pages/app/profile/components/group-detail-hook';
import LabelValueDisplay from '@components/data/label-value-display';
import { Divider } from '@components/layout-util-components/divider';
import GroupMemberRow from '@pages/app/profile/components/group-member-row';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import {
  NavTabContainer,
  NavTabHeader,
} from '@components/menu-items/app/nav-tab-header';
import { useMemo } from 'react';
import NoRcaForGroupInfo from './no-rca-for-group-info';
import { Gap } from '@components/layout-util-components/gap';
import { OutlinedButton } from '@components/buttons';
import GridSkeleton from '@components/skeletons/grid-skeleton';
import RcaCard from '@components/cards/rca-card';
import { CardLayout } from '@components/layout-util-components/card-layout';
import Column from '@components/layout-util-components/column';
import { WCTTable } from '@components/table';

export interface GroupDetailViewProps extends SideDrawerFormProps<void> {
  id: number;
}

function GroupDetailView({ onClose, id }: GroupDetailViewProps) {
  const { t } = useAppTr('groups');

  const {
    isLoading,
    group,
    isLoadingCases,
    caseData,
    activeTab,
    setActiveTab,
    leaveGroup,
    currentUserIsMember,
  } = useGroupDetail(id);

  const tabs = useMemo(
    () => [
      {
        label: `RCA ${!isLoadingCases && `(${caseData?.totalCount ?? 0})`}`,
        isActive: activeTab === ActiveTab.RCA,
        onClick: () => setActiveTab(ActiveTab.RCA),
      },
      {
        label: `Team Members ${
          !isLoading && `(${group!.groupMembers.length})`
        }`,
        isActive: activeTab === ActiveTab.TeamMember,
        onClick: () => setActiveTab(ActiveTab.TeamMember),
      },
    ],
    [activeTab, setActiveTab, isLoading, isLoadingCases, caseData, group]
  );

  return (
    <>
      <DrawerHeader onClose={() => onClose()}>{group?.name}</DrawerHeader>
      <DrawerContent>
        <Column>
          <Grid item container>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label={t('detail.sections.createdByLabel')}
                  value={group?.createdByUserName ?? ''}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label={t('detail.sections.dateCreatedLabel')}
                  value={
                    group
                      ? t('detail.sections.dateCreated', {
                          created: group!.created,
                        })
                      : null
                  }
                />
              )}
            </Grid>
          </Grid>
          <Gap size={20} />
          <Grid item>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label={t('detail.sections.descriptionLabel')}
                value={group!.description ?? ''}
              />
            )}
          </Grid>
          <Divider addMargin compact />
          <Gap size={20} />
          <NavTabContainer>
            {tabs.map((t) => (
              <NavTabHeader
                key={t.label}
                onClick={t.onClick}
                isSelected={t.isActive}
              >
                {t.label}
              </NavTabHeader>
            ))}
          </NavTabContainer>
          <Gap size={20} />
          {activeTab === ActiveTab.RCA && (
            <>
              {/*{!isLoadingCases && (*/}
              {/*  <WCTTableFilters*/}
              {/*    isLoading={isLoading}*/}
              {/*    isFetching={isFetching}*/}
              {/*    search={search.value}*/}
              {/*    onSearch={search.set}*/}
              {/*  >*/}
              {/*    <DropDownFilter*/}
              {/*      value={type.value}*/}
              {/*      onChange={type.set}*/}
              {/*      options={caseTypeOptions ?? []}*/}
              {/*    >*/}
              {/*      Type*/}
              {/*    </DropDownFilter>*/}
              {/*    <DropDownFilter*/}
              {/*      value={status.value}*/}
              {/*      onChange={status.set}*/}
              {/*      options={statusOptions ?? []}*/}
              {/*    >*/}
              {/*      Status*/}
              {/*    </DropDownFilter>*/}
              {/*    <DropDownFilter*/}
              {/*      value={severity.value}*/}
              {/*      onChange={severity.set}*/}
              {/*      options={severityOptions ?? []}*/}
              {/*    >*/}
              {/*      Severity*/}
              {/*    </DropDownFilter>*/}
              {/*  </WCTTableFilters>*/}
              {/*)}*/}
              {isLoadingCases ? (
                <GridSkeleton count={1} itemHeight={300} />
              ) : caseData!.totalCount > 0 ? (
                <CardLayout
                  sx={{
                    '.content-footer-card-outer-container': {
                      width: 'calc(50% - 14.5px) !important',
                      '&:hover': {
                        width: 'calc(50% - 12.5px) !important',
                      },
                      '.status': {
                        'p.MuiTypography-body1': {
                          fontSize: '13px',
                        },
                      },
                    },
                  }}
                >
                  {caseData!.model.map((rca) => (
                    <RcaCard
                      key={rca.caseId}
                      data={rca}
                      afterClickRedirect={onClose}
                    />
                  ))}
                </CardLayout>
              ) : (
                <NoRcaForGroupInfo />
              )}
            </>
          )}
          {activeTab === ActiveTab.TeamMember && (
            <>
              <WCTTable
                count={group?.groupMembers.length ?? 0}
                isLoading={isLoading}
                headers={[
                  {
                    title: 'Name',
                  },
                  {
                    title: 'Kudos Level',
                  },
                ]}
              >
                {group?.groupMembers.map((member) => (
                  <GroupMemberRow
                    key={member.companyUserId}
                    member={member}
                    isAdmin={member.isAdmin}
                  />
                ))}
              </WCTTable>
            </>
          )}
        </Column>
      </DrawerContent>
      <DrawerFooter>
        {currentUserIsMember && (
          <OutlinedButton onClick={leaveGroup}>Leave group</OutlinedButton>
        )}
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(GroupDetailView);
