import { InlineBoxSkeleton } from '@components/skeletons';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/system';

interface Props {
  title: string;
  count?: number;
  isLoading: boolean;
  variant?: Variant;
  sx?: SxProps;
}

export default function PageCount({
  title,
  count,
  isLoading,
  variant = 'h1',
  sx,
}: Props) {
  if (isLoading) {
    return (
      <Typography variant={variant} sx={sx}>
        {title} <InlineBoxSkeleton width={24} />
      </Typography>
    );
  }

  const nonNullCount = count ?? 0;

  return (
    <Typography variant={variant} sx={sx}>
      {nonNullCount <= 0 ? `${title}` : `${title} (${nonNullCount})`}
    </Typography>
  );
}
