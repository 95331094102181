import { styled } from '@mui/material';

export const StatusPanel = styled('div')({
  position: 'absolute',
  top: '10%',
  left: '30%',
  padding: '56px 72px',
  width: '40%',
  backgroundColor: 'white',
  borderRadius: 12,
  boxShadow: '0px 4px 36px 0px #0000001A',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});
