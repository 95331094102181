import useUser from '@store/user/user-hook';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function LogoutCapturePage() {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useUser();

  useEffect(() => {
    if (isLoggedIn) {
      logout();
    } else {
      navigate('/auth/login');
    }
  }, [isLoggedIn, logout, navigate]);

  return <p>Logging out...</p>;
}

export default requireAuth(LogoutCapturePage, { mfaRequired: false });
