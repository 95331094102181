import { api } from '@api/api';
import { SubscriptionPaymentDetailsPortalResponse } from '@api/types/company/subscription/subscription-payment-details-portal.response';
import { makeError } from '@api/types/api-error';
import { BillingPortalUrlType } from '@api/types/company/subscription/billing-portal-url-type';

const companySubscriptionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionPaymentDetailsUrl: build.query<
      SubscriptionPaymentDetailsPortalResponse,
      BillingPortalUrlType
    >({
      query: (type) => ({
        url: `company/subscription/getBillingPortalUrl/?linkType=${type}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (error) => makeError<never>(error),
    }),
  }),
});

export const { useLazyGetSubscriptionPaymentDetailsUrlQuery } =
  companySubscriptionApi;
