import { useMemo, useState } from 'react';

export interface Filter<T> {
  value: T | undefined;

  // String form of array, i.e. [1,2,3] => "1,2,3"
  arrayValue: T | undefined;
  set: (value: T | undefined) => void;
}

export default function useFilter<T>(initialValue?: T): Filter<T> {
  const [value, set] = useState<T | undefined>(initialValue as T);

  const arrayValue = useMemo(() => {
    if (value == null) {
      return undefined;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return undefined;
      }

      return value;
    }
  }, [value]);

  return useMemo(() => {
    return {
      value,
      arrayValue,
      set,
    };
  }, [arrayValue, value]);
}
