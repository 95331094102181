import { Box, Typography, Button, SxProps, Theme, styled } from '@mui/material';

const StyledBoxBannerWrapper = styled(Box)(({ theme }) => ({
  mb: theme.spacing(6),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  backgroundColor: '#909090',
  borderRadius: theme.spacing(3),
}));

const StyledBoxBanner = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

interface Props {
  sx?: SxProps<Theme>;
}

export const BecomeAnExpertBanner = ({ sx }: Props) => {
  return (
    <StyledBoxBannerWrapper sx={sx}>
      <StyledBoxBanner>
        <Box mb={2}>
          <Typography mb={1} variant="subtitle1" color="#fff">
            Become an expert
          </Typography>
          <Typography color="#fff">
            We offer personalised training to take your skills to the next level
          </Typography>
        </Box>
        <Button variant="outlined" sx={{ borderColor: '#fff', color: '#fff' }}>
          Learn More
        </Button>
      </StyledBoxBanner>
      <Box sx={{ backgroundColor: '#fff', height: '150px', width: '150px' }} />
    </StyledBoxBannerWrapper>
  );
};
