import { api } from '@api/api';
import { Option } from '@api/types/option';

const timezoneApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTimezoneOptions: build.query<Option[], void>({
      query: () => 'timezone/options',
    }),
  }),
});

export const { useGetTimezoneOptionsQuery } = timezoneApi;
