import { useGetGroupsForCompanyQuery } from '@api/endpoints/company/company-company-group.api';
import { useFilter, useSortableHeader } from '@components/table';
import useLoadMore from '@hooks/use-load-more-hook';
import { SortDirection } from '@api/types/sort-direction';
import { useAppTr } from '@i18n/use-app-tr';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import { usePermissions } from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';

export default function useManageGroups() {
  const { t } = useAppTr('groups');

  const search = useFilter<string>();
  const companyUser = useFilter<number>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const canCreateGroup = usePermissions([Permission.manageCompanyGroups]);

  const sort = useSortableHeader({ initialProperty: 'groupName' });

  const { data: userOptions, isLoading: loadingUserOptions } =
    useGetCompanyUsersOptionsQuery({});

  const sortByOptions: Array<SortOption> = [
    {
      id: 'groupName',
      label: t('admin.sort.nameAsc'),
      direction: SortDirection.asc,
    },
    {
      id: 'groupName',
      label: t('admin.sort.nameDesc'),
      direction: SortDirection.desc,
    },
    {
      id: 'additionalUsers',
      label: t('admin.sort.memberCountAsc'),
      direction: SortDirection.asc,
    },
    {
      id: 'additionalUsers',
      label: t('admin.sort.memberCountDesc'),
      direction: SortDirection.desc,
    },
  ];

  const {
    data: groups,
    isLoading: loadingGroups,
    isFetching,
  } = useGetGroupsForCompanyQuery({
    searchText: search.value,
    skip,
    take,
    sortBy: sort.property,
    sortDirection: sort.direction,
    companyUserId: companyUser.value,
  });

  const isLoading = loadingGroups || loadingUserOptions;

  return {
    isLoading,
    isFetching,
    groups,
    search,
    loadMore,
    sortByOptions,
    sort,
    showLoadMoreButton: canLoadMore(groups?.totalCount ?? 0),
    companyUser,
    userOptions,
    loadingGroups,
    hasNoGroups: groups && groups!.totalCount === 0,
    hasNoFilteredGroups:
      groups && groups!.totalCount > 0 && groups!.count === 0,
    hasFilteredGroups: groups && groups!.totalCount > 0 && groups!.count > 0,
    canCreateGroup,
  };
}
