import { styled, Typography } from '@mui/material';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { ReactNode, useState } from 'react';
import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import ActionsRow from '../../layout-util-components/actions-row';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

type YesButtonAsyncAction = () => Promise<boolean>;

export interface ConfirmationModalProps extends ModalProps<boolean> {
  title?: ReactNode;
  message: ReactNode | ReactNode[];
  yesButtonLabel?: ReactNode;
  noButtonLabel?: ReactNode;
  fillNoButton?: boolean;
  yesButtonAsyncAction?: YesButtonAsyncAction;
  otherActions?: RenderOtherActions;
  otherActionsAtEnd?: boolean;
}

export type RenderOtherActions = (
  props: Pick<ConfirmationModalProps, 'onClose'>
) => ReactNode;

const Container = styled('div')({
  minWidth: 400,
  display: 'flex',
  flexDirection: 'column',
});

const ConfirmationModal = ({
  title,
  message,
  yesButtonLabel,
  noButtonLabel,
  yesButtonAsyncAction,
  onClose,
  fillNoButton,
  otherActions,
  otherActionsAtEnd = false,
}: ConfirmationModalProps) => {
  const [isBusy, setIsBusy] = useState(false);
  const messages = Array.isArray(message) ? message : [message];

  const onYesClicked = async () => {
    if (yesButtonAsyncAction == null) {
      onClose(true);
      return;
    }

    setIsBusy(true);
    try {
      const result = await yesButtonAsyncAction();
      if (result) {
        onClose(true);
      } else {
        setIsBusy(false);
      }
    } catch (_) {
      setIsBusy(false);
    }
  };

  return (
    <Container>
      {title ? <Typography typography="h2">{title}</Typography> : null}
      <Gap size={15} />
      {messages.map((m) => (
        <>
          <Typography typography="body1">{m}</Typography>
          <Gap size={24} />
        </>
      ))}
      <ActionsRow>
        {otherActions && !otherActionsAtEnd ? (
          <>
            {otherActions({ onClose })}
            <Spacer />
          </>
        ) : null}
        {fillNoButton ? (
          <PrimaryButton onClick={() => onClose(false)}>
            {noButtonLabel ? noButtonLabel : 'No'}
          </PrimaryButton>
        ) : (
          <OutlinedButton onClick={() => onClose(false)}>
            {noButtonLabel ? noButtonLabel : 'No'}
          </OutlinedButton>
        )}
        <PrimaryButton isBusy={isBusy} onClick={onYesClicked}>
          {yesButtonLabel ? yesButtonLabel : 'Yes'}
        </PrimaryButton>
        {otherActions && otherActionsAtEnd ? otherActions({ onClose }) : null}
      </ActionsRow>
    </Container>
  );
};

export default withModal(ConfirmationModal);
