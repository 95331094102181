import { api } from '@api/api';
import { EntityChainItemDetailResponse } from '@api/types/chain/entity-chain-item-detail.response';
import { NestedOption } from '@api/types/nested-option';
import { Option } from '@api/types/option';

const themeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThemeOptions: build.query<Option[], number>({
      query: (caseId: number) => ({
        url: 'theme/options',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
    getNestedThemeOptions: build.query<NestedOption[], number>({
      query: (caseId: number) => ({
        url: 'theme/nestedOptions',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
    getThemeDetailedOptions: build.query<
      EntityChainItemDetailResponse[],
      number
    >({
      query: (caseId: number) => ({
        url: 'theme/detailedOptions',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
  }),
});

export const {
  useGetThemeOptionsQuery,
  useGetNestedThemeOptionsQuery,
  useGetThemeDetailedOptionsQuery,
} = themeApi;
