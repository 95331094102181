import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertInfo } from '@components/alerts/hooks/page-alert-hook';

interface UIState {
  isCurrentFormDirty: boolean;
  alert?: AlertInfo;
}

const initialState: UIState = {
  isCurrentFormDirty: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCurrentFormDirty(state, { payload }: PayloadAction<boolean>) {
      state.isCurrentFormDirty = payload;
    },
    setAlert(state, { payload }: PayloadAction<AlertInfo>) {
      state.alert = payload;
    },
    resetAlert(state) {
      state.alert = undefined;
    },
  },
});

export default uiSlice.reducer;

export const { setCurrentFormDirty, setAlert, resetAlert } = uiSlice.actions;
