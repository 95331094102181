import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { Skeleton, styled, Typography } from '@mui/material';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import WCTTextField from '@components/input/text-field';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { useAppTr } from '@i18n/use-app-tr';
import useFunctionalLocationForm from '@pages/app/company-profile/forms/functional-location-form-hook';
import WCTAddressField from '@components/input/address-field';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { CreateCompanyLocationRequest } from '@api/types/company/company-location/create-company-location.request';
import BinarySelectField from '@components/input/binary-select-field';
import LatLngSelectorMap from '@components/google-map/lat-lng-selector-map';
import { reverseGeocode } from '@util/address-util';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';

export interface FunctionalLocationFormProps
  extends SideDrawerFormProps<CompanyLocationResource> {
  functionalLocationId?: number;
  allowPinOnMap?: boolean;
  creationMutation: UseMutation<
    MutationDefinition<CreateCompanyLocationRequest, any, any, any>
  >;
  isIncidentLocation?: boolean;
}

const Form = styled('form')({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  alignItems: 'stretch',
});

function FunctionalLocationForm({
  onClose,
  functionalLocationId,
  allowPinOnMap,
  creationMutation,
  isIncidentLocation,
}: FunctionalLocationFormProps) {
  const { t } = useAppTr('functionalLocationForm');

  const {
    isEdit,
    canSubmit,
    isBusy,
    title,
    description,
    address,
    isLoading,
    countryOptions,
    submit,
    shouldUseMap,
    setShouldUseMap,
    trackLocationName,
    setTrackLocationName,
  } = useFunctionalLocationForm(creationMutation, functionalLocationId);

  return (
    <Form
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();

        let addAnother = false;

        if ('submitter' in e.nativeEvent) {
          const submitter = (e.nativeEvent as SubmitEvent).submitter;
          addAnother = submitter != null && submitter.id === 'createAndAdd';
        }

        const resource = await submit(addAnother);

        if (resource != null && !addAnother) {
          onClose(resource);
        }
      }}
    >
      <DrawerHeader onClose={onClose}>
        {t(
          isEdit
            ? 'title.update'
            : isIncidentLocation
            ? 'title.createIncident'
            : 'title.create'
        )}
      </DrawerHeader>
      <DrawerContent>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="title"
            label={t('form.title')}
            value={title.value}
            error={title.error}
            onChange={title.set}
            readonly={isEdit}
            maxLength={50}
            required
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="description"
            label={t('form.description')}
            value={description.value}
            error={description.error}
            onChange={description.set}
            readonly={isEdit}
            multiline
          />
        )}
        <Gap size={32} />
        <Typography variant="h3">
          {isLoading ? <Skeleton /> : t('form.address')}
        </Typography>
        {allowPinOnMap && (
          <>
            <Gap size={20} />
            <BinarySelectField
              name="shouldUseMap"
              value={shouldUseMap}
              onChange={(s) => {
                setShouldUseMap(s);
                setTrackLocationName(s);
              }}
              options={[
                {
                  id: false,
                  label: t('form.option.address'),
                },
                {
                  id: true,
                  label: t('form.option.map'),
                },
              ]}
            />
          </>
        )}
        <Gap size={20} />
        {shouldUseMap && (
          <>
            <Gap size={20} />
            <div style={{ width: '100%', height: 400 }}>
              <LatLngSelectorMap
                value={{
                  lat: address.value.latitude,
                  lng: address.value.longitude,
                }}
                onChange={(value) => {
                  if (trackLocationName) {
                    reverseGeocode(value?.lat || 0, value?.lng || 0).then(
                      (a) => {
                        address.set({
                          ...address.value,
                          ...a,
                        });
                      }
                    );
                  } else {
                    address.set({
                      ...address.value,
                      ...{ latitude: value?.lat, longitude: value?.lng },
                    });
                  }
                }}
              />
              {/*<GoogleMapReact*/}
              {/*  bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}*/}
              {/*  defaultCenter={{*/}
              {/*    lat: lat.value || 51.5072,*/}
              {/*    lng: lng.value || 0.1276,*/}
              {/*  }}*/}
              {/*  defaultZoom={8}*/}
              {/*  yesIWantToUseGoogleMapApiInternals*/}
              {/*></GoogleMapReact>*/}
            </div>
            <Gap size={20} />
          </>
        )}
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTAddressField
            id="address"
            value={address.value}
            onChange={address.set}
            errors={address.errors}
            countryOptions={countryOptions!}
            readonly={isEdit || trackLocationName}
            forceReadOnly={shouldUseMap}
            onEditAddressClick={() => {
              setShouldUseMap(false);
              address.set({
                ...address.value,
                ...{ latitude: undefined, longitude: undefined },
              });
            }}
            compact
            required
          />
        )}
      </DrawerContent>
      <DrawerFooter>
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <OutlinedButton onClick={() => onClose()}>Close</OutlinedButton>
        )}
        <Spacer />
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
            Save
          </PrimaryButton>
        )}
      </DrawerFooter>
    </Form>
  );
}

export default withSideDrawer(FunctionalLocationForm);
