import { useRef, useState } from 'react';
import { useGetCaseOutcomeDetailQuery } from '@api/endpoints/case-outcome.api';
import { useGetCaseDetailQuery } from '@api/endpoints/case.api';

export default function useRcaOutcome(caseId: number, outcomeId?: number) {
  const appBarRef = useRef<HTMLDivElement>(null);
  const [currentOutcomeId, setCurrentOutcomeId] = useState(outcomeId);

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);

  const { data: outcomeDetail, isLoading: loadingOutcome } =
    useGetCaseOutcomeDetailQuery(
      { caseId, outcomeId: currentOutcomeId ?? -1 },
      { skip: currentOutcomeId == null }
    );

  const isLoading = loadingOutcome || loadingCaseDetail;

  return {
    caseDetail,
    appBarRef,
    caseId,
    outcomeId: currentOutcomeId,
    isEdit: outcomeId != null,
    setCurrentOutcomeId,
    outcomeDetail,
    isLoading,
  };
}

export type RcaOutcomeState = ReturnType<typeof useRcaOutcome>;
