import { useCallback, useMemo, useState } from 'react';
import { SortDirection } from '@api/types/sort-direction';
import { Option } from '@api/types/option';

export interface SortOption extends Option<string> {
  direction: SortDirection;
}

export type SortValueType = Pick<SortOption, 'id' | 'direction'>;

interface Props {
  initialProperty?: string;
  initialDirection?: SortDirection;
  allowNoSort?: boolean;
}

export default function useSortableHeader({
  allowNoSort = true,
  initialProperty,
  initialDirection = initialProperty ? SortDirection.asc : undefined,
}: Props = {}) {
  const [property, setProperty] = useState<string | undefined>(initialProperty);
  const [direction, setDirection] = useState<SortDirection | undefined>(
    initialDirection
  );

  const value = useMemo((): SortValueType | undefined => {
    if (property == null || direction == null) {
      return undefined;
    }

    return {
      id: property,
      direction,
    };
  }, [direction, property]);

  const onToggle = useCallback(
    (newProperty: string) => {
      if (
        direction == null ||
        (direction === SortDirection.desc && !allowNoSort)
      ) {
        setProperty(newProperty);
        setDirection(SortDirection.asc);
      } else if (direction === SortDirection.asc) {
        setProperty(newProperty);
        setDirection(
          property !== newProperty ? SortDirection.asc : SortDirection.desc
        );
      } else {
        setProperty(undefined);
        setDirection(undefined);
      }
    },
    [allowNoSort, direction, property]
  );

  const set = useCallback(
    (value?: SortOption) => {
      if (value == null) {
        setProperty(initialProperty);
        setDirection(initialDirection);
      } else {
        setProperty(value.id);
        setDirection(value.direction);
      }
    },
    [initialDirection, initialProperty]
  );

  return useMemo(
    () => ({
      sortable: true,
      property,
      value,
      setProperty,
      direction,
      onToggle,
      set,
      allowNoSort,
    }),
    [allowNoSort, direction, onToggle, property, set, value]
  );
}

export type SortableHeader = ReturnType<typeof useSortableHeader>;
