/* eslint-disable quotes */
import OnboardingEntryContainer, {
  OnboardingEntryContainerProps,
} from '@components/containers/onboarding-entry-container';
import { faListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCasePermission } from '@hooks/case/use-case-permission';

type Props = {
  isCaseTasks?: boolean;
  onCreateClicked?: () => void;
} & Partial<OnboardingEntryContainerProps>;

export default function NoTasksInfo({
  onCreateClicked,
  isCaseTasks,
  ...rest
}: Props) {
  const { canContribute } = useCasePermission();

  let title = "You don't currently have any tasks";
  let message = "There aren't currently any tasks within the platform";
  if (isCaseTasks) {
    title = 'No tasks are assigned to this RCA';
    message = 'Return to the the Analysis chart to add tasks to the RCA causes';
    if (canContribute && onCreateClicked != null) {
      title = 'There are no tasks currently assigned to this cause box';
      message =
        "There aren't currently any tasks for this cause box, you can add one below";
    }
  }

  return (
    <OnboardingEntryContainer
      {...rest}
      icon={<FontAwesomeIcon icon={faListCheck} size="2x" />}
      title={title}
      message={message}
      createMessage={canContribute ? 'Add Task' : undefined}
      onCreateClicked={canContribute ? onCreateClicked : undefined}
    />
  );
}
