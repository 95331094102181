import {
  faCirclePlay,
  faFileLines,
  faSquareQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpCentreContentType } from '@api/types/help-center/help-center-content-type';

interface Props {
  contentType?: HelpCentreContentType;
}

export const ContentTypeIcon = ({ contentType }: Props) => {
  var icon = faFileLines;
  switch (contentType) {
    case HelpCentreContentType.faq:
      icon = faSquareQuestion;
      break;
    case HelpCentreContentType.video:
      icon = faCirclePlay;
      break;
  }

  return <FontAwesomeIcon icon={icon} />;
};
