import { useLocation } from 'react-router-dom';

export const COMPANY_BILLING_PATH = '/company-profile/billing';

interface Opt {
  startsWith?: boolean;
}

export function useIsPath(
  path: string | Array<string>,
  { startsWith }: Opt = {}
) {
  const { pathname } = useLocation();

  const compare = (path: string) => {
    return startsWith
      ? pathname.toLowerCase().startsWith(path.toLowerCase())
      : pathname.toLowerCase() === path.toLowerCase();
  };

  return Array.isArray(path) ? path.find(compare) != null : compare(path);
}
