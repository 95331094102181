import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcaEditorState } from '@store/rca-editor/rca-editor-slice';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { RcaUtil } from '@util/rca-util';

export type SnapshotState = Pick<
  RcaEditorState,
  'nodes' | 'edges' | 'storage' | 'storageNodeIdsToConsume'
>;

export enum SnapshotStatus {
  pending,
  saving,
  committed,
  skipped,
  error,
}

export type HydrateSnapshotPayload = {
  snapshotId: string;
  data: ChainDetailResponse;
};

const MAX_SNAPSHOTS = 30;

interface Snapshot {
  id: string;
  timestamp: number;
  data: SnapshotState;
  canBeSkipped: boolean;
  status: SnapshotStatus;
}

interface RcaGraphSaverState {
  snapshots: Array<Snapshot>;
  currentSnapshot: Snapshot | null;
}

const initialState: RcaGraphSaverState = {
  snapshots: [],
  currentSnapshot: null,
};

const log = (message: string, ...optionalParams: any[]) => {
  console.log('[graph-saver (slice)] ' + message, ...optionalParams);
};

const getSnapshotData = (data: RcaEditorState): SnapshotState => {
  const { nodes, edges, storage, storageNodeIdsToConsume } = cloneDeep(data);

  return {
    nodes,
    edges,
    storage,
    storageNodeIdsToConsume,
  };
};

const rcaGraphSaverSlice = createSlice({
  name: 'rcaGraphSaver',
  initialState: initialState,
  reducers: {
    hardResetSnapshotState: (state, _: PayloadAction) => {
      log('Hard resetting snapshot state');
      return initialState;
    },
    beginSaveSnapshot: (state, { payload }: PayloadAction<string>) => {
      const snapshot = state.snapshots.find((x) => x.id === payload);
      if (snapshot != null) {
        console.assert(
          snapshot.status === SnapshotStatus.pending,
          'Snapshot should only be in a pending before saving'
        );
        snapshot.status = SnapshotStatus.saving;
      }
    },
    setInitialSnapshot: (state, { payload }: PayloadAction<RcaEditorState>) => {
      const data = getSnapshotData(payload);
      const snapshot: Snapshot = {
        id: uuid(),
        timestamp: Date.now(),
        data,
        canBeSkipped: false,
        status: SnapshotStatus.committed,
      };

      state.snapshots = [snapshot];
    },
    addSnapshot: (
      state,
      { payload }: PayloadAction<RcaEditorState & { canBeSkipped: boolean }>
    ) => {
      const data = getSnapshotData(payload);
      const snapshot: Snapshot = {
        id: uuid(),
        timestamp: Date.now(),
        data,
        canBeSkipped: payload.canBeSkipped,
        status: SnapshotStatus.pending,
      };

      log('Current snapshots:', state.snapshots);
      // Remove any snapshots that are not committed or currently saving, only if they are allowed to be skipped.
      state.snapshots = state.snapshots.filter((x) => {
        const isRequired =
          [SnapshotStatus.committed, SnapshotStatus.saving].includes(
            x.status
          ) || !x.canBeSkipped;

        log(
          `snapshot ${x.id} is required?: ${isRequired} it's status: ${x.status}`,
          snapshot
        );
        return isRequired;
      });
      log('Filtered snapshots:', state.snapshots);

      log('Adding snapshot:', snapshot);
      state.snapshots.push(snapshot);
      state.currentSnapshot = snapshot;

      if (state.snapshots.length > MAX_SNAPSHOTS) {
        log('max snapshots reached, removing oldest snapshot');
        state.snapshots.shift();
      }
    },
    resetSnapshotState: (state, _) => {
      Object.assign(state, initialState);
    },
    commitSnapshotUptoId: (state, { payload }: PayloadAction<string>) => {
      log('Committing snapshot upto id:', payload);
      const index = state.snapshots.findIndex((x) => x.id === payload);
      if (index === -1) {
        log('Snapshot not found');
        return;
      }

      for (let i = 0; i < index; i++) {
        const snapshot = state.snapshots[i];
        if (
          snapshot.status === SnapshotStatus.pending ||
          snapshot.status === SnapshotStatus.saving
        ) {
          log(`Skipping snapshot ${snapshot.id}`, snapshot);
          snapshot.status = SnapshotStatus.skipped;
        }
      }
      state.snapshots[index].status = SnapshotStatus.committed;
    },
    hydrateSnapshot: (
      state,
      { payload }: PayloadAction<HydrateSnapshotPayload>
    ) => {
      const { snapshotId, data } = payload;

      log('Hydrating snapshot:', snapshotId, data);
      const index = state.snapshots.findIndex((x) => x.id === snapshotId);
      if (index === -1) {
        log('Snapshot not found');
        return;
      }

      // We will also need to hydrate the storage cause box ids in
      // snapshots ABOVE this current one aswell, otherwise future update graph
      // calls will fail due to missing cause box IDs.
      for (let i = index; i < state.snapshots.length; i++) {
        log(`Hydrating snapshot ${state.snapshots[i].id}`);
        RcaUtil.populateNodesFromServerData(
          state.snapshots[i].data.nodes,
          state.snapshots[i].data.storage,
          data
        );
      }
    },
    revertToLastViableSnapshot: (state, _: PayloadAction) => {
      const { snapshots } = state;
      let lastCommittedIndex = snapshots.length - 1;
      log(
        `Reverting to last viable snapshot, starting index is: ${lastCommittedIndex}`
      );
      for (; lastCommittedIndex >= 0; lastCommittedIndex--) {
        const status = snapshots[lastCommittedIndex].status;
        if (
          [SnapshotStatus.committed, SnapshotStatus.saving].includes(status)
        ) {
          log(
            `Found last viable snapshot at index ${lastCommittedIndex}. It's status: ${status}`
          );
          break;
        }
      }

      if (lastCommittedIndex === -1) {
        log('No viable snapshots found');
        return;
      }

      state.snapshots = snapshots.slice(0, lastCommittedIndex + 1);
      state.currentSnapshot = snapshots[lastCommittedIndex];
    },
  },
});

export const {
  beginSaveSnapshot,
  hardResetSnapshotState,
  hydrateSnapshot,
  commitSnapshotUptoId,
  addSnapshot,
  setInitialSnapshot,
  revertToLastViableSnapshot,
} = rcaGraphSaverSlice.actions;

export default rcaGraphSaverSlice.reducer;
