import { api } from '@api/api';
import { Option } from '@api/types/option';

const frequencyTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFrequencyTypeOptions: build.query<Option[], void>({
      query: () => ({
        url: 'frequencyType/names',
      }),
    }),
  }),
});

export const { useGetFrequencyTypeOptionsQuery } = frequencyTypeApi;
