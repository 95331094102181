import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, styled } from '@mui/material';
import Logo from '@assets/svgs/logo.svg';
import { ReactComponent as BarsIcon } from '@assets/svgs/bars.svg';
import { SideMenuButton } from '@components/buttons';
import { ReactComponent as CompaniesIcon } from '@assets/svgs/companies-icon.svg';
import { ReactComponent as UsersIcon } from '@assets/svgs/users-icon.svg';
import { Gap } from '@components/layout-util-components/gap';

const LayoutContainer = styled('div')({
  width: '100vw',
  height: '100vh',
});

const ContentScrollContainer = styled('div')({
  display: 'inline-block',
  height: '100vh',
  width: 'calc(100vw - 75px)',
  overflowY: 'auto',
});

const ContentContainer = styled('div')({
  display: 'flex',
  flex: '1 0 100%',
  width: '100%',
  flexDirection: 'column',
});

const NavSpacer = styled('div')({
  display: 'inline-block',
  backgroundColor: '#535353',
  width: 75,
  height: '100vh',
});

const NavContainer = styled('div')({
  left: 0,
  top: 0,
  bottom: 0,
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#535353',
  width: 75,
  height: '100vh',
  padding: 15,
});

const StyledLogo = styled('img')({
  display: 'inline-block',
  width: 45,
  height: 45,
});

export default function AdminLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const makeNavTap = (path: string) => () => navigate(path);
  const isSelected = (path: string) => pathname.startsWith(`/${path}`);

  return (
    <LayoutContainer>
      <NavSpacer />
      <NavContainer>
        <StyledLogo src={Logo} alt="logo" />
        <Gap size={15} />
        <IconButton>
          <BarsIcon />
        </IconButton>

        <Gap size={15} />
        <SideMenuButton
          onClick={makeNavTap('/companies')}
          isSelected={isSelected('companies')}
          icon={<CompaniesIcon />}
        />

        <Gap size={15} />
        <SideMenuButton
          onClick={makeNavTap('/users')}
          isSelected={isSelected('users')}
          icon={<UsersIcon />}
        />
      </NavContainer>
      <ContentScrollContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </ContentScrollContainer>
    </LayoutContainer>
  );
}
