import { useGetCaseAnalysisTypesQuery } from '@api/endpoints/case-analysis-type.api';

export default function useCreateTypePage(caseId?: number) {
  const { data: caseAnalysisTypes, isLoading: loadingCaseAnalysisTypes } =
    useGetCaseAnalysisTypesQuery({});

  return {
    caseAnalysisTypes,
    loadingCaseAnalysisTypes,
  };
}
