import { styled } from '@mui/material';
import { ReactComponent as Icon } from '@assets/svgs/meta-connection-icon.svg';
import { MetaConnectionPosition } from '@pages/app/rca/tabs/components/meta-connections/types';
import { MouseEventHandler, useMemo } from 'react';
import { ReactComponent as AndIcon } from '@assets/svgs/and.svg';
import { ReactComponent as OrIcon } from '@assets/svgs/or.svg';
import { store, useAppDispatch, useAppSelector } from '@store/store';
import { RcaNodeType } from '@store/rca-editor/types';
import {
  makeSelectChildNodes,
  makeSelectIsNodeBusy,
  makeSelectParentNode,
} from '@store/rca-editor/selectors';
import { insertMetaNode } from '@store/rca-editor/rca-editor-actions';
import usePopoverMenu from '@components/popover-menu/use-popover-menu';
import PopoverMenu from '@components/popover-menu/popover-menu';
import { LoadingIndicator } from '@components/loading-indicator';

interface Props {
  nodeId: string;
  position: MetaConnectionPosition;
  isParentNode: boolean;
}

const Button = styled('button')(({ theme: { palette } }) => ({
  border: 'none',
  width: 25,
  height: 25,
  padding: '7px 6px 7px 6px',
  borderRadius: '50%',
  background: palette.common.grey90,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function MetaConnectionButton({
  nodeId,
  position,
  isParentNode,
}: Props) {
  const dispatch = useAppDispatch();
  const menu = usePopoverMenu();

  const selectIsBusy = useMemo(() => makeSelectIsNodeBusy(nodeId), [nodeId]);

  const isBusy = useAppSelector(selectIsBusy);

  const onCreateMetaNode = (nodeType: RcaNodeType) => {
    const parentId = isParentNode
      ? nodeId
      : makeSelectParentNode(nodeId)(store.getState())?.id;
    if (parentId != null) {
      const children = makeSelectChildNodes(parentId)(store.getState());
      dispatch(insertMetaNode(nodeType, children));
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    menu.open([
      {
        icon: <AndIcon />,
        label: 'Or',
        onClick: () => onCreateMetaNode(RcaNodeType.or),
      },
      {
        icon: <OrIcon />,
        label: 'And/Or',
        onClick: () => onCreateMetaNode(RcaNodeType.andOr),
      },
    ]);
  };

  return (
    <Button
      ref={menu.ref}
      id={menu.id}
      type="button"
      className={`meta-connection meta-connection-${position}`}
      aria-controls={menu.isShown ? menu.id : undefined}
      aria-haspopup="true"
      aria-expanded={menu.isShown ? 'true' : undefined}
      onClick={handleClick}
    >
      {isBusy ? (
        <LoadingIndicator show />
      ) : (
        <>
          <Icon />
          <PopoverMenu {...menu} />
        </>
      )}
    </Button>
  );
}
