import { useGetCountryNamesQuery } from '@api/endpoints/country.api';
import { useFilter, usePagination, useSortableHeader } from '@components/table';

import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import {
  useDeleteLocationForCompanyMutation,
  useGetLocationsForCompanyQuery,
  useToggleCompanyLocationStatusMutation,
} from '@api/endpoints/company-location.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export default function useFunctionalLocations() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const { data: countryOptions, isLoading: loadingCountries } =
    useGetCountryNamesQuery(false);

  const sort = useSortableHeader({ initialProperty: 'name' });
  const pagination = usePagination();

  const search = useFilter<string>();

  const {
    data,
    isLoading: loadingData,
    isFetching,
  } = useGetLocationsForCompanyQuery({
    skip: pagination.skip,
    take: pagination.take,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const [deleteCompany] = useDeleteLocationForCompanyMutation();

  const [toggleStatus] = useToggleCompanyLocationStatusMutation();

  const isLoading = loadingCountries || loadingData;

  const [onDeleteCompany] = useBusyAction(async (id: number) => {
    try {
      await deleteCompany(id).unwrap();
      await invalidation('CompanyLocation', id);
      showSuccessMessage('Location removed');
      return true;
    } catch (e) {
      if (isApiError(e)) {
        const { message } = e;
        showErrorMessage(message);
      }
      return false;
    }
  });

  const [onToggleLocationStatus] = useBusyAction(
    async (props: { id: number; on: boolean }) => {
      try {
        await toggleStatus(props.id).unwrap();
        await invalidation('CompanyLocation', props.id);
        showSuccessMessage(
          props.on
            ? 'Company Location enabled succesfully'
            : 'Company Location disabled succesfully'
        );
        return true;
      } catch (e) {
        if (isApiError(e)) {
          const { message } = e;
          showErrorMessage(message);
        }
        return false;
      }
    }
  );

  return {
    isLoading,
    isFetching,
    sort,
    countryOptions,
    search,
    pagination,
    data,
    onDeleteCompany,
    onToggleLocationStatus,
  };
}
