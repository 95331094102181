import OutcomeIconWithBadge from '@components/outcome-report/components/outcome-icon-with-badge';
import { faFolder } from '@fortawesome/pro-light-svg-icons';

interface Props {
  count?: number;
}

export default function FileIndicator({ count }: Props) {
  if (count == null || count <= 0) {
    return null;
  }

  return <OutcomeIconWithBadge icon={faFolder} badge={count} />;
}
