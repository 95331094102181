import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyThemes from './company-themes-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import CreateChildThemeModal, {
  CreateChildThemeModalProps,
} from './create-child-theme-modal';

function CompanyThemesPage() {
  const { options, loadingThemes, enableTheme, disableTheme, deleteTheme } =
    useCompanyThemes();

  const { showPopup } = useUiPopup();

  const openChildModal = (parentId: number, parentName: string) => {
    showPopup<CreateChildThemeModalProps, boolean>(CreateChildThemeModal, {
      parentId: parentId,
      parentName: parentName,
      type: 'theme',
    });
  };

  return (
    <>
      <SubpageTitle breadCrumb="RCA Settings" title="Theme Configuration" />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingThemes}
        onEnable={enableTheme}
        onDisable={disableTheme}
        onDelete={deleteTheme}
        type="theme"
        onCreateClick={openChildModal}
        isTopLevel
        sx={{ padding: '8px 20px' }}
        childSx={{ padding: '5px 20px' }}
        space={8}
      />
    </>
  );
}

export default requireAuth(CompanyThemesPage);
