import {
  AnalysisOverviewState,
  AnalysisViewMode,
} from '@pages/app/analysis/analysis-overview-hook';
import PinDisplayMap, { PinInfo } from '@components/google-map/pin-display-map';
import { useMemo } from 'react';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { useGetCompanyDetailsQuery } from '@api/endpoints/company/company.api';

const hasReportingLocation = (caseItem: CaseItemRowResponse) =>
  caseItem.reportingLocation != null &&
  caseItem.reportingLocation.latitude != null &&
  caseItem.reportingLocation.longitude != null;

const hasIncidentLocation = (caseItem: CaseItemRowResponse) =>
  caseItem.incidentLocation != null &&
  caseItem.incidentLocation.latitude != null &&
  caseItem.incidentLocation.longitude != null;

export default function AnalysisMapView({
  caseData,
  location,
  viewMode,
}: Pick<AnalysisOverviewState, 'viewMode' | 'location' | 'caseData'>) {
  const data = caseData!.model;

  const { data: companyDetails, isLoading: loadingDetails } =
    useGetCompanyDetailsQuery();

  const pins = useMemo((): Array<PinInfo> => {
    const casesWithReportingLocations = data.filter(hasReportingLocation);
    const casesWithIncidentLocations = data.filter(hasIncidentLocation);

    const reportingLocations = casesWithReportingLocations.reduce(
      (map, current) => {
        const id = current.reportingLocation!.companyLocationId;
        if (map.has(id)) {
          map.get(id)!.push(current);
        } else {
          map.set(id, [current]);
        }
        return map;
      },
      new Map<number, Array<CaseItemRowResponse>>()
    );

    const incidentLocations = casesWithIncidentLocations.reduce(
      (map, current) => {
        const id = current.incidentLocation!.companyLocationId;
        if (map.has(id)) {
          map.get(id)!.push(current);
        } else {
          map.set(id, [current]);
        }

        return map;
      },
      new Map<number, Array<CaseItemRowResponse>>()
    );

    const retArray: Array<PinInfo> = [];
    reportingLocations.forEach((caseArray) => {
      const location = caseArray[0].reportingLocation!;

      retArray.push({
        lat: location.latitude!,
        lng: location.longitude!,
        isReportingLocation: true,
        cases: caseArray,
      });
    });

    incidentLocations.forEach((caseArray) => {
      const location = caseArray[0].incidentLocation!;

      retArray.push({
        lat: location.latitude!,
        lng: location.longitude!,
        isReportingLocation: false,
        cases: caseArray,
      });
    });

    return retArray;
  }, [data]);

  return (
    <div style={{ width: '100%', height: 800 }}>
      {!loadingDetails && (
        <PinDisplayMap
          pins={pins}
          onFilterByLocationId={(locationId) => {
            location.set(locationId);
            viewMode.set(AnalysisViewMode.table);
          }}
          mapType={
            companyDetails?.defaultSatelliteMap ? 'satellite' : 'roadmap'
          } // 'roadmap', 'satellite', 'hybrid', or 'terrain'
          labelsOn={companyDetails?.defaultShowMapLabel ?? false} // Set to true to show labels
          showTerrain={companyDetails?.defaultShowMapTerrain ?? false}
        />
      )}
    </div>
  );
}
