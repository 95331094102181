import useField from '@hooks/use-field-hook';
import { equals, required, validPassword } from '@util/validators';
import { useAuthTr } from '@i18n/use-auth-tr';
import {
  useCompleteUserSetPasswordMutation,
  useConfirmUserEmailMutation,
  useConfirmUserEmailTokenQuery,
} from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { ConfirmUserEmailRequest } from '@api/types/user/confirm-user-email.request';
import { CompleteUserSetPasswordRequest } from '@api/types/user/complete-user-set-password.request';
import { useAppDispatch } from '@store/store';
import { refreshUserState } from '@store/user/user-slice';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { isNullOrEmpty } from '@util/string-util';

export default function useCreatePassword(uid?: string, token?: string) {
  const requireTokenCheck = !isNullOrEmpty(uid) && !isNullOrEmpty(token);

  const { t } = useAuthTr('createPassword');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const dispatch = useAppDispatch();

  const { data: tokenConfirmedResponse, isLoading: isCheckingToken } =
    useConfirmUserEmailTokenQuery(
      { token: token ?? '', uid: uid ?? '' },
      { skip: !requireTokenCheck }
    );

  // Normal confirm email flow
  const [confirmUserEmail, { isLoading: submittingConfirmUserEmail }] =
    useConfirmUserEmailMutation();

  // Company invite flow
  const [completeUser, { isLoading: submittingCompleteUser }] =
    useCompleteUserSetPasswordMutation();

  const password = useField<string>([required(), validPassword()]);
  const confirmPassword = useField<string>([
    required(),
    equals(password.value, t('form.confirmPassword.missMatchError')),
  ]);

  const { isDirty, isValid, validateAll } = useFieldsWatcher([
    password,
    confirmPassword,
  ]);

  const hasValidToken = !requireTokenCheck
    ? true
    : tokenConfirmedResponse?.isValid ?? false;

  const isSubmitting = submittingConfirmUserEmail || submittingCompleteUser;
  const isLoading = isCheckingToken;
  const canSubmit =
    isDirty && isValid && !isLoading && !isSubmitting && hasValidToken;

  const submitUserConfirm = async (
    token: string,
    uid: string
  ): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return confirmUserEmail({
      token,
      uid,
      password: password.value,
      confirmPassword: confirmPassword.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        dispatch(refreshUserState());
        return true;
      })
      .catch(({ message, errors }: ApiError<ConfirmUserEmailRequest>) => {
        showErrorMessage(message);

        password.setError(errors?.password);
        confirmPassword.setError(errors?.confirmPassword);

        return false;
      });
  };

  const submitUserInvite = async (
    token: string,
    companyId: number
  ): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return completeUser({
      inviteToken: token,
      password: password.value,
      confirmPassword: confirmPassword.value,
      companyId,
    })
      .unwrap()
      .then(() => {
        return true;
      })
      .catch(
        ({ errors, message }: ApiError<CompleteUserSetPasswordRequest>) => {
          showErrorMessage(message);

          password.setError(errors?.password);
          confirmPassword.setError(errors?.confirmPassword);

          return false;
        }
      );
  };

  return {
    password,
    confirmPassword,
    canSubmit,
    isLoading,
    submitUserConfirm,
    submitUserInvite,
    hasValidToken,
    isSubmitting,
  };
}
