import { RootState } from '@store/store';
import locales from '@store/locale/locales';

export const selectDatetimeLongFormat = (state: RootState) => {
  const { locale } = state;
  return locale.use24HourClock
    ? locale.dateFormat.long24
    : locale.dateFormat.long12;
};

export const selectDatetimeShortFormat = (state: RootState) => {
  const { locale } = state;
  return locale.use24HourClock
    ? locale.dateFormat.short24
    : locale.dateFormat.short12;
};

export const selectDateOnlyFormatLong = (state: RootState) => {
  const { locale } = state;
  return locale.dateFormat.dateOnlyLong;
};

export const selectDateOnlyFormatShort = (state: RootState) => {
  const { locale } = state;
  return locale.dateFormat.dateOnlyShort;
};

export const selectTimeOnlyFormatShort = (state: RootState) => {
  const { locale } = state;
  return locale.dateFormat.timeOnlyShort;
};

export const selectLocale = (state: RootState) => {
  const { locale } = state;
  return locales[locale.localeId];
};

export const selectTimezone = (state: RootState) => {
  const { locale } = state;
  return locale.timezone;
};
