import { styled } from '@mui/material';

export const HeaderComponent = styled('div')({
  height: 240,
  borderRadius: '16px 16px 0 0 ',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'url(/svgs/onboarding_step1.svg) no-repeat center center #00223A',
  backgroundSize: 'cover',
  '.icon-container': {
    height: 100,
    width: 100,
  },
});
