import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import useCaseFileManager from './components/modals/case-file-manager/case-file-manager-modal-hook';
import ContentContainer from '@components/containers/content-container';
import CaseFileManagerGridView from './components/modals/case-file-manager/views/case-file-manager-grid-view';

function RcaEditorFiles() {
  const state = useCaseFileManager();

  return (
    <ContentContainer sx={{ width: '100%' }}>
      <CaseFileManagerGridView state={state} />
    </ContentContainer>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorFiles));
