import withModal from '@components/ui-popup/modals/modal-hoc';
import { styled, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { COMPANY_BILLING_PATH } from '@util/path-util';
import useUser from '@store/user/user-hook';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div')({
  maxWidth: 658,
  display: 'flex',
  flexDirection: 'column',
});

function PaymentIssueModal() {
  const { isAdminOrSuperAdminUser, logout } = useUser();
  const navigate = useNavigate();

  return (
    <Container>
      {isAdminOrSuperAdminUser ? (
        <>
          <Typography variant="h3">
            There has been an issue taking payment for your account
          </Typography>
          <Gap size={24} />
          <PrimaryButton onClick={() => navigate(COMPANY_BILLING_PATH)}>
            Update Payment Details
          </PrimaryButton>
        </>
      ) : (
        <>
          <Typography variant="h3">
            There has been an issue taking payment for your account - please
            contact your administrator to continue solving problems with What
            Caused This
          </Typography>
          <Gap size={20} />
          <Typography variant="body2">
            We are just letting you know that there has been an issue when
            trying to take payment for this account.
          </Typography>
          <Gap size={20} />
          <Typography variant="body2">
            Please contact your administrator to continue collaborating on Root
            Cause Analysis problem solving.
          </Typography>
          <Gap size={24} />
          <PrimaryButton onClick={() => logout()}>Logout</PrimaryButton>
        </>
      )}
    </Container>
  );
}

export default withModal(PaymentIssueModal, { blurBackdrop: true });
