import { Gap, GapSize } from '@components/layout-util-components/gap';
import ReferenceDataDisableListItem from './reference-data-disable-list-item';
import { Box, SxProps, Theme } from '@mui/material';

export interface ReferenceDataDisableListOption {
  id: number;
  label: string;
  enabled: boolean;
  hasWarning: boolean;
  disabledDateTime?: Date;
  children?: ReferenceDataDisableListOption[];
}

interface Props {
  isLoading: boolean;
  options: ReferenceDataDisableListOption[];
  type: string;
  isTopLevel?: boolean;
  sx?: SxProps<Theme>;
  childSx?: SxProps<Theme>;
  space?: GapSize;
  onEnable: (id: number) => void;
  onDisable: (id: number) => void;
  onDelete?: (id: number) => void;
  onCreateClick?: (id: number, name: string) => void;
  onDeleteClick?: (id: number, name: string) => void;
}

export default function ReferenceDataDisableList({
  isLoading,
  options,
  type,
  isTopLevel,
  sx,
  childSx,
  space,
  onEnable,
  onDisable,
  onDelete,
  onCreateClick,
  onDeleteClick,
}: Props) {
  return (
    <>
      {options.map((o) => (
        <>
          <ReferenceDataDisableListItem
            isLoading={isLoading}
            option={o}
            onEnable={onEnable}
            onDisable={onDisable}
            onDelete={onDelete}
            type={type}
            onCreateClick={
              onCreateClick
                ? () => {
                    onCreateClick(o.id, o.label);
                  }
                : undefined
            }
            onDeleteClick={
              onDeleteClick
                ? () => {
                    onDeleteClick(o.id, o.label);
                  }
                : undefined
            }
            children={o.children}
            isTopLevel={isTopLevel}
            sx={sx}
            childSx={childSx}
            space={space}
          />
        </>
      ))}
    </>
  );
}
