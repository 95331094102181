import { api } from '@api/api';
import { Option } from '@api/types/option';

const waRoleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWARoleOptions: build.query<Option<String>[], void>({
      query: () => ({
        url: 'role/options',
      }),
    }),
  }),
});

export const { useGetAllWARoleOptionsQuery } = waRoleApi;
