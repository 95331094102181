import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import SolutionsIndexPage from '@pages/app/solutions/solutions-index-page';
import ContentContainer from '@components/containers/content-container';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

function RcaEditorSolutions() {
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  return (
    <div style={{ width: '100%' }}>
      <SolutionsIndexPage caseId={caseId} />
    </div>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorSolutions));
