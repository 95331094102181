import { CompanyGroupUserResource } from '@api/types/company/company-group/company-group-user.resource';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getFirstName, getLastName } from '@util/name-util';
import { TableCell, TableRow, Typography } from '@mui/material';
import ColorBadge from '@components/badges/color-badge';

interface Props {
  member: CompanyGroupUserResource;
  isAdmin?: boolean;
}

export default function GroupMemberRow({ member, isAdmin }: Props) {
  const { name, jobTitle, kudosLevel, kudosPoints } = member;
  const firstName = getFirstName(name);
  const lastName = getLastName(name);

  return (
    <TableRow
      sx={{
        '.content-footer-card-outer-container': {
          width: 'calc(50% - 14.5px) !important',
          '&:hover': {
            width: 'calc(50% - 12.5px) !important',
          },
          '.status': {
            'p.MuiTypography-body1': {
              fontSize: '13px',
            },
          },
        },
      }}
    >
      <TableCell
        sx={{
          '.circle-container': {
            width: 32,
            height: 32,
            background: '#00223A',
            color: '#fff',
            fontSize: 10,
            letterSpacing: '-.5px',
            opacity: 1,
          },
          '.title': {
            fontSize: '15px',
            fontWeight: 400,
            color: '#00223A',
            marginBottom: '3px',
          },
          '.subtitle': {
            fontSize: '12px',
            fontWeight: 400,
            color: '#636D73',
          },
        }}
      >
        <InitialsContainerWithData
          label={name}
          firstName={firstName}
          lastName={lastName}
          subLabel={jobTitle}
        />
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            borderRadius: '4px',
            padding: '4px',
            background: '#DBE5EC',
            fontSize: 13,
            color: '#00223A',
            width: '50px',
            textAlign: 'center',
          }}
        >
          {`Lvl ${kudosLevel ?? 0}`}
        </Typography>
      </TableCell>
      <TableCell>{isAdmin ? <ColorBadge>Admin</ColorBadge> : null}</TableCell>
    </TableRow>
  );
}
