import { useTheme } from '@mui/material';
import OutcomeColoredBadge, {
  OutcomeColoredBadgeProps,
} from '@components/outcome-report/components/outcome-colored-badge';

interface Props {
  effectiveness: number | string;
  postFix?: string;
}

export default function SolutionEffectivenessBadge({
  effectiveness,
  postFix,
}: Props) {
  const { palette } = useTheme();
  const hasEfficacy = typeof effectiveness === 'number';

  let badgeProps: OutcomeColoredBadgeProps = {
    bgColor: `${palette.error.main}26`,
    textColor: palette.error.main,
    indicatorColor: palette.error.main,
    borderColor: palette.error.main,
    label: `${hasEfficacy ? `${effectiveness.toFixed(1)}%` : effectiveness} ${
      postFix || ''
    }`,
  };

  if (effectiveness > 39) {
    badgeProps.bgColor = `${palette.primary.main}26`;
    badgeProps.textColor = palette.primary.main;
    badgeProps.indicatorColor = palette.primary.main;
    badgeProps.borderColor = palette.primary.main;
  }

  if (effectiveness > 69) {
    badgeProps.bgColor = `${palette.success.main}26`;
    badgeProps.textColor = palette.success.main;
    badgeProps.indicatorColor = palette.success.main;
    badgeProps.borderColor = palette.success.main;
  }

  return <OutcomeColoredBadge {...badgeProps} showIndicator={hasEfficacy} />;
}
