import { Filter } from '@components/table/hooks/use-filter-hook';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import { Option } from '@api/types/option';

interface Props {
  isLoading: boolean;
  isFetching: boolean;
  search: Filter<string>;
  role: Filter<Array<string>>;
  status: Filter<Array<string>>;
  roleOptions?: Array<Option<string>>;
  statusOptions?: Array<Option<string>>;
}
export default function WctUsersTableFilters({
  isLoading,
  isFetching,
  search,
  role,
  status,
  roleOptions,
  statusOptions,
}: Props) {
  return (
    <WCTTableFilters
      isLoading={isLoading}
      isFetching={isFetching}
      search={search.value}
      onSearch={search.set}
    >
      <DropDownFilter
        value={role.value}
        onChange={role.set}
        options={roleOptions ?? []}
        multiple
      >
        Role
      </DropDownFilter>
      <DropDownFilter
        value={status.value}
        onChange={status.set}
        options={statusOptions ?? []}
        multiple
      >
        Status
      </DropDownFilter>
    </WCTTableFilters>
  );
}
