import { ReactNode } from 'react';
import { styled } from '@mui/material';

interface Props {
  label?: ReactNode;
  value?: ReactNode;
}

const Container = styled('dl')({
  display: 'inline-flex',
  flexDirection: 'column',
  margin: 0,
});

const StyledDT = styled('dt')({
  fontSize: 14,
  fontWeight: 500,
  color: '#949FA2',
  textTransform: 'uppercase',
});

const StyledDD = styled('dd')({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '19px',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '7px 0 0',
  p: {
    margin: '5px 0',
  },
  a: {
    fontSize: 13,
    fontWeight: 400,
    padding: 0,
  },
  '.initials-container-with-data': {
    '.circle-container': {
      width: 24,
      height: 24,
      fontSize: 9,
      background: '#D8DBE3',
      flex: '0 0 24px',
    },
    '.name-container .subtitle': {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '17px',
      color: 'rga(0,25,39,1)',
    },
  },
});

export default function LabelValueDisplay({ label, value }: Props) {
  return (
    <Container>
      {label ? <StyledDT>{label}</StyledDT> : null}
      {value ? <StyledDD>{value}</StyledDD> : null}
    </Container>
  );
}
