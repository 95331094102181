export enum TaskStatus {
  unknown = 'Unknown',
  active = 'Active',
  overdue = 'Overdue',
  complete = 'Complete',
}

export const allTaskStatus = [
  TaskStatus.unknown,
  TaskStatus.active,
  TaskStatus.overdue,
  TaskStatus.complete,
];

export const nonCompleteTaskStatus = [
  TaskStatus.unknown,
  TaskStatus.active,
  TaskStatus.overdue,
];
