const ENV = process.env.REACT_APP_ENV;
const MODE = process.env.REACT_APP_MODE;

export const isTest = ENV === 'test';
export const isProd = ENV === 'production';
export const isApp = MODE === 'app';
export const isWctAdmin = MODE === 'wct-admin';
export const isAdmin = MODE === 'admin' || isWctAdmin;

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
export const GOOGLE_OAUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
