import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useAppSelector } from '@store/store';
import { selectDateOnlyFormatShort } from '@store/locale/locale-selector';
import { useMemo, useState } from 'react';
import { i18nDayJs } from '@i18n/index';
import { DefaultTFuncReturn } from 'i18next';
import { optionalLabel } from '@util/string-util';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';

const StyledDatePicker = styled(DatePicker)<DatePickerProps<Dayjs>>(
  ({ theme }) => ({
    maxWidth: 300,
    '& .MuiInputBase-root': {
      border: '1px solid #687A85',
      background: 'white',
      '&.Mui-focused': {
        border: '1px solid #FF6C00',
        outline: '5px solid rgba(255, 108, 0, 0.12)',
        background: 'white',
        '&::after': {
          display: 'none',
        },
      },
      '&.Mui-disabled': {
        border: '1px solid #ECEDF0',
        background: '#ECEDF0',
        '&:hover': {
          border: '1px solid #ECEDF0',
          background: '#ECEDF0',
        },
        '.MuiInputBase-readOnly': {
          color: '#000',
          textFillColor: '#000',
        },
      },
    },
  })
);

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  error?: string;
  placeholder?: string | DefaultTFuncReturn;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  disablePast?: boolean;
  readonly?: boolean;
}

export default function WCTDateField({
  name,
  label,
  value,
  required,
  onChange,
  error,
  placeholder,
  onBlur,
  disablePast,
  readonly,
}: Props) {
  const [open, setOpen] = useState(false);
  const format = useAppSelector(selectDateOnlyFormatShort);
  const hasError = !!error;

  const valueAsDate = useMemo(() => {
    if (value == null) {
      return null;
    }

    try {
      const date = i18nDayJs(value).utc();
      if (!date.isValid()) {
        return null;
      }

      return date;
    } catch {
      return null;
    }
  }, [value]);

  return (
    <StyledDatePicker
      label={optionalLabel(label, required)}
      value={valueAsDate}
      onChange={(v) => {
        const value = i18nDayJs(v);
        if (v == null) {
          onChange(undefined);
        } else if (value.isValid()) {
          onChange(value.startOf('day').toISOString() || undefined);
        }
      }}
      format={format}
      disablePast={disablePast}
      readOnly={readonly}
      disabled={readonly}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          name: name,
          hiddenLabel: !label,
          error: hasError,
          variant: 'filled',
          helperText: error,
          placeholder: placeholder as string | undefined,
          required: required,
          onBlur: onBlur,
          onClick: (e) => {
            e.stopPropagation();
            setOpen(true);
          },
          fullWidth: true,
          sx: ({ palette }) => ({
            maxWidth: 225,
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              boxSizing: 'border-box',
              border: `2px solid ${palette.common.grey50}`,
              minHeight: '56px',
              '&::before': {
                display: 'none',
              },
              '&:hover::before': {
                display: 'none',
              },
              '&.Mui-focused': {
                border: `2px solid ${palette.primary.main}`,
                '&::after': {
                  display: 'none',
                },
              },
              '& input:-webkit-autofill': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:hover': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:focus': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:active': {
                WebkitBackgroundClip: 'text',
              },
            },
          }),
        },
      }}
      className="test"
      sx={{
        padding: 0,
        margin: 0,
      }}
    />
  );
}
