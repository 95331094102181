import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

export default function useSearchParamsTyped<T>() {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const params = Object.fromEntries([...searchParams]);
    return params as T;
  }, [searchParams]);
}
