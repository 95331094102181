import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';
import { Typography, styled } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty } from '@util/string-util';
import { ReactNode } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import FlatBadge from '@components/badges/flat-badge';
import { CaseSolutionResource } from '@api/types/case/case-solution/case-solution.resource';
import { BorderedContainer } from '@components/containers/bordered-container';
import Row from '@components/layout-util-components/row';
import { currencyFormatter } from '@i18n/formatters';
import { Gap } from '@components/layout-util-components/gap';
import SmallThemeBadge from '@components/outcome-report/components/small-theme-badge';

interface Props {
  solution: CaseSolutionItemResponse | CaseSolutionResource;
  trailing?: ReactNode;
  displayCost?: boolean;
  currency?: string;
  hideAssociatedCauseBoxBadge?: boolean;
}

const Container = styled(BorderedContainer)(({ theme: { palette } }) => ({
  justifyContent: 'flex-start',
  '> div': {
    flexGrow: 1,
  },
  '.name': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    marginRight: '1rem',
  },
  '.caption': {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#636D73',
    marginRight: '1rem',
  },

  '.associated-chain-items': {
    borderRadius: 4,
    background: palette.common.grey20,
    color: palette.common.black,
    padding: 4,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    alignSelf: 'flex-start',
  },
  '.scoreRow': {
    display: 'flex',

    '> *': {
      flex: '0 0 50%',
    },
    '.scoreContainer': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '.label': {
        color: '#949FA2',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      },
    },
  },
}));

export default function CaseSolutionItem({
  solution: { name, description, chainItems, cost, themes },
  trailing,
  displayCost,
  currency,
  hideAssociatedCauseBoxBadge,
}: Props) {
  return (
    <Container>
      <Column>
        <Row>
          <div>
            <Typography className="name">{name}</Typography>

            {!isNullOrEmpty(description) ? (
              <>
                <Gap size={4} />
                <Typography className="caption">{description}</Typography>
              </>
            ) : null}
          </div>
          <Spacer />
          {trailing ? (
            <>
              <Gap size={15} />
              <Spacer />
              {trailing}
            </>
          ) : null}
        </Row>
        {themes && themes.length > 0 && (
          <>
            <Gap size={12} />
            <Row sx={{ gap: 0.5, flexWrap: 'wrap' }}>
              {themes?.map(({ themeId, name, colourId }) => (
                <SmallThemeBadge
                  key={themeId}
                  colourId={colourId}
                  name={name}
                />
              ))}
            </Row>
          </>
        )}
        {chainItems && !hideAssociatedCauseBoxBadge ? (
          <>
            <Gap size={15} />
            <AssociatedCauseBoxBadge count={chainItems.length} />
          </>
        ) : null}

        {!!cost ? (
          <>
            <Gap size={15} />
            <Row
              className="scoreRow"
              sx={{
                paddingTop: '10px',
                borderTop: '1px solid rgba(0,0,0,0.10)',
              }}
            >
              <span className="scoreContainer">
                <span className="label severity">Initial Cost</span>
                <Typography>
                  {currencyFormatter(cost, {
                    decimalPlaces: 0,
                    currency: currency || '',
                  })}
                </Typography>
              </span>
            </Row>
          </>
        ) : null}
      </Column>
    </Container>
  );
}
