import { ReactNode } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as StatHigh } from '@assets/svgs/stat-high.svg';
import { ReactComponent as StatOverdue } from '@assets/svgs/stat-overdue.svg';
import { ReactComponent as StatOpen } from '@assets/svgs/stat-open.svg';

interface Props {
  label: 'OPEN TASKS' | 'OVERDUE TASKS' | 'HIGH PRIORITY';
  stat: ReactNode;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  minWidth: 250,
  padding: '15px 20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: palette.common.white,
  borderRadius: 4,
  flex: '1 0 0',
}));

export default function TasksStat({ label, stat }: Props) {
  const LABEL_TO_COLOUR: Record<Props['label'], { svg: ReactNode }> = {
    'HIGH PRIORITY': {
      svg: <StatHigh />,
    },
    'OVERDUE TASKS': {
      svg: <StatOverdue />,
    },
    'OPEN TASKS': {
      svg: <StatOpen />,
    },
  };

  return (
    <Container>
      <Box display="flex" gap="12px" alignItems="center">
        {LABEL_TO_COLOUR[label].svg}
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="18px"
          letterSpacing="0.15px"
          color="#001927"
        >
          {label}
        </Typography>
      </Box>
      <Typography fontSize={20} fontWeight={400}>
        {stat}
      </Typography>
    </Container>
  );
}
