import { api } from '@api/api';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';
import { UpdateChainItemRequest } from '@api/types/chain/update-chain-item.request';
import { makeError } from '@api/types/api-error';
import { ChainItemSetEndStateRequest } from '@api/types/chain/chain-item-set-end-state.request';
import { SetChainItemAreaNotApplicableRequest } from '@api/types/chain/set-chain-item-area-not-applicable.request';
import { CreateChainItemRequest } from '@api/types/chain/create-chain-item.request';
import { CreateChainItemResponse } from '@api/types/chain/create-chain-item.response';
import { LinkChainItemsRequest } from '@api/types/chain/link-chain-items.request';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

export type ChainItemApiPathParams = {
  chainId: number;
  chainItemId: number;
};

const chainItemApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItem: build.query<ChainItemResource, ChainItemApiPathParams>({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [{ type: 'ChainItem' as const, id: response.chainItemId }]
          : [],
    }),
    createChainItem: build.mutation<
      CreateChainItemResponse,
      CreateChainItemRequest
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/chainItem/draft`,
        method: 'POST',
        body,
      }),
    }),
    updateChainItem: build.mutation<
      void,
      UpdateChainItemRequest & { chainId: number; chainItemId: number }
    >({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ type: 'ChainItem' as const, id: arg.chainItemId }, 'CaseTotals']
          : [],
      transformErrorResponse: (error) =>
        makeError<UpdateChainItemRequest>(error),
    }),
    setChainItemEndState: build.mutation<void, ChainItemSetEndStateRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/setEndState`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ type: 'ChainItem' as const, id: arg.chainItemId }, 'CaseTotals']
          : [],
      transformErrorResponse: (error) =>
        makeError<ChainItemSetEndStateRequest>(error),
    }),
    setChainItemOnboardingAreaNotApplicable: build.mutation<
      void,
      SetChainItemAreaNotApplicableRequest
    >({
      query: ({ chainId, chainItemId, area, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/${area}Na`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<SetChainItemAreaNotApplicableRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ type: 'ChainItem' as const, id: arg.chainItemId }, 'CaseTotals']
          : [],
    }),
    linkChainItems: build.mutation<void, LinkChainItemsRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/linkChainItems`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: 'ChainItem' as const, id: arg.chainItemId }] : [],
    }),
  }),
});

export const {
  useGetChainItemQuery,
  useUpdateChainItemMutation,
  useSetChainItemEndStateMutation,
  useSetChainItemOnboardingAreaNotApplicableMutation,
  useLinkChainItemsMutation,
} = chainItemApi;

export default chainItemApi;
