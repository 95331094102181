import { TaskStatus } from '@api/types/task-detail/task-status';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import { Filter } from '@components/table/hooks/use-filter-hook';
import { Option } from '@api/types/option';

interface Props {
  isLoading: boolean;
  isFetching: boolean;
  search: Filter<string>;
  taskStatusFilter: Filter<TaskStatus[]>;
  taskStatusOptions: Option<string>[] | undefined;
  userOptions?: Option<number>[] | undefined;
  companyUserFilter?: Filter<number[]>;
}

export default function TasksFilters({
  isFetching,
  isLoading,
  search,
  taskStatusFilter,
  taskStatusOptions,
  userOptions,
  companyUserFilter,
}: Props) {
  return (
    <WCTTableFilters
      isLoading={isLoading}
      isFetching={isFetching}
      search={search.value}
      onSearch={search.set}
    >
      <DropDownFilter
        value={taskStatusFilter.value}
        onChange={taskStatusFilter.set}
        options={taskStatusOptions ?? []}
        multiple
      >
        Status
      </DropDownFilter>
      {userOptions && companyUserFilter && userOptions.length > 0 && (
        <DropDownFilter
          value={companyUserFilter.value}
          onChange={companyUserFilter.set}
          options={userOptions ?? []}
          multiple
        >
          Assigned
        </DropDownFilter>
      )}
    </WCTTableFilters>
  );
}
