import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRandomNumber } from '@util/helper-util';

export default function useCreateAccountProgress() {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => {
        Math.random();
        setProgress((v) => Math.min(v + getRandomNumber(10, 30), 100));
      }, getRandomNumber(1000, 2000));
    } else {
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
  }, [navigate, progress]);

  return {
    progress,
  };
}
