import { styled } from '@mui/material';
import { ReactNode } from 'react';
import BadgeInfo from '@components/outcome-report/components/badge-info';

interface Props {
  type?: ReactNode;
  value: string;
}

const Container = styled('div')({
  gap: 8,
  '> div': {
    gap: 8,
  },
  '.flat-badge': {
    padding: '5px 12px',
    fontSize: '16px',
    lineHeight: '1.25',
  },
});

export default function ImpactValueBadge({ type, value }: Props) {
  return (
    <Container>
      <BadgeInfo
        label={type != null ? `${type} Impact` : undefined}
        value={value}
      />
    </Container>
  );
}
