import { api } from '@api/api';
import { SubscriptionDetailsResource } from '@api/types/subscription/subscription-details.resource';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionDetails: build.query<SubscriptionDetailsResource, void>({
      query: () => 'subscription/details',
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
  }),
});

export const { useGetSubscriptionDetailsQuery } = subscriptionApi;
