import { TaskPriority } from '@api/types/task-detail/task-priority';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarExclamation } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/material';

interface Props {
  priority: TaskPriority;
}

const PRIORITY_TO_COLOR: Record<TaskPriority, string> = {
  High: '#D60000',
  Low: '#fff',
  Standard: '#fff',
};

const PRIORITY_TO_BGCOLOR: Record<TaskPriority, string> = {
  High: 'rgba(255,61,61,0.1)',
  Low: 'rgba(255,61,61,0)',
  Standard:
    '#fff' /* Nick can we remove the flag for low/standard on all tasks please */,
};

export default function TaskPriorityIndicator({ priority }: Props) {
  return (
    <>
      {priority === TaskPriority.high && (
        <Box
          sx={{
            background: PRIORITY_TO_BGCOLOR[priority],
            width: 32,
            height: 32,
            flexShrink: 0,
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            svg: {
              height: 20,
            },
          }}
        >
          <FontAwesomeIcon
            icon={faCalendarExclamation}
            color={PRIORITY_TO_COLOR[priority]}
          />
        </Box>
      )}
    </>
  );
}
