import { useCallback, useRef } from 'react';
import { GOOGLE_OAUTH_CLIENT_ID } from '@util/env';
import { useOauthLoginMutation } from '@api/endpoints/auth.api';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { OauthLoginRequest } from '@api/types/auth/oauth-login.request';

export type OAuthStateParam = {
  provider: string;
  inviteToken?: string;
};

export default function useOauth() {
  const hasFiredRequest = useRef(false);

  const { showErrorMessage } = usePageAlertVariants();
  const [login, { isLoading: isAuthenticating, isError: hasError }] =
    useOauthLoginMutation();

  const startGoogleFlow = useCallback((inviteToken?: string) => {
    const state: OAuthStateParam = {
      provider: 'Google',
    };

    if (inviteToken) {
      state.inviteToken = inviteToken;
    }

    const b64State = btoa(JSON.stringify(state));
    const redirectUrl = window.location.origin + '/oauth';

    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=email&response_type=code&state=${b64State}`;
  }, []);

  const loginWithOAuth = useCallback(
    (code: string, { provider, inviteToken }: OAuthStateParam) => {
      if (!code || !provider) {
        return Promise.resolve(false);
      }

      if (hasFiredRequest.current) {
        return Promise.resolve(false);
      }

      hasFiredRequest.current = true;
      return login({
        code,
        provider,
        inviteToken,
      })
        .unwrap()
        .then(() => true)
        .catch(({ message, errors }: ApiError<OauthLoginRequest>) => {
          showErrorMessage(
            errors?.provider ?? errors?.code ?? errors?.inviteToken ?? message
          );
          return false;
        })
        .finally(() => {
          hasFiredRequest.current = false;
        });
    },
    [login, showErrorMessage]
  );

  return {
    startGoogleFlow,
    loginWithOAuth,
    isAuthenticating,
    hasError,
  };
}
