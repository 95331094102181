import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import createAppTheme from '@styles/theme';
import { ThemeProvider } from '@mui/material';
import { useGetUserProfileQuery } from '@api/endpoints/user.api';
import { useAppSelector } from '@store/store';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';

export const AppThemeModeContext = createContext({
  setAppThemeMode: (_: 'light' | 'dark') => {},
});

export default function AppThemeModeProvider({
  children,
}: PropsWithChildren<{}>) {
  const canFetch = useAppSelector(selectIsLoggedInAndFullyAuthenticated);
  const { data: profileData, isSuccess } = useGetUserProfileQuery(undefined, {
    skip: !canFetch,
  });

  const [colourMode, setColourMode] = useState<'light' | 'dark'>(() => {
    return localStorage.getItem('colourMode') === 'dark' ? 'dark' : 'light';
  });
  const theme = useMemo(() => createAppTheme(colourMode), [colourMode]);
  const changeColourMode = useCallback((mode: 'light' | 'dark') => {
    localStorage.setItem('colourMode', mode);
    setColourMode(mode);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (profileData?.frontEndModeKey) {
        changeColourMode(
          profileData.frontEndModeKey.toLowerCase() as 'light' | 'dark'
        );
      }
    }
  }, [changeColourMode, isSuccess, profileData?.frontEndModeKey]);

  return (
    <AppThemeModeContext.Provider value={{ setAppThemeMode: changeColourMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeModeContext.Provider>
  );
}
