import { Box, styled, Typography } from '@mui/material';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsUpDownLeftRight,
  faCheck,
} from '@fortawesome/pro-light-svg-icons';
import Column from '@components/layout-util-components/column';
import FlatBadge from '@components/badges/flat-badge';
import {
  dateOnlyShortFormatter,
  timeOnlyShortFormatter,
} from '@i18n/formatters';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

export interface DraggableEvidenceItemProps {
  id?: number;
  name: string;
  isSelected?: boolean;
  draggable?: boolean;
}

type ComponentProps = DraggableEvidenceItemProps & {
  onToggleSelected: (id?: number) => void;
};

const Container = styled('div', { target: 'draggable-evidence-item' })({
  border: '1px solid #D8DBE3',
  background: 'white',
  padding: 15,
  display: 'flex',
  gap: 14,
  alignItems: 'flex-start',
  borderRadius: 5,
});

export default function CustomLenseSelectionItem({
  isSelected,
  id,
  name,
  onToggleSelected,
  draggable = true,
}: ComponentProps) {
  return (
    <Container>
      <FlatButton dark={isSelected} onClick={() => onToggleSelected(id)}>
        {isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
      </FlatButton>
      <Column>
        <Gap size={8} />
        <Typography variant="body1">{name}</Typography>
      </Column>
      {draggable && (
        <>
          <Spacer />
          <Box alignSelf="center">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          </Box>
        </>
      )}
    </Container>
  );
}
