import { ComponentType } from 'react';
import { Drawer, styled } from '@mui/material';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';

type Opt = {
  allowTapOutside?: boolean;
  marginBorder?: boolean;
  belowAppBar?: boolean;
};
export interface SideDrawerFormProps<T> extends UiPopupProps<T> {
  applyTopMargin?: boolean;
}

const StyledDrawer = styled(Drawer)<Opt>(({ marginBorder, belowAppBar }) => ({
  '&>.MuiDrawer-paper': {
    padding: '40px 52px',
    width: 620,
    top: belowAppBar ? 70 : 0,
    '@media (min-width:1501px)': {
      top: belowAppBar ? 80 : 0,
    },
    height: '100vh',
    display: 'flex',
    flexDirection: ' column',
    alignItems: 'stretch',

    ...(marginBorder && {
      height: 'unset',
      margin: 20,
      bottom: 0,
    }),
  },
}));

export default function withSideDrawer<
  P extends SideDrawerFormProps<T>,
  T = any
>(
  Component: ComponentType<P>,
  {
    allowTapOutside = false,
    belowAppBar = false,
    marginBorder = false,
  }: Opt = {}
) {
  return (props: P) => {
    const { show, onClose, applyTopMargin } = props;

    return (
      <StyledDrawer
        anchor="right"
        open={show}
        onClose={() => onClose()}
        hideBackdrop
        variant={allowTapOutside ? 'persistent' : 'temporary'}
        belowAppBar={belowAppBar || applyTopMargin}
        marginBorder={marginBorder}
        allowTapOutside={allowTapOutside}
      >
        <Component {...props} />
      </StyledDrawer>
    );
  };
}
