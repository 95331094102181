import {
  RadialOverviewStat,
  ReportRadialOverview,
  ReportRadialOverviewProps,
} from './radial-chart';

export interface ChainItemRadialOverviewStat extends RadialOverviewStat {
  chainItems: number;
}

interface ChainItemReportRadialOverviewProps extends ReportRadialOverviewProps {
  totalChainItems: number;
}

export const ChainItemReportRadialOverview = (
  props: ChainItemReportRadialOverviewProps
) => {
  const { totalChainItems } = props;

  const renderStatValue = (s: RadialOverviewStat) => {
    const stat = s as ChainItemRadialOverviewStat;
    return (
      <>
        {`${stat.percentage!.toFixed(2)}% (${
          stat.chainItems
        } of ${totalChainItems})`}
      </>
    );
  };

  return <ReportRadialOverview {...props} renderStatValue={renderStatValue} />;
};
