import requireAuth from '@components/hoc/with-required-auth-hoc';
import ContentContainer from '@components/containers/content-container';
import { styled } from '@mui/material';
import NoTasksInfo from '@pages/app/tasks/components/no-tasks-info';
import useTasks from '@pages/app/tasks/tasks-index-hook';
import TasksStat from '@pages/app/tasks/components/tasks-stat';
import { Gap } from '@components/layout-util-components/gap';
import NoFilteredTasksInfo from './components/no-filtered-tasks-info';
import { useEffect } from 'react';
import { resetFocus } from '@store/rca-editor/rca-editor-slice';
import { useAppDispatch } from '@store/store';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { numberFromString } from '@util/string-util';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import TaskForm from '@pages/app/tasks/forms/task-form';
import TasksTableData from '@components/tasks/tasks-table-data';
import TasksFilters from '@components/tasks/tasks-filters';
import PageCount from '@components/typography/page-count';

type PageParams = {
  taskId: string;
};

interface Props {
  caseId?: number;
}

const StatsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 40,
  rowGap: 10,
  flexWrap: 'wrap',
  overflowX: 'auto',
  width: '100%',
  marginTop: 32,
});

function TasksIndexPage({ caseId }: Props) {
  const dispatch = useAppDispatch();
  const { taskId: focusTaskId } = useSearchParamsTyped<PageParams>();
  const { showPopup, showSingle } = useUiPopup();

  const {
    hasNoTasks,
    hasNoFilteredTasks,
    hasFilteredTasks,
    search,
    isLoading,
    isFetching,
    tasks,
    userOptions,
    taskStatusOptions,
    companyUserFilter,
    taskStatusFilter,
    pagination,
    displayCaseName,
  } = useTasks(caseId);

  const { highPriority, open, overdue } = tasks?.counts ?? {};

  useEffect(() => {
    dispatch(resetFocus());

    const taskIdNumber = numberFromString(focusTaskId);
    if (taskIdNumber != null) {
      showSingle(() => showPopup(TaskForm, { taskId: taskIdNumber, caseId }));
    }
  }, [caseId, dispatch, focusTaskId, showPopup, showSingle]);

  return (
    <ContentContainer maxWidth={1500}>
      <PageCount title="Tasks" isLoading={isLoading} count={tasks?.count} />

      <StatsContainer>
        <TasksStat label="OPEN TASKS" stat={open ?? 0} />
        <TasksStat label="OVERDUE TASKS" stat={overdue ?? 0} />
        <TasksStat label="HIGH PRIORITY" stat={highPriority ?? 0} />
      </StatsContainer>
      <Gap size={32} />
      <TasksFilters
        isFetching={isFetching}
        isLoading={isLoading}
        search={search}
        taskStatusFilter={taskStatusFilter}
        taskStatusOptions={taskStatusOptions}
        userOptions={userOptions}
        companyUserFilter={companyUserFilter}
      />
      <Gap size={10} />
      {hasNoTasks && <NoTasksInfo isCaseTasks={caseId != null} />}
      {hasNoFilteredTasks && <NoFilteredTasksInfo />}
      {hasFilteredTasks && (
        <TasksTableData
          data={tasks}
          isLoading={isLoading}
          pagination={pagination}
          displayCaseName={displayCaseName}
        />
      )}
    </ContentContainer>
  );
}

export default requireAuth(TasksIndexPage);
