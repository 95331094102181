import { Children, PropsWithChildren } from 'react';
import { TableRowSkeleton } from '@components/skeletons';
import { styled } from '@mui/material';

interface Props {
  isLight?: boolean;
  isLoading?: boolean;
}

interface ItemRowProps {
  className?: string;
  highlight?: boolean;
  onClick?: () => void;
}

const ListItemContainer = styled('tr', {
  target: 'list-item-container',
})<ItemRowProps>(({ highlight, theme }) => ({
  minHeight: 56,

  '& td': {
    margin: 0,
    padding: 10,

    '&:first-of-type': {
      paddingLeft: 20,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    '&:last-of-type': {
      paddingRight: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
}));

const Table = styled('table')<Props>(({ isLight }) => ({
  margin: 0,
  borderCollapse: 'separate',
  borderSpacing: '0 8px',
  width: '100%',

  '& .list-item-container': {
    background: isLight ? 'white' : '#fff',
  },
}));

export function ListContainer({
  children,
  isLight,
  isLoading,
}: PropsWithChildren<Props>) {
  return (
    <div style={{ overflowX: 'scroll', paddingBottom: 10 }}>
      <Table isLight={isLight}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Level</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
            </>
          ) : (
            children
          )}
        </tbody>
      </Table>
    </div>
  );
}

export function ListItemRow({
  children,
  className,
  highlight,
  onClick,
}: PropsWithChildren<ItemRowProps>) {
  return (
    <ListItemContainer
      highlight={highlight}
      onClick={onClick}
      className={className}
    >
      {Children.map(children, (child, idx) => (
        <td key={idx}>{child}</td>
      ))}
    </ListItemContainer>
  );
}
