import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  styled,
  Switch,
} from '@mui/material';
import { ReactNode } from 'react';

interface StatusLabel {
  on?: string;
  off?: string;
}

interface Props {
  name?: string;
  label?: ReactNode;
  value: boolean;
  error?: string;
  onChange: (value: boolean) => void;
  onBlur?: () => void;
  disabled?: boolean;
  statusLabel?: StatusLabel;
}

interface SwitchContainerProps {
  variant?: 'light' | 'grey';
}

export const SwitchContainer = styled('div')<SwitchContainerProps>(
  ({ variant = 'light' }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: '15px 20px',
    borderRadius: '8px',
    background: variant === 'light' ? '#FFF' : 'rgba(0, 0, 0, 0.2)',
    // '.toggle_switch': {
    //   '.MuiSwitch-thumb': {
    //     color: '#fff',
    //     filter:
    //       'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))',
    //   },
    //   '.Mui-checked+.MuiSwitch-track': {
    //     background: '#3CA680',
    //     opacity: '1',
    //   },
    //   '.MuiTouchRipple-root:hover': {
    //     background: 'rgba(0,0,0,0.05)',
    //   },
    // },
    // '.MuiFormControlLabel-label': {
    //   fontSize: 14,
    // },
  })
);

export default function WCTSwitchField({
  onBlur,
  value,
  name,
  onChange,
  error,
  label,
  disabled,
  statusLabel,
}: Props) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={value}
            onChange={(_, v) => onChange(v)}
            onBlur={onBlur}
            className="toggle_switch"
            disabled={disabled}
          />
        }
        label={label}
      />
      {statusLabel && (
        <FormLabel sx={{ fontSize: '14px' }}>
          {value ? statusLabel.on : statusLabel.off}
        </FormLabel>
      )}
      {error ? <FormLabel error>{error}</FormLabel> : null}
    </FormGroup>
  );
}
