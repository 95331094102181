import { styled } from '@mui/material';

const Row = styled('div', { target: 'custom-row' })<{
  centerContent?: boolean;
  contentAtEnd?: boolean;
  noWrap?: boolean;
  wrap?: boolean;
  spaceBetween?: boolean;
  gap?: number;
  stretchItems?: boolean;
}>(
  ({
    centerContent,
    noWrap,
    stretchItems,
    wrap,
    contentAtEnd,
    spaceBetween,
    gap,
  }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: stretchItems ? 'stretch' : 'center',
    justifyContent: centerContent
      ? 'center'
      : contentAtEnd
      ? 'flex-end'
      : spaceBetween
      ? 'space-between'
      : 'flex-start',
    wrap: noWrap ? 'nowrap' : wrap ? 'wrap' : undefined,
    gap: gap,
  })
);

export default Row;
