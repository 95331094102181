import { Option } from '@api/types/option';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { getColorForId } from '@util/colour-identifiers';

const StyledLabelWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean; categoryColour: string }>(
  ({ theme, categoryColour }) => ({
    borderRadius: '3px',
    flexBasis: '25%',
    backgroundColor: '#fff',
    minHeight: 65,
    cursor: 'pointer',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 2px 5px 0px rgba(0, 0, 0, 0.04), 0px 1px 0px 0px rgba(0, 0, 0, 0.05)',
    alignSelf: 'stretch',
    borderTop: `3px solid ${categoryColour}`,

    '& p': {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px' /* 138.462% */,
      letterSpacing: '0.16px',
      padding: '3px 6px',
    },
  })
);

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignSelf: 'stretch',
  margin: `${theme.spacing(7)} 0`,
}));

interface Props {
  sx?: SxProps<Theme>;
  categories?: Option<number>[];
  selected?: number[];
  onClick?: (id: number) => void;
}

export const Categories = ({ categories, selected, onClick }: Props) => {
  if (!categories?.length) {
    return <Box mb={6} />;
  }

  return (
    <StyledContainer>
      {categories?.map((option) => (
        <StyledLabelWrapper
          key={option.id}
          onClick={() => {
            onClick && onClick(option.id);
          }}
          isSelected={selected?.includes(option.id)}
          categoryColour={getColorForId(option.id)}
        >
          <Typography>{option.label}</Typography>
        </StyledLabelWrapper>
      ))}
    </StyledContainer>
  );
};
