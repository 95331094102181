import { Option } from '@api/types/option';
import { styled } from '@mui/material';
import CircleContainer from '@components/containers/circle-container';
import { getInitials } from '@util/name-util';

interface Props {
  items: Array<Option<number | string>>;
  maxCount?: number;
  providedPlusCount?: number;
  extractInitials?: boolean;
}

const Container = styled('div', { target: 'grouped-avatars-container' })(
  ({ theme: { palette } }) => ({
    display: 'flex',
    '> .circle-container': {
      margin: 0,
      fontSize: 10,
      fontWeight: 600,
      boxShadow: `0 0 0 2px ${palette.common.white}`,
      background: palette.common.grey40,
      color: palette.common.black,
    },
  })
);

const OVERLAP_PIX = 7;

export default function GroupedAvatars({
  items,
  extractInitials,
  providedPlusCount,
  maxCount = 3,
}: Props) {
  const plusCount = !!providedPlusCount
    ? providedPlusCount
    : items.length > maxCount
    ? items.length - maxCount
    : null;

  return (
    <Container>
      {items
        .slice(0, Math.min(items.length, maxCount))
        .map(({ id, label }, index) => (
          <CircleContainer
            sx={{
              transform: `translateX(${-index * OVERLAP_PIX}px)`,
              zIndex: maxCount - index,
            }}
            key={id}
            size={32}
          >
            {extractInitials ? getInitials(label) : label}
          </CircleContainer>
        ))}
      {plusCount != null ? (
        <CircleContainer
          sx={{
            transform: `translateX(${-maxCount * OVERLAP_PIX}px)`,
            zIndex: 0,
          }}
          size={32}
        >
          +{plusCount}
        </CircleContainer>
      ) : null}
    </Container>
  );
}
