import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { Button, styled, Typography } from '@mui/material';
import useShareOutcomeModal, {
  SharePersonModel,
} from '@pages/app/rca/outcome/modals/share-outcome-modal-hook';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { InputSkeleton } from '@components/skeletons';
import WCTGenericTypeaheadField from '@components/input/generic-typeahead-field';
import { Gap } from '@components/layout-util-components/gap';

export interface ShareOutcomeModalProps extends ModalProps<boolean> {
  caseId: number;
  outcomeId: number;
}

const Container = styled('form')({
  minWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

function ShareOutcomeModal({
  caseId,
  outcomeId,
  onClose,
}: ShareOutcomeModalProps) {
  const {
    isLoading,
    people,
    search,
    share,
    isBusy,
    canSubmit,
    copyUrlToClipboard,
  } = useShareOutcomeModal(caseId, outcomeId);

  return (
    <Container
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        if (await share()) {
          onClose(true);
        }
      }}
      sx={{
        minWidth: '0 !important',
        '.MuiButton-textSizeMedium': {
          textTransform: 'none',
          marginLeft: 'auto',
          color: '#00223A',
          minWidth: '112px',
        },
      }}
    >
      <Row>
        <Typography variant="h1">Share Outcome</Typography>
        <Button variant="text" onClick={copyUrlToClipboard}>
          <FontAwesomeIcon icon={faLink} />
          <Gap size={4} />
          Copy link
        </Button>
      </Row>
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTGenericTypeaheadField
          id="users"
          error={people.error}
          value={people.value}
          getOptionLabel={(x: SharePersonModel) => x.nameOrEmail}
          isOptionEqualToValue={(a, b) => a.nameOrEmail === b.nameOrEmail}
          onChange={(v) => people.set(v as Array<SharePersonModel>)}
          label="Select People"
          loadOptions={search}
          required
          multiple
        />
      )}
      {/* Commenting out as client has decided to no use passwords for now */}
      {/*<Gap size={16} />*/}
      {/*<Box alignSelf="flex-start">*/}
      {/*  {isLoading ? (*/}
      {/*    <CheckboxSkeleton />*/}
      {/*  ) : (*/}
      {/*    <WCTCheckboxField*/}
      {/*      name="addPassword"*/}
      {/*      label="Add password"*/}
      {/*      error={addPassword.error}*/}
      {/*      value={addPassword.value}*/}
      {/*      onChange={addPassword.set}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Box>*/}
      {/*{addPassword.value ? (*/}
      {/*  <>*/}
      {/*    <Gap size={16} />*/}
      {/*    {isLoading ? (*/}
      {/*      <InputSkeleton />*/}
      {/*    ) : (*/}
      {/*      <WCTTextField*/}
      {/*        label="Password"*/}
      {/*        name="password"*/}
      {/*        value={password.value}*/}
      {/*        error={password.error}*/}
      {/*        onChange={password.set}*/}
      {/*        obscure*/}
      {/*        required*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*) : null}*/}
      <Gap size={24} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Share Outcome
        </PrimaryButton>
      </ActionsRow>
    </Container>
  );
}

export default withModal(ShareOutcomeModal);
