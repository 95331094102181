import { styled } from '@mui/material';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import FlatBadge from '@components/badges/flat-badge';
import { OutlinedButton } from '@components/buttons';
import { useState } from 'react';

interface Props {
  name: string;
  kudosCount: number;
  onAllocateMore?: () => void;
}

// item of a grid of 2 columns that wrap every 2 items
const Container = styled('div')({
  border: '1px solid rgba(0,0,0,0.25)',
  borderRadius: 4,
  padding: '8px 10px 8px 10px',
  display: 'inline-flex',
  flex: '1 1 auto',
  gap: 10,
  alignItems: 'center',
  '.subtitle': {
    fontWeight: 400,
    fontSize: '13px',
  },
  '.flat-badge': {
    marginLeft: 'auto',
    marginRight: '0.5rem',
  },
  '&.added-points': {
    border: '2px solid #3CA680',
    padding: '7px 9px 7px 9px',
    '.flat-badge': {
      background: '#3CA680',
    },
  },
  button: {
    width: '60px',
    padding: 0,
    minWidth: '0',
    fontSize: '12px',
  },
});

export default function KudosPersonItem({
  name,
  kudosCount,
  onAllocateMore,
}: Props) {
  const [didAllocate, setDidAllocate] = useState(false);

  return (
    <Container className={didAllocate ? 'added-points' : undefined}>
      <InitialsContainerWithData fullName={name} />
      <FlatBadge>{`${kudosCount}pts`}</FlatBadge>
      <OutlinedButton
        onClick={() => {
          if (onAllocateMore) {
            onAllocateMore();
            setDidAllocate(true);
          }
        }}
        disabled={didAllocate || !onAllocateMore}
      >
        + 5pts
      </OutlinedButton>
    </Container>
  );
}
