import { Box, Link, styled, Typography } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';
import AppLogo from '@assets/svgs/app-logo.svg';

const PageWrapper = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  minWidth: '100vw',
  minHeight: '100vh',
  boxSizing: 'border-box',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 50,
  background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
  backgroundSize: 'cover',
}));

const Logo = styled('div')(({ theme: { palette } }) => ({
  width: 80,
  height: 80,
}));

const ErrorPanel = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 540,
  minEidth: 315,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: palette.common.white,
  borderRadius: 12,
  padding: 40,
  '@media (min-height:600px)': {
    marginBottom: 130,
  },
  p: {
    margin: '5px 0 30px',
    textAlign: 'center',
  },
}));

export default function CheckingPermissionView() {
  return (
    <PageWrapper>
      <Logo>
        <Link href="/">
          <img className="nav-logo" src={AppLogo} alt="logo" />
        </Link>
      </Logo>
      <ErrorPanel>
        <Typography variant="h1">Checking Permission</Typography>
        <Typography variant="body1">
          Please wait while we check your permission to view this outcome.
        </Typography>
        <Box mt={2} mb={2} alignSelf="center" position="relative">
          <LoadingIndicator show />
        </Box>
      </ErrorPanel>
    </PageWrapper>
  );
}
