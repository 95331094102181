import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import Row from '@components/layout-util-components/row';
import { Skeleton, styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton, OutlinedButton, PrimaryButton } from '@components/buttons';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import Column from '@components/layout-util-components/column';
import CaseFileItem from '@components/cards/case-file-item';
import useCaseFileManagerList from '@pages/app/rca/tabs/components/modals/case-file-manager/views/case-file-manager-list-hook';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import { Gap } from '@components/layout-util-components/gap';
import ConditionDivider from '@components/layout-util-components/condition-divider';

interface Props {
  state: CaseFileManagerState;
  onClose: (caseFiles?: Array<CaseFileListItemResponse>) => void;
}

const ListContainer = styled(Column, {
  target: 'case-file-manager-list-view-container',
})({
  gap: 10,
  height: 257,
  overflowY: 'auto',
});

export default function CaseFileManagerListView({ state, onClose }: Props) {
  const {
    isLoading,
    gotoAddNew,
    search,
    isFetching,
    fileType,
    fileTypeOptions,
    caseFiles,
  } = state;

  const { toggleFileSelection, isSelected, canSubmit, selectedCaseFiles } =
    useCaseFileManagerList(state);

  const renderTrailing = (caseFile: CaseFileListItemResponse) => {
    if (isSelected(caseFile.caseFileId)) {
      return (
        <FlatButton
          onClick={() => toggleFileSelection(caseFile.caseFileId)}
          dark
        >
          <FontAwesomeIcon icon={faCheck} />
        </FlatButton>
      );
    } else {
      return (
        <FlatButton onClick={() => toggleFileSelection(caseFile.caseFileId)}>
          <FontAwesomeIcon icon={faPlus} />
        </FlatButton>
      );
    }
  };

  return (
    <>
      <OutlinedButton onClick={gotoAddNew}>
        {isLoading ? <Skeleton /> : ' Add File or Location'}
      </OutlinedButton>
      <Gap size={20} />
      <ConditionDivider />
      <Gap size={20} />
      <Row>
        <Typography variant="h6">
          {isLoading ? <Skeleton /> : 'Select file(s) from existing library'}
        </Typography>
      </Row>
      <Gap size={12} />
      <WCTTableFilters
        isLoading={isLoading}
        isFetching={isFetching}
        search={search.value}
        onSearch={search.set}
        searchPlaceholder="Type to search files"
      >
        <DropDownFilter
          value={fileType.value}
          onChange={fileType.set}
          options={fileTypeOptions}
        >
          File type
        </DropDownFilter>
      </WCTTableFilters>
      <Gap size={15} />
      <ListContainer>
        {caseFiles?.model.map((caseFile) => (
          <CaseFileItem
            key={caseFile.caseFileId}
            caseFile={caseFile}
            trailing={renderTrailing(caseFile)}
          />
        ))}
      </ListContainer>
      <Gap size={15} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose(undefined)}>
          Cancel
        </OutlinedButton>
        <PrimaryButton
          onClick={() => onClose(selectedCaseFiles)}
          disabled={!canSubmit}
        >
          Select file(s)
        </PrimaryButton>
      </ActionsRow>
    </>
  );
}
