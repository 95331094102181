import { usePagination, useSortableHeader } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { useGetAllWAInstallsQuery } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useField from '@hooks/use-field-hook';

export default function useWAInstallsIndexPage() {
  const search = useField<string>();
  const pagination = usePagination(10);
  const sort = useSortableHeader({
    allowNoSort: false,
    initialProperty: 'name',
    initialDirection: SortDirection.asc,
  });

  const sortByOptions: Array<SortOption> = [
    {
      id: 'name',
      label: 'Name (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'name',
      label: 'Name (Desc)',
      direction: SortDirection.desc,
    },
    {
      id: 'companyCount',
      label: 'Company Count (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'companyCount',
      label: 'Company Count (Desc)',
      direction: SortDirection.desc,
    },
    {
      id: 'created',
      label: 'Created (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'created',
      label: 'Created (Desc)',
      direction: SortDirection.desc,
    },
  ];

  const {
    data: installs,
    isLoading: loadingInstalls,
    isFetching,
  } = useGetAllWAInstallsQuery({
    searchText: search.value,
    skip: pagination.skip,
    take: pagination.take,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const isLoading = loadingInstalls;

  return {
    isLoading,
    isFetching,
    search,
    pagination,
    sort,
    sortByOptions,
    installs,
  };
}
