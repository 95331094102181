import { useState } from 'react';

type Callback<T, A = void> = (...args: A[]) => Promise<T>;

export default function useBusyAction<T, A = void>(
  callback: Callback<T, A>
): [Callback<T, A>, boolean] {
  const [busy, setBusy] = useState(false);

  const start = async (...args: A[]) => {
    setBusy(true);
    try {
      return await callback(...args);
    } finally {
      setBusy(false);
    }
  };

  return [start, busy];
}
