import useField from '@hooks/use-field-hook';
import useSharedState from '@store/shared-state-hook';
import { useTurnOff2FAMutation } from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useGetCompanyDetailsQuery } from '@api/endpoints/company/company.api';

export default function useMfaSetup() {
  const { profile } = useSharedState();
  const { showErrorMessage } = usePageAlertVariants();

  const [turnOff2FA] = useTurnOff2FAMutation();

  const { data: companyDetails, isLoading: loadingCompany } =
    useGetCompanyDetailsQuery();

  const isOn = useField<boolean>([], profile!.twoFactorEnabled);

  const disable2FA = (): Promise<boolean> => {
    return turnOff2FA()
      .unwrap()
      .then(() => true)
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    isOn,
    disable2FA,
    isLoading: loadingCompany,
    isCompanyMfaEnforced: companyDetails?.force2FA,
  };
}
