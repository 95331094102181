import {
  ListContainer,
  ListItemRow,
} from '@components/containers/list-container';
import { Skeleton } from '@mui/material';

interface Props {
  count?: number;
}
export default function ListContainerSkeleton({ count = 3 }: Props) {
  return (
    <ListContainer>
      {Array.from({ length: count }, (_, index) => (
        <ListItemRow key={index}>
          <Skeleton sx={{ flex: '1 1 auto', height: '100%' }} />
        </ListItemRow>
      ))}
    </ListContainer>
  );
}
