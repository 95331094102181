import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { Box, Link, TableCell, TableRow, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/cause-box.svg';
import { ReactComponent as AttachmentIndicatorIcon } from '@assets/svgs/attachment-indicator.svg';
import CircleContainer from '@components/containers/circle-container';
import { getInitials } from '@util/name-util';
import { ElipsisButton } from '@components/buttons';
import TaskCompleteIndicator from '@pages/app/tasks/components/task-complete-indicator';
import { NodePanelEditorTab } from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';
import usePersonalTaskOptions from '@hooks/tasks/use-personal-task-options';
import TaskPriorityIndicator from '@components/tasks/task-priority-indicator';
import { dateOnlyLongFormatter } from '@i18n/formatters';
import Spacer from '@components/layout-util-components/spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { truncateString } from '@util/string-util';

interface Props {
  task: TaskDetailListItemResponse;
  displayCaseName?: boolean;
}

export default function TaskRow({ task, displayCaseName }: Props) {
  const {
    taskDetailId,
    title,
    caseId,
    caseName,
    chainItemId,
    chainItemName,
    dueDate,
    assignedToCompanyUser,
    assignedToCompanyUserId,
    priority,
    numberOfAttachments,
    taskStatus,
    canEdit,
  } = task;
  const {
    onEditTask,
    options,
    onCompleteTask,
    onOpenTask,
    isComplete,
    isOverdue,
    isTogglingState,
    currentCompanyUserId,
  } = usePersonalTaskOptions(taskDetailId, taskStatus, {
    caseId,
  });

  return (
    <TableRow
      onClick={() => canEdit && onEditTask()}
      className={isComplete ? 'complete' : undefined}
    >
      <TableCell width={64}>
        <TaskCompleteIndicator
          isComplete={isComplete}
          onComplete={onCompleteTask}
          onOpen={onOpenTask}
          isTogglingState={isTogglingState}
          readOnly={!canEdit}
        />
      </TableCell>
      <TableCell className={'task_title'}>
        <Row>
          <Typography variant="body1">{title}</Typography>
          {priority && (
            <>
              <Gap size={15} />
              <Spacer />
              <div className={'priority'}>
                <TaskPriorityIndicator priority={priority} />
              </div>
            </>
          )}
        </Row>
      </TableCell>
      {displayCaseName ? (
        <TableCell
          sx={{
            a: {
              color: '#00223A',
              textDecoration: 'underline',
            },
          }}
        >
          <Row>
            <RcaIndicatorIcon width={28} height="auto" />
            <Gap size={10} />
            <Link
              href={`/rca/tabs/${caseId}/chart`}
              onClick={(e) => e.stopPropagation()}
            >
              {truncateString(caseName, 45)}
            </Link>
          </Row>
        </TableCell>
      ) : null}
      {!displayCaseName ? (
        <TableCell
          sx={{
            a: {
              color: '#00223A',
              textDecoration: 'underline',
            },
          }}
        >
          <Row>
            <Box
              sx={{
                background: 'rgba(0, 0, 0, 0.075)',
                width: 32,
                height: 32,
                flexShrink: 0,
                borderRadius: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                svg: {
                  height: 20,
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              }}
            >
              <CauseBoxIcon width={22} height="auto" />
            </Box>
            <Gap size={10} />
            <Link
              href={`/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}&panelTab=${NodePanelEditorTab.tasks}`}
              onClick={(e) => e.stopPropagation()}
            >
              {chainItemName}
            </Link>
          </Row>
        </TableCell>
      ) : null}
      <TableCell
        sx={{
          color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
        }}
      >
        {dueDate ? (
          <Row>
            <Box
              sx={{
                background: isOverdue
                  ? 'rgba(255,61,61,0.1)'
                  : 'rgba(0, 0, 0, 0.075)',
                width: 32,
                height: 32,
                flexShrink: 0,
                borderRadius: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
                svg: {
                  height: 20,
                  color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
                },
              }}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </Box>
            <Gap size={10} />
            {dateOnlyLongFormatter(dueDate)}
          </Row>
        ) : (
          <div />
        )}
      </TableCell>
      <TableCell width={200}>
        {assignedToCompanyUser ? (
          <Row>
            <CircleContainer size={32}>
              {getInitials(assignedToCompanyUser)}
            </CircleContainer>
            <Gap size={15} />
            <Typography
              sx={{
                color:
                  assignedToCompanyUserId === currentCompanyUserId
                    ? '#00223A'
                    : undefined,
                fontSize: '15px',
              }}
            >
              {assignedToCompanyUser}
            </Typography>
          </Row>
        ) : (
          <div />
        )}

        {numberOfAttachments ? <AttachmentIndicatorIcon /> : <></>}
      </TableCell>
      <TableCell width={64}>
        {canEdit ? <ElipsisButton actions={options} /> : <div></div>}
      </TableCell>
    </TableRow>
  );
}
