import { Box, CircularProgress } from '@mui/material';

interface Props {
  show: boolean;
}

const SIZE = 18;

export default function FetchingIndicator({ show }: Props) {
  return (
    <Box flex="0 0 auto" width={SIZE} height={SIZE} alignSelf="center">
      {show ? <CircularProgress size={SIZE} /> : null}
    </Box>
  );
}
