import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, Grid, Link, styled, Typography } from '@mui/material';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import OutcomeSeverityBadge from '../components/outcome-severity-badge';
import EvidenceStrengthBadge from '../components/evidence-strength-badge';
import CaseFileItem from '@components/cards/case-file-item';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';
import CaseFileViewModal, {
  CaseFileViewModalProps,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-view-modal';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import EvidenceForm from './../../../pages/app/evidence/forms/evidence-form';

interface Props {
  evidence: Array<CaseEvidenceResource>;
  className: string;
}

const StyledEvidenceSection = styled('div')(({ theme: { palette } }) => ({
  '.section-title': {
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  '> .dark': {
    '.overview-title': {
      color: palette.common.white,

      '.highlight': {
        color: palette.primary.main,
      },
    },
    '.stat-container .item.selected': {
      border: '2px solid #FF6C00 !important',
    },
    '.MuiTableHead-root>tr>th': {
      color: '#ffffff',
    },
  },
  '> .light': {},
  '> .white': {},
}));

const StyledGridContainer = styled(Grid)(({ theme: { palette } }) => ({
  border: '1px solid rgba(0, 0, 0, 0.25)',
  borderRadius: '5px',
  background: '#FFF',
}));
const StyledGridLeft = styled(Grid)(({ theme: { palette } }) => ({
  padding: '20px',
  borderRight: '1px solid rgba(0, 0, 0, 0.25)',
}));
const StyledGridRight = styled(Grid)(({ theme: { palette } }) => ({
  padding: '20px',
}));

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  padding: '12px 75px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  'table.MuiTable-root': {
    borderSpacing: '0 8px',
  },
  'table.MuiTable-root tr th': {
    backgroundColor: '#334E61',
    color: '#fff',
    padding: '15px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  'table.MuiTable-root tr, table.MuiTable-root tr:hover': {
    td: {
      padding: '12px 15px',
      borderTop: '1px solid rgba(0,0,0,0.25)',
      borderBottom: '1px solid rgba(0,0,0,0.25)',
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderLeft: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
        borderRight: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
    },
  },
}));

const CardInfo = styled('div')(({ theme: { palette } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  '& .MuiTypography-overline': {
    display: 'block',
    marginBottom: '0.5em',
    color: palette.blue.dark,
    fontSize: '12px',
  },
  '& .MuiTypography-body2': {
    color: palette.blue.dark,
  },
  '& .circle-container': {
    backgroundColor: palette.success.main,
    opacity: 1,
    fontSize: '10px',
    color: '#fff',
  },
  '& .name-container .label': {
    fontSize: '15px',
    fontWeight: 400,
  },
}));

export default function EvidenceDetailSection({ evidence, className }: Props) {
  const { caseId } = useParams();
  const { showPopup, showSingle } = useUiPopup();
  const navigate = useNavigate();

  if (!evidence || evidence.length === 0) {
    return <></>;
  }

  return (
    <StyledEvidenceSection>
      <SectionScaffold id="evidence" className={className}>
        <Container>
          <div className="section-title">Evidence Detail</div>
          {evidence.map((e) => (
            <StyledGridContainer container>
              <StyledGridLeft item xs={4}>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    fontWeight: 500,
                    fontSize: '20px',
                  }}
                  variant="body1"
                >
                  {e.name}{' '}
                  {/* <Link
                    onClick={() => {
                      navigate(`/rca/tabs/${caseId}/evidence`);
                      showSingle(() =>
                        showPopup(EvidenceForm, {
                          caseId: Number(caseId!),
                          evidence: e,
                          viewMode: true,
                        })
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link> */}
                </Typography>
                {e.description && (
                  <CardInfo>
                    <Typography variant="overline">Description</Typography>
                    <Typography sx={{ marginBottom: '20px' }} variant="body2">
                      {e.description}
                    </Typography>
                  </CardInfo>
                )}
                <Grid sx={{ marginBottom: '20px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <CardInfo>
                      <Typography variant="overline">Sensitivity</Typography>
                      <OutcomeSeverityBadge severity={e.sensitivity} />
                    </CardInfo>
                  </Grid>
                  <Grid item xs={6}>
                    <CardInfo>
                      <Typography variant="overline">Strength</Typography>
                      <EvidenceStrengthBadge strength={e.quality.toString()} />
                    </CardInfo>
                  </Grid>
                </Grid>
                <AssociatedCauseBoxBadge count={e.chainItemIds?.length ?? 5} />
              </StyledGridLeft>
              <StyledGridRight
                item
                xs={8}
                justifyContent="center"
                alignItems="center"
              >
                {!e.caseFiles ||
                  (e.caseFiles.length === 0 && (
                    <Typography variant="body1">
                      No files to display for this evidence
                    </Typography>
                  ))}
                {e.caseFiles && e.caseFiles.length > 0 && (
                  <Box>
                    <CardInfo>
                      <Typography variant="overline">Files</Typography>
                    </CardInfo>
                    {e.caseFiles.map((caseFile) => (
                      <Box sx={{ marginBottom: '10px' }}>
                        <CaseFileItem
                          caseFile={CaseFileResource.toListItemResponse(
                            caseFile
                          )}
                          iconSize="medium"
                          overrideIconSize={50}
                          trailing={
                            <Box>
                              <OutlinedButton
                                onClick={() => {
                                  showPopup<CaseFileViewModalProps, never>(
                                    CaseFileViewModal,
                                    {
                                      caseId: Number(caseId!),
                                      caseFileId: caseFile.caseFileId,
                                      onDelete: undefined,
                                    }
                                  );
                                }}
                              >
                                View File{' '}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </OutlinedButton>
                            </Box>
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </StyledGridRight>
            </StyledGridContainer>
          ))}
          <Box alignSelf="center">
            <PrimaryButton
              onClick={() => navigate(`/rca/tabs/${caseId}/evidence`)}
            >
              View Evidence <FontAwesomeIcon icon={faArrowRight} />
            </PrimaryButton>
          </Box>
        </Container>
      </SectionScaffold>
    </StyledEvidenceSection>
  );
}
