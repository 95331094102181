import Column from '@components/layout-util-components/column';
import { useAppTr } from '@i18n/use-app-tr';
import { Grid, Typography } from '@mui/material';
import MfaRadioGroup from '@components/mfa/mfa-radio-group';
import useSetupMfaForm from '@pages/app/profile/settings/forms/setup-mfa-form-hook';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { LinkButton, OutlinedButton, PrimaryButton } from '@components/buttons';
import { LoadingIndicator } from '@components/loading-indicator';
import withModal from '@components/ui-popup/modals/modal-hoc';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';
import WCTTextField from '@components/input/text-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { Gap } from '@components/layout-util-components/gap';

export interface SetupMfaFormProps extends UiPopupProps<boolean> {}

function SetupMfaForm({ onClose }: SetupMfaFormProps) {
  const { t } = useAppTr('mfaSettings');

  const {
    isLoading,
    options,
    setMethod,
    showCodeEntry,
    moveToCodeEntry,
    canSubmit,
    selectedMethodValue,
    code,
    isFetchingCode,
    resendCode,
    submitCode,
    isSubmitting,
  } = useSetupMfaForm();

  const onSubmitCode = async () => {
    if (await submitCode()) {
      onClose(true);
    }
  };

  return (
    <Column>
      {showCodeEntry ? (
        <>
          <Typography variant="h6">{t('enterCodeModal.title')}</Typography>
          <Gap size={24} />
          <Typography variant="body1">
            {t('enterCodeModal.message', { value: selectedMethodValue })}
          </Typography>
          <Gap size={15} />
          <Row>
            <Grid item xs={6}>
              <WCTTextField
                name="code"
                label={t('enterCodeModal.codeLabel')}
                value={code.value}
                error={code.error}
                onChange={code.set}
                onBlur={code.validate}
                maxLength={6}
              />
            </Grid>
            <Gap size={20} />
            <LinkButton disabled={isFetchingCode} onClick={resendCode}>
              {t('enterCodeModal.resendButtonLabel')}
            </LinkButton>
          </Row>
        </>
      ) : (
        <>
          <Typography variant="h6">{t('optionSelectModal.title')}</Typography>
          <Gap size={24} />
          {isLoading ? (
            <LoadingIndicator show />
          ) : (
            <MfaRadioGroup
              mfaOptions={options}
              onOptionSelected={setMethod}
              instantSelect
            />
          )}
        </>
      )}
      <Gap size={20} />
      <ActionsRow>
        <ContactSupportLink />
        <Spacer />
        <OutlinedButton onClick={() => onClose(false)}>
          {t('optionSelectModal.cancelButtonLabel')}
        </OutlinedButton>

        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          onClick={() => {
            if (!showCodeEntry) {
              moveToCodeEntry();
            } else {
              onSubmitCode();
            }
          }}
        >
          {showCodeEntry
            ? t('enterCodeModal.submitButtonLabel')
            : t('optionSelectModal.submitButtonLabel')}
        </PrimaryButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(SetupMfaForm);
