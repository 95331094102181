import { styled } from '@mui/material';

export const CardLayout = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: 25,
  width: '100%',
});
