import { api } from '@api/api';
import { Option } from '@api/types/option';

const waPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWAPackageOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'package/options',
      }),
    }),
  }),
});

export const { useGetAllWAPackageOptionsQuery } = waPackageApi;
