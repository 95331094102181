import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

interface Props {
  id: string;
  backdropImg?: string;
  backgroundColor?: string;
  className?: string;
  autoWidth?: boolean;
}

const Container = styled('div')<Props>(
  ({ backdropImg, backgroundColor, autoWidth }) => ({
    width: autoWidth ? undefined : '100vw',
    overflowY: 'hidden',
    scrollSnapAlign: 'start',

    ...(backgroundColor && {
      background: backgroundColor,
    }),

    ...(backdropImg && {
      background: `url(${backdropImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }),
  })
);

export default function SectionScaffold({
  id,
  children,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Container id={id} {...props}>
      {children}
    </Container>
  );
}
