import { api } from '@api/api';
import { CanLeaveGroupResponse } from '@api/types/company-group-user/can-leave-group.response';
import { CanLeaveGroupRequest } from '@api/types/company-group-user/can-leave-group.request';
import { makeError } from '@api/types/api-error';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const companyGroupUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    canLeaveGroup: build.query<CanLeaveGroupResponse, CanLeaveGroupRequest>({
      query: (params) => ({
        url: 'companyGroupUser/canLeave',
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (_, error, arg) =>
        !error ? [{ id: arg.companyGroupId, type: 'CompanyGroup' }] : [],
    }),
    leaveGroup: build.mutation<void, number>({
      query: (id) => ({
        url: `companyGroupUser/leave/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyGroup' }, 'CompanyGroup'] : [],
      transformErrorResponse: (error) => makeError(error),
    }),
  }),
});

export const { useCanLeaveGroupQuery, useLeaveGroupMutation } =
  companyGroupUserApi;
