import { useCallback, useMemo, useState } from 'react';
import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';

export default function useCaseFileManagerList(state: CaseFileManagerState) {
  const { caseFiles, preselectedFileIds } = state;
  const [selectedFileIds, setSelectedFileIds] = useState<Array<number>>(
    useMemo(() => preselectedFileIds ?? [], [preselectedFileIds])
  );

  const isSelected = useCallback(
    (id: number) => selectedFileIds.includes(id),
    [selectedFileIds]
  );

  const toggleFileSelection = (id: number) => {
    if (isSelected(id)) {
      setSelectedFileIds(selectedFileIds.filter((fileId) => fileId !== id));
    } else {
      setSelectedFileIds([...selectedFileIds, id]);
    }
  };

  const selectedCaseFiles = useMemo(() => {
    return caseFiles?.model.filter((file) => isSelected(file.caseFileId)) ?? [];
  }, [caseFiles?.model, isSelected]);

  const canSubmit = selectedFileIds.length > 0;

  return {
    isSelected,
    toggleFileSelection,
    canSubmit,
    selectedCaseFiles,
  };
}
