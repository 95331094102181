import Row from '@components/layout-util-components/row';
import { Skeleton } from '@mui/material';

interface Props {
  count: number;
  itemHeight?: number;
  itemWidth?: number;
}

export default function GridSkeleton({
  count,
  itemHeight = 300,
  itemWidth,
}: Props) {
  const width = itemWidth ?? `${(1.0 / count) * 100.0}%`;

  return (
    <Row sx={{ margin: 0, padding: 0, gap: 1 }}>
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <Skeleton
            key={i}
            sx={{ margin: 0, padding: 0 }}
            width={width}
            height={itemHeight}
          />
        ))}
    </Row>
  );
}
