import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import useOauth, { OAuthStateParam } from '@hooks/oauth-hook';
import { useEffect, useState } from 'react';
import Column from '@components/layout-util-components/column';
import HaveAccountLoginLink from '@pages/auth/components/have-account-login-link';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

type QueryParams = {
  code: string;
  state: string;
};

export default function OAuthPage() {
  const navigate = useNavigate();
  const [isInvalid, setIsInvalid] = useState(false);

  const { loginWithOAuth, isAuthenticating, hasError } = useOauth();
  const { code, state } = useSearchParamsTyped<QueryParams>();

  useEffect(() => {
    if (code && state) {
      const stateInfo: OAuthStateParam = JSON.parse(atob(state));
      loginWithOAuth(code, stateInfo).then((shouldNavigate) => {
        if (shouldNavigate) {
          navigate('/');
        }
      });
    } else {
      setIsInvalid(true);
    }
  }, [code, loginWithOAuth, navigate, state]);

  return (
    <Column centerContent>
      <PageAlertDisplay />
      <Typography alignSelf="center">
        {isInvalid || hasError ? (
          'Invalid request'
        ) : isAuthenticating ? (
          <span>
            Authenticating... <CircularProgress size={18} />
          </span>
        ) : null}
      </Typography>
      <Gap size={20} />
      <HaveAccountLoginLink messageOverride="Go to" />
    </Column>
  );
}
