import {
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  styled,
  TextField,
} from '@mui/material';
import { Children, PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import Spacer from '@components/layout-util-components/spacer';

type Props = {
  isLoading?: boolean;
  isFetching?: boolean;
  search?: string;
  onSearch?: (query?: string) => void;
  searchPlaceholder?: string;
  rightOfSearchContent?: ReactNode;
  size?: 'normal' | 'large';
};

const StyledFilters = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 16,
  rowGap: 10,
});

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: '#D8DBE3',
    borderRadius: 4,
    boxShadow: '4px 0 20px rgba(0,0,0,0.05)',
  },
  '&>*': {
    height: 36,
    '&>input': {
      padding: 0,
    },
    '&>.MuiInputAdornment-root': {
      marginTop: '0 !important',
    },
  },
}));

export default function WCTTableFilters({
  isLoading,
  isFetching,
  search,
  onSearch,
  children,
  searchPlaceholder,
  rightOfSearchContent,
  size = 'normal',
}: PropsWithChildren<Props>) {
  const minWidth = size === 'large' ? 320 : 220;
  return (
    <StyledFilters>
      {onSearch != null ? (
        isLoading ? (
          <Skeleton variant="rectangular" width={minWidth}>
            <StyledSearchField
              variant="filled"
              type="search"
              value={search || ''}
              onChange={(e) => onSearch(e.target.value)}
              placeholder={searchPlaceholder || 'Type to search'}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Skeleton>
        ) : (
          <StyledSearchField
            variant="filled"
            type="search"
            value={search || ''}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={searchPlaceholder || 'Type to search'}
            sx={{
              minWidth: minWidth,
              marginRight: rightOfSearchContent ? '-16px' : 0,
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )
      ) : (
        <div></div>
      )}
      {rightOfSearchContent ? (
        <Box>{isLoading ? <Skeleton></Skeleton> : rightOfSearchContent}</Box>
      ) : null}
      <FetchingIndicator show={!isLoading && !!isFetching} />
      <Spacer />
      {children
        ? Children.map(children, (filter, i) => {
            return isLoading ? (
              <Skeleton variant="rectangular">{filter}</Skeleton>
            ) : (
              filter
            );
          })
        : null}
    </StyledFilters>
  );
}
