import { Paper, styled, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import { CaseAnalysisTypeType } from '@api/types/case-analysis-type/case-analysis-type';
const ProblemIcon = '/svgs/problem-icon_lg.svg';
const RiskIcon = '/svgs/risk-icon_lg.svg';
const ImprovementIcon = '/svgs/improvement-icon_lg.svg';

interface Props {
  title: string;
  description: string;
  onClick: () => void;
  caseAnalysisType: CaseAnalysisTypeType;
}

const Container = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 23px 25px',
  border: '2px solid rgba(0,0,0,0.25)',
  boxShadow: 'none',
  width: '100%',
  flexWrap: 'wrap',
  '@media (min-width:1024px)': {
    width: 'calc(33% - 16px)',
    padding: '50px 23px 10px',
  },
  cursor: 'pointer',
  borderRadius: 6,

  p: {
    marginTop: '10px',
    '@media (min-width:1024px)': {
      minHeight: 100,
    },
  },

  '&:hover': {
    border: '2px solid #FF6C00',
    /* outline: '5px solid rgba(255,108,0, 0.15)', */
  },

  transition: 'border,background-color 80ms',
});

export default function RcaTypeCard({
  title,
  onClick,
  description,
  caseAnalysisType,
}: Props) {
  return (
    <Container onClick={onClick}>
      {caseAnalysisType === 'Improvement' && (
        <img src={ImprovementIcon} alt="empty icon" width="100" />
      )}
      {caseAnalysisType === 'Problem' && (
        <img src={ProblemIcon} alt="empty icon" width="100" />
      )}
      {caseAnalysisType === 'Risk' && (
        <img src={RiskIcon} alt="empty icon" width="100" />
      )}
      <Gap size={20} />
      <Typography variant="subtitle2">{title}</Typography>
      <Gap size={4} />
      <Typography variant="body2">
        Use this flow to model success. This RCA will chart your route to
        successful implementation of goals and objectives.
      </Typography>
    </Container>
  );
}
