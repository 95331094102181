import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import { Box, styled, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTSliderField from '@components/input/slider-field';
import ImpactCategoryBadge from '@components/outcome-report/components/impact-category-badge';
import { isNullOrEmpty } from '@util/string-util';

interface Props {
  impact: CaseImpactResource;
  state: ImpactsPanelState;
}

const Container = styled('div', { target: 'impact-option' })(
  ({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0,0,0,0.25)',
    borderRadius: 5,
    marginBottom: 10,
    padding: 10,
    '.name': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      marginRight: '1rem',
    },
    '.caption': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      color: '#636D73',
      marginRight: '1rem',
    },
  })
);

const marks = [
  {
    value: 5,
    label: (
      <>
        Very <br /> Weak
      </>
    ),
  },
  {
    value: 10,
    label: '',
  },
  {
    value: 15,
    label: '',
  },
  {
    value: 20,
    label: '',
  },
  {
    value: 25,
    label: '',
  },
  {
    value: 30,
    label: 'Weak',
  },
  {
    value: 35,
    label: '',
  },
  {
    value: 40,
    label: '',
  },
  {
    value: 45,
    label: '',
  },
  {
    value: 50,
    label: '',
  },
  {
    value: 55,
    label: 'Medium',
  },
  {
    value: 60,
    label: '',
  },
  {
    value: 65,
    label: '',
  },
  {
    value: 70,
    label: '',
  },
  {
    value: 75,
    label: '',
  },
  {
    value: 80,
    label: 'Strong',
  },
  {
    value: 85,
    label: '',
  },
  {
    value: 90,
    label: '',
  },
  {
    value: 95,
    label: '',
  },
  {
    value: 100,
    label: '',
  },
  {
    value: 105,
    label: (
      <>
        Very <br /> Strong
      </>
    ),
  },
];

export default function ImpactOption({ impact, state }: Props) {
  const { currentValues, updateValue, canContribute, highlightMissingImpacts } =
    state;

  const onChange = (val: number | number[]) => {
    let v = val as number;

    if (v <= 0) {
      v = 0;
    } else if (v <= 5) {
      v = 1;
    } else {
      v = v - 5;
    }

    updateValue(impact.caseImpactId, v);
  };

  const formatValue = (val: number | number[] | undefined): string => {
    if (val === undefined) return '0%';

    let v = val as number;

    if (v <= 0) return '0%';

    if (v <= 5) return '0.5%';

    return `${v - 5}%`;
  };

  const calculateCurrentValue = (v?: number) => {
    if (v == null) {
      return;
    }

    if (v <= 0) return 0;
    if (v < 5) return 5;
    return v + 5;
  };

  const currentValue = calculateCurrentValue(
    currentValues?.[impact.caseImpactId] as number | undefined
  );

  return (
    <Container sx={currentValue == null ? { opacity: 0.5 } : {}}>
      <Box>
        <ImpactCategoryBadge category={impact.caseImpactTypeName} />
      </Box>
      <Gap size={8} />
      <Typography className="name">{impact.name}</Typography>
      {!isNullOrEmpty(impact.description) && (
        <>
          <Gap size={4} />
          <Typography className="caption">{impact.description}</Typography>
        </>
      )}
      <Gap size={8} />
      <WCTSliderField
        name={`${impact.caseImpactId}CaseImpact`}
        value={currentValue}
        onChange={onChange}
        step={5}
        max={105}
        formatValue={formatValue}
        marks={marks}
        readonly={!canContribute}
        error={
          highlightMissingImpacts && currentValue == null
            ? 'Please select a value'
            : undefined
        }
        valueLabelDisplay="on"
      />
    </Container>
  );
}
