import { api } from '@api/api';
import { Option } from '@api/types/option';

const casePriorityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCasePriorityOptions: build.query<Option[], void>({
      query: () => ({
        url: 'casePriority/options',
      }),
    }),
  }),
});

export const { useGetCasePriorityOptionsQuery } = casePriorityApi;
