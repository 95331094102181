import { useMemo } from 'react';
import { usePageAlert } from '@components/alerts';
import { AlertInfo } from '@components/alerts/hooks/page-alert-hook';
import { DefaultTFuncReturn } from 'i18next';
import { ErrorData } from '@api/types/api-error';

type AlertInfoWithoutType = Omit<AlertInfo, 'type'>;

export default function usePageAlertVariants() {
  const { showAlert, hideAlert } = usePageAlert();

  return useMemo(
    () => ({
      hideAlert,
      showSuccessAlert: (alert: AlertInfoWithoutType) =>
        showAlert({ type: 'success', ...alert }),
      showErrorAlert: (alert: AlertInfoWithoutType) =>
        showAlert({ type: 'error', ...alert }),
      showInfoAlert: (alert: AlertInfoWithoutType) =>
        showAlert({ type: 'info', ...alert }),
      showWarningAlert: (alert: AlertInfoWithoutType) =>
        showAlert({ type: 'warning', ...alert }),
      showSuccessMessage: (
        message: string | DefaultTFuncReturn,
        title?: string | DefaultTFuncReturn
      ) => showAlert({ type: 'success', message, title }),
      showErrorMessage: (
        message?: string | DefaultTFuncReturn | ErrorData,
        title?: string | DefaultTFuncReturn
      ) => showAlert({ type: 'error', message, title }),
      showInfoMessage: (
        message: string | DefaultTFuncReturn,
        title?: string | DefaultTFuncReturn
      ) => showAlert({ type: 'info', message, title }),
      showWarningMessage: (
        message: string | DefaultTFuncReturn,
        title?: string | DefaultTFuncReturn
      ) => showAlert({ type: 'warning', message, title }),
    }),
    [hideAlert, showAlert]
  );
}
