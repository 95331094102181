import { styled } from '@mui/material';

const PaperContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 40,
  background: 'white',
  textAlign: 'center',
});

export default PaperContainer;
