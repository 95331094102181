// This was added after a lot of the state was implemented in the node view
// will move across the rest of the state as it is implemented
import { RcaNode, RcaNodeType } from '@store/rca-editor/types';
import { useMemo } from 'react';
import {
  makeSelectIsNodeBusy,
  makeSelectNodeCollapsedState,
  makeSelectParentNodeType,
  selectChainId,
  selectRcaChartMode,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { RcaChartMode } from '@store/rca-editor/rca-editor-slice';
import { useCasePermission } from '@hooks/case/use-case-permission';

export default function useNode(node: RcaNode) {
  const { canEditGraph, canContribute } = useCasePermission();
  const { id, type, data } = node;

  const selectParentNodeType = useMemo(
    () => makeSelectParentNodeType(id),
    [id]
  );
  const selectIsNodeBusy = useMemo(() => makeSelectIsNodeBusy(id), [id]);
  const selectCollapsedState = useMemo(
    () => makeSelectNodeCollapsedState(id),
    [id]
  );

  const chainId = useAppSelector(selectChainId);
  const mode = useAppSelector(selectRcaChartMode);
  const isBusy = useAppSelector(selectIsNodeBusy);
  const collapsedState = useAppSelector(selectCollapsedState);
  const parentNodeType = useAppSelector(selectParentNodeType);
  const isPresenterMode = mode === RcaChartMode.present;
  const isEndState = type === RcaNodeType.endState;
  const isConnection = type === RcaNodeType.connection;
  const isEndStateOrConnection = isEndState || isConnection;

  return {
    id,
    data,
    chainId,
    isBusy,
    isEndState,
    isEndStateOrConnection,
    collapsedState,
    isConnection,
    parentNodeType,
    isPresenterMode,
    canEditGraph,
    canContribute,
  };
}

export type NodeState = ReturnType<typeof useNode>;
