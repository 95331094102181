import { useState } from 'react';
import { useAppSelector } from '@store/store';
import { selectStorageNodes } from '@store/rca-editor/selectors';
import { useCasePermission } from '@hooks/case/use-case-permission';

export default function useChartStoragePanel() {
  const { canEditGraph } = useCasePermission();
  const [isOpen, setIsOpen] = useState(false);
  const storageNodes = useAppSelector(selectStorageNodes);

  const allowDragAndDrop = canEditGraph;
  const allowAddNode = canEditGraph;
  const allowEditNode = canEditGraph;

  return {
    isOpen,
    setIsOpen,
    storageNodes,
    allowDragAndDrop,
    allowAddNode,
    allowEditNode,
  };
}

export type ChartStoragePanelState = ReturnType<typeof useChartStoragePanel>;
