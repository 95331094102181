import { styled, Typography } from '@mui/material';
import VideoThumbnail from '@assets/images/video-thumbnail.png';
import Column from '@components/layout-util-components/column';
import FlatBadge from '@components/badges/flat-badge';
import OutlinedBadge from '@components/badges/outlined-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  title: string;
  tags?: Array<string>;
  isVideo?: boolean;
  time?: string;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  backgroundColor: '#FFF9EF',
  padding: 10,
  display: 'flex',
  borderRadius: '4px',

  '.thumbnail': {
    width: 75,
    height: 'auto',
  },

  '.footer-container': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '.tag-container': {
    display: 'flex',
    gap: 10,
  },
}));

export default function TutorialCard({ title, tags, isVideo, time }: Props) {
  return (
    <Container>
      <img className="thumbnail" src={VideoThumbnail} alt="thumbnail" />
      <Gap size={15} />
      <Column>
        <Typography variant="body1">{title}</Typography>
        <Gap size={15} />
        <div className="footer-container">
          <div className="tag-container">
            {tags?.map((tag) => (
              <FlatBadge key={tag}>{tag}</FlatBadge>
            ))}
          </div>
          <OutlinedBadge>{time}</OutlinedBadge>
        </div>
      </Column>
    </Container>
  );
}
