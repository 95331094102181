import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { numberFromString } from '@util/string-util';
import { decode } from 'punycode';

export const HEADER_PAYLOAD_COOKIE = 'X-Token-Header-Payload';
export const TOKEN_SIGNATURE_COOKIE = 'X-Token-Signature';
const cookies = new Cookies();

export type CompanyRole = 'Admin' | string;

interface UserState {
  userId?: string;
  companyUserId?: number;
  companyId?: number;
  requires2FA?: boolean;
  is2FAAuthenticated?: boolean;
  phoneNumberConfirmed?: boolean;
  firstName?: string;
  lastName?: string;
  permissions?: Array<String>;
  companyRole?: CompanyRole;
  createdCompany?: boolean;
  companyStatus?: string;
  iss?: string;
}

function userStateFromCookies(): UserState {
  const value = cookies.get(HEADER_PAYLOAD_COOKIE);
  if (!value) {
    return {};
  }

  const decoded = jwtDecode(value) as any;
  return {
    userId: decoded.sub,
    companyUserId: !!decoded.companyUserId
      ? numberFromString(decoded.companyUserId)
      : undefined,
    companyId: !!decoded.companyId
      ? numberFromString(decoded.companyId)
      : undefined,
    requires2FA: decoded.requiresTwoFactorAuth === 'True',
    is2FAAuthenticated: decoded.isTwoFactorAuthenticated === 'True',
    createdCompany: decoded.createdCompany === 'True',
    phoneNumberConfirmed: decoded.phoneNumberConfirmed === 'True',
    firstName: decoded.firstName,
    lastName: decoded.lastName,
    permissions: Array.isArray(decoded.capabilities)
      ? decoded.capabilities
      : decoded.capabilities != null && typeof decoded.capabilities === 'string'
      ? JSON.parse(decoded.capabilities)
      : [],
    companyRole: decoded.companyRole,
    companyStatus: decoded.companyStatus,
    iss: decoded.iss,
  };
}

const initialState: UserState = userStateFromCookies();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    refreshUserState: () => userStateFromCookies(),
    clearUserState: () => {
      cookies.remove(HEADER_PAYLOAD_COOKIE);
      return {};
    },
  },
});

export default userSlice.reducer;

export const { refreshUserState, clearUserState } = userSlice.actions;
