import { AppDispatch, RootState } from '@store/store';
import {
  addSnapshot,
  beginSaveSnapshot,
  commitSnapshotUptoId,
  hydrateSnapshot,
  revertToLastViableSnapshot,
  setInitialSnapshot,
  SnapshotStatus,
} from '@store/rca-graph-saver/rca-graph-saver-slice';
import { selectChainId } from '@store/rca-editor/selectors';
import chainApi from '@api/endpoints/chain/chain.api';
import {
  consumeStorageChainIds,
  decrementGlobalBusyTracker,
  incrementGlobalBusyTracker,
  resetEditorToSnapshot,
} from '@store/rca-editor/rca-editor-slice';
import Semaphore from 'semaphore';
import { ApiError, isApiError } from '@api/types/api-error';
import { setAlert } from '@store/ui/ui-slice';

const sp = Semaphore(1);

const log = (message: string, ...optionalParams: any[]) => {
  console.log('[graph-saver] ' + message, ...optionalParams);
};

export const captureInitialEditorSnapshot =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { rcaEditor } = getState();
    dispatch(setInitialSnapshot(rcaEditor));
  };

export const saveGraphState =
  (asyncAction?: () => Promise<unknown>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const chainId = selectChainId(getState())!;

    const { rcaEditor: currentState } = getState();

    const anyUndefinedChainItemIds = currentState.nodes.some(
      (x) => x.data.chainItemId == null
    );
    if (anyUndefinedChainItemIds) {
      const undefinedChainItems = currentState.nodes.filter(
        (x) => x.data.chainItemId == null
      );
      log(
        'Undefined chain item ids found, skipping save, they are:',
        undefinedChainItems
      );
      console.assert(
        asyncAction == null,
        'Cannot have async action if there are undefined cause box ids'
      );
      return;
    }
    const { storageNodeIdsToConsume } = currentState;
    const canBeSkipped =
      asyncAction == null && storageNodeIdsToConsume.length === 0;

    // Capture the editor state before the async action
    dispatch(
      addSnapshot({
        ...currentState,
        canBeSkipped: canBeSkipped,
      })
    );

    log('Creating snapshot for state:', currentState);

    const snapshotId = getState().rcaEditorSnapshot.currentSnapshot!.id;

    log(`Pushing snapshot ${snapshotId}, canBeSkipped? ${canBeSkipped}`);
    return new Promise((resolve, reject) => {
      sp.take(async () => {
        log('inside semaphore');
        try {
          // If the snapshot doesn't exist, it means 1 of 2 things:
          // 1. A previous request failed and the chain will have been in a broken state and reverted.
          // 2. Another saveGraphState call was made before the previous one completed.
          const snapshot = getState().rcaEditorSnapshot.snapshots.find(
            (x) => x.id === snapshotId
          );
          if (snapshot == null) {
            log('Unable to save due to a previous graph being reverted');
            reject({
              message: 'Unable to save due to a previous graph being reverted',
            } as ApiError<never>);
            return;
          } else if (snapshot.status === SnapshotStatus.skipped) {
            log(
              `Skipping snapshot ${snapshot.id} as it was skipped due to a previous saveGraphState call`
            );
            resolve(undefined);
            return;
          }

          log('Saving graph state (calling updateGraph)', snapshot.id);
          dispatch(beginSaveSnapshot(snapshot.id));
          dispatch(incrementGlobalBusyTracker());

          if (asyncAction != null) {
            log('Running async action');
            await asyncAction();
            log('Finished async action');
          } else {
            log('No async action to run');
          }

          const filteredNodeIdsToConsume =
            snapshot.data.storageNodeIdsToConsume.filter((id) => {
              const storageNode = snapshot.data.storage.find(
                (x) => x.clientUuid === id
              );

              return storageNode?.chainItemId != null;
            });
          const nodeChainItemIds = [
            ...snapshot.data.storage
              .filter((x) => filteredNodeIdsToConsume.includes(x.clientUuid))
              .map((x) => x.chainItemId),
          ] as Array<number>;

          log(`Consuming ${filteredNodeIdsToConsume.length} storage nodes`);
          log('saving nodes', snapshot.data.nodes);

          const chainDetail = await dispatch(
            chainApi.endpoints.updateChain.initiate({
              chainId,
              nodes: snapshot.data.nodes,
              edges: snapshot.data.edges,
              moveToStorageChainItemIds: nodeChainItemIds,
            })
          ).unwrap();

          dispatch(consumeStorageChainIds(filteredNodeIdsToConsume));

          log('hydrating snapshots, detail:', chainDetail);
          dispatch(hydrateSnapshot({ snapshotId, data: chainDetail }));

          log('comitting snapshot', snapshotId);
          dispatch(commitSnapshotUptoId(snapshotId));
          log('snapshot committed', snapshotId);
          resolve(undefined);
        } catch (e) {
          if (isApiError<any>(e)) {
            const { message, errors } = e;
            let errorMessage = message;
            if (errors) {
              errorMessage += ': ' + Object.values(errors).join(', ');
            }
            dispatch(setAlert({ message: errorMessage, type: 'error' }));
          }
          log('ERROR', e);

          log('reverting to last viable snapshot');
          dispatch(revertToLastViableSnapshot());

          // Should always be a snapshot, earliest is the very first one when we loaded the chain
          const previousSnapshot =
            getState().rcaEditorSnapshot.currentSnapshot!;

          log('last viable snapshot was: ', previousSnapshot);
          dispatch(resetEditorToSnapshot(previousSnapshot.data));

          reject(e);
        } finally {
          dispatch(decrementGlobalBusyTracker());

          log('leaving semaphore');
          sp.leave();
        }
      });
    });
  };
