import { useMemo } from 'react';
import {
  DashboardLeagueTable,
  LeagueTableStat,
} from './dashboard-stat-container';
import { formatNumberToDecimals } from '@util/string-util';

interface IDashboardStatSolutionsProps {
  report: any;
  totalChainItems?: number;
  totalRcas?: number;
  titlePrefix?: string;
}

export function DashboardStatSolutions({
  report,
  totalChainItems,
  totalRcas,
  titlePrefix,
}: IDashboardStatSolutionsProps) {
  const mappedSolutionTerms = useMemo(() => {
    if (!report || !report.metaData) return [];

    const terms = ['Long', 'Medium', 'Short'];
    var colors = ['#C640A9', '#FF6C00', '#26AEB6'];
    const results: LeagueTableStat[] = [];

    for (var x = 0; x < terms.length; x++) {
      var i = report!.metaData.findIndex(
        (y) => y.solutionTermName === terms[x]
      );

      results.push({
        id: i !== -1 ? report!.metaData[i].solutionTermId! : 0,
        name: `${terms[x]} Term`,
        percentage: i !== -1 ? report!.metaData[i].coverage : 0,
        colour: colors[x],
        causeBoxes:
          i !== -1 ? Object.keys(report!.metaData[i].chainItems).length : 0,
      });
    }

    return results;
  }, [report]);

  const hasData =
    totalChainItems && totalChainItems > 0 && report.data.length > 0;

  return (
    <DashboardLeagueTable
      title={`${titlePrefix ? `${titlePrefix} ` : ''}Solutions`}
      description={
        hasData ? (
          <>
            Of the <span>{totalChainItems}</span> cause{' '}
            {totalChainItems === 1 ? 'box' : 'boxes'}{' '}
            {totalRcas === undefined ? (
              'on this chart'
            ) : (
              <>
                across <span>{totalRcas}</span> RCA{totalRcas === 1 ? '' : 's'}
              </>
            )}
            ,{' '}
            {mappedSolutionTerms.map((x, i) => {
              return (
                <>
                  <span style={{ color: x.colour }}>
                    {formatNumberToDecimals(x.percentage)}%
                  </span>{' '}
                  {i === 0 ? 'have ' : ' '}
                  {x.name}
                  {i === 0 ? ' Solutions' : ''}
                  {i < mappedSolutionTerms.length - 1 ? ', ' : ''}
                </>
              );
            })}
            .
          </>
        ) : (
          <></>
        )
      }
      tableTitle="Solution"
      emptyMessage="No solutions assigned to visual analysis"
      entries={hasData ? mappedSolutionTerms : []}
    />
  );
}
