import {
  useCanLeaveGroupQuery,
  useLeaveGroupMutation,
} from '@api/endpoints/company-group-user.api';
import { isNullOrEmpty } from '@util/string-util';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';

export default function useLeaveGroup(companyGroupId: number, name: string) {
  const { t } = useAppTr('groups');

  const { data: response, isFetching } = useCanLeaveGroupQuery({
    companyGroupId,
  });

  const [leaveGroup, { isLoading: loadingLeaveGroup }] =
    useLeaveGroupMutation();

  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const onLeaveGroup = (): Promise<boolean> => {
    return leaveGroup(companyGroupId)
      .unwrap()
      .then(() => {
        showSuccessMessage(
          t('leaveModal.toasts.success.message'),
          t('leaveModal.toasts.success.title', { name })
        );
        return true;
      })
      .catch(({ message }: ApiError<any>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    isLoading: isFetching,
    isSubmitting: loadingLeaveGroup,
    onLeaveGroup,
    canLeave:
      response != null &&
      (isNullOrEmpty(response.reason) || response.reason === 'None'),
  };
}
