import { Option } from '@api/types/option';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  onlyNumbers,
  required,
  validCurrency,
} from '@util/validators';
import { useBinaryOptions } from '@util/constant-options';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import {
  CaseImpactType,
  CaseImpactTypeMetric,
} from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { useGetMetricDetailQuery } from '@api/endpoints/case-impact-type-metric.api';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useMemo, useState } from 'react';
import useSystemText from '@hooks/use-system-text';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import useBusyAction from '@hooks/use-busy-action-hook';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import {
  useCreateCaseImpactMutation,
  useUpdateCaseImpactMutation,
} from '@api/endpoints/case.api';
import { numberFromString } from '@util/string-util';
import { UpdateCaseImpactRequest } from '@api/types/case/case-impact/update-case-impact.request';
import { invalidation } from '@api/cache-util';
import { CreateCaseImpactRequest } from '@api/types/case/case-impact/create-case-impact.request';
import { useGetCaseImpactSeverityOptionsQuery } from '@api/endpoints/case-impact-severity.api';

export default function useImpactInfo(
  caseId: number,
  initialModel?: CaseImpactResource
) {
  const [model, setModel] = useState(initialModel);
  const isEdit = model != null;

  const { systemText } = useSystemText();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const trackOptions = useBinaryOptions({ reverse: true });

  const typeOfImpactOptions: Array<Option<boolean>> = [
    { id: CaseImpactType.actual, label: 'Actual' },
    { id: CaseImpactType.potential, label: 'Potential' },
  ];

  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseImpactSeverityOptionsQuery(caseId);

  const { data: impactTypes, isLoading: loadingImpactTypes } =
    useGetCaseImpactTypesQuery(caseId);

  const metric = useField<number>([required()], model?.caseImpactTypeId);
  const chosenMetricInfo = useMemo(() => {
    return impactTypes?.find((x) => x.caseImpactTypeId === metric.value);
  }, [impactTypes, metric.value]);

  const { data: metricValueData, isFetching: loadingMetricOptions } =
    useGetMetricDetailQuery(chosenMetricInfo?.caseImpactTypeMetricId ?? -1, {
      skip: chosenMetricInfo == null,
    });

  const actualValue = useField<boolean>([required()], model?.actualValue);
  const name = useField<string>([required(), lessThan(100)], model?.name);
  const description = useField<string>([lessThan(100)], model?.description);
  const tracked = useField<boolean>(
    [required()],
    model?.tracked === undefined ? true : model?.tracked
  );
  const severity = useField<number>([required()], model?.caseImpactSeverityId);
  const metricValue = useField<string>(
    [
      required(),
      validCurrency({
        when: () =>
          metricValueData?.caseImpactTypeMetric ===
          CaseImpactTypeMetric.currency,
      }),
      onlyNumbers({
        when: () =>
          metricValueData?.caseImpactTypeMetric ===
          CaseImpactTypeMetric.numeric,
      }),
    ],
    model?.impactValue?.toString()
  );

  const [create] = useCreateCaseImpactMutation();
  const [update] = useUpdateCaseImpactMutation();

  const impactTypeOptions = useMemo(() => {
    return (
      impactTypes?.map((option) => ({
        id: option.caseImpactTypeId,
        label: option.name,
      })) ?? []
    );
  }, [impactTypes]);

  const { isValid, isDirty, resetAll, validateAll, scrollToTopMostError } =
    useFieldsWatcher([
      metric,
      actualValue,
      name,
      description,
      tracked,
      severity,
      metricValue,
    ]);

  const [submit, isBusy] = useBusyAction(async (addAnother: boolean) => {
    if (!validateAll()) {
      scrollToTopMostError();
      return false;
    }

    if (isEdit) {
      try {
        await update({
          caseId,
          caseImpactId: model!.caseImpactId,
          actualValue: actualValue.value,
          impactValue: numberFromString(metricValue.value)!,
          caseImpactSeverityId: severity.value,
          name: name.value,
          description: description.value,
          caseImpactTypeId: metric.value,
          tracked: tracked.value,
        }).unwrap();

        await Promise.all([
          invalidation('CaseImpact', model!.caseImpactId),
          invalidation('CaseTotals'),
        ]);

        showSuccessMessage('Impact updated');

        return true;
      } catch (e) {
        if (isApiError<UpdateCaseImpactRequest>(e)) {
          const { errors, message } = e;
          showErrorMessage(errors?.caseId ?? errors?.caseImpactId ?? message);

          metric.setError(errors?.caseImpactTypeId);
          actualValue.setError(errors?.actualValue);
          description.setError(errors?.description);
          tracked.setError(errors?.tracked);
          metricValue.setError(errors?.impactValue);
          severity.setError(errors?.caseImpactSeverityId);

          scrollToTopMostError();
        }
        return false;
      }
    } else {
      try {
        await create({
          caseId,
          actualValue: actualValue.value,
          impactValue: numberFromString(metricValue.value)!,
          caseImpactSeverityId: severity.value,
          name: name.value,
          description: description.value,
          caseImpactTypeId: metric.value,
          tracked: tracked.value,
        }).unwrap();

        await Promise.all([
          invalidation('CaseImpact'),
          invalidation('CaseTotals'),
        ]);

        showSuccessMessage('Impact created');

        if (addAnother) {
          setModel(undefined);
          resetAll();
        }

        return true;
      } catch (e) {
        if (isApiError<CreateCaseImpactRequest>(e)) {
          const { errors, message } = e;
          showErrorMessage(errors?.caseId ?? message);

          metric.setError(errors?.caseImpactTypeId);
          actualValue.setError(errors?.actualValue);
          description.setError(errors?.description);
          tracked.setError(errors?.tracked);
          metricValue.setError(errors?.impactValue);
          severity.setError(errors?.caseImpactSeverityId);
        }
        return false;
      }
    }
  });

  const isLoading = loadingImpactTypes || loadingSeverityOptions;

  return {
    isLoading,
    metric,
    typeOfImpact: actualValue,
    name,
    description,
    track: tracked,
    typeOfImpactOptions,
    trackOptions,
    isBusy,
    canSubmit: isValid && isDirty && !loadingMetricOptions && !isBusy,
    metricType: metricValueData?.caseImpactTypeMetric,
    impactTypeOptions,
    metricValueOptions: metricValueData?.options ?? [],
    loadingMetricOptions,
    metricValue,
    isEdit,
    submit,
    severityOptions,
    severity,
    systemText,
    currency,
  };
}

export type ImpactInfoState = ReturnType<typeof useImpactInfo>;
