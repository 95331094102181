import { Grid, Typography } from '@mui/material';
import PasswordCriteriaItem from '@pages/auth/components/password-criteria-item';
import {
  containsLowercase,
  containsNumber,
  containsSymbol,
  containsUppercase,
  greaterThan,
  Validator,
} from '@util/validators';
import { useCommonTr } from '@i18n/use-common-tr';

interface Props {
  password: string;
}

export default function PasswordCriteria({ password }: Props) {
  const { t } = useCommonTr('validation');

  const makeValidator = (validator: Validator<any>) => () => {
    if (!password) {
      return false;
    }
    return validator.validate(password) == null;
  };

  return (
    <Grid item>
      <Typography variant="body2">{t('passwordCriteria.title')}</Typography>
      <PasswordCriteriaItem
        label={t('passwordCriteria.charCount')}
        isComplete={makeValidator(greaterThan(12))}
      />
      <PasswordCriteriaItem
        label={t('passwordCriteria.uppercase')}
        isComplete={makeValidator(containsUppercase())}
      />
      <PasswordCriteriaItem
        label={t('passwordCriteria.lowercase')}
        isComplete={makeValidator(containsLowercase())}
      />
      <PasswordCriteriaItem
        label={t('passwordCriteria.symbol')}
        isComplete={makeValidator(containsSymbol())}
      />
      <PasswordCriteriaItem
        label={t('passwordCriteria.number')}
        isComplete={makeValidator(containsNumber())}
      />
    </Grid>
  );
}
