import { ReactNode } from 'react';
import { styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  icon: ReactNode;
  title: string;
  message?: string;
  action?: ReactNode;
  asRow?: boolean;
}

const Container = styled('div')<{ asRow?: boolean }>(({ asRow }) => ({
  display: 'flex',
  flexDirection: asRow ? 'row' : 'column',
  gap: asRow ? 18 : 40,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '25px 50px',
  flexGrow: '1',
  background: 'url(/svgs/overview_outcome.svg) no-repeat center right #ffffff',

  h6: {
    marginBottom: 10,
  },
  button: {
    marginLeft: 'auto',
  },
}));

export default function EmptySection({
  icon,
  title,
  message,
  action,
  asRow,
}: Props) {
  return (
    <Container asRow={asRow}>
      {icon ? icon : null}
      <div>
        <Typography variant="subtitle2">{title}</Typography>
        {message ? (
          <>
            <Gap size={20} />
            <Typography variant="body2">{message}</Typography>
          </>
        ) : null}
      </div>
      {action ? (
        <>
          <Spacer />
          {action}
        </>
      ) : null}
    </Container>
  );
}
