export namespace SolutionTypeUtil {
  export function toShortLabel(value?: string) {
    switch (value?.toLowerCase() ?? '') {
      case 'corrective':
        return 'CA';
      case 'preventative':
        return 'PA';
      default:
        return '-';
    }
  }
}
