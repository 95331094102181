import { CollaborationState } from '@pages/app/rca/create/steps/collaboration-step-hook';
import { Grid, Typography } from '@mui/material';
import BinarySelectField from '@components/input/binary-select-field';
import WCTSelectField from '@components/input/select-field';
import { Divider } from '@components/layout-util-components/divider';
import { useBinaryOptions } from '@util/constant-options';
import Column from '@components/layout-util-components/column';
import { InputSkeleton } from '@components/skeletons';
import WarningAlert from '@components/alerts/warning-alert';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CollaborationState;
}

export default function CollaborateForm({ state }: Props) {
  const { showConfirmationModal } = useUiPopup();
  const binaryOptions = useBinaryOptions({ reverse: true });

  const { groupOptions, isLoading, isPublicCase, shouldCollaborate, groups } =
    state;

  const onMaybeMakePublic = async (shouldMakePublic: boolean) => {
    if (shouldMakePublic) {
      const shouldContinue = await showConfirmationModal({
        title:
          'Are you sure you want to make this investigation visible to all users?',
        message:
          'Please note all users within your company will be able to view this RCA and request to participate.',
        noButtonLabel: 'Cancel',
        yesButtonLabel: 'Continue',
      });

      if (shouldContinue == null || !shouldContinue) {
        return;
      }
    }

    isPublicCase.set(shouldMakePublic);
  };

  return (
    <Column>
      <Typography variant="h1">Problem Analysis - Collaborate</Typography>
      <Gap size={10} />
      <Typography variant="body1" display="inline">
        You can manage the visibility of your RCA
      </Typography>
      <Gap size={50} />
      <Grid container>
        <Grid container item xs={12} direction="column">
          <Typography variant="subtitle1">
            1. Manage Sharing Settings
          </Typography>
          <Gap size={15} />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography variant="body1">Select</Typography>
          <Typography variant="body2">
            To allow all users to view this RCA choose ALL USERS alternatively
            restrict access by selecting INVITE ONLY
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <BinarySelectField
              name="isPublicCase"
              options={[
                { id: false, label: 'Invite Only' },
                { id: true, label: 'All Users' },
              ]}
              value={isPublicCase.value}
              error={isPublicCase.error}
              onChange={onMaybeMakePublic}
            />
          )}
          {!isLoading && isPublicCase.value ? (
            <>
              <Gap size={32} />
              <WarningAlert
                title="This RCA will be visible to all viewers in the platform"
                message="All users within your company will be able to view this RCA and request to participate."
              />
            </>
          ) : null}
        </Grid>
      </Grid>
      {!isPublicCase.value &&
      (isLoading || (groupOptions && groupOptions.length > 0)) ? (
        <>
          <Gap size={32} />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Share to Groups</Typography>
              <Typography variant="body2">
                To allow groups to view this RCA please add them here
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Column>
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <WCTSelectField
                    id="groups"
                    name="groups"
                    options={groupOptions!}
                    value={groups.value}
                    error={groups.error}
                    onChange={groups.set}
                    onBlur={groups.validate}
                    multiple
                  />
                )}

                {groups.value.length > 0 && !isLoading ? (
                  <>
                    <Gap size={32} />
                    <WarningAlert
                      title="Access granted to the above groups"
                      message="All group members will be able to view this RCA"
                    />
                  </>
                ) : null}
              </Column>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Divider addMargin />
      <Grid container>
        <Grid container item xs={12} direction="column">
          <Typography variant="subtitle1">2. Build your Team</Typography>
          <Gap size={15} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Are you looking to collaborate with colleagues on this RCA?
          </Typography>
          <Typography variant="body2">Select Yes to view user list</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <BinarySelectField
              name="shouldCollaborate"
              options={binaryOptions}
              value={shouldCollaborate.value}
              error={shouldCollaborate.error}
              onChange={shouldCollaborate.set}
            />
          )}
        </Grid>
      </Grid>
    </Column>
  );
}
