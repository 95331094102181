import {
  useCompanyDisableCaseImpactTypeMutation,
  useCompanyEnableCaseImpactTypeMutation,
  useGetCompanyCaseImpactTypeQuery,
} from '@api/endpoints/company/company-case-impact-type.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useEffect, useState } from 'react';
import { ReferenceDataDisableListOption } from './components/reference-data-disable-list';

export default function useCompanyCaseImpactTypes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { data: caseImpactTypes, isLoading: loadingCaseImpactTypes } =
    useGetCompanyCaseImpactTypeQuery();
  const [options, setOptions] = useState<ReferenceDataDisableListOption[]>([]);
  const [enable] = useCompanyEnableCaseImpactTypeMutation();
  const [disable] = useCompanyDisableCaseImpactTypeMutation();

  const enableCaseImpactType = (caseImpactTypeId: number) => {
    enable(caseImpactTypeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully enabled impact category');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === caseImpactTypeId
              ? { ...option, enabled: true, disabledDateTime: undefined }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const disableCaseImpactType = (caseImpactTypeId: number) => {
    disable(caseImpactTypeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully disabled impact category');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === caseImpactTypeId
              ? { ...option, enabled: false, disabledDateTime: new Date() }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  useEffect(() => {
    setOptions(
      caseImpactTypes?.map((o) => {
        return {
          id: o.caseImpactTypeId,
          label: o.name,
          enabled: !o.disabled,
          hasWarning: o.hasRCA,
          disabledDateTime: o.disabled ? new Date(o.disabled) : undefined,
        };
      }) ?? []
    );
  }, [caseImpactTypes]);

  return {
    options,
    loadingCaseImpactTypes,
    enableCaseImpactType,
    disableCaseImpactType,
  };
}
