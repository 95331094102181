import { useGetHelpCentreContentByIdQuery } from '@api/endpoints/help-centre.api';

export default function useArticleDetail(id?: string) {
  const { data: article, isLoading } = useGetHelpCentreContentByIdQuery(id!, {
    skip: id != null,
  });

  return {
    article,
    isLoading,
  };
}
