import {
  useGetHelpCentreCategoryByIdQuery,
  useGetHelpCentreContentQuery,
} from '@api/endpoints/help-centre.api';
import { HelpCentreContentType } from '@api/types/help-center/help-center-content-type';
import { useFilter } from '@components/table';

export default function useCategoryPage(id: number) {
  const search = useFilter<string>();

  const { data: category, isLoading: loadingCategory } =
    useGetHelpCentreCategoryByIdQuery(id);

  const { data: videoContent, isLoading: loadingVideoContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.video,
      searchText: search.value,
      skip: 0,
      take: 100,
      helpCentreCategoryIds: [id],
    });

  const { data: faqContent, isLoading: loadingFaqContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.faq,
      searchText: search.value,
      skip: 0,
      take: 100,
      helpCentreCategoryIds: [id],
    });

  const isLoading = loadingVideoContent || loadingFaqContent || loadingCategory;

  return {
    search,
    category,
    videoContent,
    faqContent,
    isLoading,
  };
}
