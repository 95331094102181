import { useCallback } from 'react';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useSharedState from '@store/user/user-preferences-hook';
import AppOnboardingModal from '@components/onboarding/app-onboarding-modal';
import AppOnboardingModalOwner from '@components/onboarding/app-onboarding-modal-owner';
import useUser from '@store/user/user-hook';

export default function usePostLoginActions() {
  const preferences = useSharedState();
  const { showPopup } = useUiPopup();
  const { createdCompany } = useUser();

  const showOnboarding = useCallback(async () => {
    await showPopup(
      createdCompany ? AppOnboardingModalOwner : AppOnboardingModal
    );
    preferences.set('FirstLogin', 'true');
  }, [createdCompany, preferences, showPopup]);

  return {
    showOnboarding,
  };
}
