import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useConnectionsPanel, {
  ConnectionsPanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/connections-panel-hook';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { Typography } from '@mui/material';
import { SortByFilter, WCTTableFilters } from '@components/table';
import ConnectionOption from '@pages/app/rca/tabs/components/editor-panels/components/connections-panel/connection-option';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import {
  ElipsisButton,
  FlatButton,
  OutlinedButton,
  PrimaryButton,
} from '@components/buttons';

import { ReactComponent as SelectedIcon } from '@assets/svgs/check.svg';
import { ReactComponent as ConnectionIcon } from '@assets/svgs/connection.svg';
import Row from '@components/layout-util-components/row';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';
import { faCheck, faLinkHorizontal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ConnectionsPanel() {
  const {
    toggleSelected,
    isSelected,
    selectableNodes,
    viewState,
    beginSelecting,
    search,
    sortBy,
    isFetching,
    sortByOptions,
    confirmSelection,
    cancelSelection,
    selectedNodes,
    canContinue,
    removeSelectedNode,
    isBusy,
    isLoading,
    canContribute,
  } = useConnectionsPanel();

  return (
    <Column>
      <EditPanelHeader
        title="Create Links"
        count={selectedNodes.length}
        end={
          canContribute &&
          viewState === ConnectionsPanelViewState.listSelected ? (
            <Row contentAtEnd>
              <LoadingIndicator show={isFetching || isBusy} />

              <OutlinedButton onClick={beginSelecting}>
                Edit Links
              </OutlinedButton>
            </Row>
          ) : null
        }
      />
      <Gap size={24} />
      {isLoading ? (
        <LoadingIndicator show xOffset={-68} />
      ) : viewState === ConnectionsPanelViewState.empty ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faLinkHorizontal} size="2x" />}
          title="No cross-chart links applied"
          message={
            canContribute
              ? 'Please click below to add links this cause box'
              : undefined
          }
          createMessage={canContribute ? 'Add Link' : undefined}
          onCreateClicked={canContribute ? beginSelecting : undefined}
          dark
          compact
          hideBgImage
        />
      ) : viewState === ConnectionsPanelViewState.select ? (
        <Column>
          <Typography variant="body1">
            Please select the cause boxes below that should be added as causes
            of this cause box
          </Typography>
          <Gap size={10} />
          <WCTTableFilters
            search={search.value}
            onSearch={search.set}
            rightOfSearchContent={
              <SortByFilter
                value={sortBy.value}
                onChange={sortBy.set}
                options={sortByOptions}
              />
            }
          />
          <Gap size={10} />
          {selectableNodes.map((node) => (
            <ConnectionOption
              key={node.id}
              title={node.data?.label}
              chainItemId={node.data?.chainItemId}
              isSelected={isSelected(node)}
              trailing={
                <FlatButton
                  onClick={() => toggleSelected(node)}
                  dark={isSelected(node)}
                >
                  {isSelected(node) ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faLinkHorizontal} />
                  )}
                </FlatButton>
              }
            />
          ))}

          <Gap size={12} />
          <ActionsRow>
            <OutlinedButton onClick={cancelSelection}>Cancel</OutlinedButton>
            <PrimaryButton
              onClick={confirmSelection}
              disabled={!canContinue}
              isBusy={isBusy}
            >
              Continue
            </PrimaryButton>
          </ActionsRow>
        </Column>
      ) : (
        <Column>
          {selectedNodes.map((node) => (
            <ConnectionOption
              key={node.id}
              title={node.data?.label}
              chainItemId={node.data?.chainItemId}
              trailing={
                canContribute ? (
                  <ElipsisButton
                    disabled={isBusy}
                    actions={[
                      {
                        label: 'Remove',
                        onClick: () => removeSelectedNode(node),
                      },
                    ]}
                  />
                ) : undefined
              }
            />
          ))}
        </Column>
      )}
    </Column>
  );
}
