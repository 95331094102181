import { Option } from '@api/types/option';
import { MfaMethod } from '@api/types/auth/mfa-method';
import { MfaOptionsResponse } from '@api/types/auth/mfa-options.response';
import { useMemo, useState } from 'react';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import RadioSelectGroup from '@components/input/radio-select-group';
import { useCommonTr } from '@i18n/use-common-tr';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  mfaOptions?: MfaOptionsResponse;
  onOptionSelected: (method: MfaMethod) => void;
  instantSelect?: boolean;
}

export default function MfaRadioGroup({
  mfaOptions,
  onOptionSelected,
  instantSelect,
}: Props) {
  const { t } = useCommonTr('input');

  const [method, setMethod] = useState<MfaMethod>();

  const radioOptions = useMemo((): Array<Option<MfaMethod>> => {
    if (mfaOptions == null) {
      return [];
    }

    const options: Array<Option<MfaMethod>> = [];
    if (mfaOptions.email) {
      options.push({
        id: MfaMethod.email,
        label: t('mfa.optionSelect.email'),
      });
      // Won't happen, but sms only..
    }
    if (mfaOptions.phoneNumber && !!mfaOptions.phoneNumberConfirmed) {
      options.push({
        id: MfaMethod.sms,
        label: t('mfa.optionSelect.phone', { value: mfaOptions.phoneNumber }),
      });
    }

    return options;
  }, [mfaOptions, t]);

  const onClick = (value: MfaMethod) => {
    setMethod(value);
    setTimeout(
      () => {
        onOptionSelected(value);
      },
      instantSelect ? 10 : 1000
    );
  };

  return (
    <Column>
      <Typography>{t('mfa.optionSelect.message')}</Typography>
      <Gap size={24} />
      <RadioSelectGroup
        label={t('mfa.optionSelect.label')}
        options={radioOptions}
        value={method}
        onChange={(value) => onClick(value!)}
      />
    </Column>
  );
}
