import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Categories } from './components/categories';
import { HelpCenterFAQs } from './components/help-centre-faqs';
import useFaqPage from './faq-page-hook';
import { BackToHelpCentre } from './components/back-to-help-centre';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';

interface Params {
  faq?: number;
}

export const FaqPage = () => {
  const state = useFaqPage();
  const { faq: defaultId } = useSearchParamsTyped<Params>();

  const {
    search,
    selectedCategories,
    toggleCategory,
    faqContent,
    helpCentreCategoryOptions,
  } = state;

  const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));

  return (
    <Column>
      <ContentContainer>
        <BackToHelpCentre />
        <StyledHeader>
          <Typography variant="h1">Frequently Asked Questions</Typography>
          <TextField
            value={search.value}
            onChange={(e) => search.set(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledHeader>
        <Categories
          categories={helpCentreCategoryOptions}
          onClick={toggleCategory}
          selected={selectedCategories}
        />
        {helpCentreCategoryOptions
          ?.filter(
            (c) =>
              selectedCategories.length === 0 ||
              selectedCategories.includes(c.id)
          )
          .map((c) => (
            <Box key={c.id}>
              <HelpCenterFAQs
                label={c.label}
                showAll={false}
                defaultId={defaultId}
                faqs={
                  faqContent?.filter((x) =>
                    x.categories.find((y) => y.id === c.id)
                  ) ?? []
                }
              />
            </Box>
          ))}
      </ContentContainer>
    </Column>
  );
};

export default requireAuth(FaqPage);
