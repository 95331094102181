import { Chip, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import TutorialCard from '@components/cards/tutorial-card';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';
import { useState } from 'react';
const TutorialBgImage = '/svgs/tutorial_bg.svg';

const Container = styled(Column)(({ theme }) => ({
  backgroundColor: theme.palette.blue.dark,
  background: `url(${TutorialBgImage}) no-repeat top left ${theme.palette.blue.dark}`,
  padding: 16,
  borderRadius: 12,
  '.title': {
    color: '#fff',
    fontSize: 18,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: '500',
    svg: {
      height: 22,
      marginRight: 15,
    },
  },
  '.hide': {
    height: '24px',
    background: '#fff',
    svg: {
      fontSize: '18px',
      color: 'rgba(80,87,111,62)',
    },
  },
  '.tutorial-container': {
    marginTop: 10,
    '> *': {
      maxWidth: '32%',
      background: '#fff',
      borderRadius: 8,
    },
  },
}));

export default function OverviewGetStartedContainer() {
  const [shouldHide, setShouldHide] = useState(
    () => localStorage.getItem('hide-overview-get-started') === 'true' || false
  );

  if (shouldHide) {
    return <></>;
  }

  return (
    <>
      <Container>
        <Row>
          <Typography className="title">
            <FontAwesomeIcon icon={faQuestionCircle} />
            {' How to get started'}
          </Typography>
          <Spacer />
          <Chip
            className="hide"
            label="HIDE"
            onDelete={() => {
              localStorage.setItem('hide-overview-get-started', 'true');
              setShouldHide(true);
            }}
          />
        </Row>
        <Gap size={8} />
        <Row spaceBetween className="tutorial-container">
          <TutorialCard
            title="How to setup a Root Cause Analysis investigation"
            tags={['Analysis', 'RCA']}
            time="2:00"
            isVideo
          />
          <TutorialCard
            title="How to setup a Root Cause Analysis investigation"
            tags={['Analysis', 'RCA']}
            time="2:00"
            isVideo
          />
          <TutorialCard
            title="How to setup a Root Cause Analysis investigation"
            tags={['Analysis', 'RCA']}
            time="2:00"
            isVideo
          />
        </Row>
      </Container>

      <Gap size={32} />
    </>
  );
}
