import { styled } from '@mui/material';
import FilledBadge from '@components/badges/filled-badge';
import Row from '@components/layout-util-components/row';
import IdColorIndicator from '@pages/app/rca/tabs/reports/components/id-color-indicator';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { useAppSelector } from '@store/store';
import { v4 as uuid } from 'uuid';
import {
  ReportCell,
  ReportCellType,
  ReportRow,
  RunReportResponse,
} from '@api/types/case/case-report/run-report.response';
import { Fragment, useMemo } from 'react';
import { CoverageReportMetaData } from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import { ReportUtil } from '@util/report-util';
import { numberFromString } from '@util/string-util';
import {
  HeaderItem,
  useSortableHeader,
  WCTTableHeaders,
} from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { Gap } from '@components/layout-util-components/gap';
import WCTSelectField from '@components/input/select-field';
import Spacer from '@components/layout-util-components/spacer';
import { ReportDataSplit } from '@hooks/report/use-report-data-split-hook';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { formatCurrency } from '@util/currency-util';
import getSymbolFromCurrency from 'currency-symbol-map';

interface Props {
  headers: string[];
  splitData: ReportDataSplit;
  immutableData: RunReportResponse<CoverageReportMetaData>;
  selectedRow?: number;
  onSelect?: (index: number) => void;
}

const Table = styled('table')<{ isShrunk: boolean }>(({ isShrunk }) => ({
  borderCollapse: 'collapse',
  border: '1px solid #D8DBE3',
  borderSpacing: 0,
  th: {
    borderBottom: '1px solid #D8DBE3',
    background: '#F8F8FA',
    padding: 12,
    margin: 0,
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  td: {
    backgroundColor: 'white',
    padding: 12,
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    borderTop: '1px solid #D8DBE3',

    ...(isShrunk && {
      borderTop: 'none',

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },

      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    }),
  },
}));

export default function CoverageLeagueTable({
  headers,
  splitData,
  selectedRow,
  onSelect,
  immutableData,
}: Props) {
  const sort = useSortableHeader({
    allowNoSort: false,
  });
  const isShrunk = useAppSelector(shouldShrinkReportView);
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const sortedNonNegativeIdRows = useMemo(() => {
    const sortIdx = numberFromString(sort.value?.id);
    if (sortIdx == null) return splitData.nonNegativeIdRows;
    return [...splitData.nonNegativeIdRows].sort((a, b) => {
      const aVal = a.cells[sortIdx].value;
      const bVal = b.cells[sortIdx].value;

      // remove the % (if it exists) sign from the string and convert to number
      const aNum = numberFromString(aVal);
      const bNum = numberFromString(bVal);

      if (aNum != null && bNum != null) {
        return sort.direction === SortDirection.asc ? aNum - bNum : bNum - aNum;
      }

      return sort.direction === SortDirection.asc
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  }, [splitData.nonNegativeIdRows, sort.direction, sort.value?.id]);

  const displayToggle = onSelect != null;

  const renderCell = (cell: ReportCell, row: ReportRow) => {
    switch (cell.type) {
      case ReportCellType.empty:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 32 }}>
            <Row>
              {displayToggle && (
                <>
                  <input
                    type="radio"
                    checked={row.clientGeneratedId === selectedRow}
                    onClick={() => onSelect(row.clientGeneratedId)}
                  />
                  <Gap size={10} />
                </>
              )}
            </Row>
          </td>
        );
      case ReportCellType.identifier:
        return (
          <td>
            <Row>
              <IdColorIndicator
                id={row.clientGeneratedId}
                colour={row.colour}
              />
              <Gap size={10} />
              <span>{cell.value}</span>
            </Row>
          </td>
        );
      case ReportCellType.badge:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 200 }}>
            <FilledBadge>{cell.value}</FilledBadge>
          </td>
        );
      case ReportCellType.percent:
        const value = numberFromString(cell.value);
        return (
          <td style={{ width: 165 }}>
            <span>{value?.toFixed(1)}%</span>
          </td>
        );
      case ReportCellType.currency:
        return (
          <td style={{ width: 165 }}>
            <span>
              {getSymbolFromCurrency(currency)}
              {formatCurrency(cell.value, currency)}
            </span>
          </td>
        );
      case ReportCellType.string:
      default:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 165 }}>
            <span>{cell.value}</span>
          </td>
        );
    }
  };

  const renderDropDownOption = (id: number) => {
    const row = splitData.allRows.find((x) => x.clientGeneratedId === id);
    if (row == null) {
      return undefined;
    }

    const percentage = splitData.percentages[id];
    return (
      <Row key={id} sx={{ width: '100%' }}>
        <IdColorIndicator id={row.clientGeneratedId} colour={row.colour} />
        <Gap size={10} />
        <span>{ReportUtil.getRowName(row) ?? ''}</span>
        {percentage != null && (
          <>
            <Spacer />
            <span>({percentage.toFixed(1)}%)</span>
            <Gap size={8} />
          </>
        )}
      </Row>
    );
  };

  if (isShrunk && onSelect != null) {
    return (
      <WCTSelectField
        id="choice"
        value={selectedRow}
        label="Selected row"
        required
        options={[...sortedNonNegativeIdRows, ...splitData.negativeIdRows].map(
          (row) => ({
            id: row.clientGeneratedId,
            label: ReportUtil.getRowName(row) ?? '',
          })
        )}
        onChange={(v) => onSelect(v as number)}
        renderValue={(v) => renderDropDownOption(v as number)}
        renderOption={(option) => renderDropDownOption(option.id as number)}
        autoReadonly={false}
      />
    );
  }

  return (
    <>
      <Table isShrunk={isShrunk}>
        {!isShrunk && (
          <WCTTableHeaders
            items={headers.map(
              (value, index): HeaderItem => ({
                title: value,
                ...(index > 0 && {
                  sortProperty: `${index}`,
                  ...sort,
                }),
              })
            )}
          />
        )}
        <tbody>
          {sortedNonNegativeIdRows.map((row) => (
            <tr
              key={row.clientGeneratedId}
              style={{
                display:
                  isShrunk && row.clientGeneratedId !== selectedRow
                    ? 'none'
                    : undefined,
              }}
            >
              {row.cells.map((cell) => (
                <Fragment key={uuid()}>{renderCell(cell, row)}</Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {splitData.negativeIdRows.length > 0 && (
        <>
          {sortedNonNegativeIdRows.length > 0 && <Gap size={15} />}
          <Table isShrunk={isShrunk}>
            <tbody>
              {splitData.negativeIdRows.map((row) => (
                <tr
                  key={row.clientGeneratedId}
                  style={{
                    display:
                      isShrunk && row.clientGeneratedId !== selectedRow
                        ? 'none'
                        : undefined,
                  }}
                >
                  {row.cells.map((cell) => (
                    <Fragment key={uuid()}>{renderCell(cell, row)}</Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
