import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export enum RcaGatingStatus {
  ok,
  offline,
  tabExpired,
}

function getConnectionStatus() {
  return typeof navigator !== 'undefined' ? navigator.onLine : true;
}

export default function useRcaGating() {
  const [isConnected, setIsConnected] = useState(getConnectionStatus);
  const [hasValidTabId, setHasValidTabId] = useState(true);

  const [tabId] = useState(() => {
    const id = uuid();
    localStorage.setItem('tabId', id);
    return id;
  });

  useEffect(() => {
    function handleOnline() {
      setIsConnected(true);
    }

    function handleOffline() {
      setIsConnected(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    // List for when a tab is refocused
    function handleFocus() {
      const currentTabId = localStorage.getItem('tabId');
      if (currentTabId !== tabId) {
        setHasValidTabId(false);
      }
    }

    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [tabId]);

  return useMemo(() => {
    if (!hasValidTabId) {
      return RcaGatingStatus.tabExpired;
    }

    if (!isConnected) {
      return RcaGatingStatus.offline;
    }

    return RcaGatingStatus.ok;
  }, [hasValidTabId, isConnected]);
}
