import {
  MaybeDrafted,
  PatchCollection,
} from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { store } from '@store/store';
import { cloneDeep } from 'lodash';
import { ApiTagType } from '@api/api';

// 2ms
export const SHORT_CACHE_DURATION = 2;

type BaseQueryCallback = {
  api: any;
  endpoint: any;
};
type UpdateQueryCallbackData<T> = BaseQueryCallback & {
  callback: (d: MaybeDrafted<T>) => void;
};

type CreateCacheQueryCallbackData<T> = BaseQueryCallback & {
  callback: (d: T) => void;
  argParser?: (args: any) => boolean;
};

export function maybeCreateCacheItem(
  parsers: Array<CreateCacheQueryCallbackData<any>>
) {
  const patches: Array<PatchCollection> = [];
  const { queries: storedQueries } = store.getState().api;
  for (const parser of parsers) {
    const queries = Object.values(storedQueries).filter(
      (x) =>
        x != null &&
        x.endpointName === parser.endpoint.name &&
        (parser.argParser == null ||
          x.originalArgs == null ||
          parser.argParser(x.originalArgs))
    );

    for (const query of queries) {
      if (query == null) {
        return;
      }
      patches.push(
        store.dispatch(
          parser.api.util.upsertQueryData(
            query.endpointName,
            query.originalArgs as any,
            (function () {
              const clone = cloneDeep(query.data);
              parser.callback(clone);
              return clone;
            })()
          )
        )
      );
    }
  }

  return patches;
}

export function maybeUpdateCache(
  invalidateKey: ApiTagType,
  forId: any | null,
  parsers: Array<UpdateQueryCallbackData<any>>
): Array<PatchCollection> {
  const patches: Array<PatchCollection> = [];
  const { queries: storedQueries, provided } = store.getState().api;

  let cachedDataNames =
    forId != null
      ? provided?.[invalidateKey as any]?.[forId as any]
      : provided?.[invalidateKey as any];

  if (cachedDataNames == null || cachedDataNames.length === 0) {
    return patches;
  }

  if (typeof cachedDataNames === 'object') {
    cachedDataNames = Object.values<string>(cachedDataNames).reduce<string[]>(
      (acc, val) => acc.concat(val),
      []
    );
  }

  for (const name of cachedDataNames) {
    const query = storedQueries[name];
    if (query == null) {
      continue;
    }

    const parser = parsers.find((x) => x.endpoint.name === query.endpointName);
    if (parser == null) {
      continue;
    }

    patches.push(
      store.dispatch(
        parser.api.util.updateQueryData(
          query.endpointName,
          query.originalArgs as any,
          parser.callback
        )
      )
    );
  }

  return patches;
}

export async function invalidation(invalidateKey: ApiTagType, forId?: any) {
  const { provided, queries: initialQueries } = store.getState().api;
  const cachedDataNames =
    forId != null
      ? provided?.[invalidateKey as any]?.[forId as any]
      : provided?.[invalidateKey as any];

  if (cachedDataNames == null || cachedDataNames.length === 0) {
    return;
  }

  const values: string[][] = Object.values(cachedDataNames);
  const allCachedDataNames = values.reduce((acc, val) => acc.concat(val), []);

  const promises: Array<Promise<void>> = [];
  for (const name of allCachedDataNames) {
    const query = initialQueries[name];
    if (query == null || query.status !== 'pending') {
      continue;
    }

    promises.push(
      new Promise((resolve) => {
        const id = setInterval(() => {
          const query = store.getState().api.queries[name];
          if (query == null || query.status !== 'pending') {
            clearInterval(id);
            resolve();
            return;
          }
        }, 500);
      })
    );
  }

  return await Promise.all(promises);
}
