import { Alert, styled, Typography } from '@mui/material';
import { StyledAlertHeader } from '@components/alerts/styled';
import { AlertProps } from '@components/alerts/alert-props';
import useScrollToOnMount from '@hooks/use-scroll-to-on-mount';

const StyledSuccessAlert = styled(Alert)(({ theme: { palette } }) => ({
  backgroundColor: palette.success.light,
  color: palette.common.textDark,
}));

export default function SuccessAlert({ title, message, onClose }: AlertProps) {
  useScrollToOnMount('success-alert');

  return (
    <StyledSuccessAlert id="success-alert" severity="success" onClose={onClose}>
      {title ? <StyledAlertHeader>{title}</StyledAlertHeader> : null}
      <Typography variant="body2">{message}</Typography>
    </StyledSuccessAlert>
  );
}
