import { Box, Chip, styled, Typography } from '@mui/material';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface Props {
  title: string;
  message: string;
  modifiedTime?: Date;
  onClick: () => void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 20,
  marginTop: 20,
  padding: '15px 20px',
  backgroundColor: theme.palette.common.white,
  border: '1px solid rgba(0,0,0,0.25)',
  borderRadius: 6,
  cursor: 'pointer',
  transition: 'transform ease-in-out 300ms',
  svg: {
    color: '#00223A',
    transition: 'transform ease-in-out 300ms',
  },
  '&:hover': {
    border: '2px solid #00223A',
    padding: '14px 19px',
    svg: {
      color: '#00223A',
    },
  },
}));

export default function SettingsOptionContainer({
  title,
  message,
  modifiedTime,
  onClick,
}: Props) {
  return (
    <Container onClick={() => onClick()}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" sx={{ marginBottom: '8px' }}>
          {title}
        </Typography>
        <Typography variant="body2">{message}</Typography>
      </Box>
      {modifiedTime && (
        <Chip
          icon={<FontAwesomeIcon icon={faPen} />}
          label={`Edited ${dateOnlyShortFormatter(modifiedTime)}`}
          sx={{
            padding: '0 10px',
          }}
        />
      )}
      <FontAwesomeIcon icon={faChevronRight} />
    </Container>
  );
}
