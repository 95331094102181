import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from '@store/store';
import { hardResetRcaToInitialState } from '@store/rca-editor/rca-editor-slice';
import { hardResetSnapshotState } from '@store/rca-graph-saver/rca-graph-saver-slice';
import { EditorPanels } from '@pages/app/rca/tabs/components/rca-editor-scaffold';

export default function RcaResetter() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(hardResetSnapshotState());
      dispatch(hardResetRcaToInitialState());
    };
  }, [dispatch]);

  return (
    <>
      <Outlet />
      <EditorPanels />
    </>
  );
}
