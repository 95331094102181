import ColorBadge from '@components/badges/color-badge';
import {
  getColorForId,
  getLightenedColorForId,
} from '@util/colour-identifiers';
import Row from '@components/layout-util-components/row';
import { Circle } from '@mui/icons-material';

interface Props {
  colourId: number;
  name: string;
}

export default function SmallThemeBadge({ colourId, name }: Props) {
  return (
    <ColorBadge
      color={getLightenedColorForId(colourId)}
      textColor={getColorForId(colourId)}
      small
    >
      <Row gap={6}>
        <Circle
          sx={{
            fontSize: 8,
            color: getColorForId(colourId),
          }}
        />
        <span>{name}</span>
      </Row>
    </ColorBadge>
  );
}
