import { api } from '@api/api';
import { Address } from '@components/input/address-field';

const postcodeLookupApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchPostcode: build.query<Address[], string>({
      query: (query) => ({
        url: 'postcodeLookup',
        method: 'GET',
        params: {
          postcode: query,
        },
      }),
    }),
  }),
});

export const { useLazySearchPostcodeQuery } = postcodeLookupApi;
