import {
  useGetHelpCentreCategoryOptionsQuery,
  useGetHelpCentreContentQuery,
} from '@api/endpoints/help-centre.api';
import { HelpCentreContentType } from '@api/types/help-center/help-center-content-type';
import { useFilter } from '@components/table';
import { useState } from 'react';

export default function useArticlePage() {
  const search = useFilter<string>();
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const { data: articleContent, isLoading: loadingArticleContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.article,
      searchText: search.value,
      helpCentreCategoryIds: selectedCategories,
      skip: 0,
      take: 100,
    });

  const {
    data: helpCentreCategoryOptions,
    isLoading: loadingHelpCentreCategoryOptions,
  } = useGetHelpCentreCategoryOptionsQuery(false);

  const isLoading = loadingArticleContent || loadingHelpCentreCategoryOptions;

  const toggleCategory = (id: number) => {
    const isSelected = selectedCategories.includes(id);

    if (isSelected) {
      const updatedCategories = selectedCategories.filter(
        (categoryId) => categoryId !== id
      );
      setSelectedCategories(updatedCategories);
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  return {
    search,
    selectedCategories,
    articleContent,
    helpCentreCategoryOptions,
    isLoading,
    toggleCategory,
  };
}
