import { Dayjs } from 'dayjs';
import { selectTimezone } from '@store/locale/locale-selector';
import { store } from '@store/store';
import { i18nDayJs } from '@i18n/index';

export const getDate = (value: any): Dayjs | null => {
  let date: Date | null = null;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'string') {
    date = new Date(value);
  }

  if (date != null) {
    const timezone = selectTimezone(store.getState());
    return i18nDayJs(date).tz(timezone);
  }

  return null;
};

export const getNowUtc = () => {
  return i18nDayJs().utc();
};
