import { styled } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  onClick: () => void;
  icon: ReactNode;
  isSelected: boolean;
}

const StyledButton = styled('button')<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: 45,
    height: 45,
    borderRadius: 4,
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: 18,
    letterSpacing: '0.05em',
    backgroundColor: 'palette.success.main',
    color: 'white',
    '&>svg': {
      fill: 'white',
    },
    ...(isSelected && {
      cursor: 'default',
      color: 'black',
      backgroundColor: 'white',
      '&>svg': {
        fill: 'black',
      },
    }),
  })
);
export default function SideMenuButton({ isSelected, icon, onClick }: Props) {
  return (
    <StyledButton type="button" isSelected={isSelected} onClick={onClick}>
      {icon}
    </StyledButton>
  );
}
