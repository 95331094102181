import { styled } from '@mui/material';

interface Props {
  filled?: boolean;
  maxWidth?: number;
  padding?: string;
}

const ContentContainer = styled('div')<Props>(
  ({ theme, filled, maxWidth, padding }) => ({
    display: 'flex',
    padding: '50px 0',
    margin: '0 20px',
    '@media (min-width:768px)': {
      margin: '0 80px',
    },
    '@media (min-width:1500px)': {
      margin: '0 100px',
    },
    '@media (min-width:1650px)': {
      margin: '0 auto',
      width: '100%',
      maxWidth: '1400px',
    },
    maxWidth,
    flexDirection: 'column',
    alignItems: 'stretch',
    ...(filled && {
      backgroundColor: theme.palette.common.grey40,
    }),
  })
);

export default ContentContainer;
