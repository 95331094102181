import {
  useGetWAAllCompaniesQuery,
  useGetWAAllCompanyStatsQuery,
} from '@api/endpoints/wct-admin/wct-admin-company.api';
import { useMemo } from 'react';
import { useFilter, usePagination, useSortableHeader } from '@components/table';
import { useGetAllWAPackageOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-package.api';
import { useGetAllWASubscriptionStatusOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-subscription-status.api';
import { useGetAllWAInstallNameOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-install.api';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';

export default function useWACompaniesIndexPage() {
  const { data: packageOptions, isLoading: loadingPackageOptions } =
    useGetAllWAPackageOptionsQuery();

  const { data: subscriptionOptions, isLoading: loadingSubscriptionOptions } =
    useGetAllWASubscriptionStatusOptionsQuery();

  const { data: installOptions, isLoading: loadingInstallOptions } =
    useGetAllWAInstallNameOptionsQuery();

  const { data: companyStats, isLoading: loadingCompanyStats } =
    useGetWAAllCompanyStatsQuery();

  const search = useFilter<string>();
  const packageId = useFilter<number>();
  const managerUserId = useFilter<string>();
  const statusId = useFilter<number>();
  const installId = useFilter<number>();
  const tagIds = useFilter<Array<number>>(useMemo(() => [], []));
  const pagination = usePagination(10);
  const sort = useSortableHeader({
    allowNoSort: false,
    initialProperty: 'companyName',
    initialDirection: SortDirection.asc,
  });

  const sortByOptions: Array<SortOption> = [
    {
      id: 'companyName',
      label: 'Company Name (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'companyName',
      label: 'Company Name (Desc)',
      direction: SortDirection.desc,
    },
    {
      id: 'created',
      label: 'Created (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'created',
      label: 'Created (Desc)',
      direction: SortDirection.desc,
    },
    {
      id: 'rcaCount',
      label: 'RCA Count (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'rcaCount',
      label: 'RCA Count (Desc)',
      direction: SortDirection.desc,
    },
    {
      id: 'subscriptionName',
      label: 'Subscription (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'subscriptionName',
      label: 'Subscription (Desc)',
      direction: SortDirection.desc,
    },

    {
      id: 'companyStatusName',
      label: 'Status (Asc)',
      direction: SortDirection.asc,
    },
    {
      id: 'companyStatusName',
      label: 'Status (Desc)',
      direction: SortDirection.desc,
    },
  ];

  const {
    data: companies,
    isLoading: loadingCompanies,
    isFetching,
  } = useGetWAAllCompaniesQuery({
    searchText: search.value,
    installId: installId.value,
    managerUserId: managerUserId.value,
    packageId: packageId.value,
    statusId: statusId.value,
    tagIds: tagIds.value,
    skip: pagination.skip,
    take: pagination.take,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const isLoading =
    loadingCompanyStats ||
    loadingCompanies ||
    loadingPackageOptions ||
    loadingSubscriptionOptions ||
    loadingInstallOptions;

  return {
    companyStats,
    companies,
    isLoading,
    isFetching,
    search,
    packageId,
    managerUserId,
    statusId,
    installId,
    tagIds,
    pagination,
    packageOptions,
    subscriptionOptions,
    installOptions,
    sort,
    sortByOptions,
  };
}
