import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import { ReactNode } from 'react';
import { Gap } from '@components/layout-util-components/gap';
import useField from '@hooks/use-field-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { useCreateChildThemeMutation } from '@api/endpoints/company/company-theme.api';
import { ApiError } from '@api/types/api-error';

export type RenderOtherActions = (
  props: Pick<CreateChildThemeModalProps, 'onClose'>
) => ReactNode;

export interface CreateChildThemeModalProps extends ModalProps<boolean> {
  parentId: number;
  parentName: string;
  type: string;
}

const CreateChildThemeModal = ({
  onClose,
  parentId,
  parentName,
  type,
}: CreateChildThemeModalProps) => {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const name = useField<string>([]);

  const [createChildThemeMutation] = useCreateChildThemeMutation();

  const createChildTheme = (parentId: number, name: string) => {
    createChildThemeMutation({
      parentId,
      name,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully added theme');
        onClose();
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const { isValid, isDirty, validateAll } = useFieldsWatcher([name]);

  const submit = () => {
    if (!validateAll()) {
      return false;
    }

    createChildTheme(parentId, name.value);
  };

  return (
    <Column>
      <>
        <Typography variant="h6">
          {`Create sub ${type} for ${parentName}`}
        </Typography>
        <Gap size={20} />
        <WCTTextField
          label="Name"
          name={`${type}Name`}
          value={name.value}
          error={name.error}
          onChange={name.set}
          required
        />
        <Gap size={8} />
        <ActionsRow>
          <OutlinedButton onClick={() => onClose(false)}>Cancel</OutlinedButton>
          <PrimaryButton disabled={!isDirty && !isValid} onClick={submit}>
            Create {type}
          </PrimaryButton>
        </ActionsRow>
      </>
    </Column>
  );
};

export default withModal(CreateChildThemeModal);
