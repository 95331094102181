import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAP_KEY } from '@util/env';

type Marker = google.maps.Marker;
type Map = google.maps.Map;

interface LatLng {
  lat?: number;
  lng?: number;
}
interface Props {
  value: LatLng;
  onChange: (value?: LatLng) => void;
}

export default function LatLngSelectorMap({ value, onChange }: Props) {
  const [mapId] = useState(uuid());
  const [defaultLatLng] = useState(() => ({
    lat: value.lat ?? 51.5072,
    lng: value.lng ?? 0.1276,
  }));

  const loadedRef = useRef<boolean>(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();
  const markerRef = useRef<Marker>();

  const setMarker = useCallback((value: LatLng) => {
    if (markerRef.current != null) {
      markerRef.current!.setMap(null);
    }

    markerRef.current = new google.maps.Marker({
      map: mapRef.current!,
      position: value as any,
    });
  }, []);

  const loadMap = useCallback(async () => {
    if (loadedRef.current) {
      return;
    }

    const loader = new Loader({
      apiKey: GOOGLE_MAP_KEY,
      version: 'weekly',
    });

    try {
      await Promise.all([
        loader.importLibrary('maps'),
        loader.importLibrary('marker'),
      ]);

      const map = (mapRef.current = new google.maps.Map(
        mapContainerRef.current as HTMLElement,
        {
          center: defaultLatLng,
          zoom: 8,
        }
      ));

      if (defaultLatLng.lat != null && defaultLatLng.lng != null) {
        setMarker(defaultLatLng);
      }

      map.addListener('click', (e) => {
        const latLng: LatLng = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };

        setMarker(latLng);
        onChange(latLng);
      });

      loadedRef.current = true;
    } catch (error) {
      console.error(error);
    }
  }, [defaultLatLng, onChange, setMarker]);

  useEffect(() => {
    loadMap();
  }, [loadMap]);

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      ref={mapContainerRef}
      id={mapId}
    />
  );
}
