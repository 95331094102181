import useSolutionsListView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-list-view-hook';
import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import Column from '@components/layout-util-components/column';
import ChainItemSolutionItem from '@components/cards/chain-item-solution-item';
import { ElipsisButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import { useCasePermission } from '@hooks/case/use-case-permission';

interface Props {
  state: SolutionsPanelState;
}
export default function SolutionsListView({ state }: Props) {
  const { editSolution, onDelete } = state;
  const { solutions, currency } = useSolutionsListView(state);
  const { canContribute } = useCasePermission();

  return (
    <Column>
      {solutions.map((solution) => (
        <>
          <ChainItemSolutionItem
            key={solution.solutionId}
            solution={solution}
            currency={currency}
            trailing={
              canContribute ? (
                <ElipsisButton
                  actions={[
                    {
                      label: 'Edit Solution',
                      onClick: () => editSolution(solution),
                    },
                    {
                      label: 'Unlink Solution',
                      onClick: () => onDelete(solution),
                    },
                  ]}
                />
              ) : undefined
            }
          />
          <Gap size={15} />
        </>
      ))}
    </Column>
  );
}
