import {
  ColorId,
  getColorForId,
  getLightenedColorForId,
} from '@util/colour-identifiers';
import { ReportUtil } from '@util/report-util';

export enum ReportCellType {
  empty = 'Empty',
  identifier = 'Identifier',
  badge = 'Badge',
  percent = 'Percent',
  string = 'String',
  currency = 'Currency',
}

export interface ReportCell {
  type: ReportCellType;
  value: string;
}

export interface ReportRow {
  clientGeneratedId: number;
  colourId: ColorId;
  colour: string;
  colourLight: string;
  cells: ReportCell[];
}

interface ReportRequest {
  reportTypeId?: number;
  reportSubTypeId?: number;
  reportRankById?: number;
  ids: number[];
  caseImpactTypeId?: number;
  name?: string;
}

export class RunReportResponse<MetaDataType, TotalsType = any> {
  columns!: string[];
  data!: ReportRow[];
  metaData!: MetaDataType[];
  totals!: TotalsType;
  request!: ReportRequest;

  static fromJson<MetaDataType, TotalsType = never>(
    json: any
  ): RunReportResponse<MetaDataType, TotalsType> {
    let negativeId = -1;
    let currentId = 1;

    const totalNonDefaultEntryCount = json.data.filter(
      (row) =>
        !ReportUtil.isNotSetData(row) || ReportUtil.isNotRequiredData(row)
    ).length;
    let currentCount = totalNonDefaultEntryCount;

    return {
      columns: json.columns,
      data: json.data.map((row: ReportCell[], index: number) => {
        const metaData = json.metaData?.[index];

        const fraction =
          totalNonDefaultEntryCount > 0
            ? currentCount-- / totalNonDefaultEntryCount
            : 1.0;
        const opacityStrength = 0.4 + fraction * 0.6;

        let clientId = currentId++;
        if (ReportUtil.isNotSetData(row) || ReportUtil.isNotRequiredData(row)) {
          clientId = negativeId--;
          currentId--;
          currentCount++;
        }

        let colourId: ColorId = clientId;
        let increaseOpacity = false;
        if (clientId >= 0 && metaData != null) {
          if ('themeColourId' in metaData) {
            colourId = metaData.themeColourId;
          } else if ('solutionId' in metaData) {
            colourId = 'solution';
            increaseOpacity = true;
          } else if ('evidenceId' in metaData) {
            colourId = 'evidence';
            increaseOpacity = true;
          }
        }

        let colour = getColorForId(colourId);
        let colourLight = getLightenedColorForId(colourId);
        if (increaseOpacity) {
          colour = `${colour}${Math.round(opacityStrength * 255)
            .toString(16)
            .padStart(2, '0')}`;
        }

        return {
          clientGeneratedId: clientId,
          colourId: colourId,
          colour,
          colourLight,
          cells: row.map((cell) => {
            return {
              type: cell.type,
              value: cell.value,
            };
          }),
        };
      }),
      metaData: json.metaData,
      totals: json.totals,
      request: json.request,
    };
  }
}
