import { api } from '@api/api';
import { Option } from '@api/types/option';

const taskTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTaskTypeOptions: build.query<Option[], void>({
      query: () => ({
        url: 'taskType/options',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTaskTypeOptionsQuery } = taskTypeApi;
