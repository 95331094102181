import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useNotesPanel from '@pages/app/rca/tabs/components/editor-panels/notes-panel-hook';
import { Box } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import Spacer from '@components/layout-util-components/spacer';
import NoteInput from '@pages/app/rca/tabs/components/editor-panels/components/notes/note-input';
import { Divider } from '@components/layout-util-components/divider';
import { PageAlertDisplay } from '@components/alerts';
import NoteRow from '@pages/app/rca/tabs/components/editor-panels/components/notes/note-row';
import { ChainItemNoteResource } from '@api/types/chain/chain-item-notes/chain-item-note.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { Gap } from '@components/layout-util-components/gap';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-light-svg-icons';

export default function NotesPanel() {
  const { showConfirmationModal } = useUiPopup();
  const state = useNotesPanel();
  const {
    notes,
    isLoading,
    isFetching,
    onDelete,
    beginEditingNote,
    canContribute,
  } = state;

  const beginDelete = (note: ChainItemNoteResource) => {
    return showConfirmationModal({
      title: 'Delete Note',
      message: `Are you sure you would like to delete this note?\n\n"${note.note}"`,
      yesButtonAsyncAction: () => onDelete(note),
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box overflow="auto">
        <Column>
          <EditPanelHeader
            title="Notes"
            count={notes?.length}
            end={<FetchingIndicator show={isFetching} />}
          />
          <Gap size={24} />
          {isLoading ? (
            <LoadingIndicator show xOffset={-68} />
          ) : notes!.length === 0 ? (
            <OnboardingEntryContainer
              icon={<FontAwesomeIcon icon={faComment} size="2x" />}
              title="No notes have been added to this cause box"
              message={canContribute ? 'Please add any notes below' : undefined}
              dark
              compact
              hideBgImage
            />
          ) : (
            notes!.map((note) => (
              <NoteRow
                key={note.chainItemNoteId}
                note={note}
                onDelete={() => beginDelete(note)}
                onEdit={() => beginEditingNote(note)}
              />
            ))
          )}
        </Column>
      </Box>
      <Spacer />
      <Divider addMargin compact negativeMargin={-24} />
      <PageAlertDisplay ignoreSuccess />
      {canContribute && <NoteInput state={state} />}
    </Box>
  );
}
