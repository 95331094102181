import { Skeleton, styled, Typography } from '@mui/material';

interface Props {
  noText?: boolean;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

export default function SwitchFieldSkeleton({ noText }: Props) {
  return (
    <Container>
      <Skeleton variant="rounded" height={25} width={38} />
      {!noText ? (
        <Typography variant="body1">
          <Skeleton width={140} />
        </Typography>
      ) : null}
    </Container>
  );
}
