import useLoadMore from '@hooks/use-load-more-hook';
import { useGetUserGroupsQuery } from '@api/endpoints/company-group.api';
import { useFilter, useSortableHeader } from '@components/table';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { SortDirection } from '@api/types/sort-direction';
import { useAppTr } from '@i18n/use-app-tr';
import { Permission } from '@api/types/permission';
import { usePermissions } from '@store/user/permission-hook';

export default function useMyGroups() {
  const { t } = useAppTr('groups');
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const search = useFilter<string>();
  const sort = useSortableHeader({ initialProperty: 'groupName' });
  const canCreateGroups = usePermissions([
    Permission.createMyGroups,
    Permission.manageCompanyGroups,
  ]);

  const { data: groups, isLoading: loadingGroups } = useGetUserGroupsQuery({
    skip,
    take,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const isLoading = loadingGroups;

  const sortByOptions: Array<SortOption> = [
    {
      id: 'groupName',
      label: t('admin.sort.nameAsc'),
      direction: SortDirection.asc,
    },
    {
      id: 'groupName',
      label: t('admin.sort.nameDesc'),
      direction: SortDirection.desc,
    },
  ];

  return {
    isLoading,
    groups,
    loadMore,
    showLoadMoreButton: canLoadMore(groups?.totalCount ?? 0),
    search,
    sort,
    sortByOptions,
    hasNoGroups: groups && groups!.totalCount === 0,
    hasNoFilteredGroups:
      groups && groups!.totalCount > 0 && groups!.count === 0,
    hasFilteredGroups: groups && groups!.totalCount > 0 && groups!.count > 0,
    canCreateGroups,
  };
}
