import {
  useGetHelpCentreCategoryOptionsQuery,
  useGetHelpCentreContentQuery,
  useGetHelpCentreSearchQuery,
} from '@api/endpoints/help-centre.api';
import { HelpCentreContentType } from '@api/types/help-center/help-center-content-type';
import { useFilter } from '@components/table';
import { useState } from 'react';

export default function useHelpCentre(isRca = false) {
  const search = useFilter<string>();
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const { data: videoContent, isLoading: loadingVideoContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.video,
      searchText: search.value,
      helpCentreCategoryIds: selectedCategories,
      isRca: isRca,
      featured: true,
      skip: 0,
      take: 10,
    });

  const { data: faqContent, isLoading: loadingFaqContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.faq,
      searchText: search.value,
      helpCentreCategoryIds: selectedCategories,
      isRca: isRca,
      featured: true,
      skip: 0,
      take: 10,
    });

  const { data: articleContent, isLoading: loadingArticleContent } =
    useGetHelpCentreContentQuery({
      contentType: HelpCentreContentType.faq,
      searchText: search.value,
      helpCentreCategoryIds: selectedCategories,
      isRca: isRca,
      featured: true,
      skip: 0,
      take: 10,
    });

  const {
    data: helpCentreCategoryOptions,
    isLoading: loadingHelpCentreCategoryOptions,
  } = useGetHelpCentreCategoryOptionsQuery(isRca);

  const { data: searchResult, isLoading: isLoadingSearch } =
    useGetHelpCentreSearchQuery({
      searchText: search.value,
      isRca: isRca,
      skip: 0,
      take: 50,
    });

  const isLoading =
    loadingFaqContent ||
    loadingVideoContent ||
    loadingArticleContent ||
    loadingHelpCentreCategoryOptions;

  const toggleCategory = (id: number) => {
    const isSelected = selectedCategories.includes(id);

    if (isSelected) {
      const updatedCategories = selectedCategories.filter(
        (categoryId) => categoryId !== id
      );
      setSelectedCategories(updatedCategories);
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  return {
    search,
    searchResult,
    videoContent,
    faqContent,
    articleContent,
    helpCentreCategoryOptions,
    selectedCategories,
    toggleCategory,
    isLoading,
    isLoadingSearch,
  };
}
