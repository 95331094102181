import { api } from '@api/api';
import { CaseResource } from '@api/types/case/case.resource';
import { UpsertCaseOverviewRequest } from '@api/types/case/upsert-case-overview.request';
import { makeError } from '@api/types/api-error';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { CreateCaseImpactRequest } from '@api/types/case/case-impact/create-case-impact.request';
import { UpdateCaseImpactRequest } from '@api/types/case/case-impact/update-case-impact.request';
import { Option } from '@api/types/option';
import { PaginatedResult } from '@api/types/paginated-result';
import { PaginatedFilters } from '@api/types/paginated-filters';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import { InviteCaseUserRequest } from '@api/types/case/case-collaboration/invite-case-user.request';
import { UpdateInvitedUserCaseRoleRequest } from '@api/types/case/case-collaboration/update-invited-user-case-role.request';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { CasePaginatedFilterRequest } from '@api/types/case/case-paginated-filter.request';
import { CaseStatsCounts } from '@api/types/case/case-stats-counts';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { CreateCompanyLocationRequest } from '@api/types/company/company-location/create-company-location.request';
import { CaseTotalsResponse } from '@api/types/case/case-totals.response';
import { CaseEvidenceRequest } from '@api/types/case/case-evidence/case-evidence.request';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { CreateCaseEvidenceRequest } from '@api/types/case/case-evidence/create-case-evidence.reqest';
import { CreateCaseSolutionRequest } from '@api/types/case/case-solution/create-case-solution.request';
import { UpdateSyncGroupsRequest } from '@api/types/case/case-collaboration/update-sync-groups.request';
import { CaseOverviewTotalsResponse } from '@api/types/case/case-overview-totals.response';
import { CloseCaseRequest } from '@api/types/case/close-case.request';
import { CaseSolutionFilters } from '@api/types/case/case-solution/case-solution-filters';
import { CaseSolutionResource } from '@api/types/case/case-solution/case-solution.resource';
import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';
import { UpdateCaseSolutionRequest } from '@api/types/case/case-solution/update-case-solution.request';
import { CaseUserFilterRequest } from '@api/types/case/case-user-filter.request';
import { maybeUpdateCache, SHORT_CACHE_DURATION } from '@api/cache-util';

const caseApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseDetail: build.query<CaseResource, number>({
      query: (caseId) => ({
        url: `case/${caseId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response ? [{ id: response.caseId, type: 'Case' as const }] : [],
    }),
    deleteCase: build.mutation<void, number>({
      query: (caseId) => ({
        url: `case/${caseId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<{ caseId: number }>(error),
      invalidatesTags: (_, error, id) =>
        !error ? [{ id, type: 'Case' as const }] : [],
    }),
    getAllCaseData: build.query<
      PaginatedResult<CaseItemRowResponse, CaseStatsCounts>,
      CasePaginatedFilterRequest
    >({
      query: (params) => ({
        url: 'case',
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((item) => ({
                id: item.caseId,
                type: 'Case' as const,
              })),
              'Case',
            ]
          : [],
    }),
    getAllCaseDataForGroup: build.query<
      PaginatedResult<CaseItemRowResponse, CaseStatsCounts>,
      CasePaginatedFilterRequest
    >({
      query: (params) => ({
        url: `case/companyGroup/${params.companyGroupId!}`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((item) => ({
                id: item.caseId,
                type: 'Case' as const,
              })),
              'Case',
            ]
          : [],
    }),
    getCaseOverviewTotals: build.query<CaseOverviewTotalsResponse, number>({
      query: (caseId) => ({
        url: `case/${caseId}/caseOverviewTotals`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response, _, id) =>
        response ? [{ id, type: 'Case' as const }, 'CaseTotals'] : [],
    }),
    upsertCaseOverview: build.mutation<CaseResource, UpsertCaseOverviewRequest>(
      {
        query: ({ caseId, ...body }) => ({
          url: caseId != null ? `case/${caseId}` : 'case',
          method: caseId != null ? 'PUT' : 'POST',
          body,
        }),
        transformErrorResponse: (error) =>
          makeError<UpsertCaseOverviewRequest>(error),
        invalidatesTags: (_, error, { caseId }) =>
          !error
            ? caseId != null
              ? [{ id: caseId, type: 'Case' as const }, 'CaseTotals']
              : ['Case']
            : [],
      }
    ),
    completeCase: build.mutation<void, number>({
      query: (caseId) => ({
        url: `case/${caseId}/complete`,
        method: 'POST',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, id) =>
        !error ? [{ id, type: 'Case' as const }, 'CaseTotals'] : [],
    }),
    getCaseImpacts: build.query<
      Array<CaseImpactResource>,
      { caseId: number; tracked?: boolean }
    >({
      query: ({ caseId, tracked }) => ({
        url: `case/${caseId}/caseImpact`,
        method: 'GET',
        params:
          tracked !== undefined
            ? {
                tracked: tracked,
              }
            : undefined,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.map((caseImpact) => ({
                id: caseImpact.caseImpactId,
                type: 'CaseImpact' as const,
              })),
              'CaseImpact',
            ]
          : [],
    }),
    createIncidentLocation: build.mutation<
      CompanyLocationResource,
      CreateCompanyLocationRequest
    >({
      query: (body) => ({
        url: 'case/incidentLocation',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCompanyLocationRequest>(error),
      invalidatesTags: (response) =>
        response
          ? [
              {
                id: response.companyLocationId,
                type: 'CompanyLocation' as const,
              },
              'CompanyLocation',
            ]
          : [],
    }),
    createCaseImpact: build.mutation<
      CaseImpactResource,
      CreateCaseImpactRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/caseImpact`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCaseImpactRequest>(error),
      invalidatesTags: (_, error) =>
        !error ? ['CaseImpact', 'CaseTotals'] : [],
    }),
    updateCaseImpact: build.mutation<
      CaseImpactResource,
      UpdateCaseImpactRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/caseImpact`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateCaseImpactRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { id: arg.caseImpactId, type: 'CaseImpact' as const },
              'CaseTotals',
            ]
          : [],
    }),
    deleteCaseImpact: build.mutation<
      void,
      { caseId: number; caseImpactId: number }
    >({
      query: ({ caseId, caseImpactId }) => ({
        url: `case/${caseId}/caseImpact/${caseImpactId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { id: arg.caseImpactId, type: 'CaseImpact' as const },
              'CaseTotals',
            ]
          : [],
    }),
    getGroupOptionsForCase: build.query<Option[], number>({
      query: (caseId) => ({
        url: `case/${caseId}/companyGroup/options`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response, _, caseId) =>
        response
          ? [
              ...response.map((group) => ({
                id: group.id,
                type: 'CaseGroup' as const,
              })),
              { id: caseId, type: 'Case' as const },
              'CaseGroup',
            ]
          : [],
    }),
    updateSyncedGroupsForCase: build.mutation<
      void,
      UpdateSyncGroupsRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/collab`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateSyncGroupsRequest>(error),
      invalidatesTags: (_, error, { caseId }) =>
        !error ? [{ id: caseId, type: 'Case' as const }, 'CaseGroup'] : [],
    }),
    getUsersForCase: build.query<
      PaginatedResult<CaseUserResource>,
      PaginatedFilters & { caseId: number; invitedOnly?: boolean }
    >({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/companyUser`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map((user) => ({
                id: user.companyUserId,
                type: 'CaseUser' as const,
              })),
              'CaseUser',
            ]
          : [],
    }),
    getCaseDetailsForMe: build.query<CaseUserResource, number>({
      query: (caseId) => ({
        url: `case/${caseId}/companyUser/me`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response, _, caseId) =>
        response
          ? [
              { id: response.companyUserCaseId, type: 'CaseUser' as const },
              {
                id: caseId,
                type: 'Case' as const,
              },
            ]
          : [],
    }),
    inviteUserToCase: build.mutation<
      CaseUserResource,
      InviteCaseUserRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/companyUser`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<InviteCaseUserRequest>(error),
      invalidatesTags: (response) =>
        response
          ? [
              { id: response.companyUserCaseId, type: 'CaseUser' as const },
              'CaseUser',
            ]
          : [],
    }),
    updateUserCaseRole: build.mutation<
      CaseUserResource,
      UpdateInvitedUserCaseRoleRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/companyUser`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateInvitedUserCaseRoleRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg.companyUserCaseId, type: 'CaseUser' as const }]
          : [],
      async onQueryStarted(arg, { queryFulfilled }) {
        const patches = maybeUpdateCache('CaseUser', undefined, [
          {
            api: caseApi,
            endpoint: caseApi.endpoints.getUsersForCase,
            callback: (d: PaginatedResult<CaseUserResource>) => {
              d.model.forEach((x) => {
                if (x.companyUserCaseId === arg.companyUserCaseId) {
                  x.caseRoleId = arg.caseRoleId;
                  x.caseRoleName = arg.caseRoleName;
                  x.caseRole = arg.caseRole;
                }
              });
            },
          },
          {
            api: caseApi,
            endpoint: caseApi.endpoints.getCaseDetailsForMe,
            callback: (user: CaseUserResource) => {
              if (user.companyUserCaseId === arg.companyUserCaseId) {
                user.caseRoleId = arg.caseRoleId;
                user.caseRoleName = arg.caseRoleName;
                user.caseRole = arg.caseRole;
              }
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
    removeUserFromCase: build.mutation<
      void,
      { companyUserCaseId: number; caseId: number }
    >({
      query: ({ caseId, companyUserCaseId }) => ({
        url: `case/${caseId}/companyUser/${companyUserCaseId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, { companyUserCaseId, caseId }) =>
        !error
          ? [
              { id: companyUserCaseId, type: 'CaseUser' },
              { id: caseId, type: 'Case' },
              'CaseUser',
            ]
          : [],
    }),
    getCaseTotals: build.query<CaseTotalsResponse, number>({
      query: (caseId) => ({
        url: `case/${caseId}/caseTotals`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: ['CaseTotals'],
    }),
    getCaseTotalsForChainItem: build.query<
      CaseTotalsResponse,
      { caseId: number; chainItemId: number }
    >({
      query: ({ caseId, chainItemId }) => ({
        url: `case/${caseId}/caseTotals/${chainItemId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (_, error, arg) =>
        !error
          ? [{ id: arg.chainItemId, type: 'CaseTotals' as const }, 'CaseTotals']
          : [],
    }),
    getCaseEvidenceDetail: build.query<
      CaseEvidenceResource,
      { caseId: number; evidenceId: number }
    >({
      query: ({ caseId, evidenceId }) => ({
        url: `case/${caseId}/evidence/${evidenceId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [{ id: response.evidenceId, type: 'Evidence' as const }]
          : [],
    }),
    getCaseEvidence: build.query<
      PaginatedResult<CaseEvidenceResource>,
      CaseEvidenceRequest
    >({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/evidence`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map((evidence) => ({
                id: evidence.evidenceId,
                type: 'Evidence' as const,
              })),
              'Evidence',
            ]
          : [],
    }),
    createCaseEvidence: build.mutation<void, CreateCaseEvidenceRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/evidence`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ['Evidence', 'CaseTotals'] : []),
      transformErrorResponse: (error) =>
        makeError<CreateCaseEvidenceRequest>(error),
    }),
    updateCaseEvidence: build.mutation<
      void,
      CreateCaseEvidenceRequest & { evidenceId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/evidence`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg.evidenceId, type: 'Evidence' }, 'CaseTotals'] : [],
      transformErrorResponse: (error) =>
        makeError<CreateCaseEvidenceRequest>(error),
    }),
    removeCaseEvidence: build.mutation<
      void,
      { evidenceId: number; caseId: number }
    >({
      query: ({ caseId, evidenceId }) => ({
        url: `case/${caseId}/evidence/${evidenceId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg.evidenceId, type: 'Evidence' }, 'CaseTotals'] : [],
    }),
    getCaseSolutions: build.query<
      PaginatedResult<CaseSolutionItemResponse>,
      CaseSolutionFilters
    >({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/solution`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map((solution) => ({
                id: solution.solutionId,
                type: 'Solution' as const,
              })),
              'Solution',
            ]
          : [],
    }),
    getCaseSolutionById: build.query<
      CaseSolutionResource,
      { caseId: number; solutionId: number }
    >({
      query: ({ caseId, solutionId }) => ({
        url: `case/${caseId}/solution/${solutionId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (_, __, { solutionId }) => [
        { id: solutionId, type: 'Solution' as const },
      ],
    }),
    createCaseSolution: build.mutation<
      CaseSolutionItemResponse,
      CreateCaseSolutionRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/solution`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCaseSolutionRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['Solution', 'CaseTotals'] : []),
    }),
    updateCaseSolution: build.mutation<void, UpdateCaseSolutionRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/solution`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg.solutionId, type: 'Solution' as const }, 'CaseTotals']
          : [],
      transformErrorResponse: (error) =>
        makeError<UpdateCaseSolutionRequest>(error),
    }),
    removeCaseSolution: build.mutation<
      CaseSolutionItemResponse,
      { caseId: number; solutionId: number }
    >({
      query: ({ caseId, solutionId }) => ({
        url: `case/${caseId}/solution/${solutionId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCaseSolutionRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg.solutionId, type: 'Solution' as const }, 'CaseTotals']
          : [],
    }),
    closeCase: build.mutation<void, CloseCaseRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/closeCase`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError<CloseCaseRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg.caseId, type: 'Case' as const }, 'Case', 'CaseTotals']
          : [],
    }),
    openCase: build.mutation<void, number>({
      query: (caseId) => ({
        url: `case/${caseId}/openCase`,
        method: 'PUT',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ id: arg, type: 'Case' as const }, 'Case', 'CaseTotals']
          : [],
    }),
    getCompanyUserOptions: build.query<Option[], CaseUserFilterRequest>({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/companyUser/options`,
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.map((opt) => ({
                id: opt.id,
                type: 'CompanyUser' as const,
              })),
              'CompanyUser',
            ]
          : [],
    }),
  }),
});

export const {
  useGetCaseOverviewTotalsQuery,
  useGetAllCaseDataQuery,
  useGetAllCaseDataForGroupQuery,
  useUpsertCaseOverviewMutation,
  useGetCaseImpactsQuery,
  useCreateCaseImpactMutation,
  useUpdateCaseImpactMutation,
  useDeleteCaseImpactMutation,
  useGetGroupOptionsForCaseQuery,
  useGetUsersForCaseQuery,
  useInviteUserToCaseMutation,
  useUpdateUserCaseRoleMutation,
  useRemoveUserFromCaseMutation,
  useCompleteCaseMutation,
  useGetCaseDetailQuery,
  useCreateIncidentLocationMutation,
  useGetCaseTotalsQuery,
  useGetCaseTotalsForChainItemQuery,
  useCreateCaseEvidenceMutation,
  useDeleteCaseMutation,
  useGetCaseEvidenceQuery,
  useUpdateCaseEvidenceMutation,
  useRemoveCaseEvidenceMutation,
  useGetCaseSolutionsQuery,
  useCreateCaseSolutionMutation,
  useUpdateSyncedGroupsForCaseMutation,
  useRemoveCaseSolutionMutation,
  useUpdateCaseSolutionMutation,
  useGetCaseSolutionByIdQuery,
  useCloseCaseMutation,
  useOpenCaseMutation,
  useLazyGetCaseSolutionByIdQuery,
  useGetCaseDetailsForMeQuery,
  useGetCompanyUserOptionsQuery,
} = caseApi;

export default caseApi;
