import { WCTTable } from '@components/table';
import RcaTableRow from '@pages/app/analysis/components/rca-table-row';
import { AnalysisOverviewState } from '@pages/app/analysis/analysis-overview-hook';

interface Props {
  isLoading: boolean;
}

export default function AnalysisTableView({
  isLoading,
  sort,
  pagination,
  caseData,
}: Pick<AnalysisOverviewState, 'sort' | 'pagination' | 'caseData'> & Props) {
  const data = caseData!.model!;

  return (
    <>
      <WCTTable
        count={5}
        pagination={pagination}
        isLoading={isLoading}
        headers={[
          { title: <></> },
          { title: 'Name' },
          { title: 'Created', sortProperty: 'created', ...sort },
          { title: 'Incident Location' },
          { title: 'Severity' },
          { title: 'Health' },
          { title: 'Team' },
          { title: 'Status' },
          { title: <></> },
        ]}
      >
        {data.map((data) => (
          <RcaTableRow key={data.caseId} data={data} />
        ))}
      </WCTTable>
    </>
  );
}
