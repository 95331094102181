import { api } from '@api/api';
import { Option } from '@api/types/option';

const subscriptionStatusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionStatementOptions: build.query<Option[], void>({
      query: () => ({
        url: 'admin/subscriptionstatus/options',
      }),
    }),
  }),
});

export const { useGetSubscriptionStatementOptionsQuery } =
  subscriptionStatusApi;
