import { Box, Drawer, DrawerProps, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { DrawerHeader } from '@components/ui-popup/drawers';
import HealthScoreBar from '@pages/app/rca/tabs/components/health-score/health-score-bar';
import { Divider } from '@components/layout-util-components/divider';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { DropDownFilter } from '@components/table';
import HealthScoreNodeItem from '@pages/app/rca/tabs/components/health-score/health-score-node-item';
import useHealthScoreSidePanel from '@pages/app/rca/tabs/components/health-score/health-score-side-panel-hook';
import { v4 as uuid } from 'uuid';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import { LinkButton } from '@components/buttons';
import { useEffect } from 'react';
import { Gap } from '@components/layout-util-components/gap';
import { useAppDispatch } from '@store/store';
import {
  closeReport,
  setOpenLensesSlideOut,
} from '@store/reports/reports-slice';
import useMaybeSnapToFocusedNode from '@pages/app/rca/tabs/hooks/use-maybe-snap-to-focused-node-hook';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  topMargin: number;
}

const StyledDrawer = styled(Drawer, { target: 'health-score-side-panel' })<
  DrawerProps & Pick<Props, 'topMargin'>
>(({ topMargin, theme: { palette } }) => ({
  '.MuiPaper-root': {
    top: topMargin,
    height: `calc(100vh - ${topMargin}px)`,
    width: 450,
    boxShadow: '0px 0px 15px 0px #00000040',
    background: palette.common.white,
    padding: 20,
    overflowY: 'auto',
    zIndex: 1000,
  },
}));

export default function HealthScoreSidePanel(props: Props) {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, topMargin } = props;
  const {
    highlightNodes,
    unhighlightNodes,
    healthScore,
    actions,
    isLoading,
    canLoadMore,
    loadMore,
    actionTypeOptions,
    actionType,
    displayRemainingActionsTitle,
  } = useHealthScoreSidePanel();

  useMaybeSnapToFocusedNode(isOpen);

  useEffect(() => {
    if (isOpen) {
      highlightNodes();
      dispatch(setOpenLensesSlideOut(false));
      dispatch(closeReport());
    }
    return () => unhighlightNodes();
  }, [isOpen, highlightNodes, unhighlightNodes, dispatch]);

  return (
    <StyledDrawer
      open={isOpen}
      onClose={onClose}
      topMargin={topMargin}
      anchor="right"
      hideBackdrop
      variant="persistent"
    >
      <Column>
        <DrawerHeader onClose={onClose}>Health Score</DrawerHeader>
        <Gap size={10} />
        <Typography variant="body2">
          Address the actions below to Improve your Health Score
        </Typography>
        <Gap size={15} />
        <HealthScoreBar score={healthScore ?? 0} />
        <Divider addMargin compact />
        <Row>
          {isLoading ? (
            <InlineBoxSkeleton />
          ) : (
            <Typography variant="body2">
              {displayRemainingActionsTitle
                ? `${actions!.count}/${actions!.totalCount} outstanding actions`
                : `${actions!.totalCount} outstanding actions`}
            </Typography>
          )}
          <Spacer />
          {isLoading ? (
            <ButtonSkeleton />
          ) : (
            <DropDownFilter
              options={actionTypeOptions!}
              value={actionType.value}
              onChange={actionType.set}
              multiple
            />
          )}
        </Row>
        <Gap size={20} />
        {!isLoading
          ? actions!.model.map((action) => (
              <HealthScoreNodeItem key={uuid()} data={action} />
            ))
          : null}

        {canLoadMore ? (
          <Box alignSelf="center">
            <Gap size={20} />
            <LinkButton onClick={loadMore}>Load More</LinkButton>
          </Box>
        ) : null}
      </Column>
    </StyledDrawer>
  );
}
