import {
  useGetKudosHistoryQuery,
  useGetUserProfileQuery,
} from '@api/endpoints/user.api';
import { useAppSelector } from '@store/store';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';

export default function useKudosPage(userId?: number) {
  const canFetch = useAppSelector(selectIsLoggedInAndFullyAuthenticated);

  const {
    data: profile,
    isLoading: loadingProfile,
    isFetching: fetchingProfile,
  } = useGetUserProfileQuery(undefined, {
    skip: !canFetch,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: kudos,
    isLoading: loadingKudos,
    isFetching: fetchingHistory,
  } = useGetKudosHistoryQuery(undefined, { skip: !canFetch });

  const isFetching = fetchingProfile || fetchingHistory;
  const isLoading = loadingKudos || loadingProfile;

  return {
    kudos,
    profile,
    isLoading,
    isFetching,
  };
}
