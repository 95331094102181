import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import {
  useGetChainItemCaseImpactsQuery,
  useUpdateChainItemCaseImpactsMutation,
} from '@api/endpoints/chain/chain-item-case-impact.api';
import {
  useGetCaseDetailQuery,
  useGetCaseImpactsQuery,
} from '@api/endpoints/case.api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isApiError } from '@api/types/api-error';
import {
  CaseImpactUpdateItem,
  UpdateChainItemCaseImpactRequest,
} from '@api/types/chain/chain-item-case-impact/update-chain-item-case-impact.request';
import { usePageAlertVariants } from '@components/alerts';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useGetChainItemQuery } from '@api/endpoints/chain/chain-item.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

interface ImpactValueHolder {
  [id: number]: number | null;
}

export enum ImpactsPanelView {
  initial,
  createImpact,
}

export default function useImpactsPanel() {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const { canContribute } = useCasePermission();
  const [view, setView] = useState(
    defaultToCreate ? ImpactsPanelView.createImpact : ImpactsPanelView.initial
  );
  const [highlightMissingImpacts, setHighlightMissingImpacts] = useState(false);
  const [currentValues, setCurrentValues] = useState<ImpactValueHolder>({});

  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const chainId = useAppSelector(selectChainId)!;
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;

  const [updateImpacts] = useUpdateChainItemCaseImpactsMutation();

  const { data: chainItemDetail, isLoading: loadingChainItemDetail } =
    useGetChainItemQuery(
      { chainId, chainItemId: chainItemId ?? -1 },
      { skip: chainItemId == null }
    );

  const { data: caseImpacts, isLoading: loadingCaseImpacts } =
    useGetCaseImpactsQuery({ caseId, tracked: true });

  const { data, isFetching: isFetchingCaseDetail } = useGetCaseDetailQuery(
    caseId != null ? +caseId : 0,
    {
      skip: caseId == null,
    }
  );

  const {
    data: impacts,
    isLoading: loadingImpacts,
    isFetching,
  } = useGetChainItemCaseImpactsQuery(
    { chainId: chainId ?? -1, chainItemId: chainItemId ?? -1 },
    { skip: chainId == null || chainItemId == null }
  );

  const isLoading =
    chainItemDetail == null ||
    loadingChainItemDetail ||
    loadingImpacts ||
    loadingCaseImpacts ||
    isFetchingCaseDetail;

  const resetImpactValues = useCallback(() => {
    if (!caseImpacts || !impacts) {
      return;
    }

    let newHolder = {};

    for (let x = 0; x < caseImpacts.length; x++) {
      newHolder[caseImpacts[x].caseImpactId] = impacts.find(
        (y) => y.caseImpactId === caseImpacts[x].caseImpactId
      )?.impactValue;
    }

    setCurrentValues(newHolder);
  }, [caseImpacts, impacts]);

  useEffect(() => {
    resetImpactValues();
  }, [resetImpactValues]);

  const actualImpacts = useMemo(() => {
    if (caseImpacts == null) {
      return [];
    }

    return caseImpacts.filter((x) => x.actualValue);
  }, [caseImpacts]);

  const potentialImpacts = useMemo(() => {
    if (caseImpacts == null) {
      return [];
    }

    return caseImpacts.filter((x) => !x.actualValue);
  }, [caseImpacts]);

  const onBeginAddingImpacts = () => {
    setView(ImpactsPanelView.createImpact);
  };

  const onCancelAddingImpacts = () => {
    setView(ImpactsPanelView.initial);
  };

  const [submit, isSubmitting] = useBusyAction(
    async (caseImpacts: Array<CaseImpactUpdateItem>) => {
      try {
        await updateImpacts({
          chainId: chainId!,
          chainItemId: chainItemId!,
          caseImpacts,
        }).unwrap();

        await Promise.all([
          invalidation('ChainItemCaseImpact', chainItemId!),
          invalidation('CaseTotals'),
        ]);

        if (caseImpacts.length === 0) {
          showSuccessMessage(
            'You have successfully updated impacts for a Cause Box'
          );
        } else {
          showSuccessMessage(
            'You have successfully added impacts to a Cause Box'
          );
        }

        return true;
      } catch (error) {
        if (isApiError<UpdateChainItemCaseImpactRequest>(error)) {
          const { message, errors } = error;
          showErrorMessage(
            errors?.chainId ??
              errors?.chainItemId ??
              errors?.caseImpacts ??
              message
          );
        }

        return false;
      }
    }
  );

  const onSubmitValues = async (): Promise<boolean> => {
    setHighlightMissingImpacts(false);

    const ci =
      caseImpacts
        ?.filter((x) => currentValues[x.caseImpactId] != null)
        .map((x) => {
          return {
            caseImpactId: x.caseImpactId,
            impactValue: currentValues[x.caseImpactId] as number,
          };
        }) ?? [];

    const didSubmit = await submit(ci);
    if (didSubmit) {
      setView(ImpactsPanelView.initial);
    }
    return true;
  };

  const updateValue = (impactId: number, value?: number) => {
    setCurrentValues((v) => ({ ...v, [impactId]: value ?? null }));
  };

  return {
    caseImpacts,
    actualImpacts,
    potentialImpacts,
    impacts,
    isLoading,
    onBeginAddingImpacts,
    onCancelAddingImpacts,
    view,
    isFetching,
    isSubmitting,
    updateValue,
    onSubmitValues,
    currentValues,
    canContribute,
    impactNa: chainItemDetail?.impactNa,
    resetImpactValues,
    caseId,
    caseDetail: data,
    highlightMissingImpacts,
    hasNoImpacts: actualImpacts.length === 0 && potentialImpacts.length === 0,
  };
}

export type ImpactsPanelState = ReturnType<typeof useImpactsPanel>;
