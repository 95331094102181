import { RootState } from '@store/store';
import { createSelector } from '@reduxjs/toolkit';
import { ReportType } from '@api/types/report-type/report-type.option';

export enum ReportView {
  coverage,
  unknown,
}

const selectReportsState = (state: RootState) => state.reports;

export const selectRunReportId = (state: RootState) => state.reports.showId;

export const selectRunReportModel = (state: RootState) => state.reports.model;

export const selectRunReportData = (state: RootState) => state.reports.data;

export const selectSelectedRankRow = (state: RootState) =>
  state.reports.selectedRankRow;

export const shouldShrinkReportView = (state: RootState) =>
  state.reports.shrinkView;

export const selectIsLensesSlideOutOpen = (state: RootState) =>
  state.reports.openLensesSlideOut;

export const selectIsReportsOpen = createSelector(
  [selectIsLensesSlideOutOpen, selectReportsState, selectRunReportModel],
  (isLensesSlideOutOpen, reports, model) => {
    return !isLensesSlideOutOpen && (reports.showId != null || model != null);
  }
);

export const selectIsReportRunning = createSelector(
  [selectIsReportsOpen, selectReportsState],
  (isOpen, reports) => {
    return isOpen && reports.data == null;
  }
);

export const selectBestReportView = createSelector(
  [selectRunReportModel],
  (model) => {
    if (model == null) {
      return ReportView.unknown;
    }

    // Coverage and impacts use the same view
    switch (model.reportType) {
      case ReportType.coverage:
      case ReportType.impact:
      case ReportType.dominant:
        return ReportView.coverage;
    }
  }
);
