import { useEffect } from 'react';
import { selectFocusedNodeNullable } from '@store/rca-editor/selectors';
import { store } from '@store/store';
import { RcaUtil } from '@util/rca-util';

export default function useMaybeSnapToFocusedNode(isOpen: boolean) {
  useEffect(() => {
    function maybeSnapToFocusedNode() {
      const focusedNode = selectFocusedNodeNullable(store.getState());
      if (focusedNode != null) {
        RcaUtil.snapFocusToNode(focusedNode);
      }
    }
    if (isOpen) {
      maybeSnapToFocusedNode();
      return () => {
        maybeSnapToFocusedNode();
      };
    }
  }, [isOpen]);
}
