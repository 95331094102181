import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsRight } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onElipsisClick?: () => void;
}

export const FURTHER_ANALYSIS_COLOR = '#929494';
export const FURTHER_ANALYSIS_BORDER_COLOR = '#929494';

const Container = styled('div')((theme) => ({
  zIndex: 100,
  position: 'absolute',
  top: '50%',
  right: 5,
  transform: 'translate(50%, -50%)',
  borderRadius: 72,
  backgroundColor: FURTHER_ANALYSIS_COLOR,
  color: 'white',

  margin: 0,
  marginLeft: 21,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',

  padding: 6,
  width: 42,
  height: 42,

  '& > svg': {
    fontSize: '1.35em',
  },

  '& > button': {
    width: '100%',
    height: '100%',
    backgroundColor: '#767F82',
    color: 'white',
    border: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: '#4B5153',
      color: 'white',
      border: 'none',
    },
  },
}));

export default forwardRef<HTMLElement, Props>(function (
  { onElipsisClick },
  buttonRef
) {
  return (
    <Container>
      <FontAwesomeIcon
        ref={buttonRef as any}
        icon={faChevronsRight}
        onClick={onElipsisClick}
      />
    </Container>
  );
});
