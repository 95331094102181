import {
  Autocomplete,
  Box,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import { useNavigate } from 'react-router-dom';
import { HelpCentreContentType } from '@api/types/help-center/help-center-content-type';
import { IHelpCentreSearch } from '@api/types/help-center/help-center-search';
import { ContentTypeIcon } from './../components/content-type-icon';
import { Filter } from '@components/table/hooks/use-filter-hook';

const StylesAutocomplete = styled(Autocomplete)(({ theme }) => ({
  minWidth: 220,
  '& .MuiInputBase-root': {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(4),
    boxShadow: '4px 0 20px rgba(0,0,0,0.05)',
  },
  '&>*': {
    '&>input': {
      padding: 0,
    },
    '&>.MuiInputAdornment-root': {
      marginTop: '0 !important',
    },
  },
}));

const StyledNoResultsText = styled(Typography)(({ theme }) => ({
  color: '#626D73',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '150%' /* 21px */,
  letterSpacing: '0.15px',
}));

interface Props {
  search: Filter<string>;
  searchResult?: IHelpCentreSearch[];
  onOptionTapped?: () => void;
}

export const SearchBar = ({ search, searchResult, onOptionTapped }: Props) => {
  const navigate = useNavigate();

  return (
    <StylesAutocomplete
      noOptionsText={
        <StyledNoResultsText>
          There are no results that match your search criteria.
          <br />
          <br />
          Please update your search term above or browse our help categories
          below to find what you are looking for.
        </StyledNoResultsText>
      }
      disablePortal
      options={searchResult ?? []}
      // 'as any' because MUI creator does not like TS. (not a joke, he wrote about not supporting it)
      onChange={(_, value) => {
        onOptionTapped?.();

        const option = value as IHelpCentreSearch;

        if (option.contentType === HelpCentreContentType.faq) {
          navigate(`/help-centre/faq?faq=${option.helpCentreContentId}`);
          return;
        }

        if (option.contentType === HelpCentreContentType.video) {
          navigate(`/help-centre/video?video=${option.helpCentreContentId}`);
          return;
        }

        if (option.contentType === HelpCentreContentType.article) {
          navigate(`/help-centre/article/${option.helpCentreContentId}`);
          return;
        }

        navigate(`/help-centre/category/${option.helpCentreContentId}`);
      }}
      getOptionLabel={(option) => (option as any).title}
      renderOption={(props, o) => {
        const option = o as IHelpCentreSearch;
        return (
          <Box
            component="li"
            sx={{ '& > svg': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <ContentTypeIcon contentType={option.contentType} />
            {option?.title}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={search.value}
          onChange={(e) => search.set(e.target.value)}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
