import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useEvidencePanel, {
  EvidencePanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import AddEvidenceOptionView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/add-evidence-option-view';
import UpsertEvidenceFormView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/upsert-evidence-form-view';
import { OutlinedButton } from '@components/buttons';
import EvidenceListView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/evidence-list-view';
import { Gap } from '@components/layout-util-components/gap';
import ChainItemOnboardingContainer from '@components/containers/chain-item-onboarding-container';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

export default function EvidencePanel() {
  const state = useEvidencePanel();
  const {
    evidence,
    isLoading,
    viewState,
    startCreateFlow,
    edittingEvidenceDetail,
    canContribute,
    evidenceNa,
    isFetching,
  } = state;

  return (
    <Column>
      <EditPanelHeader
        title="Evidence Assigned to this Cause"
        count={evidence?.totalCount}
        end={
          <Row>
            <FetchingIndicator show={isFetching} />
          </Row>
        }
      />
      <Gap size={20} />
      {canContribute && viewState === EvidencePanelViewState.view ? (
        <>
          <OutlinedButton onClick={startCreateFlow}>
            Create/Assign Evidence
          </OutlinedButton>
          <Gap size={20} />
        </>
      ) : null}
      {isLoading ? (
        <LoadingIndicator show xOffset={-68} />
      ) : viewState === EvidencePanelViewState.empty ? (
        <ChainItemOnboardingContainer
          area="Evidence"
          notApplicable={evidenceNa}
          onCreateClicked={startCreateFlow}
          canContribute={canContribute}
        />
      ) : viewState === EvidencePanelViewState.createOptions ? (
        <AddEvidenceOptionView state={state} />
      ) : viewState === EvidencePanelViewState.create ||
        viewState === EvidencePanelViewState.edit ? (
        <UpsertEvidenceFormView
          handleClose={state.cancelCreate}
          handleSubmit={state.cancelCreate}
          evidence={edittingEvidenceDetail}
        />
      ) : (
        <EvidenceListView state={state} />
      )}
    </Column>
  );
}
