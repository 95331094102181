import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled, Typography } from '@mui/material';
import { CaseOutcomeResource } from '@api/types/case/case-outcome/case-outcome.resource';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { ColorClass } from '../outcome-report-scaffold';
import useSplitStringIntoParagraphs from '@hooks/outcome/use-split-string-into-paragraphs-html';

interface Props {
  outcome: CaseOutcomeResource;
  className: ColorClass;
}

const StyledClosingStatementSection = styled('div')(
  ({ theme: { palette } }) => ({
    '> .light, > .white': {
      '.initials-container-with-data': {
        '.circle-container': {
          backgroundColor: '#3CA680',
          color: '#fff',
        },
        '.name-container': {
          '.title': {
            color: '#00223A',
          },
          '.subtitle': {
            color: '#00223A99',
          },
        },
      },
    },
  })
);

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
}));

const ColWrapper = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  gap: '64px',
  height: '100%',
  maxWidth: '950px',
  padding: '10% 0',
}));

const Title = styled('div')(({ theme: { palette } }) => ({
  width: '25%',
  flex: '0 0 25%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  h2: {
    marginBottom: '1rem',
    marginTop: 4,
  },

  '.initials-container-with-data': {
    gap: 15,
    '.circle-container': {
      backgroundColor: '#3CA680',
      color: '#fff',
      opacity: '1',
      FontSize: '11px',
    },

    '.name-container': {
      '.title': {
        color: '#fff',
        fontSize: '18px',
        fontWeight: 400,
      },
      '.subtitle': {
        color: '#ffffff99',
        fontSize: '14px',
        fontWeight: 500,
      },
    },
  },
}));

const Details = styled('div')(({ theme: { palette } }) => ({
  flexGrow: 1,
  p: {
    marginTop: 0,
    lineHeight: 1.4,
    fontSize: 18,
    '&:first-child': {
      fontSize: 24,
      lineHeight: 1.4,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export default function ClosingStatementSection({ outcome, className }: Props) {
  const {
    createdByCompanyUserName,
    createdByCompanyUserJobTitle,
    recommendations,
  } = outcome;

  const textAsHtml = useSplitStringIntoParagraphs(recommendations);

  return (
    <StyledClosingStatementSection>
      <SectionScaffold id="closing-statement" className={className}>
        <Container>
          <ColWrapper>
            <Title>
              <Typography variant="h2">CLOSING STATEMENT</Typography>
              <InitialsContainerWithData
                fullName={createdByCompanyUserName}
                label={createdByCompanyUserName}
                subLabel={createdByCompanyUserJobTitle}
              />
            </Title>
            <Details>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: textAsHtml,
                }}
              />
            </Details>
          </ColWrapper>
        </Container>
      </SectionScaffold>
    </StyledClosingStatementSection>
  );
}
