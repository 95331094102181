import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfileResponse } from '@api/types/user/user-profile.response';
import i18n from '@i18n/index';
import locales from '@store/locale/locales';
import { DateFormat, dateFormats } from '@store/locale/date-formats';

interface LocaleState {
  localeId: number;
  dateFormat: DateFormat;
  use24HourClock: boolean;
  timezone: string;
}

const timezones: { [id: number]: string } = {
  1: 'UTC',
};

const initialState: LocaleState = {
  localeId: 1,
  dateFormat: dateFormats[1],
  use24HourClock: false,
  timezone: timezones[1],
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocaleFromProfile(state, action: PayloadAction<UserProfileResponse>) {
      const profile = action.payload;
      const { languageId, dateFormatId, use24HourTime, timezoneId } = profile;

      if (languageId != null && locales[languageId] != null) {
        state.localeId = languageId;
        i18n.changeLanguage(locales[languageId]);
      }

      if (dateFormatId != null && dateFormats[dateFormatId] != null) {
        state.dateFormat = dateFormats[dateFormatId];
      }

      if (use24HourTime != null) {
        state.use24HourClock = use24HourTime;
      }

      if (timezoneId != null && timezones[timezoneId] != null) {
        state.timezone = timezones[timezoneId];
      }
    },
  },
});

export default localeSlice.reducer;

export const { setLocaleFromProfile } = localeSlice.actions;
