import { ImpactInfoState } from '@pages/app/rca/create/forms/impact-info-hook';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import { ButtonSkeleton } from '@components/skeletons';

interface ImpactInfoFormButtonsProps extends ImpactInfoState {
  onCancel: () => void;
  addAnother: boolean;
}

function ImpactInfoFormButtons(props: ImpactInfoFormButtonsProps) {
  const { isLoading, canSubmit, isEdit, isBusy, onCancel, addAnother } = props;

  return (
    <>
      <OutlinedButton onClick={() => onCancel()}>Cancel</OutlinedButton>
      <Spacer />
      {isEdit ? (
        isLoading ? (
          <ButtonSkeleton />
        ) : (
          <PrimaryButton
            form="impactInfo"
            type="submit"
            disabled={!canSubmit}
            isBusy={isBusy}
          >
            Update
          </PrimaryButton>
        )
      ) : (
        <>
          {isLoading ? (
            <ButtonSkeleton />
          ) : (
            <>
              {addAnother ? (
                <OutlinedButton
                  id="create"
                  form="impactInfo"
                  type="submit"
                  disabled={!canSubmit}
                  isBusy={isBusy}
                >
                  Create
                </OutlinedButton>
              ) : (
                <PrimaryButton
                  id="create"
                  form="impactInfo"
                  type="submit"
                  disabled={!canSubmit}
                  isBusy={isBusy}
                >
                  Create
                </PrimaryButton>
              )}
            </>
          )}
          {addAnother && (
            <>
              {isLoading ? (
                <ButtonSkeleton />
              ) : (
                <PrimaryButton
                  id="createAndAdd"
                  form="impactInfo"
                  type="submit"
                  disabled={!canSubmit}
                  isBusy={isBusy}
                >
                  Create and add another
                </PrimaryButton>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ImpactInfoFormButtons;
