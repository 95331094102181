import { api } from '@api/api';
import { Option } from '@api/types/option';

const caseSeverityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseSeverityOptions: build.query<Option[], void>({
      query: () => ({
        url: 'caseSeverity/options',
      }),
    }),
  }),
});

export const { useGetCaseSeverityOptionsQuery } = caseSeverityApi;
