import { useParams } from 'react-router-dom';
import useOutcomeReport, {
  OutcomeReportViewState,
} from '@pages/app/outcome/outcome-report-hook';
import { numberFromString } from '@util/string-util';
import { styled } from '@mui/material';
import CheckingPermissionView from '@pages/app/outcome/views/checking-permission-view';
import { StatusPanel } from '@pages/app/outcome/components/status-panel';
import { LoadingIndicator } from '@components/loading-indicator';
import InvalidPermissionView from '@pages/app/outcome/views/invalid-permission-view';
import ReportView from '@pages/app/outcome/views/report-view';

type Params = {
  caseId: string;
  outcomeId: string;
};

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: palette.common.grey15,
}));

export default function OutcomeReportPage() {
  const { caseId: caseIdParam, outcomeId: outcomeIdParam } =
    useParams<Params>();
  const caseId = numberFromString(caseIdParam)!;
  const outcomeId = numberFromString(outcomeIdParam)!;

  const state = useOutcomeReport(caseId, outcomeId);
  const { viewState, outcomeAuthor } = state;

  return (
    <PageContainer>
      {viewState === OutcomeReportViewState.checkingPermission ? (
        <CheckingPermissionView />
      ) : viewState === OutcomeReportViewState.loading ? (
        <StatusPanel>
          <LoadingIndicator show />
        </StatusPanel>
      ) : viewState === OutcomeReportViewState.locked ? (
        <InvalidPermissionView author={outcomeAuthor} />
      ) : (
        <ReportView state={state} />
      )}
    </PageContainer>
  );
}
