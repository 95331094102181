import { styled } from '@mui/material';
import FlatBadge from '@components/badges/flat-badge';

interface Props {
  label?: string;
  value: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  fontSize?: string;
  padding?: string;
  height?: string;
}

const BadgeInfoContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 9,
  alignItems: 'flex-start',
}));

export default function BadgeInfo({
  label,
  value,
  backgroundColor,
  color,
  width,
  fontSize,
  padding,
  height,
}: Props) {
  return (
    <BadgeInfoContainer
      sx={{
        '.flat-badge': {
          backgroundColor,
          color,
          width,
          fontSize,
          padding,
          height,
        },
      }}
    >
      {label && <div className="label">{label}</div>}
      <div className="value">
        <FlatBadge color={color}>{value}</FlatBadge>
      </div>
    </BadgeInfoContainer>
  );
}
