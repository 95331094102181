import { usePagination } from '@components/table';
import { useCallback, useMemo } from 'react';

// TODO(Nick): Will need to make this a bit more clever, for now we can
// just bump the take param by count per request
export default function useLoadMore(countPerRequest: number = 50) {
  const pagination = usePagination(countPerRequest);
  const { onRowsPerPageChange, skip, take } = pagination;

  const loadMore = useCallback(() => {
    onRowsPerPageChange((v) => v + countPerRequest);
  }, [countPerRequest, onRowsPerPageChange]);

  const reset = useCallback(() => {
    onRowsPerPageChange(countPerRequest);
  }, [countPerRequest, onRowsPerPageChange]);

  const canLoadMore = useCallback(
    (total?: number) => {
      if (!total) {
        return false;
      }

      return skip + take < total;
    },
    [skip, take]
  );

  return useMemo(
    () => ({
      skip,
      take,
      loadMore,
      reset,
      canLoadMore,
    }),
    [canLoadMore, loadMore, reset, skip, take]
  );
}
