import { useGetMfaOptionsQuery } from '@api/endpoints/auth.api';
import { useMemo, useState } from 'react';
import { MfaMethod } from '@api/types/auth/mfa-method';
import useField from '@hooks/use-field-hook';
import { onlyNumbers, required } from '@util/validators';
import {
  useLazyRequestConfirmationCodeQuery,
  useTurnOn2FAMutation,
} from '@api/endpoints/user.api';
import useSharedState from '@store/shared-state-hook';
import { ApiError } from '@api/types/api-error';
import { MfaSubmitCodeRequest } from '@api/types/auth/mfa-submit-code.request';
import { useAppTr } from '@i18n/use-app-tr';
import { usePageAlertVariants } from '@components/alerts';

export default function useSetupMfaForm() {
  const { t } = useAppTr('mfaSettings');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { data: options, isLoading } = useGetMfaOptionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [requestCode, { isFetching: isFetchingCode }] =
    useLazyRequestConfirmationCodeQuery();

  const [submit2FACode, { isLoading: isSubmitting }] = useTurnOn2FAMutation();

  const { profile } = useSharedState();

  const [selectedMethod, setMethod] = useState<MfaMethod>();
  const [showCodeEntry, setCodeEntry] = useState(false);

  const code = useField<string>([required(), onlyNumbers()]);

  const canSubmit = useMemo(() => {
    if (!showCodeEntry) {
      return !isLoading && selectedMethod != null;
    }

    return !isFetchingCode && !isSubmitting && code.validate(false);
  }, [
    code,
    isFetchingCode,
    isLoading,
    isSubmitting,
    selectedMethod,
    showCodeEntry,
  ]);

  const resendCode = () => {
    if (selectedMethod === MfaMethod.email) {
      requestCode({
        method: MfaMethod.email,
        to: profile!.email,
      });
    } else {
      requestCode({
        method: MfaMethod.sms,
        to: profile!.phoneNumber!,
        countryDialCode: profile!.countryId,
      });
    }
  };

  const moveToCodeEntry = () => {
    setCodeEntry(true);
    resendCode();
  };

  const submitCode = (): Promise<boolean> => {
    return submit2FACode({
      method: selectedMethod!,
      code: code.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('enterCodeModal.toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<MfaSubmitCodeRequest>) => {
        showErrorMessage(message || errors?.method);
        code.setError(errors?.code);
        return false;
      });
  };

  return {
    options,
    isLoading,
    selectedMethod,
    setMethod,
    showCodeEntry,
    canSubmit,
    moveToCodeEntry,
    selectedMethodValue:
      selectedMethod === MfaMethod.sms ? options?.phoneNumber : options?.email,
    isFetchingCode,
    resendCode,
    submitCode,
    isSubmitting,
    code,
  };
}
