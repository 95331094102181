import { CaseOutcomeListItemResponse } from '@api/types/case/case-outcome/case-outcome-list-item.response';
import { styled, TableCell, TableRow, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import {
  ElipsisButton,
  OutlinedButton,
  PrimaryButton,
} from '@components/buttons';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { Gap } from '@components/layout-util-components/gap';
import { ReactComponent as NoOutcomeIcon } from '@assets/svgs/no-outcomes.svg';
import { ReactComponent as OutcomeIcon } from '@assets/svgs/outcome.svg';
import EmptySection from './empty-section';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useNavigate } from 'react-router-dom';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { usePageAlertVariants } from '@components/alerts';
import {
  CaseOutcomePathParams,
  useDeleteCaseOutcomeMutation,
} from '@api/endpoints/case-outcome.api';
import { ApiError } from '@api/types/api-error';
import { HeaderItem, WCTTable } from '@components/table';
import RcaIcon from '@components/rca-components/rca-icon';
import { useMemo } from 'react';

interface Props {
  outcomes: Array<CaseOutcomeListItemResponse>;
  caseId?: number;
  showTitle?: boolean;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
});

export default function OutcomesData({
  outcomes,
  caseId: pageCaseId,
  showTitle,
}: Props) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const { canContribute } = useCasePermission();
  const { showConfirmationModal } = useUiPopup();
  const navigate = useNavigate();

  const headerItems = useMemo(() => {
    const ret: HeaderItem[] = [];
    ret.push({ title: <></> });
    ret.push({ title: 'Name' });
    ret.push({ title: 'Created' });

    if (pageCaseId == null) {
      ret.push({ title: 'Analysis' });
    }
    ret.push({ title: 'Author' });
    ret.push({ title: <></> });
    return ret;
  }, [pageCaseId]);

  const onAddOutcomeClicked = async () => {
    navigate(`/rca/outcome/${pageCaseId}`);
  };

  const onEditOutcome = (outcomeId: number, caseId: number) => {
    navigate(`/rca/outcome/${caseId}/?outcomeId=${outcomeId}&step=0`);
  };

  const onViewOutcome = (outcomeId: number, caseId: number) => {
    window.open(`/outcome/${caseId}/report/${outcomeId}`, '_blank');
  };

  const [deleteCaseOutcome] = useDeleteCaseOutcomeMutation();

  const deleteOutcome = async (outcomeId: number, caseId: number) => {
    try {
      await deleteCaseOutcome({ caseId, outcomeId });

      showSuccessMessage('Outcome deleted successfully');
      return true;
    } catch (error) {
      const { message, errors } = error as ApiError<CaseOutcomePathParams>;

      showErrorMessage(errors?.caseId ?? errors?.outcomeId ?? message);
      return false;
    }
  };

  const onDeleteOutcome = async (outcomeId: number, caseId: number) => {
    await showConfirmationModal({
      title: 'Delete Outcome',
      message: 'Are you sure you want to delete this outcome?',
      yesButtonLabel: 'Delete',
      noButtonLabel: 'Cancel',
      yesButtonAsyncAction: () => deleteOutcome(outcomeId, caseId),
    });
  };

  return (
    <>
      {outcomes && outcomes.length > 0 ? (
        <Container>
          {showTitle && (
            <Row>
              <Typography variant="h2" color="#ffffff">
                Outcomes ({outcomes.length})
              </Typography>
              <Spacer />
              {canContribute && pageCaseId && (
                <PrimaryButton onClick={onAddOutcomeClicked}>
                  Add Outcomes
                </PrimaryButton>
              )}
            </Row>
          )}
          <WCTTable count={outcomes.length} headers={headerItems}>
            {outcomes.map((outcome) => (
              <TableRow
                key={outcome.outcomeId}
                onClick={() => onViewOutcome(outcome.outcomeId, outcome.caseId)}
              >
                <TableCell width={64}>
                  {pageCaseId ? (
                    <OutcomeIcon />
                  ) : (
                    <RcaIcon type="Problem" dark />
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{outcome.name}</Typography>
                </TableCell>
                <TableCell width="150">
                  <Typography variant="body2">
                    {dateOnlyShortFormatter(outcome.created)}
                  </Typography>
                </TableCell>
                {pageCaseId == null && (
                  <TableCell
                    sx={{
                      a: {
                        color: '#00223A',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <Row>
                      <RcaIndicatorIcon width={28} height="auto" />
                      <Gap size={10} />
                      <a
                        href={`/rca/tabs/${outcome.caseId}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {outcome.caseName}
                      </a>
                    </Row>
                  </TableCell>
                )}
                <TableCell
                  width="225"
                  sx={{
                    '.circle-container': {
                      width: 32,
                      height: 32,
                      background: '#3CA680',
                      color: '#fff',
                      fontSize: 10,
                      letterSpacing: '-.5px',
                      opacity: 1,
                    },
                    '.subtitle': {
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#00223A',
                    },
                  }}
                >
                  <InitialsContainerWithData fullName={outcome.createdBy} />
                </TableCell>
                <TableCell width={64}>
                  <Row contentAtEnd>
                    <ElipsisButton
                      actions={[
                        {
                          label: 'View',
                          onClick: () =>
                            onViewOutcome(outcome.outcomeId, outcome.caseId),
                        },
                        {
                          label: 'Edit',
                          onClick: () =>
                            onEditOutcome(outcome.outcomeId, outcome.caseId),
                        },
                        {
                          label: 'Delete',
                          onClick: () =>
                            onDeleteOutcome(outcome.outcomeId, outcome.caseId),
                        },
                      ]}
                    />
                  </Row>
                </TableCell>
              </TableRow>
            ))}
          </WCTTable>
        </Container>
      ) : (
        <EmptySection
          asRow
          icon={<NoOutcomeIcon />}
          title="You don’t currently have any outcomes"
          message={
            canContribute && pageCaseId
              ? 'There aren’t currently any outcomes within the platform, you can add one below.'
              : 'There aren’t currently any outcomes within the platform'
          }
          action={
            <>
              {canContribute && pageCaseId && (
                <OutlinedButton onClick={onAddOutcomeClicked}>
                  Add Outcomes
                </OutlinedButton>
              )}
            </>
          }
        />
      )}
    </>
  );
}
