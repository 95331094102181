import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import WCTSelectField from '@components/input/select-field';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import useUserForm from '@pages/app/company-profile/forms/user-form-hook';
import LabelValueDisplay from '@components/data/label-value-display';
import { Gap } from '@components/layout-util-components/gap';

export interface UserFormProps extends SideDrawerFormProps<void> {
  userId?: number;
  isView?: boolean;
}

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  '.groups_container': {
    listStyleType: 'none',
    padding: 0,
    margin: '10px 0 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    '> li': {
      background: '#00223A',
      borderRadius: '4px',
      padding: '5px 10px',
      color: '#fff',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
});

function UserForm({ onClose, userId, isView }: UserFormProps) {
  const { t } = useAppTr('createUserForm');

  const {
    isLoading,
    canSubmit,
    isSubmitting,
    submit,
    firstName,
    surname,
    jobTitle,
    groups,
    email,
    role,
    roleOptions,
    groupOptions,
    displayGroupOptions,
    isEdit,
  } = useUserForm(userId);

  const isCreate = !isView && !isEdit;

  const onSubmit = async () => {
    if (await submit()) {
      onClose();
    }
  };

  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <DrawerHeader onClose={onClose}>
        {isCreate ? t('title') : isView ? 'View User' : 'Edit User'}
      </DrawerHeader>
      <DrawerContent>
        <Typography variant="h4">
          {isLoading ? <Skeleton /> : isCreate ? t('details.title') : null}
        </Typography>
        <Gap size={20} />
        <Grid container wrap="nowrap" gap={1}>
          <Grid item xs={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : !isCreate ? (
              <LabelValueDisplay
                label={'Name'}
                value={`${firstName.value} ${surname.value}`}
              />
            ) : (
              <WCTTextField
                name="firstName"
                label={t('details.firstNameLabel')}
                value={firstName.value}
                error={firstName.error}
                onChange={firstName.set}
                readonly={isEdit}
                required
              />
            )}
          </Grid>
          {isCreate ? (
            <Grid item xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="surname"
                  label={t('details.surnameLabel')}
                  value={surname.value}
                  error={surname.error}
                  onChange={surname.set}
                  readonly={isEdit}
                  required
                />
              )}
            </Grid>
          ) : null}
        </Grid>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : !isCreate ? (
          <LabelValueDisplay label="Email" value={email.value} />
        ) : (
          <WCTTextField
            name="email"
            label={t('details.emailLabel')}
            value={email.value}
            error={email.error}
            onChange={email.set}
            readonly={isEdit}
            required
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : isView ? (
          <LabelValueDisplay
            label="Company Role"
            value={roleOptions!.find((x) => x.id === role.value)?.label || ''}
          />
        ) : (
          <WCTSelectField
            name="role"
            id="role"
            label="System Role"
            options={roleOptions!}
            value={role.value}
            error={role.error}
            onChange={(v) => role.set(v as number)}
            required
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : isView ? (
          <LabelValueDisplay label="Job Title" value={jobTitle.value || ''} />
        ) : !isCreate ? (
          <LabelValueDisplay
            label={t('companyRole.jobTitleLabel')}
            value={jobTitle.value}
          />
        ) : (
          <WCTTextField
            name="jobTitle"
            label={t('companyRole.jobTitleLabel')}
            value={jobTitle.value}
            error={jobTitle.error}
            onChange={jobTitle.set}
            readonly={isEdit}
          />
        )}
        {displayGroupOptions && (
          <>
            <Divider
              sx={{
                margin: '20px 0',
                borderBottom: '2px solid rgba(0, 0, 0, 0.15)',
              }}
            />
            <Typography variant="h4">
              {isLoading ? <Skeleton /> : isCreate ? t('groups.title') : null}
            </Typography>

            {isLoading ? (
              <InputSkeleton />
            ) : !isCreate ? (
              <LabelValueDisplay
                label="Groups"
                value={
                  <ul className="groups_container">
                    {groups.value?.map((groupId) => (
                      <li key={groupId}>
                        {groupOptions!.find((x) => x.id === groupId)?.label}
                      </li>
                    ))}
                  </ul>
                }
              />
            ) : (
              <>
                <Gap size={20} />
                <WCTSelectField
                  name="groups"
                  id="groups"
                  label={t('groups.groupsLabel')}
                  options={groupOptions!}
                  value={groups.value}
                  error={groups.error}
                  onChange={(v) => groups.set(v as Array<number>)}
                  readonly={isEdit}
                  multiple
                />
              </>
            )}
            <Gap size={15} />
            <Typography variant="caption">
              {isLoading ? (
                <Skeleton />
              ) : isCreate ? (
                t('groups.helperText')
              ) : null}
            </Typography>
            <Gap size={10} />
          </>
        )}
      </DrawerContent>
      <DrawerFooter>
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <OutlinedButton onClick={() => onClose()}>
            {isView ? 'Close' : t('cancelButtonLabel')}
          </OutlinedButton>
        )}
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : isView ? (
          <Box />
        ) : (
          <PrimaryButton
            type="submit"
            disabled={!canSubmit}
            isBusy={isSubmitting}
          >
            {isCreate ? t('submitButtonLabel') : 'Update User'}
          </PrimaryButton>
        )}
      </DrawerFooter>
    </Form>
  );
}

export default withSideDrawer(UserForm);
