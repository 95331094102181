import useField from '@hooks/use-field-hook';
import { equals, required, validPassword } from '@util/validators';
import { useResetPasswordMutation } from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { ResetPasswordRequest } from '@api/types/user/reset-password.request';
import { useCommonTr } from '@i18n/use-common-tr';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useResetPassword() {
  const { showErrorMessage } = usePageAlertVariants();
  const { t: validationTr } = useCommonTr('validation');

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const password = useField<string>([required(), validPassword()]);
  const confirmPassword = useField<string>([
    required(),
    equals(password.value, validationTr('passwordMissMatchError')),
  ]);

  const { isDirty, isValid, validateAll } = useFieldsWatcher([
    password,
    confirmPassword,
  ]);
  const canSubmit = isDirty && isValid && !isLoading;

  const submit = async (token: string, uid: string): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return resetPassword({
      token,
      uid,
      password: password.value,
      confirmPassword: confirmPassword.value,
    })
      .unwrap()
      .then(() => true)
      .catch(({ message, errors }: ApiError<ResetPasswordRequest>) => {
        showErrorMessage(message);
        password.setError(errors?.password);
        confirmPassword.setError(errors?.confirmPassword);
        return false;
      });
  };

  return {
    password,
    confirmPassword,
    canSubmit,
    submit,
    isLoading,
  };
}
