import { Box, Chip, styled, Typography, useTheme } from '@mui/material';

interface Props {
  severity: string;
}

const StyledChip = styled(Chip)<{ bg?: string; textCol?: string }>(
  ({ bg, textCol, theme: { palette } }) => ({
    borderRadius: '4px',
    cursor: 'pointer',
    background: bg || '#F8F8FA',
    display: 'flex',
    height: '24px',
    padding: '3px 4px 3px 1px',
    maxWidth: '115px',
    justifyContent: 'center',
    alignItems: 'center',
    '> span > .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      p: {
        fontSize: 12,
        color: textCol || '#525B5E',
      },
    },
  })
);

export default function RcaSeverityPill({ severity }: Props) {
  const { palette } = useTheme();

  const severityBgColor = {
    'very low': `${palette.blue.dark}26`,
    low: `${palette.blue.dark}26`,
    medium: `${palette.primary.main}26`,
    high: `${palette.error.main}26`,
    'very high': `${palette.error.main}26`,
  };

  const severityColor = {
    'very low': palette.blue.dark,
    low: 'palette.blue.dark',
    medium: 'palette.primary.main',
    high: 'palette.error.main',
    'very high': 'palette.error.main',
  };

  return (
    <StyledChip
      variant="filled"
      bg={severityBgColor[severity.toLowerCase()] || '#F8F8FA'}
      textCol={severityColor[severity.toLowerCase()] || '#F8F8FA'}
      label={
        <Box>
          <Typography>{severity}</Typography>
        </Box>
      }
    />
  );
}
