import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import ImpactsPage from '../create/components/impacts-page';
import useImpactStep from '@pages/app/rca/create/steps/impact-step-hook';
import ContentContainer from '@components/containers/content-container';
import { Box } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';
import { ButtonSkeleton } from '@components/skeletons';
import { OutlinedButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import PageCount from '@components/typography/page-count';

function RcaEditorImpacts() {
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const state = useImpactStep(caseId!);
  const {
    beginCreate,
    actualImpacts,
    potentialImpacts,
    isLoading,
    canContribute,
    isEmpty,
  } = state;

  const showTopButton = isLoading || !isEmpty;

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer maxWidth={1500}>
        <Row>
          <PageCount
            title="Impacts"
            isLoading={isLoading}
            count={actualImpacts?.length + potentialImpacts?.length ?? 0}
          />
          <Spacer />
          {showTopButton && (
            <>
              {isLoading ? (
                <Box>
                  <ButtonSkeleton />
                </Box>
              ) : (
                <OutlinedButton onClick={beginCreate}>
                  Add Impact
                </OutlinedButton>
              )}
            </>
          )}
        </Row>
        <ImpactsPage state={state} />
      </ContentContainer>
    </div>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorImpacts));
