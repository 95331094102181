import { ReactComponent as RcaIcon } from '@assets/svgs/rca-icon.svg';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  onCreateClicked: () => void;
}

export default function NoRcaInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<RcaIcon />}
      title="You don’t currently have access to any RCA’s"
      message="There aren’t currently any RCA’s linked to your account within the platform, you can create one below."
      createMessage="Create RCA"
      onCreateClicked={onCreateClicked}
    />
  );
}
