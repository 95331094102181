import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import Row from '@components/layout-util-components/row';
import { OutlinedButton } from '@components/buttons';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import FilledBadge from '@components/badges/filled-badge';
import { useState } from 'react';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { TableCell, TableRow } from '@mui/material';

interface Props {
  user: CaseUserResource;
  onInvite: () => Promise<any>;
}

export default function CollaborateRecomendUserRow({ user, onInvite }: Props) {
  const {
    kudosLevel,
    kudosPoints,
    name,
    companyRoleName,
    jobTitle,
    rcaCount,
    skills,
  } = user;

  const [isInviting, setIsInviting] = useState(false);

  return (
    <TableRow>
      <TableCell style={{ minWidth: 200 }}>
        <InitialsContainerWithData
          label={name}
          fullName={name}
          subLabel={jobTitle}
        />
      </TableCell>
      <TableCell>{companyRoleName}</TableCell>
      <TableCell>
        {skills && skills.length > 0 && (
          <FilledBadge>{skills[0].label}</FilledBadge>
        )}
        {skills && skills.length > 1 && (
          <FilledBadge>+{skills.length - 1}</FilledBadge>
        )}
      </TableCell>
      <TableCell>{rcaCount}</TableCell>
      <TableCell>{`Level ${
        kudosLevel != null ? kudosLevel : ''
      } (${kudosPoints}xp)`}</TableCell>
      <TableCell style={{ width: 100 }}>
        <Row contentAtEnd>
          {isInviting ? (
            <FetchingIndicator show />
          ) : (
            <OutlinedButton
              onClick={() => {
                setIsInviting(true);
                onInvite().finally(() => setIsInviting(false));
              }}
            >
              Invite
            </OutlinedButton>
          )}
        </Row>
      </TableCell>
    </TableRow>
  );
}
