import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

const OuterContainer = styled('div', {
  target: 'content-footer-card-outer-container',
})<{
  widthOverride?: number | string;
  pointer?: boolean;
}>(({ widthOverride, pointer, theme: { palette } }) => ({
  width: widthOverride ?? '100%',
  margin: '1px',
  '@media (min-width:825px)': {
    width: widthOverride ?? 'calc(50% - 14.5px)',
  },
  '@media (min-width:1100px)': {
    width: widthOverride ?? 'calc(33.3% - 18.5px)',
  },
  '@media (min-width:1450px)': {
    width: widthOverride ?? 'calc(25% - 20.75px)',
  },

  cursor: pointer ? 'pointer' : undefined,
  border: '1px solid rgba(0, 0, 0, 0.25)',
  borderRadius: 8,
  background: palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&:hover': {
    border: `2px solid ${palette.blue.dark}`,
    /* boxShadow: '0px 0px 0px 5px rgba(0, 34, 58, 0.15)', */
    margin: '0',
    '@media (min-width:825px)': {
      width: widthOverride ?? 'calc(50% - 12.5px)',
    },
    '@media (min-width:1100px)': {
      width: widthOverride ?? 'calc(33.3% - 16.5px)',
    },
    '@media (min-width:1450px)': {
      width: widthOverride ?? 'calc(25% - 18.75px)',
    },
  },
}));

const CardFooter = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: 'white',
  padding: 20,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  borderTop: '1px solid rgba(0, 0, 0, 0.25)',
}));

interface Props {
  content: ReactNode;
  footer?: ReactNode;
  onClick?: () => void;
  widthOverride?: number | string;
  background?: string;
  pointer?: boolean;
}

export default function ContentFooterCard({
  content,
  footer,
  onClick,
  widthOverride,
  background,
  pointer,
}: Props) {
  return (
    <OuterContainer
      widthOverride={widthOverride}
      pointer={pointer}
      onClick={(e) => {
        onClick?.call(e);
      }}
    >
      <Box>{content}</Box>
      {footer ? <CardFooter>{footer}</CardFooter> : null}
    </OuterContainer>
  );
}
