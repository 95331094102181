import { Address } from '@components/input/address-field';
import { useMemo } from 'react';

// Returns a memoized version of address
export default function useAddressMemo(address: Address): Address | undefined {
  const addressLineOne = address?.addressLineOne;
  const addressLineTwo = address?.addressLineTwo;
  const countryId = address?.countryId;
  const postcode = address?.postcode;
  const country = address?.country;
  const county = address?.county;
  const town = address?.town;

  const addrValue = useMemo(
    () => ({
      addressLineOne,
      addressLineTwo,
      countryId,
      postcode,
      country,
      county,
      town,
    }),
    [addressLineOne, addressLineTwo, country, countryId, county, postcode, town]
  );

  if (
    !addressLineOne &&
    !addressLineTwo &&
    !countryId &&
    !postcode &&
    !country &&
    !county &&
    !town
  ) {
    return undefined;
  }

  return addrValue;
}
