import { api } from '@api/api';
import { Option } from '@api/types/option';

const caseStatusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseStatusOptions: build.query<Option[], void>({
      query: () => ({
        url: 'caseStatus/options',
      }),
    }),
  }),
});

export const { useGetCaseStatusOptionsQuery } = caseStatusApi;
