import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import Column from '@components/layout-util-components/column';
import SettingsOptionContainer from '@components/containers/settings-option-container';
import { Permission } from '@api/types/permission';
import { useNavigate } from 'react-router-dom';

function CompanySettingsIndexPage() {
  const { t } = useAppTr('companySettingsLanding');
  const navigate = useNavigate();

  return (
    <Column>
      <Typography sx={{ lineHeight: '40px' }} variant="h2">
        {t('title')}
      </Typography>
      <SettingsOptionContainer
        title={t('info.title')}
        message="Update your information such as company name, type and address"
        onClick={() => navigate('information')}
      />
      <SettingsOptionContainer
        title={t('mfa.title')}
        message={t('mfa.message')}
        onClick={() => navigate('mfa')}
      />
      <SettingsOptionContainer
        title="Date & Time Settings"
        message={t('settings.message')}
        onClick={() => navigate('settings')}
      />
    </Column>
  );
}

export default requireAuth(CompanySettingsIndexPage, {
  permissions: [Permission.manageCompany],
});
