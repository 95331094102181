import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { Box, styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import TaskCompleteIndicator from '@pages/app/tasks/components/task-complete-indicator';
import Spacer from '@components/layout-util-components/spacer';
import PriorityIndicator from '@pages/app/tasks/components/priority-indicator';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { ReactComponent as DueDateIndicator } from '@assets/svgs/due-date-indicator.svg';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { ElipsisButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import usePersonalTaskOptions from '@hooks/tasks/use-personal-task-options';
import TaskPriorityIndicator from '@components/tasks/task-priority-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';

interface Props {
  task: TaskDetailListItemResponse;
  onEdit?: () => void;
}

const Container = styled('div')({
  padding: '12px 10px',
  border: '1px solid rgba(0,0,0,0.25)',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: 15,
  '.initials-container-with-data': {
    '.subtitle': {
      fontSize: '13px',
      fontWeight: '400',
    },
  },
});

export default function TaskPanelTaskRow({ task, onEdit }: Props) {
  const {
    onOpenTask,
    onCompleteTask,
    isComplete,
    isOverdue,
    isTogglingState,
    onEditTask,
  } = usePersonalTaskOptions(task.taskDetailId, task.taskStatus, {
    caseId: task.caseId,
  });

  return (
    <Container
      sx={{
        border: isComplete ? '1px solid rgba(0, 0, 0, 0.15)' : '',
        borderLeft: isComplete ? '2px solid #3CA680' : '',
        borderRadius: isComplete ? '0 5px 5px 0' : '5px',
        background: isComplete ? '#F8F8FA' : '',
        '.MuiTypography-body1': {
          color: isComplete ? 'rgba(0,0,0,0.4)' : '',
        },
        '.initials-container-with-data': {
          '.subtitle': {
            color: isComplete ? 'rgba(0,0,0,0.4)' : '',
          },
        },
      }}
      onClick={onEditTask}
    >
      <Row>
        <TaskCompleteIndicator
          isComplete={isComplete}
          onComplete={onCompleteTask}
          onOpen={onOpenTask}
          isTogglingState={isTogglingState}
        />
        <Gap size={10} />
        <Typography>{task.title}</Typography>
        <Spacer />
        <TaskPriorityIndicator priority={task.priority} />
      </Row>
      <Gap size={10} />
      <Row contentAtEnd>
        <InitialsContainerWithData fullName={task.assignedToCompanyUser} />
        <Gap size={15} />
        <Box
          sx={{
            background: isOverdue
              ? 'rgba(255,61,61,0.1)'
              : 'rgba(0, 0, 0, 0.075)',
            width: 32,
            height: 32,
            flexShrink: 0,
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
            svg: {
              height: 20,
              color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
            },
          }}
        >
          <FontAwesomeIcon icon={faCalendar} />
        </Box>
        <Gap size={10} />
        <Typography
          sx={{
            fontSize: 13,
            color: isOverdue ? '#D60000' : 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {dateOnlyShortFormatter(task.dueDate)}
        </Typography>
      </Row>
    </Container>
  );
}
