import requireAuth from '@components/hoc/with-required-auth-hoc';
import { useNavigate, useParams } from 'react-router-dom';
import useRcaOutcome, {
  RcaOutcomeState,
} from '@pages/app/rca/outcome/rca-outcome-hook';
import { numberFromString } from '@util/string-util';
import RcaStepperAppBar from '@pages/app/rca/create/components/rca-stepper-app-bar';
import useStepper from '@components/stepper/use-stepper-hook';
import CreateOutcomeView from '@pages/app/rca/outcome/views/create-outcome-view';
import CustomiseOutcomeView from '@pages/app/rca/outcome/views/customise-outcome-view';
import { useCallback, useEffect } from 'react';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import OutcomePreviewStep from '@pages/app/rca/outcome/views/outcome-preview-step';
import ShareButton from '@pages/app/rca/outcome/components/share-button';

type PageParams = {
  caseId: string;
};

type SearchParams = {
  type: string;
  step?: string;
  outcomeId?: string;
};

export type OutcomeStepProps = {
  state: RcaOutcomeState;
  onBack: () => void;
  onContinue: () => void;
};

function RcaOutcomePage() {
  const navigate = useNavigate();
  const { caseId } = useParams<PageParams>();
  const { step, outcomeId } = useSearchParamsTyped<SearchParams>();
  const state = useRcaOutcome(
    numberFromString(caseId)!,
    numberFromString(outcomeId)
  );
  const { isEdit, appBarRef, outcomeDetail } = state;

  const stepper = useStepper<OutcomeStepProps>([
    {
      title: isEdit ? 'Edit' : 'Create',
      Component: CreateOutcomeView,
      onClick: () => navigate(buildQueryParams(0)),
    },
    {
      title: 'Customise',
      Component: CustomiseOutcomeView,
      onClick: () => navigate(buildQueryParams(1)),
    },
    {
      title: 'Preview',
      Component: OutcomePreviewStep,
      onClick: () => navigate(buildQueryParams(2)),
    },
  ]);
  const {
    currentStep: { Component: CurrentStepComponent },
  } = stepper;

  const outcomeIdNumber = outcomeDetail?.outcomeId;
  const buildQueryParams = useCallback(
    (step: number) => {
      if (outcomeIdNumber != null) {
        return `?step=${step}&outcomeId=${outcomeIdNumber}`;
      }
      return `?step=${step}`;
    },
    [outcomeIdNumber]
  );

  const onContinue = () => {
    const nextStep = stepper.currentStepIndex + 1;
    if (!stepper.isLastStep) {
      navigate(buildQueryParams(nextStep));
    }
  };

  const onBack = () => {
    const prevStep = stepper.currentStepIndex - 1;
    if (!stepper.isFirstStep) {
      navigate(buildQueryParams(prevStep));
    } else {
      navigate(`/rca/tabs/${caseId}`, { replace: true });
    }
  };

  const setCurrentStep = stepper.setCurrentStep;
  const { showOverview, showSolutions, showInsights, showRecommendations } =
    outcomeDetail ?? {};
  useEffect(() => {
    const stepIdx = numberFromString(step);
    if (stepIdx != null) {
      setCurrentStep(numberFromString(step)!);
    }

    if (outcomeIdNumber != null) {
      const shouldSkipStep2 =
        stepIdx != null &&
        stepIdx === 1 &&
        showOverview != null &&
        !showOverview &&
        showSolutions != null &&
        !showSolutions &&
        showInsights != null &&
        !showInsights &&
        showRecommendations != null &&
        !showRecommendations;
      if (shouldSkipStep2) {
        navigate(buildQueryParams(stepIdx! + 1));
      } else {
        navigate(buildQueryParams(stepIdx!));
      }
    }
  }, [
    buildQueryParams,
    navigate,
    outcomeIdNumber,
    setCurrentStep,
    showInsights,
    showOverview,
    showRecommendations,
    showSolutions,
    step,
  ]);

  return (
    <div style={{ width: '100vw', minHeight: '100vh' }}>
      <RcaStepperAppBar
        ref={appBarRef}
        onBack={() => navigate(`/rca/tabs/${caseId}`, { replace: true })}
        stepper={stepper}
        end={
          stepper.isLastStep ? (
            <ShareButton
              caseId={outcomeDetail?.caseId ?? -1}
              outcomeId={outcomeDetail?.outcomeId ?? -1}
            />
          ) : null
        }
      />
      <CurrentStepComponent
        state={state}
        onBack={onBack}
        onContinue={onContinue}
      />
    </div>
  );
}

export default requireAuth(RcaOutcomePage);
