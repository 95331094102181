import { Typography } from '@mui/material';
import {
  NavTabContainer,
  NavTabHeader,
} from '@components/menu-items/app/nav-tab-header';
import ContentContainer from '@components/containers/content-container';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';
import { isApp } from '@util/env';

export interface ProfileLayoutTab {
  name: string;
  path: string;
  subPaths?: string[];
  index?: boolean;
}

interface Props {
  baseRoute: string;
  title: string;
  tabs: Array<ProfileLayoutTab>;
}

export default function ProfileLayoutScaffold({
  title,
  baseRoute,
  tabs,
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSelected = (path: string) => {
    return isOneSelected([path]);
  };

  const isOneSelected = (paths: string[]): boolean => {
    const cleanPath = pathname.replace(/\/+$/, '');
    return (
      paths.filter(
        (path: string) =>
          cleanPath === `/${baseRoute}/${path}`.replace(/\/+$/, '')
      ).length > 0
    );
  };

  const onClick = (path: string) => () => navigate(`/${baseRoute}/${path}`);

  return (
    <ContentContainer maxWidth={1500}>
      <Typography variant="h1">{title}</Typography>
      <Gap size={24} />
      {isApp && (
        <>
          <NavTabContainer>
            {tabs.map(({ name, path, index, subPaths }) => (
              <NavTabHeader
                key={path}
                onClick={onClick(index ? '' : path)}
                isSelected={
                  (index && (isSelected('') || isSelected('/'))) ||
                  isSelected(path) ||
                  (subPaths && isOneSelected(subPaths))
                }
              >
                {name}
              </NavTabHeader>
            ))}
          </NavTabContainer>
          <Gap size={32} />
        </>
      )}
      <Outlet />
    </ContentContainer>
  );
}
