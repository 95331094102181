import { Box, Typography, useTheme } from '@mui/material';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import getCompanyUserStatusTr from '@util/tr/user-status-tr';

interface Props {
  status: CompanyUserStatus;
}

export default function UserStatus({ status }: Props) {
  const { palette } = useTheme();

  const statusIndicatorColor = {
    [CompanyUserStatus.invited]: palette.blue.dark,
    [CompanyUserStatus.active]: palette.success.main,
    [CompanyUserStatus.inviteExpired]: '#B9C3CA',
    [CompanyUserStatus.deleted]: palette.error.main,
    [CompanyUserStatus.unknown]: '#B9C3CA',
  };

  const statusTextColor = {
    [CompanyUserStatus.invited]: palette.blue.dark,
    [CompanyUserStatus.active]: palette.success.main,
    [CompanyUserStatus.inviteExpired]: '#B9C3CA',
    [CompanyUserStatus.deleted]: palette.error.main,
    [CompanyUserStatus.unknown]: '#B9C3CA',
  };

  return (
    <Box
      className="status"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        gap: '10px',
      }}
    >
      <Box
        sx={{
          width: '9px',
          height: '9px',
          borderRadius: 9,
          background: statusIndicatorColor[status],
        }}
      />
      <Typography
        sx={{
          fontSize: '14px',
          color: statusTextColor[status],
        }}
      >
        {getCompanyUserStatusTr(status)}
      </Typography>
    </Box>
  );
}
