import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as EmptyLeagueIcon } from '@assets/svgs/league-empty.svg';

interface Props {
  message?: string;
}

export default function NoReportInfo({ message }: Props) {
  return (
    <OnboardingEntryContainer
      variant="image"
      icon={<EmptyLeagueIcon />}
      message={message}
    />
  );
}
