import { sidebarState } from '@store/sidebar-state-manager';
import { useEffect, useState } from 'react';

export default function useActionFooter() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarState.getState());
  const [footerStyle, setFooterStyle] = useState({});

  useEffect(() => {
    const unsubscribe = sidebarState.subscribe((isOpen) => {
      console.log('Sidebar state changed:', isOpen);
      setIsSidebarOpen(isOpen);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setFooterStyle({
      position: 'fixed',
      height: 82,
      bottom: 0,
      left: 0,
      width: isSidebarOpen ? 'calc(100% - 275px)' : 'calc(100% - 80px)',
      maxHeight: '75px',
      padding: '20px 80px',
      backgroundColor: 'white',
      zIndex: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: isSidebarOpen ? '275px' : '80px',
      transition: 'all 0.1s ease-in-out',
    });
  }, [isSidebarOpen]);

  return footerStyle;
}
