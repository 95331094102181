import {
  CaseImpactTypeMetric,
  CaseImpactTypeMetricsResource,
} from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { Option } from '@api/types/option';
import { CaseImpactStatRow } from '@pages/app/rca/create/steps/impact-step-models';
import { numberFromString } from '@util/string-util';
import { currencyFormatter, numberFormatter } from '@i18n/formatters';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';

export namespace CaseImpactUtil {
  export function getImpactValue(
    value: string | number,
    metric: CaseImpactTypeMetric,
    scaleOptions: Option[],
    currency = 'GBP'
  ) {
    switch (metric) {
      case CaseImpactTypeMetric.currency:
        return currencyFormatter(value, { decimalPlaces: 0, currency });
      case CaseImpactTypeMetric.scale:
        // remove decimal places from value if any
        value = `${value}`.split('.')[0];
        return scaleOptions.find((x) => `${x.id}` === `${value}`)?.label ?? '';
      case CaseImpactTypeMetric.numeric:
        return numberFormatter(value);
      default:
        return '';
    }
  }

  export function extractNumericValue(
    value: string,
    metric: CaseImpactTypeMetric
  ) {
    switch (metric) {
      case CaseImpactTypeMetric.currency:
      case CaseImpactTypeMetric.numeric:
        return numberFromString(value);
      case CaseImpactTypeMetric.scale:
      default:
        return;
    }
  }

  export function getImpactStatsRow(
    actual: Array<CaseImpactResource>,
    potential: Array<CaseImpactResource>,
    name: string,
    metric: CaseImpactTypeMetricsResource,
    currency: string
  ): CaseImpactStatRow {
    let actualValue: number = 0;
    let potentialValue: number = 0;

    let actualMetricIdx = 999;
    let potentialMetricIdx = 999;
    let actualFallbackValue: string | number | undefined = undefined;
    let potentialFallbackValue: string | number | undefined = undefined;

    for (const actualImpact of actual) {
      const value = actualImpact.impactValue;
      if (value != null) {
        actualValue += value;
      } else {
        const metricType = metric.caseImpactTypeMetric;
        if (metricType === CaseImpactTypeMetric.scale) {
          const idx = metric.options.findIndex(
            (x) => x.id === numberFromString(actualImpact.impactValue)
          );
          if (idx !== -1 && idx < actualMetricIdx) {
            actualMetricIdx = idx;
            actualFallbackValue = metric.options[idx].label;
          }
        } else if (actualFallbackValue == null) {
          actualFallbackValue = getImpactValue(
            actualImpact.impactValue,
            metric.caseImpactTypeMetric,
            metric.options,
            currency
          );
        }
      }
    }

    for (const potentialImpact of potential) {
      const value = potentialImpact.impactValue;
      if (value != null) {
        potentialValue += value;
      } else {
        const metricType = metric.caseImpactTypeMetric;
        if (metricType === CaseImpactTypeMetric.scale) {
          const idx = metric.options.findIndex(
            (x) => x.id === numberFromString(potentialImpact.impactValue)
          );
          if (idx !== -1 && idx < potentialMetricIdx) {
            potentialMetricIdx = idx;
            potentialFallbackValue = metric.options[idx].label;
          }
        } else if (potentialFallbackValue == null) {
          potentialFallbackValue = getImpactValue(
            potentialImpact.impactValue,
            metric.caseImpactTypeMetric,
            metric.options,
            currency
          );
        }
      }
    }

    const total = actualValue + potentialValue;

    const displayTotal =
      total > 0
        ? getImpactValue(
            `${total}`,
            metric.caseImpactTypeMetric,
            metric.options,
            currency
          )
        : '-';

    const displayActualValue =
      actualValue > 0
        ? getImpactValue(
            `${actualValue}`,
            metric.caseImpactTypeMetric,
            metric.options,
            currency
          )
        : actualFallbackValue
        ? actualFallbackValue
        : '-';

    const displayPotentialValue =
      potentialValue > 0
        ? getImpactValue(
            `${potentialValue}`,
            metric.caseImpactTypeMetric,
            metric.options,
            currency
          )
        : potentialFallbackValue
        ? potentialFallbackValue
        : '-';

    return {
      name,
      total: displayTotal,
      actualValue: displayActualValue,
      potentialValue: displayPotentialValue,
    };
  }
}
