import { useTranslation } from 'react-i18next';

export const useAppTr = (
  keyPrefix:
    | 'analysis'
    | 'common'
    | 'billing'
    | 'companyMfaSettings'
    | 'companyInformation'
    | 'companyPreferences'
    | 'companySettingsLanding'
    | 'createUserForm'
    | 'functionalLocationForm'
    | 'registerInterest'
    | 'personalDetails'
    | 'preferences'
    | 'groups'
    | 'groupForm'
    | 'mfaSettings'
    | 'tasks'
    | 'taskForm'
    | 'userManagement'
) => useTranslation('app', { keyPrefix });
