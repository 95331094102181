import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import { CompanyGroupListRequest } from '@api/types/company-group/company-group-list.request';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const companyGroupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserGroups: build.query<
      PaginatedResult<CompanyGroupListItemResponse>,
      CompanyGroupListRequest
    >({
      query: (params) => ({
        url: 'companygroup',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (result) =>
        result
          ? [
              ...result.model.map(({ companyGroupId }) => ({
                type: 'CompanyGroup' as const,
                id: companyGroupId,
              })),
              'CompanyGroup',
            ]
          : ['CompanyGroup'],
    }),
    getCompanyGroupDetailForUser: build.query<CompanyGroupResource, number>({
      query: (id) => ({
        url: `companygroup/${id}`,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (result) =>
        result ? [{ id: result.companyGroupId, type: 'CompanyGroup' }] : [],
    }),
  }),
});

export const { useGetUserGroupsQuery, useGetCompanyGroupDetailForUserQuery } =
  companyGroupApi;
