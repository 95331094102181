import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onElipsisClick?: () => void;
}

export const ANALYSIS_COMPLETE_COLOR = '#3CA680';
export const ANALYSIS_COMPLETE_BORDER_COLOR = '#3CA680';

const Container = styled('div')((theme) => ({
  zIndex: 100,
  position: 'absolute',
  top: '50%',
  right: 5,
  transform: 'translate(50%, -50%)',
  borderRadius: 72,
  backgroundColor: ANALYSIS_COMPLETE_COLOR,
  color: 'white',

  margin: 0,
  marginLeft: 21,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  cursor: 'pointer',

  padding: 6,
  width: 42,
  height: 42,

  '& > svg': {
    fontSize: '1.75em',
  },

  '& > button': {
    width: '100%',
    height: '100%',
    color: 'white',
    backgroundColor: '#00000033',
    border: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: '#fff',
      color: 'green',
      border: 'none',
    },
  },
}));

export default forwardRef<HTMLElement, Props>(function (
  { onElipsisClick },
  buttonRef
) {
  return (
    <Container>
      <FontAwesomeIcon
        ref={buttonRef as any}
        icon={faCircleCheck}
        onClick={onElipsisClick}
      />
    </Container>
  );
});
