import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import ImpactOption from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/impact-option';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  state: ImpactsPanelState;
}

export default function SelectImpacts({ state }: Props) {
  const {
    actualImpacts,
    potentialImpacts,
    resetImpactValues,
    onSubmitValues,
    isSubmitting,
    canContribute,
    caseDetail,
    onBeginAddingImpacts,
    hasNoImpacts,
  } = state;

  return (
    <Column>
      {!hasNoImpacts && (
        <>
          {canContribute && (
            <>
              <Typography variant="body1">
                Please use the sliders below to indicate how much each impact
                has on this cause box
              </Typography>
              <Gap size={10} />
            </>
          )}
          {actualImpacts.length > 0 ? (
            <>
              <Gap size={10} />
              <Typography>Actual Impacts</Typography>
              <Gap size={10} />
              {actualImpacts.map((impact) => (
                <ImpactOption
                  key={impact.caseImpactId}
                  impact={impact}
                  state={state}
                />
              ))}
            </>
          ) : null}
          {potentialImpacts.length > 0 ? (
            <>
              <Gap size={10} />
              <Typography>Potential Impacts</Typography>
              <Gap size={10} />
              {potentialImpacts.map((impact) => (
                <ImpactOption
                  key={impact.caseImpactId}
                  impact={impact}
                  state={state}
                />
              ))}
            </>
          ) : null}
          {canContribute && (
            <>
              <Gap size={15} />
              <ActionsRow>
                <OutlinedButton onClick={resetImpactValues}>
                  Cancel
                </OutlinedButton>
                <PrimaryButton onClick={onSubmitValues} isBusy={isSubmitting}>
                  Update Impacts
                </PrimaryButton>
              </ActionsRow>
            </>
          )}
        </>
      )}
      {hasNoImpacts && (
        <OnboardingEntryContainer
          hideBgImage
          icon={<FontAwesomeIcon icon={faBullseyePointer} size="2x" />}
          title={`There are no impacts added to this ${caseDetail?.caseAnalysisTypeName}`}
          message={
            canContribute
              ? 'Please add impacts to this RCA within the Impacts tab to assign to cause boxes within the chart'
              : undefined
          }
          createMessage={canContribute ? 'Create Impact' : undefined}
          onCreateClicked={canContribute ? onBeginAddingImpacts : undefined}
        />
      )}
    </Column>
  );
}
