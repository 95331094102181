import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  tracked: boolean;
  showLabel?: boolean;
}

const Container = styled(Row)(({ theme: { palette } }) => ({
  svg: {
    height: 24,
    '&.non-tracked': {
      color: palette.error.main,
    },
  },

  '.tracked': {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    color: palette.success.main,
    flexShrink: '0',

    '&.not-tracked': {
      color: palette.error.main,
      flexShrink: '0',
    },
  },
}));

export default function TrackedIndicator({ tracked, showLabel }: Props) {
  const icon = tracked ? (
    <FontAwesomeIcon className="tracked" icon={faCircleCheck} />
  ) : (
    <FontAwesomeIcon className="non-tracked" icon={faCircleMinus} />
  );

  return (
    <Container>
      {icon}
      {showLabel && (
        <>
          <Gap size={8} />
          <div className={`tracked ${!tracked ? 'not-tracked' : ''}`}>
            {tracked ? 'Tracked' : 'Not Tracked'}
          </div>
        </>
      )}
    </Container>
  );
}
