import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useCompanyMfaSetup from '@pages/app/company-profile/company-mfa-setup-hook';
import { Permission } from '@api/types/permission';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

function CompanyMfaSetupPage() {
  const { t } = useAppTr('companyMfaSettings');
  const { showConfirmationModal } = useUiPopup();

  const { isOn, setMfa } = useCompanyMfaSetup();

  const onToggleSetting = async () => {
    // We're about to toggle OFF
    if (isOn.value) {
      const didDisable = await showConfirmationModal({
        title: t('disableModel.title'),
        message: t('disableModel.message'),
        noButtonLabel: t('disableModel.noButtonLabel'),
        yesButtonLabel: t('disableModel.yesButtonLabel'),
        yesButtonAsyncAction: () => setMfa(false),
      });

      if (didDisable) {
        isOn.set(false);
      }
    } else {
      const didEnable = await showConfirmationModal({
        title: t('enableModal.title'),
        message: t('enableModal.message'),
        noButtonLabel: t('enableModal.noButtonLabel'),
        yesButtonLabel: t('enableModal.yesButtonLabel'),
        yesButtonAsyncAction: () => setMfa(true),
      });

      if (didEnable) {
        isOn.set(true);
      }
    }
  };

  return (
    <Container>
      <SubpageTitle breadCrumb="Company Settings" title={t('title')} />
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('sectionLabel')}</Typography>
          <Typography variant="body2">{t('sectionMessage')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          <SwitchContainer>
            <WCTSwitchField
              name="2fa-toggle"
              label={t(isOn.value ? 'toggle.on' : 'toggle.off')}
              value={isOn.value}
              error={isOn.error}
              onChange={onToggleSetting}
              onBlur={isOn.validate}
            />
          </SwitchContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default requireAuth(CompanyMfaSetupPage, {
  permissions: [Permission.manageCompany],
});
