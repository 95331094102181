import { useAppDispatch, useAppSelector } from '@store/store';
import {
  makeSelectAlLAncestors,
  makeSelectAlLDescendants,
  selectCauseNodes,
  selectChainId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RcaNode } from '@store/rca-editor/types';
import { useFilter, useSortableHeader } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { useGetChainItemQuery } from '@api/endpoints/chain/chain-item.api';
import { ApiError, isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { setNodeConnections } from '@store/rca-editor/rca-editor-actions';
import { useCasePermission } from '@hooks/case/use-case-permission';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { resetFocus } from '@store/rca-editor/rca-editor-slice';
import { LinkChainItemsRequest } from '@api/types/chain/link-chain-items.request';
import { isNullOrEmpty } from '@util/string-util';

export enum ConnectionsPanelViewState {
  empty,
  select,
  listSelected,
}
export default function useConnectionsPanel() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { canContribute } = useCasePermission();
  const hasLoaded = useRef(false);
  const [viewState, setViewState] = useState(ConnectionsPanelViewState.empty);
  const [currentNodeIds, setCurrentNodeIds] = useState<Array<number>>([]);
  const [selectedNodeIds, setSelectedNodeIds] = useState<Array<number>>([]);

  const nodes = useAppSelector(selectCauseNodes);
  const focusedNode = useAppSelector(selectFocusedNode);
  const chainId = useAppSelector(selectChainId)!;

  const selectAncestors = useMemo(
    () => makeSelectAlLAncestors(focusedNode.id),
    [focusedNode.id]
  );
  const selectDescendants = useMemo(
    () => makeSelectAlLDescendants(focusedNode.id),
    [focusedNode.id]
  );
  const ancestors = useAppSelector(selectAncestors);
  const descendants = useAppSelector(selectDescendants);

  const {
    data: chainItem,
    isLoading,
    isFetching,
  } = useGetChainItemQuery(
    { chainId, chainItemId: focusedNode.data?.chainItemId ?? -1 },
    { skip: focusedNode.data?.chainItemId == null }
  );

  const search = useFilter<string>();
  const sortBy = useSortableHeader({ initialProperty: 'description' });
  const sortByOptions: Array<SortOption> = [
    { id: 'description', label: 'A to Z', direction: SortDirection.asc },
    { id: 'description', label: 'Z to A', direction: SortDirection.desc },
  ];

  const selectedNodes = useMemo(() => {
    return nodes.filter(
      (x) =>
        x.data?.chainItemId != null &&
        selectedNodeIds.indexOf(x.data.chainItemId) !== -1
    );
  }, [nodes, selectedNodeIds]);

  const isSelected = (node: RcaNode) =>
    currentNodeIds.findIndex((nodeId) => nodeId === node.data?.chainItemId) !==
    -1;

  const toggleSelected = (node: RcaNode) => {
    if (isSelected(node)) {
      setCurrentNodeIds(
        currentNodeIds.filter((nodeId) => nodeId !== node.data?.chainItemId)
      );
    } else {
      if (node.data?.chainItemId != null) {
        setCurrentNodeIds([...currentNodeIds, node.data.chainItemId]);
      }
    }
  };

  const [saveNodes, isBusy] = useBusyAction(async (nodeIds: Array<number>) => {
    try {
      await dispatch(setNodeConnections(focusedNode.id, nodeIds));

      await Promise.all([
        invalidation('ChainItem', focusedNode.data.chainItemId),
        invalidation('CaseTotals'),
      ]);
    } catch (e) {
      if (isApiError<LinkChainItemsRequest>(e)) {
        const { message, errors } = e as ApiError<LinkChainItemsRequest>;
        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.chainItemIds ??
            message
        );
      }

      return false;
    }

    if (nodeIds.length === 0) {
      setViewState(ConnectionsPanelViewState.empty);
    }

    return true;
  });

  const removeSelectedNode = async (node: RcaNode) => {
    const removedNodeIds = currentNodeIds.filter(
      (nodeId) => nodeId !== node.data?.chainItemId
    );

    if (await saveNodes(removedNodeIds)) {
      setCurrentNodeIds(removedNodeIds);

      showSuccessMessage(
        `You have successfully disconnected Cause Box ”${
          node.data?.label ?? ''
        }”`
      );
    }
  };

  const beginSelecting = () => {
    setViewState(ConnectionsPanelViewState.select);
    setCurrentNodeIds(selectedNodeIds);
  };

  const selectableNodes = useMemo(
    () =>
      nodes
        .filter((x) => {
          const isThisNode = x.id === focusedNode.id;
          const matchesSearch =
            isNullOrEmpty(search.value) ||
            x.data?.label?.toLowerCase().includes(search.value!.toLowerCase());
          const isAncestor = ancestors.findIndex((a) => a.id === x.id) !== -1;
          const isDescendant =
            descendants.findIndex((d) => d.id === x.id) !== -1;

          return !isThisNode && matchesSearch && !isAncestor && !isDescendant;
        })
        .sort((a, b) => {
          if (sortBy.direction === SortDirection.asc) {
            return a.data?.label.localeCompare(b.data?.label ?? '');
          } else {
            return b.data?.label.localeCompare(a.data?.label ?? '');
          }
        }),
    [
      ancestors,
      descendants,
      focusedNode.id,
      nodes,
      search.value,
      sortBy.direction,
    ]
  );

  useEffect(() => {
    if (chainItem != null) {
      const nodeIds =
        chainItem.linkedToChainItem?.map((x) => x.chainItemId) ?? [];
      setSelectedNodeIds(nodeIds);
      setCurrentNodeIds(nodeIds);

      if (!hasLoaded.current) {
        if (nodeIds.length === 0) {
          setViewState(ConnectionsPanelViewState.empty);
        } else {
          setViewState(ConnectionsPanelViewState.listSelected);
        }

        hasLoaded.current = true;
      }
    }
  }, [chainItem]);

  const confirmSelection = async () => {
    if (await saveNodes(currentNodeIds)) {
      showSuccessMessage(
        `You have successfully connected Cause Box ”${
          focusedNode.data?.label ?? ''
        }” to ${currentNodeIds.length} other cause ${
          currentNodeIds.length === 1 ? 'box' : 'boxes'
        }`
      );
      search.set('');
      setViewState(ConnectionsPanelViewState.listSelected);

      dispatch(resetFocus());
    }
  };

  const cancelSelection = () => {
    setCurrentNodeIds(selectedNodeIds);

    if (selectedNodeIds == null || selectedNodeIds.length === 0) {
      setViewState(ConnectionsPanelViewState.empty);
      return;
    }
    setViewState(ConnectionsPanelViewState.listSelected);
  };

  const canContinue = useMemo(() => {
    return (
      !isLoading &&
      JSON.stringify(currentNodeIds) !== JSON.stringify(selectedNodeIds)
    );
  }, [currentNodeIds, isLoading, selectedNodeIds]);

  return {
    selectableNodes,
    selectedNodes,
    isSelected,
    toggleSelected,
    viewState,
    beginSelecting,
    search,
    sortBy,
    sortByOptions,
    confirmSelection,
    cancelSelection,
    canContinue,
    removeSelectedNode,
    isBusy,
    isLoading,
    isFetching,
    canContribute,
  };
}
