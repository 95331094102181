import { ComponentType } from 'react';
import { styled } from '@mui/material';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';

export interface SmallFormProps<T> extends UiPopupProps<T> {}

const StyledPopup = styled('div')<{ show?: boolean }>(({ show }) => ({
  background: 'white',
  width: 500,
  position: 'fixed',
  right: 30,
  bottom: 30,
  borderRadius: 10,

  boxShadow: show
    ? '0px 10px 44px 0px #00000040'
    : '0px 10px 44px 0px #00000000',
  transform: `translateY(${show ? '0' : '100%'})`,
  transition: 'transform 300ms, box-shadow 300ms',
  transitionTimingFunction: 'ease-in-out',
}));

export default function withSmallForm<P extends SmallFormProps<T>, T = any>(
  Component: ComponentType<P>
) {
  return (props: P) => {
    const { show } = props;

    return (
      <StyledPopup show={show}>
        <Component {...props} />
      </StyledPopup>
    );
  };
}
