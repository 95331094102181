import Row from '@components/layout-util-components/row';
import { styled } from '@mui/material';

interface Props {
  condition?: string;
}

const Container = styled(Row)(({ theme: { typography, palette } }) => ({
  alignSelf: 'stretch',
  gap: 20,
  padding: '0 20px',
  '.divider': {
    flex: '1 0 auto',
    borderBottom: `1px solid ${palette.tabs.background}`,
  },
  '.condition': {
    ...typography.body1,
    color: palette.common.grey60,
  },
}));

export default function ConditionDivider({ condition = 'Or' }: Props) {
  return (
    <Container>
      <div className="divider" />
      <div className="condition">{condition}</div>
      <div className="divider" />
    </Container>
  );
}
