import { PaginatedResult } from '@api/types/paginated-result';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { HeaderItem, WCTTable } from '@components/table';
import TaskRow from '@pages/app/tasks/components/task-row';
import { useMemo } from 'react';
import { PaginationState } from '@components/table/hooks/use-pagination-hook';

interface Props {
  data?: PaginatedResult<TaskDetailListItemResponse>;
  displayCaseName: boolean;
  isLoading: boolean;
  pagination?: PaginationState;
}

export default function TasksTableData({
  data,
  displayCaseName,
  isLoading,
  pagination,
}: Props) {
  const headerItems = useMemo(() => {
    const ret: HeaderItem[] = [];

    ret.push({ title: <></> });
    ret.push({ title: 'Title' });
    if (displayCaseName) {
      ret.push({ title: 'Analysis' });
    } else {
      ret.push({ title: 'Cause Box' });
    }
    ret.push({ title: 'Due Date' });
    ret.push({ title: 'Assigned' });
    ret.push({ title: <></> });

    return ret;
  }, [displayCaseName]);

  return (
    <WCTTable
      isLoading={isLoading}
      count={data?.totalCount ?? 0}
      pagination={pagination}
      headers={headerItems}
    >
      {data?.model.map((task) => (
        <TaskRow
          key={task.taskDetailId}
          task={task}
          displayCaseName={displayCaseName}
        />
      ))}
    </WCTTable>
  );
}
