import {
  useDeleteAllNotificationsMutation,
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
} from '@api/endpoints/notification.api';
import { LinkButton } from '@components/buttons';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import Column from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import NotificationEntry from '@components/notifications/notification-entry';
import { InlineBoxSkeleton } from '@components/skeletons';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import {
  DrawerContent,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHideConflictingPanels, {
  SingleUsePanel,
} from '@hooks/rca/use-hide-conflicting-panels-hook';
import useLoadMore from '@hooks/use-load-more-hook';
import { Box, Button, styled } from '@mui/material';
import { setNotificationsOpen } from '@store/notifications/notifications-slice';
import { useAppDispatch } from '@store/store';
import { useEffect, useMemo, useRef } from 'react';

interface Props extends SideDrawerFormProps<never> {
  show: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  toggleButtonRef?: React.RefObject<HTMLDivElement>;
}

const Container = styled(Column)(({ theme: { palette } }) => ({
  zIndex: 100,
  cursor: 'default',

  '.counter': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: palette.primary.main,
    width: '1.25rem',
    height: '1.25rem',
    fontSize: '0.9rem',
    borderRadius: '50%',
    color: 'white',
  },
}));

const NotificationsSidePanel: React.FC<Props> = ({
  show,
  onClickOutside,
  toggleButtonRef,
}) => {
  const dispatch = useAppDispatch();
  const panelRef = useRef<HTMLDivElement>(null);
  const { showConfirmationModal } = useUiPopup();

  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation();
  const [deleteAllNotifications] = useDeleteAllNotificationsMutation();
  const [deleteNotifications, { isLoading: isDeleting }] =
    useDeleteNotificationsMutation();

  useHideConflictingPanels(SingleUsePanel.notifications, show);

  const displayFetchingIndicator = isDeleting;

  const { skip, take, canLoadMore, loadMore } = useLoadMore(25);
  const { data, isLoading, isError } = useGetNotificationsQuery(
    {
      skip,
      take,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const unreadCount = useMemo(() => {
    if (data == null) {
      return 0;
    }

    return data.model.filter((n) => !n.read).length;
  }, [data]);

  const unreadIds = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.model.filter((n) => !n.read).map((x) => x.notificationId);
  }, [data]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (unreadIds.length > 0) {
      setTimeout(() => {
        markNotificationsAsRead({ notificationIds: unreadIds })
          .unwrap()
          .catch((e) => console.log('mark notifications as read error: ', e));
      }, 3000);
    }
  }, [markNotificationsAsRead, show, unreadIds]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node) &&
        toggleButtonRef?.current !== event.target &&
        !toggleButtonRef?.current?.contains(event.target as Node)
      ) {
        if (onClickOutside) {
          onClickOutside(event);
        }
      }
    };

    if (show) {
      // Use a small timeout to ensure the click that opened the panel doesn't immediately close it
      setTimeout(() => {
        document.addEventListener('mousedown', handleClickOutside);
      }, 0);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClickOutside, toggleButtonRef]);

  const deleteNotification = (notificationId: number) => {
    deleteNotifications({ notificationIds: [notificationId] })
      .unwrap()
      .catch((e) => console.log('delete notifications as read error: ', e));
  };

  const deleteAllNotificationForUser = async () => {
    await showConfirmationModal({
      title: 'Clear all notifications',
      message: 'Are you sure you would like to clear all notifications?',
      yesButtonLabel: 'Yes',
      noButtonLabel: 'No',
      yesButtonAsyncAction: async () => {
        return deleteAllNotifications()
          .unwrap()
          .then(() => true)
          .catch((e) => {
            console.log('delete notifications as read error: ', e);
            return false;
          });
      },
    });
  };

  return (
    <Container ref={panelRef} style={{ height: '100%' }}>
      <DrawerHeader
        onClose={(_) => {
          dispatch(setNotificationsOpen(false));
        }}
      >
        <Row>
          <span>Notifications</span> <Gap size={15} />
          <span>
            {isLoading ? (
              <InlineBoxSkeleton width={20} />
            ) : unreadCount > 0 ? (
              <div className="counter">{unreadCount}</div>
            ) : null}
          </span>
          {!isLoading && !isError && data!.model && data!.model.length > 0 && (
            <Button onClick={deleteAllNotificationForUser}>Clear All</Button>
          )}
          <Gap size={8} />
          <FetchingIndicator show={displayFetchingIndicator} />
        </Row>
      </DrawerHeader>
      <DrawerContent>
        {isLoading || isError ? (
          <ListContainerSkeleton count={5} />
        ) : data!.totalCount === 0 ? (
          <>
            <Gap size={50} />
            <OnboardingEntryContainer
              icon={<FontAwesomeIcon icon={faBell} size="2x" />}
              title="No notifications"
              message="You currently have no notifications"
              backgroundColor
            />
          </>
        ) : (
          <>
            {!isError &&
              data!.model.map((notification) => (
                <NotificationEntry
                  key={notification.notificationId}
                  notification={notification}
                  deleteNotification={deleteNotification}
                />
              ))}
            <Gap size={20} />
            <Box alignSelf="center">
              {!isError && canLoadMore(data!.totalCount) && (
                <LinkButton onClick={loadMore}>Load more</LinkButton>
              )}
            </Box>
            <Gap size={20} />
          </>
        )}
        <Gap size={50} />
      </DrawerContent>
    </Container>
  );
};

export default withSideDrawer(NotificationsSidePanel, {
  allowTapOutside: true,
});
