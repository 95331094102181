import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import useCaseFileManager, {
  CaseFileManagerViewState,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import { Box, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faSquare,
  faSquareCheck,
} from '@fortawesome/pro-light-svg-icons';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import CaseFileManagerListView from '@pages/app/rca/tabs/components/modals/case-file-manager/views/case-file-manager-list-view';
import CaseFileManagerUploadView from './views/case-file-manager-upload-view';
import CaseFileManagerLinkView from '@pages/app/rca/tabs/components/modals/case-file-manager/views/case-file-manager-link-view';
import { Gap } from '@components/layout-util-components/gap';

export interface CaseFileManagerProps
  extends ModalProps<Array<CaseFileListItemResponse>> {
  allowSelector?: boolean;
  preselectedFileIds?: Array<number>;
}

const Container = styled(Column)(({ theme: { palette } }) => ({
  minWidth: 600,

  '.option-container': {
    display: 'flex',
    gap: '30px',

    button: {
      cursor: 'pointer',
      flexGrow: 1,
      maxWidth: 'calc(50% - 15px)',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '12px',
      outline: 'none',
      borderRadius: '8px',
      border: '1px solid #00000040',
      padding: '15px 20px',
      background: palette.common.white,

      svg: {
        width: 30,
        height: 30,
      },

      '&.selected': {
        border: '4px solid #000000BF',
        padding: '12px 17px',
      },
    },
  },
}));

function CaseFileManagerModal({
  onClose,
  allowSelector = true,
  preselectedFileIds,
}: CaseFileManagerProps) {
  const state = useCaseFileManager(allowSelector, preselectedFileIds);
  const {
    viewState,
    canGoBackToFileSelect,
    isUpload,
    setIsUpload,
    goBackToList,
  } = state;

  return (
    <Container>
      {viewState === CaseFileManagerViewState.list ? (
        <CaseFileManagerListView state={state} onClose={onClose} />
      ) : (
        <>
          <Row>
            {canGoBackToFileSelect ? (
              <>
                <FlatButton shape="circle" onClick={goBackToList}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </FlatButton>
                <Gap size={20} />
              </>
            ) : null}
            <Typography variant="h1">Add new file </Typography>
          </Row>
          <Gap size={20} />
          <Typography variant="body1">
            How would you like to add a new file?
          </Typography>
          <Gap size={10} />
          <div className="option-container">
            <button
              type="button"
              onClick={() => setIsUpload(true)}
              className={isUpload === true ? 'selected' : undefined}
            >
              <FontAwesomeIcon
                icon={isUpload === true ? faSquareCheck : faSquare}
              />
              <Typography variant="body1">Upload file from desktop</Typography>
            </button>
            <button
              type="button"
              onClick={() => setIsUpload(false)}
              className={isUpload === false ? 'selected' : undefined}
            >
              <FontAwesomeIcon
                icon={isUpload === false ? faSquareCheck : faSquare}
              />
              <Typography variant="body1">Add file location</Typography>
            </button>
          </div>
          <Gap size={15} />
          {isUpload === true ? (
            <CaseFileManagerUploadView state={state} onClose={onClose} />
          ) : isUpload === false ? (
            <CaseFileManagerLinkView state={state} onClose={onClose} />
          ) : (
            <Box width={149} flexShrink={0} />
          )}
        </>
      )}
    </Container>
  );
}

export default withModal(CaseFileManagerModal);
