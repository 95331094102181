import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import SubpageTitle from '@components/menu-items/subpage-title';
import { ColumnForm } from '@components/layout-util-components/column';
import WCTSelectField from '@components/input/select-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import { PrimaryButton } from '@components/buttons';
import { InputSkeleton } from '@components/skeletons';
import useCompanyPreferences from '@pages/app/company-profile/company-preferences-hook';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import { Gap } from '@components/layout-util-components/gap';
import useActionFooter from '@hooks/use-action-footer-hook';

function CompanyPreferencesPage() {
  const footerStyle = useActionFooter();
  const { t } = useAppTr('companyPreferences');

  const {
    language,
    languageOptions,
    timezoneOptions,
    timezone,
    dateFormatOptions,
    dateFormat,
    submitPreferences,
    isSubmitting,
    timeDisplayOptions,
    canSubmit,
    isLoading,
    twentyFourHourClock,
    dateExample,
  } = useCompanyPreferences();

  return (
    <ColumnForm
      onSubmit={(e) => {
        e.preventDefault();
        submitPreferences();
      }}
    >
      <SubpageTitle
        breadCrumb={t('breadCrumb.area')}
        title="Date & Time Settings"
      />
      <Gap size={32} />
      {/* <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('language.title')}</Typography>
          <Typography variant="body2">{t('language.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="language"
              name="language"
              options={languageOptions!}
              value={language.value}
              error={language.error}
              onChange={(v) => language.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin /> */}
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('dateDisplay.title')}</Typography>
          <Typography variant="body2">{t('dateDisplay.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="dateDisplay"
              name="dateDisplay"
              options={dateFormatOptions!}
              value={dateFormat.value}
              error={dateFormat.error}
              onChange={(v) => dateFormat.set(v as number)}
              helperText={t('dateDisplay.helper', {
                formattedDate: dateExample,
              })}
            />
          )}
        </Grid>
      </Grid>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('timezone.title')}</Typography>
          <Typography variant="body2">{t('timezone.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="timezone"
              name="timezone"
              options={timezoneOptions!}
              value={timezone.value}
              error={timezone.error}
              onChange={(v) => timezone.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      <div style={footerStyle}>
        <RefreshCancelButton />
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
          styleOptions={{
            lineHeight: '26px',
          }}
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </div>
    </ColumnForm>
  );
}

export default requireAuth(CompanyPreferencesPage);
