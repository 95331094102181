import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Link, TableCell, TableRow, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import { WCTTable, WCTTableFilters } from '@components/table';
import useFunctionalLocations from '@pages/app/company-profile/functional-locations-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import FunctionalLocationForm, {
  FunctionalLocationFormProps,
} from '@pages/app/company-profile/forms/functional-location-form';
import { CompanyLocationRowResponse } from '@api/types/company/company-location/company-location-row.response';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { LoadingIndicator } from '@components/loading-indicator';
import NoFunctionalLocationInfo from './components/no-functional-locations-info';
import { usePageAlertVariants } from '@components/alerts';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ApiError } from '@api/types/api-error';
import { Gap } from '@components/layout-util-components/gap';
import { useCreateLocationForCompanyMutation } from '@api/endpoints/company-location.api';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import NoFilteredFunctionalLocationInfo from './components/no-filtered-functional-locations-info';
import { truncateString } from '@util/string-util';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageCount from '@components/typography/page-count';

function FunctionalLocationsPage() {
  const { showPopup, showSingleActionModal } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const {
    search,
    data,
    onDeleteCompany,
    sort,
    pagination,
    isLoading,
    isFetching,
    onToggleLocationStatus,
  } = useFunctionalLocations();

  const onAddButtonClicked = () => {
    showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
      }
    );
  };

  const onViewButtonClicked = (id: number) => {
    showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
        functionalLocationId: id,
      }
    );
  };

  const onDeleteLocationClicked = (location: CompanyLocationRowResponse) => {
    if (location.rcaCount > 0) {
      return showSingleActionModal({
        title: 'Cannot delete Company Location',
        message: 'This location has associated RCAs',
      });
    }

    showPopup(DeleteConfirmationModal, {
      title: 'Are you sure you want to delete this location',
      message:
        'Please note you will not be able to restore this location once deleted',
      itemName: 'Location',
      onDelete: async () => {
        try {
          await onDeleteCompany(location.companyLocationId);
          showSuccessMessage('Location deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ groupId: number }>;
          showErrorMessage(errors?.groupId ?? message);
          return false;
        }
      },
    });
  };

  const getRowActions = (row: CompanyLocationRowResponse) => {
    var actions = [
      {
        label: 'View',
        onClick: () => onViewButtonClicked(row.companyLocationId),
      },
    ];

    if (row.rcaCount === 0) {
      actions.push({
        label: 'Delete',
        onClick: () => onDeleteLocationClicked(row),
      });
    }

    return actions;
  };

  return (
    <Column>
      <Row>
        <PageCount
          title="Company Locations"
          isLoading={isLoading}
          count={data?.totalCount}
          variant="h2"
          sx={{ lineHeight: '40px' }}
        />
        <Spacer />
        <OutlinedButton onClick={onAddButtonClicked}>
          Add Company Location
        </OutlinedButton>
      </Row>
      <Typography variant="body1">
        This will be regional or local administrative locations where RCA
        activity is managed from
      </Typography>
      <Gap size={20} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : data!.totalCount === 0 ? (
        <NoFunctionalLocationInfo onCreateClicked={onAddButtonClicked} />
      ) : (
        <>
          <WCTTable
            count={data!.totalCount}
            isLoading={isLoading}
            pagination={pagination}
            filters={
              <WCTTableFilters
                isLoading={isLoading}
                isFetching={isFetching}
                search={search.value}
                onSearch={search.set}
              />
            }
            emptyState={<NoFilteredFunctionalLocationInfo />}
            headers={[
              { title: <></> },
              { title: 'Name', sortProperty: 'name', ...sort },
              { title: 'Description' },
              { title: 'Address', sortProperty: 'address', ...sort },
              { title: 'RCAs', sortProperty: 'rcaCount', ...sort },
              { title: 'Status', sortProperty: 'disabled', ...sort },
              { title: <></> },
            ]}
          >
            {!isLoading
              ? data!.model.map((row: CompanyLocationRowResponse) => (
                  <TableRow key={row.companyLocationId}>
                    <TableCell className="circular_icon_container location">
                      <span className="circular_icon location">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </span>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{row.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {truncateString(row.description, 125)}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      {row.rcaCount > 0 ? (
                        <Link
                          href={`/analysis?incidentLocation=${row.companyLocationId}`}
                        >
                          {row.rcaCount}
                        </Link>
                      ) : (
                        <>{row.rcaCount}</>
                      )}
                    </TableCell>
                    <TableCell className="toggle">
                      <SwitchContainer variant="light">
                        <WCTSwitchField
                          value={row.disabled !== true}
                          label={row.disabled === true ? 'Disabled' : 'Active'}
                          onChange={(isOn) => {
                            onToggleLocationStatus({
                              id: row.companyLocationId,
                              on: isOn,
                            });
                          }}
                        />
                      </SwitchContainer>
                    </TableCell>
                    <TableCell className="action_btn">
                      <ElipsisButton actions={getRowActions(row)} />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </WCTTable>
        </>
      )}
    </Column>
  );
}

export default requireAuth(FunctionalLocationsPage);
