import { ReactNode } from 'react';
import { styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  title: ReactNode;
  count?: number;
  end?: ReactNode;
}

const CountContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: palette.common.grey35,
}));

export default function EditPanelHeader({ title, count, end }: Props) {
  return (
    <Row
      sx={{
        minHeight: 32,
      }}
    >
      <Typography className="panel-header" variant="h3">
        {title}
      </Typography>
      <Gap size={12} />
      {count != null ? <CountContainer>{count}</CountContainer> : null}
      <Spacer />
      {end != null ? end : null}
    </Row>
  );
}
