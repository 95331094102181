import BadgeInfo from '@components/outcome-report/components/badge-info';
import { useTheme } from '@mui/material';

interface Props {
  category: string;
}

export default function ImpactCategoryBadge({ category }: Props) {
  const { palette } = useTheme();
  return (
    <BadgeInfo
      value={category}
      backgroundColor={palette.blue.dark}
      color="#fff"
      height="30px"
      padding="8px 14px"
    />
  );
}
