import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { RegisterInterestRequest } from '@api/types/enterprise/register-interest.request';

const enterpriseApi = api.injectEndpoints({
  endpoints: (build) => ({
    requestAccess: build.mutation<number, RegisterInterestRequest>({
      query: (body) => ({
        url: 'enterprise/requestaccess',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<RegisterInterestRequest>(error),
    }),
  }),
});

export const { useRequestAccessMutation } = enterpriseApi;
