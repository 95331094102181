import { Box, Link, styled, Typography } from '@mui/material';
import AppLogo from '@assets/svgs/app-logo.svg';
import OutcomeEmpty from '@assets/svgs/no-outcomes.svg';
import { PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import { CaseOutcomeAuthorResponse } from '@api/types/case/case-outcome/case-outcome-author.response';
import { getFullName } from '@util/name-util';
import { isNullOrEmpty } from '@util/string-util';

interface Props {
  author: CaseOutcomeAuthorResponse | null;
}

const PageWrapper = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  minWidth: '100vw',
  minHeight: '100vh',
  boxSizing: 'border-box',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 50,
  background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
  backgroundSize: 'cover',
}));

const Logo = styled('div')(({ theme: { palette } }) => ({
  width: 80,
  height: 80,
}));

const ErrorPanel = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 540,
  minEidth: 315,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: palette.common.white,
  borderRadius: 12,
  padding: 40,
  '@media (min-height:600px)': {
    marginBottom: 130,
  },
  p: {
    margin: '5px 0 30px',
    textAlign: 'center',
  },
}));

export default function InvalidPermissionView({ author }: Props) {
  let name = '';
  let companyName = '';

  let displayAuthorInfo = false;
  if (author != null) {
    name = getFullName(author.firstName, author.lastName);
    companyName = author.companyName;
    displayAuthorInfo = !isNullOrEmpty(name) && !isNullOrEmpty(companyName);
  }

  return (
    <PageWrapper>
      <Logo>
        <Link href="/">
          <img className="nav-logo" src={AppLogo} alt="logo" />
        </Link>
      </Logo>
      <ErrorPanel>
        <img className="nav-logo" src={OutcomeEmpty} alt="Outcome" />
        <Gap size={15} />
        <Typography variant="h1">
          You are unable to view this outcome
        </Typography>
        <Typography variant="body1">
          {displayAuthorInfo ? (
            <>
              {`${name} from ${companyName} has shared an outcome that you can't
              currently view.`}
              <br />
              <br />
            </>
          ) : (
            'This might require you to switch companies or login again.\n\nPlease click below to login.'
          )}
        </Typography>
        <Box display="flex" justifyContent="center">
          <PrimaryButton href="/">Login</PrimaryButton>
        </Box>
      </ErrorPanel>
    </PageWrapper>
  );
}
