import OutlinedButton from '@components/buttons/outlined-button';

interface Props {
  label?: string;
}

export default function RefreshCancelButton({ label }: Props) {
  return (
    <OutlinedButton onClick={() => window.location.reload()}>
      {label ?? 'Cancel'}
    </OutlinedButton>
  );
}
