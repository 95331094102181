import { IKudos } from '@api/types/user/kudos';
import { dateOnlyLongFormatter } from '@i18n/formatters';

export const isKudosItemSameDayAsNext = ({
  item,
  kudos,
  idx,
}: {
  item: IKudos;
  kudos?: IKudos[];
  idx: number;
}) => {
  if (!kudos) {
    return false;
  }

  const nextKudos = kudos[idx - 1];

  if (!nextKudos) {
    return false;
  }

  if (
    dateOnlyLongFormatter(item.created) ===
    dateOnlyLongFormatter(nextKudos.created)
  ) {
    return true;
  }

  return false;
};
