import { ReactNode, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface ActionDivider {
  divider?: boolean;
}

interface ActionButton {
  label: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  hasPermission?: boolean;
}

export type PopoverMenuAction = ActionDivider | ActionButton;

export default function usePopoverMenu() {
  const [id] = useState(uuid());
  const [target, setTarget] = useState<HTMLElement>();
  const actionsRef = useRef<Array<PopoverMenuAction>>([]);
  const [isShown, setIsShown] = useState(false);

  const open = (actions: Array<PopoverMenuAction>) => {
    actionsRef.current = actions;
    setIsShown(true);
  };

  const close = () => {
    actionsRef.current = [];
    setIsShown(false);
  };

  const ref = (element: HTMLElement | null) => {
    if (element == null) {
      setTarget(undefined);
    } else {
      setTarget(element!);
    }
  };

  return {
    id,
    ref,
    isShown,
    actionsRef,
    open,
    close,
    target,
  };
}

export type PopoverMenuState = ReturnType<typeof usePopoverMenu>;
