import requireAuth from '@components/hoc/with-required-auth-hoc';
import { useLocation, useNavigate } from 'react-router-dom';
import useMfa from '@pages/auth/mfa-hook';
import { useAppDispatch } from '@store/store';
import { refreshUserState } from '@store/user/user-slice';
import { Box, Grid, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { LinkButton, PrimaryButton } from '@components/buttons';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { LoadingIndicator } from '@components/loading-indicator';
import { MfaMethod } from '@api/types/auth/mfa-method';
import { useAuthTr } from '@i18n/use-auth-tr';
import { ErrorAlert, PageAlertDisplay } from '@components/alerts';
import MfaRadioGroup from '@components/mfa/mfa-radio-group';
import { datetimeShortFormatter } from '@i18n/formatters';
import { Gap } from '@components/layout-util-components/gap';

export interface MfaPageLocationState {
  returnTo?: string;
}

function MfaPage() {
  const { t } = useAuthTr('mfa');

  const {
    isFetchingCode,
    code,
    method,
    setMethod,
    isLoading,
    submit,
    canSubmit,
    resendCode,
    mfaOptions,
    hasExceededLimit,
  } = useMfa();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnTo } = (state || {}) as MfaPageLocationState;

  const onSubmit = async () => {
    if (await submit()) {
      dispatch(refreshUserState());
      navigate(returnTo || '/');
    }
  };

  return (
    <Grid
      container
      direction="column"
      component="form"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h1">{t('title')}</Typography>
      <Gap size={4} />
      <PageAlertDisplay />
      <Gap size={20} />
      {isLoading ? (
        <Box alignSelf="center">
          <LoadingIndicator show />
        </Box>
      ) : mfaOptions != null &&
        mfaOptions.disabled &&
        mfaOptions.disabledUntil != null ? (
        <ErrorAlert
          title="Unable to request code"
          message={`Code request is disabled until ${datetimeShortFormatter(
            mfaOptions.disabledUntil
          )}. <a href='/auth/logout'>Logout</a>`}
        />
      ) : method == null ? (
        <MfaRadioGroup mfaOptions={mfaOptions} onOptionSelected={setMethod} />
      ) : (
        <>
          <Typography>
            {t('otpSent.message', {
              method:
                method === MfaMethod.email
                  ? mfaOptions!.email
                  : mfaOptions!.phoneNumber,
            })}
          </Typography>
          <Gap size={15} />

          <WCTTextField
            placeholder={t('otpSent.placeholder')}
            value={code.value}
            error={code.error}
            onChange={code.set}
            maxLength={6}
            readonly={hasExceededLimit}
          />
          <Gap size={10} />
          <Box alignSelf="flex-end">
            <LinkButton disabled={isFetchingCode} onClick={resendCode}>
              {t('otpSent.resendButtonLabel')}
            </LinkButton>
          </Box>
          <Gap size={20} />

          <PrimaryButton
            type="submit"
            disabled={!canSubmit || hasExceededLimit}
            isBusy={isLoading}
            size="large"
          >
            {t('otpSent.loginButtonLabel')}
          </PrimaryButton>
        </>
      )}
      <Gap size={32} />

      <ContactSupportLink />
    </Grid>
  );
}

export default requireAuth(MfaPage, { mfaRequired: false });
