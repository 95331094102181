import { CardLayout } from '@components/layout-util-components/card-layout';
import { AnalysisOverviewState } from '@pages/app/analysis/analysis-overview-hook';
import RcaCard from '@components/cards/rca-card';

export default function AnalysisGridView({ caseData }: AnalysisOverviewState) {
  const data = caseData!.model;

  return (
    <CardLayout>
      {data.map((rca) => (
        <RcaCard key={rca.caseId} data={rca} afterClickRedirect={() => {}} />
      ))}
    </CardLayout>
  );
}
