import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { Permission } from '@api/types/permission';
import { usePermissions } from '@store/user/permission-hook';

interface Props {
  onCreateClicked: () => void;
}

export default function NoGroupsInfo({ onCreateClicked }: Props) {
  const canCreate = usePermissions([
    Permission.createMyGroups,
    Permission.manageCompanyGroups,
  ]);

  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
      title="You don’t currently have any groups"
      message={
        canCreate
          ? 'There aren’t currently any groups within the platform, you can create one below.'
          : undefined
      }
      createMessage={canCreate ? 'Create Group' : undefined}
      onCreateClicked={canCreate ? onCreateClicked : undefined}
    />
  );
}
