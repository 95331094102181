import { Skeleton, styled } from '@mui/material';
import CircleContainer from '@components/containers/circle-container';
import { getFullName, getInitials } from '@util/name-util';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import { DefaultTFuncReturn } from 'i18next';

export interface InitialsContainerWithDataProps {
  label?: string | DefaultTFuncReturn;
  initials?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  subLabel?: string;
  loading?: boolean;
  avatarSize?: 32 | 45 | 42 | 40;
}

const Container = styled('div', { target: 'initials-container-with-data' })({
  display: 'inline-flex',
  gap: 10,
  alignItems: 'center',
});

const NameContainer = styled('div', { target: 'name-container' })({
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled('span', { target: 'title' })({
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 12,
  color: 'black',
});

const Label = styled('span', { target: 'label' })({
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: 12,
  color: 'black',
});

export default function InitialsContainerWithData({
  label,
  initials,
  fullName,
  firstName,
  lastName,
  subLabel,
  loading,
  avatarSize,
}: InitialsContainerWithDataProps) {
  const displayFullName = fullName || getFullName(firstName, lastName);
  const subLabelText = subLabel || displayFullName;

  return (
    <Container>
      {loading ? (
        <>
          <Skeleton variant="circular" width={32} height={32} />
          <LabelValueSkeleton />
        </>
      ) : (
        <>
          <CircleContainer size={avatarSize || 32} light>
            {initials || getInitials(displayFullName)}
          </CircleContainer>
          {(label || subLabelText) && (
            <NameContainer>
              {label ? <Title>{label}</Title> : null}
              <Label className="subtitle">{subLabelText}</Label>
            </NameContainer>
          )}
        </>
      )}
    </Container>
  );
}
