import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { Box, styled, Typography } from '@mui/material';
import { HeaderComponent } from './complete-rca-components.styled';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import useCompleteRcaModal, {
  CompleteRcaModalPage,
} from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal-hook';
import RatingPage from '@pages/app/rca/tabs/components/complete-rca/rating-page';
import CollaboratorKudos from '@pages/app/rca/tabs/components/complete-rca/collaborator-kudos';
import FlatBadge from '@components/badges/flat-badge';
import { Gap } from '@components/layout-util-components/gap';
import CompleteIcon from '@assets/svgs/complete-rca.svg';
import { useNavigate } from 'react-router-dom';

export interface CompleteRcaModalProps extends ModalProps<never> {}

const Container = styled('div')({
  width: 658,
  minHeight: 538,

  button: {
    minWidth: 125,
    '& + button': {
      marginLeft: '0.75rem',
    },
  },

  '.earnedPoints': {
    '.flat-badge': {
      background: '#CDF1F3',
      color: '#00223A',
      height: 'auto',
      fontSize: '15px',
      padding: '10px 15px 11px',
    },
  },
});

const CompleteRcaModal = ({ onClose }: CompleteRcaModalProps) => {
  const state = useCompleteRcaModal();
  const navigate = useNavigate();
  const { page, setPage, isCaseOwner, setRatingAndMoveNext, currentQuestion } =
    state;

  switch (page) {
    case CompleteRcaModalPage.initial:
      return (
        <Container>
          <Column>
            <HeaderComponent>
              <div className="icon-container">
                <img src={CompleteIcon} alt="icon" />
              </div>
            </HeaderComponent>
            <Column
              sx={{ padding: '43px 91px 31px 91px', textAlign: 'center' }}
              noStretch
            >
              <Typography variant="h1">Mark Analysis as Complete</Typography>
              <Gap size={15} />
              <Typography variant="body1">
                Please note you will be able to re-open this RCA at a later date
                if required. Click Continue to complete a short survey and award
                kudos points to your RCA team.
              </Typography>
              <Spacer />
              <ActionsRow center anchorBottom addPadding>
                <OutlinedButton onClick={() => onClose()}>
                  Cancel
                </OutlinedButton>
                <PrimaryButton
                  onClick={() => setPage(CompleteRcaModalPage.questions)}
                >
                  Continue
                </PrimaryButton>
              </ActionsRow>
            </Column>
          </Column>
        </Container>
      );
    case CompleteRcaModalPage.questions:
      return (
        <Container>
          <RatingPage
            state={state}
            onCancel={() => onClose()}
            onNext={setRatingAndMoveNext}
          />
        </Container>
      );
    case CompleteRcaModalPage.teamPerformance:
      return (
        <Container>
          <CollaboratorKudos state={state} onClose={() => onClose()} />
        </Container>
      );
    case CompleteRcaModalPage.complete:
      return (
        <Container>
          <Column>
            <HeaderComponent>
              <div className="icon-container">
                <img src={CompleteIcon} alt="icon" />
              </div>
            </HeaderComponent>
            <Column
              sx={{
                padding: '30px 50px 30px 50px',
                textAlign: 'center',
                '.flat-badge': {
                  background: '#3CA680',
                  color: '#fff',
                },
              }}
              noStretch
            >
              <Typography variant="h1">
                Thank you for completing this RCA
              </Typography>
              <Gap size={15} />
              <Typography variant="body1">
                Each RCA report makes a valuable and immediate contribution to
                our organisation. RCA activity in all of its forms enables us to
                solve problems, manage risk and maximise performance in an
                evidence-based and sustainable way.
              </Typography>
              {isCaseOwner ? (
                <>
                  <Gap size={15} />
                  <Box className="earnedPoints">
                    <FlatBadge>
                      You have earned 50pts as the RCA owner
                    </FlatBadge>
                  </Box>
                </>
              ) : null}
              <Spacer />
              <ActionsRow center anchorBottom addPadding>
                <OutlinedButton
                  onClick={() => {
                    navigate('/profile/kudos');
                    onClose();
                  }}
                >
                  View Kudos Points
                </OutlinedButton>
                <PrimaryButton
                  onClick={() => {
                    navigate('/');
                    onClose();
                  }}
                >
                  Back to Dashboard
                </PrimaryButton>
              </ActionsRow>
            </Column>
          </Column>
        </Container>
      );
  }
};

export default withModal(CompleteRcaModal, {
  blurBackdrop: true,
  noPadding: true,
});
