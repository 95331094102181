import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

export default function NoGroupsInfo() {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
      title="You don’t currently have any groups"
    />
  );
}
