import { Box, FormHelperText, styled, Typography } from '@mui/material';
import { Option } from '@api/types/option';
import RadioSelectedIcon from '@assets/svgs/radio-selected.svg';
import RadioUnselectedIcon from '@assets/svgs/radio-unselected.svg';
import { DefaultTFuncReturn } from 'i18next';
import { Gap } from '@components/layout-util-components/gap';

interface Props<T> {
  label?: string | DefaultTFuncReturn;
  error?: string;
  options: Array<Option<T>>;
  value: T;
  onChange: (value: T) => void;
}

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  maxWidth: 450,
}));

const StyledIndicator = styled('img')<{
  isSelected: boolean;
}>(({ isSelected, theme }) => ({
  width: 30,
  height: 30,
  marginRight: 16,
  ...(isSelected && {
    '& svg': {
      color: theme.palette.primary.main,
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  }),
}));

const StyledOption = styled('div')<{
  displayBorder: boolean;
  isSelected: boolean;
}>(({ displayBorder, isSelected, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  borderRadius: 6,
  backgroundColor: 'white',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.common.grey50}`,
  marginBottom: '-1px',
  position: 'relative',
  '&:first-child': {
    borderRadius: '6px 6px 0 0',
  },
  '&:last-child': {
    borderRadius: '0 0 6px 6px',
    marginBottom: 0,
  },
  // ...(displayBorder && {
  //   borderRadius: '6px 6px 0 0',
  //   border: `1px solid ${theme.palette.common.grey50}`,
  // }),
  ...(isSelected && {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'default',
    zIndex: 1,
  }),
}));

export default function RadioSelectGroup<T>({
  options,
  label,
  error,
  onChange,
  value,
}: Props<T>) {
  return (
    <>
      {label ? (
        <>
          <Typography>{label}</Typography>
          <Gap size={15} />
        </>
      ) : null}
      <StyledContainer>
        {options.map(({ id, label }, index) => {
          const isSelected = id === value;

          return (
            <StyledOption
              key={`${id}`}
              displayBorder={index < options.length - 1}
              isSelected={isSelected}
              onClick={() => onChange(id)}
            >
              <StyledIndicator
                isSelected={isSelected}
                src={isSelected ? RadioSelectedIcon : RadioUnselectedIcon}
                alt="radio option indicator"
              />
              <span>{label}</span>
            </StyledOption>
          );
        })}
      </StyledContainer>
      {error ? (
        <Box mx={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      ) : null}
    </>
  );
}
