import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import useLoadMore from '@hooks/use-load-more-hook';
import { useFilter, useSortableHeader } from '@components/table';
import { useEffect, useMemo, useState } from 'react';
import { Option } from '@api/types/option';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import {
  useGetAllCaseFilesQuery,
  useRemoveCaseFileMutation,
} from '@api/endpoints/case-file.api';
import { useCasePermission } from '@hooks/case/use-case-permission';

export enum CaseFileManagerViewState {
  list,
  newFile,
}

export default function useCaseFileManager(
  allowSelector: boolean = true,
  preselectedFileIds?: Array<number>
) {
  const { canContribute } = useCasePermission();
  const [viewState, setViewState] = useState(
    allowSelector
      ? CaseFileManagerViewState.list
      : CaseFileManagerViewState.newFile
  );
  const [isUpload, setIsUpload] = useState<boolean>();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const [removeFile] = useRemoveCaseFileMutation();

  const fileTypeOptions: Array<Option<CaseFileType>> = [
    { id: CaseFileType.file, label: 'File' },
    { id: CaseFileType.location, label: 'Location' },
  ];

  const sortBy = useSortableHeader({ initialProperty: 'name' });
  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'A to Z', direction: SortDirection.asc },
    { id: 'name', label: 'Z to A', direction: SortDirection.desc },
  ];

  const fileType = useFilter<CaseFileType>();
  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const {
    data: caseFiles,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllCaseFilesQuery(
    {
      caseId: caseId,
      caseFileType: fileType.value,
      searchText: search.value,
      skip,
      take,
      sortBy: sortBy.property,
      sortDirection: sortBy.direction,
    },
    { refetchOnMountOrArgChange: true }
  );

  const canGoBackToFileSelect = useMemo(() => {
    if (!allowSelector) {
      return false;
    }
    return isSuccess && (caseFiles?.totalCount ?? 0) > 0;
  }, [allowSelector, caseFiles?.totalCount, isSuccess]);

  useEffect(() => {
    if (isSuccess && caseFiles?.totalCount === 0) {
      setViewState(CaseFileManagerViewState.newFile);
    }
  }, [caseFiles?.totalCount, isSuccess]);

  const gotoAddNew = () => {
    setViewState(CaseFileManagerViewState.newFile);
  };

  const goBackToList = () => {
    setViewState(CaseFileManagerViewState.list);
  };

  return {
    caseFiles,
    search,
    canGoBackToFileSelect,
    fileType,
    isLoading,
    loadMore,
    viewState,
    fileTypeOptions,
    gotoAddNew,
    goBackToList,
    removeFile,
    canLoadMore: canLoadMore(caseFiles?.totalCount),
    isUpload,
    setIsUpload,
    isFetching,
    sortBy,
    sortByOptions,
    caseId,
    preselectedFileIds,
    canContribute,
  };
}

export type CaseFileManagerState = ReturnType<typeof useCaseFileManager>;
