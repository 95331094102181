import {
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  useDeleteChainItemSolutionMutation,
  useGetChainItemSolutionsQuery,
} from '@api/endpoints/chain/chain-item-solution.api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChainItemSolutionResource } from '@api/types/chain/chain-item-solution/chain-item-solution.resource';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { DeleteChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/delete-chain-item-solution.request';
import caseApi from '@api/endpoints/case.api';
import { CaseSolutionResource } from '@api/types/case/case-solution/case-solution.resource';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useGetChainItemQuery } from '@api/endpoints/chain/chain-item.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export enum SolutionsPanelViewState {
  empty,
  view,
  create,
  createFromCaseSolution,
  createOptions,
  edit,
}

export default function useSolutionsPanel() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const { canContribute } = useCasePermission();

  const [viewState, setViewState] = useState<SolutionsPanelViewState>(
    defaultToCreate
      ? SolutionsPanelViewState.createOptions
      : SolutionsPanelViewState.empty
  );

  const isLoaded = useRef(false);
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const chainId = useAppSelector(selectChainId)!;
  const focusedNode = useAppSelector(selectFocusedNode);
  const chainItemId = focusedNode?.data?.chainItemId;

  const [editingSolution, setEditingSolution] =
    useState<ChainItemSolutionResource>();
  const [selectedCaseSolution, setSelectedCaseSolution] =
    useState<CaseSolutionResource>();

  const { data: chainItemDetail, isLoading: loadingChainItemDetail } =
    useGetChainItemQuery(
      { chainId, chainItemId: chainItemId ?? -1 },
      { skip: chainItemId == null }
    );

  const {
    data: solutions,
    isLoading: loadingSolutions,
    isSuccess,
    isFetching,
  } = useGetChainItemSolutionsQuery(
    { chainId: chainId ?? -1, chainItemId: chainItemId ?? -1 },
    { skip: !chainId || !chainItemId }
  );

  const [deleteSolution] = useDeleteChainItemSolutionMutation();

  const totalCount = solutions?.totalCount ?? 0;
  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (!isLoaded.current) {
      if (defaultToCreate) {
        setViewState(SolutionsPanelViewState.createOptions);
        return;
      }
    }

    if (totalCount > 0) {
      setViewState(SolutionsPanelViewState.view);
    } else {
      setViewState(
        defaultToCreate
          ? SolutionsPanelViewState.createOptions
          : SolutionsPanelViewState.empty
      );
    }

    isLoaded.current = true;
  }, [totalCount, isSuccess, defaultToCreate]);

  const [onDelete, unlinking] = useBusyAction(
    (solution: ChainItemSolutionResource) => {
      return deleteSolution({
        chainId: chainId!,
        solutionId: solution.chainItemSolutionId,
      })
        .unwrap()
        .then(async () => {
          await invalidation('Solution', solution.chainItemSolutionId);
          showSuccessMessage('Solution deleted');
          return true;
        })
        .catch(
          ({ errors, message }: ApiError<DeleteChainItemSolutionRequest>) => {
            showErrorMessage(errors?.chainId ?? errors?.solutionId ?? message);
            return false;
          }
        );
    }
  );

  const isWorking = unlinking;
  const isLoading =
    chainItemDetail == null ||
    loadingChainItemDetail ||
    loadingSolutions ||
    isWorking;
  const isEditing = editingSolution != null;

  const startCreateFlow = useCallback(() => {
    if (isWorking) return;
    setViewState(SolutionsPanelViewState.createOptions);
  }, []);

  const createNewSolution = useCallback(() => {
    setEditingSolution(undefined);
    setSelectedCaseSolution(undefined);
    setViewState(SolutionsPanelViewState.create);
  }, []);

  const editSolution = useCallback(
    async (solution: ChainItemSolutionResource) => {
      setEditingSolution(solution);

      try {
        const caseSolution = await dispatch(
          caseApi.endpoints.getCaseSolutionById.initiate({
            caseId,
            solutionId: solution.solutionId,
          })
        ).unwrap();
        setSelectedCaseSolution(caseSolution);
      } catch (error) {
        showErrorMessage('Failed to load solution');
      }

      setViewState(SolutionsPanelViewState.edit);
    },
    [caseId, dispatch, showErrorMessage]
  );

  const createNewFromCaseSolution = useCallback(
    (solution: CaseSolutionResource) => {
      setSelectedCaseSolution(solution);
      setViewState(SolutionsPanelViewState.createFromCaseSolution);
    },
    []
  );

  const updateCaseSolution = useCallback((solution: CaseSolutionResource) => {
    // Updating will simply be setting the solution and going back to the 'create' step for creating
    // a case solution.
    setSelectedCaseSolution(solution);
    setViewState(SolutionsPanelViewState.create);
  }, []);

  const cancelCreateNewSolution = useCallback(() => {
    // If this was set, then the user chose to edit the case solution
    // during the form that ties to the cause box solution.
    if (selectedCaseSolution && viewState === SolutionsPanelViewState.create) {
      setViewState(SolutionsPanelViewState.createFromCaseSolution);
    } else if (totalCount > 0) {
      setViewState(SolutionsPanelViewState.view);
    } else {
      setViewState(SolutionsPanelViewState.empty);
    }
  }, [selectedCaseSolution, totalCount, viewState]);

  const gotoViewList = useCallback(() => {
    setSelectedCaseSolution(undefined);
    setViewState(SolutionsPanelViewState.view);
  }, []);

  return {
    solutions,
    isLoading,
    viewState,
    startCreateFlow,
    createNewSolution,
    updateCaseSolution,
    cancelCreateNewSolution,
    caseId,
    chainItemId,
    gotoViewList,
    createNewFromCaseSolution,
    selectedCaseSolution,
    chainId,
    editSolution,
    editingSolution,
    onDelete,
    isEditing,
    canContribute,
    isFetching,
    solutionNa: chainItemDetail?.solutionNa,
    isWorking,
  };
}

export type SolutionsPanelState = ReturnType<typeof useSolutionsPanel>;
