import { isApp } from '@util/env';
import { useEffect, useRef } from 'react';
import usePostLoginActions from '@hooks/use-post-login-actions-hook';
import useSharedState from '@store/shared-state-hook';

export default function usePostLogin() {
  const hasTriggered = useRef(false);

  const { showOnboarding } = usePostLoginActions();

  const { isLoading: loadingSharedState, userPreferences } = useSharedState();
  const isLoading = loadingSharedState;

  useEffect(() => {
    if (isLoading || !isApp || isLoading || hasTriggered.current) {
      return;
    }

    hasTriggered.current = true;

    (async function () {
      const willShowOnboarding =
        isApp && !userPreferences.getBool('FirstLogin');
      if (willShowOnboarding) {
        await showOnboarding();
      }
    })();
  }, [isLoading, showOnboarding, userPreferences]);
}
