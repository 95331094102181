import { CaseResource } from '@api/types/case/case.resource';
import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, Typography, styled } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildings, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { getAddressString, isNullOrEmpty } from '@util/string-util';
import Row from '@components/layout-util-components/row';
import {
  dateOnlyLongFormatter,
  dateOnlyShortFormatter,
  datetimeLongFormatter,
  datetimeShortFormatter,
  getDurationString,
  timeOnlyShortFormatter,
} from '@i18n/formatters';
import useSystemText from '@hooks/use-system-text';
import { Gap } from '@components/layout-util-components/gap';
import { useGetCompanyDetailsQuery } from '@api/endpoints/company/company.api';
import PinDisplayMap from '@components/google-map/pin-display-map';

interface Props {
  caseDetails: CaseResource;
  showMap: boolean;
}

// Grid layout split into 2 columns,
// the first column takes up 1/3 of the space and contains 2 rows (overview and dates)
// the second column up 2/3 of the space and has 1 row (map)
const Container = styled('div')<{ showMap: boolean }>(
  ({ theme: { palette }, showMap }) => ({
    display: 'grid',

    gridTemplateAreas: showMap
      ? `
    'overview map'
    'dates map'
  `
      : `
    'overview dates'
  `,
    columnGap: 0,
    rowGap: 0,
    height: '100%',
    width: '100%',
    backgroundColor: palette.common.grey15,

    '.overview': {
      gridArea: 'overview',
      backgroundColor: '#FFF9EF',
      padding: 64,
    },

    '.dates': {
      gridArea: 'dates',
      backgroundColor: showMap ? palette.common.white : palette.common.white,
      padding: 64,
    },

    '.map': {
      position: 'relative',
      gridArea: 'map',
      backgroundColor: 'teal',
      overflow: 'hidden',

      '& > img': {
        width: '100%',
        height: '100%',

        objectFit: 'cover',
        objectPosition: 'center',
      },

      '.open-map-link': {
        position: 'absolute',
        right: 50,
        bottom: 50,
        borderRadius: '5px',
        padding: '15px 25px',
        background: '#00223A',
      },
    },

    '.title': {
      fontSize: '32px',
      fontWeight: '400',
      lineHeight: '30px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },

    '.data': {
      display: 'inline-flex',
      flexDirection: 'column',
      gap: 14,

      '.label': {
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'left',
        fontSize: '13px',
        textTransform: 'uppercase',
        fontWeight: '500',
        color: '#949FA2',
      },

      '.value': {
        display: 'inline-flex',
        alignItems: 'center',
        gap: 10,
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '27px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: palette.common.black,
        svg: {
          color: '#FF6C00',
        },
        '&.reporting svg': {
          color: '#00223A',
        },
      },

      '&.large': {
        gap: 17,

        '.label': {
          lineHeight: '19px',
          letterSpacing: '0px',
          textAlign: 'left',
          fontSize: '13px',
          textTransform: 'uppercase',
          fontWeight: '500',
          color: '#949FA2',
        },

        '.value': {
          fontSize: '26px',
          lineHeight: '29px',
        },
      },
    },
    '.dates .custom-row': {
      '> *': {
        flex: '0 0 50%',
      },
    },
    '.incident-table': {
      minWidth: '50%',
      borderCollapse: 'seperate',
      borderSpacing: '0 8px',

      th: {
        margin: 0,
        padding: '15px 12px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: palette.common.white,
        backgroundColor: '#334E61',

        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },

        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      },

      td: {
        margin: 0,
        padding: '15px 12px',

        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },

        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      },
    },
  })
);

const StyledLeagueEntry = styled(Box)({
  display: 'flex',
  padding: '8px 10px 8px 16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: 3,
  border: '1px solid #E0E0E0',
  background: 'white',
  p: {
    fontSize: 14,
    '&.dataValue': {
      width: '80px',
    },
    '&.percentage': {
      textAlign: 'right',
    },
  },
});

const StyledLeagueEntryHeader = styled(Box)({
  display: 'flex',
  padding: '8px 10px 8px 16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  background: 'white',
});

export default function OverviewSection({ caseDetails, showMap }: Props) {
  const { systemText } = useSystemText();

  const { data: companyDetails, isLoading: loadingDetails } =
    useGetCompanyDetailsQuery();

  const {
    focalPoint,
    incidentLocation,
    reportingLocation,
    caseIncident,
    frequencyTypeName,
    frequencyValue,
  } = caseDetails;

  const location = incidentLocation ?? reportingLocation;
  const { latitude, longitude } = location;

  return (
    <SectionScaffold id="overview">
      <Container showMap={showMap}>
        <div className="overview">
          <Column>
            <div className="title">Overview</div>
            <Gap size={32} />
            <div className="data large">
              <div className="label">
                {systemText['RCA.Overview.FocalPoint']}
              </div>
              <div className="value">{focalPoint}</div>
            </div>
            {incidentLocation != null ? (
              <>
                <Gap size={32} />
                <div className="data">
                  <div className="label">Incident Location</div>
                  <div className="value">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>{getAddressString(incidentLocation!)}</span>
                  </div>
                </div>
              </>
            ) : null}
            {reportingLocation != null ? (
              <>
                <Gap size={32} />
                <div className="data">
                  <div className="label">Reporting Location</div>
                  <div className="value reporting">
                    <FontAwesomeIcon icon={faBuildings} />
                    <span>{getAddressString(reportingLocation!)}</span>
                  </div>
                </div>
              </>
            ) : null}
          </Column>
        </div>
        <div className="dates">
          <div className="data">
            <div className="label">
              Incident date{caseIncident.length === 1 ? '' : 's'}
            </div>
          </div>
          <Box sx={{ marginBottom: '20px' }}>
            <StyledLeagueEntryHeader>
              <Box sx={{ width: '40%' }}>
                <Typography variant="caption">Start</Typography>
              </Box>
              <Box sx={{ width: '40%' }}>
                <Typography variant="caption">End</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Duration</Typography>
              </Box>
            </StyledLeagueEntryHeader>
            {caseIncident?.map((x, i) => (
              <Box sx={{ marginTop: '5px', marginBottom: '5px' }} key={i}>
                <StyledLeagueEntry>
                  <Box sx={{ width: '40%' }}>
                    <Typography>
                      {dateOnlyLongFormatter(x.startDate)}
                      <br />
                      {timeOnlyShortFormatter(x.startDate)}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '40%' }}>
                    <Typography>
                      {!isNullOrEmpty(x.endDate) ? (
                        <>
                          {dateOnlyLongFormatter(x.endDate)} <br />
                          {timeOnlyShortFormatter(x.endDate)}
                        </>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {!isNullOrEmpty(x.endDate)
                        ? `${getDurationString(x.startDate, x.endDate!)}`
                        : '-'}
                    </Typography>
                  </Box>
                </StyledLeagueEntry>
              </Box>
            ))}
          </Box>
          {frequencyValue != null && !isNullOrEmpty(frequencyTypeName) ? (
            <>
              <Gap size={32} />
              <div className="data">
                <div className="label">Frequency</div>
                <div className="value">
                  {`${
                    frequencyValue === 1 ? 'Once' : `${frequencyValue} times`
                  } ${frequencyTypeName}`}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {showMap && (
          <div className="map" style={{ width: '67vw' }}>
            {!loadingDetails && (
              <PinDisplayMap
                pins={[
                  {
                    lat: latitude ?? 0,
                    lng: longitude ?? 0,
                    isReportingLocation: false,
                    cases: [],
                  },
                ]}
                onFilterByLocationId={(locationId) => {}}
                mapType={
                  companyDetails?.defaultSatelliteMap ? 'satellite' : 'roadmap'
                }
                labelsOn={companyDetails?.defaultShowMapLabel ?? false} // Set to true to show labels
                showTerrain={companyDetails?.defaultShowMapTerrain ?? false}
                showCounts={false}
              />
            )}
          </div>
        )}
      </Container>
    </SectionScaffold>
  );
}
