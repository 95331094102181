import { api } from '@api/api';
import { ICurrency } from '@api/types/currency';
import { Option } from '@api/types/option';

const currencyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencyOptions: build.query<Option[], void>({
      query: () => 'currency/options',
    }),
    getCurrencies: build.query<ICurrency[], void>({
      query: () => 'currency/all',
    }),
  }),
});

export const { useGetCurrencyOptionsQuery, useGetCurrenciesQuery } =
  currencyApi;
