import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseCompanyUserResource } from '@api/types/case/case-company-user.resource';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { SelectedCaseEditTab } from '@pages/app/rca/edit/edit-case-page';
import { getInitials } from '@util/name-util';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import FlatBadge from '@components/badges/flat-badge';
import { Gap } from '@components/layout-util-components/gap';
import { ColorClass } from '../outcome-report-scaffold';
import {
  getColorForId,
  getLightenedColorForId,
} from '@util/colour-identifiers';

interface Props {
  teamMembers: CaseCompanyUserResource[];
  className: ColorClass;
}

const StyledTeamMemberSection = styled('div')(({ theme: { palette } }) => ({
  '> .dark': {
    '.card': {
      background: '#fff',
      '.name': {
        color: '#00223A',
      },
      '.job-title': {
        color: palette.common.grey60,
      },
      '.flat-badge': {
        background: '#00223A',
        color: '#fff',
      },
    },
  },
  '> .light': {
    '.card': {
      background: '#00223A',
      border: '1px solid #00223A',
      '.name': {
        color: '#fff',
      },
      '.job-title': {
        color: 'rgba(255,255,255,0.65)',
      },
      '.flat-badge': {
        background: '#CDF1F3',
        color: '#00223A',
      },
    },
  },
  '> .white': {
    '.card': {
      background: '#00223A',
      border: '1px solid #00223A',
      '.name': {
        color: '#fff',
      },
      '.job-title': {
        color: 'rgba(255,255,255,0.65)',
      },
      '.flat-badge': {
        background: '#CDF1F3',
        color: '#00223A',
      },
    },
  },
}));

const UserCardContainer = styled('div')(({ theme: { palette } }) => ({
  padding: 25,
  display: 'flex',
  borderRadius: 8,
  minHeight: 157,

  '.avatar': {
    width: 88,
    height: 88,
    flexShrink: '0',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#BBBBBB',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '26px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'center',
  },

  '.name': {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '26px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },

  '.job-title': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
}));

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  '.members-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px',
    alignItems: 'stretch',
  },
}));

export default function TeamMembersSection({ teamMembers, className }: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const cardProps: Array<UserCardProps> = teamMembers.map((user, index) => ({
    index,
    name: user.name,
    jobTitle: user.jobTitle,
    tag: user.caseRoleName,
    xp: user.kudosLevel,
  }));

  return (
    <StyledTeamMemberSection>
      <SectionScaffold id="team-members" className={className}>
        <Container>
          <Typography variant="h2" fontSize="32px" alignSelf="center">
            Team Members
          </Typography>
          <div className="members-grid">
            {cardProps.map((user, index) => (
              <UserCard key={index} {...user} />
            ))}
          </div>
          <Box display="flex" alignSelf="center" justifyContent="center">
            <PrimaryButton
              onClick={() =>
                navigate(
                  `/rca/edit/${caseId}/?initialTab=${SelectedCaseEditTab.collaboration}`
                )
              }
            >
              View Team Members <FontAwesomeIcon icon={faArrowRight} />
            </PrimaryButton>
          </Box>
        </Container>
      </SectionScaffold>
    </StyledTeamMemberSection>
  );
}

interface UserCardProps {
  index: number;
  name: string;
  jobTitle?: string;
  tag: string;
  xp?: string;
}

const UserCard = ({ index, tag, xp, name, jobTitle }: UserCardProps) => {
  return (
    <UserCardContainer className="card">
      <div
        className="avatar"
        style={{
          backgroundColor:
            tag && tag.toLowerCase().startsWith('owner')
              ? getColorForId('solution')
              : getColorForId(index),
          color: '#ffffff',
        }}
      >
        {getInitials(name)}
      </div>
      <Gap size={24} />
      <Column>
        <div className="name">{name}</div>
        {jobTitle && <div className="job-title">{jobTitle}</div>}
        <Spacer />
        <Box display="flex" gap={2} alignItems="center">
          {tag && <FlatBadge>{tag}</FlatBadge>}
          {xp && <FlatBadge>{`Kudos Lvl ${xp}`}</FlatBadge>}
        </Box>
      </Column>
    </UserCardContainer>
  );
};
