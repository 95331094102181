import { StepperStep } from '@components/stepper/use-stepper-hook';
import {
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

interface Props {
  activeStep: number;
  steps: Array<StepperStep<any>>;
}

export default function Stepper({ activeStep, steps }: Props) {
  return (
    <MuiStepper activeStep={activeStep}>
      {steps.map((step, i) => (
        <MuiStep key={uuid()}>
          <MuiStepLabel
            sx={{
              cursor: i < activeStep && step.onClick ? 'pointer' : undefined,
            }}
            onClick={i < activeStep ? step.onClick : undefined}
          >
            {step.title}
          </MuiStepLabel>
        </MuiStep>
      ))}
    </MuiStepper>
  );
}
