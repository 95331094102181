import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ApiError } from '@api/types/api-error';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useRemoveCaseEvidenceMutation } from '@api/endpoints/case.api';
import { usePageAlertVariants } from '@components/alerts';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { useCallback } from 'react';

export default function useEvidenceHelper() {
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const [removeEvidence] = useRemoveCaseEvidenceMutation();

  const deleteEvidence = useCallback(
    (evidenceId: number) => {
      return showPopup(DeleteConfirmationModal, {
        itemName: 'Evidence',
        title: 'Are you sure you want to delete this evidence?',
        message:
          'Please note this will remove this evidence from all associated cause boxes.',
        onDelete: async () => {
          try {
            await removeEvidence({ evidenceId, caseId: caseId! }).unwrap();
            showSuccessMessage('Case evidence deleted successfully');
            return true;
          } catch (error) {
            const { message, errors } = error as ApiError<{ caseId: number }>;
            showErrorMessage(errors?.caseId ?? message);
            return false;
          }
        },
      });
    },
    [caseId, removeEvidence, showErrorMessage, showPopup, showSuccessMessage]
  );

  return {
    deleteEvidence,
  };
}
