import { styled } from '@mui/material';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/cause-box.svg';

interface Props {
  count: number;
  variant?: 'default' | 'no-icon';
}

const Container = styled('div', { target: 'associated-cause-box-badge' })(
  ({ theme: { palette } }) => ({
    color: palette.blue.dark,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0em',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 10,
    alignSelf: 'flex-start',

    svg: {
      height: 14,
      width: 'auto',
    },
  })
);

export default function AssociatedCauseBoxBadge({
  count,
  variant = 'default',
}: Props) {
  if (count === 0) return null;
  return (
    <Container>
      {variant !== 'no-icon' && <CauseBoxIcon />}
      <span>
        Associated to {count} Cause {count === 1 ? 'box' : 'boxes'}
      </span>
    </Container>
  );
}
