import { Box, FormHelperText, styled, Typography } from '@mui/material';
import { Option } from '@api/types/option';
import { DefaultTFuncReturn } from 'i18next';
import { Gap } from '@components/layout-util-components/gap';
import Column from '@components/layout-util-components/column';

interface HelperIndicators {
  left: string;
  right: string;
}

interface Props<T> {
  name: string;
  label?: string | DefaultTFuncReturn;
  subText?: string;
  error?: string;
  options: Array<Option<T>>;
  value: T;
  onChange: (value?: T) => void;
  readonly?: boolean;
  helperIndicators?: HelperIndicators;
}

const StyledContainer = styled('div', {
  target: 'inline-radio-select-group-container',
})({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  minHeight: 40,
  alignItems: 'stretch',

  'input[type="radio"]': {
    '-webkit-appearance': 'none',
    appearance: 'none',
    margin: 0,
  },
});

const StyledIndicators = styled('div')({
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '14px',
  letterSpacing: '0px',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  color: '#00000099',
});

const StyledOption = styled('label', {
  target: 'inline-radio-select-group-option',
})<{
  isSelected: boolean;
}>(({ isSelected, theme: { palette } }) => ({
  flex: '1 1 auto',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8,
  border: '1px solid #00000040',
  borderRadius: 4,
  backgroundColor: palette.common.white,
  color: palette.common.grey90,
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '26px',
  letterSpacing: '0.46000000834465027px',
  textAlign: 'left',

  ...(isSelected && {
    border: `2px solid ${palette.primary.main}`,
    padding: 7,
    cursor: 'default',
  }),
}));

export default function InlineRadioSelectGroup<T>({
  name,
  options,
  label,
  subText,
  error,
  onChange,
  value,
  readonly,
  helperIndicators,
}: Props<T>) {
  return (
    <Column>
      {label ? (
        <>
          <Typography>{label}</Typography>
          <Gap size={15} />
        </>
      ) : null}
      {subText ? (
        <>
          {subText.split('\\n').map((s, index) => (
            <span key={index}>{s}</span>
          ))}
          <Gap size={15} />
        </>
      ) : null}
      <StyledContainer>
        {options.map(({ id, label }) => {
          const isSelected = id === value;

          return (
            <StyledOption
              key={`${id}`}
              isSelected={isSelected}
              onClick={!readonly ? () => onChange(id) : undefined}
            >
              <input
                type="radio"
                name={name}
                checked={isSelected}
                readOnly={readonly}
                onChange={(e) =>
                  e.target.checked ? onChange(id) : onChange(undefined)
                }
              />
              {label}
            </StyledOption>
          );
        })}
      </StyledContainer>
      {helperIndicators != null ? (
        <>
          <Gap size={6} />
          <StyledIndicators>
            <span>{helperIndicators!.left}</span>
            <span>{helperIndicators!.right}</span>
          </StyledIndicators>
        </>
      ) : null}
      {error ? (
        <Box mx={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      ) : null}
    </Column>
  );
}
