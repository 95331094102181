import { useFilter, usePagination } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import {
  useGetTasksForUserQuery,
  useGetTaskStatusOptionsQuery,
} from '@api/endpoints/task-detail.api';
import { TaskStatus } from '@api/types/task-detail/task-status';
import useSortableHeader, {
  SortOption,
} from '@components/table/hooks/use-sortable-header-hook';
import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';

export default function useTasks(caseId?: number) {
  const displayCaseName = caseId == null;
  const canManageTasks = usePermission(Permission.manageCompanyTasks);
  const { data: userOptions, isLoading: loadingUsers } =
    useGetCompanyUsersOptionsQuery({});
  const { data: taskStatusOptions, isLoading: loadingTaskStatusOptions } =
    useGetTaskStatusOptionsQuery();

  const sortByOptions: Array<SortOption> = [
    { id: 'dueDate', label: 'Due Date', direction: SortDirection.asc },
    {
      id: 'caseName',
      label: 'Linked RCA',
      direction: SortDirection.asc,
    },
  ];

  const companyUserFilter = useFilter<Array<number>>([]);
  const taskStatusFilter = useFilter<Array<TaskStatus>>([]);

  const search = useFilter<string>();
  const pagination = usePagination();
  const sort = useSortableHeader({ initialProperty: 'dueDate' });

  const {
    data: tasks,
    isLoading: loadingTasks,
    isFetching: fetchingUserTasks,
  } = useGetTasksForUserQuery({
    assignedToCompanyUserIds: companyUserFilter.arrayValue,
    status: taskStatusFilter.arrayValue,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
    caseId,
    skip: pagination.skip,
    take: pagination.take,
  });

  const isLoading = loadingTasks || loadingUsers || loadingTaskStatusOptions;

  return {
    search,
    sort,
    sortByOptions,
    isLoading,
    tasks,
    companyUserFilter,
    taskStatusFilter,
    pagination,
    userOptions: canManageTasks ? userOptions : [],
    taskStatusOptions,
    isFetching: fetchingUserTasks,
    hasNoTasks: tasks && tasks!.totalCount === 0,
    hasNoFilteredTasks: tasks && tasks!.totalCount > 0 && tasks!.count === 0,
    hasFilteredTasks: tasks && tasks!.totalCount > 0 && tasks!.count > 0,
    displayCaseName,
  };
}
