import { api } from '@api/api';
import { Option } from '@api/types/option';

const companyRoleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyRoles: build.query<Option<string>[], void>({
      query: () => ({
        url: 'companyRole/options',
      }),
    }),
  }),
});

export const { useGetCompanyRolesQuery } = companyRoleApi;
