import { useMemo } from 'react';
import { isNullOrEmpty } from '@util/string-util';

export default function useSplitStringIntoParagraphs(str?: string) {
  return useMemo(() => {
    if (isNullOrEmpty(str)) {
      return '<p></p>';
    }

    const rawString = str!;
    let html = '';

    // copy rawString into html but wrap paragraphs that are seperated by more than 1 line break in a <p></p> tag
    const paragraphs = rawString.split(/\n\s*\n/);
    html = paragraphs.map((paragraph) => `<p>${paragraph}</p>`).join('');

    return html;
  }, [str]);
}
