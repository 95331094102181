import { Grid, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useAuthTr } from '@i18n/use-auth-tr';

export default function NoAccountSignupLink() {
  const { t } = useAuthTr('common');

  return (
    <Grid item alignSelf="center">
      <Typography variant="body1">
        <Trans t={t} i18nKey="noAccountButtonLabel">
          Don’t have an account yet?
          <Link href="../create-account/?packageId=1">Sign up</Link>
        </Trans>
      </Typography>
    </Grid>
  );
}
