import { Button, ButtonGroup, styled } from '@mui/material';
import { ReactComponent as PlusIcon } from '@assets/svgs/plus.svg';
import { ReactComponent as MinusIcon } from '@assets/svgs/minus.svg';
import { ReactComponent as PresentModeIcon } from '@assets/svgs/presenter-mode.svg';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  ChartDisplayMode,
  RcaChartMode,
  setDisplayMode,
  setRcaChartMode,
} from '@store/rca-editor/rca-editor-slice';
import { useReactFlow, useViewport } from 'reactflow';
import {
  selectChartDisplayMode,
  selectIsHealthScorePanelOpen,
  selectRcaChartMode,
  selectRootNode,
} from '@store/rca-editor/selectors';
import { RcaUtil } from '@util/rca-util';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudArrowDown,
  faCrosshairsSimple,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';
import {
  DYNAMIC_BRAND_COLOR_ID,
  getColorBrandId,
  getColorForId,
  STATIC_BRAND_COLOR_ID,
} from '@util/colour-identifiers';
import useSystemText from '@hooks/use-system-text';
import { useEffect, useRef } from 'react';
import { toPng } from 'html-to-image';
import { getNodesBounds, getViewportForBounds } from '@xyflow/react';

function downloadImage(dataUrl) {
  const a = document.createElement('a');

  a.setAttribute('download', 'what-caused-this-diagram.png');
  a.setAttribute('href', dataUrl);
  a.click();
}

const Container = styled('div', { target: 'chart-controls' })(
  ({ theme: { palette } }) => ({
    position: 'absolute',
    left: 20,
    bottom: 20,
    display: 'flex',
    gap: 15,

    '.control-section': {
      height: 56,
      padding: 12,
      borderRadius: 4,
      display: 'inline-flex',
      gap: 12,
      background: palette.common.white,
      color: 'black',
      fill: 'black',
      fontFamily: 'Usual',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '26px',
      letterSpacing: '0.46000000834465027px',
      zIndex: 900,

      '.MuiButtonGroup-root': {
        '.MuiButton-root': {
          minWidth: 100,
        },
      },

      '.control-item': {
        display: 'inline-flex',
        alignItems: 'center',
        background: '#F9F4F1',
        textAlign: 'center',
        height: 32,
        padding: 8,
        borderRadius: 8,
        '&:hover': {
          background: '#F3EBE5',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
        },
      },

      'button.control-item': {
        border: 'none',
        width: 32,
        height: 32,
        flex: '0 0 auto',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        cursor: 'default',
        '&.toggle-choice': {
          borderRadius: 8,
          width: 'unset',
          background: '#F9F4F1',
          color: palette.button.circle.color,

          '&.selected': {
            background: palette.blue.dark,
          },
          '&.selected svg path': {
            fill: 'white',
          },
          '&:hover': {
            background: '#F3EBE5',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
          },
          '&.selected:hover': {
            background: palette.blue.dark,
            color: 'unset',
            stroke: 'unset',
          },
        },
      },
      '&.box-type button': {
        background: '#F9F4F1',
        borderColor: '#F9F4F1',
        color: '#000',
        textTransform: 'none',
      },
      '&.box-type button.MuiButton-contained': {
        background: palette.blue.dark,
        borderColor: palette.blue.dark,
        color: '#fff',
        boxShadow: 'none',
        textTransform: 'none',

        '&.health': {
          background: getColorForId('health'),
        },

        '&.theme': {
          background: getColorForId('theme'),
          color: '#000',
        },

        '&.evidence': {
          background: getColorForId('evidence'),
        },

        '&.solutions': {
          background: getColorForId('solution'),
        },

        '&.static': {
          background: getColorBrandId(STATIC_BRAND_COLOR_ID),
        },

        '&.dynamic': {
          background: getColorBrandId(DYNAMIC_BRAND_COLOR_ID),
          color: '#000',
        },
      },
    },
  })
);

export default function ChartControls() {
  const dispatch = useAppDispatch();

  const rootNode = useAppSelector(selectRootNode);
  const mode = useAppSelector(selectRcaChartMode);
  const displayMode = useAppSelector(selectChartDisplayMode);
  const isHealthScorePanelOpen = useAppSelector(selectIsHealthScorePanelOpen);

  const currentChartMode = useRef<ChartDisplayMode>(displayMode);
  const lastChartMode = useRef<ChartDisplayMode | null>(null);

  const { systemText } = useSystemText();
  const rf = useReactFlow();
  const { zoom } = useViewport();

  const onDownloadClick = () => {
    const scale = 3;
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(rf.getNodes() as unknown as any);

    const aspectRatio = nodesBounds.width / nodesBounds.height;
    const imageWidth = 2048;
    const imageHeight = imageWidth / aspectRatio;

    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2,
      200
    );

    toPng(
      document.querySelector('.react-flow__viewport') as unknown as HTMLElement,
      {
        backgroundColor: '#ECEDF0',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth.toString(),
          height: imageHeight.toString(),
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
          // transformOrigin: 'center center',
        },
        pixelRatio: scale,
      }
    ).then(downloadImage);
  };

  currentChartMode.current = displayMode;
  useEffect(() => {
    if (isHealthScorePanelOpen) {
      dispatch(setDisplayMode({ displayMode: ChartDisplayMode.healthScore }));
      lastChartMode.current = currentChartMode.current;
    } else {
      if (lastChartMode.current != null) {
        dispatch(setDisplayMode({ displayMode: lastChartMode.current }));
        lastChartMode.current = null;
      }
    }
  }, [dispatch, isHealthScorePanelOpen]);

  return (
    <Container>
      <div className="control-section">
        <div className="control-item">
          <span style={{ minWidth: 45 }}>{`${(zoom * 100).toFixed(0)}%`}</span>
          <Gap size={15} />
        </div>
        <button
          type="button"
          className="control-item"
          onClick={() => rf.zoomTo(rf.getZoom() + 0.05)}
        >
          <PlusIcon fill="inherit" />
        </button>
        <button
          type="button"
          className="control-item"
          onClick={() => rf.zoomTo(rf.getZoom() - 0.05)}
        >
          <MinusIcon fill="inherit" />
        </button>
      </div>
      <div className="control-section">
        <button
          type="button"
          className="control-item"
          onClick={onDownloadClick}
          title="Download"
        >
          <FontAwesomeIcon icon={faCloudArrowDown} size="lg" />
        </button>
        <button
          type="button"
          className="control-item"
          onClick={() => RcaUtil.snapFocusToNode(rootNode, true)}
        >
          <FontAwesomeIcon icon={faCrosshairsSimple} size="lg" />
        </button>
        <button
          type="button"
          onClick={() =>
            dispatch(
              setRcaChartMode(
                mode === RcaChartMode.build
                  ? RcaChartMode.present
                  : RcaChartMode.build
              )
            )
          }
          className={`control-item toggle-choice ${
            mode === RcaChartMode.present ? 'selected' : ''
          }`}
        >
          <PresentModeIcon />
        </button>
      </div>
      <div className="control-section box-type">
        <ButtonGroup>
          <Button
            className="health"
            variant={
              displayMode === ChartDisplayMode.healthScore
                ? 'contained'
                : 'outlined'
            }
            onClick={() =>
              dispatch(
                setDisplayMode({ displayMode: ChartDisplayMode.healthScore })
              )
            }
          >
            Health
          </Button>
          <Button
            disabled={isHealthScorePanelOpen}
            className="theme"
            variant={
              displayMode === ChartDisplayMode.theme ? 'contained' : 'outlined'
            }
            onClick={() =>
              dispatch(setDisplayMode({ displayMode: ChartDisplayMode.theme }))
            }
          >
            Theme
          </Button>
        </ButtonGroup>
      </div>
      <div className="control-section box-type">
        <ButtonGroup>
          <Button
            disabled={isHealthScorePanelOpen}
            className="evidence"
            variant={
              displayMode === ChartDisplayMode.evidence
                ? 'contained'
                : 'outlined'
            }
            onClick={() =>
              dispatch(
                setDisplayMode({ displayMode: ChartDisplayMode.evidence })
              )
            }
          >
            Evidence
          </Button>
          <Button
            disabled={isHealthScorePanelOpen}
            className="solutions"
            variant={
              displayMode === ChartDisplayMode.solutions
                ? 'contained'
                : 'outlined'
            }
            onClick={() =>
              dispatch(
                setDisplayMode({ displayMode: ChartDisplayMode.solutions })
              )
            }
          >
            Solutions
          </Button>
        </ButtonGroup>
      </div>
      <div className="control-section box-type">
        <ButtonGroup>
          <Button
            disabled={isHealthScorePanelOpen}
            className="static"
            variant={
              displayMode === ChartDisplayMode.static ? 'contained' : 'outlined'
            }
            onClick={() =>
              dispatch(setDisplayMode({ displayMode: ChartDisplayMode.static }))
            }
          >
            {systemText['RCA.CauseBox.NonTransitory']}
          </Button>
          <Button
            disabled={isHealthScorePanelOpen}
            className="dynamic"
            variant={
              displayMode === ChartDisplayMode.dynamic
                ? 'contained'
                : 'outlined'
            }
            onClick={() =>
              dispatch(
                setDisplayMode({ displayMode: ChartDisplayMode.dynamic })
              )
            }
          >
            {systemText['RCA.CauseBox.Transitory']}
          </Button>
        </ButtonGroup>
      </div>
    </Container>
  );
}
