import { Skeleton } from '@mui/material';
import WCTTextField, { TextFieldMaxSize } from '@components/input/text-field';

interface Props {
  multiline?: boolean;
  maxWidth?: TextFieldMaxSize;
}

export default function InputSkeleton({ multiline, maxWidth }: Props) {
  return (
    <Skeleton variant="rectangular" width="100%" sx={{ maxWidth: maxWidth }}>
      <WCTTextField label="loading" onChange={() => {}} multiline={multiline} />
    </Skeleton>
  );
}
