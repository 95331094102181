import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';

interface Props {
  small?: boolean;
  xSmall?: boolean;
  color?: string;
  textColor?: string;
}

export default function ColorBadge({
  children,
  ...props
}: PropsWithChildren<Props>) {
  const { palette } = useTheme();
  const { color, textColor, small, xSmall } = props;

  return (
    <div
      style={{
        display: 'inline-flex',
        marginRight: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color ?? palette.badge.green,
        color: textColor ?? '#fff',
        borderRadius: 4,
        padding: '3px 10px',
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '18px',
        letterSpacing: '0.16px',

        ...(small && {
          fontSize: '12px',
          lineHeight: '15px',
          letterSpacing: '0.15000000596046448px',
          textAlign: 'left',
          padding: '2px 10px 2px 5px',
        }),
        ...(xSmall && {
          padding: '2px 5px 2px 5px',
        }),
      }}
    >
      {children}
    </div>
  );
}
