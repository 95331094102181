import { Grid, TableCell, TableRow } from '@mui/material';
import { WCTTable } from '@components/table';
import { useAdminTr } from '@i18n/use-admin-tr';
import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import useWCTUsersIndex from '@pages/admin/users/wct-users-index-hook';
import WctUsersHeader from '@pages/admin/users/components/wct-users-header';
import WctUsersTableFilters from '@pages/admin/users/components/wct-users-table-filters';
import FilledBadge from '@components/badges/filled-badge';
import ColorBadge from '@components/badges/color-badge';
import UserNameCell from '@pages/admin/users/components/user-name-cell';
import { ElipsisButton } from '@components/buttons';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';

function WctUsersIndexPage() {
  const {
    searchFilter,
    roleFilter,
    statusFilter,
    sort,
    users,
    pagination,
    isLoading,
    isFetching,
    roleOptions,
    statusOptions,
    toggleUserStatus,
    isTogglingStatus,
  } = useWCTUsersIndex();

  const { t } = useAdminTr('users');
  const { showConfirmationModal } = useUiPopup();

  const onToggleUserStatus = async (userId: string, isActive: boolean) => {
    const shouldToggle = await showConfirmationModal({
      message: `Are you should you want to ${
        isActive ? 'disable' : 'enable'
      } this user?`,
    });

    if (shouldToggle) {
      toggleUserStatus(userId);
    }
  };

  return (
    <>
      <WctUsersHeader userCount={users?.totalCount} />
      <ContentContainer>
        <Grid item container alignItems="stretch" direction="column">
          <WCTTable
            isLoading={isLoading}
            count={users?.totalCount ?? 0}
            pagination={pagination}
            filters={
              <WctUsersTableFilters
                isLoading={isLoading}
                isFetching={isFetching}
                search={searchFilter}
                role={roleFilter}
                status={statusFilter}
                roleOptions={roleOptions}
                statusOptions={statusOptions}
              />
            }
            headers={[
              { title: 'Name/Job Title', sortProperty: 'name', ...sort },
              { title: 'Role', sortProperty: 'role', ...sort },
              { title: 'Sector' },
              { title: 'Assigned Companies' },
              { title: 'Account Created' },
              { title: 'Status', sortProperty: 'status', ...sort },
              { title: <></> },
            ]}
          >
            {users?.model.map(
              ({
                userId,
                name,
                jobTitle,
                roleName,
                sectors,
                companies,
                created,
                status,
              }) => (
                <TableRow key={userId}>
                  <TableCell>
                    <UserNameCell name={name} jobTitle={jobTitle} />
                  </TableCell>
                  <TableCell>{roleName}</TableCell>
                  <TableCell>
                    {sectors?.map(({ label }) => label).join(', ')}
                  </TableCell>
                  <TableCell>
                    {companies?.map(({ id, label }) => (
                      <FilledBadge key={id}>{label}</FilledBadge>
                    ))}
                  </TableCell>
                  <TableCell>
                    {t('table.data.created', {
                      value: new Date(created),
                    })}
                  </TableCell>
                  <TableCell>
                    <ColorBadge>{status}</ColorBadge>
                  </TableCell>
                  <TableCell>
                    <ElipsisButton
                      disabled={isTogglingStatus}
                      actions={[
                        {
                          label: status === 'Disabled' ? 'Enable' : 'Disable',
                          onClick: () => {
                            onToggleUserStatus(userId, status !== 'Disabled');
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </WCTTable>
        </Grid>
      </ContentContainer>
    </>
  );
}

export default requireAuth(WctUsersIndexPage);
