import { styled } from '@mui/material';

const DrawerContent = styled('div')({
  margin: '10px 0',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowY: 'auto',
});

export default DrawerContent;
