import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { NotificationResource } from '@api/types/notifications/notification.resource';
import { GetNotificationsRequest } from '@api/types/notifications/get-notifications.request';
import { MarkNotificationsRequest } from '@api/types/notifications/mark-notifications.request';
import { maybeUpdateCache } from '@api/cache-util';

const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<
      PaginatedResult<NotificationResource>,
      GetNotificationsRequest
    >({
      query: (params) => ({
        url: 'notification',
        method: 'GET',
        params,
      }),
      providesTags: (response, error) =>
        !error ? [{ type: 'Notification' }] : [],
    }),
    markNotificationsAsRead: build.mutation<void, MarkNotificationsRequest>({
      query: (body) => ({
        url: 'notification/markAsRead',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ notificationIds }, { queryFulfilled }) {
        try {
          await queryFulfilled;

          maybeUpdateCache('Notification', undefined, [
            {
              api: notificationApi,
              endpoint: notificationApi.endpoints.getNotifications,
              callback: (d) => {
                if (d == null) {
                  return;
                }

                for (const item of d.model) {
                  if (notificationIds.includes(item.notificationId)) {
                    item.read = true;
                  }
                }
              },
            },
          ]);
        } catch (e) {}
      },
    }),
    deleteAllNotifications: build.mutation<void, void>({
      query: (body) => ({
        url: 'notification/all',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Notification'],
    }),
    deleteNotifications: build.mutation<void, MarkNotificationsRequest>({
      query: (body) => ({
        url: 'notification',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const {
  useMarkNotificationsAsReadMutation,
  useLazyGetNotificationsQuery,
  useGetNotificationsQuery,
  useDeleteAllNotificationsMutation,
  useDeleteNotificationsMutation,
} = notificationApi;
