import { ReactComponent as NoTasksIcon } from '@assets/svgs/no-tasks.svg';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  onCreateClicked?: () => void;
}

export default function NoCollaboratorsInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<NoTasksIcon />}
      title="There are no Collaborators to add"
      message="Adding users to your company profile will allow you to invite them to collaborate"
      createMessage="Invite users to company"
      onCreateClicked={onCreateClicked}
    />
  );
}
