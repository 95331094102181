import { Typography } from '@mui/material';
import useImpactInfo from '@pages/app/rca/create/forms/impact-info-hook';
import withSideDrawer, {
  SideDrawerFormProps,
} from '../../../../../components/ui-popup/drawers/with-side-drawer';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@components/ui-popup/drawers';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import ImpactInfoFormInputs from './components/impact-info-form-inputs';
import ImpactInfoFormButtons from './components/impact-info-form-buttons';

interface ImpactInfoDrawerFormProps extends SideDrawerFormProps<never> {
  caseId: number;
  model?: CaseImpactResource;
}

function ImpactInfoDrawerForm({
  caseId,
  model,
  onClose,
}: ImpactInfoDrawerFormProps) {
  const state = useImpactInfo(caseId, model);
  const { isEdit } = state;

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Typography variant="h4">{isEdit ? 'Edit' : 'Add'} Impact</Typography>
      </DrawerHeader>
      <DrawerContent>
        <ImpactInfoFormInputs {...{ ...state, ...{ onSuccess: onClose } }} />
      </DrawerContent>
      <DrawerFooter>
        <ImpactInfoFormButtons
          {...{ ...state, ...{ onCancel: onClose, addAnother: true } }}
        />
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(ImpactInfoDrawerForm);
