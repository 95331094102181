import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

interface Props {
  unreadCount?: number;
  applyTopMargin?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  display: 'inline-flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',

  svg: {
    height: '1.4rem',
  },

  '.counter': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    transform: 'translate(50%, -50%)',
    width: '1rem',
    height: '1rem',
    fontSize: '0.7rem',
    borderRadius: '50%',
    color: 'white',
  },
}));

export default function NotificationsIcon({
  unreadCount,
  applyTopMargin,
}: Readonly<Props>) {
  if (!unreadCount) {
    unreadCount = 0;
  }

  return (
    <Container>
      <FontAwesomeIcon icon={faBell} />
      {unreadCount > 0 && <div className="counter">{unreadCount}</div>}
    </Container>
  );
}
