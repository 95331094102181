import { Option } from '@api/types/option';

export const useDefaultIfSingleOption = <T extends Option<K>, K = number>(
  options: T[] | undefined,
  elseDefault?: K
): K | undefined => {
  if (!options) {
    return undefined;
  }

  if (options.length === 1) {
    return options[0].id;
  }

  return elseDefault;
};
