import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { Option } from '@api/types/option';
import { maybeUpdateCache } from '@api/cache-util';

const skillApi = api.injectEndpoints({
  endpoints: (build) => ({
    // User Profile endpoints
    getSkillOptions: build.query<Option[], void>({
      query: () => ({
        url: 'Skill/Options',
      }),
    }),
    getSkillLinked: build.query<Option[], void>({
      query: () => ({
        url: 'Skill/Linked',
      }),
      providesTags: (response) => (response ? ['Skills'] : []),
    }),
    updateSkillsLinks: build.mutation<Option[], Option[]>({
      query: (arg) => ({
        url: 'Skill/Link',
        method: 'PUT',
        body: { skillIds: arg.map((x) => x.id) },
      }),
      transformErrorResponse: (error) =>
        makeError<{
          skillId: number;
          name: string;
        }>(error),
      invalidatesTags: (_, error) => (!error ? ['Skills'] : []),
      async onQueryStarted(arg, { queryFulfilled }) {
        const patches = maybeUpdateCache('Skills', undefined, [
          {
            api: skillApi,
            endpoint: skillApi.endpoints.getSkillLinked,
            callback: (_) => {
              return arg;
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (_) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
  }),
});

export const {
  useUpdateSkillsLinksMutation,
  useGetSkillLinkedQuery,
  useGetSkillOptionsQuery,
} = skillApi;
