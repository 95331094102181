import { styled } from '@mui/material';
import { PageAlertDisplay } from '@components/alerts';
import { PropsWithChildren } from 'react';

interface Props {
  noAlert?: boolean;
  center?: boolean;
  anchorBottom?: boolean;
  addPadding?: boolean;
  filled?: boolean;
  className?: string;
}

const Container = styled('div')<{
  anchorBottom?: boolean;
  addPadding?: boolean;
  filled?: boolean;
}>(({ anchorBottom, addPadding, filled }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',

  ...(anchorBottom && {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  }),

  ...(addPadding && {
    padding: '20px 10%',
  }),

  ...(filled && {
    background: 'white',
    boxShadow: '0px -1px 16px 0px #00000033',
  }),
}));

const RowContainer = styled('div')<{ center?: boolean }>(({ center }) => ({
  display: 'flex',
  justifyContent: center ? 'center' : 'flex-end',
  alignItems: 'center',
  gap: 10,
}));

export default function ActionsRow({
  anchorBottom,
  children,
  center,
  noAlert,
  addPadding,
  filled,
}: PropsWithChildren<Props>) {
  return (
    <Container
      anchorBottom={anchorBottom}
      addPadding={addPadding}
      filled={filled}
    >
      {!noAlert ? <PageAlertDisplay ignoreSuccess addMargin /> : null}
      <RowContainer center={center}>{children}</RowContainer>
    </Container>
  );
}
