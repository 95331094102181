import { CaseIncidentResource } from '@api/types/case/case-incident.resource';
import { OutlinedButton } from '@components/buttons';
import WCTDateTimeField from '@components/input/date-time-field';
import { faAdd, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { ICaseIncidentError } from '../overview-step-hook';

interface IRowError {
  startDate?: string;
  endDate?: string;
}

interface ICaseIncidentInputRowProps {
  startDate?: string;
  endDate?: string;
  error?: IRowError;
  index: number;
  onStartDateChange: (value: string | undefined) => void;
  onEndDateChange: (value: string | undefined) => void;
  onDelete: () => void;
}

interface ICaseIncidentInputProps {
  value: CaseIncidentResource[];
  setCaseIncident: (value: CaseIncidentResource[]) => void;
  setCaseIncidentErrors: (errors: ICaseIncidentError[]) => void;
  errors?: IRowError[];
}

function CaseIncidentInputRow({
  startDate,
  endDate,
  error,
  index,
  onStartDateChange,
  onEndDateChange,
  onDelete,
}: ICaseIncidentInputRowProps) {
  const [startDateTimePickerClosed, setStartDateTimePickerClosed] =
    useState<number>();

  const [startDateTimePickerOpened, setStartDateTimePickerOpened] =
    useState<number>();

  const [endDateTimePickerOpened, setEndDateTimePickerOpened] =
    useState<number>();

  const onStartDateTimeClosed = () => {
    if (startDate) {
      setStartDateTimePickerClosed(new Date().getTime());
    }
  };

  return (
    <Grid
      container
      alignItems={'start'}
      sx={{ marginBottom: '5px', marginTop: '5px' }}
    >
      <Grid item xs={5}>
        <WCTDateTimeField
          name={`startDate${index}`}
          //label="Start Date / Time"
          value={startDate}
          error={error?.startDate}
          onChange={onStartDateChange}
          onPickerClosed={onStartDateTimeClosed}
          onPickerOpened={() =>
            setStartDateTimePickerOpened(new Date().getTime())
          }
          closeOnValueChange={endDateTimePickerOpened?.toString()}
          maxDate={endDate}
          variant="small"
          disableFuture
          required
          hideIcon
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5}>
        <WCTDateTimeField
          name={`endDate${index}`}
          //label="End Date / Time"
          value={endDate}
          error={error?.endDate}
          onChange={onEndDateChange}
          openOnValueChange={startDateTimePickerClosed?.toString()}
          closeOnValueChange={startDateTimePickerOpened?.toString()}
          onPickerOpened={() =>
            setEndDateTimePickerOpened(new Date().getTime())
          }
          variant="small"
          disableFuture
          hideIcon
        />
      </Grid>
      <Grid item xs={1} sx={{ textAlign: 'right', paddingTop: '5px' }}>
        <IconButton onClick={onDelete}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default function CaseIncidentInput({
  value,
  setCaseIncident,
  setCaseIncidentErrors,
  errors,
}: ICaseIncidentInputProps) {
  const onStartDateChange = (startDate: string | undefined, index: number) => {
    let newValues = [...value];
    let newErrors = errors ? [...errors] : [];

    if (index >= 0 && index < newValues.length) {
      newValues[index] = { ...newValues[index] };
      newValues[index].startDate = startDate ?? '';

      if (newErrors[index]) {
        newErrors[index].startDate = '';
      }
    }

    setCaseIncident(newValues);
    setCaseIncidentErrors(newErrors);
  };

  const onEndDateChange = (endDate: string | undefined, index: number) => {
    let newValues = [...value];
    let newErrors = errors ? [...errors] : [];

    if (index >= 0 && index < newValues.length) {
      newValues[index] = { ...newValues[index] };
      newValues[index].endDate = endDate;

      if (newErrors[index]) {
        newErrors[index].endDate = '';
      }
    }

    setCaseIncident(newValues);
    setCaseIncidentErrors(newErrors);
  };

  const onDelete = (index: number) => {
    let newValues = [...value];
    let newErrors = errors ? [...errors] : [];

    if (index >= 0 && index < newValues.length) {
      newValues.splice(index, 1);
      newErrors.splice(index, 1);
    }

    if (newValues.length === 0) {
      newValues.push({
        startDate: '',
        endDate: '',
      });
      newErrors.push({
        startDate: '',
        endDate: '',
      });
    }

    setCaseIncident(newValues);
    setCaseIncidentErrors(newErrors);
  };

  const onAdd = () => {
    let newValues = value === undefined ? [] : [...value];
    let newErrors = errors ? [...errors] : [];

    newValues.push({
      startDate: '',
      endDate: '',
    });

    newErrors.push({
      startDate: '',
      endDate: '',
    });

    setCaseIncident(newValues);
    setCaseIncidentErrors(newErrors);
  };

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item xs={5}>
          Start Date / Time
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          End Date / Time
        </Grid>
        <Grid item xs={1} sx={{ textAlign: 'right' }}>
          <OutlinedButton onClick={onAdd} sx={{ minWidth: 0 }}>
            <FontAwesomeIcon icon={faAdd} color="#FF6C00" />
          </OutlinedButton>
        </Grid>
      </Grid>
      {value?.map((caseIncident: CaseIncidentResource, i: number) => (
        <CaseIncidentInputRow
          startDate={caseIncident.startDate}
          endDate={caseIncident.endDate}
          index={i}
          error={errors && errors[i]}
          onStartDateChange={(value: string | undefined) =>
            onStartDateChange(value, i)
          }
          onEndDateChange={(value: string | undefined) =>
            onEndDateChange(value, i)
          }
          onDelete={() => onDelete(i)}
        />
      ))}
    </>
  );
}
