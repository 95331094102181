import useField from '@hooks/use-field-hook';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import {
  useGetCompanyDetailsQuery,
  useSetCompany2FAMutation,
} from '@api/endpoints/company/company.api';
import { useAppTr } from '@i18n/use-app-tr';
import { SetCompany2faRequest } from '@api/types/company/set-company-2fa.request';

export default function useCompanyMfaSetup() {
  const { t } = useAppTr('companyMfaSettings');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { data: companyDetails, isLoading: loadingCompany } =
    useGetCompanyDetailsQuery();

  const [setCompany2FA] = useSetCompany2FAMutation();

  const isOn = useField<boolean>([], companyDetails?.force2FA ?? false);

  const setMfa = (enabled: boolean): Promise<boolean> => {
    return setCompany2FA({
      forceTwoFA: enabled,
    })
      .unwrap()
      .then(() => {
        if (enabled) {
          showSuccessMessage(t('toasts.enabledSuccess'));
        } else {
          showSuccessMessage(t('toasts.disabledSuccess'));
        }
        return true;
      })
      .catch(({ message, errors }: ApiError<SetCompany2faRequest>) => {
        showErrorMessage(message ?? errors?.forceTwoFA);
        return false;
      });
  };

  return {
    isOn,
    setMfa,
    isLoading: loadingCompany,
  };
}
