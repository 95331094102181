import { api } from '@api/api';
import { Option } from '@api/types/option';

const waSubscriptionStatusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWASubscriptionStatusOptions: build.query<Option<String>[], void>({
      query: () => ({
        url: 'subscriptionStatus/options',
      }),
    }),
  }),
});

export const { useGetAllWASubscriptionStatusOptionsQuery } =
  waSubscriptionStatusApi;
