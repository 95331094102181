import { styled } from '@mui/material';

interface Props {
  addMargin?: boolean;
  compact?: boolean;
  superCompact?: boolean;
  negativeMargin?: number;
  isLineVisible?: boolean;
}
export const Divider = styled('div')<Props>(
  ({
    addMargin,
    compact,
    superCompact,
    negativeMargin,
    isLineVisible = true,
  }) => ({
    height: 0,
    border: isLineVisible ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
    alignSelf: 'stretch',
    flexGrow: 0,
    ...(addMargin && {
      margin: superCompact
        ? `9px ${negativeMargin ?? 0}px`
        : compact
        ? `20px ${negativeMargin ?? 0}px`
        : `40px ${negativeMargin ?? 0}px`,
    }),
  })
);
