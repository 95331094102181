import { styled } from '@mui/material';

export const StyledAlertHeader = styled('div')(({ theme: { palette } }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 500,
  paddingBottom: 4,
  color: palette.common.textDark,
}));
