import { useEffect, useState } from 'react';
import {
  useLazyGetCaseOutcomeAuthorQuery,
  useLazyGetCaseOutcomeResultQuery,
} from '@api/endpoints/case-outcome.api';
import { CaseOutcomeAuthorResponse } from '@api/types/case/case-outcome/case-outcome-author.response';
import useUser from '@store/user/user-hook';

export enum OutcomeReportViewState {
  checkingPermission,
  loading,
  locked,
  view,
}

export default function useOutcomeReport(caseId: number, outcomeId: number) {
  const [getOutcomeResult, { data: outcomeData }] =
    useLazyGetCaseOutcomeResultQuery();
  const [getCaseAuthor] = useLazyGetCaseOutcomeAuthorQuery();

  const { companyUserId } = useUser();

  const [viewState, setViewState] = useState(
    OutcomeReportViewState.checkingPermission
  );
  const [outcomeAuthor, setOutcomeAuthor] =
    useState<CaseOutcomeAuthorResponse | null>(null);

  useEffect(() => {
    if (caseId == null || outcomeId == null) {
      return;
    }

    async function checkPermissionAndProgress() {
      try {
        await getOutcomeResult({ caseId, outcomeId }).unwrap();
        setViewState(OutcomeReportViewState.view);
      } catch (_) {
        try {
          const authorInfo = await getCaseAuthor({
            caseId,
            outcomeId,
          }).unwrap();
          setOutcomeAuthor(authorInfo);
        } catch (_) {}

        setViewState(OutcomeReportViewState.locked);
      }
    }

    checkPermissionAndProgress();
  }, [caseId, getCaseAuthor, getOutcomeResult, outcomeId]);

  const loadReport = () => {
    setViewState(OutcomeReportViewState.view);
  };

  return {
    caseId,
    outcomeId,
    viewState,
    loadReport,
    outcomeAuthor,
    canShare:
      companyUserId != null &&
      (companyUserId === outcomeData?.outcome?.createdByCompanyUserId ||
        companyUserId === outcomeData?.case?.createdByCompanyUserId),
  };
}

export type OutcomeReportState = ReturnType<typeof useOutcomeReport>;
