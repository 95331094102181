import { PropsWithChildren } from 'react';
import { styled, TableBody } from '@mui/material';
import { Palette } from '@mui/icons-material';

const StyledTableBody = styled(TableBody)({
  '&>tr': {
    td: {
      backgroundColor: 'white',
      padding: '12px 15px',
      borderTop: '1px solid rgba(0,0,0,0.25)',
      borderBottom: '1px solid rgba(0,0,0,0.25)',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderLeft: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
        borderRight: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
        '&.action_btn': {
          width: 62,
        },
      },
      '&.toggle': {
        width: 150,
        div: {
          padding: 0,
          label: {
            marginRight: 0,
          },
        },
      },
      '&.circular_icon_container': {
        paddingRight: '0 !important',
        '.circular_icon': {
          width: 32,
          height: 32,
          borderRadius: 16,
          background: '#00223A',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          svg: {
            color: '#fff',
            height: 16,
          },
        },
      },
    },
    '&.complete': {
      td: {
        background: '#F8F8FA',
        borderTop: '1px solid rgba(0,0,0,0.15)',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        cursor: 'pointer',
        color: 'rgba(0,0,0,0.4)',
        '&:first-child': {
          borderRadius: '0 0 0 0',
          borderLeft: '2px solid #3CA680',
          padding: '12px 15px 12px 14px',
        },
        '&:last-child': {
          borderRadius: '0 4px 4px 0',
          borderRight: '1px solid rgba(0,0,0,0.15)',
          padding: '12px 15px',
          '&.action_btn': {
            width: 62,
          },
        },
      },
      '.circle-container': {
        background: '#D8D8E3',
        color: 'rgba(0,0,0,0.4)',
        '& ~ p': {
          color: 'rgba(0,0,0,0.4)',
        },
      },
      '.task_title .priority div': {
        background: 'rgba(0, 0, 0, 0.075)',
        svg: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
      a: {
        color: 'rgba(0,0,0,0.4)',
      },
    },
    '&:hover, &.complete:hover': {
      td: {
        borderTop: '2px solid #00223A',
        padding: '11px 15px',
        borderBottom: '2px solid #00223A',
        '&:first-child': {
          borderLeft: '2px solid #00223A',
          padding: '11px 15px 11px 14px',
          borderRadius: '4px 0 0 4px',
        },
        '&:last-child': {
          borderRight: '2px solid #00223A',
          padding: '11px 14px 11px 15px',
        },
      },
    },
    '&.child-row': {
      td: {
        background: '#D8DBE366',
        '&:first-child': {
          paddingRight: '0',
        },
      },
    },
  },
});

export default function WCTTableBody({ children }: PropsWithChildren<{}>) {
  return <StyledTableBody>{children}</StyledTableBody>;
}
