type Listener = (isOpen: boolean) => void;

class SidebarStateManager {
  private listeners: Listener[] = [];
  private isOpen: boolean;

  constructor() {
    this.isOpen = localStorage?.getItem('openNav') === 'true';
  }

  getState(): boolean {
    return this.isOpen;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    localStorage?.setItem('openNav', this.isOpen.toString());
    this.emit();
  }

  subscribe(listener: Listener): () => void {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  private emit(): void {
    this.listeners.forEach((listener) => listener(this.isOpen));
  }
}

export const sidebarState = new SidebarStateManager();
