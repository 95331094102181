import BadgeInfo from '@components/outcome-report/components/badge-info';
import { useTheme } from '@mui/material';

interface Props {
  type: string;
}

export default function SolutionTypeBadge({ type }: Props) {
  const { palette } = useTheme();
  return (
    <BadgeInfo
      value={type}
      height="30px"
      padding="7px 10px"
      fontSize="16px"
      backgroundColor={palette.blue.dark}
      color="#fff"
    />
  );
}
