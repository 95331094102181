import { api } from '@api/api';
import { Option } from '@api/types/option';

const sectorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSectorOptions: build.query<Option[], void>({
      query: () => ({
        url: 'sector/options',
      }),
    }),
  }),
});

export const { useGetSectorOptionsQuery } = sectorApi;
