import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

interface Props {
  icon: IconProp;
  badge: ReactNode;
}

const Container = styled('div', { target: 'icon-width-badge' })({
  position: 'relative', // for the badge to be positioned absolutely
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 36,
  height: 36,
  flexShrink: 0,
  borderRadius: '18px',
  background: '#CDF1F3',

  '.badge': {
    position: 'absolute',
    top: -6,
    right: -6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    background: '#00223A',
    borderRadius: '50%',
    width: 18,
    height: 18,
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'center',
  },
});

export default function OutcomeIconWithBadge({ icon, badge }: Props) {
  return (
    <Container>
      <FontAwesomeIcon icon={icon} />
      <div className="badge">{badge}</div>
    </Container>
  );
}
