import { Typography } from '@mui/material';
import { OutlinedButton } from '@components/buttons';
import PaperContainer from '@components/containers/paper-container';
import { ReactNode } from 'react';
import { Gap } from '@components/layout-util-components/gap';

export interface NoContentContainerProps {
  icon: ReactNode;
  title: ReactNode;
  message?: ReactNode;
  createMessage?: ReactNode;
  onCreateClicked?: () => void;
  compact?: boolean;
  dark?: boolean;
}

export default function NoContentContainer({
  icon,
  title,
  message,
  createMessage,
  onCreateClicked,
  compact,
  dark,
}: NoContentContainerProps) {
  return (
    <PaperContainer
      sx={({ palette }) => ({
        padding: compact ? '20px !important' : undefined,
        backgroundColor: dark ? palette.common.grey15 : undefined,
      })}
    >
      {icon}
      <Gap size={32} />
      <Typography variant="h3" component="p">
        {title}
      </Typography>
      {message && (
        <>
          <Gap size={20} />
          <Typography variant="body2">{message}</Typography>
        </>
      )}
      {!!createMessage ? (
        <>
          <Gap size={32} />
          <OutlinedButton onClick={onCreateClicked}>
            {createMessage}
          </OutlinedButton>
        </>
      ) : null}
    </PaperContainer>
  );
}
