import { Button, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const StyledButton = styled(Button)<MuiButtonProps & AnchorHTMLAttributes<any>>(
  ({ theme: { palette } }) => ({
    textTransform: 'none',
    borderRadius: 4,
    background: '#D8DBE3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    padding: '8px 20px',
    color: palette.common.grey90,
    border: '1px solid #D8DBE3',
    boxShadow: 'none',
    '&:hover': {
      background: '#D8DBE3',
      border: '1px solid #D8DBE3',
      boxShadow: 'none',
    },
  })
);

const SecondaryButton = ({
  id,
  children,
  isBusy,
  onClick,
  disabled,
  type,
  href,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton
      id={id}
      type={type ?? 'button'}
      variant="contained"
      onClick={onClick}
      disabled={disabled || isBusy}
      href={href}
      target={!!href ? '_blank' : undefined}
      rel={!!href ? 'noreferrer' : undefined}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} />
    </StyledButton>
  );
};

export default SecondaryButton;
