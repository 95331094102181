import { ReactComponent as ImprovementIcon } from '@assets/svgs/improvement_sm_alt.svg';
import { ReactComponent as ImprovementIconDark } from '@assets/svgs/improvement-icon_sm.svg';
import { ReactComponent as RiskIcon } from '@assets/svgs/risk_sm_alt.svg';
import { ReactComponent as RiskIconDark } from '@assets/svgs/risk-icon_sm.svg';
import { ReactComponent as ProblemIcon } from '@assets/svgs/problem_sm_alt.svg';
import { ReactComponent as ProblemIconDark } from '@assets/svgs/problem-icon_sm.svg';
import { CaseAnalysisTypeType } from '@api/types/case-analysis-type/case-analysis-type';

interface Props {
  type: CaseAnalysisTypeType;
  dark?: boolean;
}

export default function RcaIcon({ type, dark }: Props) {
  return (
    <>
      {type === 'Improvement' &&
        (dark ? <ImprovementIconDark /> : <ImprovementIcon />)}
      {type === 'Risk' && (dark ? <RiskIconDark /> : <RiskIcon />)}
      {type === 'Problem' && (dark ? <ProblemIconDark /> : <ProblemIcon />)}
    </>
  );
}
