import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WarningAlert from '@components/alerts/warning-alert';
import WCTTextField from '@components/input/text-field';
import useDeleteConfirmation, {
  DeleteConfirmationViewState,
} from '@components/ui-popup/modals/delete-confirmation-hook';
import Spacer from '@components/layout-util-components/spacer';
import { ReactNode } from 'react';
import { Gap } from '@components/layout-util-components/gap';

export type RenderOtherActions = (
  props: Pick<DeleteConfirmationModalProps, 'onClose'>
) => ReactNode;

export interface DeleteConfirmationModalProps extends ModalProps<boolean> {
  title?: string;
  message?: string;
  confirmTitle?: string;
  deleteButtonMessage?: string;
  confirmMessage?: string;
  itemName?: string;
  onDelete: () => Promise<boolean>;
  otherActions?: RenderOtherActions;
  otherActionsAtEnd?: boolean;
}

const DeleteConfirmationModal = ({
  onClose,
  title,
  message,
  deleteButtonMessage,
  confirmTitle,
  confirmMessage,
  itemName,
  onDelete,
  otherActionsAtEnd,
  otherActions,
}: DeleteConfirmationModalProps) => {
  const {
    viewState,
    deleteConfirmText,
    onSubmitConfirm,
    onConfirmYes,
    canSubmitConfirm,
    isBusy,
  } = useDeleteConfirmation(onDelete);

  const onSubmit = async () => {
    if (await onSubmitConfirm()) {
      onClose(true);
    }
  };

  return (
    <Column>
      {viewState === DeleteConfirmationViewState.yesNo ? (
        <>
          <Typography variant="h6">{title ?? `Delete ${itemName}`}</Typography>
          <Gap size={15} />
          <Typography variant="body1">
            {message ?? `Are you sure you want to delete this ${itemName}?`}
          </Typography>
          <Gap size={8} />
          <ActionsRow>
            {otherActions && !otherActionsAtEnd ? (
              <>
                {otherActions({ onClose })}
                <Spacer />
              </>
            ) : null}
            <OutlinedButton onClick={() => onClose(false)}>
              Close
            </OutlinedButton>
            <PrimaryButton onClick={() => onConfirmYes()}>
              {deleteButtonMessage ?? 'Yes, Delete'}
            </PrimaryButton>
            {otherActions && otherActionsAtEnd
              ? otherActions({ onClose })
              : null}
          </ActionsRow>
        </>
      ) : viewState === DeleteConfirmationViewState.confirm ? (
        <>
          <Typography variant="h6">
            {confirmTitle ?? `Confirm deletion of ${itemName}`}
          </Typography>
          <Gap size={15} />
          <Typography variant="body1">
            {confirmMessage ??
              `To delete this ${itemName} please type 'DELETE' below to confirm the deletion of this ${itemName}`}
          </Typography>
          <Gap size={20} />
          <WarningAlert title="Please note all data will be deleted and you will be unable to restore this once deleted" />
          <Gap size={20} />
          <WCTTextField
            name="deleteEntry"
            label="Type ‘DELETE’"
            value={deleteConfirmText.value}
            error={deleteConfirmText.error}
            onChange={deleteConfirmText.set}
            required
          />
          <Gap size={8} />
          <ActionsRow>
            <OutlinedButton onClick={() => onClose(false)}>
              Cancel
            </OutlinedButton>
            <PrimaryButton
              disabled={!canSubmitConfirm}
              onClick={onSubmit}
              isBusy={isBusy}
            >
              Confirm deletion
            </PrimaryButton>
          </ActionsRow>
        </>
      ) : null}
    </Column>
  );
};

export default withModal(DeleteConfirmationModal);
