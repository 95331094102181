import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { PanelEditorSubActionContainer } from '@pages/app/rca/tabs/components/panel-editor-side-sub-action';

export interface PanelEditorSideActionProps {
  icon: ReactNode;
  label: string;
  isOpen: boolean;
  actions: Array<ReactNode>;
  onClick: () => void;
  disabled?: boolean;
}

export enum PanelEditorSideActionType {
  addEndState,
  highlight,
  collapse,
  createAsFocalPoint,
  connect,
  disprove,
  deleteItem,
}

const Container = styled('div')<{ isOpen: boolean; disabled: boolean }>(
  ({ isOpen, disabled, theme: { palette } }) => ({
    '.side-action-button': {
      cursor: !disabled ? 'pointer' : 'default',
      background: isOpen ? palette.common.black : 'rgba(255,255,255,0.175)',
      color: isOpen ? palette.common.white : '#ffffff',
      border: 'none',
      minHeight: 75,
      padding: '8px 10px',
      borderRadius: 5,
      fontFamily: 'Usual',
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '14px',
      letterSpacing: '0px',
      textAlign: 'left',
      display: 'inline-flex',
      flexDirection: 'column',
      transition: 'all 0.3s ease-in-out',
      gap: 13,
      '&:hover': {
        background: 'rgba(255,255,255,0.25)',
        cursor: 'pointer',
      },
      'svg path': {
        height: 18,
        width: 'auto',
        fill: '#fff',
        transition: 'all 0.3s ease-in-out',
      },
      ...(disabled && {
        background: 'rgba(255,255,255,0.1)',
        color: 'rgba(255,255,255,0.2)',
        opacity: '0.6',
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(255,255,255,0.1)',
          color: 'rgba(255,255,255,0.2)',
          cursor: 'pointer',
        },

        svg: {
          stroke: 'rgb(255,255,255)',
          opacity: '0.2',
        },
      }),
    },
  })
);

export default function PanelEditorSideAction({
  icon,
  label,
  isOpen,
  onClick,
  disabled,
  actions,
}: PanelEditorSideActionProps) {
  return (
    <Container isOpen={isOpen} disabled={disabled ?? false}>
      <button
        type="button"
        onClick={!disabled ? onClick : () => {}}
        disabled={disabled}
        className="side-action-button"
      >
        {icon}
        <span>{label}</span>
      </button>
      {!disabled ? (
        <PanelEditorSubActionContainer isOpen={isOpen}>
          {actions}
        </PanelEditorSubActionContainer>
      ) : null}
    </Container>
  );
}
