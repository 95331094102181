import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  onCreateClicked: () => void;
}

export default function NoFunctionalLocationInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faLocationDot} size="2x" />}
      title="You don’t currently have any company locations"
      message="There aren’t currently any company locations within the platform, you can create one below."
      createMessage="Create Company Location"
      onCreateClicked={onCreateClicked}
    />
  );
}
