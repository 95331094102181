import { styled, Table } from '@mui/material';
import { Children, PropsWithChildren, ReactNode } from 'react';
import WCTTableHeaders, { HeaderItem } from '@components/table/table-headers';
import { WCTTablePagination } from '@components/table/index';
import WCTTableBody from '@components/table/table-body';
import { TableBodySkeleton } from '@components/skeletons';
import { PaginationState } from '@components/table/hooks/use-pagination-hook';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  count: number;
  filters?: ReactNode;
  headers?: Array<HeaderItem>;
  pagination?: PaginationState;
  isLoading?: boolean;
  emptyState?: ReactNode;
  noRowClick?: boolean;
}

const StyledContainer = styled('div')<Props>(({ noRowClick }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 5px',

    ...(noRowClick && {
      tr: {
        cursor: 'default !important',
        td: {
          cursor: 'default !important',
        },
      },
    }),
  },
}));

export default function WCTTable(props: PropsWithChildren<Props>) {
  const {
    count,
    children,
    filters,
    headers,
    pagination,
    isLoading,
    emptyState,
  } = props;
  const displayEmptyState =
    emptyState != null && !isLoading && Children.count(children) === 0;

  return (
    <StyledContainer {...props}>
      {filters ? filters : null}
      {displayEmptyState ? (
        <>
          <Gap size={24} />
          {emptyState}
        </>
      ) : (
        <Table>
          {headers ? <WCTTableHeaders items={headers} /> : null}
          {isLoading ? (
            <TableBodySkeleton cols={headers?.length} />
          ) : (
            <WCTTableBody>{children}</WCTTableBody>
          )}
          {pagination ? (
            <WCTTablePagination count={count} {...pagination} />
          ) : null}
        </Table>
      )}
    </StyledContainer>
  );
}
