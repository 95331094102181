import { OutcomeReportState } from '@pages/app/outcome/outcome-report-hook';
import { styled } from '@mui/material';
import OutcomeReportScaffold from '@components/outcome-report/outcome-report-scaffold';
import ShareButton from '@pages/app/rca/outcome/components/share-button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { OutlinedButton } from '@components/buttons';

interface Props {
  state: OutcomeReportState;
}

const PageContainer = styled('div')({
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',

  '.share-button-container': {
    position: 'fixed',
    right: 20,
    top: 20,
    zIndex: 1,
  },
});

export default function ReportView({ state }: Props) {
  const { caseId, outcomeId, canShare } = state;
  const reportRef = useRef<HTMLDivElement>(null);

  // const handleExportAsPDF = async () => {
  //   const element = reportRef.current;
  //   if (!element) return;
  //   console.log('PDF: Going to export PDF');
  //   const canvas = await html2canvas(element);
  //   console.log('PDF: Created htm2canvas element');
  //   const imgData = canvas.toDataURL('image/png');
  //   console.log('PDF: Image Data', imgData);
  //   const pdf = new jsPDF('landscape');
  //   console.log('PDF: PDF', pdf);
  //   const imgWidth = 297; // A4 width in landscape mode (in mm)
  //   const pageHeight = 210; // A4 height in landscape mode (in mm)
  //   const imgProps = pdf.getImageProperties(imgData);
  //   const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

  //   let heightLeft = imgHeight;
  //   let position = 0;
  //   console.log('PDF: Adding images');
  //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;
  //   console.log('PDF: Added images');
  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }
  //   console.log('PDF: Going to save PDF');
  //   pdf.save('report.pdf');
  //   console.log('PDF: Saved PDF');
  // };

  return (
    <PageContainer>
      {canShare && (
        <div className="share-button-container">
          {/* <OutlinedButton onClick={handleExportAsPDF}>Download</OutlinedButton> */}
          <ShareButton caseId={caseId} outcomeId={outcomeId} filled />
        </div>
      )}
      <div ref={reportRef}>
        <OutcomeReportScaffold caseId={caseId} outcomeId={outcomeId} />
      </div>
    </PageContainer>
  );
}
