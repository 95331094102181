import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { isApp } from '@util/env';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(tz);

export const i18nDayJs = dayjs;

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en-GB',
    defaultNS: isApp ? 'app' : 'admin',
    ns: isApp ? ['app', 'auth', 'common'] : ['admin', 'auth', 'common'],
    //preload: isApp ? ['app', 'auth', 'common'] : ['admin', 'auth', 'common'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
