import { styled } from '@mui/material';

export const BorderedContainer = styled('div')({
  padding: 10,
  borderRadius: 4,
  border: '1px solid rgba(0,0,0,0.25)',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'flex-start',
});
