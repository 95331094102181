import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled, TableCell, TableRow, Typography } from '@mui/material';
import { OutcomeEvidenceOverviewResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-evidence-overview.response';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { EvidenceStrength } from '@api/types/case/case-evidence/evidence-strength';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import { ColorClass } from '../outcome-report-scaffold';
import OutcomeSeverityBadge from '../components/outcome-severity-badge';
import EvidenceStrengthBadge from '../components/evidence-strength-badge';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { WCTTable } from '@components/table';
import FileIndicator from '@components/outcome-report/components/file-indicator';
import EvidenceSectionOverview from './evidence-section-overview';

interface Props {
  evidenceOverview: OutcomeEvidenceOverviewResponse;
  evidence: Array<CaseEvidenceResource>;
  className: ColorClass;
  coverage: RunReportResponse<any>;
}

const StyledEvidenceSection = styled('div')(({ theme: { palette } }) => ({
  '> .dark': {
    '.overview-title': {
      color: palette.common.white,

      '.highlight': {
        color: palette.primary.main,
      },
    },
    '.stat-container .item.selected': {
      border: '2px solid #FF6C00 !important',
    },
    '.MuiTableHead-root>tr>th': {
      color: '#ffffff',
    },
  },
  '> .light': {},
  '> .white': {},
}));

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  padding: '12px 75px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  'table.MuiTable-root': {
    borderSpacing: '0 8px',
  },
  'table.MuiTable-root tr th': {
    backgroundColor: '#334E61',
    color: '#fff',
    padding: '15px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  'table.MuiTable-root tr, table.MuiTable-root tr:hover': {
    td: {
      padding: '12px 15px',
      borderTop: '1px solid rgba(0,0,0,0.25)',
      borderBottom: '1px solid rgba(0,0,0,0.25)',
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderLeft: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
        borderRight: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
    },
  },
}));

export default function EvidenceSection({
  evidenceOverview,
  evidence,
  className,
  coverage,
}: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  if (evidenceOverview.totalEvidence === 0) {
    return <></>;
  }

  const evidenceProps: Array<EvidenceRowProps> = evidence.map((evidence) => ({
    evidenceName: evidence.name,
    description: evidence.description,
    sensitivity: evidence.sensitivity,
    strength: evidence.quality,
    numberOfAssociatedCauseBoxes: evidence.chainItems?.length ?? 0,
    fileCount: evidence.caseFiles?.length ?? 0,
  }));

  return (
    <StyledEvidenceSection>
      <SectionScaffold id="evidence" className={className}>
        <Container>
          <EvidenceSectionOverview coverage={coverage} />
          <WCTTable
            noRowClick
            count={evidenceProps.length}
            headers={[
              { title: 'Name' },
              { title: 'Description' },
              { title: 'Files' },
              { title: 'Sensitivity' },
              { title: 'Associated Cause Boxes' },
              { title: 'Strength' },
            ]}
          >
            {evidenceProps.map((evidence, index) => (
              <EvidenceRow key={index} {...evidence} />
            ))}
          </WCTTable>
          <Box alignSelf="center">
            <PrimaryButton
              onClick={() => navigate(`/rca/tabs/${caseId}/evidence`)}
            >
              View Evidence <FontAwesomeIcon icon={faArrowRight} />
            </PrimaryButton>
          </Box>
        </Container>
      </SectionScaffold>
    </StyledEvidenceSection>
  );
}

interface EvidenceRowProps {
  fileCount: number;
  evidenceName: string;
  description?: string;
  sensitivity: EvidenceSensitivity;
  strength: EvidenceStrength;
  numberOfAssociatedCauseBoxes: number;
}

const EvidenceRowContainer = styled(TableRow)({});

const EvidenceRow = ({
  evidenceName,
  numberOfAssociatedCauseBoxes,
  sensitivity,
  strength,
  fileCount,
  description,
}: EvidenceRowProps) => {
  return (
    <EvidenceRowContainer className="card">
      <TableCell>
        <Typography
          sx={{
            color: '#000000 !important',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '1.25',
          }}
        >
          {evidenceName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            color: '#636D73 !important',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.25',
          }}
        >
          {description}
        </Typography>
      </TableCell>
      <TableCell width="75">
        <FileIndicator count={fileCount} />
      </TableCell>
      <TableCell>
        <OutcomeSeverityBadge severity={sensitivity} />
      </TableCell>
      <TableCell width="300">
        <AssociatedCauseBoxBadge count={numberOfAssociatedCauseBoxes} />
      </TableCell>
      <TableCell>
        <EvidenceStrengthBadge strength={strength.toString()} />
      </TableCell>
    </EvidenceRowContainer>
  );
};
