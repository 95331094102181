import { useCallback } from 'react';
import GroupDetailView, {
  GroupDetailViewProps,
} from '@pages/app/profile/components/group-detail-view';
import LeaveGroupModal, {
  LeaveGroupAction,
  LeaveGroupModalProps,
} from '@pages/app/profile/components/leave-group-modal';
import GroupForm, { GroupFormProps } from '@pages/app/groups/forms/group-form';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useDeleteCompanyGroupMutation } from '@api/endpoints/company/company-company-group.api';
import useUser from '@store/user/user-hook';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';

interface Opt {
  allowLeave?: boolean;
}

export default function usePersonalGroupActions(
  groupId: number,
  name: string,
  adminId: number,
  { allowLeave = true }: Opt = {}
) {
  const { companyUserId } = useUser();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const { showPopup } = useUiPopup();

  const canUpdate =
    usePermission(Permission.manageCompanyGroups) || adminId === companyUserId;
  const canDelete =
    usePermission(Permission.manageCompanyGroups) || adminId === companyUserId;

  const [deleteGroup] = useDeleteCompanyGroupMutation();

  const onViewGroup = useCallback(() => {
    showPopup<GroupDetailViewProps, void>(GroupDetailView, { id: groupId });
  }, [groupId, showPopup]);

  const onLeaveGroup = useCallback(async () => {
    const action = await showPopup<LeaveGroupModalProps, LeaveGroupAction>(
      LeaveGroupModal,
      {
        companyGroupId: groupId,
        companyName: name,
      }
    );

    if (action === LeaveGroupAction.edit) {
      return showPopup<GroupFormProps, CompanyGroupResource>(GroupForm, {
        groupId,
      });
    }
  }, [groupId, name, showPopup]);

  const showEditGroup = useCallback(() => {
    showPopup<GroupFormProps, CompanyGroupResource>(GroupForm, {
      groupId,
    });
  }, [groupId, showPopup]);

  const showDeleteGroup = useCallback(() => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'Group',
      message:
        // eslint-disable-next-line quotes
        "Please note you will not be able to restore this group once deleted. Any users that are linked to RCA's via this group will revert to individual or alternative group permissions.",
      onDelete: async () => {
        try {
          await deleteGroup(groupId).unwrap();
          showSuccessMessage('Group deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ groupId: number }>;
          showErrorMessage(errors?.groupId ?? message);
          return false;
        }
      },
    });
  }, [deleteGroup, groupId, showErrorMessage, showPopup, showSuccessMessage]);

  const options: Array<PopoverMenuAction> = [
    {
      label: 'View Group',
      onClick: onViewGroup,
    },
    {
      label: 'Edit Group',
      onClick: showEditGroup,
      hasPermission: canUpdate,
    },
    { divider: true },
    {
      label: 'Leave Group',
      onClick: onLeaveGroup,
      hasPermission: allowLeave,
    },
    {
      label: 'Delete Group',
      onClick: showDeleteGroup,
      hasPermission: canDelete,
    },
  ];

  return {
    onViewGroup,
    onLeaveGroup,
    options,
  };
}
