import { styled } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { useCommonTr } from '@i18n/use-common-tr';
import { Option } from '@api/types/option';
import { ErrorType } from '@api/types/api-error';
import WCTTypeaheadField from '@components/input/typeahead-field';

export interface PhoneNumber {
  dialCode?: number;
  number?: string;
}

interface PhoneNumberFieldProps {
  id: string;
  name?: string;
  label: string;
  dialCodes: Array<Option>;
  required?: boolean;
  value?: PhoneNumber;
  errors?: ErrorType<PhoneNumber>;
  onChange: (value?: PhoneNumber) => void;
  onBlur?: () => void;
}

const StyledContainer = styled('div')({
  display: 'flex',
  gap: 8,
  '&>.wct-select': {
    maxWidth: 105,
  },
});

const StyledTypeahead = styled('div')(({ theme: { palette } }) => ({
  width: '50%',
  '.MuiFormHelperText-root': {
    fontSize: 12,
    margin: 0,
  },
  label: {
    color: palette.common.textMid,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    transform: 'translate(12px, 12px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: palette.common.textMid,
      transform: 'translate(12px, 3px) scale(0.75)',
    },
  },
  '.MuiInputBase-root': {
    minHeight: '56px',
    display: 'flex',
    padding: 12,
    borderRadius: 4,
    border: `1px solid ${palette.common.grey70}`,
    background: 'white',
    '&.Mui-focused': {
      outline: '5px solid rgba(255, 108, 0, 0.12)',
    },
    '&:before, &:after': {
      display: 'none',
    },
    '&:hover': {
      background: 'white',
    },
    '.MuiInputAdornment-root': {
      button: {
        svg: {
          fill: '#FF5F30',
          width: 21,
        },
      },
    },
    input: {
      padding: 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px' /* 150% */,
      letterSpacing: '0.15px',
      marginBottom: -15,
    },
    '.MuiAutocomplete-endAdornment button[title="Clear"]': {
      display: 'none',
    },
  },
  '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiFilledInput-root':
    {
      paddingRight: '42px',
    },
}));

const StyledTextField = styled(WCTTextField)(({ theme: { palette } }) => ({
  width: '50%',
  '.MuiFormHelperText-root': {
    fontSize: 12,
    margin: 0,
  },
  label: {
    color: palette.common.textMid,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    transform: 'translate(12px, 12px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: palette.common.textMid,
      transform: 'translate(12px, 3px) scale(0.75)',
    },
  },
  '.MuiInputBase-root': {
    minHeight: '56px',
    display: 'flex',
    padding: 12,
    borderRadius: 4,
    border: `1px solid ${palette.common.grey70}`,
    background: 'white',
    '&.Mui-focused': {
      outline: '5px solid rgba(255, 108, 0, 0.12)',
    },
    '.MuiInputAdornment-root': {
      button: {
        svg: {
          fill: '#FF5F30',
          width: 21,
        },
      },
    },
    input: {
      padding: 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px' /* 150% */,
      letterSpacing: '0.15px',
      marginBottom: -15,
    },
  },
}));

export default function WCTPhoneNumberField({
  id,
  name,
  label,
  dialCodes,
  value,
  errors,
  onChange,
  onBlur,
  required,
}: PhoneNumberFieldProps) {
  const { t } = useCommonTr('input');

  return (
    <StyledContainer>
      <StyledTypeahead>
        <WCTTypeaheadField
          id={`${id}-area-code`}
          name={name ? `${name}-code` : undefined}
          label={t('phoneEntry.code')}
          value={dialCodes.find((x) => x.id === value?.dialCode)}
          onChange={(dialCode) => {
            let newValue: number | undefined;
            if (Array.isArray(dialCode)) {
              if (dialCode.length > 0) {
                newValue = dialCode[0].id;
              }
            } else {
              newValue = dialCode?.id;
            }
            onChange({
              ...value,
              dialCode: newValue,
            });
          }}
          options={dialCodes}
          required={required}
        />
      </StyledTypeahead>
      <StyledTextField
        label={label}
        name={name}
        value={value?.number}
        error={errors?.number || errors?.dialCode}
        onChange={(phoneNumber) => {
          onChange({
            ...value,
            number: phoneNumber,
          });
        }}
        onBlur={onBlur}
        required={required}
      />
    </StyledContainer>
  );
}
