import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

export default function NoFilteredFunctionalLocationInfo() {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faLocationDot} size="2x" />}
      title="No Company Locations to display"
      message="Adjust the filters to view company locations."
    />
  );
}
