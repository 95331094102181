import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import {
  ReportCell,
  ReportRow,
  RunReportResponse,
} from '@api/types/case/case-report/run-report.response';
import { Circle } from '@mui/icons-material';
import { Gap } from '@components/layout-util-components/gap';
import { v4 as uuid } from 'uuid';
import {
  RadialOverviewStat,
  ReportRadialOverview,
} from '@components/outcome-report/components/radial-chart';
import { numberFromString } from '@util/string-util';
import { ColorClass } from '../outcome-report-scaffold';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export interface InsightSectionProps {
  title: string;
  id: number;
  report: RunReportResponse<
    {
      themeId: number;
      themeName: string;
      chainItems: Record<string, string>;
      rank: number;
    },
    {
      chainItemCount: number;
      coveragePercent: number;
      coverageShare: Array<number>;
    }
  >;
  className?: ColorClass;
  autoWidth?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '110px 10%',
  gap: '50px',

  '.overview-container': {
    margin: '0 20%',
    textAlign: 'center',

    '.section-title': {
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '42px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'center',
    },

    '.section-overview': {
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '31px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'center',
      color: '#E3E3E3',
    },
  },

  '.insights-table': {
    minWidth: '50%',
    borderCollapse: 'seperate',
    borderSpacing: '0 8px',

    th: {
      margin: 0,
      padding: '15px 12px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: palette.common.white,
      backgroundColor: '#334E61',

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },

      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    },

    td: {
      margin: 0,
      padding: '15px 12px',

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },

      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    },
  },
}));

const noClientIdColours: { [key: string]: string } = {
  dark: '#253F52',
  light: '#D8DBE3',
  white: '#D8DBE3',
};

export default function InsightsSection({
  report,
  id,
  title,
  className,
  autoWidth,
}: InsightSectionProps) {
  const [selectedRowId, setSelectedRowId] = useState<number | undefined>();

  const resetSelectedRow = useDebouncedCallback(() => {
    setSelectedRowId(undefined);
  }, 200);

  const stats: Array<RadialOverviewStat> = report.data.map((row) => {
    const identifierCell = row.cells.find(
      (cell) => cell.type === 'Identifier'
    )!;
    const percentageCell = row.cells.find((cell) => cell.type === 'Percent');
    const percentage = numberFromString(percentageCell?.value ?? '0%')!;

    return {
      id: row.clientGeneratedId,
      name: identifierCell.value,
      percentage: percentage || 0,
      chartPercentage: percentage || 0,
      colour:
        row.clientGeneratedId >= 0 ? row.colour : noClientIdColours[className!],
    };
  });

  return (
    <SectionScaffold
      id={`insights-row-${id}`}
      className={className}
      autoWidth={autoWidth}
    >
      <Container>
        <div className="overview-container">
          <Typography
            variant="h2"
            fontSize="32px"
          >{`Intelligence - ${title}`}</Typography>
        </div>
        <Row className="layout">
          <ReportRadialOverview
            name={title}
            totalPercentage={report.totals.coveragePercent}
            stats={stats}
            size="large"
            highlightItemId={selectedRowId}
            onItemHover={(item) => {
              // Get from label
              const stat = stats.find((x) => x.name === item.label);
              if (stat != null) {
                setSelectedRowId(stat.id);
              }
            }}
            onItemLeave={() => setSelectedRowId(undefined)}
          />
          <Spacer />
          <table className="insights-table">
            <thead>
              <tr>
                {report.columns.map((column) => (
                  <th key={column ?? uuid()}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {report.data
                .filter((x) => x.clientGeneratedId >= 0)
                .map((row) => (
                  <InsightRow
                    key={row.clientGeneratedId}
                    isSelected={selectedRowId === row.clientGeneratedId}
                    onHover={() => {
                      resetSelectedRow.cancel();
                      setSelectedRowId(row.clientGeneratedId);
                    }}
                    onLeave={() => resetSelectedRow()}
                    {...{
                      ...row,
                      ...{
                        colour:
                          row.clientGeneratedId >= 0
                            ? row.colour
                            : noClientIdColours[className!],
                      },
                    }}
                  />
                ))}
              {report.data
                .filter((x) => x.clientGeneratedId < 0)
                .map((row) => (
                  <InsightRow
                    key={row.clientGeneratedId}
                    isSelected={selectedRowId === row.clientGeneratedId}
                    onHover={() => {
                      resetSelectedRow.cancel();
                      setSelectedRowId(row.clientGeneratedId);
                    }}
                    onLeave={() => resetSelectedRow()}
                    {...{
                      ...row,
                      ...{
                        colour:
                          row.clientGeneratedId >= 0
                            ? row.colour
                            : noClientIdColours[className!],
                      },
                    }}
                  />
                ))}
            </tbody>
          </table>
        </Row>
      </Container>
    </SectionScaffold>
  );
}

const InsightRowContainer = styled('tr')(({ theme: { palette } }) => ({
  '.rank': {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },

  '.name': {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },

  '.coverage': {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },
}));

const InsightRow = ({
  colour,
  cells,
  clientGeneratedId,
  isSelected,
  onHover,
  onLeave,
}: ReportRow & {
  isSelected: boolean;
  onHover: () => void;
  onLeave: () => void;
}) => {
  const renderCell = (cell: ReportCell) => {
    switch (cell.type) {
      case 'Empty':
        return <></>;
      case 'Identifier':
        return (
          <Row>
            <Circle htmlColor={colour} />
            <Gap size={12} />
            <div className="name">{cell.value}</div>
          </Row>
        );
      case 'Badge':
        return cell.value;
      case 'String':
      case 'Percent':
      default:
        return cell.value;
    }
  };
  return (
    <InsightRowContainer
      className={`${clientGeneratedId < 0 ? 'no-data' : ''} ${
        isSelected ? 'selected' : ''
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {cells.map((cell, index) => (
        <td key={index}>{renderCell(cell)}</td>
      ))}
    </InsightRowContainer>
  );
};
