import { Grid, Link, Typography } from '@mui/material';

export default function ContactSupportLink() {
  return (
    <Grid item alignSelf="center">
      <Typography variant="body1">
        Need Help?{' '}
        <Link href="mailto:support@whatcausedthis.com">Contact Support</Link>
      </Typography>
    </Grid>
  );
}
