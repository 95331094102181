import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Modal,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getColorForId } from '@util/colour-identifiers';
import videoDefault from '@assets/svgs/video-default.svg';

const StyledVideosHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface Props {
  sx?: SxProps<Theme>;
  videos: IHelpCentreContent[];
  label?: string;
  showAll?: boolean;
  defaultId?: number;
  direction?: 'column' | 'row';
}

export const Videos = ({
  sx,
  videos,
  label = 'Videos',
  direction = 'column',
  showAll = true,
  defaultId,
}: Props) => {
  const defaultVideo = useMemo(() => {
    return defaultId
      ? videos.find((x) => x.helpCentreContentId === defaultId)
      : undefined;
  }, [defaultId, videos]);

  const [selectedVideo, setSelectedVideo] = useState<string | undefined>(
    defaultVideo?.content
  );
  const navigate = useNavigate();

  if (!videos.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Modal open={!!selectedVideo} onClose={() => setSelectedVideo(undefined)}>
        <Box
          style={{
            width: '80%',
            height: '80%',
            margin: 'auto',
            marginTop: '5%',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={selectedVideo}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="What Caused This Help Centre"
          />
        </Box>
      </Modal>
      <StyledVideosHeaderWrapper sx={{ marginBottom: '15px' }}>
        <Typography variant="h2">{label}</Typography>
        {showAll && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography variant="h2">View All</Typography>
            <IconButton
              onClick={() => navigate('/help-centre/video')}
              aria-label="View all"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        )}
      </StyledVideosHeaderWrapper>
      <Grid mb={6} container spacing={2}>
        {videos.map((video) => (
          <Grid
            item
            xs={direction === 'column' ? 4 : 12}
            key={`video${video.helpCentreContentId}`}
          >
            <Card
              sx={{
                p: 0,
                display: 'flex',
                boxShadow: 'none',
                borderRadius: '5px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: direction,
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <CardMedia
                  sx={{
                    width: '100%',
                    img: {
                      width: '100%',
                    },
                  }}
                  image={video.mediaUrl}
                  title={video.title}
                  onClick={() => setSelectedVideo(video.mediaUrl)}
                >
                  <img src={videoDefault} alt="logo" />
                </CardMedia>
                <Box>
                  <StyledCardContent>
                    <Typography gutterBottom variant="h4" component="div">
                      {video.title}
                    </Typography>
                  </StyledCardContent>
                  <CardActions
                    sx={{
                      padding: '0 20px 30px',
                    }}
                  >
                    {video.categories.map((category) => (
                      <Chip
                        className="category_pill"
                        size="small"
                        variant="outlined"
                        key={`video-category-${category.id}`}
                        label={category.label}
                        sx={{
                          color: getColorForId(category.id),
                          borderColor: getColorForId(category.id),
                        }}
                      />
                    ))}
                  </CardActions>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
