import { Box } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { BaseCaseFile } from '@api/types/file-types';
import { useEffect, useRef, useState } from 'react';
import { isNullOrEmpty } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';
import WCTSwitchField from '../switch-field';
import CaseFileIcon from '@components/case-file/case-file-icon';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';

interface Props {
  file: BaseCaseFile;
  caseId: number;
  onRemove: () => void;
  onUpdateFile: (name: string, blur: boolean) => void;
  error?: boolean;
}

export default function CaseFileItem({
  file,
  caseId,
  onRemove,
  onUpdateFile,
  error,
}: Props) {
  const {
    file: { length, contentType, filename },
    smallThumbnailFile,
    largeThumbnailFile,
    smallBlurredThumbnailFile,
    largeBlurredThumbnailFile,
    showBlurred,
  } = file;
  const [initialFileName] = useState(filename);
  const [newName, setNewName] = useState(filename);
  const [newBlur, setNewBlur] = useState<boolean>(showBlurred ?? false);

  const updateRef = useRef(onUpdateFile);
  useEffect(() => {
    if (isNullOrEmpty(newName)) {
      updateRef.current(initialFileName, newBlur);
    } else {
      updateRef.current(newName, newBlur);
    }
  }, [initialFileName, newName, newBlur]);

  return (
    <div className="item complete">
      <Box width={344}>
        <WCTTextField
          placeholder="Add file name"
          value={newName}
          onChange={setNewName}
          error={error && newName === '' ? 'Name is required' : ''}
        />
      </Box>
      <Gap size={20} />
      <Box width={50} height={50}>
        <CaseFileIcon
          size={50}
          contentType={contentType}
          fileMetadataId={smallThumbnailFile?.fileMetadataId}
          blurredFileMetadataId={smallBlurredThumbnailFile?.fileMetadataId}
          largeFileMetadataId={largeThumbnailFile?.fileMetadataId}
          largeBlurredFileMetadataId={largeBlurredThumbnailFile?.fileMetadataId}
          type={CaseFileType.file}
          blurred={showBlurred}
          caseId={caseId}
        />
      </Box>
      <Gap size={10} />
      <Box width={110}>
        {smallThumbnailFile && (
          <WCTSwitchField
            value={newBlur ?? false}
            onChange={setNewBlur}
            statusLabel={{
              on: 'Masked',
              off: 'Unmasked',
            }}
          />
        )}
      </Box>
      {/* <Gap size={20} />
      <Spacer />
      <span>{initialFileName}</span> */}
      <Gap size={128} />
      {/* convert length to MB */}
      <span>{(length / (1024 * 1024)).toFixed(1)}mb</span>
      <Spacer />
      <Gap size={20} />
      <FlatButton onClick={onRemove}>
        <FontAwesomeIcon icon={faTrash} />
      </FlatButton>
    </div>
  );
}
