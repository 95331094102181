import { api } from '@api/api';
import { CaseAnalysisType } from '@api/types/case-analysis-type/case-analysis-type';
import { CaseAnalysisTypeParams } from '@api/types/case-analysis-type/case-analysis-type-params';
import { Option } from '@api/types/option';

const caseAnalysisTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseAnalysisTypeOptions: build.query<Option[], CaseAnalysisTypeParams>({
      query: (params: CaseAnalysisTypeParams) => ({
        url: 'caseAnalysisType/options',
        params,
      }),
      providesTags: ['CaseAnalysisType'],
    }),
    getCaseAnalysisTypes: build.query<
      CaseAnalysisType[],
      CaseAnalysisTypeParams
    >({
      query: (params: CaseAnalysisTypeParams) => ({
        url: 'caseAnalysisType',
        params,
      }),
      providesTags: ['CaseAnalysisType'],
    }),
  }),
});

export const {
  useGetCaseAnalysisTypeOptionsQuery,
  useGetCaseAnalysisTypesQuery,
} = caseAnalysisTypeApi;
