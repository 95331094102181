import { Skeleton } from '@mui/material';

interface Props {
  width?: number | string;
  height?: number | string;
}
export default function InlineBoxSkeleton({ width, height }: Props) {
  return (
    <Skeleton
      variant="text"
      sx={{ display: 'inline-block' }}
      width={width ?? 10}
      height={height}
    />
  );
}
