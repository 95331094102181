import { Option } from '@api/types/option';
import { useMemo } from 'react';
import { useCommonTr } from '@i18n/use-common-tr';

type BinaryOptionsProps = {
  falseLabel?: string;
  trueLabel?: string;
  reverse?: boolean;
};

export function useBinaryOptions({
  falseLabel,
  trueLabel,
  reverse,
}: BinaryOptionsProps = {}) {
  const { t } = useCommonTr('options');

  return useMemo((): Array<Option<boolean>> => {
    return reverse
      ? [
          { id: true, label: trueLabel || t('binary.trueLabel') },
          { id: false, label: falseLabel || t('binary.falseLabel') },
        ]
      : [
          { id: false, label: falseLabel || t('binary.falseLabel') },
          { id: true, label: trueLabel || t('binary.trueLabel') },
        ];
  }, [falseLabel, reverse, t, trueLabel]);
}
