import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, TableCell, TableRow, Typography, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { OutcomeSolutionOverviewResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-solution-overview.response';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { currencyFormatter } from '@i18n/formatters';
import { ReportUtil } from '@util/report-util';
import { ColorClass } from '../outcome-report-scaffold';
import { ThemeResource } from '@api/types/theme/theme.resource';
import { OutcomeSolutionChainItemResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-solution-chain-item.response';
import { useMemo } from 'react';
import SolutionEffectivenessBadge from '../components/solution-effectiveness-badge';
import getGroupedSolutionTermAndEffectivenessReports = ReportUtil.getGroupedSolutionTermAndEffectivenessReports;
import { WCTTable } from '@components/table';
import {
  ChainItemRadialOverviewStat,
  ChainItemReportRadialOverview,
} from '../components/chain-item-radial-chart';
import SolutionValueBadge from '../components/solution-value-badge';
import SolutionsSectionOverview, {
  SolutionTermCoverage,
} from './solutions-section-overview';

interface CoverageMetaData {
  solutionId: number;
  chainItems: Record<string, string>;
  rank: number;
}

interface CoverageTotals {
  chainItemCount: number;
  coveragePercent: number;
  coverageShare: Array<number>;
  solutionCount: number;
  linkedSolutionAverageEffectiveness: number;
}

interface Props {
  solutionCount: number;
  solutions: Array<OutcomeSolutionOverviewResponse>;
  coverage: RunReportResponse<CoverageMetaData, CoverageTotals>;
  effectivnessReport: RunReportResponse<
    {
      solutionId: number;
      efficacyName?: string;
      chainItems: Record<number, string>;
      rank: number;
    },
    {
      chainItemCount: number;
      coveragePercent: number;
      coverageShare: Array<number>;
      solutionCount: number;
      linkedSolutionAverageEffectiveness: number;
    }
  >;
  termReport: SolutionTermCoverage;
  currency?: string;
  className: ColorClass;
  limit: number;
}

const StyledSolutionSection = styled('div')(({ theme: { palette } }) => ({
  '> .dark': {
    '.card': {
      background: '#fff',
    },
  },
  '> .light': {
    '.card': {
      background: '#fff',
      border: '1px solid rgba(0,0,0,0.25)',
    },
  },
  '> .white': {
    '.card': {
      background: '#FFF9EF',
      border: '1px solid rgba(0,0,0,0.25)',
    },
  },
}));

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  padding: '80px 8%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  'table.MuiTable-root': {
    borderSpacing: '0 8px',
  },
  'table.MuiTable-root tr th': {
    backgroundColor: '#334E61',
    color: '#fff',
    padding: '15px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  'table.MuiTable-root tr, table.MuiTable-root tr:hover': {
    td: {
      padding: '12px 15px',
      borderTop: '1px solid rgba(0,0,0,0.25)',
      borderBottom: '1px solid rgba(0,0,0,0.25)',
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderLeft: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
        borderRight: '1px solid rgba(0,0,0,0.25)',
        padding: '12px 15px',
      },
    },
  },
}));

export default function SolutionsSection({
  solutions,
  termReport,
  currency = 'GBP',
  className,
}: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const solutionCards: Array<SolutionCardProps> = solutions.map((solution) => ({
    name: solution.name,
    cost: solution.cost,
    chainItems: solution.chainItems,
    description: solution.description,
    associatedCauseBoxCount: solution.chainItems?.length ?? 0,
    currency,
    themes: solution.themes,
    coverage: termReport.totals.chainItemCount
      ? (solution.chainItems.length / termReport.totals.chainItemCount) * 100
      : 0,
  }));

  return (
    <StyledSolutionSection>
      <SectionScaffold id="closing-statement" className={className}>
        <Container>
          <SolutionsSectionOverview termReport={termReport} />
          <WCTTable
            noRowClick
            count={solutionCards.length}
            headers={[
              { title: 'Name' },
              { title: 'Description' },
              { title: 'Cause Boxes' },
              { title: 'Avg. Effectiveness' },
              { title: 'Cost' },
              { title: 'Additional Cost' },
              { title: 'Total Cost' },
              { title: 'Coverage' },
            ]}
          >
            {solutionCards.map((solution, index) => (
              <SolutionRow key={index} {...solution} />
            ))}
          </WCTTable>
          <Box alignSelf="center">
            <PrimaryButton
              onClick={() => navigate(`/rca/tabs/${caseId}/solutions`)}
            >
              View More Solutions <FontAwesomeIcon icon={faArrowRight} />
            </PrimaryButton>
          </Box>
        </Container>
      </SectionScaffold>
    </StyledSolutionSection>
  );
}

interface SolutionCardProps {
  type?: string;
  name: string;
  description?: string;
  effectivness?: string;
  term?: string;
  cost?: number;
  currency?: string;
  themes?: ThemeResource[];
  chainItems: Array<OutcomeSolutionChainItemResponse>;
  coverage: number;
}

const SolutionCardContainer = styled(TableRow)(({ theme: { palette } }) => ({
  // background: palette.common.grey15,
  // borderRadius: 8,
  // padding: '25px 25px 0',
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'flex-start',
  // '.solution-stat, .scoreContainer': {
  //   gap: 8,
  //   display: 'flex',
  //   flexDirection: 'column',
  //   '.label': {
  //     lineHeight: '120%',
  //   },
  //   '.value.total': {
  //     fontWeight: '500',
  //     color: '#00223A',
  //   },
  //   '.MuiChip-root': {
  //     borderStyle: 'solid',
  //     height: 32,
  //   },
  // },
  // '.solutionEffectiveness': {
  //   marginLeft: '-25px',
  //   marginRight: '-25px',
  //   padding: '20px 25px',
  //   borderTop: '1px solid rgba(0,0,0, 0.25)',
  //   '> *': {
  //     flex: '0 0 33.33%',
  //   },
  // },
  // '.solutionFooter': {
  //   marginLeft: '-25px',
  //   marginRight: '-25px',
  //   padding: '20px 25px',
  //   borderTop: '1px solid rgba(0,0,0, 0.25)',
  //   '> *': {
  //     flex: '0 0 33.33%',
  //   },
  // },
}));

const SolutionRow = ({
  cost,
  description,
  effectivness,
  name,
  term,
  type,
  currency = 'GBP',
  themes,
  chainItems,
  coverage,
}: SolutionCardProps) => {
  const accumulatedCost = useMemo(() => {
    const additionalCosts = chainItems.map(
      (chainItem) => chainItem.additionalCost ?? 0
    );
    return additionalCosts.reduce((acc, cost) => acc + cost, 0);
  }, [chainItems]);

  const averageEffectiveness = useMemo(() => {
    const linkedChainItems = chainItems.filter(
      (x) => x.efficacy && x.efficacy > 1
    );

    if (linkedChainItems.length === 0) {
      return 0;
    }

    return (
      linkedChainItems
        .map((chainItem) => chainItem.efficacy ?? 0)
        .reduce((acc, cost) => acc + cost, 0) / linkedChainItems.length
    );
  }, [chainItems]);

  return (
    <SolutionCardContainer>
      <TableCell>
        <Typography
          sx={{
            color: '#000000 !important',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '1.25',
          }}
        >
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            color: '#636D73 !important',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.25',
          }}
        >
          {description}
        </Typography>
      </TableCell>
      <TableCell>
        <AssociatedCauseBoxBadge count={chainItems.length} />
      </TableCell>
      <TableCell>
        <SolutionEffectivenessBadge effectiveness={averageEffectiveness} />
      </TableCell>
      <TableCell>
        <Typography className="value">
          {cost
            ? currencyFormatter(cost.toString(), {
                decimalPlaces: 0,
                currency,
              })
            : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        {accumulatedCost > 0 && (
          <Typography>
            {'+ '}
            {currencyFormatter(accumulatedCost.toString(), {
              decimalPlaces: 0,
              currency,
            })}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {accumulatedCost > 0 && (
          <strong>
            {currencyFormatter((accumulatedCost + (cost ?? 0)).toString(), {
              decimalPlaces: 0,
              currency,
            })}
          </strong>
        )}
      </TableCell>
      <TableCell>
        <SolutionValueBadge value={`${coverage?.toFixed(2) ?? 0}%`} />
      </TableCell>
    </SolutionCardContainer>
  );
};
