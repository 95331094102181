import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@components/ui-popup/modals';
import withModal from '@components/ui-popup/modals/modal-hoc';
import { OutlinedButton } from '@components/buttons';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';

export enum DisproveConfirmationAction {
  disproveAll,
  moveToStorage,
}
export interface DisproveConfirmationProps
  extends UiPopupProps<DisproveConfirmationAction> {}

function DisproveConfirmationModal(props: DisproveConfirmationProps) {
  const { onClose, ...confirmationProps } = props;

  const handleOnClose = (value?: boolean) => {
    if (value != null) {
      if (value) {
        onClose(DisproveConfirmationAction.moveToStorage);
      } else {
        onClose(DisproveConfirmationAction.disproveAll);
      }
    }

    onClose();
  };

  return (
    <ConfirmationModal
      {...(confirmationProps as ConfirmationModalProps)}
      title="Disprove Cause Box"
      message={
        'Do you want to continue and disprove this cause box and all child boxes? If so you are able to move child boxes to another path.\n' +
        '\n' +
        'Alternatively you can click below to move all child elements to the Cause storage area.'
      }
      yesButtonLabel="Move child items to Cause stack"
      noButtonLabel="Continue"
      onClose={handleOnClose}
      otherActions={() => (
        <OutlinedButton onClick={() => onClose?.call(undefined)}>
          Cancel
        </OutlinedButton>
      )}
      fillNoButton
    />
  );
}

export default withModal(DisproveConfirmationModal);
