export enum SubscriptionStatus {
  unknown = 'Unknown',
  active = 'Active',
  trialing = 'Trialing',
  pastDue = 'PastDue',
  paused = 'Paused',
  canceled = 'Canceled',
  incomplete = 'Incomplete',
  incompleteExpired = 'Incomplete_Expired',
  unpaid = 'Unpaid',
}
