import useField from '@hooks/use-field-hook';
import { lessThan, required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { ApiError } from '@api/types/api-error';
import { CreateTaskDetailRequest } from '@api/types/task-detail/create-task-detail.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';
import useUser from '@store/user/user-hook';
import { TaskPriority } from '@api/types/task-detail/task-priority';
import { UpdateTaskDetailRequest } from '@api/types/task-detail/update-task-detail.request';
import { useState } from 'react';
import { TaskStatus } from '@api/types/task-detail/task-status';
import useCompleteTask from '@hooks/tasks/use-complete-tasks';
import {
  useCreateTaskForUserMutation,
  useGetTaskDetailForCaseQuery,
  useUpdateTaskForUserMutation,
} from '@api/endpoints/task-detail.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { useGetCompanyUserOptionsQuery } from '@api/endpoints/case.api';

export default function useTaskForm(taskId?: number, caseId?: number) {
  const { fullName: userFullName } = useUser();
  const [now] = useState(new Date());
  const isEdit = taskId != null;

  const { t } = useAppTr('taskForm');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { companyUserId } = useUser();

  const { data: taskDetail, isLoading: loadingDetail } =
    useGetTaskDetailForCaseQuery(
      { id: taskId!, caseId: caseId! },
      {
        skip: taskId == null || caseId == null,
      }
    );

  const { data: userOptions, isLoading: loadingUsers } =
    useGetCompanyUserOptionsQuery({
      caseId,
      excludeCaseRoles: ['ReadOnly'],
    });

  const [createTask] = useCreateTaskForUserMutation();
  const [updateTask] = useUpdateTaskForUserMutation();

  const {
    completeTask,
    openTask,
    isBusy: isTogglingTaskStatus,
  } = useCompleteTask();

  const isCompleteToggleStatus = useField<boolean>(
    [],
    taskDetail?.status === TaskStatus.complete
  );
  const name = useField<string>([required(), lessThan(50)], taskDetail?.title);
  const description = useField<string>([], taskDetail?.description);
  const assignedUser = useField<number>(
    [],
    taskDetail?.assignedToCompanyUserId ?? companyUserId
  );
  const dueDate = useField<string | undefined>(
    [required()],
    taskDetail?.dueDate
  );

  const priority = useField<TaskPriority>([required()], taskDetail?.priority);

  const {
    isValid,
    isDirty: isFormDirty,
    validateAll,
  } = useFieldsWatcher([name, description, assignedUser, dueDate, priority]);

  const [submit, isSubmitting] = useBusyAction(async () => {
    if (!validateAll()) {
      return false;
    }

    if (taskId != null) {
      return updateTask({
        taskDetailId: taskId!,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
        caseId: caseId!,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail', taskId!);
          showSuccessMessage(t('toasts.success.edit', { name: name.value }));
          return true;
        })
        .catch(({ message, errors }: ApiError<UpdateTaskDetailRequest>) => {
          showErrorMessage(message);

          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    } else {
      return createTask({
        caseId: caseId!,
        title: name.value,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail');
          showSuccessMessage(t('toasts.success.create'));
          return true;
        })
        .catch(({ message, errors }: ApiError<CreateTaskDetailRequest>) => {
          showErrorMessage(message);

          name.setError(errors?.title);
          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    }
  });

  const isBusy = isSubmitting || isTogglingTaskStatus;
  const isLoading = loadingUsers || loadingDetail;
  const canSubmit =
    isValid &&
    (isFormDirty || isCompleteToggleStatus.isDirty) &&
    !isLoading &&
    !isBusy;

  return {
    isEdit,
    name,
    isCompleteToggleStatus,
    description,
    assignedUser,
    dueDate,
    priority,
    isLoading,
    isBusy,
    isFormDirty,
    canSubmit,
    userOptions,
    submit,
    createdDate: taskDetail?.created ?? now,
    createdBy: taskDetail?.createdByName ?? userFullName,
    taskName: taskDetail?.title ?? '',
    isComplete: isEdit && isCompleteToggleStatus.value,
    complete: completeTask,
    open: openTask,
  };
}
