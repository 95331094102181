import { Address } from '@components/input/address-field';
import { DefaultTFuncReturn } from 'i18next';

export function isNullOrEmpty(str?: string) {
  return str == null || str.length === 0;
}

export function numberFromString(str?: string | number) {
  let num = str;

  if (num == null) {
    return undefined;
  } else {
    // noinspection SuspiciousTypeOfGuard
    if (typeof num === 'number') {
      return num;
    } else if (typeof num === 'string') {
      const match = num.match(/^-?\d+(?:\.\d+)?%?$/);
      return match ? parseFloat(match[0]) : undefined;
    }
  }

  try {
    return parseInt(num, 10);
  } catch (_) {
    return undefined;
  }
}

export function maskPhoneNumber(dialCode: string, phone: string) {
  const mask = '********';
  return `${dialCode}${mask}${phone.substring(mask.length)}`;
}

export function getAddressString(address?: Address) {
  if (address == null) {
    return '';
  }

  return [
    address.addressLineOne,
    address.addressLineTwo,
    address.town,
    address.county,
    address.country,
    address.postcode,
  ]
    .filter((x) => !isNullOrEmpty(x))
    .join(', ');
}

export function optionalLabel(
  label?: string | DefaultTFuncReturn,
  required?: boolean
) {
  if (label == null) {
    return;
  }

  return required ? `${label} *` : label;
}

export function truncateString(
  input?: string,
  maxLength: number = 10,
  suffix = '...'
): string {
  if (!input) {
    return '';
  }

  if (input.length <= maxLength) {
    return input;
  } else {
    return input.slice(0, maxLength) + suffix;
  }
}

export function isLocationString(location: string): boolean {
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

  if (urlPattern.test(location)) {
    return true;
  }

  // const fileLocationKeywords = ['file://', 'C:\\', 'D:\\', '/']; // Add more if needed
  // for (const keyword of fileLocationKeywords) {
  //   if (location.includes(keyword)) {
  //     return true;
  //   }
  // }

  return false;
}

export function formatNumberToDecimals(
  value: string | number,
  decimals = 1
): string {
  if (typeof value === 'string') {
    value = numberFromString(value) ?? 0;
  }

  if (value % 1 === 0) {
    return value.toString();
  } else {
    return value.toFixed(decimals);
  }
}
