import { Box, Grid, Link, Skeleton, styled, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import WCTPhoneNumberField from '@components/input/phone-number-field';
import WCTCheckboxField from '@components/input/checkbox-field';
import useCreateAccount from '@pages/auth/create-account-hook';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAuthTr } from '@i18n/use-auth-tr';
import HaveAccountLoginLink from '@pages/auth/components/have-account-login-link';
import {
  ButtonSkeleton,
  CheckboxSkeleton,
  InputSkeleton,
} from '@components/skeletons';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { PageAlertDisplay } from '@components/alerts';
import { PrimaryButton } from '@components/buttons';

type QueryParams = {
  packageId?: string;
};

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.MuiFormControlLabel-root': {
    gap: 4,
  },
  'label span': {
    fontSize: '14px',
  },
});

export default function CreateAccountPage() {
  const { packageId } = useSearchParamsTyped<QueryParams>();

  const {
    dialCodes,
    email,
    firstName,
    surname,
    phone,
    optIn,
    agreeToTerms,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
  } = useCreateAccount(+(packageId ?? ''));

  const { t } = useAuthTr('createAccount');
  const { t: commonTr } = useAuthTr('common');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const didSubmit = await submit();
    if (didSubmit) {
      navigate('../confirm-email-request', {
        replace: true,
        state: email.value,
      });
    }
  };

  return (
    <Grid
      noValidate
      component="form"
      container
      direction="column"
      justifyContent="center"
      gap="25px"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h2" style={{ fontSize: '32px' }}>
        {isLoading ? <Skeleton /> : t('title')}
      </Typography>
      <Typography variant="body1" color="common.textMid">
        {isLoading ? <Skeleton /> : t('message')}
      </Typography>
      <PageAlertDisplay />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.email')}
          value={email.value}
          error={email.error}
          onChange={email.set}
          required
        />
      )}

      <Grid container wrap="nowrap" gap={1}>
        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('form.firstName')}
              value={firstName.value}
              error={firstName.error}
              onChange={firstName.set}
              required
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('form.surname')}
              value={surname.value}
              error={surname.error}
              onChange={surname.set}
              required
            />
          )}
        </Grid>
      </Grid>
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTPhoneNumberField
          id="phone-number"
          dialCodes={dialCodes!}
          value={phone.value}
          errors={phone.errors}
          onChange={phone.set}
          label={t('form.phone')}
          required
        />
      )}
      {isLoading ? (
        <CheckboxSkeleton />
      ) : (
        <StyledBox style={{ marginBottom: '-20px' }}>
          <WCTCheckboxField
            label={commonTr('marketingOptIn')}
            value={optIn.value}
            onChange={optIn.set}
          />
        </StyledBox>
      )}

      {isLoading ? (
        <CheckboxSkeleton />
      ) : (
        <StyledBox>
          <WCTCheckboxField
            label={
              <Trans t={commonTr} i18nKey="terms">
                {/*TODO(Nick): Implement terms of use*/}I agree to the
                <Link href="#terms" target="_blank">
                  Terms of Use
                </Link>
                {/*TODO(Nick): Implement privacy policy*/}
                <Link href="#privacy" target="_blank">
                  Privacy Policy
                </Link>
              </Trans>
            }
            value={agreeToTerms.value}
            onChange={agreeToTerms.set}
            error={agreeToTerms.error}
          />
        </StyledBox>
      )}

      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <PrimaryButton
          type="submit"
          size="large"
          onClick={onSubmit}
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          <Typography>{t('buttonLabel')}</Typography>
        </PrimaryButton>
      )}

      {isLoading ? (
        <Box alignSelf="center">
          <Skeleton variant="rectangular">
            <HaveAccountLoginLink />
          </Skeleton>
        </Box>
      ) : (
        <HaveAccountLoginLink />
      )}
    </Grid>
  );
}
