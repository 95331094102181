import {
  NodeCollapseState,
  RcaNode,
  RcaNodeType,
  StorageNode,
} from '@store/rca-editor/types';
import { ReactFlowInstance } from 'reactflow';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import {
  GroupedNode,
  MetaGroupedNodes,
  NodeDisplayBadge,
  NormalGroupedNodes,
  selectHasFocusedNode,
  selectIsAnyPanelOpen,
} from '@store/rca-editor/selectors';
import { numberFromString, truncateString } from '@util/string-util';
import { store } from '@store/store';

// This isn't technically the react way, but it's the only way to get the react flow instance
// outside of the chart component.

export let reactFlowInstance: ReactFlowInstance | undefined;
export let chartStorageContainer: HTMLDivElement | undefined;

export namespace RcaUtil {
  export const NODE_WIDTH = 350;
  export const NODE_HEIGHT = 102;
  export const HALF_NODE_WIDTH = NODE_WIDTH / 2.0;
  export const HALF_NODE_HEIGHT = NODE_HEIGHT / 2.0;

  export function setReactFlowInstance(instance?: ReactFlowInstance) {
    reactFlowInstance = instance;
  }

  export function setChartStorageContainer(container: HTMLDivElement | null) {
    chartStorageContainer = container ?? undefined;
  }

  export function isPointInStorageContainer(x: number, y: number) {
    if (chartStorageContainer == null) {
      return false;
    }

    const rect = chartStorageContainer.getBoundingClientRect();

    return (
      x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
    );
  }

  export function isCauseNode(node: RcaNode) {
    return (
      node.type == null ||
      node.type === RcaNodeType.default ||
      node.type === RcaNodeType.endState
    );
  }

  export function isDraggable(node: RcaNode) {
    return !isMetaNode(node) && !!node.data.label;
  }

  export function isMetaNode(node?: RcaNode) {
    if (node == null) {
      return false;
    }
    return isMetaNodeType(node.type);
  }

  export function isMetaNodeType(nodeType?: RcaNodeType) {
    return nodeType === RcaNodeType.or || nodeType === RcaNodeType.andOr;
  }

  export function isAttachable(node: RcaNode) {
    return (
      node.type == null ||
      node.type === RcaNodeType.default ||
      node.type === RcaNodeType.or ||
      node.type === RcaNodeType.andOr
    );
  }

  export function sortNode(a: RcaNode, b: RcaNode): number {
    const aMeta = RcaUtil.isMetaNode(a);
    const bMeta = RcaUtil.isMetaNode(b);
    if (aMeta && !bMeta) {
      return 1;
    } else if (!aMeta && bMeta) {
      return -1;
    } else if (aMeta && bMeta) {
      return a.data.sortOrder - b.data.sortOrder;
    }

    const aConnection = a.type === RcaNodeType.connection;
    const bConnection = b.type === RcaNodeType.connection;
    if (aConnection && !bConnection) {
      return 1;
    } else if (!aConnection && bConnection) {
      return -1;
    } else if (aConnection && bConnection) {
      return a.data.sortOrder - b.data.sortOrder;
    }

    return a.data.sortOrder - b.data.sortOrder;
  }

  export function sortNodes(nodes: Array<RcaNode>) {
    return [...nodes].sort(sortNode);
  }

  // Iterates through the array of nodes and bump's any node's sortOrder that is above
  // [insertingSortOrder] by 1
  export function incrementInsertedSortOrder(
    nodes: Array<RcaNode>,
    insertingSortOrder: number
  ) {
    console.assert(insertingSortOrder >= 0);

    return nodes.map((node) => {
      if (node.data.sortOrder < insertingSortOrder) {
        return node;
      }
      return {
        ...node,
        data: {
          ...node.data,
          sortOrder: node.data.sortOrder + 1,
        },
      };
    });
  }

  export function getBestSortOrder(nodes: Array<RcaNode>, type: RcaNodeType) {
    if (nodes.length === 0) {
      return 0;
    }

    // get last default node in nodes array
    const lastDefaultNode = nodes
      .filter((node) => node.type === RcaNodeType.default)
      .sort((a, b) => b.data.sortOrder - a.data.sortOrder)?.[0];
    // get last connection node in nodes array
    const lastConnectionNode = nodes
      .filter((node) => node.type === RcaNodeType.connection)
      .sort((a, b) => b.data.sortOrder - a.data.sortOrder)?.[0];

    if (type === RcaNodeType.default) {
      return (lastDefaultNode?.data.sortOrder ?? -1) + 1;
    } else if (type === RcaNodeType.connection) {
      if (lastConnectionNode == null) {
        return (lastDefaultNode?.data.sortOrder ?? -1) + 1;
      } else {
        return (lastConnectionNode.data.sortOrder ?? -1) + 1;
      }
    }

    const sortOrder = nodes[nodes.length - 1].data.sortOrder + 1;
    return isNaN(sortOrder) ? 0 : sortOrder;
  }

  // Iterates nodes and sets their sort order sequentially
  export function setSortOrders(nodes: Array<GroupedNode>) {
    const normalNodes = nodes.filter(
      (x) => x.type === 'normal'
    ) as Array<NormalGroupedNodes>;
    normalNodes.sort((a, b) => {
      if (a.isConnection && !b.isConnection) {
        return 1;
      } else if (!a.isConnection && b.isConnection) {
        return -1;
      }
      return 0;
    });

    const metaNodes = nodes.filter(
      (x) => x.type === 'meta'
    ) as MetaGroupedNodes[];

    const sortedNormalNodes = [...normalNodes.map((x) => x.node)].sort(
      sortNode
    );
    const sortedMetaNodes = metaNodes.flatMap((x) => x.children).sort(sortNode);

    return [...sortedNormalNodes, ...sortedMetaNodes].map(
      (node, index): RcaNode => {
        return {
          ...node,
          data: {
            ...node.data,
            sortOrder: index,
          },
        };
      }
    );
  }

  export function formatHealthScore(healthScore?: number | string) {
    const num = numberFromString(healthScore);
    return `${num?.toFixed(0) ?? '0'}%`;
  }

  export function getNodeDisplayMaxBadges(
    badges: NodeDisplayBadge[],
    max: number = 2
  ) {
    function truncateBadges(badges: NodeDisplayBadge[]) {
      return badges.map((x) => ({
        ...x,
        text: truncateString(x.text, badges.length > 1 ? 5 : 22),
      }));
    }

    if (badges.length <= max) {
      return truncateBadges(badges);
    }

    const diff = badges.length - max;
    return [
      ...truncateBadges(badges.slice(0, max)),
      { text: `+${diff}`, baseColor: '#b1afaf', rank: -99 },
    ];
  }

  export function getNodePosFromCursor(e: any) {
    if (reactFlowInstance == null) {
      throw Error('reactFlowInstance is null');
    }

    const zoom = reactFlowInstance.getViewport().zoom;
    return reactFlowInstance.screenToFlowPosition({
      x: e.clientX - NODE_WIDTH * 0.4 * zoom,
      y: e.clientY - NODE_HEIGHT * 0.5 * zoom,
    });
  }

  export function getHealthStateFromScore(health?: number) {
    if (health == null) {
      return 'not-applicable';
    }

    if (health >= 70) {
      return 'healthy';
    } else if (health >= 30) {
      return 'average';
    } else if (health < 0) {
      return 'not-applicable';
    }

    return 'unhealthy';
  }

  export function getHealthScoreColorFromScore(health: number) {
    const state = getHealthStateFromScore(health);
    switch (state) {
      case 'healthy':
        return '#3CA680';
      case 'average':
        return '#ff6C00';
      case 'unhealthy':
        return '#e44848';
      case 'not-applicable':
        return '#D8DBE3';
    }
  }

  export function smoothFocusToNode(node: RcaNode) {
    const inst = reactFlowInstance;
    if (inst == null) {
      return;
    }

    const zoom = inst.getZoom();

    inst.fitView({
      nodes: [node],
      duration: 500,
      minZoom: zoom,
      maxZoom: zoom,
      padding: 0.5,
    });
  }

  export function snapFocusToNode(
    node: RcaNode,
    offset: boolean = false,
    centerIfPossible: boolean = false
  ) {
    const inst = reactFlowInstance;
    if (inst == null) {
      return;
    }

    const isAnyPanelOpen = selectIsAnyPanelOpen(store.getState());
    const hasFocusedNode = selectHasFocusedNode(store.getState());

    const zoom = inst.getZoom();
    const shouldOffset = offset || (isAnyPanelOpen && hasFocusedNode);

    const canCenter = !shouldOffset && centerIfPossible;

    const xOffsetMultiplier = canCenter ? 0.5 : 0.35;
    const appBarHeight = 80;

    const xOffset =
      (shouldOffset
        ? 50 + HALF_NODE_WIDTH * zoom
        : document.body.clientWidth * xOffsetMultiplier) -
      node.position.x * zoom -
      HALF_NODE_WIDTH * zoom;

    const yOffset =
      document.body.clientHeight * 0.5 -
      appBarHeight -
      HALF_NODE_HEIGHT -
      node.position.y * zoom;

    inst.setViewport({
      x: xOffset,
      y: yOffset,
      zoom,
    });
  }

  export function populateNodesFromServerData(
    nodes: Array<RcaNode>,
    storageNodes: Array<StorageNode>,
    data: ChainDetailResponse,
    focusedNodeId?: string
  ) {
    // Populate the nodes that don't have a cause box id
    for (const node of nodes) {
      if (node.data.chainItemId == null) {
        const serverNode =
          data.nodes.find((x) => x.id === node.id) ??
          data.storage.find((x) => x.clientUuid === node.id);
        if (serverNode != null) {
          if ('data' in serverNode) {
            const serverData = serverNode.data;
            const { chainItemId, caseId, endStateId, healthScore } = serverData;
            node.draggable = !node.data.isRoot;
            node.selected = focusedNodeId === node.id;
            node.data = {
              ...node.data,
              chainItemId: chainItemId,
              caseId: caseId,
              endStateId: endStateId,
              healthScore: healthScore,
              highlight: focusedNodeId === node.id,
            };
          } else {
            node.selected = focusedNodeId === node.id;
            node.data = {
              highlight: focusedNodeId === node.id,
              ...node.data,
              ...serverNode,
            };
          }
        }
      }
    }

    // Populate storage nodes that don't have a cause box id
    for (const node of storageNodes) {
      if (node.chainItemId == null) {
        const serverNode =
          data.nodes.find((x) => x.id === node.clientUuid) ??
          data.storage.find((x) => x.clientUuid === node.clientUuid);
        if (serverNode != null) {
          if ('data' in serverNode) {
            node.chainItemId = serverNode.data.chainItemId!;
          } else {
            node.chainItemId = serverNode.chainItemId;
          }
        }
      }
    }
  }

  export function getNodeCollapsedState(
    node: RcaNode | undefined,
    parent: RcaNode | undefined,
    anscestors: Array<RcaNode>,
    hoverVisibilityNodeId: string | undefined,
    draggingDescendantIds?: Array<string>
  ) {
    if (node == null) {
      return NodeCollapseState.default;
    }

    const descendants = draggingDescendantIds ?? [];
    if (descendants.includes(node.id)) {
      return NodeCollapseState.hidden;
    }
    if (parent != null && parent.id === hoverVisibilityNodeId) {
      return NodeCollapseState.ghost;
    }

    const collapseChain = node.data.collapse;
    if (collapseChain) {
      return NodeCollapseState.collapsed;
    }

    if (anscestors.some((x) => x.data.collapse)) {
      return NodeCollapseState.hidden;
    }

    return NodeCollapseState.default;
  }
}
