import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { Popover, styled } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';

interface Props {
  companyUserId: number;
  target: HTMLDivElement;
  onClose: () => void;
}

const Container = styled('div')({
  padding: 12,
  maxHeight: 150,
  overflowY: 'auto',
  minWidth: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

export default function UserGroupsPopup({
  companyUserId,
  target,
  onClose,
}: Props) {
  const { data, isFetching } = useGetCompanyGroupOptionsQuery({
    companyUserId,
  });

  return (
    <Popover
      open
      anchorEl={target}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Container>
        <LoadingIndicator show={isFetching} />
        {data?.slice(1).map(({ id, label }) => (
          <div key={id}>{label}</div>
        ))}
      </Container>
    </Popover>
  );
}
