interface Props {
  isRead: boolean;
}

export default function NotificationIndicator({ isRead }: Props) {
  return (
    <div
      style={{
        flex: '0 0 auto',
      }}
      className={
        isRead ? 'notification-indicator read' : 'notification-indicator'
      }
    />
  );
}
