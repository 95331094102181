import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

export const BackToHelpCentre = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ display: 'flex', cursor: 'pointer' }}
      onClick={() => navigate('/help-centre')}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      <Typography sx={{ ml: 2 }}>Back to help centre</Typography>
    </Box>
  );
};
