import { api } from '@api/api';
import { CompanyInviteDetailsResponse } from '@api/types/company-user/company-invite-details.response';
import { CompanyInviteDetailsRequest } from '@api/types/company-user/company-invite-details.request';
import { AcceptCompanyInviteRequest } from '@api/types/company-user/accept-company-invite.request';
import { CompanyUserResource } from '@api/types/company-user/company-user.resource';
import { Option } from '@api/types/option';
import { TypeAheadFilters } from '@api/types/type-ahead-filters';
import { refreshUserState } from '@store/user/user-slice';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const companyUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompaniesForCurrentUser: build.query<CompanyUserResource[], void>({
      query: () => ({
        url: 'companyuser',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ companyUserId }) => ({
                type: 'CompanyUser' as const,
                companyUserId,
              })),
              'CompanyUser',
            ]
          : [],
    }),
    getCompanyUsersOptions: build.query<Option[], TypeAheadFilters>({
      query: (params) => ({
        url: 'companyuser/options',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.map((opt) => ({
                id: opt.id,
                type: 'CompanyUser' as const,
              })),
              'CompanyUser',
            ]
          : [],
    }),
    getCompanyInviteDetails: build.query<
      CompanyInviteDetailsResponse,
      CompanyInviteDetailsRequest
    >({
      query: (params) => ({
        url: 'companyuser/invite/details',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (_, error, arg) =>
        !error ? [{ id: arg.companyId, type: 'CompanyUser' as const }] : [],
    }),
    acceptCompanyInvite: build.query<void, AcceptCompanyInviteRequest>({
      query: (body) => ({
        url: 'companyuser/invite/accept',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
    }),
  }),
});

export const {
  useGetCompanyInviteDetailsQuery,
  useGetCompanyUsersOptionsQuery,
  useGetCompaniesForCurrentUserQuery,
  useAcceptCompanyInviteQuery,
  useLazyGetCompanyUsersOptionsQuery,
} = companyUserApi;
