import { useLazyGetSubscriptionPaymentDetailsUrlQuery } from '@api/endpoints/company/company-subscription.api';
import { useMemo, useState } from 'react';
import { SubscriptionStatus } from '@api/types/subscription/subscription-status';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { useGetSubscriptionDetailsQuery } from '@api/endpoints/subscription.api';
import { PackageName } from '@api/types/subscription/package-name';
import { BillingPortalUrlType } from '@api/types/company/subscription/billing-portal-url-type';
import { getDate, getNowUtc } from '@util/date-util';

export default function useBilling() {
  const { showErrorMessage } = usePageAlertVariants();
  const [loadingLinkType, setLoadingLinkType] =
    useState<BillingPortalUrlType>();

  const { data: details, isLoading } = useGetSubscriptionDetailsQuery();
  const isEnterprise = !isLoading
    ? details!.packageName === PackageName.enterprise
    : null;

  const [getPortalUrl, { isLoading: loadingPortalUrl }] =
    useLazyGetSubscriptionPaymentDetailsUrlQuery();

  const canCancel = useMemo(() => {
    if (details == null) {
      return false;
    }

    return [
      SubscriptionStatus.trialing,
      SubscriptionStatus.active,
      SubscriptionStatus.paused,
    ].includes(details!.status);
  }, [details]);

  const canViewHistory = useMemo(() => {
    if (details == null) {
      return false;
    }

    return [SubscriptionStatus.active].includes(details!.status);
  }, [details]);

  const canReactivate = useMemo(() => {
    if (details == null || isEnterprise) {
      return false;
    }

    return (
      (details.status === SubscriptionStatus.canceled &&
        details.endDate != null &&
        getDate(details.endDate)?.isAfter(getNowUtc())) ??
      false
    );
  }, [details, isEnterprise]);

  const canAddPaymentMethod = useMemo(() => {
    if (details == null || isEnterprise) {
      return false;
    }

    return !details.hasPaymentMethod || details.hasPaymentIssue;
  }, [details, isEnterprise]);

  const canChangePaymentMethod = useMemo(() => {
    if (details == null || isEnterprise) {
      return false;
    }

    return details.hasPaymentMethod || details.hasPaymentIssue;
  }, [details, isEnterprise]);

  const gotoPortal = async (linkType: BillingPortalUrlType) => {
    setLoadingLinkType(linkType);
    const url = await getPortalUrl(linkType)
      .unwrap()
      .then((data) => data.url)
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      })
      .finally(() => setLoadingLinkType(undefined));

    if (url && typeof url === 'string') {
      window.open(url, '_newtab' + Math.floor(Math.random() * 999999));
      return true;
    }

    return false;
  };

  return {
    details,
    isLoading,
    loadingPortalUrl,
    gotoPortal,
    canViewHistory,
    canCancel,
    canAddPaymentMethod,
    canChangePaymentMethod,
    isEnterprise,
    loadingLinkType,
    canReactivate,
  };
}
