import { Pagination } from '@components/table/table-pagination';
import { useMemo, useState } from 'react';

export type PaginationState = Omit<Pagination, 'count'>;

export default function usePagination(
  initialRowsPerPage: number = 50
): PaginationState {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  return useMemo(
    () => ({
      page,
      onPageChange: setPage,
      rowsPerPage,
      onRowsPerPageChange: setRowsPerPage,
      skip: page * rowsPerPage,
      take: rowsPerPage,
    }),
    [page, rowsPerPage]
  );
}
