import { ListItemRow } from '@components/containers/list-container';
import { Box, Typography } from '@mui/material';
import { ElipsisButton } from '@components/buttons';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { truncateString } from '@util/string-util';
import EvidenceStrengthBadge from '@components/outcome-report/components/evidence-strength-badge';
import OutcomeSeverityBadge from '@components/outcome-report/components/outcome-severity-badge';
import FileIndicator from '@components/outcome-report/components/file-indicator';

interface Props {
  evidence: CaseEvidenceResource;
  onView: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}
export default function EvidenceRow({
  evidence,
  onView,
  onEdit,
  onDelete,
}: Props) {
  const { description, name, sensitivity, caseFiles, chainItems, quality } =
    evidence;

  const actions = [
    {
      label: 'View',
      onClick: () => onView(),
    },
  ];

  if (onEdit) {
    actions.push({
      label: 'Edit',
      onClick: () => onEdit(),
    });
  }

  if (onDelete) {
    actions.push({
      label: 'Delete',
      onClick: () => onDelete(),
    });
  }

  return (
    <ListItemRow onClick={onView}>
      <div>
        <Typography variant="body1">{truncateString(name, 40)}</Typography>
      </div>
      <div>
        <Typography variant="body2">
          {truncateString(description, 75)}
        </Typography>
      </div>
      <div>
        <FileIndicator count={caseFiles?.length} />
      </div>
      <div>
        {!!chainItems?.length ? (
          <Box display="flex" justifyContent="flex-start">
            <AssociatedCauseBoxBadge count={chainItems.length} />
          </Box>
        ) : null}
      </div>
      <div>
        <OutcomeSeverityBadge severity={sensitivity} />
      </div>
      <div>
        <EvidenceStrengthBadge strength={quality.toString()} />
      </div>
      <Box display="flex" justifyContent="flex-end">
        <ElipsisButton actions={actions} />
      </Box>
    </ListItemRow>
  );
}
