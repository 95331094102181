/* eslint-disable @typescript-eslint/naming-convention */
import { CSSProperties } from 'react';

import { PaletteColorOptions, PaletteOptions } from '@mui/material';

export type GradientVariants =
  | 'bannerGradientPrimary'
  | 'bannerGradientSecondary'
  | 'buttonGradient'
  | 'iconGradient';
declare module '@mui/material/styles' {
  interface IBadgeOptions {
    green: string;
    healthy: {
      background: string;
      contrast: string;
    };
    average: {
      background: string;
      contrast: string;
    };
    unhealthy: {
      background: string;
      contrast: string;
    };
  }

  interface IButtonOptions {
    circle: {
      color: string;
    };
  }
  interface PaletteOptions {
    white?: PaletteColorOptions;
    badgeGradient?: PaletteColorOptions;
    icon: Record<string, string>;
    badge: IBadgeOptions;
    button: IButtonOptions;
    toolbar: Record<string, string>;
    tabs: Record<string, string>;
    blue: Record<string, string>;
  }
  interface Palette {
    white?: PaletteColorOptions;
    badgeGradient?: PaletteColorOptions;
    icon: Record<string, string>;
    button: IButtonOptions;
    badge: IBadgeOptions;
    toolbar: Record<string, string>;
    tabs: Record<string, string>;
    blue: Record<string, string>;
  }

  interface CommonColors {
    grey90?: CSSProperties['color'];
    grey70?: CSSProperties['color'];
    grey60?: CSSProperties['color'];
    grey50?: CSSProperties['color'];
    grey45?: CSSProperties['color'];
    grey40?: CSSProperties['color'];
    grey35?: CSSProperties['color'];
    grey30?: CSSProperties['color'];
    grey20?: CSSProperties['color'];
    grey17?: CSSProperties['color'];
    grey15?: CSSProperties['color'];
    grey14?: CSSProperties['color'];
    grey10?: CSSProperties['color'];
    grey05?: CSSProperties['color'];
    grey0?: CSSProperties['color'];
    textDark?: CSSProperties['color'];
    textMid?: CSSProperties['color'];
    textLight?: CSSProperties['color'];
    textLink?: CSSProperties['color'];
    cream?: CSSProperties['color'];
  }
}

export const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#FF6C00',
    light: '#FFF1E6',
    dark: '#FF6C00',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#007AFF',
    contrastText: '#ffffff',
  },
  success: {
    main: '#3CA680',
    light: '#3CA680',
  },
  warning: {
    main: '#FF6C00',
    light: '#FFF1E6',
  },
  error: {
    main: '#FF3D3D',
    light: '#FF3D3D',
  },
  info: {
    main: '#0085FF',
    light: '#E6F3FF',
  },
  white: {
    main: 'white',
    contrastText: '#000',
  },
  blue: {
    dark: '#00223A',
    mid: '#24465E',
    text: '#0d5080',
    light: '#CDF1F3',
  },
  tabs: {
    text: '#202020',
    contrastText: '#FFF',
    background: '#D8DBE3',
  },
  common: {
    grey90: '#001927',
    grey70: '#687A85',
    grey60: '#636D73',
    grey50: '#C1CDD3',
    grey45: '#D9D9D9',
    grey40: '#D8D8E3',
    grey35: '#E6E6E6',
    grey30: '#E6EDF0',
    grey20: '#EFEFEF',
    grey17: '#E0E0E0',
    grey15: '#F6F6F6',
    grey14: '#F5F5F5',
    grey10: '#F3F4F7',
    grey05: '#FBFBFB',
    grey0: '#FFFFFF',
    textDark: '#121212',
    textMid: '#767676',
    textLight: '#BABABA',
    textLink: '#007AFF',
    white: '#FFFFFF',
    black: '#000000',
    cream: '#fff9ef',
  },
  icon: {
    main: '#115D67',
    highlight: '#EF8041',
  },
  badge: {
    green: '#3CA680',
    healthy: {
      background: '#98ffd6',
      contrast: '#006c',
    },
    average: {
      background: '#FFE5D1',
      contrast: '#ff974a',
    },
    unhealthy: {
      background: '#ffd1d1',
      contrast: '#e44848',
    },
  },
  button: {
    circle: {
      color: '#001927',
    },
  },
  toolbar: {
    background: '#000',
    contrastText: '#FFF',
  },
};

// I have just copied light for now, this can be updated with the UI pass
export const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#FF6C00',
    light: '#FFF1E6',
    dark: '#FF6C00',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#007AFF',
    contrastText: '#ffffff',
  },
  success: {
    main: '#41AD2F',
    light: '#D9EFD5',
  },
  warning: {
    main: '#FFB800',
    light: '#FFF1CC',
  },
  error: {
    main: '#FC3939',
    light: '#FFE6E6',
  },
  info: {
    main: '#0085FF',
    light: '#E6F3FF',
  },
  white: {
    main: 'white',
    contrastText: '#000',
  },
  blue: {
    dark: '#00223A',
    mid: '#0A3350',
    text: '#0d5080',
    light: '#CDF1F3',
  },
  tabs: {
    text: '#202020',
    contrastText: '#FFF',
    background: '#D8DBE3',
  },
  common: {
    grey90: '#001927',
    grey70: '#687A85',
    grey60: '#636D73',
    grey50: '#C1CDD3',
    grey45: '#D9D9D9',
    grey40: '#E7E7E7',
    grey35: '#E6E6E6',
    grey30: '#E6EDF0',
    grey20: '#EFEFEF',
    grey17: '#E0E0E0',
    grey15: '#F6F6F6',
    grey14: '#F5F5F5',
    grey10: '#F3F4F7',
    grey05: '#FBFBFB',
    grey0: '#FFFFFF',
    textDark: '#121212',
    textMid: '#767676',
    textLight: '#BABABA',
    textLink: '#007AFF',
    white: '#FFFFFF',
    black: '#000000',
  },
  icon: {
    main: '#115D67',
    highlight: '#EF8041',
  },
  badge: {
    green: '#41AD2F',
    healthy: {
      background: '#98ffd6',
      contrast: '#006c3c',
    },
    average: {
      background: '#FFE5D1',
      contrast: '#ff974a',
    },
    unhealthy: {
      background: '#ffd1d1',
      contrast: '#ff504a',
    },
  },
  button: {
    circle: {
      color: '#858585',
    },
  },
  toolbar: {
    background: '#000',
    contrastText: '#FFF',
  },
};
