import { WCTTableBody } from '@components/table';
import { TableRowSkeleton } from '@components/skeletons/index';

interface Props {
  cols?: number;
}

export default function TableBodySkeleton({ cols }: Props) {
  return (
    <WCTTableBody>
      <TableRowSkeleton cols={cols} />
      <TableRowSkeleton cols={cols} />
      <TableRowSkeleton cols={cols} />
      <TableRowSkeleton cols={cols} />
      <TableRowSkeleton cols={cols} />
      <TableRowSkeleton cols={cols} />
    </WCTTableBody>
  );
}
