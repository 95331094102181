import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';

export default function getCompanyUserStatusTr(status: CompanyUserStatus) {
  switch (status) {
    case CompanyUserStatus.unknown:
      return 'Unknown';
    case CompanyUserStatus.invited:
      return 'Invited';
    case CompanyUserStatus.active:
      return 'Active';
    case CompanyUserStatus.inviteExpired:
      return 'Invite Expired';
    case CompanyUserStatus.deleted:
      return 'Deleted';
  }
}
