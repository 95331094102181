import {
  useGetWACompanyDetailQuery,
  useSetWACompanyStatusMutation,
} from '@api/endpoints/wct-admin/wct-admin-company.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { ColumnForm } from '@components/layout-util-components/column';
import { Grid, Typography } from '@mui/material';
import { useGetAllWASubscriptionStatusOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-subscription-status.api';
import useField from '@hooks/use-field-hook';
import { afterDateTime, onlyNumbers, required } from '@util/validators';
import dayjs from 'dayjs';
import { Gap } from '@components/layout-util-components/gap';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import WCTSelectField from '@components/input/select-field';
import { InputSkeleton } from '@components/skeletons';
import WCTDateField from '@components/input/date-field';
import WCTTextField from '@components/input/text-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import OutlinedButton from '@components/buttons/outlined-button';
import { PrimaryButton } from '@components/buttons';
import { numberFromString } from '@util/string-util';
import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import { WASetCompanyStatusRequest } from '@api/types/wct-admin/wct-admin-company-types';
import { invalidation } from '@api/cache-util';

export interface WAManageSubscriptionFormProps extends ModalProps<never> {
  companyId: number;
  companyName: string;
}

function WAManageSubscriptionForm({
  companyId,
  companyName,
  onClose,
}: WAManageSubscriptionFormProps) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [setStatus] = useSetWACompanyStatusMutation();
  const { data: subscriptionOptions, isLoading: loadingSubscriptionOptions } =
    useGetAllWASubscriptionStatusOptionsQuery();
  const { data, isLoading: loadingCompany } =
    useGetWACompanyDetailQuery(companyId);

  const labelAsEnum = (label?: string) => {
    if (label == null) {
      return;
    }

    let newLabel = label.trim();
    // Inconsistency on backend
    if (newLabel === 'Past Due') {
      newLabel = 'PastDue';
    } else if (newLabel === 'Incomplete Expired') {
      newLabel = 'Incomplete_Expired';
    }

    return newLabel;
  };

  const subscriptionStatus = useField<string>(
    [required()],
    labelAsEnum(data?.subscription?.status)
  );
  const expiryDate = useField<string>(
    [
      afterDateTime(
        dayjs().utc().toISOString(),
        undefined,
        'Must be in the future'
      ),
    ],
    data?.subscription?.endDate
  );
  const seatCount = useField<string>(
    [required(), onlyNumbers()],
    data?.subscription?.quantity?.toString()
  );

  const { validateAll, isValid } = useFieldsWatcher([
    subscriptionStatus,
    expiryDate,
    seatCount,
  ]);

  const [onSetStatus, isBusy] = useBusyAction(async () => {
    if (!validateAll()) {
      return false;
    }

    try {
      await setStatus({
        companyId,
        expiryDate: expiryDate.value,
        subscriptionStatus: labelAsEnum(subscriptionStatus.value)!,
        quantity: numberFromString(seatCount.value)!,
        boltOns: [] as any, // TODO(Nick): This should likely come from the backend
      }).unwrap();
      await invalidation('WACompany');

      showSuccessMessage('Subscription status updated');
      return true;
    } catch (error) {
      if (isApiError<WASetCompanyStatusRequest>(error)) {
        const { message, errors } = error;
        showErrorMessage(errors?.companyId ?? message);

        expiryDate.setError(errors?.expiryDate);
        subscriptionStatus.setError(errors?.subscriptionStatus);
        seatCount.setError(errors?.quantity);
      }

      return false;
    }
  });

  const isLoading = loadingCompany || loadingSubscriptionOptions;
  const canSubmit = isValid && !isLoading && !isBusy;

  return (
    <ColumnForm
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        const didSave = await onSetStatus();
        if (didSave) {
          onClose();
        }
      }}
    >
      <Typography variant="h5">{`Manage ${companyName}'s subscription`}</Typography>
      <Gap size={24} />
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="status"
              name="status"
              label="Status"
              options={subscriptionOptions!.map((x) => ({
                id: labelAsEnum(x.label),
                label: x.label,
              }))}
              value={subscriptionStatus.value}
              onChange={subscriptionStatus.set}
              error={subscriptionStatus.error}
              required
            />
          )}
        </Grid>

        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTDateField
              name="expiry"
              label="End Date"
              value={expiryDate.value}
              onChange={expiryDate.set}
              error={expiryDate.error}
              disablePast
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <WCTTextField
            name="seatCount"
            label={'No. of seats'}
            onChange={seatCount.set}
            value={seatCount.value}
            error={seatCount.error}
            maxWidth={200}
            required
          />
        </Grid>
      </Grid>
      <Gap size={24} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Submit
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}

export default withModal(WAManageSubscriptionForm);
