import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useEffect, useState } from 'react';
import { ReferenceDataDisableListOption } from './components/reference-data-disable-list';
import {
  useCompanyDisableCaseAnalysisTypeMutation,
  useCompanyEnableCaseAnalysisTypeMutation,
  useGetCompanyCaseAnalysisTypeQuery,
} from '@api/endpoints/company/company-case-analysis-type.api';

export default function useCompanyCaseAnalysisTypes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { data: caseAnalysisTypes, isLoading: loadingCaseAnalysisTypes } =
    useGetCompanyCaseAnalysisTypeQuery();
  const [options, setOptions] = useState<ReferenceDataDisableListOption[]>([]);
  const [enable] = useCompanyEnableCaseAnalysisTypeMutation();
  const [disable] = useCompanyDisableCaseAnalysisTypeMutation();

  const enableCaseAnalysisType = (caseAnalysisTypeId: number) => {
    enable(caseAnalysisTypeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully enabled Type');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === caseAnalysisTypeId
              ? { ...option, enabled: true, disabledDateTime: undefined }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const disableCaseAnalysisType = (caseAnalysisTypeId: number) => {
    disable(caseAnalysisTypeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully disabled Type');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === caseAnalysisTypeId
              ? { ...option, enabled: false, disabledDateTime: new Date() }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  useEffect(() => {
    setOptions(
      caseAnalysisTypes?.map((o) => {
        return {
          id: o.caseAnalysisTypeId,
          label: o.name,
          enabled: !o.disabled,
          hasWarning: o.hasRca,
          disabledDateTime: o.disabled ? new Date(o.disabled) : undefined,
        };
      }) ?? []
    );
  }, [caseAnalysisTypes]);

  return {
    options,
    loadingCaseAnalysisTypes,
    enableCaseAnalysisType,
    disableCaseAnalysisType,
  };
}
