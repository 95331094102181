import { Address } from '@components/input/address-field';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAP_KEY } from './env';

export async function getGoogleLocationForString(
  address: Address | undefined
): Promise<google.maps.GeocoderResult | null> {
  const loader = new Loader({
    apiKey: GOOGLE_MAP_KEY,
    version: 'weekly',
  });

  try {
    await loader.importLibrary('maps');
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: address?.postcode }, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results[0]);
        } else {
          resolve(null);
        }
      });
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reverseGeocode(
  lat: number,
  lng: number
): Promise<Address> {
  const loader = new Loader({
    apiKey: GOOGLE_MAP_KEY,
    version: 'weekly',
  });

  await loader.importLibrary('maps');

  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const components = results[0].address_components;

        const streetNumber = getAddressComponent(components, 'street_number');
        const route = getAddressComponent(components, 'route');
        const locality = getAddressComponent(components, 'locality');
        const postalTown = getAddressComponent(components, 'postal_town');
        const country = getAddressComponent(components, 'country');
        const postcode = getAddressComponent(components, 'postal_code');

        let addressLineOne = '';
        let town = '';

        if (postcode) {
          addressLineOne = streetNumber ? `${streetNumber} ${route}` : locality;
          town = postalTown;
        } else {
          addressLineOne = postalTown ? `${postalTown} ${route}` : locality;
          town = country;
        }

        resolve({
          addressLineOne: addressLineOne,
          town: town,
          postcode: postcode,
          county: undefined,
          latitude: lat,
          longitude: lng,
        });
      } else {
        reject(status);
      }
    });
  });
}

function getAddressComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string
) {
  const component = components.find((c) => c.types.includes(type));
  return component ? component.long_name : '';
}
