import { api } from '@api/api';
import { Option } from '@api/types/option';

const kudosPointApi = api.injectEndpoints({
  endpoints: (build) => ({
    getKudosPointOptions: build.query<Option[], void>({
      query: () => ({
        url: 'kudosPoint/options',
      }),
    }),
  }),
});

export const { useGetKudosPointOptionsQuery } = kudosPointApi;
