import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorAlert } from '@components/alerts';
import { LinkButton } from '@components/buttons';
import { Box, Grid, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

type LoginErrorNavigationState = {
  title: string;
  message: string;
};

export default function LoginErrorPage() {
  const { state } = useLocation();
  const { title, message } = (state || {}) as LoginErrorNavigationState;

  const navigate = useNavigate();

  return (
    <Grid direction="column">
      <Typography variant="h4">What Caused This</Typography>
      <Gap size={24} />
      <ErrorAlert title={title} message={message} />
      <Gap size={15} />
      <Box display="flex" justifyContent="center">
        <LinkButton onClick={() => navigate('/auth/login', { replace: true })}>
          Back to Login
        </LinkButton>
      </Box>
    </Grid>
  );
}
