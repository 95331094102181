import { Box, Link, styled, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppLogo from '@assets/svgs/app-logo.svg';
import desktopBG from '@assets/svgs/login_desktop.svg';
import desktopWideBG from '@assets/svgs/login_desktop_wide.svg';
import mobLeftBG from '@assets/svgs/login_mobile_left.svg';
import mobRightBG from '@assets/svgs/login-mobile-right.svg';

import { useTranslation } from 'react-i18next';

const StyledFooterLinks = styled('div')({
  display: 'flex',
  marginTop: 'auto',
  justifyContent: 'center',
  paddingTop: '20px',
  gap: '20px',
  a: {
    color: 'black',
    textDecorationColor: 'black',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '166%' /* 19.92px */,
    textDecorationLine: 'underline',
  },
});

const StyledPageContainer = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  })
);

const StyledLeftSection = styled('div')({
  display: 'block',
});

const StyledLeftContent = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    paddingLeft: '25px',
    paddingRight: '25px',
    minHeight: 'calc(100vh - 139px)',
    [breakpoints.up('xs')]: {
      width: '100%',
      paddingLeft: '32px',
      paddingRight: '32px',
      minHeight: 'calc(100vh - 164px)',
    },
    [breakpoints.up('sm')]: {
      width: '100%',
      paddingLeft: '72px',
      paddingRight: '72px',
    },
    [breakpoints.up('md')]: {
      width: '500px',
      paddingLeft: '60px',
      paddingRight: '60px',
      minHeight: 'calc(100vh - 144px)',
    },
    [breakpoints.up('lg')]: {
      width: '610px',
      paddingLeft: '80px',
      paddingRight: '80px',
    },
    [breakpoints.up('xl')]: {
      width: '658px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  })
);

const StyledContent = styled('div')({
  width: '350px',
  position: 'relative',
  left: '10%',
});

const StyledLogoContainer = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    background: '#09233B',
    [breakpoints.up('xs')]: {
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    [breakpoints.up('sm')]: {
      paddingLeft: '72px',
      paddingRight: '72px',
    },
    [breakpoints.up('md')]: {
      width: '500px',
      paddingLeft: '60px',
      paddingRight: '60px',
      justifyContent: 'flex-start',
      background: 'transparent',
      paddingTop: spacing(8),
      paddingBottom: 0,
    },
    [breakpoints.up('lg')]: {
      width: '610px',
      paddingLeft: '80px',
      paddingRight: '80px',
      justifyContent: 'flex-start',
      background: 'transparent',
      paddingTop: spacing(8),
      paddingBottom: 0,
    },
    [breakpoints.up('xl')]: {
      width: '658px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
  })
);

const StyledLogo = styled('img')(({ theme: { spacing, breakpoints } }) => ({
  position: 'relative',
  top: 0,
  zIndex: 10,
  width: '75px',
  height: '75px',
  [breakpoints.up('xs')]: {
    width: '100px',
    height: '100px',
  },
  [breakpoints.up('md')]: {
    width: '80px',
    height: '80px',
  },
}));

const StyledLeftBG = styled('div')(({ theme: { spacing, breakpoints } }) => ({
  position: 'absolute',
  height: '100%',
  width: '35%',
  top: 0,
  left: 0,
  background: `url(${mobLeftBG}) top right no-repeat #09233B`,
  backgroundSize: 'cover',
  [breakpoints.up('md')]: {
    display: 'none',
  },
}));

const StyledRightBG = styled('div')(({ theme: { spacing, breakpoints } }) => ({
  position: 'absolute',
  height: '100%',
  width: '35%',
  top: 0,
  right: '0%',
  background: `url(${mobRightBG}) 0 50% no-repeat #09233B`,
  backgroundSize: 'cover',
  [breakpoints.up('md')]: {
    display: 'none',
  },
}));

const StyledRightSection = styled(Box)(
  ({ theme: { palette, breakpoints } }) => ({
    display: 'none',
    [breakpoints.up('md')]: {
      width: 'calc(100% - 500px)',
      background: `url(${desktopBG}) 0 0 no-repeat #09233B`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& h2': {
        fontSize: '55px',
        fontWeight: '400',
        color: '#fff',
        lineHeight: '1.1em',
        marginTop: '20px',
      },
      '& p': {
        color: '#FEF9F0',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '32px',
      },
    },
    [breakpoints.up('lg')]: {
      width: 'calc(100% - 610px)',
      background: `url(${desktopWideBG}) 0 0 no-repeat #09233B`,
      backgroundSize: 'cover',
    },
    [breakpoints.up('xl')]: {
      width: 'calc(100% - 658px)',
      background: `url(${desktopWideBG}) 0 0 no-repeat #09233B`,
      backgroundSize: 'cover',
    },
  })
);

export default function AuthLayout() {
  const { t } = useTranslation('auth', { keyPrefix: 'common' });

  return (
    <StyledPageContainer>
      <StyledLeftSection>
        <StyledLogoContainer>
          <StyledLogo src={AppLogo} alt="logo" />
          <StyledLeftBG />
          <StyledRightBG />
        </StyledLogoContainer>
        <StyledLeftContent>
          <Outlet />
          <StyledFooterLinks>
            <Link
              variant="caption"
              href="https://wct.withcandour.dev/terms-of-use"
              target="_blank"
            >
              {t('footerLinks.terms')}
            </Link>
            <Link
              variant="caption"
              href="https://wct.withcandour.dev/privacy-notice"
              target="_blank"
            >
              {t('footerLinks.privacy')}
            </Link>
            <Link
              variant="caption"
              href="https://wct.withcandour.dev/data-protection"
              target="_blank"
            >
              {t('footerLinks.data')}
            </Link>
          </StyledFooterLinks>
        </StyledLeftContent>
      </StyledLeftSection>
      <StyledRightSection>
        <StyledContent>
          <Typography>
            The Root Cause Analysis platform powering innovative and effective
            problem solving
          </Typography>
          <Typography variant="h2">Understand more</Typography>
        </StyledContent>
      </StyledRightSection>
    </StyledPageContainer>
  );
}
