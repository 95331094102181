import { styled } from '@mui/material';
import { ColorId, getColorForId } from '@util/colour-identifiers';

interface Props {
  id: ColorId;
  colour?: string;
}

const Container = styled('div')<{ color: string }>(({ color }) => ({
  display: 'block',
  flex: '0 0 auto',
  width: 12,
  height: 12,
  borderRadius: 6,
  backgroundColor: color,
}));

export default function IdColorIndicator({ id, colour }: Props) {
  return <Container color={colour ?? getColorForId(id)} />;
}
