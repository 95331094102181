import { styled } from '@mui/material';
import { ReactComponent as TaskCompleteIcon } from '@assets/svgs/task-complete.svg';
import { ReactComponent as TaskIncompleteIcon } from '@assets/svgs/task-incomplete.svg';

interface Props {
  isComplete: boolean;
  onComplete?: () => void;
  onOpen?: () => void;
  isTogglingState: boolean;
  readOnly?: boolean;
}

const StyledIndicatorContainer = styled('div')<{ isPointer: boolean }>(
  ({ isPointer }) => ({
    width: 32,
    height: 32,
    flexShrink: 0,
    cursor: isPointer ? 'pointer' : 'default',
    '& > *': {
      width: '100%',
      height: '100%',
    },
  })
);

export default function TaskCompleteIndicator({
  isComplete,
  onComplete,
  onOpen,
  isTogglingState,
  readOnly,
}: Props) {
  const showTick = isTogglingState ? !isComplete : isComplete;

  return (
    <StyledIndicatorContainer
      onClick={(e) => {
        if (isTogglingState || !onOpen || !onComplete || readOnly) {
          return;
        }
        e.stopPropagation();
        isComplete ? onOpen() : onComplete();
      }}
      isPointer={!isTogglingState && !!onOpen && !!onComplete && !readOnly}
    >
      {showTick ? <TaskCompleteIcon /> : <TaskIncompleteIcon />}
    </StyledIndicatorContainer>
  );
}
