import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { getColorForId } from '@util/colour-identifiers';
import articleDefault from '@assets/svgs/article-default.svg';

const StyledArticleHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '30px 20px 25px',
  minHeight: '105px',
});

interface Props {
  sx?: SxProps<Theme>;
  articles: IHelpCentreContent[];
  label?: string;
  showAll?: boolean;
  direction?: 'column' | 'row';
}

export const Articles = ({
  sx,
  articles,
  label = 'Articles',
  direction = 'column',
  showAll = true,
}: Props) => {
  const navigate = useNavigate();

  if (!articles.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <StyledArticleHeaderWrapper sx={{ marginBottom: '15px' }}>
        <Typography variant="h2">{label}</Typography>
        {showAll && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography fontWeight={500}>View All</Typography>
            <IconButton
              onClick={() => navigate('article')}
              aria-label="View all"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        )}
      </StyledArticleHeaderWrapper>
      <Grid mb={6} container spacing={2}>
        {articles.map((article) => (
          <Grid
            item
            xs={direction === 'column' ? 4 : 12}
            key={`article${article.helpCentreContentId}`}
          >
            <Card
              sx={{
                p: 0,
                display: 'flex',
                boxShadow: 'none',
                borderRadius: '5px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: direction,
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <CardMedia
                  sx={{
                    width: '100%',
                    img: {
                      width: '100%',
                    },
                  }}
                  image={article.mediaUrl}
                  title={article.title}
                  onClick={() =>
                    navigate(
                      `/help-centre/article/${article.helpCentreContentId}`
                    )
                  }
                >
                  <img src={articleDefault} alt="logo" />
                </CardMedia>
                <Box>
                  <StyledCardContent>
                    <Typography gutterBottom variant="h4" component="div">
                      {article.title}
                    </Typography>
                  </StyledCardContent>
                  <CardActions
                    sx={{
                      padding: '0 20px 30px',
                    }}
                  >
                    {article.categories.map((category) => (
                      <Chip
                        size="small"
                        key={`article-category-${category.id}`}
                        label={category.label}
                        variant="outlined"
                        sx={{
                          color: getColorForId(category.id),
                          borderColor: getColorForId(category.id),
                        }}
                      />
                    ))}
                  </CardActions>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
