import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { EventItemResult } from '@api/types/events/event-item.result';
import {
  CaseEventRequest,
  ChainEventRequest,
  ChainItemEventRequest,
} from '@api/types/events/event-filter.request';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventsForCase: build.query<
      PaginatedResult<EventItemResult>,
      CaseEventRequest
    >({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/event`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: ['Case'],
    }),
    getEventsForChain: build.query<
      PaginatedResult<EventItemResult>,
      ChainEventRequest
    >({
      query: ({ chainId, ...params }) => ({
        url: `chain/${chainId}/event`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: ['ChainItem'],
    }),
    getEventsForChainItem: build.query<
      PaginatedResult<EventItemResult>,
      ChainItemEventRequest
    >({
      query: ({ chainId, chainItemId, ...params }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/event`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: ['ChainItem'],
    }),
  }),
});

export const {
  useGetEventsForCaseQuery,
  useGetEventsForChainQuery,
  useGetEventsForChainItemQuery,
} = eventsApi;

export default eventsApi;
