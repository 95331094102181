import {
  useGetChainItemEvidenceQuery,
  useUnlinkEvidenceMutation,
} from '@api/endpoints/chain/chain-item-evidence.api';
import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import { useCallback, useEffect, useState } from 'react';
import { useFilter, useSortableHeader } from '@components/table';
import useLoadMore from '@hooks/use-load-more-hook';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useGetChainItemQuery } from '@api/endpoints/chain/chain-item.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { isApiError } from '@api/types/api-error';
import { LinkEvidenceRequest } from '@api/types/chain/chain-item-evidence/link-evidence.request';
import { usePageAlertVariants } from '@components/alerts';

export enum EvidencePanelViewState {
  empty,
  view,
  createOptions,
  create,
  edit,
}

export default function useEvidencePanel() {
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { canContribute } = useCasePermission();
  const [viewState, setViewState] = useState<EvidencePanelViewState>(
    defaultToCreate
      ? EvidencePanelViewState.createOptions
      : EvidencePanelViewState.empty
  );
  const [unlinkEvidence] = useUnlinkEvidenceMutation();
  const [edittingEvidenceDetail, setEdittingEvidenceDetail] =
    useState<CaseEvidenceResource>();
  const chainId = useAppSelector(selectChainId)!;
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId, label } = focusedNode.data;

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name', direction: SortDirection.asc },
  ];

  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const sort = useSortableHeader({ initialProperty: 'name' });

  const { data: chainItemDetail, isLoading: loadingChainItemDetail } =
    useGetChainItemQuery(
      { chainId, chainItemId: chainItemId ?? -1 },
      { skip: chainItemId == null }
    );

  const {
    data: evidence,
    isLoading: loadingEvidence,
    isSuccess,
    isFetching,
  } = useGetChainItemEvidenceQuery(
    {
      chainItemId: chainItemId ?? -1,
      chainId,
      searchText: search.value,
      sortBy: sort.property,
      sortDirection: sort.direction,
      skip,
      take,
    },
    { skip: !chainItemId }
  );

  const [onUnlinkEvidence, isUnlinking] = useBusyAction(
    async (evidence: CaseEvidenceResource) => {
      try {
        await unlinkEvidence({
          evidenceId: evidence.evidenceId,
          chainId: chainId!,
          chainItemId: chainItemId!,
        }).unwrap();

        await invalidation('Evidence', evidence.evidenceId);
        showSuccessMessage(
          `You have successfully unlinked evidence from Cause Box “${label}”`
        );
        return true;
      } catch (e) {
        if (isApiError<LinkEvidenceRequest>(e)) {
          const { message, errors } = e;
          showErrorMessage(
            errors?.chainId ??
              errors?.chainItemId ??
              errors?.evidenceId ??
              message
          );
        }

        return false;
      }
    }
  );

  const isWorking = isUnlinking;
  const isLoading =
    chainItemDetail == null ||
    loadingEvidence ||
    loadingChainItemDetail ||
    isWorking;

  const startCreateFlow = useCallback(() => {
    if (isWorking) return;
    setViewState(EvidencePanelViewState.createOptions);
  }, []);

  const createNew = useCallback(() => {
    setEdittingEvidenceDetail(undefined);
    setViewState(EvidencePanelViewState.create);
  }, []);

  const editEvidence = useCallback((evidence: CaseEvidenceResource) => {
    setEdittingEvidenceDetail(evidence);
    setViewState(EvidencePanelViewState.edit);
  }, []);

  const cancelCreate = useCallback(
    (didAdd: boolean = false) => {
      if (evidence!.totalCount === 0 && !didAdd) {
        setViewState(EvidencePanelViewState.empty);
      } else {
        setViewState(EvidencePanelViewState.view);
      }
    },
    [evidence]
  );

  const gotoViewList = useCallback(() => {
    setViewState(EvidencePanelViewState.view);
  }, []);

  const totalCount = evidence?.totalCount ?? 0;
  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    setViewState(
      totalCount > 0
        ? EvidencePanelViewState.view
        : EvidencePanelViewState.empty
    );
  }, [isSuccess, totalCount]);

  return {
    search,
    sort,
    sortByOptions,
    evidence,
    isLoading,
    viewState,
    startCreateFlow,
    createNew,
    cancelCreate,
    gotoViewList,
    loadMore,
    canLoadMore,
    isFetching,
    chainId,
    chainItemId,
    editEvidence,
    edittingEvidenceDetail,
    setViewState,
    canContribute,
    evidenceNa: chainItemDetail?.evidenceNa,
    onUnlinkEvidence,
    isUnlinking,
    isWorking,
  };
}

export type EvidencePanelState = ReturnType<typeof useEvidencePanel>;
