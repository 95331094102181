import { Box, Button, Grid, Popover, Typography, styled } from '@mui/material';
import { PropsWithChildren, ReactNode, useMemo, useRef, useState } from 'react';
import { Option } from '@api/types/option';
import WCTCheckboxField from '@components/input/checkbox-field';
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from '@mui/styled-engine';

interface Props {
  value?: any;
  onChange: (value: any) => void;
  multiple?: boolean;
  options: Array<Option<any>>;
  styledOptions?: CSSProperties;
}

const StyledButton = styled(Button)<{
  styledOptions?: CSSProperties;
  filled: boolean;
}>(({ theme, styledOptions, filled }) => ({
  color: filled ? '#fff' : theme.palette.common.textDark,
  textTransform: 'none',
  height: '36px',
  background: filled ? '#00223A' : '#D8DBE3',
  boxShadow: 'none',
  border: filled ? '1px solid #00223A' : '1px solid #D8DBE3',
  fontWeight: filled ? '400' : '400',
  padding: filled ? '5px 15px' : '400',
  fontSize: 13,
  ...styledOptions,
  '&:hover': {
    border: filled ? '1px solid #00223A' : '1px solid #00223A',
    background: filled ? '#00223A' : '#D8DBE3',
  },
}));

const StyledSelectButton = styled('span')<{ disabled?: boolean }>(
  ({ disabled, theme }) => ({
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    userSelect: 'none',
    textDecoration: 'underline',
    ...(disabled && {
      cursor: 'unset',
      color: theme.palette.grey[500],
    }),
  })
);

const PopoverContent = styled(Grid)({
  minWidth: 192,
  padding: 17,
});

export default function DropDownFilter({
  children,
  value,
  onChange,
  multiple,
  options,
  styledOptions,
}: PropsWithChildren<Props>) {
  const buttonRef = useRef<HTMLButtonElement | null>();
  const [isOpen, setIsOpen] = useState(false);

  const filled = useMemo(() => {
    if (value == null) {
      return false;
    }

    if (multiple) {
      if (!Array.isArray(value)) {
        return false;
      }

      return value.length > 0;
    }

    return true;
  }, [multiple, value]);

  const valueLength = value != null && Array.isArray(value) ? value.length : 0;
  const optionsLength = options.length;

  const isSelected = (checkValue: any) => {
    if (value == null) {
      return false;
    }

    if (multiple) {
      if (Array.isArray(value)) {
        return !!value.find((x) => x === checkValue);
      }
      return false;
    }

    return value === checkValue;
  };

  const select = (checkValue: any) => {
    const isAlreadySelected = isSelected(checkValue);

    if (multiple) {
      if (Array.isArray(value)) {
        if (isAlreadySelected) {
          onChange(value.filter((x) => x !== checkValue));
        } else {
          onChange([...value, checkValue]);
        }
        return;
      }
      onChange([checkValue]);
      return;
    }

    if (isAlreadySelected) {
      onChange(undefined);
    } else {
      onChange(checkValue);
    }
  };

  const selectAll = () => {
    onChange(options.map(({ id }) => id));
  };

  const unselectAll = () => {
    onChange([]);
  };

  //This works if needed will produce "Craig John (+2)"
  // const selectedOptionText = (): ReactNode => {
  //   var label = '';
  //   var count = 0;
  //   if (value) {
  //     if (Array.isArray(value)) {
  //       var selectedOptions = options.filter((x) =>
  //         value.some((z) => z === x.id)
  //       );

  //       if (selectedOptions.length > 0) {
  //         label = selectedOptions[0].label;
  //         count = selectedOptions.length - 1;
  //       }
  //     }
  //   } else {

  //   }

  //   return label ? (
  //     <Typography>
  //       {label} {count > 0 ? `(+${count})` : ''}
  //     </Typography>
  //   ) : (
  //     children
  //   );
  // };

  return (
    <>
      <StyledButton
        filled={filled}
        styledOptions={styledOptions}
        ref={(b) => (buttonRef.current = b)}
        variant={filled ? 'contained' : 'outlined'}
        onClick={() => setIsOpen((v) => !v)}
      >
        {children}
        <Box marginLeft={children ? 1 : 0} alignSelf="center">
          <FontAwesomeIcon icon={faBarsFilter} />
        </Box>
      </StyledButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsOpen(false)}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContent
          container
          direction="column"
          alignItems="stretch"
          justifyContent="start"
        >
          {multiple ? (
            <Grid item container>
              <StyledSelectButton
                disabled={valueLength === optionsLength}
                onClick={selectAll}
              >
                Select all
              </StyledSelectButton>
              <Box flexGrow={1} minWidth={18} />
              <StyledSelectButton
                disabled={valueLength === 0}
                onClick={unselectAll}
              >
                Clear all
              </StyledSelectButton>
            </Grid>
          ) : null}

          {options.map(({ id, label }) => (
            <WCTCheckboxField
              key={id}
              label={label}
              value={isSelected(id)}
              onChange={(_) => select(id)}
            />
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
}
