import { api } from '@api/api';
import { Option } from '@api/types/option';

const caseTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseTypeOptions: build.query<Option[], void>({
      query: () => ({
        url: 'caseType/options',
      }),
    }),
  }),
});

export const { useGetCaseTypeOptionsQuery } = caseTypeApi;
