import { styled } from '@mui/material';

export const OverviewContainer = styled('div')<{ dark?: boolean }>(
  ({ dark, theme: { palette } }) => ({
    flex: '1 1 auto',
    padding: '20px 22px 15px 22px',
    borderRadius: 8,
    backgroundColor: dark ? palette.blue.dark : '#FFFFFF',
    minHeight: 100,
  })
);
