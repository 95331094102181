import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Box, IconButton, Skeleton, styled, Typography } from '@mui/material';
import useSkillPage from './skills-page-hook';
import { SkillsTypeaheadField } from './components/skills-typeahead-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { InputSkeleton } from '@components/skeletons';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import Column from '@components/layout-util-components/column';
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons';

const StyledSkillContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 25,
});

const StyledSkillBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  gap: '12px',
  padding: '20px 12px',
  paddingLeft: '50px',
  borderRadius: 8,
  background: '#fff',
  border: '1px solid rgba(0,0,0,0.25)',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.16px',
  '&:hover': {
    border: '1px solid rgba(0,0,0,0.4)',
  },
  '&:hover button': {
    opacity: 1,
  },
}));

const StyledIconBox = styled(Box)({
  display: 'flex',
  width: '26px',
  height: '26px',
  flexDirection: 'column',
  justifyContent: 'center',
  '& button': {
    color: '#001825',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    borderRadius: '13px',
    background: '#F9F4F1',
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    /** Only display close icon on hover of parent skill box */
    opacity: 0,
    transition: 'opacity',
    transitionTimingFunction: 'ease-out',
    transitionDuration: '100ms',
    '&:hover': {
      background: '#F1E3DB',
    },
  },
});

export const SkillPage = () => {
  const {
    skills,
    isLoading,
    skillsOptions,
    selectSkill,
    removeSkill,
    isSubmitting,
  } = useSkillPage();

  return (
    <Column>
      <Typography variant="h2">Skills</Typography>
      <Gap size={8} />
      {isLoading ? (
        <Box maxWidth={250}>
          <InputSkeleton />
        </Box>
      ) : (
        <Row>
          <Box
            minWidth={300}
            sx={{
              '.MuiAutocomplete-root .MuiFilledInput-root .MuiAutocomplete-endAdornment':
                {
                  display: 'none',
                },
            }}
          >
            <SkillsTypeaheadField
              id="skills"
              name="skills"
              placeholder="Search skills"
              value={skills}
              onChange={selectSkill}
              options={skillsOptions!}
              required
            />
          </Box>
          <Gap size={8} />
          <FetchingIndicator show={isSubmitting} />
        </Row>
      )}
      <Gap size={32} />
      {isLoading || skills.length > 0 ? (
        <StyledSkillContainerBox>
          {isLoading
            ? [...Array(4)].map((_) => <Skeleton width="12rem" height="8rem" />)
            : skills.map(({ id, label }) => (
                <StyledSkillBox key={id}>
                  <Typography>{label}</Typography>
                  <StyledIconBox>
                    <IconButton onClick={() => removeSkill(id)}>
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  </StyledIconBox>
                </StyledSkillBox>
              ))}
        </StyledSkillContainerBox>
      ) : (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faFileCertificate} size="2x" />}
          title="Add your skills"
          message="Complete your profile and increase collaboration by adding your professional skills and interests."
        />
      )}
    </Column>
  );
};

export default requireAuth(SkillPage);
