import { DashboardState } from '@pages/app/dashboard/dashboard-hook';
import {
  DropDownFilter,
  SortByFilter,
  WCTTableFilters,
} from '@components/table';
import RadioFilter from '@components/table/filters/radio-filter';
import { AnalysisViewMode } from '@pages/app/analysis/analysis-overview-hook';
import { ReactComponent as BarsIcon } from '@assets/svgs/bars.svg';
import { ReactComponent as GridIcon } from '@assets/svgs/grid-2.svg';
import { ReactComponent as LocationIcon } from '@assets/svgs/location-dot.svg';
import { Gap } from '@components/layout-util-components/gap';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { SortDirection } from '@api/types/sort-direction';

interface Props {
  state: DashboardState;
}

export default function AnalysisDashboardFilters({ state }: Props) {
  const {
    isLoading,
    viewMode,
    isFetchingUserCases,
    userCaseSearch,
    userCaseSort,
    userCaseStatus,
    userCaseGroup,
    caseStatusOptions,
    caseGroupOptions,
  } = state;

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name A-Z', direction: SortDirection.asc },
    { id: 'name', label: 'Name Z-A', direction: SortDirection.desc },
    {
      id: 'created',
      label: 'Date Created (Newest First)',
      direction: SortDirection.desc,
    },
    {
      id: 'created',
      label: 'Date Created (Oldest First)',
      direction: SortDirection.asc,
    },
  ];

  return (
    <>
      <WCTTableFilters
        isLoading={isLoading}
        isFetching={isFetchingUserCases}
        search={userCaseSearch.value}
        onSearch={userCaseSearch.set}
        rightOfSearchContent={
          <SortByFilter
            value={userCaseSort.value}
            onChange={userCaseSort.set}
            options={sortByOptions}
          />
        }
      >
        <DropDownFilter
          value={userCaseStatus.value}
          onChange={userCaseStatus.set}
          options={caseStatusOptions ?? []}
          styledOptions={{
            marginLeft: 'auto',
          }}
          multiple
        >
          Status
        </DropDownFilter>
        <DropDownFilter
          value={userCaseGroup.value}
          onChange={userCaseGroup.set}
          options={caseGroupOptions ?? []}
          multiple
        >
          Group
        </DropDownFilter>
        <RadioFilter
          options={[
            { id: AnalysisViewMode.grid, label: <GridIcon /> },
            { id: AnalysisViewMode.table, label: <BarsIcon /> },
            { id: AnalysisViewMode.map, label: <LocationIcon /> },
          ]}
          value={viewMode.value}
          onChange={viewMode.set}
        />
      </WCTTableFilters>
      <Gap size={32} />
    </>
  );
}
