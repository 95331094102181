export enum ChainItemActionType {
  task = 'Task',
  evidence = 'Evidence',
  solution = 'Solution',
  noEndState = 'NoEndState',
  impact = 'Impact',
}

export enum ChainItemActionSubType {
  taskIncomplete = 'TaskIncomplete',
  evidenceNA = 'EvidenceNA',
  solutionNA = 'SolutionNA',
  noEndState = 'NoEndState',
}

export interface ChainItemAction {
  chainItemActionTypeKey: ChainItemActionType;
  chainItemActionSubTypeKey: ChainItemActionSubType;
  count: number;
  points: number;
}

export interface ChainItemActionRow {
  chainItemId: number;
  label: string;
  count: number;
  points: number;
  actions: Array<ChainItemAction>;
}
