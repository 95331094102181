import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useSolutionsPanel, {
  SolutionsPanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import SolutionsOptionsView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-options-view';
import CreateSolutionFormView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-solution-form-view';
import { OutlinedButton } from '@components/buttons';
import CreateFromCaseSolutionFormView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-from-case-solution-form-view';
import SolutionsListView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-list-view';
import { Gap } from '@components/layout-util-components/gap';
import ChainItemOnboardingContainer from '@components/containers/chain-item-onboarding-container';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

export default function SolutionsPanel() {
  const state = useSolutionsPanel();
  const {
    solutions,
    viewState,
    startCreateFlow,
    isLoading,
    isEditing,
    canContribute,
    solutionNa,
    isFetching,
  } = state;

  const isInForm =
    viewState === SolutionsPanelViewState.create ||
    viewState === SolutionsPanelViewState.createFromCaseSolution;

  const isAssigningExisting =
    viewState === SolutionsPanelViewState.createFromCaseSolution ||
    viewState === SolutionsPanelViewState.edit;

  return (
    <Column>
      {!isAssigningExisting && (
        <EditPanelHeader
          title={
            !isInForm
              ? 'Solutions Assigned to this Cause'
              : isEditing
              ? 'Edit Solution'
              : 'Create Solution'
          }
          count={!isInForm ? solutions?.totalCount : undefined}
        />
      )}
      <FetchingIndicator show={isFetching} />
      {canContribute && viewState === SolutionsPanelViewState.view ? (
        <OutlinedButton onClick={startCreateFlow}>
          Create/Assign Solution
        </OutlinedButton>
      ) : null}
      {!isAssigningExisting && <Gap size={20} />}
      {isLoading ? (
        <LoadingIndicator show xOffset={-68} />
      ) : viewState === SolutionsPanelViewState.empty ? (
        <ChainItemOnboardingContainer
          area="Solution"
          notApplicable={solutionNa}
          onCreateClicked={startCreateFlow}
          canContribute={canContribute}
        />
      ) : viewState === SolutionsPanelViewState.createOptions ? (
        <SolutionsOptionsView state={state} />
      ) : viewState === SolutionsPanelViewState.create ? (
        <CreateSolutionFormView state={state} />
      ) : viewState === SolutionsPanelViewState.createFromCaseSolution ||
        viewState === SolutionsPanelViewState.edit ? (
        <CreateFromCaseSolutionFormView state={state} />
      ) : viewState === SolutionsPanelViewState.view ? (
        <SolutionsListView state={state} />
      ) : null}
    </Column>
  );
}
