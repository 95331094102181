import { styled, Typography } from '@mui/material';
import { ReactComponent as ChevronLeft } from '@assets/svgs/chevron-left.svg';
import { useNavigate } from 'react-router-dom';

interface Props {
  breadCrumb: string;
  title: string;
}

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 22,
});

const StyledButton = styled('button')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 42,
  height: 42,
  background: palette.common.white,
  boxShadow: '0px 5px 20px 0 rgba(0, 0, 0, 0.04)',
  border: 'none',
  borderRadius: 4,
  flex: '0 0 auto',
  cursor: 'pointer',
  '& svg path': {
    fill: palette.primary.main,
  },
}));

export default function SubpageTitle({ breadCrumb, title }: Props) {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledButton type="button" onClick={() => navigate(-1)}>
        <ChevronLeft />
      </StyledButton>
      <div>
        <Typography variant="body2">{breadCrumb}</Typography>
        <Typography variant="h6">{title}</Typography>
      </div>
    </Container>
  );
}
