import { Skeleton } from '@mui/material';
import WCTDateField from '@components/input/date-field';

export default function DatePickerSkeleton() {
  return (
    <Skeleton variant="rectangular" width="100%">
      <WCTDateField label="loading" onChange={() => {}} />
    </Skeleton>
  );
}
