import useImpactInfo from '@pages/app/rca/create/forms/impact-info-hook';
import ImpactInfoFormInputs from './components/impact-info-form-inputs';
import ImpactInfoFormButtons from './components/impact-info-form-buttons';
import Row from '@components/layout-util-components/row';

interface ImpactInfoPanelFormProps {
  caseId: number;
  onSuccess: () => void;
  onCancel: () => void;
}

function ImpactInfoPanelForm({
  caseId,
  onSuccess,
  onCancel,
}: ImpactInfoPanelFormProps) {
  const state = useImpactInfo(caseId);
  return (
    <>
      <ImpactInfoFormInputs {...{ ...state, ...{ onSuccess: onSuccess } }} />
      <Row>
        <ImpactInfoFormButtons
          {...{ ...state, ...{ onCancel: onCancel, addAnother: false } }}
        />
      </Row>
    </>
  );
}

export default ImpactInfoPanelForm;
