import { Box, Skeleton, styled, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import WCTPhoneNumberField from '@components/input/phone-number-field';
import WCTSelectField from '@components/input/select-field';
import { PrimaryButton } from '@components/buttons';
import { useAppTr } from '@i18n/use-app-tr';
import useRegisterInterest from '@pages/app/register-interest/register-interest-hook';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

const StyledForm = styled('form')({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 25,
});

export default function RegisterInterestPage() {
  const { t } = useAppTr('registerInterest');

  const {
    country,
    countries,
    dialCodes,
    companyName,
    firstName,
    surname,
    submit,
    isSubmitting,
    canSubmit,
    email,
    requirements,
    isLoading,
    jobTitle,
    phoneNumber,
  } = useRegisterInterest();

  const onSubmit = async () => {
    if (await submit()) {
      // TODO(Nick):: Go somewhere?
    }
  };

  return (
    <>
      <Typography variant="h4">
        {isLoading ? <Skeleton /> : t('title')}
      </Typography>
      <Gap size={15} />
      <Typography variant="body1">
        {isLoading ? <Skeleton /> : t('message')}
      </Typography>
      <Gap size={32} />
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.email')}
            value={email.value}
            error={email.error}
            onChange={email.set}
            required
          />
        )}
        <Box display="flex" gap={2}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('fields.firstName')}
              value={firstName.value}
              error={firstName.error}
              onChange={firstName.set}
              required
            />
          )}
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('fields.surname')}
              value={surname.value}
              error={surname.error}
              onChange={surname.set}
              required
            />
          )}
        </Box>
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTPhoneNumberField
            id="phone-number"
            label={t('fields.phoneNumber')}
            dialCodes={dialCodes ?? []}
            value={phoneNumber.value}
            errors={phoneNumber.errors}
            onChange={phoneNumber.set}
            required
          />
        )}
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.companyName')}
            value={companyName.value}
            error={companyName.error}
            onChange={companyName.set}
            required
          />
        )}
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.jobTitle')}
            value={jobTitle.value}
            error={jobTitle.error}
            onChange={jobTitle.set}
          />
        )}

        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="country"
            label={t('fields.country')}
            options={countries ?? []}
            value={country.value}
            error={country.error}
            onChange={(v) => country.set(v as number)}
            required
          />
        )}

        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.requirements')}
            value={requirements.value}
            error={requirements.error}
            onChange={requirements.set}
            required
          />
        )}

        <PageAlertDisplay />

        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <PrimaryButton
            disabled={!canSubmit}
            isBusy={isSubmitting}
            type="submit"
            onClick={onSubmit}
          >
            {t('buttonLabel')}
          </PrimaryButton>
        )}

        <Typography variant="caption">
          {isLoading ? <Skeleton /> : t('footerMessage')}
        </Typography>
      </StyledForm>
    </>
  );
}
