export interface Window {
  _env_: {
    REACT_APP_API_URL: string;
  };
}

const useProxy = !!process.env.REACT_APP_PROXY;
const runtimeApiUrl = (window as unknown as Window)?._env_.REACT_APP_API_URL;

const config = {
  apiUrl:
    runtimeApiUrl !== '%%_REACT_APP_API_URL_%%'
      ? runtimeApiUrl
      : useProxy
      ? ''
      : process.env.REACT_APP_API_URL,
};

export default config;
