import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { faListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onCreateClicked?: () => void;
}

export default function NoTaskInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faListCheck} size="2x" />}
      title="There are no Tasks to show"
      message="You can create your first Task from within an RCA Visual Analysis"
    />
  );
}
