import { useEffect, useMemo, useState } from 'react';
import {
  CaseImpactStatRow,
  CaseImpactStats,
} from '@pages/app/rca/create/steps/impact-step-models';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  useDeleteCaseImpactMutation,
  useGetCaseImpactsQuery,
} from '@api/endpoints/case.api';
import {
  CaseImpactType,
  CaseImpactTypeMetricsResource,
} from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { isApiError } from '@api/types/api-error';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import caseImpactTypeMetricApi from '@api/endpoints/case-impact-type-metric.api';
import { CaseImpactUtil } from '@util/case-impact-util';
import { useGetCaseImpactSeverityOptionsQuery } from '@api/endpoints/case-impact-severity.api';
import { useIsPath } from '@util/path-util';
import useSystemText from '@hooks/use-system-text';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import useBusyAction from '@hooks/use-busy-action-hook';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import ImpactInfoDrawerForm from '@pages/app/rca/create/forms/impact-info-drawer-form';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { useCasePermission } from '@hooks/case/use-case-permission';

export default function useImpactStep(caseId: number) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { showPopup } = useUiPopup();
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const dispatch = useAppDispatch();
  const { systemText } = useSystemText();
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const { canContribute } = useCasePermission();

  const [statData, setStatData] = useState<CaseImpactStats>();

  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseImpactSeverityOptionsQuery(caseId);

  const { data: impactTypeOptions, isLoading: loadingImpactTypeOptions } =
    useGetCaseImpactTypesQuery(caseId);

  const {
    data: impactData,
    isLoading: loadingImpactData,
    isFetching,
  } = useGetCaseImpactsQuery({ caseId });

  const [deleteImpact] = useDeleteCaseImpactMutation();

  const isLoading =
    loadingImpactTypeOptions || loadingImpactData || loadingSeverityOptions;

  const impactModels = useMemo(() => {
    return impactData ?? [];
  }, [impactData]);

  const actualImpacts = useMemo(() => {
    return impactModels.filter((x) => x.actualValue === CaseImpactType.actual);
  }, [impactModels]);

  const potentialImpacts = useMemo(() => {
    return impactModels.filter(
      (x) => x.actualValue === CaseImpactType.potential
    );
  }, [impactModels]);

  const [remove, isDeletingImpact] = useBusyAction(async (impactId: number) => {
    try {
      await deleteImpact({
        caseId,
        caseImpactId: impactId,
      }).unwrap();
      await invalidation('CaseImpact', impactId);
      showSuccessMessage('Impact removed');
      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  });

  useEffect(() => {
    if (impactTypeOptions == null) {
      return;
    }

    const timeout = setTimeout(async () => {
      const promises: Record<
        number,
        Promise<CaseImpactTypeMetricsResource>
      > = {};

      for (const inputType of impactTypeOptions) {
        promises[inputType.caseImpactTypeMetricId] = dispatch(
          caseImpactTypeMetricApi.endpoints.getMetricDetail.initiate(
            inputType.caseImpactTypeMetricId
          )
        ).unwrap();
      }

      const results = await Promise.allSettled(
        Object.values(promises).map((x) => x)
      );

      const metricInfos: Record<number, CaseImpactTypeMetricsResource> = {};
      for (const result of results) {
        if (result.status === 'fulfilled') {
          const data = result.value;
          metricInfos[data.caseImpactTypeMetricId] = data;
        }
      }

      const rows: Array<CaseImpactStatRow> = [];
      for (const impactModel of impactModels) {
        const impactType = impactTypeOptions.find(
          (x) => x.caseImpactTypeId === impactModel.caseImpactTypeId
        );
        if (impactType == null) {
          continue;
        }

        const metricInfo = metricInfos[impactType.caseImpactTypeMetricId];
        if (metricInfo == null) {
          continue;
        }

        const actualImpactArr = impactModels.filter(
          (x) =>
            x.caseImpactTypeMetricId === impactType.caseImpactTypeMetricId &&
            x.actualValue
        );
        const potentialImpactArr = impactModels.filter(
          (x) =>
            x.caseImpactTypeMetricId === impactType.caseImpactTypeMetricId &&
            !x.actualValue
        );

        const stats = CaseImpactUtil.getImpactStatsRow(
          actualImpactArr,
          potentialImpactArr,
          impactType.name,
          metricInfo,
          currency
        );
        if (!rows.find((x) => x.name === stats.name)) {
          rows.push(stats);
        }
      }
      setStatData({
        rows,
      });
    }, 300);

    return () => clearTimeout(timeout);
  }, [currency, dispatch, impactModels, impactTypeOptions]);

  const canSubmit = !isLoading && !isDeletingImpact && !isFetching;

  const isEmpty = impactModels.length === 0;

  const beginCreate = () => {
    showPopup(ImpactInfoDrawerForm, { caseId: caseId! });
  };

  const beginEdit = (resource: CaseImpactResource) => {
    showPopup(ImpactInfoDrawerForm, { caseId: caseId!, model: resource });
  };

  return {
    isFetching,
    impactModels,
    remove,
    isLoading,
    canSubmit,
    impactTypeOptions,
    actualImpacts,
    potentialImpacts,
    statData,
    isEdit,
    severityOptions,
    systemText,
    isEmpty,
    beginCreate,
    beginEdit,
    caseId,
    canContribute,
  };
}

export type ImpactStepState = ReturnType<typeof useImpactStep>;
