import { DashboardState } from '@pages/app/dashboard/dashboard-hook';
import { SortByFilter, WCTTableFilters } from '@components/table';
import { Gap } from '@components/layout-util-components/gap';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { SortDirection } from '@api/types/sort-direction';

interface Props {
  state: DashboardState;
}

export default function GroupDashboardFilters({ state }: Props) {
  const { isLoading, isFetchingUserGroups, userGroupSearch, userGroupSort } =
    state;

  const sortByOptions: Array<SortOption> = [
    {
      id: 'groupName',
      label: 'Name (A-Z)',
      direction: SortDirection.asc,
    },
    {
      id: 'groupName',
      label: 'Name (Z-A)',
      direction: SortDirection.desc,
    },
  ];

  return (
    <>
      <WCTTableFilters
        isFetching={isFetchingUserGroups}
        search={userGroupSearch.value}
        onSearch={userGroupSearch.set}
        isLoading={isLoading}
        rightOfSearchContent={
          <SortByFilter
            value={userGroupSort.value}
            onChange={userGroupSort.set}
            options={sortByOptions}
          />
        }
      />
      <Gap size={32} />
    </>
  );
}
