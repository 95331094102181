// A hook that scrolls to the provided element ID when the component mounts.
import { useEffect } from 'react';

export default function useScrollToOnMount(elementId: string) {
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementId]);
}
