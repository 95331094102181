import { OutcomeStepProps } from '@pages/app/rca/outcome/rca-outcome-page';
import useOutcomePreviewStep from '@pages/app/rca/outcome/views/outcome-preview-step-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import FullPageFormScaffold from '@components/layouts/scaffolds/full-page-form-scaffold';
import OutcomeReportScaffold from '@components/outcome-report/outcome-report-scaffold';

export default function OutcomePreviewStep({ state }: OutcomeStepProps) {
  const { appBarRef } = state;
  const { isLoading } = useOutcomePreviewStep(state);

  return (
    <FullPageFormScaffold appBarRef={appBarRef} onSubmit={() => {}} noPadding>
      {isLoading ? (
        <LoadingIndicator show />
      ) : (
        <OutcomeReportScaffold
          caseId={state.caseId}
          outcomeId={state.outcomeId!}
        />
      )}
    </FullPageFormScaffold>
  );
}
