import { Box, Chip, Typography } from '@mui/material';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { ReactNode } from 'react';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { BorderedContainer } from '@components/containers/bordered-container';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  evidence: CaseEvidenceResource;
  endAction?: ReactNode;
}

export default function EvidenceCard({ evidence, endAction }: Props) {
  const { description, name, sensitivity, chainItemIds, quality } = evidence;

  return (
    <BorderedContainer>
      <Column sx={{ flexGrow: 1, flexShrink: 0, gap: 1 }}>
        <div>
          <Typography>{name}</Typography>
          <Typography>{description}</Typography>
        </div>
        <Row>
          <Chip label={sensitivity} />
          <Gap size={8} />
          <Chip label={`${quality}/5`} />
        </Row>
        <Box alignSelf="flex-start">
          <AssociatedCauseBoxBadge count={chainItemIds?.length ?? 0} />
        </Box>
      </Column>
      {endAction ? endAction : null}
    </BorderedContainer>
  );
}
