import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';

interface CheckboxFieldProps {
  label: ReactNode;
  name?: string;
  value: boolean;
  error?: string;
  onChange: (value: boolean) => void;
  outline?: boolean;
}

const StyledControl = styled(FormControlLabel, { target: 'checkbox-field' })<{
  outline?: boolean;
}>(({ outline, theme }) => ({
  borderRadius: 4,
  border: outline ? `1px solid ${theme.palette.common.grey50}` : 'none',
  marginLeft: outline ? 0 : undefined,
  padding: outline ? '4px 10px' : undefined,
  gap: 12,

  '.MuiButtonBase-root': {
    padding: outline ? 0 : undefined,
  },
}));

export default function WCTCheckboxField({
  label,
  name,
  value,
  onChange,
  error,
  outline,
}: CheckboxFieldProps) {
  return (
    <Column>
      <StyledControl
        control={
          <Checkbox
            value={value}
            name={name}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        }
        label={label}
        labelPlacement="end"
        outline={outline}
      />
      {!isNullOrEmpty(error) ? (
        <>
          <Gap size={8} />
          <FormHelperText error>{error}</FormHelperText>
        </>
      ) : null}
    </Column>
  );
}
