import { api } from '@api/api';
import { Option } from '@api/types/option';

const frontendModeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFrontendModeOptions: build.query<Option[], void>({
      query: () => 'frontendmode/options',
    }),
  }),
});

export const { useGetFrontendModeOptionsQuery } = frontendModeApi;
