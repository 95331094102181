import {
  ReportRow,
  RunReportResponse,
} from '@api/types/case/case-report/run-report.response';
import { useMemo } from 'react';
import { ReportUtil } from '@util/report-util';

export interface ReportDataSplit {
  nonNegativeIdRows: Array<ReportRow>;
  negativeIdRows: Array<ReportRow>;
  allRows: Array<ReportRow>;
  percentages: Record<number, number>;
}
export default function useReportDataSplit(
  report?: RunReportResponse<any, { coverageShare?: number[] }>
) {
  return useMemo(() => {
    if (report == null) {
      return {
        nonNegativeIdRows: [],
        negativeIdRows: [],
        allRows: [],
        percentages: {},
      } as ReportDataSplit;
    }

    return ReportUtil.splitRows(report!, report.data);
  }, [report]);
}
