import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { ConfirmationModal } from '@components/ui-popup/modals';
import { useAppTr } from '@i18n/use-app-tr';
import useLeaveGroup from '@pages/app/profile/components/leave-group-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import GroupForm, { GroupFormProps } from '@pages/app/groups/forms/group-form';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';

export enum LeaveGroupAction {
  cancel,
  edit,
  success,
}
export interface LeaveGroupModalProps extends ModalProps<LeaveGroupAction> {
  companyGroupId: number;
  companyName: string;
}

function LeaveGroupModal({
  companyGroupId,
  companyName,
  onClose,
  show,
}: LeaveGroupModalProps) {
  const { showPopup } = useUiPopup();
  const { t } = useAppTr('groups');

  const { isLoading, isSubmitting, canLeave, onLeaveGroup } = useLeaveGroup(
    companyGroupId,
    companyName
  );

  const title = canLeave
    ? t('leaveModal.title.allowed')
    : t('leaveModal.title.disallowed');
  const message = canLeave
    ? t('leaveModal.message.allowed')
    : t('leaveModal.message.disallowed');
  const yesButtonLabel = canLeave
    ? t('leaveModal.primaryButtonLabel.allowed')
    : t('leaveModal.primaryButtonLabel.disallowed');

  const onConfirmation = async (shouldLeave?: boolean) => {
    if (!shouldLeave) {
      onClose(LeaveGroupAction.cancel);
      return;
    }

    if (canLeave) {
      if (await onLeaveGroup()) {
        onClose(LeaveGroupAction.success);
      }
      return;
    } else {
      showPopup<GroupFormProps, CompanyGroupResource>(GroupForm, {
        groupId: companyGroupId,
      });
    }

    onClose(LeaveGroupAction.edit);
  };

  return (
    <ConfirmationModal
      show={show}
      onClose={onConfirmation}
      title={!isLoading ? title : null}
      message={!isLoading ? message : <LoadingIndicator show />}
      noButtonLabel={t('leaveModal.cancelButtonLabel')}
      yesButtonLabel={
        isLoading || isSubmitting ? <LoadingIndicator show /> : yesButtonLabel
      }
    />
  );
}

export default withModal(LeaveGroupModal);
