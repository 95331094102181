import { useFilter, usePagination } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import useSortableHeader, {
  SortOption,
} from '@components/table/hooks/use-sortable-header-hook';
import {
  useGetCaseSolutionsQuery,
  useRemoveCaseSolutionMutation,
} from '@api/endpoints/case.api';
import { useGetSolutionTypeOptionsQuery } from '@api/endpoints/solution-type.api';
import { useGetSolutionTermOptionsQuery } from '@api/endpoints/solution-term.api';
import useUser from '@store/user/user-hook';
import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export default function useSolutions(caseId?: number) {
  const { companyUserId } = useUser();
  const typeFilter = useFilter<Array<number>>([]);
  const termFilter = useFilter<Array<number>>([]);
  const { canContribute } = useCasePermission();

  const currency = useAppSelector(selectCurrentRcaCurrency);

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name A-Z', direction: SortDirection.asc },
    { id: 'name', label: 'Name Z-A', direction: SortDirection.desc },
    { id: 'cost', label: 'Cost Asc', direction: SortDirection.asc },
    { id: 'cost', label: 'Cost Desc', direction: SortDirection.desc },
  ];

  const search = useFilter<string>();
  const pagination = usePagination();

  const sort = useSortableHeader({
    initialProperty: 'name',
    allowNoSort: false,
  });

  const {
    data: termFilterOptions,
    isLoading: termFilterOptionsLoading,
    isFetching: termFilterOptionsFetching,
  } = useGetSolutionTermOptionsQuery();

  const {
    data: typeFilterOptions,
    isLoading: typeFilterOptionsLoading,
    isFetching: typeFilterOptionsFetching,
  } = useGetSolutionTypeOptionsQuery();

  const {
    data: solutions,
    isLoading: loadingSolutions,
    isFetching,
  } = useGetCaseSolutionsQuery(
    {
      searchText: search.value,
      sortBy: sort.property,
      sortDirection: sort.direction,
      skip: pagination.skip,
      take: pagination.take,
      caseId: caseId as number,
      solutionTypes: typeFilter.arrayValue,
      solutionTerms: termFilter.arrayValue,
    },
    { skip: caseId == null }
  );

  const [deleteSolution, { isLoading: isDeletingSolution }] =
    useRemoveCaseSolutionMutation();

  const isLoading =
    loadingSolutions ||
    isDeletingSolution ||
    typeFilterOptionsLoading ||
    typeFilterOptionsFetching ||
    termFilterOptionsLoading ||
    termFilterOptionsFetching;

  const canDelete = (solution: CaseSolutionItemResponse) => {
    return canContribute || solution.createdByCompanyUserId === companyUserId;
  };

  return {
    search,
    sort,
    sortByOptions,
    isLoading,
    isFetching,
    solutions,
    typeFilter,
    typeFilterOptions,
    termFilter,
    termFilterOptions,
    hasNoSolutions: !isLoading && (solutions?.totalCount ?? 0) === 0,
    pagination,
    deleteSolution,
    canDelete,
    canContribute,
    caseId,
    currency,
  };
}
