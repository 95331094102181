import useUser from '@store/user/user-hook';
import {
  useGetUserStorePreferencesQuery,
  useSetUserStorePreferenceMutation,
} from '@api/endpoints/user-store.api';
import { UserPreferenceKey } from '@api/types/user-store/user-store-preference';
import { useCallback, useMemo } from 'react';
import { isAdmin } from '@util/env';

export default function useUserPreferences() {
  const { isLoggedInAndFullyAuthenticated } = useUser();
  const { data, isLoading } = useGetUserStorePreferencesQuery(undefined, {
    skip: !isLoggedInAndFullyAuthenticated || isAdmin,
  });

  const [set] = useSetUserStorePreferenceMutation();

  const get = useCallback(
    (key: UserPreferenceKey) => {
      if (data == null) {
        return undefined;
      }

      const index = data.findIndex((x) => x.storeKey === key);
      if (index === -1) {
        return undefined;
      }

      return data[index]!;
    },
    [data]
  );

  return useMemo(
    () => ({
      isLoading,
      get,
      getBool: (key: UserPreferenceKey) => get(key)?.storeValue === 'true',
      set: (key: UserPreferenceKey, value: string) =>
        set({ storeKey: key, storeValue: value }),
    }),
    [get, isLoading, set]
  );
}
