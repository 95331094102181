import { api } from '@api/api';
import { ChainItemCaseImpactResource } from '@api/types/chain/chain-item-case-impact/chain-item-case-impact.resource';
import { UpdateChainItemCaseImpactRequest } from '@api/types/chain/chain-item-case-impact/update-chain-item-case-impact.request';
import { makeError } from '@api/types/api-error';
import { DeleteChainItemCaseImpactRequest } from '@api/types/chain/chain-item-case-impact/delete-chain-item-case-impact.request';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

export interface ChainItemCaseImpactPathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemCaseImpactApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemCaseImpacts: build.query<
      Array<ChainItemCaseImpactResource>,
      ChainItemCaseImpactPathParams
    >({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItemCaseImpact/${chainItemId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (_, error, arg) =>
        !error
          ? [
              { type: 'ChainItemCaseImpact' as const, id: arg.chainItemId },
              'ChainItemCaseImpact',
            ]
          : [],
    }),
    updateChainItemCaseImpacts: build.mutation<
      void,
      UpdateChainItemCaseImpactRequest
    >({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItemCaseImpact/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, request) =>
        !error
          ? [
              { type: 'ChainItemCaseImpact' as const, id: request.chainItemId },
              'ChainItemCaseImpact',
              'CaseTotals',
            ]
          : [],
      transformErrorResponse: (error) =>
        makeError<UpdateChainItemCaseImpactRequest>(error),
    }),
    deleteChainItemCaseImpact: build.mutation<
      void,
      DeleteChainItemCaseImpactRequest
    >({
      query: ({ chainId, chainItemId, chainItemCaseImpactId }) => ({
        url: `chain/${chainId}/chainItemCaseImpact/${chainItemId}/${chainItemCaseImpactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, request) =>
        !error
          ? [
              { type: 'ChainItemCaseImpact' as const, id: request.chainItemId },
              'ChainItemCaseImpact',
              'CaseTotals',
            ]
          : [],
    }),
  }),
});

export const {
  useGetChainItemCaseImpactsQuery,
  useUpdateChainItemCaseImpactsMutation,
  useDeleteChainItemCaseImpactMutation,
} = chainItemCaseImpactApi;

export default chainItemCaseImpactApi;
