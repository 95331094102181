import { InlineBoxSkeleton } from '@components/skeletons';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { ConfirmationModal } from '@components/ui-popup/modals';
import { useState } from 'react';
import { PrimaryButton } from '@components/buttons';
import { Gap, GapSize } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faLock,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import ReferenceDataDisableList from './reference-data-disable-list';
import Row from '@components/layout-util-components/row';
import NoTextBadge from '@components/outcome-report/components/no-text-badge';

export interface ReferenceDataDisableListOption {
  id: number;
  label: string;
  enabled: boolean;
  hasWarning: boolean;
  disabledDateTime?: Date;
  children?: ReferenceDataDisableListOption[];
  colorId?: number;
}

interface Props {
  isLoading: boolean;
  option: ReferenceDataDisableListOption;
  onEnable: (id: number) => void;
  onDisable: (id: number) => void;
  onDelete?: (id: number) => void;
  type: string;
  isTopLevel?: boolean;
  onCreateClick?: () => void;
  onDeleteClick?: () => void;
  children?: ReferenceDataDisableListOption[];
  sx?: SxProps<Theme>;
  childSx?: SxProps<Theme>;
  space?: GapSize;
}

interface SelectedToDelete {
  id: number;
  label: string;
}

const ChildText = styled(Typography, { target: 'associated-cause-box-badge' })(
  ({ theme: { palette } }) => ({
    marginTop: '13px',
    color: palette.blue.dark,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0em',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 10,
    alignSelf: 'flex-start',
    width: '160px',
  })
);

export default function ReferenceDataDisableListItem({
  isLoading,
  option,
  onEnable,
  onDisable,
  onDelete,
  type,
  onCreateClick,
  onDeleteClick,
  children,
  isTopLevel,
  sx,
  childSx,
  space,
}: Props) {
  const [selectedToDelete, setSelectedToDelete] = useState<SelectedToDelete>();
  const [selected, setSelected] = useState<number>();
  const [showChildren, setShowChildren] = useState<boolean>();
  return (
    <>
      <SwitchContainer variant="light" sx={{ alignItems: 'center', ...sx }}>
        {isLoading ? (
          <InlineBoxSkeleton />
        ) : (
          <>
            <Box sx={{ flex: 1 }}>
              <Row>
                {onCreateClick && (
                  <Box sx={{ width: '60px' }}>
                    {children && children.length > 0 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowChildren(!showChildren);
                        }}
                      >
                        {showChildren ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )}
                      </IconButton>
                    )}
                  </Box>
                )}
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ marginRight: '10px' }}>
                    {option.label}
                  </Typography>
                  {type === 'theme' && isTopLevel && (
                    <NoTextBadge colourId={option.colorId ?? 0} />
                  )}
                </Box>
              </Row>
            </Box>
            {children && children.length > 0 && (
              <ChildText>
                {children.length} child{' '}
                {children.length === 1 ? type : `${type}s`}
              </ChildText>
            )}
            {onDeleteClick && (
              <Box sx={{ width: '130px', textAlign: 'center' }}>
                {!option.hasWarning ? (
                  <IconButton onClick={onDeleteClick}>
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                ) : (
                  <Box title="You cannot delete or edit this Theme as it's been used on an RCA">
                    <IconButton>
                      <FontAwesomeIcon icon={faLock} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
            {onCreateClick && (
              <PrimaryButton onClick={onCreateClick}>
                Add sub {type}
              </PrimaryButton>
            )}
            <Box sx={{ marginLeft: '10px', minWidth: '130px' }}>
              <WCTSwitchField
                name={`theme-toggle-${option.id}`}
                label={option.enabled ? 'Active' : 'Disabled'}
                value={option.enabled}
                onChange={(enabled: boolean) => {
                  if (!enabled) {
                    setSelected(option.id);
                  } else {
                    onEnable(option.id);
                  }
                }}
              />
            </Box>
          </>
        )}
      </SwitchContainer>
      <Gap size={space ?? 15} />
      {children && showChildren && (
        <Box
          sx={{
            marginLeft: '60px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReferenceDataDisableList
            isLoading={isLoading}
            options={children}
            onEnable={onEnable}
            onDisable={onDisable}
            onDelete={onDelete}
            type={type}
            onDeleteClick={(id: number, label: string) =>
              setSelectedToDelete({ id, label })
            }
            sx={childSx ?? sx}
            space={space}
          />
        </Box>
      )}
      {selected && (
        <ConfirmationModal
          show={true}
          title={`Are you sure you want to disable this ${type}?`}
          message={`Disabling this ${type} will make it unavailable to select for new RCA. It will remain active on all existing RCA.`}
          yesButtonAsyncAction={async () => {
            await onDisable(selected);
            return true;
          }}
          onClose={() => setSelected(undefined)}
        />
      )}
      {selectedToDelete && (
        <ConfirmationModal
          show={true}
          title={`Are you sure you want to delete the ${type} '${selectedToDelete.label}'?`}
          message={'This action is permanent cannot be undone.'}
          yesButtonAsyncAction={async () => {
            onDelete && (await onDelete(selectedToDelete.id));
            return true;
          }}
          onClose={() => setSelectedToDelete(undefined)}
        />
      )}
    </>
  );
}
