import { Typography, styled } from '@mui/material';

const StyledFormTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '35px',
  letterSpacing: '-0.5px',
});

interface Props {
  text: string;
}

function FormTitle({ text }: Props) {
  return <StyledFormTitle variant="body1">{text}</StyledFormTitle>;
}

export default FormTitle;
