import { Grid, Link, Typography } from '@mui/material';
import useForgotPassword from '@pages/auth/forgot-password-hook';
import { useState } from 'react';
import { PageAlertDisplay, SuccessAlert } from '@components/alerts';
import { useAuthTr } from '@i18n/use-auth-tr';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import { PrimaryButton } from '@components/buttons';

export default function ForgotPasswordPage() {
  const { email, submit, canSubmit, isLoading } = useForgotPassword();

  const { t } = useAuthTr('forgotPassword');

  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = async () => {
    if (await submit()) {
      setShowSuccess(true);
    }
  };

  return (
    <Grid
      container
      component="form"
      noValidate
      direction="column"
      gap="25px"
      justifyContent="center"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h2" style={{ fontSize: '32px' }}>
        {t('title')}
      </Typography>
      {showSuccess ? (
        <>
          <Gap size={2} />
          <SuccessAlert
            title={t('alerts.sent.title')}
            message={t('alerts.sent.message')}
          />
          <Gap size={2} />
        </>
      ) : (
        <>
          <Typography variant="body1" color="common.textMid">
            {t('message')}
          </Typography>
          <PageAlertDisplay />
          <WCTTextField
            label={t('email')}
            value={email.value}
            error={email.error}
            onChange={email.set}
            required
          />

          <PrimaryButton
            type="submit"
            size="large"
            disabled={!canSubmit}
            isBusy={isLoading}
          >
            <Typography>{t('buttonLabel')}</Typography>
          </PrimaryButton>
        </>
      )}
      <Grid item alignSelf="center">
        <Typography variant="body1">
          <Link color="common.textDark" href="../login">
            {t('backButtonLabel')}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
