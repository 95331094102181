import { NotificationResource } from '@api/types/notifications/notification.resource';
import Column from '@components/layout-util-components/column';
import { Divider } from '@components/layout-util-components/divider';
import Row from '@components/layout-util-components/row';
import { Box, Button, styled, Typography } from '@mui/material';
import NotificationIndicator from '@components/notifications/notification-indicator';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons';
import { datetimeLongFormatter } from '@i18n/formatters';
import { useMemo } from 'react';
import { isNullOrEmpty } from '@util/string-util';

interface Props {
  notification: NotificationResource;
  deleteNotification: (notificationId: number) => void;
}

const Container = styled(Column, { target: 'notification-entry' })(
  ({ theme: { palette } }) => ({
    '.notification-indicator': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: palette.primary.main,
      marginRight: 10,

      '&.read': {
        backgroundColor: palette.grey[400],
      },
    },

    '.footer': {
      color: '#949FA2',
      fontSize: 13,
    },

    '.description': {
      textAlign: 'left',
      a: {
        color: `${palette.primary.main} !important`,
      },
    },
  })
);

export default function NotificationEntry({
  notification,
  deleteNotification,
}: Props) {
  const {
    read,
    description,
    companyUserName,
    created,
    fromCompanyUserName,
    kudos,
    notificationId,
  } = notification;

  const viewLinkData = useMemo(() => {
    const {
      caseId,
      caseName,
      companyGroupId,
      companyGroupName,
      taskDetailId,
      outcomeId,
      outcomeName,
    } = notification;

    if (outcomeId != null && caseId != null) {
      let link = `/outcome/${caseId}/report/${outcomeId}`;
      return {
        link,
        text: outcomeName,
        key: 'OutcomeName',
      };
    }

    if (caseId != null && !isNullOrEmpty(caseName)) {
      let link = `/rca/tabs/${caseId}`;
      if (taskDetailId != null) {
        link += `/tasks/?taskId=${taskDetailId}`;
      }
      return {
        link,
        text: caseName,
        key: 'CaseName',
      };
    }

    if (companyGroupId != null && !isNullOrEmpty(companyGroupName)) {
      return {
        link: `/groups/?companyGroupId=${companyGroupId}`,
        text: companyGroupName,
        key: 'CompanyGroupName',
      };
    }
  }, [notification]);

  const parsedDescription = useMemo(() => {
    const link = viewLinkData;
    let parsedDescription = description;

    if (link != null) {
      parsedDescription = parsedDescription.replaceAll(
        `{${link.key}}`,
        `<a href="${link.link}">${link.text}</a>`
      );
    }

    if (!isNullOrEmpty(fromCompanyUserName)) {
      parsedDescription = parsedDescription.replaceAll(
        '{FromCompanyUserName}',
        fromCompanyUserName
      );
    }

    if (kudos != null) {
      parsedDescription = parsedDescription.replaceAll(
        '{Kudos}',
        kudos.toString()
      );
    }

    return `<span>${parsedDescription}</span>`;
  }, [description, fromCompanyUserName, kudos, viewLinkData]);

  return (
    <Container>
      <Divider addMargin compact />
      <Row sx={{ width: 515 }}>
        <Column sx={{ flex: '1 1 auto' }}>
          <Row>
            <NotificationIndicator isRead={read} />
            <Gap size={4} />
            <Typography
              className="description"
              dangerouslySetInnerHTML={{ __html: parsedDescription }}
            ></Typography>
          </Row>
          <Gap size={8} />
          <Row className="footer">
            <FontAwesomeIcon icon={faUser} />
            <Gap size={8} />
            <Typography variant="body1">{companyUserName}</Typography>
            <Gap size={20} />
            <FontAwesomeIcon icon={faClock} />
            <Gap size={8} />
            <Typography variant="body1">
              {datetimeLongFormatter(created)}
            </Typography>
          </Row>
        </Column>
        <Box>
          <Button onClick={() => deleteNotification(notificationId)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </Box>
      </Row>
    </Container>
  );
}
