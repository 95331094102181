import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { TaskDetailListRequest } from '@api/types/task-detail/task-detail-list.request';
import { TaskDetailResource } from '@api/types/task-detail/task-detail.resource';
import { Option } from '@api/types/option';
import { CreateTaskDetailRequest } from '@api/types/task-detail/create-task-detail.request';
import { makeError } from '@api/types/api-error';
import { UpdateTaskDetailRequest } from '@api/types/task-detail/update-task-detail.request';
import { TaskDetailStats } from '@api/types/task-detail/task-detail-stats';
import { TaskDetailAttachmentResource } from '@api/types/task-detail/task-detail-attachment.resource';
import { FileUploadRequest, FileUploadResponse } from '@api/types/file-types';
import { CompleteTaskRequest } from '@api/types/task-detail/complete-task.request';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const taskDetailApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasksForUser: build.query<
      PaginatedResult<TaskDetailListItemResponse, TaskDetailStats>,
      TaskDetailListRequest
    >({
      query: (params) => ({ url: 'taskdetail', method: 'GET', params }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : ['TaskDetail'],
    }),
    getTasksForCompany: build.query<
      PaginatedResult<TaskDetailListItemResponse, TaskDetailStats>,
      TaskDetailListRequest
    >({
      query: (params) => ({ url: 'company/taskdetail', method: 'GET', params }),

      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : ['TaskDetail'],
    }),
    getTaskDetailForUser: build.query<TaskDetailResource, number>({
      query: (id) => ({
        url: `taskDetail/${id}`,
        method: 'GET',
      }),

      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (result) =>
        result
          ? [{ id: result.taskDetailId, type: 'TaskDetail' as const }]
          : [],
    }),
    getTaskDetailForCase: build.query<
      TaskDetailResource,
      { id: number; caseId: number }
    >({
      query: ({ id, caseId }) => ({
        url: `case/${caseId}/taskDetail/${id}`,
        method: 'GET',
      }),

      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (result) =>
        result
          ? [{ id: result.taskDetailId, type: 'TaskDetail' as const }]
          : [],
    }),
    createTaskForUser: build.mutation<
      TaskDetailResource,
      CreateTaskDetailRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/taskDetail`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateTaskDetailRequest>(error),
      invalidatesTags: (_, error) =>
        !error ? ['TaskDetail', 'CaseTotals'] : [],
    }),
    updateTaskForUser: build.mutation<
      TaskDetailResource,
      UpdateTaskDetailRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/taskDetail`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateTaskDetailRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg.taskDetailId, type: 'TaskDetail' as const }] : [],
    }),
    completeTaskForUser: build.mutation<void, CompleteTaskRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/taskDetail/complete`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { id: arg.taskDetailId, type: 'TaskDetail' as const },
              'CaseTotals',
            ]
          : [],
    }),
    openTaskForUser: build.mutation<void, CompleteTaskRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/taskDetail/open`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { id: arg.taskDetailId, type: 'TaskDetail' as const },
              'CaseTotals',
            ]
          : [],
    }),
    getTaskPriorityOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'taskDetail/priority/options',
        method: 'GET',
      }),
    }),
    getTaskStatusOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'taskDetail/status/options',
        method: 'GET',
      }),
    }),
    uploadTaskDetailAttachment: build.mutation<
      FileUploadResponse<TaskDetailAttachmentResource>,
      FileUploadRequest
    >({
      query: (request) => {
        const { files } = request;
        const formData = new FormData();
        files.forEach((file) => {
          formData.append('files', file);
        });

        return {
          url: 'taskDetail/attachment',
          method: 'POST',
          body: formData,
        };
      },
      transformErrorResponse: (error) => makeError<FileUploadRequest>(error),
    }),
  }),
});

export const {
  useCreateTaskForUserMutation,
  useUpdateTaskForUserMutation,
  useGetTaskStatusOptionsQuery,
  useGetTaskDetailForCaseQuery,
  useGetTasksForCompanyQuery,
  useGetTasksForUserQuery,
  useCompleteTaskForUserMutation,
  useOpenTaskForUserMutation,
} = taskDetailApi;

export default taskDetailApi;
