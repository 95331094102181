import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCirclePlay,
  faEye,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faPaperclip,
} from '@fortawesome/pro-light-svg-icons';
import { Box, Popover, Typography, styled } from '@mui/material';
import config from 'src/config';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { useState } from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  contentType?: string;
  fileMetadataId?: number;
  largeFileMetadataId?: number;
  blurredFileMetadataId?: number;
  largeBlurredFileMetadataId?: number;
  blurred?: boolean;
  caseId: number;
  type: CaseFileType;
  size: number;
  iconSize?: number;
  canUnblur?: boolean;
  backgroundColor?: string;
  color?: string;
}

function getFontAwesomeIcon(contentType: string): IconDefinition {
  const contentTypeIconMap: { [key: string]: IconDefinition } = {
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      faFilePowerpoint,
    'video/mp4': faCirclePlay,
    'video/x-msvideo': faCirclePlay,
    'video/quicktime': faCirclePlay,
    'video/x-ms-wmv': faCirclePlay,
    'audio/mpeg': faFileAudio,
    'audio/wav': faFileAudio,
    'text/plain': faFileAlt,
    'application/zip': faFileArchive,
    'application/x-rar-compressed': faFileArchive,
    'image/jpeg': faFileImage,
    'image/png': faFileImage,
    'image/gif': faFileImage,
  };

  // Return the corresponding Font Awesome icon, or a default icon if the content type is not found
  return contentTypeIconMap[contentType] || faFile;
}

const CircleContainer = styled('div')<{
  size: number;
  backgroundColor?: string;
  color?: string;
}>(({ size, backgroundColor, color, theme: { palette } }) => ({
  display: 'inline-flex',
  //justifyContent: 'center',
  backgroundColor: backgroundColor ?? palette.success.light,
  color: color ?? 'white',
  borderRadius: 999,
  alignItems: 'center',
  justifyContent: 'center',
  width: size,
  height: size,
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: '0.05em',
  textAlign: 'center',
  flexDirection: 'column',
}));

const RevealContainer = styled('div')<{ size: number }>(
  ({ size, theme: { palette } }) => ({
    width: size,
    height: size,
    display: 'block',
    zIndex: 1,
    paddingTop: '50%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    color: '#fff',
    background: 'rgba(0, 0, 0, .5)',
    borderRadius: '5px',
    cursor: 'pointer',
  })
);

const Container = styled('div')<{ size: number }>(
  ({ size, theme: { palette } }) => ({
    width: size,
    height: size,
    position: 'relative',
    // '&:hover': {
    //   '& div': {
    //     display: 'inline-flex',
    //   },
    // },
  })
);

export default function CaseFileIcon({
  contentType,
  fileMetadataId,
  largeFileMetadataId,
  blurredFileMetadataId,
  largeBlurredFileMetadataId,
  blurred,
  caseId,
  size,
  type,
  canUnblur,
  backgroundColor,
  color,
  iconSize: overrideIconSize,
}: Props) {
  const [overrideBlur, setOverrideBlur] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const largeSize = 500;

  const handleClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isBlurred = blurred && !overrideBlur;

  if (!overrideIconSize) {
    overrideIconSize = size;
  }

  let showNoMediaText = false;
  let iconSize: SizeProp = '2xl';
  let eyeIconSize: SizeProp | undefined = undefined;

  if (overrideIconSize > 50) {
    iconSize = '6x';
    eyeIconSize = '4x';
  }

  if (overrideIconSize >= 200) {
    iconSize = '4x';
    eyeIconSize = '6x';
    showNoMediaText = true;
  }

  const thumbnailImageUrl =
    fileMetadataId && caseId && type === CaseFileType.file
      ? `${config.apiUrl}/api/case/${caseId}/caseFile/file/${
          isBlurred ? blurredFileMetadataId : fileMetadataId
        }/Preview`
      : undefined;

  const largeImageUrl =
    fileMetadataId && caseId && type === CaseFileType.file
      ? `${config.apiUrl}/api/case/${caseId}/caseFile/file/${
          isBlurred ? largeBlurredFileMetadataId : largeFileMetadataId
        }/Preview`
      : undefined;

  const icon: IconDefinition | undefined = thumbnailImageUrl
    ? undefined
    : type === CaseFileType.file
    ? getFontAwesomeIcon(contentType!)
    : faPaperclip;

  return (
    <Container size={thumbnailImageUrl ? size : overrideIconSize}>
      {thumbnailImageUrl && (
        <>
          <img
            style={{ width: size, height: size, borderRadius: '5px' }}
            src={thumbnailImageUrl}
            alt="Upload preview"
          />
          <RevealContainer
            size={size}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            {/* <FontAwesomeIcon icon={faEye} size={eyeIconSize} /> */}
          </RevealContainer>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <Box sx={{ position: 'relative' }}>
              <img
                style={{
                  width: largeSize,
                  height: largeSize,
                  borderRadius: '5px',
                }}
                src={largeImageUrl}
                alt="Upload full preview"
              />
              {isBlurred && canUnblur && (
                <RevealContainer
                  size={largeSize}
                  title="Reveal original image"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOverrideBlur(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} size={eyeIconSize} />
                </RevealContainer>
              )}
            </Box>
          </Popover>
        </>
      )}
      {icon && (
        <CircleContainer
          size={overrideIconSize}
          backgroundColor={backgroundColor}
          color={color}
        >
          <FontAwesomeIcon icon={icon} size={iconSize} />
          {showNoMediaText && (
            <Typography sx={{ marginTop: '20px', display: 'block' }}>
              No media preview available
            </Typography>
          )}
        </CircleContainer>
      )}
    </Container>
  );
}
