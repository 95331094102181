import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import useField from '@hooks/use-field-hook';
import { BaseCaseFile } from '@api/types/file-types';
import { createValidator, equals, required } from '@util/validators';
import { useMemo, useState } from 'react';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { ApiError } from '@api/types/api-error';
import { CreateCaseFileRequest } from '@api/types/case/case-file/create-case-file.request';
import { useAppDispatch } from '@store/store';
import { usePageAlertVariants } from '@components/alerts';
import caseFileApi from '@api/endpoints/case-file.api';

export default function useCaseFileManagerUpload({
  caseId,
}: CaseFileManagerState) {
  const [isBusy, setIsBusy] = useState(false);
  const { showErrorMessage } = usePageAlertVariants();
  const dispatch = useAppDispatch();
  const requiredName = () => {
    return createValidator<Array<BaseCaseFile>>((value) => {
      if (!value || value.length === 0) return undefined;

      if (value.filter((x) => !x.filename).length > 0) {
        return 'All files require a name';
      }

      return undefined;
    });
  };

  const uploadedFiles = useField<Array<BaseCaseFile>>(
    [required({}, 'At least one file is required'), requiredName()],
    useMemo(() => [], [])
  );
  const confirmedTerms = useField<boolean>(
    [equals(true, 'Terms and conditions must be accepted')],
    false
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    confirmedTerms,
    uploadedFiles,
  ]);

  const canSubmitFiles = isValid && isDirty && !isBusy;

  const submit = async () => {
    console.log('fff 1');
    if (!validateAll()) {
      return false;
    }
    console.log('fff 2');
    try {
      setIsBusy(true);

      return await dispatch(
        caseFileApi.endpoints.createCaseFile.initiate({
          caseId: caseId,
          files: uploadedFiles.value.map((file) => ({
            caseFileType: CaseFileType.file,
            name: file.filename,
            fileMetadataId: file.file.fileMetadataId,
            smallThumbnailFileMetadataId:
              file.smallThumbnailFile?.fileMetadataId,
            smallBlurredThumbnailFileMetadataId:
              file.smallBlurredThumbnailFile?.fileMetadataId,
            mediumThumbnailFileMetadataId:
              file.mediumThumbnailFile?.fileMetadataId,
            mediumBlurredThumbnailFileMetadataId:
              file.mediumBlurredThumbnailFile?.fileMetadataId,
            largeThumbnailFileMetadataId:
              file.largeThumbnailFile?.fileMetadataId,
            largeBlurredThumbnailFileMetadataId:
              file.largeBlurredThumbnailFile?.fileMetadataId,
            showBlurredThumbnail: file.showBlurred,
          })),
        })
      ).unwrap();
    } catch (error) {
      setIsBusy(false);
      const { errors, message } = error as ApiError<
        CreateCaseFileRequest & { caseId: number }
      >;
      showErrorMessage(errors?.caseId ?? message);
      return false;
    }
  };

  return {
    uploadedFiles,
    confirmedTerms,
    canSubmitFiles,
    submit,
    isBusy,
    setIsBusy,
  };
}
