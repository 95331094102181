import { RcaUtil } from '@util/rca-util';
import { Box, Typography } from '@mui/material';

interface Props {
  health?: number;
}

export default function RcaHealthPill({ health }: Props) {
  const healthColor = RcaUtil.getHealthScoreColorFromScore(
    Math.round(health ?? 0)
  );

  return (
    <Box
      className="health"
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        maxWidth: '115px',
        borderColor: healthColor,
        background: `${healthColor}15`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        gap: '10px',
      }}
    >
      <Box
        sx={{
          width: '9px',
          height: '9px',
          borderRadius: 8,
          background: healthColor,
        }}
      />
      <Typography
        sx={{
          fontSize: '13px',
        }}
      >
        {!health || health < 0 ? 'N/A' : `${Math.round(health)}%`}
      </Typography>
    </Box>
  );
}
