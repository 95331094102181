import { api } from '@api/api';
import { Option } from '@api/types/option';

const dateformatApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDateFormatOptions: build.query<Option[], void>({
      query: () => 'dateformat/options',
    }),
  }),
});

export const { useGetDateFormatOptionsQuery } = dateformatApi;
