import { Skeleton, TableCell, TableRow } from '@mui/material';

interface Props {
  cols?: number;
}

export default function TableRowSkeleton({ cols }: Props) {
  return (
    <TableRow>
      <TableCell colSpan={cols}>
        <Skeleton variant="rectangular" />
      </TableCell>
    </TableRow>
  );
}
