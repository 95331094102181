import {
  useGetMfaOptionsQuery,
  useLazyRequest2FACodeQuery,
  useLoginWith2FACodeMutation,
} from '@api/endpoints/auth.api';
import useField from '@hooks/use-field-hook';
import { onlyNumbers, required } from '@util/validators';
import { ApiError } from '@api/types/api-error';
import { MfaCodeRequest } from '@api/types/auth/mfa-code.request';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MfaMethod } from '@api/types/auth/mfa-method';
import { MfaSubmitCodeRequest } from '@api/types/auth/mfa-submit-code.request';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useMfa() {
  const hasAutoSentCode = useRef(false);
  const [method, setMethodValue] = useState<MfaMethod>();

  const { showErrorMessage } = usePageAlertVariants();

  const {
    data: mfaOptions,
    isLoading: loadingOptions,
    refetch: refetchOptions,
  } = useGetMfaOptionsQuery();

  const [requestCode, { isFetching: isFetchingCode }] =
    useLazyRequest2FACodeQuery();

  const [loginWith2FA, { isLoading: isSubmitting }] =
    useLoginWith2FACodeMutation();

  const code = useField<string>([required(), onlyNumbers()]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([code]);
  const canSubmit = isValid && isDirty && !isSubmitting && !isFetchingCode;

  const hasExceededLimit = useMemo(() => {
    if (code.error == null) {
      return false;
    }

    return code.error!.indexOf('request a new code') !== -1;
  }, [code.error]);

  const setMethod = useCallback(
    (method: MfaMethod) => {
      setMethodValue(method);

      requestCode({
        method,
      })
        .unwrap()
        .then(() => {})
        .catch(({ message }: ApiError<MfaCodeRequest>) => {
          showErrorMessage(message);
        });
    },
    [requestCode, showErrorMessage]
  );

  useEffect(() => {
    if (
      hasAutoSentCode.current ||
      mfaOptions == null ||
      mfaOptions.disabled ||
      mfaOptions.disabledUntil != null
    ) {
      return;
    }

    hasAutoSentCode.current = true;

    const hasValidPhoneNumber =
      !!mfaOptions.phoneNumber && !!mfaOptions.phoneNumberConfirmed;
    // If we only have one option, automatically set it to that method
    if (mfaOptions.email && !hasValidPhoneNumber) {
      setMethod(MfaMethod.email);
    } else if (hasValidPhoneNumber && !mfaOptions.email) {
      setMethod(MfaMethod.sms);
    }
  }, [mfaOptions, setMethod]);

  const resendCode = () => {
    return requestCode({
      method: method!,
    })
      .unwrap()
      .then(() => {
        code.setError(undefined);
      })
      .catch(({ message }: ApiError<MfaCodeRequest>) => {
        showErrorMessage(message);
      });
  };

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return loginWith2FA({ method: method!, code: code.value })
      .unwrap()
      .then(() => true)
      .catch(({ message, errors }: ApiError<MfaSubmitCodeRequest>) => {
        showErrorMessage((errors?.method as string) ?? message);
        code.setError(errors?.code);

        refetchOptions();

        return false;
      });
  };

  return {
    isLoading: loadingOptions,
    method,
    setMethod,
    isFetchingCode,
    code,
    isSubmitting,
    submit,
    canSubmit,
    resendCode,
    mfaOptions,
    hasExceededLimit,
  };
}
