import { Box, Chip, Link, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoReportInfo from '@pages/app/dashboard/components/no-report-info';
import { getColorBrandId, getColorForId } from '@util/colour-identifiers';
import { RadialOverviewStat } from '@components/outcome-report/components/radial-chart';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { formatNumberToDecimals, truncateString } from '@util/string-util';
import { FlexColumn } from '@components/layout-util-components/flex';

const StyledHeader = styled(Typography)({
  fontSize: 18,
  lineHeight: '133.4%' /* 24.012px */,
  fontWeight: 400,
  '& > span': {
    color: '#FF6C00',
  },
  '&.content': {
    fontSize: 20,
    lineHeight: '140%',
    marginRight: '33%',
    '@media (max-width:1400px)': {
      marginRight: '0',
    },
  },
});

const StyledSubHeader = styled(Typography)({
  color: '#555555',
  fontSize: 16,
  fontWeight: 'normal',
  lineHeight: '150%' /* 18px */,
  letterSpacing: '0.15px',
  marginBottom: '10px',
});

const StyledLeagueEntry = styled(Box)({
  display: 'flex',
  padding: '8px 10px 8px 16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: 3,
  border: '1px solid #E0E0E0',
  background: 'white',
  p: {
    fontSize: 14,
    '&.dataValue': {
      width: '75px',
      textAlign: 'center',
    },
    '&.percentage': {
      textAlign: 'right',
    },
  },
});

export const DashboardStatContainer = styled(Box)<{ isTotals?: boolean }>(
  ({ isTotals }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    flex: '1 0 0',
    alignSelf: 'stretch',
    hr: {
      height: 1,
      background: 'rgba(0, 0, 0, 0.15)',
      margin: '20px 0 10px 0',
    },

    ...(isTotals
      ? {
          '> .MuiBox-root': {
            gap: 20,
            alignItems: 'center',
            '> .MuiBox-root:first-of-type': {
              display: 'flex',
              alignItems: 'center',
              width: '40px',
              height: '40px',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 300,
              borderRadius: 4,
              flexShrink: 0,
              svg: {
                height: '1.25em',
                path: {
                  fill: 'inherit',
                  stroke: 'inherit',
                },
              },
            },
            '> .MuiBox-root:last-of-type': {
              display: 'flex',
              flexDirection: 'column',
            },
            '.stat_header': {
              lineHeight: 1,
              marginBottom: '4px',
            },
            '.circle-container': {
              width: 36,
              height: 36,
              borderRadius: 18,
              fontSize: 12,
              flex: '0 0 36px',
            },
            '.flat-badge': {
              marginTop: '4px',
            },
            '.plus': {
              padding: '4px 6px',
              borderRadius: '4px',
              background: '#D8DBE3',
              color: '#000000',
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '12px',
              letterSpacing: '0em',
              textAlign: 'center',
              flex: '0 0 auto',
              marginTop: '4px',
            },
          },
        }
      : {
          margin: '30px 0',
          '> .MuiBox-root': {
            flexDirection: 'column',
            '> .MuiBox-root.coverage': {
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
            },
            '> .MuiBox-root.title': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 11,
            },
            '> .MuiBox-root.solution': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
        }),
  })
);

type StatBoxProps = {
  bgColor: string;
  fontColour: string;
  icon: ReactNode;
  value: ReactNode;
  label: ReactNode;
};

export const StyledStatBox = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 4,
  display: 'flex',
  background: palette.common.white,
  padding: '20px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

export const LargeStyledStatBox = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  background: palette.common.white,
  padding: '30px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  alignItems: 'start !important',
  justifyContent: 'flex-start',
  gap: '15px',
}));

export function DashboardStatBox({
  bgColor,
  fontColour,
  icon,
  value,
  label,
}: StatBoxProps) {
  return (
    <StyledStatBox>
      <Box sx={{ background: bgColor, color: fontColour }}>{icon}</Box>
      <Box>
        <StyledSubHeader className="stat_header" variant="body2">
          {label}
        </StyledSubHeader>
        <StyledHeader className="stat_subheader" variant="body1">
          {value}
        </StyledHeader>
      </Box>
    </StyledStatBox>
  );
}

export function LargeDashboardStatBox({
  bgColor,
  fontColour,
  icon,
  value,
  label,
}: StatBoxProps) {
  return (
    <LargeStyledStatBox>
      <Row>
        <Box
          sx={{
            background: bgColor,
            width: '46px',
            height: '46px',
            color: fontColour,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            fontSize: '20px',
          }}
        >
          {icon}
        </Box>
        <Box>
          <StyledSubHeader
            sx={{
              textTransform: 'none',
              color: '#555',
              marginLeft: '10px',
              fontSize: '18px',
            }}
            className="stat_header"
            variant="body2"
          >
            {label}
          </StyledSubHeader>
        </Box>
      </Row>
      <StyledHeader
        className="stat_subheader"
        sx={{
          fontSize: '30px',
        }}
        variant="body1"
      >
        {value}
      </StyledHeader>
    </LargeStyledStatBox>
  );
}

export interface DashboardLinkData {
  text: string;
  href?: string;
  onClick?: () => void;
}

export type LeagueTableStat = {
  id: number;
  name: string;
  colour: string;
  causeBoxes?: number;
  percentage: number;
};

type StyledLeagueTableProps = {
  title: string;
  description?: string | ReactNode;
  content?: string | ReactNode;
  tableTitle?: string;
  afterTitle?: ReactNode;
  emptyMessage?: string;
  entries?: LeagueTableStat[];
  footerLink?: DashboardLinkData;
  hideCoverageLabel?: boolean;
  noPercentage?: boolean;
  noData?: boolean;
  descriptionMinHeight?: string | number;
  showCauseBox?: boolean;
};

const LeagueEntryItem = (data: any) => {
  return (
    <StyledLeagueEntry key={data.id}>
      <Box
        sx={{
          flex: 1,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: '0 0 auto',
            width: '12px',
            height: '12px',
            borderRadius: 10,
            marginRight: '3px',
            background: data.colour ?? getColorForId(data.id ?? -1),
          }}
        />
        <Gap size={6} />
        <StyledHeader>{truncateString(data.name, 25)}</StyledHeader>
      </Box>
      {data.showCauseBox && (
        <>
          <StyledHeader className="dataValue">{data.causeBoxes}</StyledHeader>
        </>
      )}
      {data.noPercentage ? (
        <StyledHeader className="dataValue percentage">
          {formatNumberToDecimals(data.percentage, 1)}
        </StyledHeader>
      ) : (
        <StyledHeader className="dataValue percentage">
          {formatNumberToDecimals(data.percentage, 1)}%
        </StyledHeader>
      )}
    </StyledLeagueEntry>
  );
};

export function DashboardLeagueTable({
  title,
  description,
  content,
  tableTitle,
  afterTitle,
  entries,
  emptyMessage,
  footerLink,
  hideCoverageLabel,
  noPercentage,
  descriptionMinHeight,
  noData = false,
  showCauseBox = true,
}: StyledLeagueTableProps) {
  const total = entries?.length ?? 0;

  const nonNegativeIdRows = entries?.filter(({ id }) => id >= 0) ?? [];
  const negativeIdRows = entries?.filter(({ id }) => id < 0) ?? [];
  negativeIdRows.sort((a, b) => {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();

    if (aLower.endsWith('required')) {
      return -1;
    } else if (bLower.endsWith('required')) {
      return 1;
    }

    return 0;
  });
  return (
    <StyledStatBox>
      {total > 0 || noData ? (
        <>
          <Row>
            <StyledSubHeader variant="body1">{title}</StyledSubHeader>
            {afterTitle && (
              <>
                <Spacer />
                <Gap size={20} />
                {afterTitle}
              </>
            )}
          </Row>
          {description && (
            <StyledHeader
              sx={{ minHeight: descriptionMinHeight }}
              variant="body1"
            >
              {description}
            </StyledHeader>
          )}
          {content && (
            <StyledHeader variant="body1" className="content">
              {content}
            </StyledHeader>
          )}
          {!noData && (
            <>
              <Box className="coverage">
                <Row
                  sx={{
                    paddingLeft: '17px',
                    paddingRight: '11px',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Gap size={15} />
                    <Typography variant="caption">{tableTitle}</Typography>
                  </Box>
                  {showCauseBox && (
                    <>
                      <Typography
                        sx={{ width: '75px' }}
                        variant="caption"
                        className="causes_header"
                      >
                        Cause Boxes
                      </Typography>
                    </>
                  )}
                  {!hideCoverageLabel && (
                    <Typography
                      sx={{
                        width: '75px',
                        textAlign: 'right',
                      }}
                      variant="caption"
                      className="causes_header"
                    >
                      Coverage
                    </Typography>
                  )}
                </Row>
                {nonNegativeIdRows.slice(0, 5).map((data) => (
                  <LeagueEntryItem
                    {...data}
                    noPercentage={noPercentage}
                    showCauseBox={showCauseBox}
                  />
                ))}
                {negativeIdRows.length > 0 && (
                  <>
                    <Gap size={15} />
                    <Spacer />
                  </>
                )}
                {negativeIdRows.map((data) => (
                  <LeagueEntryItem
                    {...data}
                    noPercentage={noPercentage}
                    showCauseBox={showCauseBox}
                  />
                ))}
              </Box>
              {!!footerLink && (
                <>
                  <Gap size={12} />
                  <Spacer />
                  <Row>
                    <Spacer />
                    <Link
                      href={footerLink.href ?? '#'}
                      className="link"
                      onClick={(e) => {
                        if (footerLink?.onClick != null) {
                          e.preventDefault();
                          footerLink.onClick();
                        }
                      }}
                    >
                      {footerLink.text}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {afterTitle ? (
            <Row>
              <StyledHeader variant="body1">{title}</StyledHeader>
              <Spacer />
              <Gap size={20} />
              {afterTitle}
            </Row>
          ) : null}
          <NoReportInfo message={emptyMessage} />
        </>
      )}
    </StyledStatBox>
  );
}

const StyledSplitCoverageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '> .MuiBox-root.solution': {
    flexDirection: 'column',
    borderRadius: 5,
    flexGrow: 1,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '7px 13px 5px 13px',
    '> .MuiBox-root': {
      gap: 8,
    },
    '> p': {
      textTransform: 'uppercase',
    },
  },
});

type StyledSplitCoverage = {
  title: string;
  subtitle: string;
  emptyMessage: string;
  topEntries: RadialOverviewStat[];
  topTitle: string;
  topSubtitle: string;
  bottomEntries: RadialOverviewStat[];
  bottomTitle: string;
  bottomSubtitle: string;
};

export function DashboardSplitCoverage({
  title,
  subtitle,
  topEntries,
  topTitle,
  topSubtitle,
  bottomEntries,
  bottomTitle,
  bottomSubtitle,
  emptyMessage,
}: StyledSplitCoverage) {
  const statsToGradiant = (stats: RadialOverviewStat[]) => {
    const totals = stats.reduce((acc, { percentage }) => acc + percentage!, 0);
    const percentages = stats.map(({ percentage }) => percentage! / totals);

    // Initialise with fallback colour
    let gradientString = 'linear-gradient(90deg';

    let previousStopPoint = 0;

    stats.forEach(({ id }, i) => {
      const percentage = percentages[i] * 100.0;
      const startPoint = previousStopPoint;
      const stopPoint = startPoint + percentage;
      previousStopPoint = stopPoint;

      const color = getColorBrandId(id ?? -1);

      gradientString += `, ${color} ${startPoint}% ${stopPoint}%`;
    });

    return (gradientString += ')');
  };

  return (
    <StyledStatBox>
      {topEntries.length + bottomEntries.length > 0 ? (
        <>
          <StyledHeader variant="body1">{title}</StyledHeader>
          <StyledSubHeader variant="body1">{subtitle}</StyledSubHeader>
          {topEntries.length > 0 && (
            <>
              <Box className="title">
                <StyledHeader>{topTitle}</StyledHeader>
                <StyledSubHeader>{topSubtitle}</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: statsToGradiant(topEntries),
                }}
              />
              <StyledSplitCoverageBox>
                {topEntries.map((data) => (
                  <Box className="solution" key={data.id}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorBrandId(data.id ?? -1),
                        }}
                      />
                      <StyledHeader>
                        {`${Math.round(data.percentage!)}%` || '0.00%'}
                      </StyledHeader>
                    </Box>
                    <StyledSubHeader>{data.name}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSplitCoverageBox>
              <hr />
            </>
          )}
          {bottomEntries.length > 0 && (
            <>
              <Box className="title">
                <StyledHeader>{bottomTitle}</StyledHeader>
                <StyledSubHeader>{bottomSubtitle}</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: statsToGradiant(bottomEntries),
                }}
              />
              <StyledSplitCoverageBox>
                {bottomEntries.map((data) => (
                  <Box className="solution" key={data.id}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorBrandId(data.id ?? -1),
                        }}
                      />
                      <StyledHeader>
                        {`${Math.round(data.percentage!)}%` || '0.00%'}
                      </StyledHeader>
                    </Box>
                    <StyledSubHeader>{data.name}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSplitCoverageBox>
            </>
          )}
        </>
      ) : (
        <NoReportInfo message={emptyMessage} />
      )}
    </StyledStatBox>
  );
}
