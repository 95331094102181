import { ReportViewProps } from '@pages/app/rca/tabs/reports/views/report-view-props';
import Column from '@components/layout-util-components/column';
import CoverageOverviewContainer from '@pages/app/rca/tabs/reports/components/coverage-overview-container';
import { styled, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectSelectedRankRow,
  shouldShrinkReportView,
} from '@store/reports/reports-selectors';
import CoverageLeagueTable from '@pages/app/rca/tabs/reports/components/coverage-league-table';
import { setSelectedRankRow } from '@store/reports/reports-slice';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled(Column)(() => ({
  padding: '0px 25px 25px 25px',
}));

export interface CoverageReportMetaData extends Record<string, any> {
  themeId?: number;
  chainItems: Record<number, string>;
  rank: number;
}

export interface CoverageReportTotals extends Record<string, any> {
  chainItemCount: number;
  coveragePercent: number;
  coverageShare: Array<number>;
}

export default function CoverageReportView({
  data,
  model,
  splitData,
}: ReportViewProps<CoverageReportMetaData, CoverageReportTotals>) {
  const dispatch = useAppDispatch();

  const isShrunk = useAppSelector(shouldShrinkReportView);
  const selectedRowId = useAppSelector(selectSelectedRankRow);

  const hasTotals = !!data.totals.chainItemCount;

  return (
    <Container>
      {hasTotals ? (
        <CoverageOverviewContainer
          model={model}
          splitData={splitData}
          causeBoxCount={data.totals.chainItemCount}
          totalCoverage={data.totals.coveragePercent}
          onSelectSegment={(rowId) => dispatch(setSelectedRankRow(rowId))}
        />
      ) : null}
      {!isShrunk && hasTotals ? (
        <>
          <Gap size={20} />
          <Typography variant="body1">
            {model.reportSubTypeName} coverage league table
          </Typography>
          <Gap size={15} />
        </>
      ) : (
        <Gap size={8} />
      )}

      <CoverageLeagueTable
        headers={data.columns}
        splitData={splitData}
        immutableData={data}
        onSelect={(rowId) => dispatch(setSelectedRankRow(rowId))}
        selectedRow={selectedRowId}
      />
    </Container>
  );
}
