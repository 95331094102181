export interface DateFormat {
  long24: string;
  long12: string;
  short24: string;
  short12: string;
  dateOnlyLong: string;
  dateOnlyShort: string;
  timeOnlyShort: string;
}

export const dateFormats: { [id: number]: DateFormat } = {
  1: {
    long24: 'HH:mm, DD MMM YYYY',
    long12: 'HH:mm, DD MMM YYYY',
    short24: 'DD/MM/YYYY HH:mm:ss',
    short12: 'DD/MM/YYYY HH:mm:ss',
    dateOnlyLong: 'DD MMM YYYY',
    dateOnlyShort: 'DD/MM/YYYY',
    timeOnlyShort: 'HH:mm',
  },
  2: {
    long24: 'HH:mm, MMM DD YYYY',
    long12: 'HH:mm, MMM DD YYYY',
    short24: 'MM/DD/YYYY HH:mm:ss',
    short12: 'MM/DD/YYYY HH:mm:ss',
    dateOnlyLong: 'MMM DD YYYY',
    dateOnlyShort: 'MM/DD/YYYY',
    timeOnlyShort: 'HH:mm',
  },
};
