import { ComponentType, useEffect, useMemo, useRef } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useUser from '@store/user/user-hook';
import { Permission } from '@api/types/permission';
import { useIsPath } from '@util/path-util';
import { isApp } from '@util/env';
import {
  HEADER_PAYLOAD_COOKIE,
  refreshUserState,
} from '@store/user/user-slice';
import { useAppDispatch } from '@store/store';
import { ErrorPage } from '@components/error-handling/error-route-catcher';
import { useGetCompaniesForCurrentUserQuery } from '@api/endpoints/company-user.api';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import config from 'src/config';

interface Args {
  mfaRequired?: boolean;
  permissions?: Array<Permission>;
}

export default function requireAuth<P>(
  Component: ComponentType<P>,
  { mfaRequired = true, permissions = [] }: Args = {}
) {
  return (props: P) => {
    const lastCheckedExpDate = useRef<Date>();
    const dispatch = useAppDispatch();
    const { pathname, search } = useLocation();

    const isMfaPath = useIsPath('/auth/mfa', { startsWith: true });
    const isLogoutPath = useIsPath('/auth/logout', { startsWith: true });
    const isSelectCompanyPath = useIsPath('/auth/select-company', {
      startsWith: true,
    });
    const isCreateCompanyPath = useIsPath('/auth/create-company', {
      startsWith: true,
    });

    const {
      isLoggedIn,
      userRequires2fa,
      hasPermission,
      isLoggedInAndFullyAuthenticated,
      hasSelectedCompany,
      logout,
    } = useUser();

    const { data: availableCompanies, isLoading: loadingUserCompanies } =
      useGetCompaniesForCurrentUserQuery(undefined, {
        skip: !isApp || !isLoggedIn,
      });

    const requiresSelectCompany =
      isApp &&
      !hasSelectedCompany &&
      availableCompanies != null &&
      availableCompanies.length > 0;

    const requiresCreateCompany =
      isApp && availableCompanies != null && availableCompanies.length === 0;

    const isLoading = loadingUserCompanies;

    useEffect(() => {
      // Monitor login cookie and call logout when it expires
      const interval = setInterval(() => {
        if (!isLoggedIn) {
          clearInterval(interval);
          return;
        }

        const cookie = document.cookie
          .split(';')
          .find((c) => c.trim().startsWith(HEADER_PAYLOAD_COOKIE));

        if (cookie == null) {
          logout();
        }
        // Decode jwt cookie
        const payload = JSON.parse(atob(cookie!.split('.')[1]));
        const expires = new Date(payload.exp * 1000);
        const now = new Date();
        if (now > expires || payload.iss !== config.apiUrl) {
          // delete cookie
          try {
            document.cookie = `${HEADER_PAYLOAD_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          } finally {
            logout();
          }
        } else {
          // check if expires is after lastCheckedExpDate
          const current = lastCheckedExpDate.current;
          const shouldRefresh = current == null || expires > current;
          if (shouldRefresh) {
            dispatch(refreshUserState());
            lastCheckedExpDate.current = expires;
          } else {
            console.log('skipping check because nothing has happened');
          }
        }
      }, 5000);

      return () => clearInterval(interval);
    }, [dispatch, isLoggedIn, logout]);

    const isPermissionGranted = useMemo(() => {
      if (!isLoggedIn) {
        return false;
      }

      if (permissions == null || permissions.length === 0) {
        return true;
      }

      for (const permission of permissions) {
        if (hasPermission(permission)) {
          return true;
        }
      }

      return false;
    }, [hasPermission, isLoggedIn]);

    if (isLogoutPath) {
      return <Component {...(props as any)} />;
    }

    if (!isLoggedIn) {
      return (
        <Navigate
          to={`/auth/login/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (isLoading) {
      return <FullScreenLoadingIndicator />;
    }

    if (isLoggedInAndFullyAuthenticated && !isPermissionGranted) {
      return (
        <ErrorPage goTo={'/'} message={'This page does not seem to exist.'} />
      );
    }

    if (requiresSelectCompany) {
      if (isSelectCompanyPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/select-company/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (requiresCreateCompany) {
      if (isCreateCompanyPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/create-company/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (mfaRequired && userRequires2fa) {
      if (isMfaPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/mfa/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    return <Component {...(props as any)} />;
  };
}
