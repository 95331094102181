import { api } from '@api/api';
import { RegisterUserRequest } from '@api/types/user/register-user.request';
import { makeError } from '@api/types/api-error';
import { ResendEmailConfirmationRequest } from '@api/types/user/resend-email-confirmation.request';
import { ResetPasswordRequest } from '@api/types/user/reset-password.request';
import { ConfirmUserEmailRequest } from '@api/types/user/confirm-user-email.request';
import { UpdatePasswordRequest } from '@api/types/user/update-password.request';
import { InvitedUserDetailsResponse } from '@api/types/user/invited-user-details.response';
import { InvitedUserDetailsRequest } from '@api/types/user/invited-user-details.request';
import { CompleteUserRequest } from '@api/types/user/complete-user.request';
import { CompleteUserSetPasswordRequest } from '@api/types/user/complete-user-set-password.request';
import { UserProfileResponse } from '@api/types/user/user-profile.response';
import { UpdateUserProfileRequest } from '@api/types/user/update-user-profile.request';
import { ConfirmationCodeRequest } from '@api/types/user/confirmation-code.request';
import { refreshUserState } from '@store/user/user-slice';
import { MfaSubmitCodeRequest } from '@api/types/auth/mfa-submit-code.request';
import { UpdatePreferencesRequest } from '@api/types/user/update-preferences.request';
import { setLocaleFromProfile } from '@store/locale/locale-slice';
import { resetFormatters } from '@i18n/formatters';
import { PaginatedResult } from '@api/types/paginated-result';
import { IKudos } from '@api/types/user/kudos';
import { ConfirmUserEmailTokenRequest } from '@api/types/user/confirm-user-email-token.request';
import { ConfirmUserEmailTokenResponse } from '@api/types/user/confirm-user-email-token.response';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getKudosHistory: build.query<PaginatedResult<IKudos>, void>({
      query: () => ({
        url: 'user/KudosHistory',
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    registerUser: build.mutation<void, RegisterUserRequest>({
      query: (body) => ({
        url: 'user/register',
        method: 'POST',
        body: {
          ...body,
          // TODO(Nick): Just for testing purposes, this wil be provided by query string
          signUpPackageId: body.signUpPackageId ?? 1,
        },
      }),
      transformErrorResponse: (error) => makeError<RegisterUserRequest>(error),
    }),
    resendEmailConfirmation: build.mutation<
      void,
      ResendEmailConfirmationRequest
    >({
      query: (body) => ({
        url: 'user/resendemailconfirmation',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<ResendEmailConfirmationRequest>(error),
    }),
    forgottenPassword: build.mutation<void, ResendEmailConfirmationRequest>({
      query: (body) => ({
        url: 'user/forgottenpassword',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<ResendEmailConfirmationRequest>(error, {
          emailConfirmed: 'emailAddress',
        }),
    }),
    confirmUserEmail: build.mutation<void, ConfirmUserEmailRequest>({
      query: (body) => ({
        url: 'user/confirmuseremail',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<ConfirmUserEmailRequest>(error),
    }),
    confirmUserEmailToken: build.query<
      ConfirmUserEmailTokenResponse,
      ConfirmUserEmailTokenRequest
    >({
      query: (params) => ({
        url: 'user/confirmuseremail/isValidToken',
        method: 'GET',
        params,
      }),
    }),
    resetPassword: build.mutation<void, ResetPasswordRequest>({
      query: (body) => ({
        url: 'user/resetpassword',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<ResetPasswordRequest>(error, {
          // Map this to the password field so the error message
          // appears to the user
          invalidToken: 'password',
        }),
    }),
    updatePassword: build.mutation<void, UpdatePasswordRequest>({
      query: (body) => ({
        url: 'user/updatepassword',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<UpdatePasswordRequest>(error, {
          passwordMismatch: 'existingPassword',
        }),
    }),
    getInvitedUserDetails: build.query<
      InvitedUserDetailsResponse,
      InvitedUserDetailsRequest
    >({
      query: (params) => ({
        url: 'user/invitedUserDetails',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    completeUser: build.mutation<void, CompleteUserRequest>({
      query: (body) => ({
        url: 'user/completeuser',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<CompleteUserRequest>(error),
    }),
    completeUserSetPassword: build.mutation<
      void,
      CompleteUserSetPasswordRequest
    >({
      query: (body) => ({
        url: 'user/completeuser/setpassword',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<CompleteUserSetPasswordRequest>(error),
    }),
    getUserProfile: build.query<UserProfileResponse, void>({
      query: () => ({
        url: 'user/profile',
      }),
      providesTags: [{ id: 'self', type: 'User' }],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      async onCacheEntryAdded(_, { cacheDataLoaded, dispatch }) {
        const result = await cacheDataLoaded;
        const { data: profile } = result;
        dispatch(setLocaleFromProfile(profile));
        resetFormatters();
      },
    }),
    updateUserProfile: build.mutation<void, UpdateUserProfileRequest>({
      query: (body) => ({
        url: 'user/profile',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<UpdateUserProfileRequest>(error),
      invalidatesTags: [{ id: 'self', type: 'User' }],
    }),
    updateUserPreferences: build.mutation<void, UpdatePreferencesRequest>({
      query: (body) => ({
        url: 'user/preferences',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdatePreferencesRequest>(error),
      invalidatesTags: [{ id: 'self', type: 'User' }],
    }),
    requestConfirmationCode: build.query<void, ConfirmationCodeRequest>({
      query: (body) => ({
        url: 'user/profile/requestConfirmationCode',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformErrorResponse: (error) =>
        makeError<ConfirmationCodeRequest>(error),
    }),
    turnOn2FA: build.mutation<void, MfaSubmitCodeRequest>({
      query: (body) => ({
        url: 'user/turnOn2FA',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ id: 'self', type: 'User' }],
      transformErrorResponse: (error) => makeError<MfaSubmitCodeRequest>(error),
    }),
    turnOff2FA: build.mutation<void, void>({
      query: () => ({
        url: 'user/turnOff2FA',
        method: 'POST',
      }),
      invalidatesTags: [{ id: 'self', type: 'User' }],
      transformErrorResponse: (error) => makeError<never>(error),
    }),
  }),
});

export const {
  useGetKudosHistoryQuery,
  useRegisterUserMutation,
  useResendEmailConfirmationMutation,
  useForgottenPasswordMutation,
  useResetPasswordMutation,
  useConfirmUserEmailMutation,
  useUpdatePasswordMutation,
  useGetInvitedUserDetailsQuery,
  useCompleteUserMutation,
  useCompleteUserSetPasswordMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLazyRequestConfirmationCodeQuery,
  useTurnOn2FAMutation,
  useTurnOff2FAMutation,
  useUpdateUserPreferencesMutation,
  useConfirmUserEmailTokenQuery,
} = userApi;
