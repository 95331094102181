import { useTheme } from '@mui/material';
import OutcomeColoredBadge from '@components/outcome-report/components/outcome-colored-badge';
import { getStrengthColor } from '@util/colour-identifiers';

interface Props {
  strength: string;
}

export default function EvidenceStrengthBadge({ strength }: Props) {
  const { palette } = useTheme();

  const severityBgColor =
    {
      '1': '#FF3D3D26',
      '2': '#FF9B5126',
      '3': '#FF6C0026',
      '4': '#3CA68026',
      '5': '#178F6426',
    }[strength] || '#00223A26';

  const severityColor =
    {
      '1': getStrengthColor(1),
      '2': getStrengthColor(2),
      '3': getStrengthColor(3),
      '4': getStrengthColor(4),
      '5': getStrengthColor(5),
    }[strength] || '#00223A';

  const indicatorColor =
    {
      '1': getStrengthColor(1),
      '2': getStrengthColor(2),
      '3': getStrengthColor(3),
      '4': getStrengthColor(4),
      '5': getStrengthColor(5),
    }[strength] || '#00223A';

  const borderColor =
    {
      '1': getStrengthColor(1),
      '2': getStrengthColor(2),
      '3': getStrengthColor(3),
      '4': getStrengthColor(4),
      '5': getStrengthColor(5),
    }[strength] || '#00223A';

  return (
    <OutcomeColoredBadge
      bgColor={severityBgColor}
      textColor={severityColor}
      indicatorColor={indicatorColor}
      borderColor={borderColor}
      label={`${strength}/5`}
    />
  );
}
