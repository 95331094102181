import { styled } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

export const PanelEditorSubActionContainer = styled('div')<{ isOpen: boolean }>(
  ({ isOpen }) => ({
    position: 'absolute',
    left: -20,
    top: 0,
    width: 243,
    transform: 'translateX(-100%)',
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 8,
  })
);

const StyledSubAction = styled('button')(({ theme: { palette } }) => ({
  minHeight: 46,
  background: palette.common.white,
  cursor: 'pointer',
  color: palette.common.black,
  border: 'none',
  borderRadius: 8,
  boxShadow: '0px 0px 4px 0px #00000040',
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: 16,
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0px',
  textAlign: 'left',
  span: {
    flex: '1 1 auto',
  },
  svg: {
    stroke: palette.common.black,
    width: '16px',
    height: 'auto',
  },
}));

export default function PanelEditorSideSubAction({
  icon,
  label,
  onClick,
}: Props) {
  return (
    <StyledSubAction type="button" onClick={onClick}>
      {icon}
      <span>{label}</span>
    </StyledSubAction>
  );
}
