import { useMemo } from 'react';
import {
  makeSelectChainItemHealthScore,
  selectChainId,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

export default function useSelectChainItemHealthScore(chainItemId?: number) {
  const chainId = useAppSelector(selectChainId);
  const selectHealthScore = useMemo(() => {
    if (chainId == null || chainItemId == null) {
      return (_) => undefined;
    }

    return makeSelectChainItemHealthScore(chainId, chainItemId);
  }, [chainId, chainItemId]);

  return useAppSelector(selectHealthScore);
}
