import { api } from '@api/api';
import { Option } from '@api/types/option';

const countryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDialCodes: build.query<Option[], boolean | undefined>({
      query: (isUserLocation) => ({
        url: 'country/dialcodes',
        method: 'GET',
        params: {
          isUserLocation,
        },
      }),
    }),
    getCountryNames: build.query<Option[], boolean | undefined>({
      query: (isUserLocation) => ({
        url: 'country/names',
        method: 'GET',
        params: {
          isUserLocation,
        },
      }),
    }),
  }),
});

export const { useGetDialCodesQuery, useGetCountryNamesQuery } = countryApi;
