import { api } from '@api/api';
import { Option } from '@api/types/option';

const businessAreaApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBusinessAreaOptions: build.query<Option[], void>({
      query: () => ({
        url: 'businessarea/options',
      }),
    }),
  }),
});

export const { useGetBusinessAreaOptionsQuery } = businessAreaApi;
