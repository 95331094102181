import useCompleteTask from '@hooks/tasks/use-complete-tasks';
import TaskForm from '@pages/app/tasks/forms/task-form';
import { useCallback } from 'react';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { TaskStatus } from '@api/types/task-detail/task-status';
import { useCasePermission } from '@hooks/case/use-case-permission';
import useUser from '@store/user/user-hook';

interface Opt {
  caseId?: number;
}

export default function usePersonalTaskOptions(
  taskId: number,
  taskStatus: TaskStatus,
  { caseId }: Opt = {}
) {
  const { showSingle, showPopup } = useUiPopup();
  const { openTask, completeTask, isBusy: isTogglingState } = useCompleteTask();
  const { canContribute } = useCasePermission();
  const { companyUserId } = useUser();

  const isComplete = taskStatus === TaskStatus.complete;
  const isOverdue = taskStatus === TaskStatus.overdue;

  const onEditTask = useCallback(() => {
    showSingle(() => showPopup(TaskForm, { taskId, caseId }));
  }, [caseId, showPopup, showSingle, taskId]);

  const onCompleteTask = () => completeTask(taskId, caseId!);
  const onOpenTask = () => openTask(taskId, caseId!);

  const options: Array<PopoverMenuAction> = [
    {
      label: 'Edit',
      onClick: onEditTask,
    },
  ];

  return {
    onEditTask,
    options,
    onCompleteTask: canContribute ? onCompleteTask : undefined,
    onOpenTask: canContribute ? onOpenTask : undefined,
    isComplete,
    isOverdue,
    isTogglingState,
    currentCompanyUserId: companyUserId,
  };
}
