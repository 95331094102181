import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

export default function useSolutionsListView({
  solutions,
}: SolutionsPanelState) {
  const currency = useAppSelector(selectCurrentRcaCurrency);

  return {
    solutions: solutions?.model ?? [],
    currency,
  };
}
