import { Skeleton } from '@mui/material';
import { PrimaryButton } from '@components/buttons';

interface Props {
  noGrow?: boolean;
}
export default function ButtonSkeleton({ noGrow }: Props) {
  return (
    <Skeleton variant="rectangular" width={noGrow ? 100 : '100%'}>
      <PrimaryButton onClick={() => {}}>LOADING</PrimaryButton>
    </Skeleton>
  );
}
