import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, Typography } from '@mui/material';
import SettingsOptionContainer from '@components/containers/settings-option-container';
import { useNavigate } from 'react-router-dom';
import { isApp } from '@util/env';

function ProfileSettingsIndexPage() {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" alignItems="stretch">
      <Typography variant="h2">Security</Typography>
      <SettingsOptionContainer
        title="Update your password"
        message="Update your password and enable multi-factor authentication"
        onClick={() => navigate('./password')}
      />
      {isApp && (
        <SettingsOptionContainer
          title="Multi-factor Authentication"
          message="Increase your platform security by enabling multi-factor authentication."
          onClick={() => navigate('./mfa')}
        />
      )}
      {/*<SettingsOptionContainer*/}
      {/*  title="Export Profile"*/}
      {/*  message="Export the data from your profile into a PDF file"*/}
      {/*/>*/}
    </Grid>
  );
}

export default requireAuth(ProfileSettingsIndexPage);
