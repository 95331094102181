import useField from '@hooks/use-field-hook';
import { lessThan, required } from '@util/validators';
import { useEffect, useMemo } from 'react';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import {
  useCreateCompanyGroupMutation,
  useGetCompanyGroupDetailQuery,
  useUpdateCompanyGroupMutation,
} from '@api/endpoints/company/company-company-group.api';
import { ApiError } from '@api/types/api-error';
import { CreateCompanyGroupRequest } from '@api/types/company-group/create-company-group.request';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useAppTr } from '@i18n/use-app-tr';
import { usePageAlertVariants } from '@components/alerts';

export default function useGroupForm(groupId?: number) {
  const { t } = useAppTr('groupForm');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { data: companyUserOptions, isLoading: loadingUsers } =
    useGetCompanyUsersOptionsQuery({});

  const { data: group, isLoading: loadingGroup } =
    useGetCompanyGroupDetailQuery(groupId ?? 0, { skip: !groupId });

  const [createGroup, { isLoading: submittingCreate }] =
    useCreateCompanyGroupMutation();
  const [updateGroup, { isLoading: submittingUpdate }] =
    useUpdateCompanyGroupMutation();

  const name = useField<string>([required(), lessThan(20)], group?.name);
  const description = useField<string>([lessThan(50)], group?.description);
  const admins = useField<Array<number>>(
    [required()],
    useMemo(() => {
      return (
        group?.groupMembers
          .filter((x) => x.isAdmin)
          .map((x) => x.companyUserId) ?? []
      );
    }, [group?.groupMembers])
  );
  const members = useField<Array<number>>(
    [],
    useMemo(() => {
      return (
        group?.groupMembers
          .filter((x) => !x.isAdmin)
          .map((x) => x.companyUserId) ?? []
      );
    }, [group?.groupMembers])
  );

  const originalAdmins = useMemo(() => {
    return group?.groupMembers?.filter((x) => x.isAdmin) ?? [];
  }, [group?.groupMembers]);

  const originalMembers = useMemo(() => {
    return group?.groupMembers?.filter((x) => !x.isAdmin) ?? [];
  }, [group?.groupMembers]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    name,
    description,
    admins,
    members,
  ]);

  const isSubmitting = submittingCreate || submittingUpdate;
  const isLoading = loadingUsers || loadingGroup;
  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  useEffect(() => {
    if (loadingUsers) return;

    if (companyUserOptions?.length === 1) {
      admins.set([companyUserOptions[0].id]);
    }
  }, [loadingUsers, admins, companyUserOptions]);

  const adminOptions = useMemo(() => {
    const filteredUserOptions = companyUserOptions ?? [];
    for (const originalAdmin of originalAdmins) {
      if (
        filteredUserOptions.findIndex(
          (x) => x.id === originalAdmin.companyUserId
        ) === -1
      ) {
        filteredUserOptions.push({
          id: originalAdmin.companyUserId,
          label: originalAdmin.name,
        });
      }
    }
    return filteredUserOptions;
  }, [companyUserOptions, originalAdmins]);

  const memberOptions = useMemo(() => {
    const filteredUserOptions =
      companyUserOptions?.filter(
        (x) => !admins.value.find((y) => x.id === y)
      ) ?? [];
    for (const originalMember of originalMembers) {
      if (
        filteredUserOptions.findIndex(
          (x) => x.id === originalMember.companyUserId
        ) === -1
      ) {
        filteredUserOptions.push({
          id: originalMember.companyUserId,
          label: originalMember.name,
        });
      }
    }
    return filteredUserOptions;
  }, [admins.value, companyUserOptions, originalMembers]);

  const submitCreate = async (): Promise<CompanyGroupResource | null> => {
    if (!validateAll()) {
      return null;
    }

    return createGroup({
      name: name.value,
      description: description.value,
      groupAdminCompanyUserIds: admins.value,
      groupMemberCompanyUserIds: members.value,
    })
      .unwrap()
      .then((groupResource) => {
        showSuccessMessage(t('toasts.success.create'));
        return groupResource;
      })
      .catch(({ errors, message }: ApiError<CreateCompanyGroupRequest>) => {
        showErrorMessage(message);

        name.setError(errors?.name);
        description.setError(errors?.description);
        admins.setError(errors?.groupAdminCompanyUserIds);
        members.setError(errors?.groupMemberCompanyUserIds);

        return null;
      });
  };

  const submitUpdate = async (): Promise<CompanyGroupResource | number> => {
    if (!validateAll()) {
      return -1;
    }

    return updateGroup({
      companyGroupId: groupId!,
      name: name.value,
      description: description.value,
      groupAdminCompanyUserIds: admins.value,
      groupMemberCompanyUserIds: members.value,
    })
      .unwrap()
      .then((groupResource) => {
        showSuccessMessage(t('toasts.success.update'));
        return groupResource;
      })
      .catch(({ errors, message }: ApiError<CreateCompanyGroupRequest>) => {
        showErrorMessage(message);

        name.setError(errors?.name);
        description.setError(errors?.description);
        admins.setError(errors?.groupAdminCompanyUserIds);
        members.setError(errors?.groupMemberCompanyUserIds);

        return -1;
      });
  };

  const removeAdminsFromMembers = (newAdmins: Array<number>) => {
    members.set([...members.value.filter((x) => !newAdmins.includes(x))]);
  };

  return {
    originalGroupData: group,
    name,
    description,
    adminOptions,
    memberOptions,
    admins,
    members,
    originalAdmins,
    originalMembers,
    submit: groupId ? submitUpdate : submitCreate,
    isLoading,
    isSubmitting,
    canSubmit,
    removeAdminsFromMembers,
    companyUserOptions,
  };
}
