import { useGetCompaniesForCurrentUserQuery } from '@api/endpoints/company-user.api';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import useUser from '@store/user/user-hook';
import { useChangeCompanyMutation } from '@api/endpoints/auth.api';
import { isApiError } from '@api/types/api-error';
import { ChangeCompanyRequest } from '@api/types/auth/change-company.request';
import { usePageAlertVariants } from '@components/alerts';

export default function useSelectCompany() {
  const { showErrorMessage } = usePageAlertVariants();
  const { companyId } = useUser();
  const { data: companyOptions, isLoading: loadingCompanies } =
    useGetCompaniesForCurrentUserQuery();

  const [changeCompany, { isLoading: isSubmitting }] =
    useChangeCompanyMutation();

  const selectedCompany = useField<number>(
    [required()],
    companyId ?? companyOptions?.[0]?.companyId
  );

  const { isValid } = useFieldsWatcher([selectedCompany]);

  const isLoading = loadingCompanies;
  const canSubmit = isValid && !isLoading;

  const submit = async () => {
    try {
      await changeCompany({
        companyId: selectedCompany.value,
      });

      return true;
    } catch (e) {
      if (isApiError<ChangeCompanyRequest>(e)) {
        showErrorMessage(e.message);
        selectedCompany.setError(e.errors?.companyId);
      }
      return false;
    }
  };

  return {
    companyOptions,
    selectedCompany,
    isLoading,
    canSubmit,
    submit,
    isSubmitting,
  };
}
