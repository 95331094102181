import { Context, useContext } from 'react';

export default function useCreateContextHook<T>(
  context: Context<T | undefined>
): T {
  const contextValue = useContext(context);
  if (contextValue == null) {
    throw Error(
      'State should not be null, have you inserted a Provider higher up the tree?'
    );
  }

  return contextValue;
}
