import { AppDispatch, RootState } from '@store/store';
import systemTextApi from '@api/endpoints/system-text.api';
import { closeReport } from '@store/reports/reports-slice';
import { setLoading, setSystemText } from './system-text-slice';

export const loadSystemText =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const data = await dispatch(
        systemTextApi.endpoints.getSystemText.initiate(undefined, {
          forceRefetch: true,
        })
      ).unwrap();

      dispatch(setSystemText(data));
    } catch (e) {
      console.log(e);
      dispatch(closeReport());
    }
  };
