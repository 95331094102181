import { Option } from '@api/types/option';
import WCTGenericTypeaheadField, {
  WCTGenericTypeaheadFieldProps,
} from '@components/input/generic-typeahead-field';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  InputAdornment,
  ListItemText,
  MenuItem,
} from '@mui/material';

export function SkillsTypeaheadField(
  props: WCTGenericTypeaheadFieldProps<Option>
) {
  const { value } = props;
  return (
    <WCTGenericTypeaheadField
      {...props}
      forcePopupIcon={false}
      preventCloseOnSelect
      multiple
      inputProps={{
        disableUnderline: true,
        sx: {
          borderRadius: '4px',
          display: 'flex',
          gap: '7px',
          padding: '0px 12px 0px 8px',
          height: '40px',
          '& > input#skills': {
            padding: '0px',
          },
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              width: '24px',
              height: '18px',
              margin: 0,
              fontSize: '16px',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
      getOptionLabel={(option: Option) => option.label}
      isOptionEqualToValue={(option: Option, value: Option) =>
        option.id === value.id
      }
      renderOption={(props, option) => (
        <MenuItem {...props} key={`${option.id}`} value={option.id}>
          <Checkbox
            checked={
              Array.isArray(value) &&
              value.findIndex((x) => x.id === option.id) !== -1
            }
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      )}
    />
  );
}
