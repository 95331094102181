import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  styled,
  Typography,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { useState } from 'react';
import { getColorForId } from '@util/colour-identifiers';

const StyledAccordianSummaryContent = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
});

interface Props {
  faqs: IHelpCentreContent[];
  defaultId?: number;
}

export const FaqsList = ({ faqs, defaultId }: Props) => {
  const [expanded, setExpanded] = useState<number | false>(defaultId || false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (!faqs.length) {
    return null;
  }

  return (
    <Box>
      {faqs.map((faq) => (
        <Accordion
          key={`faq-${faq.helpCentreContentId}`}
          expanded={expanded === faq.helpCentreContentId}
          onChange={handleChange(faq.helpCentreContentId)}
          sx={{
            background: 'none',
            boxShadow: 'none',
            border: 'none',
            '&::before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              padding: '15px 25px',
              marginTop: '8px',
              borderRadius: '8px',
              background: '#fff',
              border: '2px solid #fff',
              flexDirection: 'row-reverse',
              '&[aria-expanded="true"]': {
                borderRadius: '8px 8px 0 0',
                padding: '15px 25px 5px',
                '&:hover': {
                  border: '2px solid #fff',
                },
              },
              '&:hover': {
                border: '2px solid #FF6C00',
              },
              svg: {
                height: '25px',
                path: {
                  fill: '#FF6C00',
                },
              },
            }}
          >
            <StyledAccordianSummaryContent>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{ ml: 2, fontSize: '16px', lineHeight: '25px' }}
                >
                  {faq.title}
                </Typography>
              </Box>
              <Box>
                {faq.categories.map((category) => (
                  <Chip
                    size="small"
                    key={`faq-category-${category.id}`}
                    label={category.label}
                    variant="outlined"
                    sx={{
                      ml: 2,
                      color: getColorForId(category.id),
                      borderColor: getColorForId(category.id),
                    }}
                  />
                ))}
              </Box>
            </StyledAccordianSummaryContent>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0px 25px 40px',
              borderRadius: '0 0 8px 8px',
              border: 'none',
              background: '#fff',
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: '#636D73',
                lineHeight: '22px',
              }}
            >
              {faq.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
