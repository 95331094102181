import { useGetCompanyReferenceDataModifiedTimesQuery } from '@api/endpoints/company/company.api';

export default function useCompanyRcaSettings() {
  const { data: modifiedTimes } =
    useGetCompanyReferenceDataModifiedTimesQuery();

  return {
    modifiedTimes,
  };
}
