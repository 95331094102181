import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';

export default function useGroups() {
  const canManageGroups = usePermission(Permission.manageCompanyGroups);

  return {
    canManageGroups,
  };
}
