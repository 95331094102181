import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { PrimaryButton } from '@components/buttons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { CaseImpactUtil } from '@util/case-impact-util';
import { OutcomeReportScaleOptionsLookup } from '@components/outcome-report/outcome-report-hook';
import { Gap } from '@components/layout-util-components/gap';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { ColorClass } from '../outcome-report-scaffold';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutcomeSeverityBadge from '../components/outcome-severity-badge';
import TrackedIndicator from '@components/outcome-report/components/tracked-indicator';
import ImpactCategoryBadge from '@components/outcome-report/components/impact-category-badge';
import ImpactValueBadge from '@components/outcome-report/components/impact-value-badge';
import getImpactValue = CaseImpactUtil.getImpactValue;

interface Props {
  impacts: Array<CaseImpactResource>;
  optionsLookup: OutcomeReportScaleOptionsLookup;
  className: ColorClass;
}

const StyledImpactsSection = styled('div')(({ theme: { palette } }) => ({
  '> .dark': {},
  '> .light': {
    '.impacts-grid .card-container .card': {
      background: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.25)',
    },
    '.impacts-grid .card-container.actual .card': {
      background: '#FFF9EF',
    },
    '.impacts-grid': {
      '> .title': {
        color: '#00223A',
      },
    },
  },
  '> .white': {
    '.impacts-grid .card-container .card': {
      background: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.25)',
    },
    '.impacts-grid .card-container.actual .card': {
      background: '#FFF9EF',
    },
    '.impacts-grid': {
      '> .title': {
        color: '#00223A',
      },
    },
  },
}));

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')({
  padding: '12px 75px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  '.section-title': {
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
  },

  '.impacts-grid': {
    '.card-container': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '24px',
      alignItems: 'stretch',
      '&.actual .card': {
        background: '#FFF9EF',
      },
    },
    '> .title': {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '21px',
      textAlign: 'left',
      color: 'rgba(255,255,255,1)',
      marginBottom: 20,
    },
  },
});

export default function ImpactsSection({
  impacts,
  optionsLookup,
  className,
}: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const actualImpacts = impacts.filter((impact) => impact.actualValue);
  const potentialImpacts = impacts.filter((impact) => !impact.actualValue);

  return (
    <StyledImpactsSection>
      <SectionScaffold id="impacts" className={className}>
        <Container>
          <Typography variant="h2" className="section-title">
            Impacts
          </Typography>
          {actualImpacts.length > 0 ? (
            <>
              <div className="impacts-grid">
                <div className="title">Actual</div>
                <div className="card-container actual">
                  {actualImpacts.map((impact) => {
                    const opt = optionsLookup[impact.caseImpactTypeMetricId];
                    if (!opt) {
                      return null;
                    }
                    return (
                      <ImpactCard
                        key={impact.caseImpactId}
                        category={impact.caseImpactTypeName}
                        tracked={impact.tracked}
                        name={impact.name}
                        description={impact.description}
                        type={impact.actualValue ? 'Actual' : 'Potential'}
                        severity={impact.caseImpactSeverityName}
                        value={getImpactValue(
                          impact.impactValue,
                          opt.caseImpactTypeMetric,
                          opt.options,
                          currency
                        )?.toString()}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
          {potentialImpacts.length > 0 ? (
            <>
              <div className="impacts-grid">
                <div className="title">Potential</div>
                <div className="card-container">
                  {potentialImpacts.map((impact) => {
                    const opt = optionsLookup[impact.caseImpactTypeMetricId];
                    if (!opt) {
                      return null;
                    }
                    return (
                      <ImpactCard
                        key={impact.caseImpactId}
                        category={impact.caseImpactTypeName}
                        name={impact.name}
                        tracked={impact.tracked}
                        description={impact.description}
                        type={impact.actualValue ? 'Actual' : 'Potential'}
                        severity={impact.caseImpactSeverityName}
                        value={getImpactValue(
                          impact.impactValue,
                          opt.caseImpactTypeMetric,
                          opt.options
                        )?.toString()}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
          <Box alignSelf="center">
            <PrimaryButton
              onClick={() => navigate(`/rca/tabs/${caseId}/impacts`)}
            >
              View Impacts <FontAwesomeIcon icon={faArrowRight} />
            </PrimaryButton>
          </Box>
        </Container>
      </SectionScaffold>
    </StyledImpactsSection>
  );
}

interface ImpactCardProps {
  category: string;
  name: string;
  tracked: boolean;
  description?: string;
  type?: string;
  severity?: string;
  value?: string;
}

const ImpactCardContainer = styled('div')(({ theme: { palette } }) => ({
  background: palette.common.white,
  borderRadius: 8,
  padding: 25,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  '.impactTitle': {
    alignItems: 'center',
    label: {
      display: 'none',
    },
  },

  '.scoreContainer': {
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    '.MuiChip-root': {
      padding: '4px 5px',
      borderStyle: 'solid',
      height: 'auto',
      width: 'auto',
      '> span': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
      },
    },
  },
  '.impact-stat': {
    gap: 8,
    '> div': {
      gap: 8,
    },
    '.flat-badge': {
      padding: '4px 12px',
      height: 30,
      fontSize: '15px',
      lineHeight: '1.25',
    },
  },
}));

const ImpactCard = ({
  tracked,
  severity,
  name,
  description,
  value,
  type,
  category,
}: ImpactCardProps) => {
  return (
    <ImpactCardContainer className="card">
      <Row className="impactTitle">
        <ImpactCategoryBadge category={category} />
        <Spacer />
        <TrackedIndicator tracked={tracked} showLabel />
      </Row>
      {name && (
        <>
          <Gap size={12} />
          <div className="title">{name}</div>
        </>
      )}
      {description && (
        <>
          <Gap size={4} />
          <div className="subTitle">{description}</div>
        </>
      )}
      <Gap size={32} />
      <Row className="cardFooter">
        {severity && (
          <>
            <span className="scoreContainer">
              <span className="label severity">SEVERITY</span>
              <OutcomeSeverityBadge severity={severity} />
            </span>
            <Gap size={50} />
          </>
        )}
        {type && value != null ? (
          <ImpactValueBadge type={type} value={value} />
        ) : null}
      </Row>
    </ImpactCardContainer>
  );
};
