import Column, { ColumnForm } from '@components/layout-util-components/column';
import { Chip, styled } from '@mui/material';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import WCTTextField from '@components/input/text-field';
import useOverviewPanel from '@pages/app/rca/tabs/components/editor-panels/overview-panel-hook';
import WCTSelectField from '@components/input/select-field';
import { InputSkeleton } from '@components/skeletons';
import { Divider } from '@components/layout-util-components/divider';
import BinarySelectField from '@components/input/binary-select-field';
import WarningAlert from '@components/alerts/warning-alert';
import ActionsRow from '@components/layout-util-components/actions-row';
import { PrimaryButton } from '@components/buttons';
import { useAppDispatch } from '@store/store';
import {
  NodePanelEditorTab,
  selectNodePanelEditorForCreateTab,
  selectNodePanelEditorTab,
} from '@store/rca-editor/rca-editor-slice';
import useDirtyGuard from '@hooks/use-dirty-guard';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faComment,
  faFile,
  faListCheck,
} from '@fortawesome/pro-light-svg-icons';
import { faBullseyePointer } from '@fortawesome/pro-regular-svg-icons';
import { RCA_BOX_TEXT_MAX_LENGTH } from '@pages/app/rca/tabs/components/node-types/rca-default-node';
import MultiSelectTreeView from '@components/input/multi-select-tree-field';
import WCTNestedSelectField from '@components/input/nested-select-field';

const Container = styled(Column)(({ theme: { palette } }) => ({
  '.quick-actions-title': {
    fontFamily: 'Usual',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'left',
    color: '#00000080',
  },

  '.quick-action': {
    display: 'flex',
    fontFamily: 'Usual',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '16px 16px',
    fontSize: '14px',
    borderRadius: 8,
    background: '#ECEDF0',
    border: '2px solid #ECEDF0',
    marginTop: 8,
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      border: `2px solid ${palette.blue.dark}`,
      cursor: 'pointer',
    },
    svg: {
      color: palette.common.black,
      stroke: palette.common.black,
      width: 20,
      maxWidth: '100%',
      maxHeight: '100%',
      fill: 'inherit',
    },

    span: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#383838',
    },
  },
}));

export default function OverviewPanel() {
  const { confirmDirty } = useDirtyGuard();
  const dispatch = useAppDispatch();
  const {
    description,
    transitory,
    nodeTypeOptions,
    theme,
    themeOptions,
    isLoading,
    canEdit,
    hasChainItemId,
    canSubmit,
    onSubmit,
    disproved,
    removeDisproved,
    canContribute,
    isSubmitting,
    isFetching,
    canRemoveDisproval,
  } = useOverviewPanel();

  return (
    <Container>
      <ColumnForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <EditPanelHeader
          title="Cause Overview"
          end={
            <Row>
              <FetchingIndicator show={isFetching} />
              <Gap size={12} />
              {disproved ? (
                <Chip
                  size="small"
                  label="Marked as Disproved"
                  color="error"
                  onDelete={canRemoveDisproval ? removeDisproved : undefined}
                />
              ) : null}
            </Row>
          }
        />
        <Gap size={20} />
        {!isLoading && !hasChainItemId ? (
          <>
            <WarningAlert message="Wait for graph to save before editing this cause box" />
            <Gap size={10} />
          </>
        ) : null}
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="description"
            label="Cause Description"
            value={description.value}
            error={description.error}
            onChange={description.set}
            onBlur={description.validate}
            multiline
            readonly={!canEdit}
            required
            maxLength={RCA_BOX_TEXT_MAX_LENGTH}
          />
        )}
        <Gap size={24} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTNestedSelectField
            id="theme"
            name="theme"
            label="Theme"
            options={themeOptions!}
            value={theme.value}
            error={theme.error}
            onChange={theme.set}
            onBlur={theme.validate}
            multiple
            readonly={!canEdit}
          />
        )}
        <Gap size={24} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <BinarySelectField
            name="type"
            label="Cause Type"
            options={nodeTypeOptions!}
            value={transitory.value}
            error={transitory.error}
            onChange={transitory.set}
            disabled={!canEdit}
            compact
          />
        )}
        <Gap size={20} />
        {canEdit ? (
          <ActionsRow>
            <PrimaryButton
              type="submit"
              isBusy={isSubmitting}
              disabled={!canSubmit}
            >
              Save
            </PrimaryButton>
          </ActionsRow>
        ) : null}
      </ColumnForm>
      {canContribute && (
        <>
          <Divider addMargin compact negativeMargin={-24} />
          <div className="quick-actions-title">QUICK ACTIONS</div>
          <button
            type="button"
            className="quick-action"
            onClick={confirmDirty(() =>
              dispatch(
                selectNodePanelEditorForCreateTab(NodePanelEditorTab.evidence)
              )
            )}
          >
            <FontAwesomeIcon icon={faFile} size="1x" />
            <Gap size={12} />
            <span>Add Evidence</span>
          </button>
          <button
            type="button"
            className="quick-action"
            onClick={confirmDirty(() =>
              dispatch(
                selectNodePanelEditorForCreateTab(NodePanelEditorTab.tasks)
              )
            )}
          >
            <FontAwesomeIcon icon={faListCheck} size="1x" />
            <Gap size={12} />
            <span>Add Task</span>
          </button>
          <button
            type="button"
            className="quick-action"
            onClick={confirmDirty(() =>
              dispatch(selectNodePanelEditorTab(NodePanelEditorTab.impacts))
            )}
          >
            <FontAwesomeIcon icon={faBullseyePointer} size="1x" />
            <Gap size={12} />
            <span>Add Impact</span>
          </button>
          <button
            type="button"
            className="quick-action"
            onClick={confirmDirty(() =>
              dispatch(
                selectNodePanelEditorForCreateTab(NodePanelEditorTab.notes)
              )
            )}
          >
            <FontAwesomeIcon icon={faComment} size="1x" />
            <Gap size={12} />
            <span>Add Note</span>
          </button>
          <button
            type="button"
            className="quick-action"
            onClick={confirmDirty(() =>
              dispatch(
                selectNodePanelEditorForCreateTab(NodePanelEditorTab.solutions)
              )
            )}
          >
            <FontAwesomeIcon icon={faCircleCheck} size="1x" />
            <Gap size={12} />
            <span>Add Solution</span>
          </button>
        </>
      )}
    </Container>
  );
}
