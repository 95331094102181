import { useAppSelector } from '@store/store';
import { selectIsCurrentFormDirty } from '@store/ui/selectors';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useCallback, useMemo } from 'react';

export default function useDirtyGuard() {
  const { showConfirmationModal } = useUiPopup();
  const isDirty = useAppSelector(selectIsCurrentFormDirty);

  const confirmDirty = useCallback(
    (confirm: () => void) => {
      return async () => {
        if (!isDirty) {
          confirm();
          return;
        }

        const shouldConfirm = await showConfirmationModal({
          title: 'There are unsaved changes',
          message: 'Are you sure you want to continue without saving changes?',
          noButtonLabel: 'No',
          yesButtonLabel: 'Yes',
        });

        if (shouldConfirm != null && shouldConfirm) {
          confirm();
        }
      };
    },
    [isDirty, showConfirmationModal]
  );

  return useMemo(
    () => ({
      confirmDirty,
    }),
    [confirmDirty]
  );
}
