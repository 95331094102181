import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import { useGetTasksForChainItemQuery } from '@api/endpoints/chain/chain.api';
import { useState } from 'react';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import { TaskPriority } from '@api/types/task-detail/task-priority';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { ApiError } from '@api/types/api-error';
import { CreateTaskDetailRequest } from '@api/types/task-detail/create-task-detail.request';
import { usePageAlertVariants } from '@components/alerts';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { UpdateTaskDetailRequest } from '@api/types/task-detail/update-task-detail.request';
import { useFilter, useSortableHeader } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import {
  allTaskStatus,
  nonCompleteTaskStatus,
} from '@api/types/task-detail/task-status';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import {
  useCreateTaskForUserMutation,
  useUpdateTaskForUserMutation,
} from '@api/endpoints/task-detail.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { useGetCompanyUserOptionsQuery } from '@api/endpoints/case.api';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { useGetChainItemQuery } from '@api/endpoints/chain/chain-item.api';

export default function useTasksPanel() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const [showForm, setShowForm] = useState(defaultToCreate);
  const chainId = useAppSelector(selectChainId)!;
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;
  const { canContribute } = useCasePermission();

  const { data: chainItemDetail, isLoading: loadingChainItemDetail } =
    useGetChainItemQuery(
      { chainId, chainItemId: chainItemId ?? -1 },
      { skip: chainItemId == null }
    );

  const [createTask] = useCreateTaskForUserMutation();
  const [updateTask] = useUpdateTaskForUserMutation();

  const { data: userOptions, isLoading: loadingUsers } =
    useGetCompanyUserOptionsQuery({
      caseId,
      excludeCaseRoles: ['ReadOnly'],
    });

  const [edittingId, setEdittingId] = useState<number>();
  const name = useField<string>([required()]);
  const description = useField<string>([required()]);
  const assignedUser = useField<number>([required()]);
  const dueDate = useField<string | undefined>([required()]);
  const priority = useField<TaskPriority>([required()], TaskPriority.low);

  const showCompleted = useFilter<boolean>(false);
  const sort = useSortableHeader({
    initialProperty: 'dueDate',
    initialDirection: SortDirection.desc,
  });

  const sortOptions: Array<SortOption> = [
    {
      id: 'dueDate',
      label: 'Due Date (latest first)',
      direction: SortDirection.desc,
    },
    {
      id: 'dueDate',
      label: 'Due Date (earliest first)',
      direction: SortDirection.asc,
    },
  ];

  const {
    data: tasks,
    isLoading: loadingTasks,
    isUninitialized,
    isFetching,
  } = useGetTasksForChainItemQuery(
    {
      chainId,
      chainItemId: chainItemId ?? -1,
      sortBy: sort.property,
      sortDirection: sort.direction,
      status: allTaskStatus,
    },
    { skip: chainItemId == null }
  );

  const { isValid, isDirty, resetAll, validateAll } = useFieldsWatcher([
    name,
    description,
    assignedUser,
    dueDate,
    priority,
  ]);

  const beginAddingTask = () => {
    setEdittingId(undefined);
    resetAll();
    setShowForm(true);
  };

  const beginEditting = (task: TaskDetailListItemResponse) => {
    setEdittingId(task.taskDetailId);
    name.reset(task.title);
    description.reset(task.description);
    assignedUser.reset(task.assignedToCompanyUserId);
    dueDate.reset(task.dueDate);
    priority.reset(task.priority);
    setShowForm(true);
  };

  const [submit, isBusy] = useBusyAction(async () => {
    if (!validateAll()) {
      return false;
    }

    if (edittingId == null) {
      return createTask({
        chainItemId: chainItemId,
        title: name.value,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
        caseId: caseId!,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail');
          showSuccessMessage('You have successfully added a task');
          setShowForm(false);
          resetAll();
          return true;
        })
        .catch(({ message, errors }: ApiError<CreateTaskDetailRequest>) => {
          showErrorMessage(errors?.chainItemId || message);

          name.setError(errors?.title);
          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    } else {
      return updateTask({
        taskDetailId: edittingId!,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
        caseId: caseId!,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail', edittingId!);
          showSuccessMessage('You have successfully updated the task');
          setShowForm(false);
          setEdittingId(undefined);
          resetAll();
          return true;
        })
        .catch(({ message, errors }: ApiError<UpdateTaskDetailRequest>) => {
          showErrorMessage(errors?.taskDetailId || message);

          // name.setError(errors?.title);
          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    }
  });

  const isLoading =
    chainItemDetail == null ||
    loadingTasks ||
    isUninitialized ||
    loadingUsers ||
    loadingChainItemDetail;
  const canSubmit = isDirty && isValid && !isLoading && !isBusy;

  return {
    tasks,
    isLoading,
    canSubmit,
    isBusy,
    isEditting: edittingId != null,
    showForm,
    beginAddingTask,
    name,
    description,
    assignedUser,
    dueDate,
    userOptions,
    priority,
    submit,
    beginEditting,
    showCompleted,
    sort,
    setShowForm,
    sortOptions,
    canContribute,
    tasksNA: chainItemDetail?.tasksNA,
    isFetching,
  };
}
