import { Option } from '@api/types/option';
import WCTGenericTypeaheadField, {
  WCTGenericTypeaheadFieldProps,
} from './generic-typeahead-field';

export default function WCTTypeaheadField<T>(
  props: WCTGenericTypeaheadFieldProps<Option<T>>
) {
  return (
    <WCTGenericTypeaheadField
      {...props}
      getOptionLabel={(option: Option<T>) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
