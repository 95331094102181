import { ApiError } from '@api/types/api-error';
import { CompleteTaskRequest } from '@api/types/task-detail/complete-task.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';
import {
  useCompleteTaskForUserMutation,
  useOpenTaskForUserMutation,
} from '@api/endpoints/task-detail.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export default function useCompleteTask() {
  const { t } = useAppTr('taskForm');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [submitCompleteTask] = useCompleteTaskForUserMutation();
  const [submitOpenTask] = useOpenTaskForUserMutation();

  const [completeTask, isCompletingTask] = useBusyAction(
    (taskId: number, caseId: number) =>
      submitCompleteTask({
        taskDetailId: taskId,
        caseId: caseId,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail', taskId);
          showSuccessMessage(t('toasts.success.complete'));
          return true;
        })
        .catch(({ message, errors }: ApiError<CompleteTaskRequest>) => {
          showErrorMessage(errors?.taskDetailId ?? message);
          return false;
        })
  );

  const [openTask, isOpeningTask] = useBusyAction(
    (taskId: number, caseId: number) =>
      submitOpenTask({
        taskDetailId: taskId,
        caseId: caseId,
      })
        .unwrap()
        .then(async () => {
          await invalidation('TaskDetail', taskId);
          showSuccessMessage(t('toasts.success.open'));
          return true;
        })
        .catch(({ message, errors }: ApiError<CompleteTaskRequest>) => {
          showErrorMessage(errors?.taskDetailId ?? message);
          return false;
        })
  );

  return {
    completeTask,
    openTask,
    isBusy: isOpeningTask || isCompletingTask,
  };
}
