import {
  selectCurrentRcaCaseDetail,
  selectCurrentUserCaseRole,
  selectIsUserChainEditor,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

export function useCasePermission() {
  const caseDetails = useAppSelector(selectCurrentRcaCaseDetail);
  const caseRole = useAppSelector(selectCurrentUserCaseRole);
  const isChainEditor = useAppSelector(selectIsUserChainEditor);

  const isOwner = caseRole === 'Owner';

  const isCaseComplete =
    caseDetails != null &&
    caseDetails.caseStatusName.toLowerCase().indexOf('complete') !== -1;

  // 'Owner' | 'Contributor' | 'ReadOnly' | 'ChainEditor'
  return {
    loading: caseRole === undefined,
    isOwner,
    isChainEditor,
    isCaseComplete,
    isContributor: caseRole === 'Contributor',
    isReadOnly: caseRole === 'ReadOnly' || isCaseComplete,
    canContribute: caseRole !== 'ReadOnly' && !isCaseComplete,
    canChangeCaseDetails: (isOwner || isChainEditor) && !isCaseComplete,
    canEditGraph: isChainEditor && !isCaseComplete,
    canChangeEditor: caseRole == null || isOwner || isChainEditor,
    canReopenCase: (isOwner || isChainEditor) && isCaseComplete,
  };
}
