import {
  selectDateOnlyFormatLong,
  selectDatetimeLongFormat,
  selectDatetimeShortFormat,
  selectLocale,
  selectTimeOnlyFormatShort,
} from '@store/locale/locale-selector';
import { store } from '@store/store';
import { getDate } from '@util/date-util';
import i18n from 'i18next';
import dayjs from 'dayjs';

export function formatDateCustom(value: any, format: string) {
  const date = getDate(value);
  if (date == null) return value;

  return date.format(format);
}

export function datetimeLongFormatter(value: any) {
  const format = selectDatetimeLongFormat(store.getState());

  return formatDateCustom(value, format);
}

export function datetimeShortFormatter(value: any) {
  const format = selectDatetimeShortFormat(store.getState());
  return formatDateCustom(value, format);
}

export function dateOnlyLongFormatter(value: any) {
  const format = selectDateOnlyFormatLong(store.getState());
  return formatDateCustom(value, format);
}

export function dateOnlyShortFormatter(value: any) {
  return dateOnlyLongFormatter(value);

  // There should now only be one date format as per client request.
  // leaving this code here just encase we need to revert back to two formats.

  // const format = selectDateOnlyFormatShort(store.getState());
  // return formatDateCustom(value, format);
}

export function timeOnlyShortFormatter(value: any) {
  const format = selectTimeOnlyFormatShort(store.getState());

  return formatDateCustom(value, format);
}

export function timespanFormatter(value: any) {
  const date = getDate(value);
  if (date == null) return value;

  const locale = selectLocale(store.getState());
  const dayJsDate = date.locale(locale);
  return dayJsDate.fromNow();
}

function getOrdinal(n: number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

export function todayYesterdayDateFormatter(value: any) {
  const date = getDate(value);
  if (date == null) return value;

  const locale = selectLocale(store.getState());
  const dayJsDate = date.locale(locale);
  const today = dayjs().locale(locale);
  const yesterday = today.subtract(1, 'day');

  if (dayJsDate.isSame(today, 'day')) {
    return 'Today';
  }

  if (dayJsDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  if (dayJsDate.isSame(today, 'year')) {
    const formattedDate = dayJsDate.format('ddd D MMM');
    const ordinalFormattedDate = formattedDate.replace(
      /\b(\d{1,2})\b/,
      (match, p1) => getOrdinal(parseInt(p1))
    );
    return ordinalFormattedDate;
  }

  const formattedYearDate = dayJsDate.format('ddd D MMM YYYY');
  const ordinalYearDate = formattedYearDate.replace(
    /\b(\d{1,2})\b/,
    (match, p1) => getOrdinal(parseInt(p1))
  );
  return ordinalYearDate;
}

export function getDurationString(date1Str: string, date2Str: string) {
  // Parse the date strings into Date objects
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);

  // Calculate the difference in milliseconds
  let diffMs = Math.abs(date2.getTime() - date1.getTime());

  // Define the duration in various units
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30.4375; // Average days in a month
  const msPerYear = msPerDay * 365.25; // Average days in a year

  // Calculate the difference in each unit
  const years = Math.floor(diffMs / msPerYear);
  diffMs -= years * msPerYear;

  const months = Math.floor(diffMs / msPerMonth);
  diffMs -= months * msPerMonth;

  const days = Math.floor(diffMs / msPerDay);
  diffMs -= days * msPerDay;

  const hours = Math.floor(diffMs / msPerHour);
  diffMs -= hours * msPerHour;

  const minutes = Math.floor(diffMs / msPerMinute);
  diffMs -= minutes * msPerMinute;

  const seconds = Math.floor(diffMs / msPerSecond);

  // Determine the largest unit to display
  if (years > 0) {
    return `${years} year${years !== 1 ? 's' : ''}`;
  } else if (months > 0) {
    return `${months} month${months !== 1 ? 's' : ''}`;
  } else if (days > 0) {
    return `${days} day${days !== 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  }
}

export function currencyFormatter(
  value: any,
  opt = { decimalPlaces: 0, currency: 'GBP' }
) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());

  let formatter: Intl.NumberFormat;
  try {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: opt.currency,
      maximumFractionDigits: opt.decimalPlaces,
    });
  } catch {
    formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: opt.decimalPlaces,
    });
  }

  return formatter.format(sanitisedValue);
}

export function numberFormatter(value: any, opt = { decimalPlaces: 0 }) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: opt.decimalPlaces,
  });
  return formatter.format(sanitisedValue);
}

export function resetFormatters() {
  const formatter = i18n.services.formatter!;
  formatter.add('datetime', datetimeLongFormatter);
  formatter.add('datetimeShort', datetimeShortFormatter);
  formatter.add('date', dateOnlyLongFormatter);
  formatter.add('dateShort', dateOnlyShortFormatter);
  formatter.add('timespan', timespanFormatter);
}
