import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import useKudosPage from './kudos-page-hook';
import { IKudos } from '@api/types/user/kudos';
import { todayYesterdayDateFormatter } from '@i18n/formatters';
import { isKudosItemSameDayAsNext } from './utils/is-kudos-item-same-day-as-next';
import { AvatarConnectingLine } from '../rca/tabs/components/avatar-connecting-line';
import { Divider } from '@components/layout-util-components/divider';
import useUser from '@store/user/user-hook';
import KudosIcon from '@assets/svgs/kudos-points.svg';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { Gap } from '@components/layout-util-components/gap';

export const KudosPage = () => {
  const userCompanyId = useUser()?.companyUserId;

  const state = useKudosPage(userCompanyId);
  const { isLoading, kudos, profile, isFetching } = state;

  return (
    <Box sx={{ alignItems: 'flex-start' }}>
      <Row>
        <Typography variant="h2">Kudos Points</Typography>
        <Gap size={8} />
        <FetchingIndicator show={isFetching} />
      </Row>
      <Box mt={3} mb={3} sx={{ display: 'flex' }}>
        {isLoading ? (
          <Skeleton height="100%" width="275" />
        ) : (
          <Box
            sx={{
              p: 3,
              mr: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#00223A',
              borderRadius: '8px',
              maxWidth: 275,
              flex: '0 0 275px',
              '@media (max-width:1024px)': {
                maxWidth: 200,
                flex: '0 0 200px',
              },
              '.kudos-container': {
                width: '120px',
                height: '120px',
                borderRadius: '70px',
                background: '#3CA680',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <>
              <div className="kudos-container">
                <img src={KudosIcon} alt="Kudos Points" />
              </div>
              <Typography
                variant="h2"
                sx={{ color: '#fff', pt: 3, pb: 3 }}
              >{`Level ${profile?.level ?? 0}`}</Typography>
              {profile?.kudosPoints && (
                <Box
                  sx={{
                    p: 1,
                    m: 1,
                    mb: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255, 0.07)',
                    borderRadius: '4px',
                    minWidth: '80%',
                  }}
                >
                  <Typography variant="h3" sx={{ color: '#fff' }}>
                    {profile.kudosPoints} pts
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 0,
                      color: 'rgba(255,255,255,0.8)',
                      fontWeight: '400',
                      marginTop: '5px',
                    }}
                  >
                    + {profile.kudosPointsToNextLevel} to Level{' '}
                    {(profile?.level ?? 0) + 1}
                  </Typography>
                </Box>
              )}
            </>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {isLoading
            ? [...new Array(6)].map((_, idx) => (
                <Skeleton
                  key={`item-loading-row-${idx}`}
                  height="4rem"
                  width="100%"
                />
              ))
            : kudos?.model.map((item: IKudos, idx: number) => {
                const isFirstItemInDay = !isKudosItemSameDayAsNext({
                  item,
                  kudos: kudos?.model,
                  idx,
                });

                const date = new Date(item.created);
                const timeOfEvent = todayYesterdayDateFormatter(date);

                return (
                  <Box
                    mt={isFirstItemInDay && idx !== 0 ? 3 : 0}
                    key={`item-row-${idx}`}
                  >
                    {isFirstItemInDay && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Chip
                          sx={{
                            mr: 1,
                            borderRadius: '4px',
                            background: '#D8DBE3',
                          }}
                          label={timeOfEvent}
                        />
                        <Box width={'100%'}>
                          <Divider sx={{ flexGrow: 1 }} />
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        '.kudos-container.sm': {
                          background: '#3CA680',
                          width: 36,
                          height: 36,
                          flexShrink: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '18px',
                          img: {
                            height: 24,
                          },
                        },
                      }}
                    >
                      {!isFirstItemInDay && <AvatarConnectingLine />}
                      <div className="kudos-container sm">
                        <img src={KudosIcon} alt="Kudos Points" />
                      </div>
                      <Box ml={2}>
                        <Box mb={1}>
                          <Typography variant="body1">
                            You have been assigned{' '}
                            <strong>{item.points} pts</strong>
                          </Typography>
                        </Box>
                        <Box mb={2}>
                          <Typography
                            sx={{ color: '#555555', fontSize: '14px' }}
                            variant="body2"
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
        </Box>
      </Box>
    </Box>
  );
};

export default requireAuth(KudosPage);
