import CircleContainer from '@components/containers/circle-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material';

const Container = styled('div')(({ theme: { palette } }) => ({
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translate(50%, -50%)',

  '.circle-container': {
    backgroundColor: palette.blue.dark,
    color: palette.common.white,
    svg: {
      height: 19,
    },
  },
}));

export default function NodeLinkIcon() {
  return (
    <Container>
      <CircleContainer size={42}>
        <FontAwesomeIcon icon={faLink} />
      </CircleContainer>
    </Container>
  );
}
