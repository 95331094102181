import { Typography, styled } from '@mui/material';

const StyledFormSubTitle = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '130%',
  color: palette.common.grey60,
}));

interface Props {
  text: string;
}

function FormSubtitle({ text }: Props) {
  return <StyledFormSubTitle variant="body2">{text}</StyledFormSubTitle>;
}

export default FormSubtitle;
