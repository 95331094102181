import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Permission } from '@api/types/permission';
import useManageUsers from '@pages/app/company-profile/manage-users-hook';
import CompanyUserForm, {
  UserFormProps,
} from '@pages/app/company-profile/forms/user-form';
import { WCTTable } from '@components/table';
import FilledBadge from '@components/badges/filled-badge';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import UserTableFilters from '@pages/app/company-profile/components/user-table-filters';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import { useRef, useState } from 'react';
import UserGroupsPopup from '@pages/app/company-profile/components/user-groups-popup';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { Gap } from '@components/layout-util-components/gap';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { truncateString } from '@util/string-util';
import UserStatus from '@components/user-components/app/user-status';
import Column from '@components/layout-util-components/column';
import PageCount from '@components/typography/page-count';

function ManageUsersPage() {
  const { t } = useAppTr('userManagement');
  const { showPopup } = useUiPopup();

  const userGroupRef = useRef<HTMLDivElement>();
  const [displayUserGroupsId, setDisplayUserGroupsId] = useState<number>();

  const {
    canCreateUser,
    isLoading,
    isFetching,
    users,
    userStatusOptions,
    statusFilter,
    sort,
    groupOptions,
    kudosFilter,
    groupsFilter,
    rolesFilter,
    searchFilter,
    roleOptions,
    kudosOptions,
    pagination,
    canDeleteUser,
    canViewUser,
    canEditUser,
    reinviteUser,
    onDeleteUser,
  } = useManageUsers();

  const showCreateUser = () => {
    showPopup(CompanyUserForm);
  };

  const viewUser = (userId: number) => {
    showPopup<UserFormProps, never>(CompanyUserForm, {
      userId,
      isView: true,
    });
  };

  const editUser = (userId: number) => {
    showPopup<UserFormProps, never>(CompanyUserForm, { userId });
  };

  const deleteUser = (userId: number) => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'User',
      onDelete: () => onDeleteUser(userId),
    });
  };

  const showGroups = (
    userId: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    userGroupRef.current = e.currentTarget;
    setDisplayUserGroupsId(userId);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Column>
          <PageCount
            title="User Management"
            isLoading={isLoading}
            count={users?.totalCount}
            variant="h2"
            sx={{ lineHeight: '40px' }}
          />
          <Typography>View, edit or delete system users</Typography>
        </Column>
        <Gap size={12} />
        {canCreateUser ? (
          <OutlinedButton disabled={isLoading} onClick={showCreateUser}>
            {t('createButtonLabel')}
          </OutlinedButton>
        ) : null}
      </Grid>
      <Gap size={20} />
      <Grid item container alignItems="stretch" direction="column">
        <WCTTable
          isLoading={isLoading}
          count={users?.totalCount ?? 0}
          pagination={pagination}
          emptyState={
            <OnboardingEntryContainer
              icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
              title="No Users to display"
              message="Adjust the filters to view users."
            />
          }
          filters={
            <UserTableFilters
              isLoading={isLoading}
              isFetching={isFetching}
              search={searchFilter}
              role={rolesFilter}
              group={groupsFilter}
              kudos={kudosFilter}
              status={statusFilter}
              roleOptions={roleOptions}
              statusOptions={userStatusOptions}
              groupOptions={groupOptions}
              kudosOptions={kudosOptions}
            />
          }
          headers={[
            { title: t('table.headers.name'), sortProperty: 'name', ...sort },
            {
              title: t('table.headers.role'),
              sortProperty: 'companyRoleName',
              ...sort,
            },
            {
              title: t('table.headers.groups'),
            },
            { title: t('table.headers.rca') },
            {
              title: t('table.headers.kudos'),
            },
            {
              title: t('table.headers.status'),
            },
            { title: <></> },
          ]}
        >
          {users?.model.map(
            ({
              companyUserId,
              name,
              jobTitle,
              companyRoleName,
              firstCompanyGroup,
              otherGroupCount,
              rcaCount,
              kudosLevel,
              kudosPoints,
              status,
            }) => (
              <TableRow key={companyUserId}>
                <TableCell
                  className="initials"
                  sx={{
                    '.circle-container': {
                      width: 32,
                      height: 32,
                      background: '#00223A',
                      color: '#fff',
                      fontSize: 10,
                      letterSpacing: '-.5px',
                      opacity: 1,
                    },
                    '.title': {
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#00223A',
                      marginBottom: '3px',
                    },
                    '.subtitle': {
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#636D73',
                    },
                  }}
                >
                  <InitialsContainerWithData
                    label={name}
                    fullName={name}
                    subLabel={jobTitle}
                  />
                </TableCell>
                <TableCell>{companyRoleName}</TableCell>
                <TableCell>
                  <Box gap={1}>
                    {firstCompanyGroup ? (
                      <FilledBadge>
                        {truncateString(firstCompanyGroup.label, 20)}
                      </FilledBadge>
                    ) : null}
                    {otherGroupCount ? (
                      <FilledBadge
                        onMouseEnter={(e) => showGroups(companyUserId, e)}
                      >
                        +{otherGroupCount}
                      </FilledBadge>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell>{rcaCount} </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      borderRadius: '4px',
                      padding: '4px',
                      background: '#DBE5EC',
                      fontSize: 13,
                      color: '#00223A',
                      width: '50px',
                      textAlign: 'center',
                    }}
                  >
                    Lvl {kudosLevel ?? 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <UserStatus status={status} />
                </TableCell>
                <TableCell className="action_btn">
                  <ElipsisButton
                    actions={[
                      {
                        label: 'Resend Invite',
                        onClick: () => reinviteUser(companyUserId),
                        hasPermission:
                          canEditUser &&
                          (status === CompanyUserStatus.inviteExpired ||
                            status === CompanyUserStatus.invited),
                      },
                      {
                        label: 'View',
                        hasPermission: canViewUser,
                        onClick: () => viewUser(companyUserId),
                      },
                      {
                        label: 'Edit',
                        hasPermission: canEditUser,
                        onClick: () => editUser(companyUserId),
                      },
                      {
                        label: 'Delete',
                        hasPermission: canDeleteUser(companyUserId),
                        onClick: () => deleteUser(companyUserId),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </WCTTable>
      </Grid>
      {displayUserGroupsId ? (
        <UserGroupsPopup
          target={userGroupRef.current!}
          companyUserId={displayUserGroupsId!}
          onClose={() => setDisplayUserGroupsId(undefined)}
        />
      ) : null}
    </>
  );
}

export default requireAuth(ManageUsersPage, {
  permissions: [Permission.manageCompanyUsers],
});
