import { TaskPriority } from '@api/types/task-detail/task-priority';
import { FormGroup, FormHelperText, styled } from '@mui/material';
import { ReactComponent as HighPriorityIcon } from '@assets/svgs/flag-high.svg';
import { ReactComponent as MediumPriorityIcon } from '@assets/svgs/flag-medium.svg';
import { ReactComponent as LowPriorityIcon } from '@assets/svgs/flag-low.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarExclamation } from '@fortawesome/pro-light-svg-icons';

interface Props {
  name: string;
  value?: TaskPriority;
  error?: string;
  onChange: (value: TaskPriority | null) => void;
  readonly?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'row',

  input: {
    WebkitAppearance: 'none',
    appearance: 'none',
    /* For iOS < 15 to remove gradient background */
    backgroundColor: palette.common.white,
    /* Not removed via appearance */
    margin: 0,
  },

  '& .priority-label': {
    borderRadius: '4px',
    width: '52px',
    height: '52px',
  },
}));

const Option = styled('label', {
  target: 'priority-label',
})<{
  isSelected: boolean;
  readOnly?: boolean;
}>(({ isSelected, readOnly }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  border: isSelected ? '1px solid rgba(255,61,61,1)' : '1px solid #687A85',
  background: isSelected ? 'rgba(255,61,61,0.1)' : 'white',
  color: isSelected ? 'rgba(255,61,61,1)' : '#687A85',
  cursor: isSelected || readOnly ? 'default' : 'pointer',
  svg: {
    height: '20px',
  },
}));

export default function PrioritySelectorField({
  name,
  value,
  error,
  onChange,
  readonly,
}: Props) {
  const makeSelect = (priority: TaskPriority) => () => {
    if (readonly) {
      return null;
    }

    if (value === TaskPriority.high) {
      onChange(TaskPriority.low);
    } else {
      onChange(priority);
    }
  };

  return (
    <FormGroup>
      <Container>
        <Option
          isSelected={value === TaskPriority.high}
          onClick={makeSelect(TaskPriority.high)}
          readOnly={readonly}
        >
          <FontAwesomeIcon icon={faCalendarExclamation} />
        </Option>
      </Container>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormGroup>
  );
}
