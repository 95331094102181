import { useEffect, useRef } from 'react';
import { numberFromString } from '@util/string-util';
import GroupDetailView, {
  GroupDetailViewProps,
} from '@pages/app/profile/components/group-detail-view';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';

export default function useOpenGroupOnMount(companyGroupId?: number | string) {
  const { showPopup } = useUiPopup();
  const lastOpened = useRef<number | string | undefined>();

  useEffect(() => {
    const groupId = numberFromString(companyGroupId);
    if (groupId != null && lastOpened.current !== companyGroupId) {
      showPopup<GroupDetailViewProps, void>(GroupDetailView, { id: groupId });
      lastOpened.current = companyGroupId;
    }
  }, [companyGroupId, showPopup]);
}
