import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useField from '@hooks/use-field-hook';
import { required, validCurrency } from '@util/validators';
import { useGetSolutionTermOptionsQuery } from '@api/endpoints/solution-term.api';
import {
  useCreateChainItemSolutionMutation,
  useUpdateChainItemSolutionMutation,
} from '@api/endpoints/chain/chain-item-solution.api';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { CreateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/create-chain-item-solution.request';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { numberFromString } from '@util/string-util';
import { useAppSelector } from '@store/store';
import {
  selectCurrentRcaCurrency,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import { useGetSolutionTypeOptionsQuery } from '@api/endpoints/solution-type.api';
import { useState } from 'react';
import { UpdateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/update-chain-item-solution.request';
import { useCasePermission } from '@hooks/case/use-case-permission';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export default function useCreateFromCaseSolutionForm(
  state: SolutionsPanelState
) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const { canContribute } = useCasePermission();
  const caseSolution = state.selectedCaseSolution!;
  const { chainItemId, chainId, editingSolution } = state;

  const focusedNode = useAppSelector(selectFocusedNode);
  const { label } = focusedNode.data;

  const { data: solutionTypes, isLoading: loadingSolutionTypes } =
    useGetSolutionTypeOptionsQuery();
  const { data: termOptions, isLoading: loadingTerms } =
    useGetSolutionTermOptionsQuery();

  const [updateSolution] = useUpdateChainItemSolutionMutation();
  const [createSolution] = useCreateChainItemSolutionMutation();

  const [showAdditionalCost, setShowAdditionalCost] = useState(
    !!(editingSolution?.additionalCost && editingSolution?.additionalCost > 0)
  );
  const type = useField<number>([required()], editingSolution?.solutionTypeId);
  const additionalCost = useField<string>(
    [validCurrency({ when: () => showAdditionalCost })],
    editingSolution?.additionalCost?.toString()
  );
  const effectiveness = useField<number>(
    [required()],
    editingSolution?.efficacy
  );
  const term = useField<number>([required()], editingSolution?.solutionTermId);

  const { isValid, validateAll } = useFieldsWatcher([
    type,
    additionalCost,
    effectiveness,
    term,
  ]);

  const [submit, isBusy] = useBusyAction(async () => {
    if (!validateAll()) {
      return false;
    }

    if (editingSolution) {
      try {
        await updateSolution({
          chainId: chainId!,
          chainItemSolutionId: editingSolution.chainItemSolutionId,
          solutionTypeId: type.value,
          solutionTermId: term.value,
          efficacy: effectiveness.value,
          additionalCost: showAdditionalCost
            ? numberFromString(additionalCost.value)
            : undefined,
          rejected: editingSolution.rejected ?? false,
        }).unwrap();

        await invalidation('Solution', editingSolution.chainItemSolutionId);

        showSuccessMessage(
          `You have successfully updated a solution for Cause Box “${label}”`
        );

        return true;
      } catch (error) {
        const { errors, message } =
          error as ApiError<UpdateChainItemSolutionRequest>;
        showErrorMessage(
          errors?.chainId ?? errors?.chainItemSolutionId ?? message
        );

        type.setError(errors?.solutionTypeId);
        term.setError(errors?.solutionTermId);
        effectiveness.setError(errors?.efficacy);
        additionalCost.setError(errors?.additionalCost);

        return false;
      }
    } else {
      try {
        await createSolution({
          chainId: chainId!,
          chainItemId: chainItemId!,
          solutionTypeId: type.value,
          solutionTermId: term.value,
          solutionId: caseSolution.solutionId,
          additionalCost: showAdditionalCost
            ? numberFromString(additionalCost.value)
            : undefined,
          efficacy: effectiveness.value,
          rejected: false,
        }).unwrap();

        await invalidation('Solution');

        showSuccessMessage(
          'You have successfully added a solution to a Cause Box'
        );

        return true;
      } catch (error) {
        const { errors, message } =
          error as ApiError<CreateChainItemSolutionRequest>;

        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.solutionId ??
            message
        );

        type.setError(errors?.solutionTypeId);
        term.setError(errors?.solutionTermId);
        effectiveness.setError(errors?.efficacy);
        additionalCost.setError(errors?.additionalCost);

        return false;
      }
    }
  });

  const isLoading = loadingTerms || loadingSolutionTypes;
  const canSubmit = isValid && !isBusy;

  const onChangeEffectiveness = (val: number | number[]) => {
    let v = val as number;

    if (v <= 0) {
      v = 0;
    } else if (v <= 5) {
      v = 1;
    } else {
      v = v - 5;
    }
    effectiveness.set(v);
  };

  const formatEffectivenessValue = (
    val: number | number[] | undefined
  ): string => {
    if (val === undefined) return '0%';

    let v = val as number;

    if (v <= 0) return '0%';

    if (v <= 5) return '0.5%';

    return `${v - 5}%`;
  };

  const calculateEffectivenessCurrentValue = (v: number) => {
    if (v <= 0) return 0;
    if (v < 5) return 5;
    return v + 5;
  };

  const currentEffectivenessValue =
    effectiveness.value === undefined
      ? 0
      : calculateEffectivenessCurrentValue(effectiveness.value);

  return {
    caseSolution,
    termOptions,
    additionalCost,
    solutionTypes,
    effectiveness,
    term,
    isLoading,
    canSubmit,
    submit,
    isBusy,
    showAdditionalCost,
    setShowAdditionalCost,
    type,
    currency,
    canContribute,
    canShowSolutionCost: !!caseSolution?.cost,
    currentEffectivenessValue,
    onChangeEffectiveness,
    formatEffectivenessValue,
  };
}
