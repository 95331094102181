import { api } from '@api/api';
import { Option } from '@api/types/option';

const boltOnApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBoltOnOptions: build.query<Option<String>[], void>({
      query: () => ({
        url: 'boltOn/options',
        method: 'GET',
      }),
    }),
    getBoltOnTypeOptions: build.query<Option<String>[], void>({
      query: () => ({
        url: 'boltOnType/options',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBoltOnOptionsQuery, useGetBoltOnTypeOptionsQuery } =
  boltOnApi;
