import { styled } from '@mui/material';

const SmallFormContent = styled('div')({
  padding: '0 25px',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowY: 'auto',
  maxHeight: '70vh',
});

export default SmallFormContent;
