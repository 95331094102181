import { Alert, styled, Typography } from '@mui/material';
import { StyledAlertHeader } from '@components/alerts/styled';
import { AlertProps } from '@components/alerts/alert-props';
import { useCommonTr } from '@i18n/use-common-tr';
import useScrollToOnMount from '@hooks/use-scroll-to-on-mount';

const StyledErrorAlert = styled(Alert)(({ theme: { palette } }) => ({
  backgroundColor: palette.error.light,
  color: palette.common.textDark,
}));

export default function ErrorAlert({ title, message, onClose }: AlertProps) {
  useScrollToOnMount('error-alert');

  const { t } = useCommonTr('errors');
  return (
    <StyledErrorAlert id="error-alert" severity="error" onClose={onClose}>
      {title ? <StyledAlertHeader>{title}</StyledAlertHeader> : null}
      <Typography variant="body2">
        {message != null && typeof message === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: message as string }} />
        ) : message != null ? (
          message
        ) : (
          t('generic')
        )}
      </Typography>
    </StyledErrorAlert>
  );
}
