import useUserPreferences from '@store/user/user-preferences-hook';
import { useMemo } from 'react';
import { useGetUserProfileQuery } from '@api/endpoints/user.api';
import { useAppSelector } from '@store/store';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';

// Don't use this hook unless the user is logged in.
export default function useSharedState() {
  const canFetchProfile = useAppSelector(selectIsLoggedInAndFullyAuthenticated);
  const { data: profile, isLoading: loadingProfile } = useGetUserProfileQuery(
    undefined,
    { skip: !canFetchProfile }
  );
  const userPreferences = useUserPreferences();

  const isLoading = loadingProfile || userPreferences.isLoading;

  return useMemo(
    () => ({
      userPreferences,
      profile,
      isLoading,
    }),
    [isLoading, profile, userPreferences]
  );
}
