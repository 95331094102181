import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { StorageNode } from '@store/rca-editor/types';
import useDeleteStorageNode, {
  DeleteStorageNodeUiState,
} from '@pages/app/rca/tabs/components/modals/delete-storage-node-hook';
import { Box, Typography } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WarningAlert from '@components/alerts/warning-alert';
import WCTTextField from '@components/input/text-field';
import { useEffect } from 'react';
import { Gap } from '@components/layout-util-components/gap';

export interface DeleteStorageNodeModalProps extends ModalProps<boolean> {
  node: StorageNode;
}
const DeleteStorageNodeModal = ({
  onClose,
  node,
}: DeleteStorageNodeModalProps) => {
  const {
    pageState,
    withDataMessage,
    deleteConfirmText,
    onSubmitConfirm,
    onConfirmYes,
    canSubmitConfirm,
  } = useDeleteStorageNode(node);

  useEffect(() => {
    if (pageState === DeleteStorageNodeUiState.close) {
      onClose(true);
    }
  }, [onClose, pageState]);

  return (
    <Column>
      {pageState === DeleteStorageNodeUiState.loading ||
      pageState === DeleteStorageNodeUiState.close ? (
        <Box>
          <LoadingIndicator show />
        </Box>
      ) : pageState === DeleteStorageNodeUiState.yesNo ? (
        <>
          <Typography variant="h6">
            Are you sure you want to delete this Cause Box?
          </Typography>
          <Gap size={15} />
          <Typography variant="body1">{withDataMessage}</Typography>
          <Gap size={8} />
          <ActionsRow>
            <OutlinedButton onClick={() => onClose(false)}>
              Close
            </OutlinedButton>
            <PrimaryButton onClick={() => onConfirmYes()}>
              Yes, Delete
            </PrimaryButton>
          </ActionsRow>
        </>
      ) : pageState === DeleteStorageNodeUiState.confirm ? (
        <>
          <Typography variant="h6">Confirm deletion of Cause Box</Typography>
          <Gap size={15} />
          <Typography variant="body1">
            To delete this Cause Box please type ‘DELETE’ below to confirm the
            deletion of this Cause Box
          </Typography>
          <Gap size={20} />
          <WarningAlert title="Please note all data will be deleted and you will be unable to restore this once deleted" />
          <Gap size={20} />
          <WCTTextField
            name="deleteEntry"
            label="Type ‘DELETE’"
            value={deleteConfirmText.value}
            error={deleteConfirmText.error}
            onChange={deleteConfirmText.set}
            onBlur={deleteConfirmText.validate}
          />
          <ActionsRow>
            <OutlinedButton onClick={() => onClose(false)}>
              Cancel
            </OutlinedButton>
            <PrimaryButton
              disabled={!canSubmitConfirm}
              onClick={() => onSubmitConfirm()}
            >
              Confirm deletion
            </PrimaryButton>
          </ActionsRow>
        </>
      ) : null}
    </Column>
  );
};

export default withModal(DeleteStorageNodeModal);
