import { RcaNode } from '@store/rca-editor/types';
import { RcaDefaultNode } from '@pages/app/rca/tabs/components/node-types/rca-default-node';

export default function DragHolderNode(node: RcaNode) {
  return (
    <div>
      <RcaDefaultNode {...node} />
    </div>
  );
}
