import { EvidencePanelState } from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import { useCasePermission } from '@hooks/case/use-case-permission';

export default function useEvidenceListView({
  evidence,
  editEvidence,
  onUnlinkEvidence,
  isUnlinking,
}: EvidencePanelState) {
  const { canContribute } = useCasePermission();

  return {
    evidenceList: evidence?.model ?? [],
    editEvidence,
    onUnlinkEvidence,
    isUnlinking,
    canContribute,
  };
}
