import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { BackToHelpCentre } from './components/back-to-help-centre';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import { Videos } from './components/videos';
import { useParams } from 'react-router-dom';
import useCategoryPage from './view-category-page-hook';
import { HelpCenterFAQs } from './components/help-centre-faqs';

export const CategoryPage = () => {
  const { id } = useParams<{ id: string }>();
  const state = useCategoryPage(Number(id));

  const { search, videoContent, faqContent, category } = state;

  const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));

  return (
    <Column>
      <ContentContainer>
        <BackToHelpCentre />
        <StyledHeader>
          <Typography variant="h1">{category?.name}</Typography>
          <TextField
            value={search.value}
            onChange={(e) => search.set(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledHeader>
        <Videos showAll={false} videos={videoContent ?? []} />
        <HelpCenterFAQs showAll={false} faqs={faqContent ?? []} />
      </ContentContainer>
    </Column>
  );
};

export default requireAuth(CategoryPage);
