import { useMemo } from 'react';
import { DashboardLeagueTable } from './dashboard-stat-container';
import { formatNumberToDecimals, numberFromString } from '@util/string-util';

interface IDashboardStatThemeProps {
  titlePrefix?: string;
  report: any;
  limit?: number;
  descriptionMinHeight?: string | number;
}

export function DashboardStatTheme({
  report,
  limit,
  descriptionMinHeight,
  titlePrefix,
}: IDashboardStatThemeProps) {
  const filteredThemes = useMemo(() => {
    if (!report || !report.data) return [];
    return report!.data
      .filter((x) => x.clientGeneratedId > 0)
      .slice(0, limit ?? 5);
  }, [report, limit]);

  const topThemeDescription = useMemo(() => {
    if (filteredThemes.length === 0) return <></>;

    const t: any[] = [];

    for (var x = 0; x < filteredThemes.length; x++) {
      if (
        x === 0 ||
        filteredThemes[x].cells[3].value ===
          filteredThemes[x - 1].cells[3].value
      ) {
        t.push(filteredThemes[x]);
        continue;
      }
      break;
    }

    if (t.length === 1) {
      return (
        <>
          <span
            style={{
              color: t[0].colour,
            }}
          >
            '{t[0].cells[1].value}'
          </span>{' '}
          is the most occurring Theme, accounting for{' '}
          <span>{formatNumberToDecimals(t[0].cells[3].value)}%</span> of the
          total cause boxes.
        </>
      );
    } else if (t.length === 2) {
      return (
        <>
          <span
            style={{
              color: t[0].colour,
            }}
          >
            '{t[0].cells[1].value}'
          </span>
          {' and '}
          <span
            style={{
              color: t[0].colour,
            }}
          >
            '{t[1].cells[1].value}'
          </span>{' '}
          are the most occurring Themes, each accounting for{' '}
          <span>{formatNumberToDecimals(t[0].cells[3].value)}%</span> of the
          total cause boxes.
        </>
      );
    } else {
      return (
        <>
          There are multiple themes with the same coverage of{' '}
          <span>
            {formatNumberToDecimals(filteredThemes[0].cells[3].value)}%
          </span>{' '}
          of the total cause boxes.
        </>
      );
    }
  }, [filteredThemes]);

  return (
    <DashboardLeagueTable
      title={
        filteredThemes.length > 0
          ? `${titlePrefix ? `${titlePrefix} ` : ''}Themes (Top ${
              filteredThemes.length
            })`
          : `${titlePrefix ? `${titlePrefix} ` : ''}Themes`
      }
      descriptionMinHeight={descriptionMinHeight}
      description={topThemeDescription}
      tableTitle="Theme"
      emptyMessage="No themes assigned to visual analysis"
      entries={filteredThemes.map((row) => {
        return {
          id: row.clientGeneratedId,
          name: row.cells[1].value,
          causeBoxes: numberFromString(row.cells[2].value),
          percentage: numberFromString(row.cells[3].value) ?? 0.0,
          colour: row.colour,
        };
      })}
    />
  );
}
