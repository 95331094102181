import { ReportType } from '@api/types/report-type/report-type.option';

export enum ReportSubType {
  causeType = 'CauseType',
  evidence = 'Evidence',
  impact = 'Impact',
  solution = 'Solution',
  theme = 'Theme',
}

export interface ReportSubtypeOption {
  reportSubTypeId: number;
  name: string;
  reportSubType: ReportSubType;
  reportTypeId: number;
  reportTypeName: string;
  reportType: ReportType;
}
