import {
  SmallFormContent,
  SmallFormFooter,
  SmallFormHeader,
  SmallFormProps,
  withSmallForm,
} from '@components/ui-popup/small-form';
import Column, { ColumnForm } from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import { LinkButton, PrimaryButton } from '@components/buttons';
import { Grid, Typography } from '@mui/material';
import LabelValueDisplay from '@components/data/label-value-display';
import { useAppTr } from '@i18n/use-app-tr';
import { Divider } from '@components/layout-util-components/divider';
import useTaskForm from '@pages/app/tasks/forms/task-form-hook';
import { DatePickerSkeleton, InputSkeleton } from '@components/skeletons';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import WCTSelectField from '@components/input/select-field';
import Row from '@components/layout-util-components/row';
import WCTDateField from '@components/input/date-field';
import PrioritySelectorField from '@components/input/priority-selector-field';
import TaskCompleteIndicator from '@pages/app/tasks/components/task-complete-indicator';
import { Gap } from '@components/layout-util-components/gap';

export interface AddTaskFormProps extends SmallFormProps<never> {
  taskId?: number;
  caseId?: number;
}

function TaskForm({ onClose, taskId, caseId }: AddTaskFormProps) {
  const { t } = useAppTr('taskForm');

  const {
    isEdit,
    dueDate,
    userOptions,
    assignedUser,
    description,
    isBusy,
    canSubmit,
    name,
    isLoading,
    isFormDirty,
    priority,
    submit,
    createdBy,
    createdDate,
    complete,
    taskName,
    open,
    isComplete,
    isCompleteToggleStatus,
  } = useTaskForm(taskId, caseId);

  const onSubmit = async () => {
    if (isFormDirty) {
      if (!(await submit())) {
        return;
      }
    }

    if (isEdit) {
      if (isCompleteToggleStatus.isDirty) {
        const didUserComplete = isCompleteToggleStatus.value;
        if (didUserComplete) {
          if (!(await complete(taskId!, caseId!))) {
            return;
          }
        } else {
          if (!(await open(taskId!!, caseId!))) {
            return;
          }
        }
      }
    }

    onClose();
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <SmallFormHeader
        onClose={onClose}
        before={
          isEdit ? (
            <TaskCompleteIndicator
              isComplete={isCompleteToggleStatus.value}
              onComplete={() => isCompleteToggleStatus.set(true)}
              onOpen={() => isCompleteToggleStatus.set(false)}
              isTogglingState={false}
            />
          ) : undefined
        }
      >
        <Column
          sx={{
            marginLeft: '10px',
          }}
        >
          <Typography variant="h2">
            {isEdit ? taskName : t('title.create')}
          </Typography>
          {isEdit && (
            <Typography variant="h4">
              {isCompleteToggleStatus.value ? 'Completed' : 'Mark as complete'}
            </Typography>
          )}
        </Column>
      </SmallFormHeader>
      <SmallFormContent>
        <Grid container>
          <Grid item xs={6}>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label={t('createdByLabel')}
                value={createdBy}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label={t('dateCreated.label')}
                value={t(
                  isEdit
                    ? 'dateCreated.value.edit'
                    : 'dateCreated.value.create',
                  { value: createdDate }
                )}
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin compact />
        {!isEdit ? (
          <>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                name="taskName"
                label={t('form.name')}
                value={name.value}
                error={name.error}
                onChange={name.set}
                readonly={isComplete}
                maxLength={50}
                required
              />
            )}
            <Gap size={15} />
          </>
        ) : null}
        <Gap size={15} />
        {isLoading ? (
          <InputSkeleton multiline />
        ) : (
          <WCTTextField
            name="taskDescription"
            label={t('form.description')}
            value={description.value}
            error={description.error}
            onChange={description.set}
            readonly={isComplete}
            required
            multiline
          />
        )}
        <Gap size={15} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="assignedUser"
            name="assignedUser"
            options={userOptions!}
            label={t('form.user')}
            value={assignedUser.value}
            error={assignedUser.error}
            onChange={(v) => assignedUser.set(v as number)}
            readonly={isComplete}
            required
          />
        )}
        <Divider compact addMargin />
        <Row>
          {isLoading ? (
            <DatePickerSkeleton />
          ) : (
            <WCTDateField
              name="dueDate"
              label={t('form.due')}
              value={dueDate.value}
              error={dueDate.error}
              onChange={dueDate.set}
              readonly={isComplete}
              disablePast
              required
            />
          )}
          <Gap size={20} />
          <PrioritySelectorField
            name="taskPriority"
            value={priority.value}
            error={priority.error}
            onChange={priority.set}
            readonly={isComplete}
          />
        </Row>
        <Divider compact addMargin />
      </SmallFormContent>
      <SmallFormFooter>
        <LinkButton onClick={() => onClose()}>
          {t('cancelButtonLabel')}
        </LinkButton>
        <PrimaryButton disabled={!canSubmit} isBusy={isBusy} type="submit">
          {isEdit ? t('buttonLabel.edit') : t('buttonLabel.create')}
        </PrimaryButton>
      </SmallFormFooter>
    </ColumnForm>
  );
}

export default withSmallForm(TaskForm);
