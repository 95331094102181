import { RootState } from '@store/store';
import { createSelector } from '@reduxjs/toolkit';
import { CreateAnalysisType } from '@store/create-analysis-process/types';

export const selectCreateAnalysisType = (state: RootState) =>
  state.createAnalysisProcess.selectedTypeId;

export const selectCurrentCase = (state: RootState) =>
  state.createAnalysisProcess.currentCase;

export const selectCurrentCaseId = createSelector(
  [selectCurrentCase],
  (currentCase) => currentCase?.caseId
);

export const selectStepTitle = createSelector(
  [selectCreateAnalysisType, (state, stepName: string) => stepName],
  (selectedType, stepName) => {
    if (selectedType == null) {
      return '';
    }

    switch (selectedType) {
      case CreateAnalysisType.improvement:
        return `Improvement Analysis - ${stepName}`;
      case CreateAnalysisType.problem:
        return `Problem Analysis - ${stepName}`;
      case CreateAnalysisType.risk:
        return `Risk Analysis - ${stepName}`;
    }
  }
);
