import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useGetSystemTextQuery } from '@api/endpoints/system-text.api';
import { useUpdateCompanySystemTextMutation } from '@api/endpoints/company/company-system-text.api';
import useField from '@hooks/use-field-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useMemo } from 'react';

export default function useCompanySystemText() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { data: systemText, isLoading } = useGetSystemTextQuery();

  const systemTextFocalPoint = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Overview.FocalPoint'
    );
  }, [systemText]);

  const systemTextBusinessFunctions = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Overview.BusinessFunctions'
    );
  }, [systemText]);

  const systemTextRecurrence = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Overview.Recurrence'
    );
  }, [systemText]);

  const systemTextTypeOfImpact = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Details.TypeOfImpact'
    );
  }, [systemText]);

  const systemTextImpactCategory = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Details.ImpactCategory'
    );
  }, [systemText]);

  const systemTextInviteOnly = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Collaborate.InviteOnly'
    );
  }, [systemText]);

  const systemTextShareWithGroups = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.Collaborate.ShareWithGroups'
    );
  }, [systemText]);

  const systemTextTransitory = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.CauseBox.Transitory'
    );
  }, [systemText]);

  const systemTextNonTransitory = useMemo(() => {
    return systemText?.find(
      (x) => x.systemTextKey === 'RCA.CauseBox.NonTransitory'
    );
  }, [systemText]);

  const businessFunctions = useField<string>(
    [],
    systemTextBusinessFunctions?.text ===
      systemTextBusinessFunctions?.originalText
      ? ''
      : systemTextBusinessFunctions?.text
  );

  const focalPoint = useField<string>(
    [],
    systemTextFocalPoint?.text === systemTextFocalPoint?.originalText
      ? ''
      : systemTextFocalPoint?.text
  );

  const impactCategory = useField<string>(
    [],
    systemTextImpactCategory?.text === systemTextImpactCategory?.originalText
      ? ''
      : systemTextImpactCategory?.text
  );

  const recurrence = useField<string>(
    [],
    systemTextRecurrence?.text === systemTextRecurrence?.originalText
      ? ''
      : systemTextRecurrence?.text
  );

  const typeOfImpact = useField<string>(
    [],
    systemTextTypeOfImpact?.text === systemTextTypeOfImpact?.originalText
      ? ''
      : systemTextTypeOfImpact?.text
  );

  const inviteOnly = useField<string>(
    [],
    systemTextInviteOnly?.text === systemTextInviteOnly?.originalText
      ? ''
      : systemTextInviteOnly?.text
  );

  const shareWithGroups = useField<string>(
    [],
    systemTextShareWithGroups?.text === systemTextShareWithGroups?.originalText
      ? ''
      : systemTextShareWithGroups?.text
  );

  const transitory = useField<string>(
    [],
    systemTextTransitory?.text === systemTextTransitory?.originalText
      ? ''
      : systemTextTransitory?.text
  );

  const nonTransitory = useField<string>(
    [],
    systemTextNonTransitory?.text === systemTextNonTransitory?.originalText
      ? ''
      : systemTextNonTransitory?.text
  );

  const [updateSystemText, { isLoading: isSubmitting }] =
    useUpdateCompanySystemTextMutation();

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    focalPoint,
    impactCategory,
    businessFunctions,
    recurrence,
    typeOfImpact,
    inviteOnly,
    shareWithGroups,
    transitory,
    nonTransitory,
  ]);

  const canSubmit = !isSubmitting && !isLoading && isValid && isDirty;

  const submit = () => {
    if (!validateAll()) {
      return false;
    }

    updateSystemText({
      systemText: [
        {
          systemTextId: systemTextFocalPoint?.systemTextId ?? 0,
          text: focalPoint.value,
        },
        {
          systemTextId: systemTextBusinessFunctions?.systemTextId ?? 0,
          text: businessFunctions.value,
        },
        {
          systemTextId: systemTextRecurrence?.systemTextId ?? 0,
          text: recurrence.value,
        },
        {
          systemTextId: systemTextImpactCategory?.systemTextId ?? 0,
          text: impactCategory.value,
        },
        {
          systemTextId: systemTextTypeOfImpact?.systemTextId ?? 0,
          text: typeOfImpact.value,
        },
        {
          systemTextId: systemTextInviteOnly?.systemTextId ?? 0,
          text: inviteOnly.value,
        },
        {
          systemTextId: systemTextShareWithGroups?.systemTextId ?? 0,
          text: shareWithGroups.value,
        },
        {
          systemTextId: systemTextTransitory?.systemTextId ?? 0,
          text: transitory.value,
        },
        {
          systemTextId: systemTextNonTransitory?.systemTextId ?? 0,
          text: nonTransitory.value,
        },
      ],
    })
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully updated labels');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    focalPoint,
    impactCategory,
    businessFunctions,
    recurrence,
    typeOfImpact,
    inviteOnly,
    shareWithGroups,
    transitory,
    nonTransitory,
    isLoading,
    isSubmitting,
    canSubmit,
    submit,
  };
}
