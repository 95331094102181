import {
  Autocomplete,
  Box,
  CircularProgress,
  InputProps,
  styled,
  TextField,
} from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { optionalLabel } from '@util/string-util';

export interface WCTGenericTypeaheadFieldProps<T> {
  id: string;
  name?: string;
  label?: string | DefaultTFuncReturn;
  value?: T | Array<T>;
  error?: string;
  helperText?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  required?: boolean;
  onChange: (value: T | Array<T>) => void;
  onBlur?: () => void;
  readonly?: boolean;
  options?: Array<T>;
  loadOptions?: (search: string) => Promise<Array<T>>;
  groupBy?: (option: T) => string;
  renderOption?: (props, option) => ReactNode;
  multiple?: boolean;
  inputProps?: Partial<InputProps>;
  forcePopupIcon?: boolean;
  preventCloseOnSelect?: boolean;
}

interface Props<T> extends WCTGenericTypeaheadFieldProps<T> {
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
}

const StyledContainer = styled(Box)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid #687A85',
    background: 'white',
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'white',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: '1px solid #FF6C00',
      outline: '5px solid rgba(255, 108, 0, 0.12)',
      background: 'white',
      '&::after': {
        display: 'none',
      },
    },
    '&.Mui-disabled': {
      border: '1px solid #ECEDF0',
      background: '#ECEDF0',
      '&:hover': {
        border: '1px solid #ECEDF0',
        background: '#ECEDF0',
      },
      '.MuiInputBase-readOnly': {
        color: '#000',
        textFillColor: '#000',
      },
    },
    '& input:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:active': {
      WebkitBackgroundClip: 'text',
    },
  },
}));

export default function WCTGenericTypeaheadField<T>({
  label,
  inputProps,
  id,
  name,
  value,
  error,
  helperText,
  placeholder,
  required,
  onChange,
  onBlur,
  readonly,
  options: preloadedOptions,
  loadOptions,
  groupBy,
  renderOption,
  getOptionLabel,
  isOptionEqualToValue,
  multiple,
  forcePopupIcon,
  preventCloseOnSelect = false,
}: Props<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const isAsync = loadOptions != null;
  const isLocalFilter = preloadedOptions != null;
  if (!isAsync && !isLocalFilter) {
    throw new Error(
      'options OR loadOptions must be provided to WCTTypeaheadField'
    );
  } else if (isAsync && isLocalFilter) {
    throw new Error(
      'only ONE of the following props may be provided, but NEVER both: -options or -loadOptions to WCTTypeaheadField'
    );
  }

  const hasLabel = !!label;
  const hasError = !!error;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(preloadedOptions ?? []);
  const [searchText, setSearchText] = useState<string | undefined>();

  const onSearch = useDebouncedCallback((search: string) => {
    if (!isAsync) {
      throw new Error(
        'onSearch should never be called when Typeahead is not async'
      );
    }
    setSearchText(search);
    setIsLoading(true);

    loadOptions(search)
      .then((newOptions) => {
        setOptions(newOptions);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, 300);

  useEffect(() => {
    setOptions(preloadedOptions ?? []);
  }, [preloadedOptions]);

  return (
    <StyledContainer>
      <Autocomplete
        id={id}
        forcePopupIcon={forcePopupIcon}
        loading={isLoading}
        options={options}
        disableCloseOnSelect={multiple}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={isAsync ? (x) => x : undefined}
        value={value || null}
        inputValue={searchText || ''}
        onInputChange={(e, value) => {
          if (
            preventCloseOnSelect &&
            (e == null || e.target !== inputRef.current)
          ) {
            e?.preventDefault();
            e?.stopPropagation();
            return;
          }
          setSearchText(value);
        }}
        readOnly={readonly}
        disabled={readonly}
        onChange={(e, value) => {
          onChange(value as T);
        }}
        onBlur={onBlur}
        groupBy={groupBy}
        renderOption={renderOption}
        multiple={multiple}
        noOptionsText={
          !searchText ? 'Start typing to search' : 'No options found'
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              variant="filled"
              hiddenLabel={!label}
              fullWidth
              required={required}
              name={name}
              error={hasError}
              helperText={hasError ? error : helperText}
              placeholder={placeholder as string}
              label={hasLabel ? optionalLabel(label, required) : undefined}
              onChange={isAsync ? (e) => onSearch(e.target.value) : undefined}
              InputProps={{
                ...params.InputProps,
                ...inputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </StyledContainer>
  );
}
