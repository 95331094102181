import { styled } from '@mui/material';
import ActionsRow from '@components/layout-util-components/actions-row';
import { LinkButton, PrimaryButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { ReportPanelState } from '@pages/app/rca/tabs/reports/report-panel-hook';
import { ButtonSkeleton } from '@components/skeletons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: ReportPanelState;
}

const Container = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  minHeight: 66,
  alignItems: 'center',
  padding: '15px 20px',
  borderTop: '1px solid #DFDFDF',
  background: '#fff',
});

export default function ReportFooterBar({ state }: Props) {
  const { save, canSubmit, isBusy, isLoading, runData, isEmpty, exportData } =
    state;

  return (
    <Container>
      {!isLoading && !isEmpty ? (
        <Row>
          <FontAwesomeIcon icon={faFileCsv} />
          <Gap size={10} />
          <LinkButton disabled={runData == null} onClick={exportData}>
            Export data
          </LinkButton>
        </Row>
      ) : null}
      <ActionsRow>
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <PrimaryButton onClick={save} disabled={!canSubmit} isBusy={isBusy}>
            Save Report
          </PrimaryButton>
        )}
      </ActionsRow>
    </Container>
  );
}
