import { Alert, styled, Typography } from '@mui/material';
import { StyledAlertHeader } from '@components/alerts/styled';
import { AlertProps } from '@components/alerts/alert-props';
import useScrollToOnMount from '@hooks/use-scroll-to-on-mount';

const StyledWarningAlert = styled(Alert)(({ theme: { palette } }) => ({
  backgroundColor: palette.warning.light,
  color: palette.common.textDark,
}));

export default function WarningAlert({ title, message, onClose }: AlertProps) {
  useScrollToOnMount('warning-alert');
  return (
    <StyledWarningAlert id="warning-alert" severity="warning" onClose={onClose}>
      {title ? <StyledAlertHeader>{title}</StyledAlertHeader> : null}
      <Typography variant="body2">
        {message != null && typeof message === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: message as string }} />
        ) : message != null ? (
          message
        ) : null}
      </Typography>
    </StyledWarningAlert>
  );
}
