import { useFilter } from '@components/table';
import { useGetCaseEvidenceQuery } from '@api/endpoints/case.api';
import { useAppSelector } from '@store/store';
import {
  selectCurrentRcaCaseId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import useLoadMore from '@hooks/use-load-more-hook';
import {
  EvidencePanelState,
  EvidencePanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import { useEffect, useState } from 'react';
import {
  useLinkEvidenceMutation,
  useUnlinkEvidenceMutation,
} from '@api/endpoints/chain/chain-item-evidence.api';
import { ApiError } from '@api/types/api-error';
import { LinkEvidenceRequest } from '@api/types/chain/chain-item-evidence/link-evidence.request';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export default function useAddEvidenceOptionView({
  createNew,
  evidence: chainItemEvidence,
  chainId,
  chainItemId,
  setViewState,
}: EvidencePanelState) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const search = useFilter<string>();
  const [selectedEvidence, setSelectedEvidence] = useState<number[]>([]);

  const focusedNode = useAppSelector(selectFocusedNode);
  const { label } = focusedNode?.data ?? {};
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const {
    data: caseEvidence,
    isFetching,
    isSuccess,
    isLoading,
  } = useGetCaseEvidenceQuery({
    caseId,
    searchText: search.value,
    skip,
    take,
  });

  const [linkEvidence] = useLinkEvidenceMutation();
  const [unlinkEvidence] = useUnlinkEvidenceMutation();

  const isSelected = (id: number) => selectedEvidence.includes(id);
  const canSubmit = selectedEvidence.length > 0;

  const [toggleEvidence, isTogglingEvidence] = useBusyAction(
    async (id: number) => {
      if (selectedEvidence.includes(id)) {
        const newSelectedEvidence = selectedEvidence.filter((e) => e !== id);
        setSelectedEvidence(newSelectedEvidence);

        return unlinkEvidence({
          chainId: chainId!,
          chainItemId: chainItemId!,
          evidenceId: id,
        })
          .unwrap()
          .then(async () => {
            await Promise.all([
              invalidation('Evidence', id),
              invalidation('CaseTotals'),
            ]);
            showSuccessMessage(
              `You have successfully unlinked evidence from Cause Box “${label}”`
            );
            if (newSelectedEvidence.length === 0) {
              setViewState(EvidencePanelViewState.empty);
            } else {
              setViewState(EvidencePanelViewState.view);
            }
            return true;
          })
          .catch(({ errors, message }: ApiError<LinkEvidenceRequest>) => {
            showErrorMessage(
              errors?.chainId ??
                errors?.chainItemId ??
                errors?.evidenceId ??
                message
            );

            return false;
          });
      } else {
        const newSelectedEvidence = [...selectedEvidence, id];
        setSelectedEvidence(newSelectedEvidence);

        return linkEvidence({
          chainId: chainId!,
          chainItemId: chainItemId!,
          evidenceId: id,
        })
          .unwrap()
          .then(async () => {
            await Promise.all([
              invalidation('Evidence', id),
              invalidation('CaseTotals'),
            ]);
            showSuccessMessage(
              'You have successfully added evidence to a Cause Box'
            );

            if (newSelectedEvidence.length === 0) {
              setViewState(EvidencePanelViewState.empty);
            } else {
              setViewState(EvidencePanelViewState.view);
            }
            return true;
          })
          .catch(({ errors, message }: ApiError<LinkEvidenceRequest>) => {
            showErrorMessage(
              errors?.chainId ??
                errors?.chainItemId ??
                errors?.evidenceId ??
                message
            );
            return false;
          });
      }
    }
  );

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (caseEvidence?.totalCount === 0) {
      createNew();
    } else {
      setSelectedEvidence(
        chainItemEvidence?.model.map((e) => e.evidenceId) ?? []
      );
    }
  }, [
    caseEvidence?.totalCount,
    chainItemEvidence?.model,
    createNew,
    isSuccess,
  ]);

  return {
    search,
    caseEvidence,
    isLoading,
    isFetching,
    loadMore,
    canLoadMore: canLoadMore(caseEvidence?.totalCount),
    isSelected,
    toggleEvidence,
    canSubmit,
    isTogglingEvidence,
  };
}
