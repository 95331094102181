import { useState } from 'react';
import { HeaderComponent } from '@pages/app/rca/tabs/components/complete-rca/complete-rca-components.styled';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import { CompleteRcaModalState } from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal-hook';
import CompleteIcon from '@assets/svgs/complete-rca.svg';
import { InlineBoxSkeleton } from '@components/skeletons';
import SwitchFieldSkeleton from '@components/skeletons/switch-field-skeleton';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CompleteRcaModalState;
  onCancel: () => void;
  onNext: (rating: number) => void;
}

export default function RatingPage({ state, onCancel, onNext }: Props) {
  const { isLoading, currentQuestion, canSubmit, isBusy } = state;
  const [rating, setRating] = useState<number>();

  return (
    <Column>
      <HeaderComponent>
        <div className="icon-container">
          <img src={CompleteIcon} alt="icon" />
        </div>
      </HeaderComponent>
      <Column
        sx={{
          padding: '43px 91px 31px 91px',
          textAlign: 'center',
          '.MuiTypography-body1': {
            fontSize: 22,
            marginBottom: '15px',
          },
        }}
      >
        {isLoading ? (
          <SwitchFieldSkeleton />
        ) : (
          <InlineRadioSelectGroup
            name="name"
            options={currentQuestion!.options}
            subText={currentQuestion?.subText}
            value={rating}
            onChange={(v) => setRating(v as number)}
            label={currentQuestion!.question}
            // helperIndicators={{
            //   left: 'Weak',
            //   right: 'Strong',
            // }}
          />
        )}
        <Spacer />
        <ActionsRow center anchorBottom addPadding>
          <OutlinedButton onClick={onCancel}>Cancel</OutlinedButton>
          <PrimaryButton
            disabled={rating == null || !canSubmit}
            isBusy={isBusy}
            onClick={() => {
              const selectedRating = rating!;
              setRating(undefined);
              onNext(selectedRating);
            }}
          >
            Next
          </PrimaryButton>
        </ActionsRow>
      </Column>
    </Column>
  );
}
