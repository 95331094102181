import { styled } from '@mui/material';
import BadgeInfo from '@components/outcome-report/components/badge-info';

interface Props {
  value: string;
}

const Container = styled('div')({
  gap: 8,
  '> div': {
    gap: 8,
  },
  '.flat-badge': {
    padding: '6px 12px',
    fontSize: '16px',
    lineHeight: '1.25',
  },
});

export default function SolutionValueBadge({ value }: Props) {
  return (
    <Container>
      <BadgeInfo value={value} />
    </Container>
  );
}
