import { Grid, styled, Typography } from '@mui/material';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { useAppTr } from '@i18n/use-app-tr';
import { getFirstName, getLastName } from '@util/name-util';
import { ElipsisButton } from '@components/buttons';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import ContentFooterCard from '@components/cards/content-footer-card';
import GroupedAvatars from '@components/containers/grouped-avatars';
import Spacer from '@components/layout-util-components/spacer';
import usePersonalGroupActions from '@hooks/groups/use-personal-group-actions';
import { truncateString } from '@util/string-util';
import CircleContainer from '@components/containers/circle-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { dateOnlyLongFormatter } from '@i18n/formatters';
import HeaderBGImage from '@assets/svgs/card-detail.svg';

interface Props {
  group: CompanyGroupListItemResponse;
  allowLeave: boolean;
}

const CardHeader = styled('div')(({ theme: { palette } }) => ({
  background: `url(${HeaderBGImage}) no-repeat top right  ${palette.blue.dark}`,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: 20,
  gap: 12,
  display: 'flex',
  flexDirection: 'column',
  color: palette.common.white,
  '& p': {
    display: 'block',
    lineHeight: '150%',
    height: '2.9em',
    marginRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .circle-container': {
    backgroundColor: palette.blue.light,
    color: palette.blue.dark,
    svg: {
      height: 18,
    },
  },
}));

const CardContent = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  gap: 20,
  '& .MuiTypography-body2': {
    color: palette.blue.dark,
    lineHeight: '130%',
    height: 38,
  },
}));

const CardInfo = styled('div')(({ theme: { palette } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  '& .MuiTypography-overline': {
    display: 'block',
    marginBottom: '0.5em',
    color: palette.blue.dark,
    fontSize: '12px',
  },
  '& .circle-container': {
    backgroundColor: palette.success.main,
    opacity: 1,
    fontSize: '10px',
    color: '#fff',
  },
  '& .name-container .label': {
    fontSize: '14px',
    fontWeight: 400,
  },
}));

export default function GroupCard({ group, allowLeave }: Props) {
  const { t } = useAppTr('groups');
  const {
    groupName: name,
    groupAdminName,
    firstCompanyUsers,
    created,
    companyGroupId,
    groupAdminCompanyUserId,
    description,
    rcaCount,
  } = group;

  const { options, onViewGroup } = usePersonalGroupActions(
    companyGroupId,
    name,
    groupAdminCompanyUserId,
    { allowLeave }
  );

  return (
    <ContentFooterCard
      onClick={onViewGroup}
      pointer
      content={
        <>
          <CardHeader>
            <CircleContainer size={32}>
              <FontAwesomeIcon icon={faUsers} />
            </CircleContainer>
            <Typography variant="body1">{truncateString(name, 45)}</Typography>
          </CardHeader>
          <CardContent>
            <Typography variant="body2">
              {truncateString(description, 60)}
            </Typography>
            <CardInfo>
              <Typography variant="overline">Primary Admin</Typography>
              <InitialsContainerWithData
                firstName={getFirstName(groupAdminName)}
                lastName={getLastName(groupAdminName)}
              />
            </CardInfo>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography variant="overline">Created</Typography>
                  {dateOnlyLongFormatter(created)}
                </CardInfo>
              </Grid>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography variant="overline">RCA's</Typography>
                  {rcaCount}
                </CardInfo>
              </Grid>
            </Grid>
          </CardContent>
        </>
      }
      footer={
        <>
          <GroupedAvatars items={firstCompanyUsers} extractInitials />
          <Spacer />
          <ElipsisButton actions={options} />
        </>
      }
    />
  );
}
