import { api } from '@api/api';
import { Option } from '@api/types/option';

const impactReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getImpactReportGroupOptions: build.query<Option<string>[], void>({
      query: () => 'impactReport/impactGroup/options',
    }),
    getImpactReportTypeOptions: build.query<Option<string>[], void>({
      query: () => 'impactReport/impactType/options',
    }),
  }),
});

export const {
  useGetImpactReportGroupOptionsQuery,
  useGetImpactReportTypeOptionsQuery,
} = impactReportApi;
