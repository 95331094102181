export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function areEqualShallow<T>(a: T, b: T) {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export function convertBytesToMB(bytes: number) {
  return `${(bytes / 1024 / 1024).toFixed(1)}mb`;
}
