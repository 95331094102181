import { styled } from '@mui/material';

export const NavTabContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 10,
  height: 31,
});

export const NavTabHeader = styled('div')<{ isSelected?: boolean }>(
  ({ isSelected, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: '7px 16px',
    backgroundColor: theme.palette.tabs.background,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '128%',
    borderRadius: 4,
    color: theme.palette.tabs.text,
    userSelect: 'none',
    cursor: 'pointer',
    ...(isSelected && {
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
      color: theme.palette.tabs.contrastText,
      cursor: 'default',
    }),
  })
);
