import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { ElipsisButton } from '@components/buttons';
import WCTSelectField from '@components/input/select-field';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import FilledBadge from '@components/badges/filled-badge';
import WCTSwitchField from '@components/input/switch-field';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { CaseRoleOption } from '@api/types/case-role/case-role-option';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

interface Props {
  user: CaseUserResource;
  onRoleChange: (newRole: CaseRoleOption) => void;
  onRemove: () => Promise<any>;
  roleOptions: CaseRoleOption[];
  onSetToChainEditor: () => void;
}

export default function CollaborateInvitedUserRow({
  user,
  onRoleChange,
  onRemove,
  roleOptions,
  onSetToChainEditor,
}: Props) {
  const {
    companyUserId,
    name,
    skills,
    jobTitle,
    companyRoleName,
    companyRole,
    isChainEditor,
    // This is the case role that was given to the user and we only care
    // for owner role here
    caseRole: givenCaseRole,
    caseRoleName: givenCaseRoleName,
    caseRoleId: givenCaseRoleId,
  } = user;
  const [isRemoving, setIsRemoving] = useState(false);

  const isCompanyRoleViewer = companyRole === 'Viewer';

  const { canChangeEditor } = useCasePermission();

  const actualRole = useMemo(
    () =>
      roleOptions.find((x) => x.caseRoleId === givenCaseRoleId) ?? {
        caseRoleId: givenCaseRoleId,
        caseRole: givenCaseRole,
        name: givenCaseRoleName,
      },
    [givenCaseRoleId, givenCaseRoleName, givenCaseRole, roleOptions]
  );

  const filteredRoleOptions = useMemo(() => {
    if (!isCompanyRoleViewer) {
      return roleOptions;
    }

    return roleOptions.filter((x) => x.caseRole === 'ReadOnly');
  }, [isCompanyRoleViewer, roleOptions]);

  const caseRoleName = actualRole.name;

  const isViewerRole =
    actualRole.caseRole === 'ReadOnly' || isCompanyRoleViewer;
  const isOwnerRole = actualRole.caseRole === 'Owner';

  return (
    <TableRow>
      <TableCell style={{ minWidth: 200 }}>
        <InitialsContainerWithData
          label={name}
          fullName={name}
          subLabel={jobTitle}
        />
      </TableCell>
      <TableCell>{companyRoleName}</TableCell>
      <TableCell>
        {skills && skills.length > 0 && (
          <FilledBadge>{skills[0].label}</FilledBadge>
        )}
        {skills && skills.length > 1 && (
          <FilledBadge>+{skills.length - 1}</FilledBadge>
        )}
      </TableCell>
      <TableCell>
        <WCTSwitchField
          value={isChainEditor}
          disabled={isViewerRole || !canChangeEditor}
          onChange={(isOn) => {
            if (isOn) {
              onSetToChainEditor();
            }
          }}
        />
      </TableCell>
      <TableCell width={269}>
        {isOwnerRole ? (
          <Typography>{caseRoleName}</Typography>
        ) : (
          <WCTSelectField
            name={`user-${companyUserId}-invite-role`}
            id={`user-${companyUserId}-invite-role`}
            options={filteredRoleOptions.map((x) => ({
              id: x.caseRoleId,
              label: x.name,
            }))}
            value={actualRole.caseRoleId}
            onChange={(v) =>
              onRoleChange(roleOptions.find((x) => x.caseRoleId === v)!)
            }
            placeholder="Invite Role"
          />
        )}
      </TableCell>
      <TableCell style={{ width: 100 }}>
        {isRemoving ? (
          <FetchingIndicator show />
        ) : (
          <ElipsisButton
            actions={[
              {
                label: 'Remove',
                onClick: () => {
                  setIsRemoving(true);
                  onRemove().finally(() => setIsRemoving(false));
                },
                hasPermission: !isOwnerRole,
              },
            ]}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
