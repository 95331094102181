import { useState } from 'react';
import { useGetCompleteCaseQuestionsQuery } from '@api/endpoints/complete-case-question.api';
import {
  useCloseCaseMutation,
  useGetCaseDetailQuery,
  useGetCompanyUserOptionsQuery,
} from '@api/endpoints/case.api';
import { isApiError } from '@api/types/api-error';
import {
  CaseKudos,
  CaseQuestionAnswer,
  CloseCaseRequest,
} from '@api/types/case/close-case.request';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { usePageAlertVariants } from '@components/alerts';
import useUser from '@store/user/user-hook';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';

export enum CompleteRcaModalPage {
  initial,
  questions,
  teamPerformance,
  complete,
}

export default function useCompleteRcaModal() {
  const { companyUserId } = useUser();
  const { showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);

  const { data: questions, isLoading: loadingQuestions } =
    useGetCompleteCaseQuestionsQuery();

  const { data: users, isLoading: loadingUsers } =
    useGetCompanyUserOptionsQuery({
      caseId,
      excludeCaseRoles: ['ReadOnly', 'Owner'],
    });

  const [closeCase] = useCloseCaseMutation();

  const [page, setPage] = useState<CompleteRcaModalPage>(
    CompleteRcaModalPage.initial
  );

  // Dictionary that holds the ratings for each question
  const [ratings, setRatings] = useState<Record<number, number>>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const [pointsAllocated, setPointsAllocated] = useState<
    Record<number, boolean>
  >({});

  const [additionalPointsAvailable, setAdditionalPointsAvailable] =
    useState(40);

  const isCaseOwner = caseDetail?.createdByCompanyUserId === companyUserId;
  const canGiveMorePoints = additionalPointsAvailable > 0;

  const [complete, isBusy] = useBusyAction(async () => {
    const kudos: Array<CaseKudos> = Object.keys(pointsAllocated).map((key) => ({
      companyUserId: parseInt(key),
      kudos: true,
    }));

    const questionAnswers: Array<CaseQuestionAnswer> = Object.keys(ratings).map(
      (key) => ({
        questionId: questions![parseInt(key)].questionId,
        optionId: ratings[parseInt(key)],
      })
    );

    try {
      await closeCase({
        caseId,
        kudos,
        questionAnswers,
      }).unwrap();

      setPage(CompleteRcaModalPage.complete);
      await Promise.all([
        invalidation('Case', caseId),
        invalidation('Case'),
        invalidation('CaseTotals'),
      ]);
      return true;
    } catch (e) {
      if (isApiError<CloseCaseRequest>(e)) {
        const { errors, message } = e;
        showErrorMessage(
          errors?.caseId ?? errors?.kudos ?? errors?.questionAnswers ?? message
        );
      }

      return false;
    }
  });

  const setRatingAndMoveNext = (rating: number) => {
    setRatings((prev) => ({
      ...prev,
      [currentQuestion]: rating,
    }));

    if (currentQuestion + 1 < questions!.length) {
      setCurrentQuestion((prev) => prev + 1);
    } else if (users && users.length > 0) {
      setPage(CompleteRcaModalPage.teamPerformance);
    } else {
      complete();
    }
  };

  const givePointsTo = (id: number) => {
    setPointsAllocated((prev) => ({
      ...prev,
      [id]: true,
    }));

    setAdditionalPointsAvailable((prev) => prev - 5);
  };

  const getPointsFor = (id: number) => {
    return pointsAllocated[id] ? 25 : 20;
  };

  const isLoading = loadingQuestions || loadingCaseDetail || loadingUsers;
  const canSubmit = !isBusy && !isLoading;

  return {
    page,
    setPage,
    setRatingAndMoveNext,
    complete,
    givePointsTo,
    totalAdditionalPointsAward: 40 - additionalPointsAvailable,
    canGiveMorePoints,
    additionalPointsAvailable,
    questions,
    isLoading,
    currentQuestion: questions?.[currentQuestion],
    canSubmit,
    isBusy,
    isCaseOwner,
    users,
    getPointsFor,
  };
}

export type CompleteRcaModalState = ReturnType<typeof useCompleteRcaModal>;
