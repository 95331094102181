import {
  useGetCompanyUserDetailQuery,
  useInviteUserMutation,
  useUpdateCompanyUserMutation,
} from '@api/endpoints/company/company-company-user.api';
import { useGetCompanyRolesQuery } from '@api/endpoints/company-role.api';
import useField from '@hooks/use-field-hook';
import { required, validEmail } from '@util/validators';
import { useMemo } from 'react';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { ApiError } from '@api/types/api-error';
import { InviteUserErrors } from '@api/types/company/company-user/invite-user.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { UpdateCompanyUserRequest } from '@api/types/company/company-user/update-company-user.request';

export default function useUserForm(userId?: number) {
  const isEdit = userId != null;
  const { t } = useAppTr('createUserForm');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { data: userDetail, isLoading: loadingUserDetail } =
    useGetCompanyUserDetailQuery(userId ?? -1, {
      skip: !isEdit,
    });

  const { data: roleOptions, isLoading: loadingRoles } =
    useGetCompanyRolesQuery();

  const { data: groupOptions, isLoading: loadingGroups } =
    useGetCompanyGroupOptionsQuery({});

  const [inviteUser, { isLoading: isSubmittingInvite }] =
    useInviteUserMutation();

  const [updateUser, { isLoading: isSubmittingUpdate }] =
    useUpdateCompanyUserMutation();

  const firstName = useField<string>([required()], userDetail?.firstName);
  const surname = useField<string>([required()], userDetail?.lastName);
  const email = useField<string>(
    [required(), validEmail()],
    userDetail?.emailAddress
  );
  const role = useField<string>([required()], userDetail?.companyRole);
  const jobTitle = useField<string>([], userDetail?.jobTitle);
  const groups = useField<Array<number>>(
    [],
    useMemo(
      () => userDetail?.groups.map((x) => x.id) ?? [],
      [userDetail?.groups]
    )
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    firstName,
    surname,
    email,
    role,
    jobTitle,
    groups,
  ]);

  const isLoading = loadingRoles || loadingGroups || loadingUserDetail;
  const isSubmitting = isSubmittingInvite || isSubmittingUpdate;
  const canSubmit = !isLoading && !isSubmitting && isDirty && isValid;

  const displayGroupOptions =
    isLoading || (groupOptions != null && groupOptions.length > 0);

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    if (isEdit) {
      return updateUser({
        companyUserId: userId!,
        jobTitle: jobTitle.value,
        companyRole: role.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage('User updated');
          return true;
        })
        .catch(({ message, errors }: ApiError<UpdateCompanyUserRequest>) => {
          showErrorMessage(errors?.companyUserId ?? message);

          jobTitle.setError(errors?.jobTitle);
          role.setError(errors?.companyRole);
          return false;
        });
    }

    return inviteUser({
      firstName: firstName.value,
      lastName: surname.value,
      jobTitle: jobTitle.value,
      companyGroupIds: groups.value,
      emailAddress: email.value,
      companyRole: role.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<InviteUserErrors>) => {
        showErrorMessage(errors?.companyId || message);

        firstName.setError(errors?.firstName);
        surname.setError(errors?.lastName);
        jobTitle.setError(errors?.jobTitle);
        groups.setError(errors?.companyGroupIds);
        email.setError(errors?.emailAddress || errors?.userId);
        role.setError(errors?.companyRole);

        return false;
      });
  };

  return {
    firstName,
    surname,
    email,
    role,
    jobTitle,
    groups,
    roleOptions,
    groupOptions,
    isLoading,
    canSubmit,
    isSubmitting,
    submit,
    isEdit,
    displayGroupOptions,
  };
}
