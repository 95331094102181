import { useAppSelector } from '@store/store';
import { selectDatetimeShortFormat } from '@store/locale/locale-selector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { i18nDayJs } from '@i18n/index';
import { DefaultTFuncReturn } from 'i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box, styled } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.types';
import { Dayjs } from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';
import { optionalLabel } from '@util/string-util';

type Variant = 'default' | 'small';

interface StyledDateTimePickerProps extends DateTimePickerProps<Dayjs> {
  styledVariant?: Variant;
  hideIcon?: boolean;
}

const StyledDateTimePicker = styled(DateTimePicker)<StyledDateTimePickerProps>(
  ({ theme, styledVariant, hideIcon }) => ({
    maxWidth: styledVariant === 'small' ? 195 : 300,
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      boxSizing: 'border-box',
      border: '1px solid #687A85',
      background: 'white',
      '&.Mui-focused': {
        border: '1px solid #FF6C00',
        outline: '5px solid rgba(255, 108, 0, 0.12)',
        background: 'white',
        '&::after': {
          display: 'none',
        },
      },
      '&::before': {
        display: 'none',
      },
      '&:hover::before': {
        display: 'none',
      },
      '&.Mui-disabled': {
        border: '1px solid #ECEDF0',
        background: '#ECEDF0',
        '&:hover': {
          border: '1px solid #ECEDF0',
          background: '#ECEDF0',
        },
        '.MuiInputBase-readOnly': {
          color: '#000',
          textFillColor: '#000',
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        display: !hideIcon ? undefined : 'none',
      },
      '& input:-webkit-autofill': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:hover': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:focus': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:active': {
        WebkitBackgroundClip: 'text',
      },
      '& input': {
        height: '0.7em',
      },
    },
    '.MuiInputAdornment-positionEnd': {
      position: 'absolute',
      left: '100%',
      button: {
        border: '1px solid #B3BABE',
        borderRadius: 4,
        background: '#B3BABE',
        color: 'white',
      },
    },
  })
);

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  error?: string;
  placeholder?: string | DefaultTFuncReturn;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  disablePast?: boolean;
  disableFuture?: boolean;
  readonly?: boolean;
  openOnValueChange?: string;
  closeOnValueChange?: string;
  onPickerClosed?: () => void;
  onPickerOpened?: () => void;
  maxDate?: string;
  variant?: Variant;
  hideIcon?: boolean;
}

export default function WCTDateTimeField({
  name,
  label,
  value,
  required,
  onChange,
  error,
  placeholder,
  onBlur,
  disablePast,
  disableFuture,
  readonly,
  openOnValueChange,
  closeOnValueChange,
  onPickerClosed,
  onPickerOpened,
  maxDate,
  hideIcon,
  variant = 'default',
}: Props) {
  const format = useAppSelector(selectDatetimeShortFormat);
  const hasError = !!error;
  const [open, setOpen] = useState(false);
  const [openOnValueChangeState, setOpenOnValueChangeState] =
    useState(openOnValueChange);
  const [closeOnValueChangeState, setCloseOnValueChangeState] =
    useState(closeOnValueChange);

  const onBlurDebounce = useDebouncedCallback(() => onBlur?.call(undefined));

  const closePicker = useCallback(() => {
    setOpen(false);
    onPickerClosed && onPickerClosed();
  }, [setOpen, onPickerClosed]);

  const openPicker = useCallback(() => {
    setOpen(true);
    onPickerOpened && onPickerOpened();
  }, [setOpen, onPickerOpened]);

  const valueAsDate = useMemo(() => {
    if (value == null) {
      return null;
    }

    try {
      const date = i18nDayJs(value).utc();
      if (!date.isValid()) {
        return null;
      }

      return date;
    } catch {
      return null;
    }
  }, [value]);

  const maxDateAsDayJs = useMemo(() => {
    if (maxDate == null) {
      return undefined;
    }

    try {
      const date = i18nDayJs(maxDate).utc();
      if (!date.isValid()) {
        return undefined;
      }

      return date;
    } catch {
      return undefined;
    }
  }, [maxDate]);

  useEffect(() => {
    if (openOnValueChange === openOnValueChangeState) return;

    if (!value) {
      openPicker();
      setOpenOnValueChangeState(openOnValueChange);
    }
  }, [
    value,
    openOnValueChange,
    openOnValueChangeState,
    setOpenOnValueChangeState,
    openPicker,
  ]);

  useEffect(() => {
    if (closeOnValueChange === closeOnValueChangeState) return;

    closePicker();
    setCloseOnValueChangeState(closeOnValueChange);
  }, [
    closeOnValueChange,
    closeOnValueChangeState,
    setCloseOnValueChangeState,
    closePicker,
  ]);

  return (
    <Box
      onClick={() => {
        if (!open) {
          openPicker();
        }
      }}
    >
      <StyledDateTimePicker
        label={optionalLabel(label, required)}
        value={valueAsDate}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        onChange={(v) => {
          const value = i18nDayJs(v);
          if (v == null) {
            onChange(undefined);
          } else if (value.isValid()) {
            onChange(value.startOf('minute').toISOString() || undefined);
          }

          onBlurDebounce();
        }}
        format={format}
        ampm={false}
        disablePast={disablePast}
        disableFuture={disableFuture}
        maxDate={maxDateAsDayJs}
        readOnly={readonly}
        disabled={readonly}
        open={open}
        onOpen={() => openPicker()}
        onClose={closePicker}
        styledVariant={variant}
        hideIcon={hideIcon}
        slotProps={{
          textField: {
            name: name,
            hiddenLabel: !label,
            error: hasError,
            variant: 'filled',
            helperText: error,
            placeholder: placeholder as string | undefined,
            required: required,
            onBlur: onBlur,
            onClick: (e) => {
              e.stopPropagation();
              openPicker();
            },
            fullWidth: true,
            sx: {
              maxWidth: variant === 'small' ? 195 : 225,
            },
          },
        }}
      />
    </Box>
  );
}
