import RcaCreateScaffold from '@pages/app/rca/create/components/rca-create-scaffold';
import ContentContainer from '@components/containers/content-container';
import { Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import RcaTypeCard from '@pages/app/rca/create/components/rca-type-card';
import { useNavigate } from 'react-router-dom';
import RcaStepperAppBar from '@pages/app/rca/create/components/rca-stepper-app-bar';
import { useAppDispatch } from '@store/store';
import { setAnalysisCreationType } from '@store/create-analysis-process/create-analysis-slice';
import useCreateTypePage from './rca-create-type-page-hook';
import { Gap } from '@components/layout-util-components/gap';
import GridSkeleton from '@components/skeletons/grid-skeleton';
import { Fragment } from 'react';
import HelpSideBar from '@pages/app/rca/create/components/help-side-bar';

function RcaCreateTypePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { caseAnalysisTypes, loadingCaseAnalysisTypes } = useCreateTypePage();

  const onSelectChoice = (caseAnalysisTypeId: number) => {
    dispatch(setAnalysisCreationType(caseAnalysisTypeId));
    navigate(`./steps/?type=${caseAnalysisTypeId}`);
  };

  return (
    <RcaCreateScaffold
      appbar={<RcaStepperAppBar onBack={() => navigate('/analysis')} />}
      sidebar={<HelpSideBar />}
    >
      <ContentContainer>
        <Gap size={50} />
        <Typography variant="h1">Create</Typography>
        <Gap size={20} />
        <Typography variant="subtitle1">Select RCA Type *</Typography>
        <Gap size={10} />
        <Typography variant="body2">
          Select your chosen RCA type from the flow options below
        </Typography>
        <Gap size={50} />
        {loadingCaseAnalysisTypes ? (
          <GridSkeleton count={3} itemWidth={250} />
        ) : (
          <Row wrap>
            {caseAnalysisTypes!.map((x, i) => (
              <Fragment key={x.caseAnalysisTypeId}>
                <RcaTypeCard
                  title={x.name}
                  description={x.description}
                  onClick={() => onSelectChoice(x.caseAnalysisTypeId)}
                  caseAnalysisType={x.caseAnalysisType}
                />
                {i < caseAnalysisTypes!.length - 1 && <Gap size={24} />}
              </Fragment>
            ))}
          </Row>
        )}
      </ContentContainer>
    </RcaCreateScaffold>
  );
}

export default RcaCreateTypePage;
