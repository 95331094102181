import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

export default function NoFilteredGroupsInfo() {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
      title="No Groups to display"
      message="Adjust the filters to view groups."
    />
  );
}
