import {
  styled,
  TableFooter,
  TablePagination,
  tablePaginationClasses,
  TablePaginationProps,
  TableRow,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export interface Pagination {
  count: number;
  page: number;
  rowsPerPage: number;
  skip: number;
  take: number;
  onPageChange: Dispatch<SetStateAction<number>>;
  onRowsPerPageChange: Dispatch<SetStateAction<number>>;
}

const StyledFooter = styled(TableFooter)({
  borderBottom: 0,
  '& td': {
    borderBottom: 0,
  },
});
const StyledPagination = styled(TablePagination)<TablePaginationProps>({
  [`& .${tablePaginationClasses.spacer}`]: {
    display: 'none',
  },
  [`& .${tablePaginationClasses.toolbar}`]: {
    justifyContent: 'center',
  },
});

export default function WCTTablePagination({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: Pagination) {
  return (
    <StyledFooter>
      <TableRow>
        <StyledPagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(e, p) => onPageChange(p)}
          onRowsPerPageChange={(e) => onRowsPerPageChange(+e.target.value)}
          align="center"
        />
      </TableRow>
    </StyledFooter>
  );
}
