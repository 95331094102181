import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { OkModal } from '@components/ui-popup/modals';
import { OutlinedButton } from '@components/buttons';
import { usePageAlertVariants } from '@components/alerts';

interface WAInstallSecretModalProps extends ModalProps<never> {
  secret: string;
}

function WAInstallSecretModal({
  secret,
  show,
  onClose,
}: WAInstallSecretModalProps) {
  const { showSuccessMessage } = usePageAlertVariants();
  return (
    <OkModal
      show={show}
      onClose={() => onClose()}
      title="Install Secret"
      message={`This is your secret key. Please copy it and keep it safe.\n\nThis will only be shown once.\n\n${secret}`}
      okButtonLabel="I have kept it safe"
      otherAction={
        <OutlinedButton
          onClick={() => {
            navigator.clipboard.writeText(secret);
            showSuccessMessage('Secret copied to clipboard');
          }}
        >
          Copy
        </OutlinedButton>
      }
    />
  );
}

export default withModal(WAInstallSecretModal);
