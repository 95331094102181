import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import ConditionDivider from '@components/layout-util-components/condition-divider';
import { Gap } from '@components/layout-util-components/gap';
import { Typography } from '@mui/material';
import SecondaryButton from '@components/buttons/secondary-button';
import useBusyAction from '@hooks/use-busy-action-hook';
import { useSetChainItemOnboardingAreaNotApplicableMutation } from '@api/endpoints/chain/chain-item.api';
import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectFocusedNodeChainItemId,
} from '@store/rca-editor/selectors';
import { isApiError } from '@api/types/api-error';
import { SetChainItemAreaNotApplicableRequest } from '@api/types/chain/set-chain-item-area-not-applicable.request';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faFile,
  faListCheck,
} from '@fortawesome/pro-light-svg-icons';
import { faBullseyePointer } from '@fortawesome/pro-regular-svg-icons';

export type OnboardingChainItemArea =
  | 'Task'
  | 'Solution'
  | 'Evidence'
  | 'Impact';

interface Props {
  area: OnboardingChainItemArea;
  notApplicable?: boolean;
  onCreateClicked: () => void;
  canContribute: boolean;
}

const Icon = {
  Task: <FontAwesomeIcon icon={faListCheck} size="2x" />,
  Solution: <FontAwesomeIcon icon={faCircleCheck} size="2x" />,
  Evidence: <FontAwesomeIcon icon={faFile} size="2x" />,
  Impact: <FontAwesomeIcon icon={faBullseyePointer} size="2x" />,
};

const Title = {
  Task: 'There are no tasks currently assigned to this cause box',
  Solution: 'There are no solutions currently assigned to this cause box',
  Evidence: 'No evidence assigned',
  Impact: 'There are no impacts currently assigned to this cause box',
};

const Message = {
  Task: {
    noChoiceOrApplicable:
      'Please click below to Create a New Task for this Cause',
    noChoiceOrApplicableCantContribute: 'No tasks added to this Cause',
    notApplicable:
      'You have selected ‘Mark as No Tasks Required’ for this Cause.',
  },
  Solution: {
    noChoiceOrApplicable:
      'Please click below to Create a New Solution or Assign an Existing Solution to this Cause',
    noChoiceOrApplicableCantContribute: 'No solutions added to this Cause',
    notApplicable:
      'You have selected ‘Mark as No Solutions Required’ for this Cause.',
  },
  Evidence: {
    noChoiceOrApplicable:
      'Please click below to Create New Evidence or Assign Existing Evidence to this Cause',
    noChoiceOrApplicableCantContribute: 'No evidence added to this Cause',
    notApplicable:
      'You have selected ‘Mark as No Evidence Required’ for this Cause.',
  },
  Impact: {
    noChoiceOrApplicable:
      'Please click below to Assign an Impact to this Cause',
    noChoiceOrApplicableCantContribute: 'No impacts added to this Cause',
    notApplicable:
      'You have selected ‘Mark as No Impacts Required’ for this Cause.',
  },
};

const CreateButton = {
  Task: 'Create Task',
  Solution: 'Create/Assign Solution',
  Evidence: 'Create/Assign Evidence',
  Impact: 'Assign Impact',
};

const Footer = {
  Task: {
    noChoiceMade: '',
    isNotApplicable: '',
  },
  Solution: {
    noChoiceMade: '',
    isNotApplicable: '',
  },
  Evidence: {
    noChoiceMade: '',
    isNotApplicable: '',
  },
  Impact: {
    noChoiceMade: '',
    isNotApplicable: '',
  },
};

const NotApplicableButton = {
  Task: 'Mark as No Tasks Required',
  Solution: 'Mark as No Solutions Required',
  Evidence: 'Mark as No Evidence Required',
  Impact: 'Mark as No Impacts Required',
};

export default function ChainItemOnboardingContainer({
  area,
  notApplicable,
  onCreateClicked,
  canContribute,
}: Props) {
  const { showErrorMessage } = usePageAlertVariants();
  const chainId = useAppSelector(selectChainId)!;
  const chainItemId = useAppSelector(selectFocusedNodeChainItemId);

  const disableButtons = chainItemId == null;
  const hasMadeChoice = notApplicable != null;
  const showCreateButton = !notApplicable && canContribute;

  const icon = Icon[area];
  const title = Title[area];
  const message = notApplicable
    ? Message[area].notApplicable
    : canContribute
    ? Message[area].noChoiceOrApplicable
    : Message[area].noChoiceOrApplicableCantContribute;
  const footer =
    hasMadeChoice && notApplicable
      ? Footer[area].isNotApplicable
      : Footer[area].noChoiceMade;

  const [setNotApplicable] =
    useSetChainItemOnboardingAreaNotApplicableMutation();

  const [makeNotApplicable, isBusy] = useBusyAction(async (na: boolean) => {
    try {
      await setNotApplicable({
        chainId,
        area,
        chainItemId: chainItemId!,
        na,
      }).unwrap();
      await Promise.all([
        invalidation('ChainItem', chainItemId!),
        invalidation('CaseTotals'),
      ]);
      return true;
    } catch (e) {
      if (isApiError<SetChainItemAreaNotApplicableRequest>(e)) {
        const { message, errors } = e;
        showErrorMessage(
          errors?.chainId ?? errors?.chainItemId ?? errors?.na ?? message
        );
      }
      return false;
    }
  });

  const makeApplicableThenOnCreate = async () => {
    if (notApplicable) {
      await makeNotApplicable(false);
    }
    onCreateClicked();
  };

  return (
    <OnboardingEntryContainer
      icon={icon}
      title={title}
      message={message}
      createMessage={showCreateButton ? CreateButton[area] : undefined}
      onCreateClicked={showCreateButton ? onCreateClicked : undefined}
      hideBgImage
      afterContent={
        canContribute && (
          <>
            <ConditionDivider />
            <Gap size={32} />
            <Typography variant="body1">{footer}</Typography>
            <Gap size={15} />
            {showCreateButton ? (
              <SecondaryButton
                disabled={disableButtons}
                isBusy={isBusy}
                onClick={() => makeNotApplicable(true)}
              >
                {NotApplicableButton[area]}
              </SecondaryButton>
            ) : (
              <SecondaryButton
                disabled={disableButtons}
                isBusy={isBusy}
                onClick={makeApplicableThenOnCreate}
              >
                {CreateButton[area]}
              </SecondaryButton>
            )}
          </>
        )
      }
    />
  );
}
